import axios from "axios";
import C from "constants/onboarding";
import { batchActions } from "redux-batched-actions";
import ActionHelpers from "./helpers";
import map from "lodash/map";
import { authBearerToken } from "utils/auth";
import Analytics, {
  ONBOARDING_COMPLETE,
  ONBOARDING_SKIPPED,
  PLATFORM_WEB
} from "utils/analytics";
import { ONBOARDING_STATUSES } from "utils/constants/onboarding";

export function inviteTeammatesRequest() {
  return {
    type: C.UPDATE_ONBOARDING_STORE,
    payload: {
      isLoading: {
        action: "send",
        type: "invites",
        message: "Inviting teammates"
      }
    }
  };
}

export function skipInviteTeammatesRequest() {
  return {
    type: C.UPDATE_ONBOARDING_STORE,
    payload: {
      isLoading: {
        action: "skip",
        type: "invites",
        message: "Skip inviting teammates"
      }
    }
  };
}
/**
 * setOnboardings
 *
 * set a onboardings array payload on the store
 */
export function setOnboardings(payload = []) {
  return {
    type: C.SET_ONBOARDINGS,
    payload
  };
}

export function updateOnboardingAction(payload) {
  return {
    type: C.UPDATE_ONBOARDING,
    payload
  };
}

export function updateUserOnboardingConfig(config) {
  return {
    type: C.SET_ONBOARDING_CONFIG,
    payload: [config]
  };
}
/**
 * updateUserOnboardingConfigOptions
 *
 * @param {Object} config - onboarding config
 * @param {Object} config.id - onboarding id
 * @param {Object} config.field_id - field to set options on
 * @param {Object} config.section_id - onboarding section targeted
 * @param {Object} config.options - options to set
 * @api public
 */
export function updateUserOnboardingConfigOptions(config) {
  return {
    type: C.SET_ONBOARDING_CONFIG_OPTIONS,
    payload: config
  };
}

/**
 * resetOnboardingNotes
 *
 */
export function resetOnboardingNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ONBOARDING_STORE,
      payload
    });
  };
}

export function resetFormFields(payload) {
  return {
    type: C.RESET_ONBOARDING_STORE_FORM,
    payload
  };
}

/**
 * setOrRemoveOnboardingFormSectionValues
 *
 */
export function setOrRemoveOnboardingFormSectionValues(
  payload,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch({
      type: C.SET_OR_REMOVE_ONBOARDING_FORM_SECTION_VALUES,
      payload
    });
    callback();
  };
}
/**
 * removeOnboardingFormSectionValues
 *
 */
export function removeOnboardingFormSectionValues(
  payload,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch({
      type: C.REMOVE_ONBOARDING_FORM_SECTION_VALUES,
      payload
    });
    callback();
  };
}
/**
 * setOrUpdateOnboardingFormSectionValues
 *
 */
export function setOrUpdateOnboardingFormSectionValues(
  payload,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch({
      type: C.SET_UPDATE_ONBOARDING_FORM_SECTION_VALUES,
      payload
    });
    callback();
  };
}

/**
 * replaceOnboardingFormSectionValues
 *
 */
export function replaceOnboardingFormSectionValues(
  payload,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch({
      type: C.REPLACE_ONBOARDING_FORM_SECTION_VALUES,
      payload
    });
    callback();
  };
}

/**
 * progressOnboarding
 *
 * completes the current onboarding flow - (i.e. all scenes in the onboarding are completed)
 */
export function progressOnboarding(user, onboarding, callback = () => {}) {
  const { id: userId, uuid: userUUID } = user;
  const { id, service, category } = onboarding;
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ONBOARDING_STORE,
        "progress",
        "onboarding",
        "Progresss the onboarding"
      )
    );
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userId}/onboardings/${id}/progress`;

    axios({
      method: "put",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        if (data.status === ONBOARDING_STATUSES.COMPLETE) {
          const trackingProps = {
            distinct_id: userUUID,
            platform: PLATFORM_WEB,
            service,
            category
          };
          Analytics.track(ONBOARDING_COMPLETE, trackingProps);
        }

        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_ONBOARDING_STORE),
            updateOnboardingAction(data)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ONBOARDING_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * skipOnboarding
 *
 * skips the current onboarding flow - (i.e. sets the status to skipped)
 */
export function skipOnboarding(user, onboarding, callback = () => {}) {
  const { id: userId, uuid: userUUID } = user;
  const { id, service, category } = onboarding;
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ONBOARDING_STORE,
        "skip",
        "onboarding",
        "Skipping onboarding step"
      )
    );
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userId}/onboardings/${id}/skip`;

    axios({
      method: "put",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        if (data.status === ONBOARDING_STATUSES.SKIPPED) {
          const trackingProps = {
            distinct_id: userUUID,
            platform: PLATFORM_WEB,
            service,
            category
          };
          Analytics.track(ONBOARDING_SKIPPED, trackingProps);
        }

        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_ONBOARDING_STORE),
            updateOnboardingAction(data)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ONBOARDING_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * completeOnboarding
 *
 * completes the current onboarding flow - (i.e. all scenes in the onboarding are completed)
 */
export function completeOnboarding(user, onboarding, callback = () => {}) {
  const { id: userId, uuid: userUUID } = user;
  const { id, service, category } = onboarding;
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ONBOARDING_STORE,
        "complete",
        "onboarding",
        "Completing the onboarding"
      )
    );
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userId}/onboardings/${id}/complete`;

    axios({
      method: "put",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const trackingProps = {
          distinct_id: userUUID,
          platform: PLATFORM_WEB,
          service,
          category
        };
        Analytics.track(ONBOARDING_COMPLETE, trackingProps);

        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_ONBOARDING_STORE),
            updateOnboardingAction(data)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ONBOARDING_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * updateOnboarding
 *
 * update user onboarding
 */
export function updateOnboarding(
  userId,
  onboarding,
  payload,
  callback = () => {}
) {
  const { id } = onboarding;

  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ONBOARDING_STORE,
        "update",
        "onboarding",
        "Update the onboarding"
      )
    );
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userId}/onboardings/${id}`;

    axios({
      method: "put",
      url: REQUEST_URL,
      data: payload,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_ONBOARDING_STORE),
            updateOnboardingAction(data)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ONBOARDING_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * prefillFormWithFields
 *
 * initialize onboarding form with fields
 */
export function prefillFormWithFields(config, sections, callback = () => {}) {
  // set form using config
  return (dispatch) => {
    const fields = internals.consolidatedFieldsFromConfig(config, sections);

    dispatch(resetFormFields(fields));
    callback();
  };
}

/**
 * generateFieldsFromConfig
 *
 * fetch onboarding config
 */
export function generateFieldsFromConfig(config, callback = () => {}) {
  // set form using config
  return (dispatch) => {
    const fields = internals.fieldsFromConfig(config);
    dispatch(resetFormFields(fields));
    callback();
  };
}

/**
 * fetchOnboardingConfig
 *
 * fetch configuration for specific onboarding section
 */
export function fetchOnboardingConfig(userId, onboarding, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ONBOARDING_STORE,
        "options",
        "onboarding",
        "Fetching onboarding config"
      )
    );
    const ONBOARDING_CONFIG_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userId}/onboardings/${onboarding.id}/quiz/config`;

    axios
      .get(ONBOARDING_CONFIG_URL, { headers: authBearerToken() })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_ONBOARDING_STORE),
            updateUserOnboardingConfig(data)
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ONBOARDING_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchOnboardingOptions
 *
 * fetch onboarding options
 * set the options on the axiosing config field
 */
export function fetchOnboardingOptions(
  OPTIONS_URL,
  payload,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ONBOARDING_STORE,
        "options",
        "onboarding",
        "Fetching onboarding options"
      )
    );
    axios({
      method: "post",
      url: OPTIONS_URL,
      data: payload,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_ONBOARDING_STORE),
            updateUserOnboardingConfigOptions(data)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ONBOARDING_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * skipInvite
 *
 * skips the service onboarding invite step and marks as complete
 */
export function skipInvite(skipEndpoint, callback = () => {}) {
  return (dispatch) => {
    dispatch(skipInviteTeammatesRequest());
    axios
      .get(`${process.env.NEXT_PUBLIC_API_ROOT}${skipEndpoint}`, {
        headers: authBearerToken()
      })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(),
            updateOnboardingAction(data)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            batchActions(
              ActionHelpers.errorAndClear(
                C.UPDATE_ONBOARDING_STORE,
                err,
                false,
                dispatch
              )
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * onboardingMessage
 *
 * trigger an onboarding related message
 */
export function onboardingMessage(message, callback = () => {}) {
  return (dispatch) => {
    dispatch(ActionHelpers.successNote(C.UPDATE_ONBOARDING_STORE, message));
  };
}

/**
 * inviteTeammates
 *
 * sends invites to service teammates to setup boltmail
 * marks step as complete
 * @param {Object} app
 * @param {Object} sender
 * @param {String} uid
 * @param {String} email
 * @param {Object} user
 * @param {Number} distinct_id
 * @param {String} email
 * @param {String} inviteEndpoint
 * @param {Array} members
 * @param {Number} organization_id
 * @param {String} teamUID
 */
export function inviteTeammates(
  sender,
  user,
  inviteEndpoint,
  members,
  organizationID,
  teamUID,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(inviteTeammatesRequest());

    const INVITE_URL = `${process.env.NEXT_PUBLIC_API_ROOT}${inviteEndpoint}`;
    axios({
      method: "post",
      url: INVITE_URL,
      data: {
        members,
        organization_id: organizationID,
        teamUID,
        userID: user.id,
        sender
      },
      headers: authBearerToken()
    })
      .then(({ data }) => {
        // Analytics.track("Invitations", {
        //   distinct_id: user.id,
        //   email: user.email,
        //   count: members.length,
        //   platform: "web"
        // });

        dispatch(
          batchActions([
            ActionHelpers.clearLoader(),
            updateOnboardingAction(data)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            batchActions(
              ActionHelpers.errorAndClear(
                C.UPDATE_ONBOARDING_STORE,
                err,
                false,
                dispatch
              )
            )
          )
        );
        callback(err);
      });
  };
}

export const internals = {
  fieldsFromConfig: (config) => {
    return {
      sections: map(config.sections, (section) => {
        return {
          uid: section.uid,
          values: []
        };
      })
    };
  },
  consolidatedFieldsFromConfig: (config, sections) => {
    return {
      sections: map(config.sections, (section) => {
        return (
          sections.find(
            (existingSection) => existingSection.uid === section.uid
          ) || {
            uid: section.uid,
            values: []
          }
        );
      })
    };
  }
};
