import { v4 as uuidv4 } from "uuid";
import {
  TAX_RATE_CLUSIVITY,
  TAX_RATE_DISPLAY_NAME,
  TAX_RATE_DISPLAY_NAME_OPTIONS,
  TAX_RATE_FORM_KEY,
  TAX_RATE_CLUSIVITY_LABEL_MAP,
  TAX_BEHAVIOR
} from "./constants/taxRate";
import orderBy from "lodash/orderBy";
import startCase from "lodash/startCase";
import { formatUTCTime } from "utils/date";
import { getStripeAdminLink } from "utils/stripe";
import { FORM_KEY } from "utils/constants/form";
import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import { reduceKeyDifferences } from "./data";
import { processUpdateMetadata } from "./metadata";
import { COUNTRY_US } from "components/FormerEditor/common/constants/currency";
import { PRODUCT_STATUS } from "./constants/product";

export const getTaxRateClusivity = (taxRate) =>
  taxRate.inclusive
    ? TAX_RATE_CLUSIVITY.INCLUSIVE
    : TAX_RATE_CLUSIVITY.EXCLUSIVE;

export const getTaxRateClusivityLabel = (taxRate) =>
  TAX_RATE_CLUSIVITY_LABEL_MAP[getTaxRateClusivity(taxRate)];

export const getCreateTaxRateScaffold = (uuid = uuidv4()) => {
  const displayNameMatch = TAX_RATE_DISPLAY_NAME_OPTIONS.find(
    ({ uid }) => uid === TAX_RATE_DISPLAY_NAME.SALES_TAX
  );

  return {
    [FORM_KEY]: {
      [TAX_RATE_FORM_KEY.DISPLAY_NAME_TYPE]:
        displayNameMatch.uid || TAX_RATE_DISPLAY_NAME.SALES_TAX,
      [TAX_RATE_FORM_KEY.CLUSIVITY]: TAX_RATE_CLUSIVITY.EXCLUSIVE
    },
    key: uuid,
    uuid,
    display_name:
      displayNameMatch.label || startCase(TAX_RATE_DISPLAY_NAME.SALES_TAX),
    percentage: "",
    inclusive: false,
    country: "",
    state: "",
    description: "",
    jurisdiction: ""
  };
};

export const prepareEditTaxRateInitialValues = ({ taxRate }) => {
  /**
   * We need to determin the tax rate type to initialize the form with
   * - If the display_name is the same as one of the resticted tax rate option labels then it is NOT custom type
   */
  const displayNameMatch = TAX_RATE_DISPLAY_NAME_OPTIONS.find(
    ({ label }) => label.toLowerCase() === taxRate.display_name.toLowerCase()
  );
  const taxRateType = displayNameMatch
    ? displayNameMatch.uid
    : TAX_RATE_DISPLAY_NAME.CUSTOM;

  return {
    [FORM_KEY]: {
      [TAX_RATE_FORM_KEY.DISPLAY_NAME_TYPE]: taxRateType,
      [TAX_RATE_FORM_KEY.CLUSIVITY]: getTaxRateClusivity(taxRate)
    },
    id: taxRate.id,
    display_name: taxRate.display_name,
    percentage: `${taxRate.percentage}%`,
    inclusive: taxRate.inclusive,
    country: taxRate.country,
    state: taxRate.state,
    description: taxRate.description,
    jurisdiction: taxRate.jurisdiction
  };
};

export const prepareCreateTaxRateInitialValues = (uuid) => {
  return {
    taxRates: [getCreateTaxRateScaffold(uuid)]
  };
};

const prepareTaxRateFromValues = (taxRate) => {
  /**
   * Required fields for creation
   */
  const result = {
    display_name: taxRate.display_name,
    inclusive: taxRate.inclusive,
    percentage: parseFloat(taxRate.percentage)
  };
  ["country", "state", "description", "jurisdiction"].forEach((key) => {
    if (taxRate[key]) {
      result[key] = taxRate[key];
    }
  });

  /**
   * Ensure that state is only present when the country is US
   */
  if (result.country !== COUNTRY_US && result.state) {
    delete result.state;
  }

  return result;
};

export const prepareCreateTaxRates = (taxRates) => {
  return omitAndSanitizeParams({
    taxRates: taxRates.map((values) => prepareTaxRateFromValues(values))
  });
};

/**
 * Compare changes on keys for update
 * Note: db will merge the data field but that should be deprecated
 * @param {Object} initial form values
 * @param {Object} current form values
 */
export const prepareUpdateTaxRate = (initial, current) => {
  const result = reduceKeyDifferences(
    ["active", "country", "description", "jurisdiction", "state"],
    initial,
    current
  );
  /**
   * Ensure that state is only present when the country is US
   */
  if ((current.country !== COUNTRY_US && result.state) || !result.state) {
    delete result.state;
  }

  /**
   * Process metadata - setting null where values are empty so that they are unset within Stripe
   * https://stripe.com/docs/api/products/update#update_product-metadata
   */
  result.metadata = processUpdateMetadata(initial.metadata, current.metadata);

  return result;
};

export const prepareTaxRateShow = ({ taxRate }) => {
  const result = {
    id: taxRate.id,
    displayName: taxRate.display_name,
    percentage: `${taxRate.percentage}%`,
    inclusive: taxRate.inclusive,
    type: taxRate.inclusive
      ? startCase(TAX_RATE_CLUSIVITY.INCLUSIVE.toLowerCase())
      : startCase(TAX_RATE_CLUSIVITY.EXCLUSIVE.toLowerCase()),
    description: taxRate.description,
    jurisdiction: taxRate.jurisdiction,
    status: taxRate.active
      ? startCase(PRODUCT_STATUS.ACTIVE)
      : startCase(PRODUCT_STATUS.INACTIVE),
    country: taxRate.country,
    state: taxRate.state,
    adminUrl: getStripeAdminLink(taxRate.livemode, `taxRates/${taxRate.id}`),
    createdAt: formatUTCTime(taxRate.created * 1000)
  };

  return result;
};

export const prepareTaxRateList = ({ taxRates, shouldOrder }) => {
  const ctxModels = shouldOrder
    ? orderBy(taxRates, ["created"], ["desc"])
    : taxRates;

  return ctxModels.map((taxRate) => {
    return prepareTaxRateShow({
      taxRate
    });
  });
};

export const isTaxRateId = (id) => /^txr_/.test(id);

export const isMissingTaxBehavior = (behavior) =>
  !behavior || behavior === TAX_BEHAVIOR.UNSPECIFIED;

export const hasSpecifiedTaxBehavior = (behavior) =>
  behavior && behavior !== TAX_BEHAVIOR.UNSPECIFIED;

export const getPriceTaxBehaviorLabel = ({ tax_behavior }) =>
  hasSpecifiedTaxBehavior(tax_behavior)
    ? `Tax ${tax_behavior === TAX_BEHAVIOR.INCLUSIVE ? "included" : "excluded"}`
    : null;
