import SERVICES from "utils/constants/services";

const PAGES_CREATORS_SUPPORT = "pages__creators--support";
const PAGES_SUBSCRIPTIONS_CHROME = "pages__subscriptions--chrome";
const PAGES_SUBSCRIPTIONS_CHROME_MOBILE = "pages__subscriptions--chromeMobile";

const FEATURES_ANALYTICS_CHROME = "features__analytics--chrome";
const FEATURES_ANALYTICS_CHROME_MOBILE = "features__analytics--chromeMobile";

const FEATURES_EDITOR_CHROME = "features__editor--chrome";
const FEATURES_EDITOR_CHROME_MOBILE = "features__editor--chromeMobile";

const PAYMENTS_SECURE = "payments__secure";
const PRICING_TABLE_ISO = "pricing_table__isometric";
const FEATURE_COMPARISON = "feature__comparison";
const LINK_CHECKOUT = "link__checkout";
const PUBLISH_PAGE_PREVIEW = "publish_page__preview";
const PUBLISH_PAGE_EDITOR = "publish_page__editor";
const PAGE_PICKER = "page__picker";
const BLOCKS_PICKER = "blocks__picker";
const PRODUCT_FEATURE_SHOW = "productFeature__show";
const PRODUCT_FEATURE_TOOLTIP = "productFeature__tooltip";
const EDITOR_BLOCK_SETTINGS = "editor__blockSettings";
const EDITOR_EDIT_BLOCK = "editor__editBlock";
const EDITOR_PUBLISH = "editor__publish";
const EDITOR_SIDE_NAV = "editor__sideNav";
const PRICING_TABLE_CHROME = "pricingTable__chrome";

const PAYMENT_LINK_BUILD = "paymentLink__build";
const PAYMENT_LINK_OMNICHANNEL = "paymentLink__omnichannel";
const PAYMENT_LINK_PAYMENT_METHODS = "paymentLink__paymentMethods";

const LINK_USE_CASE_EMAIL = "link__useCase--email";
const LINK_USE_CASE_VIDEO = "link__useCase--video";
const LINK_USE_CASE_SOCIAL = "link__useCase--social";
const LINK_USE_CASE_CONTACTLESS = "link__useCase--contactless";
const LINK_USE_CASE_SMS = "link__useCase--sms";

const USE_CASE_ICON_EMAIL = "useCase__icon--email";
const USE_CASE_ICON_SOCIAL = "useCase__icon--social";
const USE_CASE_ICON_VIDEO = "useCase__icon--video";
const USE_CASE_ICON_SMS = "useCase__icon--sms";
const USE_CASE_ICON_CONTACTLESS = "useCase__icon--contactless";

const USE_CASE_PULSE = "useCase__pulse";
const USE_CASE_EMAIL = "useCase__email";
const USE_CASE_SOCIAL = "useCase__social";
const USE_CASE_VIDEO = "useCase__video";
const USE_CASE_SMS = "useCase__sms";
const USE_CASE_CONTACTLESS = "useCase__contactless";

const BUILDING_BLOCK_PRICING = "buildingBlock__pricing";
const LOW_CODE_PAYMENT_LINK = "lowCode__paymentLink";
const PAYMENT_DATA_CONFIG = "paymentData__config";
const LOGIC_PREFILL_CONFIG = "logicPrefill__config";

const STATISTIC_ABANDON = "statistic__abandon";
const STATISTIC_EXPERIENCE = "statistic__experience";
const STATISTIC_REVENUE = "statistic__revenue";
const STATISTIC_RPU = "statistic__rpu";

export default {
  PAYMENTS_SECURE,
  PRICING_TABLE_ISO,
  LINK_CHECKOUT,
  FEATURE_COMPARISON,
  PUBLISH_PAGE_PREVIEW,
  PUBLISH_PAGE_EDITOR,
  BLOCKS_PICKER,
  PAGE_PICKER,
  PRODUCT_FEATURE_SHOW,
  PRODUCT_FEATURE_TOOLTIP,
  EDITOR_BLOCK_SETTINGS,
  EDITOR_EDIT_BLOCK,
  EDITOR_PUBLISH,
  EDITOR_SIDE_NAV,
  PRICING_TABLE_CHROME,
  // Payment Link Education
  PAYMENT_LINK_BUILD,
  PAYMENT_LINK_OMNICHANNEL,
  PAYMENT_LINK_PAYMENT_METHODS,
  // UseCase icons
  USE_CASE_ICON_EMAIL,
  USE_CASE_ICON_SOCIAL,
  USE_CASE_ICON_VIDEO,
  USE_CASE_ICON_SMS,
  USE_CASE_ICON_CONTACTLESS,
  // UseCase images
  USE_CASE_PULSE,
  USE_CASE_EMAIL,
  USE_CASE_SOCIAL,
  USE_CASE_VIDEO,
  USE_CASE_SMS,
  USE_CASE_CONTACTLESS,
  // Building blocks
  BUILDING_BLOCK_PRICING,
  // Low code payment link
  LOW_CODE_PAYMENT_LINK,
  PAYMENT_DATA_CONFIG,
  LOGIC_PREFILL_CONFIG,
  // Stats
  STATISTIC_ABANDON,
  STATISTIC_EXPERIENCE,
  STATISTIC_REVENUE,
  STATISTIC_RPU,
  // Link use cases
  LINK_USE_CASE_EMAIL,
  LINK_USE_CASE_VIDEO,
  LINK_USE_CASE_SOCIAL,
  LINK_USE_CASE_CONTACTLESS,
  LINK_USE_CASE_SMS,
  // Pages - Creators
  PAGES_CREATORS_SUPPORT,
  PAGES_SUBSCRIPTIONS_CHROME,
  PAGES_SUBSCRIPTIONS_CHROME_MOBILE,
  // Features - Analytics
  FEATURES_ANALYTICS_CHROME,
  FEATURES_ANALYTICS_CHROME_MOBILE,
  // Features - Editor
  FEATURES_EDITOR_CHROME,
  FEATURES_EDITOR_CHROME_MOBILE
};

export const DIMENSIONS = {
  SHOW_LARGE: {
    width: 200,
    height: 160
  },
  SHOW_SMALL: {
    width: 120,
    height: 120
  },
  PRODUCT_CARD: {
    width: 80,
    height: 80
  },
  PRODUCT_CELL: {
    height: 180,
    width: 180
  },
  PRODUCT_LIST_ITEM: {
    width: 52,
    height: 52
  },
  SALE_CARD: {
    width: 64,
    height: 64
  },
  SALE_CELL: {
    width: 180,
    height: 140
  }
};

export const ICON_DIMENSIONS = {
  [SERVICES.SHOPIFY]: {
    width: "22",
    height: "25"
  },
  [SERVICES.SQUARESPACE]: {
    width: "28",
    height: "24"
  }
};

export const LOGO_DIMENSIONS = {
  [SERVICES.SHOPIFY]: {
    width: "105",
    height: "30"
  },
  [SERVICES.SQUARESPACE]: {
    width: "127",
    height: "30"
  },
  [SERVICES.ZAPIER]: {
    width: "66",
    height: "30"
  },
  [SERVICES.REWARDFUL]: {
    width: "139",
    height: "31"
  }
};
