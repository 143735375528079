import CONSTANTS from "constants/organization";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const internals = {
  [CONSTANTS.SET_ORGANIZATIONS]: (state, { payload }) => {
    return helpers.setIn(state, ["data"], "id", payload);
  },
  [CONSTANTS.SET_ORGANIZATION_SUBSCRIPTION]: (
    state,
    { payload: { uuid, subscription } }
  ) => {
    const orgIndex = state
      .get("data")
      .findIndex((org) => org.get("uuid") === uuid);
    if (orgIndex > -1) {
      return state.setIn(["data", orgIndex, "subscription"], subscription);
    }

    return state;
  },
  [CONSTANTS.SET_ORGANIZATION_LICENSES]: (
    state,
    { payload: { uuid, licenses } }
  ) => {
    const orgIndex = state
      .get("data")
      .findIndex((org) => org.get("uuid") === uuid);
    if (orgIndex > -1) {
      return helpers.setIn(
        state,
        ["data", orgIndex, "organization_licenses"],
        "id",
        licenses
      );
    }

    return state;
  },
  [CONSTANTS.SET_ORGANIZATION_API_KEYS]: (
    state,
    { payload: { uuid, apiKeys } }
  ) => {
    const orgIndex = state
      .get("data")
      .findIndex((org) => org.get("uuid") === uuid);
    if (orgIndex > -1) {
      return helpers.setIn(
        state,
        ["data", orgIndex, "api_keys"],
        "id",
        apiKeys
      );
    }

    return state;
  },
  [CONSTANTS.SET_ORGANIZATION_SUBDOMAINS]: (
    state,
    { payload: { uuid, subdomains } }
  ) => {
    const orgIndex = state
      .get("data")
      .findIndex((org) => org.get("uuid") === uuid);

    if (orgIndex > -1) {
      return state.setIn(["data", orgIndex, "subdomains"], fromJS(subdomains));
    }

    return state;
  },
  [CONSTANTS.UPDATE_ORGANIZATION_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
