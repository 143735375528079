import * as Sentry from "@sentry/react";
import debounce from "lodash/debounce";
import get from "lodash/get";
import QS from "qs";
import { API_PATHS } from "./paths";
import { isTestRoute } from "./route";

const DIALOG_CLASS_NAME = "sentry-error-embed-wrapper";

const debouncedDialog = debounce((event, user) => {
  Sentry.showReportDialog({ eventId: event.event_id, ...user });
}, 500);

export const showErrorDialog = (user) => (event) => {
  const notShown =
    document.getElementsByClassName(DIALOG_CLASS_NAME).length === 0;

  /**
   * Check if it is an exception, and if so, show the report dialog
   * Ensure not show too many times by debouncing
   * */
  if (event.exception && notShown) {
    debouncedDialog(event, user);
  }
  return event;
};

export const captureException = (error) =>
  process.env.NODE_ENV === "production"
    ? Sentry.captureException(error)
    : console.error(error.message);

export const getCheckoutErrorAPIRoute = ({ pathname, query }) => {
  const path = isTestRoute(pathname)
    ? API_PATHS.TEST_CHECKOUT_ERROR
    : API_PATHS.CHECKOUT_ERROR;

  return `${path}?${QS.stringify(query)}`;
};

export const getErrorMessage = (err) =>
  get(err, "response.data.message") || err.message;

export const reduceDisplayErrorMessage = (displayError) => {
  const messages = [];
  if (displayError.title) {
    messages.push(displayError.title);
  }
  if (displayError.body && displayError.body.length > 0) {
    messages.push(displayError.body.join(" "));
  }

  return messages.length > 0 ? messages.join(" ") : "";
};
