import { PAYMENT_ACTION } from "./payment";

export const EDITOR_PRESENTATION = {
  SPLIT: "split",
  LIST: "list",
  GRID: "grid"
};

export const EDITOR_FLOW = {
  STANDARD: "standard",
  LEFT_RIGHT: "leftRight",
  RIGHT_LEFT: "rightLeft"
};

export const INITIAL_PRODUCT_COLLECTION_CONFIG = {
  layout: EDITOR_PRESENTATION.SPLIT,
  flow: EDITOR_FLOW.STANDARD,
  paymentAction: PAYMENT_ACTION.SCAN,
  initialPreview: {
    show: true
  },
  checkout: {
    common: {}
  },
  products: {
    checkouts: {},
    layout: []
  },
  cart: {
    products: []
  }
};

export const INITIAL_FORMER_FORM_CONFIG = {
  logic: {
    checkouts: {
      fields: [],
      combinations: []
    }
  }
};

export const EDITOR_PRESENTATION_PRESENTATION_CONFIG = [
  {
    icon: EDITOR_PRESENTATION.LIST,
    value: EDITOR_PRESENTATION.LIST
  },
  {
    icon: EDITOR_PRESENTATION.GRID,
    value: EDITOR_PRESENTATION.GRID
  }
];

export const EDITOR_FLOW_PRESENTATION_CONFIG = [
  {
    icon: EDITOR_FLOW.STANDARD,
    value: EDITOR_FLOW.STANDARD
  },
  {
    icon: EDITOR_FLOW.LEFT_RIGHT,
    value: EDITOR_FLOW.LEFT_RIGHT
  },
  {
    icon: EDITOR_FLOW.RIGHT_LEFT,
    value: EDITOR_FLOW.RIGHT_LEFT
  }
];

const LEFT = "left";
const RIGHT = "right";

export const EDITOR_FLOW_DIRECTION = {
  LEFT,
  RIGHT
};

const EDIT = "edit";
const INSPECT = "inspect";
const USE = "use";
export const EDITOR_MODE = {
  EDIT,
  INSPECT,
  USE
};

const UI = "ui";
const CODE = "code";
export const PREVIEW_MODE = {
  UI,
  CODE
};

export const EDITABLE_COMMAND = {
  BOLD: "bold",
  ITALIC: "italic",
  STRIKE: "strike",
  CODE: "code",
  PARAGRAPH: "paragraph",
  HEADING: "heading",
  BULLET_LIST: "bulletList",
  ORDERED_LIST: "orderedList",
  CODE_BLOCK: "codeBlock",
  BLOCKQUOTE: "blockquote",
  SUPERSCRIPT: "superscript",
  SUBSCRIPT: "subscript",
  HORIZONTAL_RULE: "horizontalRule",
  HARD_BREAK: "hardBreak",
  UNDO: "undo",
  REDO: "redo",
  LINK: "link"
};

export const EDITABLE_CONTENT_ACTION = {
  TOGGLE: "toggle",
  SET: "set",
  UNSET: "unset"
};

export const EDITABLE_CONTENT_MENU_CONFIG = [
  {
    command: EDITABLE_COMMAND.BOLD,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.ITALIC,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.STRIKE,
    action: EDITABLE_CONTENT_ACTION.TOGGLE,
    label: "Strikethrough"
  },
  {
    command: EDITABLE_COMMAND.CODE,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.PARAGRAPH,
    action: EDITABLE_CONTENT_ACTION.SET
  },
  ...[1, 2, 3, 4, 5, 6].map((level) => ({
    command: EDITABLE_COMMAND.HEADING,
    icon: `${EDITABLE_COMMAND.HEADING}_${level}`,
    label: `H${level}`,
    action: EDITABLE_CONTENT_ACTION.TOGGLE,
    props: {
      level
    }
  })),
  {
    command: EDITABLE_COMMAND.BULLET_LIST,
    action: EDITABLE_CONTENT_ACTION.TOGGLE,
    label: "Unordered list"
  },
  {
    command: EDITABLE_COMMAND.ORDERED_LIST,
    action: EDITABLE_CONTENT_ACTION.TOGGLE,
    label: "Ordered list"
  },
  {
    command: EDITABLE_COMMAND.CODE_BLOCK,
    action: EDITABLE_CONTENT_ACTION.TOGGLE,
    label: "Code block"
  },
  {
    command: EDITABLE_COMMAND.BLOCKQUOTE,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.SUBSCRIPT,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.SUPERSCRIPT,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.HORIZONTAL_RULE,
    action: EDITABLE_CONTENT_ACTION.SET,
    label: "Horizontal rule"
  },
  {
    command: EDITABLE_COMMAND.HARD_BREAK,
    action: EDITABLE_CONTENT_ACTION.SET,
    label: "Hard break"
  },
  {
    command: EDITABLE_COMMAND.UNDO
  },
  {
    command: EDITABLE_COMMAND.REDO
  }
];

export const LEGAL_CONTENT_MENU_CONFIG = [
  {
    command: EDITABLE_COMMAND.BOLD,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.ITALIC,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.SUBSCRIPT,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.SUPERSCRIPT,
    action: EDITABLE_CONTENT_ACTION.TOGGLE
  },
  {
    command: EDITABLE_COMMAND.LINK,
    label: "Set link",
    action: EDITABLE_CONTENT_ACTION.SET
  },
  {
    command: EDITABLE_COMMAND.LINK,
    icon: "unlinked",
    label: "Unset link",
    action: EDITABLE_CONTENT_ACTION.UNSET,
    getDisabled: (editor) => !editor.isActive("link")
  },
  {
    command: EDITABLE_COMMAND.UNDO
  },
  {
    command: EDITABLE_COMMAND.REDO
  }
];
