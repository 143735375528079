const NOTE = "note";
export const CONFIRMATION_TEMPLATE = {
  NOTE
};

const getNoteTemplate = () => ({ copy: "" });

export const CONFIRMATION_TEMPLATE_FACTORY = {
  [CONFIRMATION_TEMPLATE.NOTE]: getNoteTemplate
};
