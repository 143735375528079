import maxBy from "lodash/maxBy";
import set from "lodash/set";
import get from "lodash/get";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import { getTemplatePrice } from "utils/constants/price";
import { PRODUCT_STATUS } from "utils/constants/product";
import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import {
  preparePriceFromValues,
  stripePricesRange,
  validatePrice
} from "./price";
import { STATE_KEYS } from "./constants/state";
import { reduceKeyDifferences } from "utils/data";
import { transformPrice } from "components/FormerEditor/common";
import { formattedPriceToUnitAmount } from "components/FormerEditor/common/currency";
import Analytics from "utils/analytics";
import { formatUTCTime } from "./date";
import { getStripeAdminLink } from "./stripe";
import { FORM_KEY, FORM_MODE } from "./constants/form";
import { INTEGRATION_SERVICE } from "./constants/metadata";
import {
  prepareMetadataFieldArray,
  processCreateMetadata,
  processUpdateMetadata
} from "./metadata";
import { CURRENCY_CODES } from "components/FormerEditor/common/constants/currency";
import { METADATA_FLEX_PRODUCT } from "utils/constants/stripe";
import { CREATE_PRODUCTS_SCHEMA } from "constants/validation";
import { validateSchema } from "./validate";
import { reorder } from "./drag";

export const getMaxPriceProduct = (products) => {
  const maxValues = products.map((product) => ({
    id: product.id,
    amount: maxBy(product.prices, "amount").amount
  }));

  const maxProduct = maxBy(maxValues, "amount");

  return products.find(({ id }) => id === maxProduct.id);
};

export const orderProducts = (products) => {
  const collection = [];

  products.forEach((product) => {
    if (collection.length === 0) {
      collection.push(product);
    } else {
      let insertionIndex;
      for (let colIx = 0; colIx < collection.length; colIx++) {
        const colItem = collection[colIx];
        const shouldInsert =
          product.prices[0].amount < colItem.prices[0].amount;
        if (shouldInsert) {
          insertionIndex = colIx;
          break;
        }
      }
      if (insertionIndex >= 0) {
        collection.splice(insertionIndex, 0, product);
      } else {
        throw new Error(`Invalid index :${insertionIndex}`);
      }
    }
  });

  return collection;
};

export const findProductForPrice = ({ products, id }) => {
  return products.find((product) => {
    return product.prices.find((price) => {
      return price.id === id;
    });
  });
};

export const productsForLayout = (products, layout) => {
  if (!layout) {
    return products;
  } else {
    return layout.reduce((memo, { uid }) => {
      const prodMatch = products.find((product) => product.uid === uid);
      if (prodMatch) {
        memo.push(prodMatch);
      }
      return memo;
    }, []);
  }
};

/**
 * Remove product
 * - remove the product from the layout will trigger a recompute
 * - related entity data will be reset
 */
export const removeProduct = ({
  values,
  uid,
  setValues,
  setFieldTouched,
  productsPath,
  layoutPath
}) => {
  return () => {
    const updatedLayouts = [...get(values, layoutPath, [])];
    const layoutMatchIndex = updatedLayouts.findIndex(
      (layout) => layout.uid === uid
    );
    if (layoutMatchIndex > -1) {
      updatedLayouts.splice(layoutMatchIndex, 1);
      set(values, layoutPath, updatedLayouts);
    }

    setValues(values);
    setFieldTouched(productsPath, true);
  };
};

export const resolvePrimariesLayoutOnProductDelete = ({
  products,
  values,
  setValues,
  setTouched
}) => {
  const touched = {
    [STATE_KEYS.MANIFEST.PRICING_PRIMARIES]: true,
    [STATE_KEYS.MANIFEST.LAYOUT]: true
  };
  const updatedValues = cloneDeep(values);

  const pricePrimaries = get(
    updatedValues,
    STATE_KEYS.MANIFEST.PRICING_PRIMARIES
  );
  const primariesWithoutProducts = pricePrimaries.filter(
    (primary) => !products.find(({ id }) => id === primary.product)
  );
  set(
    updatedValues,
    STATE_KEYS.MANIFEST.PRICING_PRIMARIES,
    primariesWithoutProducts
  );

  const layout = get(updatedValues, STATE_KEYS.MANIFEST.PRICING_LAYOUT);

  const layoutWithoutProducts = layout.filter(
    (layout) => !products.find(({ id }) => id === layout.uid)
  );

  set(updatedValues, STATE_KEYS.MANIFEST.PRICING_LAYOUT, layoutWithoutProducts);

  setValues(updatedValues);
  setTouched(touched);
};

export const productsNotInLayout = (products, layout = []) => {
  const layoutUIDs = layout.map(({ uid }) => uid);
  return products.filter((product) => layoutUIDs.indexOf(product.id) === -1);
};

export const getCreateProductsInitialValues = () => ({
  [FORM_KEY]: {
    mode: FORM_MODE.CREATE
  },
  products: [
    {
      name: "",
      description: "",
      prices: [getTemplatePrice()],
      images: []
    }
  ]
});

const prepareProductFromValues = ({
  name,
  description,
  id,
  images,
  uuid,
  prices,
  metadata
}) => {
  const result = {
    id,
    uuid,
    name
  };

  if (images && images.length) {
    result.images = images;
  }

  if (prices && prices.length > 0) {
    result.prices = prices.map((values) => preparePriceFromValues(values));
  }

  if (description) {
    result.description = description;
  }
  if (metadata) {
    result.metadata = processCreateMetadata(metadata);
  }

  return result;
};

export const prepareCreateProducts = (products) => {
  return omitAndSanitizeParams({
    products: products.map((values) => prepareProductFromValues(values))
  });
};

export const getUpdateProductParams = (initial, current) => {
  const result = reduceKeyDifferences(
    [
      "name",
      "description",
      "statement_descriptor",
      "active",
      "images",
      "tax_code"
    ],
    initial,
    current
  );

  /**
   * Process metadata - setting null where values are empty so that they are unset within Stripe
   * https://stripe.com/docs/api/products/update#update_product-metadata
   */
  result.metadata = processUpdateMetadata(initial.metadata, current.metadata);

  return result;
};

export const removeProductPrice = ({
  products,
  productIndex,
  priceIndex,
  setFieldValue,
  tracking
}) => () => {
  const updatedProducts = cloneDeep(products);
  const prodPrices = [...updatedProducts[productIndex].prices];
  prodPrices.splice(priceIndex, 1);

  updatedProducts[productIndex].prices = prodPrices;
  tracking && Analytics.trackEventWithData(tracking);
  setFieldValue("products", updatedProducts);
};

export const transformProductsPrices = (products = []) =>
  products.map((product) => {
    const result = {
      ...product
    };
    if (product.prices) {
      result.prices =
        product.prices &&
        product.prices.map((price) =>
          transformPrice(price, { amount: formattedPriceToUnitAmount(price) })
        );
    }
    return result;
  });

export const transformNewProductsPrices = (products = []) =>
  products.map((product) => {
    const productId = product.id || product.uuid;
    const result = {
      ...product,
      id: productId
    };

    if (product.prices) {
      result.prices =
        product.prices &&
        product.prices.map((price) =>
          transformPrice(price, {
            product: productId,
            amount: formattedPriceToUnitAmount(price)
          })
        );
    }
    return result;
  });

export const prepareProductShow = ({ product, prices }) => {
  const active = product.active;
  const result = {
    id: product.id,
    name: product.name,
    active,
    status: product.active ? PRODUCT_STATUS.ACTIVE : PRODUCT_STATUS.INACTIVE,
    adminUrl: getStripeAdminLink(product.livemode, `products/${product.id}`),
    createdAt: formatUTCTime(product.created * 1000)
  };

  if (product.description) {
    result.description = product.description;
  }

  if (product.images && product.images.length) {
    result.image = product.images[0];
  }

  if (product.metadata && product.metadata[INTEGRATION_SERVICE]) {
    result.service = product.metadata[INTEGRATION_SERVICE];
  }

  const currencies = [];
  for (let priceIx = 0; priceIx < prices.length; priceIx++) {
    const currency = prices[priceIx].currency;
    if (currency && currencies.indexOf(currency) === -1) {
      currencies.push(currency);
    }
  }

  result.currencies = currencies;
  const multipleCurrencies = currencies.length > 1;
  let pricesForRange = prices;
  if (multipleCurrencies) {
    // Should use currency for locale here
    const usdCode = CURRENCY_CODES.USD.toLowerCase();
    const filterCurrency =
      currencies.indexOf(usdCode) > -1 ? usdCode : currencies[0];
    pricesForRange = prices.filter(
      ({ currency }) => currency === filterCurrency
    );
  }

  result.priceRange = stripePricesRange(pricesForRange);

  return result;
};

export const getNonFlexProducts = (products) =>
  products.filter(({ metadata }) => !metadata[METADATA_FLEX_PRODUCT]);

export const reduceRecurringProducts = ({ prices, products }) =>
  prices
    .reduce((memo, price) => {
      if (price.recurring && memo.indexOf(price.product) === -1) {
        memo.push(price.product);
      }
      return memo;
    }, [])
    .reduce((memo, productId) => {
      const productMatch = products.find(({ id }) => id === productId);

      if (productMatch && !productMatch.metadata[METADATA_FLEX_PRODUCT]) {
        memo.push(productMatch);
      }
      return memo;
    }, []);

export const prepareProductList = ({ products, prices, shouldOrder }) => {
  let ctxModels = [];
  if (products) {
    ctxModels = shouldOrder
      ? orderBy(products, ["created"], ["desc"])
      : products;
  }

  ctxModels = getNonFlexProducts(ctxModels);

  return ctxModels.map((product) => {
    const productPrices = prices.filter(
      (price) => price.product === product.id
    );

    return {
      ...prepareProductShow({
        product,
        prices: productPrices
      }),
      prices: Object.keys(
        productPrices.reduce((memo, { id }) => {
          memo[id] = true;
          return memo;
        }, {})
      )
    };
  });
};

export const prepareEditProductInitialValues = (product) => {
  return {
    id: product.id,
    active: product.active,
    name: product.name,
    description: product.description || "",
    statement_descriptor: product.statement_descriptor || "",
    images: product.images,
    tax_code: product.tax_code || "",
    metadata: prepareMetadataFieldArray(product.metadata)
  };
};

export const orderedBuilderProducts = ({ builder, products }) => {
  const builderProdIds = uniq(
    builder.productPrices.map(({ product }) => product)
  );
  return builderProdIds.reduce((memo, productId) => {
    const prepMatch = products.find(({ id }) => id === productId);
    if (prepMatch) {
      memo.push(prepMatch);
    }

    return memo;
  }, []);
};

export const validateProductsSchema = async (values) => {
  let validResult = await validateSchema(CREATE_PRODUCTS_SCHEMA, values);

  values.products.forEach((product, prodIx) => {
    product.prices &&
      product.prices.forEach((_, priceIx) => {
        const rootPath = `products[${prodIx}].prices[${priceIx}]`;
        validResult = validatePrice(values, rootPath, validResult);
      });
  });

  return validResult;
};

export const reorderProductCollection = ({
  setFieldValue,
  setFieldTouched,
  products,
  productsPath,
  tracking
}) => ({ source, destination }) => {
  const droppedOffList = !destination;
  if (droppedOffList) {
    return;
  }
  tracking && Analytics.trackEventWithData(tracking);
  const {
    droppableId: destinationDropId,
    index: destinationIndex
  } = destination;
  const { droppableId: sourceDropId, index: sourceIndex } = source;

  const cachedPaymentLinks = cloneDeep(products);

  /**
   * Drop has happened between two lists so an object is being moved
   */
  const changedList = sourceDropId !== destinationDropId;

  if (!changedList) {
    const reorderedPaymentLinks = reorder(
      cachedPaymentLinks,
      sourceIndex,
      destinationIndex
    );
    setFieldValue(productsPath, reorderedPaymentLinks);
    setFieldTouched(productsPath, true);
  } else {
    console.error("List transfer not supported");
  }
};

export const addProductToCollection = ({
  values,
  setValues,
  setFieldTouched,
  template,
  productsPath
}) => {
  return () => {
    // Need to add to config
    const updatedPaymentLinks = [...get(values, productsPath, [])];

    updatedPaymentLinks.push(template);
    const nextIndex = updatedPaymentLinks.length - 1;
    set(values, productsPath, updatedPaymentLinks);
    set(values, STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX, nextIndex);

    setValues(values);
    setFieldTouched(productsPath, true);
  };
};

/**
 * Remove product
 * - remove the product from the layout will trigger a recompute
 * - related entity data will be reset
 */
export const removeProductFromCollection = ({
  values,
  touched,
  index,
  name,
  uuid,
  setValues,
  setFieldTouched,
  productsPath
}) => {
  return () => {
    if (index > -1) {
      const updatedPaymentLinks = [...get(values, productsPath, [])];
      updatedPaymentLinks.splice(index, 1);
      set(values, productsPath, updatedPaymentLinks);

      const productsCheckoutsPath = `${name}.${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_PRODUCTS_CHECKOUTS}`;
      const productConfigs = get(values, productsCheckoutsPath);

      if (productConfigs && productConfigs[uuid]) {
        delete productConfigs[uuid];
        set(values, productsCheckoutsPath, productConfigs);
      }

      setValues(values);
      if (!get(touched, productsPath)) {
        setFieldTouched(productsPath, true);
      }
    }
  };
};
