import { useMemo } from "react";
import { useRouter } from "next/router";
import {
  getAdminContext,
  getApplicationTheme,
  getAppUserCapabilities,
  getCtxAppResourceAndIDs,
  getCtxModelsForResourceAndUUID,
  getManifestLiveVariation,
  getRemainingUserEducationKeys,
  getUserEducation,
  inferModelsForResource,
  orderedApplications
} from "utils/mapStateToProps";
import {
  MODEL_RESOURCES,
  USER,
  APPLICATION,
  PAGE,
  ORGANIZATION
} from "utils/constants/models";
import { getAccountConnectStatusProps } from "utils/merchantAccount";
import { useSelector } from "react-redux";
import { getEnvCollection } from "components/FormerEditor/common";
import { useApplicationContext } from "./context";
import { SHORT_LINK_ACTION_UID } from "./constants/shortLink";
import { STATE_KEYS } from "./constants/state";
import get from "lodash/get";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import { EVENT_NAME } from "./constants/event";
import {
  goodStandingStatus,
  needsAttentionStatus,
  suspendedStatus
} from "./subscription";
import { getPaymentMethodOptions } from "./payment";
import { getModelIDFromQuery, reduceQueryParamReferences } from "./route";
import { getJWT } from "./auth";
import { APPLICATION_STATUS } from "./constants/application";
import { orderByDate } from "./date";
import { calculateProgress, currentRequiredOnboarding } from "./onboarding";
import { SETTINGS_TABS } from "./constants/settings";
import { prepareManifestList, filterPageShortLinks } from "./manifest";
import { MANIFEST_STATUS, RESTRICTED_FIELDS } from "./constants/manifest";
import { useFormikContext } from "formik";
import { getFieldPath } from "./form";
import { PAYMENT_MODE, SETUP_PAYMENT_METHODS } from "./constants/payment";
import { buildPlatformSubscriptionCheckoutParams } from "./checkout/actions";
import { ADMIN, READER } from "./constants/role";
import { getConfig } from "./env";
import { findLatestInsightAndControls } from "./insight";
import { SILENT, THIRD_PARTY_QUERY_PARAM_KEYS } from "utils/constants/query";
import { ENTITLEMENT_CONFIG_TYPE } from "./constants/entitlementConfig";
import { INCOMPLETE } from "utils/constants/status";
import { reduceRecurringProducts } from "./product";
import { MOCK_UUID } from "./constants/request";
import invert from "lodash/invert";
import { USER_CAPABILITY } from "./constants/capability";
import { useUserFeature } from "./hooks/capabilities";
import { ENTITY_TYPE } from "components/FormerEditor/common/constants/entity";
import { DEFAULT_TAX_IDS } from "./constants/taxRate";

/**
 * We set the env to be test when in marketing builder mode
 * - This allows us to filter by resources where !livemode
 * - Else we default te the NODE_ENV so that
 * -- development still works (i.e. getEnvCollection filters where NODE_ENV !== 'production')
 * -- vica versa -> production works
 */
export const useCtxEnv = (props) => {
  const appCtx = useApplicationContext();

  if (props && props.env) {
    return props.env;
  } else if (appCtx && appCtx.env) {
    return appCtx.env;
  } else {
    return process.env.NODE_ENV;
  }
};

/**
 * Application context is what most navigation is derived from. Reference from
 * - context
 * - query string
 * - store
 * @returns {Object<{
 * application: Object,
 * resourceId: String,
 * resourceUUID: String,
 * resource: String,
 * merchantAccount: Object,
 * merchantAccountUUID: Object,
 * customerId: String,
 * capability: Object,
 * counts: Object,
 * enabled: Object}>}} result
 */
export const useCtxReferences = () => {
  const router = useRouter();

  const appCtx = useApplicationContext();
  /**
   * Applications are ordered by resource priority
   * 0. Org apps
   * 1. User apps
   */
  const orderedApps = useOrderedApplications() || [];
  const paramAppUUID = getModelIDFromQuery(APPLICATION, router.query);

  let applicationUUID;
  if (appCtx && appCtx.application && appCtx.application.uuid) {
    applicationUUID = appCtx.application.uuid;
  } else if (paramAppUUID) {
    applicationUUID = paramAppUUID;
  } else if (Array.isArray(orderedApps) && orderedApps.length > 0) {
    applicationUUID = orderedApps[0].uuid;
  }

  return useSelector((state) =>
    getCtxAppResourceAndIDs(state, applicationUUID)
  );
};

export const useViewsForSubdomainKey = ({ subdomain, key }) => {
  /**
   * Computations are nested under a subdomain[key]
   * Where key is a uid for Manifestroute or ShortLink.(test_)short_id
   */
  const computations = useSelector((state) =>
    state.getIn(["computation", "data"])
  ).toJS();

  return computations[subdomain] && computations[subdomain][key]
    ? computations[subdomain][key]
    : [];
};

export const useLatestInsightAndControls = ({
  subdomain,
  type,
  filterData
}) => {
  const insights = useInsights();

  return findLatestInsightAndControls({
    subdomain,
    insights,
    type,
    filterData
  });
};

export const useShortLink = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["shortLink", "data"])
      .find((shortLink) => shortLink.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useShortLinks = () =>
  useSelector((state) => state.getIn(["shortLink", "data"]).toJS());

export const useSubscriptions = () =>
  useSelector((state) => state.getIn(["subscription", "data"]).toJS());

export const useApplications = () =>
  useSelector((state) => state.getIn(["application", "data"]).toJS());

export const useMockApplication = () =>
  useSelector((state) => {
    const apps = state.getIn(["application", "data"]).toJS();
    return apps.find(({ uuid }) => uuid === MOCK_UUID);
  });

export const useAppData = () =>
  useSelector((state) => state.getIn(["app", "data"]).toJS());

export const useAppCheckout = (key) => {
  const checkout = useSelector((state) =>
    state.getIn(["app", "data", "checkout"]).toJS()
  );
  return key ? get(checkout, key) : checkout;
};

export const useCapabilities = () => {
  const { resource, resourceUUID } = useCtxReferences();

  return useSelector((state) => {
    const resourceCapability = state
      .getIn(["capability", "data"])
      .find(
        (capability) =>
          capability.get("resource") === resource &&
          capability.get("resourceUUID") === resourceUUID
      );
    return resourceCapability ? resourceCapability.toJS() : null;
  });
};

export const useAppUserCapabilities = () =>
  useSelector((state) => getAppUserCapabilities(state));

export const useGoodStandingSubscriptions = () =>
  useSelector((state) => {
    const models = state.getIn(["subscription", "data"]).toJS();
    return models.filter(({ raw }) => raw && goodStandingStatus(raw.status));
  });

export const useReducerLoading = (reducer) =>
  useSelector((state) => Boolean(state.getIn([reducer, "isLoading"])));

export const useAppAuthLoading = () =>
  useSelector(
    (state) =>
      Boolean(state.getIn(["auth", "isLoading"])) ||
      Boolean(state.getIn(["app", "isLoading"]))
  );

export const useNeedsAttentionSubscriptions = () =>
  useSelector((state) => {
    const models = state.getIn(["subscription", "data"]).toJS();
    return models.filter(({ raw }) => raw && needsAttentionStatus(raw.status));
  });

export const useSuspendedSubscriptions = () =>
  useSelector((state) => {
    const models = state.getIn(["subscription", "data"]).toJS();
    return models.filter(({ raw }) => raw && suspendedStatus(raw.status));
  });

export const useVariables = () =>
  useSelector((state) => state.getIn(["variable", "data"]).toJS());

export const useCoupon = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["coupon", "data"])
      .find((coupon) => coupon.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useEvents = (name, key = "name") =>
  useSelector((state) => {
    const events = state.getIn(["event", "data"]);

    return name
      ? events.filter((event) => event.get(key) === name).toJS()
      : events.toJS();
  });

export const useEvent = (value, key = "id") =>
  useSelector((state) => {
    const event = state
      .getIn(["event", "data"])
      .find((data) => data.get(key) === value);

    return event ? event.toJS() : null;
  });

export const useAssocMetadata = ({ id, key, applicationId }) =>
  useSelector((state) => {
    const metadatas = state.getIn(["metadata", "data"]);
    const match = metadatas.find((metadata) => {
      return (
        metadata.get(key) === id &&
        metadata.get("application_id") === applicationId
      );
    });

    return match ? match.toJS() : null;
  });

/**
 * Get all the metadatas which have defined routes
 */
export const useAppRouteMetadatas = (appId) => {
  const { application } = useCtxReferences();
  const ctxAppId = application ? application.id : appId;

  return useSelector((state) => {
    const matches = state.getIn(["metadata", "data"]).filter((metadata) => {
      const route = metadata.get("route");

      return Boolean(route) && metadata.get("application_id") === ctxAppId;
    });

    return matches && matches.count() ? matches.toJS() : [];
  });
};

export const useAppEntities = () =>
  useSelector((state) => state.getIn(["app", "data", "entities"]).toJS());

export const useGroupedAppEntities = () => {
  const entities = useSelector((state) =>
    state.getIn(["app", "data", "entities"]).toJS()
  );

  const primitives = useMemo(
    () => entities.filter(({ type }) => type === ENTITY_TYPE.PRIMITIVE),
    []
  );

  /**
   * Some specialized fields are restricted to beta accounts
   * - e.g. Identity is currently platform only
   */
  const identityEnabled = useUserFeature(USER_CAPABILITY.IDENTITY);
  const uploadEnabled = useUserFeature(USER_CAPABILITY.UPLOAD);
  const addressEnabled = useUserFeature(USER_CAPABILITY.ADDRESS);
  const specialized = useMemo(
    () =>
      entities.filter(({ type, uid }) => {
        let canUse = type === ENTITY_TYPE.SPECIALIZED;
        if (RESTRICTED_FIELDS.IDENTITY === uid && !identityEnabled) {
          canUse = false;
        } else if (RESTRICTED_FIELDS.FILE === uid && !uploadEnabled) {
          canUse = false;
        } else if (RESTRICTED_FIELDS.ADDRESS === uid && !addressEnabled) {
          canUse = false;
        }
        return canUse;
      }),
    []
  );

  return {
    primitives,
    specialized
  };
};

export const useAppVariables = () =>
  useSelector((state) => state.getIn(["app", "data", "variables"]).toJS());

export const useAppTemplates = () =>
  useSelector((state) => state.getIn(["app", "data", "templates"]).toJS());

export const useAppBuilder = () =>
  useSelector((state) => state.getIn(["app", "data", "builder"]).toJS());

export const useApplicationTheme = () => {
  const router = useRouter();
  const appCtx = useApplicationContext();
  const applicationUUID = appCtx
    ? appCtx.application.uuid
    : getModelIDFromQuery(APPLICATION, router.query);

  return useSelector((state) =>
    getApplicationTheme(state, { applicationUUID })
  );
};

export const useAppAdmin = (uuid, manifestUUID) => {
  const query = {};
  if (uuid) {
    query.applicationUUID = uuid;
  }
  if (manifestUUID) {
    query.manifestUUID = manifestUUID;
  }

  return useSelector((state) => getAdminContext(state, query));
};

export const useSale = (id, key = "id") =>
  useSelector((state) => {
    const saleMatch = state
      .getIn(["event", "data"])
      .find(
        (event) =>
          event.get(key) === id &&
          event.get("name") === EVENT_NAME.MERCHANT_ACCOUNT_CHECKOUT_COMPLETED
      );
    return saleMatch ? saleMatch.toJS() : null;
  });

export const usePayment = (id) =>
  useSelector((state) => {
    const modelMatch = state
      .getIn(["payment", "data"])
      .find((event) => event.get("id") === id);
    return modelMatch ? modelMatch.toJS() : null;
  });

export const useSession = (id) =>
  useSelector((state) => {
    const modelMatch = state
      .getIn(["session", "data"])
      .find((event) => event.get("id") === id);
    return modelMatch ? modelMatch.toJS() : null;
  });

export const useProduct = (id) => {
  const env = useCtxEnv();
  return useSelector((state) => {
    const products = getEnvCollection(
      state.getIn(["product", "data"]).toJS(),
      env
    );
    const prices = getEnvCollection(state.getIn(["price", "data"]).toJS(), env);
    const productMatch = products.find((product) => product.id === id);
    const productPrices = productMatch
      ? prices.filter((price) => price.product === productMatch.id)
      : [];

    return productMatch
      ? {
          ...productMatch,
          prices: productPrices
        }
      : null;
  });
};

export const useMerchantAccount = ({ uuid }) => {
  const merchantAccount = useSelector((state) =>
    state
      .getIn(["merchantAccount", "data"])
      .find((merchantAccount) => merchantAccount.get("uuid") === uuid)
  );

  return merchantAccount && merchantAccount.toJS();
};

export const useManifest = (id, key = "uuid") => {
  const model = useSelector((state) =>
    state
      .getIn(["manifest", "data"])
      .find((manifest) => manifest.get(key) === id)
  );

  return model && model.toJS();
};

export const useManifests = () =>
  useSelector((state) => state.getIn(["manifest", "data"]).toJS());

export const useMetadatas = () =>
  useSelector((state) => state.getIn(["metadata", "data"]).toJS());

export const useActiveManifestsForApplicationRoute = (route) => {
  const result = [];
  const { application } = useCtxReferences();
  const manifests = useSelector((state) =>
    state.getIn(["manifest", "data"]).toJS()
  );
  const config = application.routes[route];
  const activeRouteWithVariations =
    config &&
    config.status === APPLICATION_STATUS.ACTIVE &&
    Array.isArray(config.variations) &&
    config.variations.length > 0;

  if (activeRouteWithVariations) {
    config.variations
      .reduce((memo, { manifest: id }) => {
        if (memo.indexOf(id) === -1) {
          memo.push(id);
        }
        return memo;
      }, [])
      .forEach((id) => {
        const match = manifests.find((model) => model.id === id);
        if (match) {
          result.push(match);
        }
      });
  }

  return result;
};

export const useFeatureGroups = () => {
  const { resource } = useCtxReferences();

  return useSelector((state) => {
    return getCtxModelsForResourceAndUUID(
      state,
      MODEL_RESOURCES.FEATURE_GROUP,
      resource
    );
  });
};

export const useFeatureGroupFeatures = () => {
  const { resource } = useCtxReferences();

  return useSelector((state) => ({
    features: getCtxModelsForResourceAndUUID(
      state,
      MODEL_RESOURCES.FEATURE,
      resource
    ),
    featureGroups: getCtxModelsForResourceAndUUID(
      state,
      MODEL_RESOURCES.FEATURE_GROUP,
      resource
    )
  }));
};

export const getCheckoutTracking = ({ state, router }) => {
  const appUUID =
    router.query.application || getModelIDFromQuery(APPLICATION, router.query);
  const pageUUID = router.query.page || getModelIDFromQuery(PAGE, router.query);
  /**
   * Pick params for 3rd parties like
   * - Google
   * - Rewardful
   * Pick silent tracking param
   */
  const result = pick(router.query, [SILENT, ...THIRD_PARTY_QUERY_PARAM_KEYS]);

  /**
   * These values are set for when the User is within the authed page builder
   * - Usually values for manifest, subdomain, alias etc. are parsed from {manifest, config} response on /render
   * - But in page builder mode there is no render call, so pull these values from local store if found
   */
  const manifest = state
    .getIn(["manifest", "data"])
    .find((model) => model.get("uuid") && model.get("uuid") === pageUUID);

  if (manifest) {
    result.manifest_id = manifest.get("id");
  }
  const application = state
    .getIn(["application", "data"])
    .find((model) => model.get("uuid") && model.get("uuid") === appUUID);
  const userUUID = state.getIn(["user", "data", "uuid"]);
  if (userUUID) {
    result.user_uuid = userUUID;
  }
  if (application) {
    result.subdomain = application.get("subdomain");
  }
  if (manifest && application) {
    const liveVariation = getManifestLiveVariation({
      id: manifest.get("id"),
      application: application.toJS()
    });
    if (liveVariation) {
      result.route = liveVariation.route;
    }
  }

  return result;
};

/**
 * Build the tracking object which will be set in page context and then forwarded in checkout request
 */
export const useCheckoutTracking = () => {
  const router = useRouter();

  const result = useSelector((state) =>
    getCheckoutTracking({
      router,
      state
    })
  );

  return result;
};

export const useMerchantAccounts = () =>
  useSelector((state) => state.getIn(["merchantAccount", "data"]).toJS());

export const useUserMerchantAccounts = () =>
  useSelector((state) => state.getIn(["userMerchantAccount", "data"]).toJS());

export const useOrgMerchantAccounts = () =>
  useSelector((state) =>
    state.getIn(["organizationMerchantAccount", "data"]).toJS()
  );

export const useResourceApiKeys = () => {
  const { resource, resourceUUID } = useCtxReferences();
  if (resource === USER) {
    return useSelector((state) =>
      state.getIn(["user", "data", "api_keys"]).toJS()
    );
  } else {
    return useSelector((state) => {
      const org = state
        .getIn(["organization", "data"])
        .find((model) => model.get("uuid") === resourceUUID);
      if (org && org.get("api_keys")) {
        return org.get("api_keys").toJS();
      } else {
        return [];
      }
    });
  }
};

export const useResourceSubdomains = () => {
  const { resource, resourceUUID } = useCtxReferences();

  if (resource === USER) {
    return useSelector((state) =>
      state.getIn(["user", "data", "subdomains"])
        ? state.getIn(["user", "data", "subdomains"]).toJS()
        : []
    );
  } else {
    return useSelector((state) => {
      const org = state
        .getIn(["organization", "data"])
        .find((model) => model.get("uuid") === resourceUUID);
      if (org && org.get("subdomains")) {
        return org.get("subdomains").toJS();
      } else {
        return [];
      }
    });
  }
};

export const useInferModelsForResource = (modelResource) =>
  useSelector((state) => inferModelsForResource(state, modelResource));

export const useProductPrices = (uid) => {
  if (!uid) {
    throw new Error("No id provided to useProductPrices");
  }
  const env = useCtxEnv();
  return useSelector((state) => {
    return getEnvCollection(state.getIn(["price", "data"]).toJS(), env).filter(
      (price) => price.product === uid
    );
  });
};

export const useProductsPrices = (props) => {
  const env = useCtxEnv(props);

  return useSelector((state) => ({
    products: getEnvCollection(state.getIn(["product", "data"]).toJS(), env),
    prices: getEnvCollection(state.getIn(["price", "data"]).toJS(), env)
  }));
};

export const useRecurringProducts = (props) => {
  const env = useCtxEnv(props);

  const { products, prices } = useSelector((state) => ({
    products: getEnvCollection(state.getIn(["product", "data"]).toJS(), env),
    prices: getEnvCollection(state.getIn(["price", "data"]).toJS(), env)
  }));

  return reduceRecurringProducts({
    products,
    prices
  });
};

export const useCouponPromotionCodes = (props) => {
  const env = useCtxEnv(props);

  return useSelector((state) => ({
    coupons: getEnvCollection(state.getIn(["coupon", "data"]).toJS(), env),
    promotionCodes: getEnvCollection(
      state.getIn(["promotionCode", "data"]).toJS(),
      env
    )
  }));
};

export const useTaxRates = (props) => {
  const env = useCtxEnv(props);

  return useSelector((state) =>
    getEnvCollection(state.getIn(["taxRate", "data"]).toJS(), env)
  );
};

export const useTaxRate = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["taxRate", "data"])
      .find((taxRate) => taxRate.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useShippingRates = (props) => {
  const env = useCtxEnv(props);

  return useSelector((state) =>
    getEnvCollection(state.getIn(["shippingRate", "data"]).toJS(), env)
  );
};

export const useShippingRate = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["shippingRate", "data"])
      .find((shippingRate) => shippingRate.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useAppShortLinks = () => {
  const { resource, application } = useCtxReferences();

  const shortLinks = useSelector((state) => {
    return getCtxModelsForResourceAndUUID(
      state,
      MODEL_RESOURCES.SHORT_LINK,
      resource
    );
  });
  const appId = application && application.id;

  return shortLinks.filter(
    ({ data: { application_id } }) => application_id === appId
  );
};

export const useAppManifests = () => {
  const { resource } = useCtxReferences();

  const manifests = useSelector((state) => {
    return getCtxModelsForResourceAndUUID(
      state,
      MODEL_RESOURCES.MANIFEST,
      resource
    );
  });

  return manifests;
};

export const useAppContactlessConfigs = () => {
  const { resource } = useCtxReferences();

  const manifests = useSelector((state) => {
    return getCtxModelsForResourceAndUUID(
      state,
      MODEL_RESOURCES.CONTACTLESS_CONFIG,
      resource
    );
  });

  return manifests;
};

export const useAppEntitlementConfigs = () => {
  const { resource } = useCtxReferences();

  const manifests = useSelector((state) => {
    return getCtxModelsForResourceAndUUID(
      state,
      MODEL_RESOURCES.ENTITLEMENT_CONFIG,
      resource
    );
  });

  return manifests;
};

export const useAttachments = () =>
  useSelector((state) => state.getIn(["attachment", "data"]).toJS());

export const useAttachment = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["attachment", "data"])
      .find((attachment) => attachment.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useAppPagesTemplatesIndex = () =>
  useSelector((state) =>
    state.getIn(["app", "data", "pages", "templates", "index"]).toJS()
  );

export const useAppPagesTemplate = (uuid) =>
  useSelector((state) => {
    const item = state.getIn([
      "app",
      "data",
      "pages",
      "templates",
      "data",
      uuid
    ]);
    return item ? item.toJS() : null;
  });

export const useCampaigns = () =>
  useSelector((state) => state.getIn(["campaign", "data"]).toJS());

export const useLegalContents = () =>
  useSelector((state) => state.getIn(["legalContent", "data"]).toJS());

export const useWorkflows = () =>
  useSelector((state) => state.getIn(["workflow", "data"]).toJS());

export const useWebhooks = () =>
  useSelector((state) => state.getIn(["webhook", "data"]).toJS());

export const useLegalContent = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["legalContent", "data"])
      .find((legalContent) => legalContent.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useEditableContents = () =>
  useSelector((state) => state.getIn(["editableContent", "data"]).toJS());

export const useEditableContent = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["editableContent", "data"])
      .find((editableContent) => editableContent.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useContactlessConfigs = () =>
  useSelector((state) => state.getIn(["contactlessConfig", "data"]).toJS());

export const useCampaign = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["campaign", "data"])
      .find((campaign) => campaign.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useDatasources = () =>
  useSelector((state) => state.getIn(["datasource", "data"]).toJS());

export const useDatasource = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["datasource", "data"])
      .find((datasource) => datasource.get(key) === id);
    return match ? match.toJS() : null;
  });

export const useModelReport = (model, id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["report", "data"])
      .find(
        (report) => report.get("model") === model && report.get(key) === id
      );
    return match ? match.toJS() : null;
  });

export const useAudiences = () =>
  useSelector((state) => state.getIn(["audience", "data"]).toJS());

export const useAudience = (id, key = "id") =>
  useSelector((state) => {
    const match = state
      .getIn(["audience", "data"])
      .find((audience) => audience.get(key) === id);
    return match ? match.toJS() : null;
  });

const getSelections = (selections, models) =>
  selections && Array.isArray(selections)
    ? selections.reduce((memo, code) => {
        const modelMatch = models.find((model) => model.code === code);

        if (modelMatch) {
          memo.push({
            name: modelMatch.name,
            id: modelMatch.code
          });
        }
        return memo;
      }, [])
    : [];

/**
 *
 * @param {Array} selections - collection of country codes (e.g. US, IE etc.)
 */
export const useCountrySelections = (selections = []) => {
  const countriesState = useSelector((state) =>
    state.getIn(["app", "data", "checkout", "countries"])
  );
  const countries = countriesState ? countriesState.toJS() : [];
  let options = [];
  let countrySelections = [];
  if (Array.isArray(countries) && countries.length > 0) {
    options = countries.map(({ name, code }) => ({
      name,
      id: code
    }));
    countrySelections = getSelections(selections, countries);
  }

  return {
    options,
    selections: countrySelections
  };
};

export const preparePaymentMethodSelections = (props) => {
  const { selections, account, mode, paymentMethods } = props;
  // Add test around this input here
  let options = [];
  if (!isEmpty(account) && paymentMethods) {
    const { available, unavailable } = getPaymentMethodOptions({
      account,
      options: paymentMethods
    });
    options = [...available, ...unavailable];
  }

  /**
   * Only allow subset of options when payment mode is setup
   */
  if (mode === PAYMENT_MODE.SETUP) {
    options = options.filter(
      ({ id }) => SETUP_PAYMENT_METHODS.indexOf(id) > -1
    );
  }

  return {
    options,
    selections: selections.reduce((memo, paymentMethod) => {
      const optionMatch = options.find(({ id }) => id === paymentMethod);
      if (optionMatch) {
        memo.push(optionMatch);
      }
      return memo;
    }, [])
  };
};

export const usePaymentMethodSelections = (selections = [], name) => {
  const { merchantAccount } = useCtxReferences();
  const { values } = useFormikContext();
  const modePath = getFieldPath(name, "mode");
  const mode = get(values, modePath);
  const account = get(merchantAccount, "account");

  const paymentMethodsState = useSelector((state) =>
    state.getIn(["app", "data", "checkout", "paymentMethods"])
  );
  const paymentMethods = paymentMethodsState
    ? paymentMethodsState.toJS()
    : null;

  return preparePaymentMethodSelections({
    selections,
    account,
    mode,
    paymentMethods
  });
};

export const useStateNameMap = () => {
  const statesState = useSelector((state) =>
    state.getIn(["app", "data", "checkout", "states"])
  );
  const states = statesState ? statesState.toJS() : [];
  return useMemo(() => {
    const nameMap = states.reduce((memo, state) => {
      if (state.name) {
        memo[state.name.toLowerCase()] = state.code;
      }
      return memo;
    }, {});
    return {
      data: nameMap,
      inverted: invert(nameMap)
    };
  }, [states.length]);
};

export const useStateSelections = (selections = []) => {
  const statesState = useSelector((state) =>
    state.getIn(["app", "data", "checkout", "states"])
  );
  const states = statesState ? statesState.toJS() : [];
  let options = [];
  let stateSelections = [];
  if (Array.isArray(states) && states.length > 0) {
    options = states.map(({ name, code }) => ({
      name,
      id: code
    }));
    stateSelections = getSelections(selections, states);
  }

  return {
    options,
    selections: stateSelections
  };
};

export const useTaxIds = () => {
  const data = useSelector((state) =>
    state.getIn(["app", "data", "checkout", "taxIds"])
  );

  return data ? data.toJS() : DEFAULT_TAX_IDS;
};

export const useIntegration = (id, key = "uuid") => {
  const model = useSelector((state) =>
    state
      .getIn(["integration", "data"])
      .find((integration) => integration.get(key) === id)
  );

  return model && model.toJS();
};

export const useIntegrations = () =>
  useSelector((state) => state.getIn(["integration", "data"]).toJS());

export const useIntegrationForService = (service) =>
  useSelector((state) => {
    const integrationMatch = state
      .getIn(["integration", "data"])
      .find((integration) => integration.get("service") === service);
    return integrationMatch ? integrationMatch.toJS() : null;
  });

export const useIntegrationsForService = (service) =>
  useSelector((state) => {
    const integrationMatch = state
      .getIn(["integration", "data"])
      .filter((integration) => integration.get("service") === service);
    return integrationMatch ? integrationMatch.toJS() : null;
  });

export const useProductPaymentLinks = (uid) => {
  const shortLinks = useAppShortLinks();

  return shortLinks.reduce((memo, shortLink) => {
    if (shortLink.data.action_uid === SHORT_LINK_ACTION_UID.MERCHANT_CHECKOUT) {
      const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
      const productMatch = primaries.find(({ product }) => product === uid);
      if (productMatch) {
        memo.push(shortLink);
      }
    } else {
      throw new Error(`Unsupport action: ${shortLink.data.action_uid}`);
    }
    return memo;
  }, []);
};

export const useInsights = () =>
  useSelector((state) => state.getIn(["insight", "data"])).toJS();

export const useOrderedApplications = () =>
  useSelector((state) => orderedApplications(state));

export const useAuthLoading = () =>
  useSelector((state) => Boolean(state.getIn(["auth", "isLoading"])));

export const useUser = () =>
  useSelector((state) => state.getIn(["user", "data"]).toJS());

export const useUserId = () =>
  useSelector((state) => state.getIn(["user", "data", "id"]));

export const useUserConfirmed = () =>
  useSelector((state) => state.getIn(["user", "data", "confirmed"]));

export const useIsAuthed = () =>
  useSelector((state) => {
    const userId = state.getIn(["user", "data", "id"]);
    return Boolean(userId && getJWT());
  });

export const useForceFetchShortLinkResources = (shortLink) => {
  const {
    products: currentProducts,
    prices: currentPrices
  } = useProductsPrices();

  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
  const missingResources = primaries.reduce((memo, primary) => {
    const productMatch = currentProducts.find(
      ({ id }) => id === primary.product
    );
    const priceMatch = currentPrices.find(({ id }) => id === primary.price);

    if (!productMatch || !priceMatch) {
      memo.push(primary);
    }

    return memo;
  }, []);

  return Boolean(
    Array.isArray(missingResources) && missingResources.length > 0
  );
};

export const useQueryParamReferences = () => {
  const { query } = useRouter();
  return reduceQueryParamReferences(query);
};

export const useAccountConnectStatusProps = () => {
  const { merchantAccount } = useCtxReferences();

  return getAccountConnectStatusProps(merchantAccount);
};

export const useRemainingEducationKeys = (keys = []) =>
  useSelector((state) => getRemainingUserEducationKeys(state, keys));

export const useEducation = (keys) =>
  useSelector((state) => getUserEducation(state, keys));

export const useLatestManifest = () => {
  const manifests = useSelector((state) =>
    state.getIn(["manifest", "data"]).toJS()
  );

  return orderByDate(manifests, "created_at", "desc")[0];
};

export const useOnboardingProgress = () => {
  const onboardings = useSelector((state) =>
    state.getIn(["onboarding", "data"]).toJS()
  );

  return calculateProgress(onboardings);
};

export const useAnyIncompleteOnboardings = () => {
  let anyRemain = false;
  useSelector((state) => {
    const onbs = state.getIn(["onboarding", "data"]);
    const incompleteMatch = onbs.find(
      (onb) => onb.get("status") === INCOMPLETE
    );
    anyRemain = Boolean(incompleteMatch);
  });
  return anyRemain;
};

export const useAppOnboardingConfig = () =>
  useSelector((state) => state.getIn(["app", "data", "onboarding"]).toJS());

export const useCurrentOnboarding = () => {
  let currentOnboarding;
  const appOnboardingConfig = useAppOnboardingConfig();
  const onboardings = useSelector((state) =>
    state.getIn(["onboarding", "data"]).toJS()
  );

  const getRequiredOnboarding = Boolean(
    appOnboardingConfig && Array.isArray(onboardings) && onboardings.length > 0
  );

  if (getRequiredOnboarding) {
    currentOnboarding = currentRequiredOnboarding(
      appOnboardingConfig,
      onboardings
    );
  }

  return currentOnboarding;
};

/**
 * App Settings section
 * * Optionally useUserFeature to control what settings they see based on defined server side capabilities
 */
export const useSettingSections = () => {
  const sections = [
    SETTINGS_TABS.MERCHANT_ACCOUNTS,
    SETTINGS_TABS.BILLING,
    SETTINGS_TABS.CHECKOUT,
    SETTINGS_TABS.BRANDING,
    SETTINGS_TABS.DEVELOPMENT,
    SETTINGS_TABS.APPLICATION,
    SETTINGS_TABS.CONTACTLESS,
    SETTINGS_TABS.EMAIL,
    SETTINGS_TABS.INTEGRATIONS
  ];

  return sections;
};

export const useLiveAppManifests = () => {
  const { application } = useCtxReferences();
  const manifests = useAppManifests();

  return prepareManifestList({
    manifests,
    application,
    shouldOrder: true
  }).filter(({ status }) => status === MANIFEST_STATUS.LIVE);
};

export const useSeparatedAppManifests = () => {
  const { application } = useCtxReferences();
  const manifests = useAppManifests();

  return prepareManifestList({
    manifests,
    application,
    shouldOrder: true
  }).reduce(
    (memo, manifest) => {
      memo.total += 1;
      if (manifest.status === MANIFEST_STATUS.LIVE) {
        memo.live.push(manifest);
      } else {
        memo.offline.push(manifest);
      }

      return memo;
    },
    {
      live: [],
      offline: [],
      total: 0
    }
  );
};

export const useManifestShortLinks = (values) => {
  const ctx = useFormikContext();
  const ctxValues = values && values.manifest ? values : ctx.values;
  const shortLinks = useAppShortLinks();
  return filterPageShortLinks(ctxValues, shortLinks);
};

export const usePlatformSubscriptionCheckoutParams = () => {
  const { customerId, resource, resourceUUID } = useCtxReferences();

  return buildPlatformSubscriptionCheckoutParams({
    customer: customerId,
    resource: resource,
    resourceUUID: resourceUUID
  });
};

export const useMerchantAccountConfig = () => {
  const { merchantAccount } = useCtxReferences();
  return get(merchantAccount, "merchant_account_config", {});
};

/**
 * If the user is an organization user then determine their role
 * else assume individuals are admins
 */
export const useUserRole = () => {
  const { resource } = useCtxReferences();
  const orgUserRole = useSelector(
    (state) =>
      state.getIn(["user", "data", "organization_users", 0, "role"]) || READER
  );

  return resource === ORGANIZATION ? orgUserRole : ADMIN;
};

export const useMerchantAccountRole = () => {
  const { resource } = useCtxReferences();

  const orgUserRole = useSelector((state) => {
    const orgUser = state.getIn(["user", "data", "organization_users", 0]);

    if (orgUser) {
      return orgUser.getIn(["organization_user_merchant_accounts", 0, "role"]);
    }
  });

  return resource === ORGANIZATION ? orgUserRole || READER : ADMIN;
};

export const useResourcePolicyRole = (modelResource) => {
  const { resource } = useCtxReferences();

  const orgUserRole = useSelector((state) => {
    const orgUser = state.getIn(["user", "data", "organization_users", 0]);

    if (orgUser) {
      return orgUser.getIn([`organization_user_${modelResource}s`, 0, "role"]);
    }
  });

  return resource === ORGANIZATION ? orgUserRole || READER : ADMIN;
};

export const useCommonEntitlementConfigData = () => {
  const entitlementConfigs = useAppEntitlementConfigs();

  const entitlementConfig = entitlementConfigs.find(
    ({ type }) => type === ENTITLEMENT_CONFIG_TYPE.COMMON
  );

  return entitlementConfig ? entitlementConfig.data : null;
};

/**
 * Short circuit for the platform organization
 */
export const useHasTestClientKey = () => {
  const ctxReferences = useCtxReferences();
  const org = useSelector((state) => state.getIn(["organization", "data", 0]));
  let hasTestKeys = false;

  if (
    ctxReferences &&
    ctxReferences.merchantAccount &&
    ctxReferences.resource
  ) {
    const { merchantAccount, resource } = ctxReferences;

    /**
     * Short circuit for app platform account
     */
    if (resource === ORGANIZATION && org) {
      hasTestKeys = org.get("subdomain") === getConfig("APP_SUBDOMAIN");
      if (hasTestKeys) {
        return hasTestKeys;
      }
    }

    hasTestKeys = Boolean(merchantAccount && merchantAccount.test_client_key);
  }
  return hasTestKeys;
};
