import { isSm, unfixBody, fixBody } from "utils/view";

class Scroll {
  constructor(props = { y: 0 }) {
    this._scrollY = props.y;
  }

  top() {
    if (isSm()) {
      /**
       * 1. Cache Y position first
       * 2. Then add class
       */
      this._scrollY = window && window.scrollY;
      this.fix && this.fix();
      this.to && this.to(0, 0);
    }
  }

  reset() {
    if (isSm()) {
      /**
       * 1. Remove class
       * 2. Then reset Y position
       */
      this.unfix();
      this.to(0, this._scrollY);
    }
  }

  /**
   * Add fixed position class to help with overscroll position when mobile
   */
  fix() {
    this._scrollY = window && window.scrollY;
    fixBody();
  }

  /**
  /**
   * Remove fixed position class
   */
  unfix() {
    unfixBody();
    this.to(0, this._scrollY);
  }

  to(x = 0, y = 0) {
    typeof window !== "undefined" && window.scrollTo(x, y);
  }
}

export default Scroll;

export const DESKTOP_SCROLL_CONTAINER = "desktopScrollContainer";

export const scrollTopForId = (id, options) => {
  if (typeof document !== "undefined") {
    setTimeout(() => {
      const target = document.getElementById(id);

      if (target && target.scrollTop > 0) {
        target.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
          ...options
        });
      }
    }, 0);
  }
};

export const scrollWindowTop = (id) => {
  if (typeof document !== "undefined") {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
};

export const scrollIntoViewForId = (id) => {
  if (typeof document !== "undefined") {
    setTimeout(() => {
      const target = document.getElementById(id);

      target && target.scrollIntoView(id);
    }, 0);
  }
};
