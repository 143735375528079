export const devLogger = (message, data, prettyPrint = true) => {
  // if (process.env.NODE_ENV === "development") {
  //   console.log(
  //     `[${message}]`,
  //     typeof data === "object" && prettyPrint
  //       ? JSON.stringify(data, null, 2)
  //       : data || ""
  //   );
  // }
};
