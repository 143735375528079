import C from "constants/product";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { setPrices } from "./price";
import { REQUEST_METHOD } from "utils/constants/request";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_PRODUCT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setProducts = (payload) => {
  return {
    type: C.SET_PRODUCTS,
    payload
  };
};

export const replaceProducts = (payload) => {
  return {
    type: C.REPLACE_PRODUCTS,
    payload
  };
};

export const removeProducts = (payload) => {
  return {
    type: C.REMOVE_PRODUCTS,
    payload
  };
};

/**
 * resetProductNotes
 */
export function resetProductNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_PRODUCT_STORE,
      payload
    });
  };
}

export function showProductNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_PRODUCT_STORE, message || `Success!`)
    );
  };
}

/**
 * createMerchantAccountProducts
 */
export function createMerchantAccountProducts(
  { resourceUUID, resource, uuid, data: requestData },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_PRODUCT_STORE,
        "get",
        "app",
        "Create merchant account product"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/products`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data: requestData,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const { products, prices } = data;
        const actions = [ActionHelpers.clearLoader(C.UPDATE_PRODUCT_STORE)];
        if (products.length) {
          actions.push(setProducts(products));
        }
        if (prices.length) {
          actions.push(setPrices(prices));
        }

        dispatch(batchActions(actions));
        callback(null, { products, prices });
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRODUCT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * createMerchantAccountProductsFromIntegrationImport
 */
export function createMerchantAccountProductsFromIntegrationImport(
  { resourceUUID, resource, uuid, data: requestData },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_PRODUCT_STORE,
        "get",
        "app",
        "Create merchant account product from integration import"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/products/import`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data: requestData,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const { products, prices } = data;
        const actions = [ActionHelpers.clearLoader(C.UPDATE_PRODUCT_STORE)];
        if (products.length) {
          actions.push(setProducts(products));
          actions.push(
            ActionHelpers.successNote(
              C.UPDATE_PRODUCT_STORE,
              `Imported ${products.length} products.`
            )
          );
        }
        if (prices.length) {
          actions.push(setPrices(prices));
        }

        dispatch(batchActions(actions));
        callback(null, { products, prices });
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRODUCT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * updateMerchantAccountProducts
 */
export const updateMerchantAccountProducts = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/products`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { products } }) => {
        const productCount = products.length;
        if (productCount > 0) {
          const noteMessage = `Updated`;
          dispatch(
            batchActions([
              setProducts(products),
              ActionHelpers.successNote(C.UPDATE_PRODUCT_STORE, noteMessage)
            ])
          );
        }
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRODUCT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * deleteMerchantAccountProducts
 */
export const deleteMerchantAccountProducts = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/products`;

    return axios({
      method: REQUEST_METHOD.DELETE,
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { products } }) => {
        if (products.length > 0) {
          dispatch(batchActions([removeProducts(data.products)]));
        }
        callback(null, products);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRODUCT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * archiveMerchantAccountProducts
 */
export const archiveMerchantAccountProducts = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/products/archive`;

    return axios({
      method: REQUEST_METHOD.PUT,
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { products } }) => {
        if (products.length > 0) {
          dispatch(batchActions([removeProducts(data.products)]));
        }
        callback(null, products);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRODUCT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
