export const GENERIC_ERROR = {
  title: "Whoops!",
  body: [
    "There was an error loading this page.",
    "Please refresh to try again."
  ]
};

export const NO_DATA_ERROR = {
  title: "No data",
  body: ["No data was found for this request"]
};

export const PAGE_PARAMS_MISSING = {
  title: "Not found",
  body: ["This page could not be found."]
};

export const NO_SHORT_ID_ERROR = {
  title: "Incorrect payment link id",
  body: [
    "The payment link id you're trying to use has an error.",
    "It may have a typo or is no longer valid."
  ]
};

export const CHECKOUT_SESSION_ERROR_CONTENT = {
  title: "Whoops!",
  body: [
    "There was an error loading your order summary.",
    "Please refresh to try again."
  ]
};

export const CHECKOUT_CONTENT_ERROR_CONTENT = {
  title: "Whoops!",
  body: [
    "There was an error loading your content.",
    "Please refresh to try again. Or click the download content link in the email you received."
  ]
};

export const CHECKOUT_CONTENT_NO_DATA_CONTENT = {
  title: "No content",
  body: ["Please check your email for a link to download your content."]
};

export const BILLING_MANAGE_STATUS_ERROR_CONTENT = {
  title: "Whoops!",
  body: [
    "There was an error loading your billing status.",
    "Please refresh to try again. Or click the billing management link in the email receipt you received."
  ]
};

export const BILLING_MANAGE_STATUS_NO_DATA_CONTENT = {
  title: "No content",
  body: ["Please check your email for a link to manage your billing."]
};

export const CHECKOUT_GENERIC_ERROR_CONTENT = {
  title: "Whoops!",
  body: [
    "There was an error with your checkout.",
    "Close the page and try again."
  ]
};

export const SERVER_GENERIC_ERROR = {
  props: {
    error: GENERIC_ERROR
  }
};

export const SERVER_PARAMS_MISSING_ERROR = {
  props: {
    error: PAGE_PARAMS_MISSING
  }
};

export const ERROR_RESPONSE_PATH = "response.data.path";
export const ERROR_RESPONSE_MESSAGE = "response.data.message";
