import { v4 as uuidv4 } from "uuid";
import { RECURRING_INTERVALS } from "utils/constants/payment";
import {
  getCurrencyConfigForLocale,
  formatUnitAmount
} from "components/FormerEditor/common/currency";
import { CURRENCY_CODES } from "components/FormerEditor/common/constants/currency";
import { getUUID } from "utils/uuid";
import isNumber from "lodash/isNumber";
import { TAX_BEHAVIOR } from "./taxRate";

export const FULL_INTERVAL_SHORTHAND_MAP = {
  [RECURRING_INTERVALS.DAY]: "day",
  [RECURRING_INTERVALS.WEEK]: "wk",
  [RECURRING_INTERVALS.MONTH]: "mo",
  [RECURRING_INTERVALS.YEAR]: "yr"
};

const METERED = "metered";
const LICENSED = "licensed";
export const USAGE_TYPE = {
  LICENSED,
  METERED
};

export const TRANSACTION_MAX = 100000000;
export const TRANSACTION_MIN = 50;

export const SCHEDULE_TYPE_KEY = "schedule_type";
const RECURRING = "recurring";
const ONE_TIME = "one_time";
export const SCHEDULE_TYPE = {
  RECURRING,
  ONE_TIME
};

export const PRICE_TYPE = {
  RECURRING,
  ONE_TIME
};

export const SCHEDULE_LABEL_MAP = {
  [RECURRING]: "Recurring",
  [ONE_TIME]: "One time"
};

export const COMMON_RECURRING_PROPS = {
  interval_count: 1,
  usage_type: USAGE_TYPE.LICENSED
};

export const EMPTY_RECURRING = {
  interval: "",
  ...COMMON_RECURRING_PROPS
};

export const DEFAULT_RECURRING = {
  interval: RECURRING_INTERVALS.MONTH,
  ...COMMON_RECURRING_PROPS
};

export const DAY_RECURRING = {
  interval: RECURRING_INTERVALS.DAY,
  ...COMMON_RECURRING_PROPS
};

export const WEEK_RECURRING = {
  interval: RECURRING_INTERVALS.DAY,
  ...COMMON_RECURRING_PROPS
};

export const MONTH_RECURRING = {
  interval: RECURRING_INTERVALS.DAY,
  ...COMMON_RECURRING_PROPS
};

export const YEAR_RECURRING = {
  interval: RECURRING_INTERVALS.YEAR,
  ...COMMON_RECURRING_PROPS
};

export const PRIMARY_PRICE_KEYS = ["product", "price", "currency", "interval"];

export const templatePrice = ({ recurring, currency, amount } = {}) => {
  const ctxAmount = isNumber(amount) ? amount : 1000;
  const ctxRecurring = recurring || DEFAULT_RECURRING;
  const ctxCurrency =
    currency || getCurrencyConfigForLocale().code.toLowerCase();

  return {
    recurring: ctxRecurring,
    currency: ctxCurrency,
    formattedPrice: formatUnitAmount({
      amount: ctxAmount,
      currency: ctxCurrency
    }),
    tax_behavior: TAX_BEHAVIOR.EXCLUSIVE
  };
};

export const getFlexTemplatePrice = (amount, uuid = getUUID()) => {
  const template = {
    key: uuid,
    uuid
  };
  const currencyConfig = getCurrencyConfigForLocale();
  const currency = currencyConfig.code.toLowerCase();

  const formattedPrice = isNumber(amount)
    ? formatUnitAmount({
        amount,
        currency
      })
    : "";
  template.currency = currency;
  template.formattedPrice = formattedPrice;
  template.recurring = null;
  template.tax_behavior = TAX_BEHAVIOR.EXCLUSIVE;

  return template;
};

export const getTemplatePrice = (product, uuid = getUUID()) => {
  const template = {
    key: uuid,
    uuid,
    ...templatePrice()
  };
  if (product) {
    template.product = product;
  }
  return template;
};

export const getStubTemplatePageProductPrices = () => [
  {
    uuid: uuidv4(),
    ...templatePrice()
  },
  {
    uuid: uuidv4(),
    ...templatePrice({
      recurring: YEAR_RECURRING,
      amount: 1000 * 12,
      currency: CURRENCY_CODES.USD.toLowerCase()
    })
  }
];

export const getStubTemplateLinkProductPrices = () => [
  {
    uuid: uuidv4(),
    ...templatePrice({
      amount: 1000 * 10,
      currency: CURRENCY_CODES.USD.toLowerCase()
    }),
    recurring: null
  }
];

export const getNewPriceInLatestConfig = (
  { models, product },
  uuid = uuidv4()
) => {
  const props = {
    key: uuid,
    uuid
  };
  const latestPrice = models && models.length && models[models.length - 1];
  if (latestPrice) {
    props.currency = latestPrice.currency;
  }

  const result = {
    ...props,
    ...templatePrice(props)
  };
  if (product) {
    result.product = product;
  }
  if (latestPrice) {
    result.recurring = latestPrice.recurring;
  }

  return result;
};
