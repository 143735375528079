import CONSTANTS from "constants/organizationShortLink";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const SHORT_LINK_ID = "short_link_id";
const ORGANIZATION_ID = "organization_id";

const internals = {
  [CONSTANTS.SET_ORGANIZATION_SHORT_LINKS]: (state, { payload }) => {
    return helpers.setAssociationsIn(
      state,
      payload,
      SHORT_LINK_ID,
      ORGANIZATION_ID
    );
  },
  [CONSTANTS.REPLACE_ORGANIZATION_SHORT_LINKS]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  [CONSTANTS.UPDATE_ORGANIZATION_SHORT_LINK_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
