export const READER = "reader";
export const WRITER = "writer";
export const ADMIN = "admin";
export const OWNER = "owner";
export const SUPER = "super";

export const ROLES = {
  SUPER,
  ADMIN,
  OWNER,
  WRITER,
  READER
};

export const TECHNICAL = "technical";
export const MANAGEMENT = "management";

export const TRACKS = {
  TECHNICAL,
  MANAGEMENT
};
