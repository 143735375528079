import { useMemo } from "react";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { enableBatching } from "redux-batched-actions";
import { composeWithDevTools } from "redux-devtools-extension";
import { combineReducers } from "redux-immutable";
import { fromJS } from "immutable";
import keys from "lodash/keys";
import { clearJWT } from "utils/auth";
import Analytics from "utils/analytics";
import AUTH_CONST from "constants/auth";
import { initialState as initialApp, reducer as app } from "./app";
import {
  initialState as initialManifest,
  reducer as manifest
} from "./manifest";
import {
  initialState as initialMetadata,
  reducer as metadata
} from "./metadata";
import {
  initialState as initialComputation,
  reducer as computation
} from "./computation";
import { initialState as initialEvent, reducer as event } from "./event";
import { initialState as initialSession, reducer as session } from "./session";
import { initialState as initialPayment, reducer as payment } from "./payment";
import {
  initialState as initialShortLink,
  reducer as shortLink
} from "./shortLink";
import {
  initialState as initialAttachment,
  reducer as attachment
} from "./attachment";
import {
  initialState as initialAudience,
  reducer as audience
} from "./audience";
import {
  initialState as initialCampaign,
  reducer as campaign
} from "./campaign";
import {
  initialState as initialLegalContent,
  reducer as legalContent
} from "./legalContent";
import {
  initialState as initialEditableContent,
  reducer as editableContent
} from "./editableContent";
import {
  initialState as initialWorkflow,
  reducer as workflow
} from "./workflow";
import { initialState as initialWebhook, reducer as webhook } from "./webhook";
import {
  initialState as initialContactlessConfig,
  reducer as contactlessConfig
} from "./contactlessConfig";
import {
  initialState as initialEntitlementConfig,
  reducer as entitlementConfig
} from "./entitlementConfig";
import {
  initialState as initialDatasource,
  reducer as datasource
} from "./datasource";
import { initialState as initialReport, reducer as report } from "./report";
import { initialState as initialInsight, reducer as insight } from "./insight";
import { initialState as initialPrice, reducer as price } from "./price";
import { initialState as initialProduct, reducer as product } from "./product";
import { initialState as initialCoupon, reducer as coupon } from "./coupon";
import { initialState as initialTaxRate, reducer as taxRate } from "./taxRate";
import {
  initialState as initialShippingRate,
  reducer as shippingRate
} from "./shippingRate";
import {
  initialState as initialIntegration,
  reducer as integration
} from "./integration";
import {
  initialState as initialPromotionCode,
  reducer as promotionCode
} from "./promotionCode";
import { initialState as initialFeature, reducer as feature } from "./feature";
import {
  initialState as initialVariable,
  reducer as variable
} from "./variable";
import {
  initialState as initialDeployment,
  reducer as deployment
} from "./deployment";
import { initialState as initialTheme, reducer as theme } from "./theme";
import {
  initialState as initialOrganizationApplication,
  reducer as organizationApplication
} from "./organizationApplication";
import {
  initialState as initialUserApplication,
  reducer as userApplication
} from "./userApplication";
import {
  initialState as initialUserFeature,
  reducer as userFeature
} from "./userFeature";
import {
  initialState as initialUserFeatureGroup,
  reducer as userFeatureGroup
} from "./userFeatureGroup";
import {
  initialState as initialUserShortLink,
  reducer as userShortLink
} from "./userShortLink";
import {
  initialState as initialUserAttachment,
  reducer as userAttachment
} from "./userAttachment";
import {
  initialState as initialUserAudience,
  reducer as userAudience
} from "./userAudience";
import {
  initialState as initialUserCampaign,
  reducer as userCampaign
} from "./userCampaign";
import {
  initialState as initialUserLegalContent,
  reducer as userLegalContent
} from "./userLegalContent";
import {
  initialState as initialUserEditableContent,
  reducer as userEditableContent
} from "./userEditableContent";
import {
  initialState as initialUserWorkflow,
  reducer as userWorkflow
} from "./userWorkflow";
import {
  initialState as initialUserWebhook,
  reducer as userWebhook
} from "./userWebhook";
import {
  initialState as initialUserContactlessConfig,
  reducer as userContactlessConfig
} from "./userContactlessConfig";
import {
  initialState as initialUserEntitlementConfig,
  reducer as userEntitlementConfig
} from "./userEntitlementConfig";
import {
  initialState as initialUserDatasource,
  reducer as userDatasource
} from "./userDatasource";

import {
  initialState as initialOrganizationFeature,
  reducer as organizationFeature
} from "./organizationFeature";
import {
  initialState as initialOrganizationFeatureGroup,
  reducer as organizationFeatureGroup
} from "./organizationFeatureGroup";
import {
  initialState as initialOrganizationShortLink,
  reducer as organizationShortLink
} from "./organizationShortLink";
import {
  initialState as initialOrganizationAttachment,
  reducer as organizationAttachment
} from "./organizationAttachment";
import {
  initialState as initialOrganizationAudience,
  reducer as organizationAudience
} from "./organizationAudience";
import {
  initialState as initialOrganizationCampaign,
  reducer as organizationCampaign
} from "./organizationCampaign";
import {
  initialState as initialOrganizationLegalContent,
  reducer as organizationLegalContent
} from "./organizationLegalContent";
import {
  initialState as initialOrganizationEditableContent,
  reducer as organizationEditableContent
} from "./organizationEditableContent";
import {
  initialState as initialOrganizationWorkflow,
  reducer as organizationWorkflow
} from "./organizationWorkflow";
import {
  initialState as initialOrganizationWebhook,
  reducer as organizationWebhook
} from "./organizationWebhook";
import {
  initialState as initialOrganizationContactlessConfig,
  reducer as organizationContactlessConfig
} from "./organizationContactlessConfig";
import {
  initialState as initialOrganizationEntitlementConfig,
  reducer as organizationEntitlementConfig
} from "./organizationEntitlementConfig";
import {
  initialState as initialOrganizationDatasource,
  reducer as organizationDatasource
} from "./organizationDatasource";
import {
  initialState as initialOrganizationManifest,
  reducer as organizationManifest
} from "./organizationManifest";
import {
  initialState as initialOrganizationMerchantAccount,
  reducer as organizationMerchantAccount
} from "./organizationMerchantAccount";
import {
  initialState as initialUserMerchantAccount,
  reducer as userMerchantAccount
} from "./userMerchantAccount";
import {
  initialState as initialUserManifest,
  reducer as userManifest
} from "./userManifest";
import {
  initialState as initialApplication,
  reducer as application
} from "./application";
import {
  initialState as initialCapability,
  reducer as capability
} from "./capability";
import {
  initialState as initialFeatureGroup,
  reducer as featureGroup
} from "./featureGroup";
import { initialState as initialAuth, reducer as auth } from "./auth";
import {
  initialState as initialOnboarding,
  reducer as onboarding
} from "./onboarding";
import { initialState as initialUser, reducer as user } from "./user";
import {
  initialState as initialOrganization,
  reducer as organization
} from "./organization";
import { initialState as initialLicense, reducer as license } from "./license";
import {
  initialState as initialMerchantAccount,
  reducer as merchantAccount
} from "./merchantAccount";
import {
  initialState as initialPaymentMethod,
  reducer as paymentMethod
} from "./paymentMethod";
import {
  initialState as initialSubscription,
  reducer as subscription
} from "./subscription";

let store;

const baseReducer = {
  app: initialApp,
  application: initialApplication,
  deployment: initialDeployment,
  event: initialEvent,
  session: initialSession,
  payment: initialPayment,
  theme: initialTheme,
  manifest: initialManifest,
  metadata: initialMetadata,
  price: initialPrice,
  product: initialProduct,
  coupon: initialCoupon,
  taxRate: initialTaxRate,
  shippingRate: initialShippingRate,
  promotionCode: initialPromotionCode,
  feature: initialFeature,
  variable: initialVariable,
  capability: initialCapability,
  featureGroup: initialFeatureGroup,
  computation: initialComputation,
  merchantAccount: initialMerchantAccount,
  auth: initialAuth,
  integration: initialIntegration,
  onboarding: initialOnboarding,
  shortLink: initialShortLink,
  attachment: initialAttachment,
  audience: initialAudience,
  campaign: initialCampaign,
  legalContent: initialLegalContent,
  editableContent: initialEditableContent,
  workflow: initialWorkflow,
  webhook: initialWebhook,
  contactlessConfig: initialContactlessConfig,
  entitlementConfig: initialEntitlementConfig,
  datasource: initialDatasource,
  report: initialReport,
  insight: initialInsight,
  license: initialLicense,
  paymentMethod: initialPaymentMethod,
  subscription: initialSubscription,

  /**
   * User
   */
  user: initialUser,
  userApplication: initialUserApplication,
  userManifest: initialUserManifest,
  userMerchantAccount: initialUserMerchantAccount,
  userFeature: initialUserFeature,
  userFeatureGroup: initialUserFeatureGroup,
  userShortLink: initialUserShortLink,
  userAttachment: initialUserAttachment,
  userAudience: initialUserAudience,
  userCampaign: initialUserCampaign,
  userLegalContent: initialUserLegalContent,
  userEditableContent: initialUserEditableContent,
  userWorkflow: initialUserWorkflow,
  userWebhook: initialUserWebhook,
  userContactlessConfig: initialUserContactlessConfig,
  userEntitlementConfig: initialUserEntitlementConfig,
  userDatasource: initialUserDatasource,

  /**
   * Organization
   */
  organization: initialOrganization,
  organizationApplication: initialOrganizationApplication,
  organizationManifest: initialOrganizationManifest,
  organizationMerchantAccount: initialOrganizationMerchantAccount,
  organizationFeature: initialOrganizationFeature,
  organizationFeatureGroup: initialOrganizationFeatureGroup,
  organizationShortLink: initialOrganizationShortLink,
  organizationAttachment: initialOrganizationAttachment,
  organizationAudience: initialOrganizationAudience,
  organizationCampaign: initialOrganizationCampaign,
  organizationLegalContent: initialOrganizationLegalContent,
  organizationEditableContent: initialOrganizationEditableContent,
  organizationWorkflow: initialOrganizationWorkflow,
  organizationWebhook: initialOrganizationWebhook,
  organizationContactlessConfig: initialOrganizationContactlessConfig,
  organizationEntitlementConfig: initialOrganizationEntitlementConfig,
  organizationDatasource: initialOrganizationDatasource
};

export const reducerKeys = keys(baseReducer);
const exampleInitialState = fromJS(baseReducer);

function initStore(preloadedState = exampleInitialState) {
  const appReducer = combineReducers({
    app,
    application,
    deployment,
    event,
    session,
    payment,
    theme,
    manifest,
    metadata,
    price,
    product,
    coupon,
    taxRate,
    shippingRate,
    promotionCode,
    feature,
    variable,
    computation,
    shortLink,
    attachment,
    audience,
    campaign,
    legalContent,
    editableContent,
    workflow,
    webhook,
    contactlessConfig,
    entitlementConfig,
    capability,
    datasource,
    report,
    insight,
    featureGroup,
    merchantAccount,
    auth,
    integration,
    onboarding,
    license,
    paymentMethod,
    subscription,
    user,
    userApplication,
    userManifest,
    userMerchantAccount,
    userFeature,
    userFeatureGroup,
    userShortLink,
    userAttachment,
    userAudience,
    userCampaign,
    userLegalContent,
    userEditableContent,
    userContactlessConfig,
    userEntitlementConfig,
    userDatasource,
    userWorkflow,
    userWebhook,
    organization,
    organizationApplication,
    organizationManifest,
    organizationMerchantAccount,
    organizationFeature,
    organizationFeatureGroup,
    organizationShortLink,
    organizationAttachment,
    organizationCampaign,
    organizationLegalContent,
    organizationEditableContent,
    organizationContactlessConfig,
    organizationEntitlementConfig,
    organizationDatasource,
    organizationAudience,
    organizationWorkflow,
    organizationWebhook
  });

  const rootReducer = (state, action) => {
    /**
     * On Logout we need to:
     * - unset the reducers
     * - remove JWTs
     * - unset the 3PS registrations
     */
    if (action.type === AUTH_CONST.LOGOUT) {
      clearJWT();
      Analytics.clearRegistrations();
      return appReducer(undefined, action);
    } else {
      return appReducer(state, action);
    }
  };

  return createStore(
    enableBatching(rootReducer),
    fromJS(preloadedState),
    composeWithDevTools(applyMiddleware(thunk))
  );
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
