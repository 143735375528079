export const LEGAL_CONTENT_FORM_KEY = {};

const GENERAL = "general";

export const LEGAL_CONTENT_TYPE = {
  GENERAL
};

const EDITABLE_CONTENT = "editable_content";

export const LEGAL_CONTENT_CONTENT_TYPE = {
  EDITABLE_CONTENT
};

export const LEGAL_CONTENT_ACCEPTANCE_TYPE = {
  BROWSEWRAP: "browsewrap",
  CLICKWRAP: "clickwrap"
};

export const LEGAL_CONTENT_ACCEPTANCE_TYPE_OPTIONS = [
  {
    label: "Browsewrap",
    uid: LEGAL_CONTENT_ACCEPTANCE_TYPE.CLICKWRAP,
    description:
      "User is not required to click to accept the terms of the legal content."
  },
  {
    label: "Clickwrap",
    uid: LEGAL_CONTENT_ACCEPTANCE_TYPE.CLICKWRAP,
    description:
      "User is required to click to accept the terms of the legal content."
  }
];

export const LEGAL_CONTENT_PRESENTATION_TYPE = {
  OVERLAY: "overlay",
  INLINE: "inline"
};

export const LEGAL_CONTENT_PRESENTATION_TYPE_OPTIONS = [
  {
    label: "Overlay",
    uid: LEGAL_CONTENT_PRESENTATION_TYPE.OVERLAY,
    description: "User can click to see content presented in an overlay."
  },
  {
    label: "Inline",
    uid: LEGAL_CONTENT_PRESENTATION_TYPE.INLINE,
    description: "User will preview content inline on the form."
  }
];
