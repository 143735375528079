import C from "constants/organizationFeature";
import ActionHelpers from "./helpers";
// import axios from "axios";
// import { batchActions } from "redux-batched-actions";
// import { authBearerToken } from "utils/auth";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_FEATURE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setOrganizationFeatures = (payload) => {
  return {
    type: C.SET_ORGANIZATION_FEATURES,
    payload
  };
};

export const replaceOrganizationFeatures = (payload) => {
  return {
    type: C.REPLACE_ORGANIZATION_FEATURES,
    payload
  };
};

/**
 * resetOrganizationFeatureNotes
 */
export function resetOrganizationFeatureNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_FEATURE_STORE,
      payload
    });
  };
}

export function showOrganizationFeatureNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ORGANIZATION_FEATURE_STORE,
        message || `Success!`
      )
    );
  };
}
