import axios from "axios";
import { batchActions } from "redux-batched-actions";
import ActionHelpers from "./helpers";
import { authBearerToken } from "utils/auth";
import CONSTANTS from "constants/capability";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: CONSTANTS.UPDATE_CAPABILITY_STORE,
    payload: {
      isLoading: true
    }
  };
}

export function replaceCapabilities(payload) {
  return {
    type: CONSTANTS.REPLACE_CAPABILITIES,
    payload
  };
}

export function resetCapabilityNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: CONSTANTS.UPDATE_CAPABILITY_STORE,
      payload
    });
  };
}

/**
 * fetchCapabilities
 */
export const fetchCapabilities = (
  { resourceUUID, resource },
  callback = () => {}
) => {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        CONSTANTS.UPDATE_CAPABILITY_STORE,
        "get",
        "app",
        "Fetch all resource capabilities"
      )
    );

    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/capabilities`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const capabilities = Array.isArray(data) ? data : [data];

        dispatch(replaceCapabilities(capabilities));

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              CONSTANTS.UPDATE_DASHBOARD_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
