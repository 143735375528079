import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";
import isArray from "lodash/isArray";
import reduce from "lodash/reduce";
import omit from "lodash/omit";
import pick from "lodash/pick";
import each from "lodash/each";
import get from "lodash/get";
import { authBearerToken, clearJWT } from "utils/auth";
import { logoutAction } from "./auth";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
const UNAUTHED_CODE = 401;
const MAINTENANCE_CODE = 503;
const UNAUTH_NOTE_MESSAGE = {
  message: "A request was unauthorized. Please sign in again."
};
const MAINTENANCE_NOTE_MESSAGE = {
  message:
    "PriceBlocs is undergoing scheduled maintenance. Please try again later."
};
const LOGOUT_MESSAGE_MAP = {
  [MAINTENANCE_CODE]: MAINTENANCE_NOTE_MESSAGE,
  [UNAUTHED_CODE]: UNAUTH_NOTE_MESSAGE
};

export const ACTION_CONFIG = {
  showNote: true,
  setLoader: true
};

export const NO_LOAD_ACTION_CONFIG = {
  showNote: true,
  setLoader: false
};

const ActionHelpers = {
  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  },

  parseJSON(response) {
    return response.json();
  },

  request(method, url, params) {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json"
      },
      crossDomain: true,
      credentials: "include"
    };
    if (!isEmpty(params)) {
      options.body = JSON.stringify(params);
    }

    if (typeof window !== "undefined" && typeof window.fetch !== "undefined") {
      return window
        .fetch(url, options)
        .then(this.checkStatus)
        .then(this.parseJSON);
    }
  },
  /**
   * action handler
   *
   * format api error responses for the dropdown note component
   * note path getter for picking of response data
   */
  handler(next, path = "body") {
    return (err, response) => {
      if (err) {
        const errorData = get(response, path);
        next(this.JSONAPIError(errorData), null);
      } else {
        const data = get(response, path);
        next(err, data);
      }
    };
  },
  JSONAPIError(errorData) {
    let error = {};
    if (errorData) {
      error = {
        status: errorData.statusCode,
        text: errorData.details.text,
        method: errorData.details.method,
        path: errorData.details.path
      };
    }

    return error;
  },
  loadingAction(actionType, action = "", loadingType = "", message = "") {
    return {
      type: actionType,
      payload: {
        isLoading: {
          action,
          type: loadingType,
          message
        }
      }
    };
  },
  successNote(type, message, params = {}) {
    return {
      type,
      payload: {
        note: {
          status: 200,
          autoClose: true,
          message,
          actions: [],
          ...params
        }
      }
    };
  },
  authErrorAndClear(type, error, suppress = false, dispatch = () => {}) {
    clearJWT();
    return [this.clearLoader(type), this.requestFailure(type, error, suppress)];
  },
  // 400 - Bad Request - server side validation errors
  // 401 - Unauthenticated
  // 404 - Not found
  errorAndClear(type, error, suppress = false, dispatch = () => {}) {
    let actions = [
      this.clearLoader(type),
      this.requestFailure(type, error, suppress)
    ];

    const requiresLogout =
      (error.response && error.response.status === UNAUTHED_CODE) ||
      (error.response && error.response.status === MAINTENANCE_CODE);

    if (requiresLogout) {
      clearJWT();

      const unauthNoteAction = this.requestFailure(
        type,
        LOGOUT_MESSAGE_MAP[error.response.status],
        suppress
      );

      actions = [logoutAction(), unauthNoteAction];
      // Optionally hard reset to login
    }

    return actions;
  },
  requestFailure(type, error, suppress) {
    if (suppress) return {};

    return {
      type,
      payload: {
        note: error
      }
    };
  },
  clearLoader(type) {
    return {
      type,
      payload: {
        isLoading: false
      }
    };
  },
  applySingularKey(memo, prod, singKey) {
    const className = get(prod, `${singKey}.class_name`, "").toLowerCase();
    if (className) {
      const plural = `${className}s`;
      if (isEmpty(memo[plural])) {
        memo[plural] = [];
      }
      memo[plural] = memo[plural].concat(prod[singKey]);
    }
    prod = omit(prod, singKey);

    return {
      memo,
      prod
    };
  },
  // TODO: refactor trashtown
  // - needs test coverage first
  // - Ideally each collection key is an array of values
  deconstructReducerData(data, primaryKey, multiKeys = [], singularKeys = []) {
    let result;
    if (isArray(data)) {
      const initMemo = reduce(
        multiKeys,
        (memo, key) => {
          if (key) {
            memo[key] = [];
          }
          return memo;
        },
        { [primaryKey]: [] }
      );

      result = reduce(
        data,
        (memo, prod) => {
          each(multiKeys, (assocKey) => {
            if (assocKey) {
              memo[assocKey] = memo[assocKey].concat(prod[assocKey]);
            }
          });

          each(singularKeys, (singKey) => {
            const appliedSing = this.applySingularKey(memo, prod, singKey);
            memo = appliedSing.memo;
            prod = appliedSing.prod;
          });

          memo[primaryKey].push(omit(prod, multiKeys));
          return memo;
        },
        initMemo
      );
    } else {
      const omissions = flatten(multiKeys.concat(singularKeys));
      let base = pick(data, multiKeys);

      each(singularKeys, (singKey) => {
        const appliedSing = this.applySingularKey(base, data, singKey);
        base = appliedSing.memo;
        data = appliedSing.prod;
      });

      result = {
        ...base,
        [primaryKey]: omit(data, omissions)
      };
    }

    return result;
  }
};

export const withMethod = ({
  method,
  url,
  onComplete,
  constant,
  dispatch,
  callback,
  data,
  params
}) => {
  const props = {
    method,
    url,
    headers: authBearerToken()
  };
  if (data) {
    props.data = data;
  }
  if (params) {
    props.params = params;
  }

  return axios({
    method,
    url,
    headers: authBearerToken()
  })
    .then(({ data: responseData }) => {
      const actions = onComplete(responseData);
      if (actions) {
        dispatch(Array.isArray(actions) ? batchActions(actions) : actions);
      }
      callback(null, responseData);
    })
    .catch((err) => {
      dispatch(
        batchActions(
          ActionHelpers.errorAndClear(constant, err, false, dispatch)
        )
      );
      callback(err);
    });
};

export default ActionHelpers;
