import keymirror from "keymirror";

export default keymirror({
  SET_MERCHANT_ACCOUNTS: null,
  UNSET_MOCK_MERCHANT_ACCOUNT: null,
  REPLACE_MERCHANT_ACCOUNTS: null,
  REMOVE_MERCHANT_ACCOUNTS: null,
  SET_MERCHANT_ACCOUNT_VALUES: null,
  SET_MERCHANT_ACCOUNT_CONFIG: null,
  UPDATE_MERCHANT_ACCOUNT_STORE: null
});
