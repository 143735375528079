const ENTITY_ADD = "entity_add";
const ENTITY_SETTINGS = "entity_settings";
const PAGE_THEME = "page_theme";
export const BUILDER_INTENT = {
  ENTITY_ADD,
  ENTITY_SETTINGS,
  PAGE_THEME
};

export const BUILDER_ENTITY = {
  ENTITY_ADD,
  PAGE_THEME
};

export const BLOCK_LIST_WIDTH = 240;
export const BLOCK_SETTINGS_WIDTH = 360;
export const BLOCK_SECTION_NAV = 56;

export const HUB_SIDEBAR_NAV_WIDTH = 180;

export const BLOCK_REGISTRY = {
  SCROLL_CONTAINER: "scroll-container",
  PAGE_PREVIEW: "page-preview"
};

export const AUTHED_DIRTY_BUILDER_FORM_CONFIRM = [
  {
    title: "You have unsaved changes",
    subheader:
      "Are you sure you want to go back to the hub and discard these changes?"
  }
];

export const UNAUTHED_DIRTY_BUILDER_FORM_CONFIRM = [
  {
    title: "Discard changes",
    subheader:
      "Are you sure you want to discard your changes and build another page?"
  }
];
