import CONSTANTS from "constants/userContactlessConfig";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const CONTACTLESS_CONFIG_ID = "contactless_config_id";
const USER_ID = "user_id";

const internals = {
  [CONSTANTS.SET_USER_CONTACTLESS_CONFIGS]: (state, { payload }) => {
    return helpers.setAssociationsIn(
      state,
      payload,
      CONTACTLESS_CONFIG_ID,
      USER_ID
    );
  },
  [CONSTANTS.REPLACE_USER_CONTACTLESS_CONFIGS]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  [CONSTANTS.UPDATE_USER_CONTACTLESS_CONFIG_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
