import { updateManifestParams } from "utils/manifest";
import { ROUTE_STATUS } from "utils/constants/application";
import {
  getProtocol,
  getWebClientRoot,
  isPlatformSubdomain
} from "utils/subdomain";
import { getManifestLiveVariation } from "utils/mapStateToProps";
import axios from "axios";
import { getConfig } from "./env";
import { authBearerToken } from "./auth";
import { EXCLUSIVE_PATHS, PLATFORM_PAGE_ROUTES } from "./paths";
import compact from "lodash/compact";
import startCase from "lodash/startCase";
import get from "lodash/get";
import set from "lodash/set";
import { APPLICATION_UPDATE_SCHEMA, DOMAIN_TYPE } from "constants/validation";
import { ensureLeadingSlash } from "./route";
import { validateSchema } from "./validate";
import { ORGANIZATION, USER } from "./constants/models";
import { isMalformedValue } from "./request";
import { getAppUrlVariations } from "./hooks/application";
import { STATE_KEYS } from "./constants/state";
import { FORM_KEY } from "./constants/form";

export const getAppManifestPublishParams = ({
  publish,
  manifest,
  variables,
  theme,
  application,
  appEntities = []
}) => {
  /**
   * Note: API expects only one route to be published at a time so this object should only have one route key
   * - This single route key is used in the deployment call within the API - getDeploymentForManifestPublish
   */
  const sanitizedRoute = publish && ensureLeadingSlash(publish.route);
  const routes = {
    [sanitizedRoute]: {
      status: ROUTE_STATUS.ACTIVE,
      variations: [
        {
          weight: 100,
          manifest: manifest.id
        }
      ]
    }
  };
  const applicationParams = {
    id: application.id,
    merchant_account_id: application.merchant_account_id,
    routes
  };
  if (publish && publish.fallback) {
    applicationParams.fallback = publish.fallback;
  }

  return {
    ...updateManifestParams({ manifest, variables, theme, appEntities }),
    application: applicationParams
  };
};

export const hasAnyActiveRoutes = (application) => {
  let isActive = false;
  for (const route in application.routes) {
    const config = application.routes[route];
    isActive = config.status === ROUTE_STATUS.ACTIVE;

    if (isActive) {
      break;
    }
  }

  return isActive;
};

export const getApplicationPageRoute = ({ application, route }) => {
  const urlVariations = getAppUrlVariations({ application });

  const isPlatform = application && isPlatformSubdomain(application.subdomain);
  let isOrg = application.resource === ORGANIZATION;
  /**
   * Published platform pages are rendered at the alias route
   * So we need to flip org to false when application is platform and page is not a platform route
   */
  const usePlatformAlias = isPlatform && !PLATFORM_PAGE_ROUTES[route];
  if (usePlatformAlias) {
    isOrg = false;
  }

  return isOrg
    ? `${urlVariations.subdomain}${route}`
    : `${urlVariations.alias}${route}`;
};

export const getManifestLiveVariationParams = ({ id, application }) => {
  const liveVariation = getManifestLiveVariation({
    id,
    application
  });

  return liveVariation
    ? {
        ...liveVariation,
        url: getApplicationPageRoute({
          application,
          route: liveVariation.route
        })
      }
    : null;
};

export const getAliasRoot = (alias, env = process.env.NODE_ENV) => {
  const protocol = getProtocol(env);
  const webClientRoot = getWebClientRoot();

  return alias ? `${protocol}${webClientRoot}/${alias}` : "";
};

const ALIAS_ERROR = {
  OWNED: "You're already using that name.",
  TAKEN: "That name is unavailable, please try another."
};

export const validateApplicationAlias = async ({
  current,
  alias,
  setLoading,
  onError
}) => {
  setLoading && setLoading(true);

  try {
    const response = await axios({
      method: "get",
      url: `${getConfig("API_ROOT")}/v1/applications/alias`,
      params: { alias },
      headers: authBearerToken()
    });
    setLoading && setLoading(false);
    return response.data;
  } catch (error) {
    setLoading && setLoading(false);

    onError &&
      onError(current === alias ? ALIAS_ERROR.OWNED : ALIAS_ERROR.TAKEN);
    return error;
  }
};

export const prepareUpdateAppInitialValues = ({
  alias,
  subdomain,
  fallback,
  data,
  resource
}) => ({
  [FORM_KEY]: {
    resource
  },
  alias,
  subdomain,
  fallback,
  data
});

export const prepareUpdateApp = (values) => {
  const { alias, fallback, data } = values;
  const result = {
    data
  };
  if (alias && values[FORM_KEY] && values[FORM_KEY].resource === USER) {
    result.alias = alias;
  }
  if (fallback) {
    result.fallback = fallback;
  } else {
    result.fallback = "";
  }

  return result;
};

/**
 * Prepare valus for fallback edit
 * - Note: use the alias here for form presentation but not used in update
 * @param {Object} params
 * @param {String} params.alias
 * @param {String} params.fallback
 */
export const prepareUpdateAppFallbackInitialValues = ({ alias, fallback }) => ({
  alias,
  fallback
});

/**
 * Fallback can be set to a route or an empty string
 * @param {Object} Application
 */
export const prepareUpdateAppFallback = ({ fallback }) => {
  const result = {};
  if (fallback) {
    result.fallback = fallback;
  } else {
    result.fallback = "";
  }

  return result;
};

/**
 * Note: organizations shouldnt edit their alias
 * @param {Object} params
 * @param {String} params.alias
 * @param {String} params.fallback
 */
export const prepareUpdateAppAliasInitialValues = ({ alias, resource }) => ({
  [FORM_KEY]: {
    resource
  },
  alias
});

/**
 * Fallback can be set to a route or an empty string
 * @param {Object} Application
 */
export const prepareUpdateAppAlias = ({ alias }) => ({ alias });

/**
 * Route is an alias url if the first fragment is:
 * - present
 * - not malformed i.e. [object Object]
 * - not part of the defined route paths
 * @returns
 */
export const isEligibleAliasUrl = (url = "", paths = EXCLUSIVE_PATHS) => {
  const urlFragments = compact(url.split("/"));
  const root = urlFragments[0];

  return root && !isMalformedValue(root) && paths.indexOf(root) === -1;
};

/**
 * Validate the values against the schema
 * - also ensure that user doesnt try set an alias which is taken by the app
 */
export const validateApplicationSchema = async (values) => {
  const validResult = await validateSchema(APPLICATION_UPDATE_SCHEMA, values);
  if (values[FORM_KEY] === USER) {
    const notEligible = !isEligibleAliasUrl(`/${values.alias}`);

    if (values.alias && notEligible) {
      validResult.alias = "That alias is taken";
    }
  }

  const domains = get(values, STATE_KEYS.APPLICATION.ALLOWLIST_DOMAINS) || [];
  if (Array.isArray(domains) && domains.length > 0) {
    domains.forEach((domain, domainIx) => {
      try {
        DOMAIN_TYPE.validateSync(domain);
      } catch (error) {
        set(
          validResult,
          `${STATE_KEYS.APPLICATION.ALLOWLIST_DOMAINS}[${domainIx}]`,
          error.message
        );
      }
    });
  }

  return validResult;
};

export const getAppPageRouteOptions = (application) => {
  const result = {};

  application &&
    application.routes &&
    Object.keys(application.routes).forEach((key) => {
      if (!result[key]) {
        result[key] = {
          route: key,
          label: startCase(key)
        };
      }
    });

  return Object.values(result);
};

export const getAppPageRoutes = (application) => {
  const options = getAppPageRouteOptions(application);
  return options.map(({ route }) => route);
};

export const getAppSetupInitialValues = ({ resource, application, email }) => {
  /**
   * Note: application should have a resource attribute
   * - can substitute for the passed resource for the value on the application
   */
  const isOrg = resource === ORGANIZATION;
  const alias = isOrg
    ? application.alias || application.subdomain || ""
    : email.split("@")[0];
  return {
    alias
  };
};
