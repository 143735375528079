/* eslint-disable react/display-name, react/prop-types */
import React, { useMemo, useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";
import { DESKTOP, PHONE } from "utils/constants/ui";
import { isApplicationPath } from "utils/route";
import { ROUTES } from "utils/constants/application";
import { useCheckoutTracking } from "utils/selectors";
import { useStripeStatusPromise } from "utils/hooks/stripe";
import { useIsUnauthedBuilder } from "utils/hooks/editor";

export const createUseContext = (contextProviderWrapperCreator) => {
  const Context = createContext();
  const useContext = () => React.useContext(Context);
  let ContextProvider;

  if (typeof contextProviderWrapperCreator === "function") {
    ContextProvider = contextProviderWrapperCreator(Context.Provider);
  } else {
    ContextProvider = Context.Provider;
  }

  return {
    Context,
    ContextProvider,
    ContextConsumer: Context.Consumer,
    useContext
  };
};

/**
 * Store and share the ability for a user to activate settings
 * ===
 * NOTE: the value of test_mode changes between where this context is used
 * this context is share between the editor and preview pages
 * so we check the route path to determine if its in test mode (i.e. on the builder route)
 * ===
 * - In builder mode
 * -- user can set the editor menu to be active
 * -- user can see hover interactions on the page
 * -- a stateKey is passed to access state at the right path
 * -- tracking is in test_mode
 * - In preview / live mode
 * -- We toggle off these enhancements so that the user cannot inadvertently perform builder like actions (i.e set menu settings active / see hover interactions)
 * * -- tracking is in live mode
 */
export const {
  ContextProvider: PageFactoryContextProvider,
  useContext: usePageFactoryContext
} = createUseContext((Provider) => ({ children, admin, ...props }) => {
  const clientKey = admin && admin.clientKey;

  /**
   * Potentiall remove this
   */
  const {
    stripePromise,
    stripeStatus,
    hasStripeClientKey
  } = useStripeStatusPromise(clientKey);

  const router = useRouter();
  const isEditMode = isApplicationPath(router.pathname);
  const isUnauthedBuilder = useIsUnauthedBuilder();
  const [canActivateSettings, setActivateSettings] = useState(
    Boolean(isEditMode || props.canActivateSettings)
  );
  const isAuthedBuilder = canActivateSettings && !isUnauthedBuilder;

  /**
   * In editor its: "manifest.data" because there are sibing keys which are in config to control editor state
   * In live page its: "" becuase there are no longer a need for sibling keys as its out of state
   */
  const [stateKey, setStateKey] = useState(props.stateKey || "");
  const linkId = useMemo(() => uuidv4(), []);

  const [focusPath, setFocusPath] = useState("");

  const checkoutTracking = useCheckoutTracking();
  const initialTracking = {
    link_id: linkId,
    /**
     * Note this value is just a default
     * - It will be overridden within the setTracking call of the PageFactory component
     * - Values for route, subdomain and alias will be parsed from the page response of manifest and config
     * - They are then extended into the tracking object
     */
    route: ROUTES.PRICING,
    ...checkoutTracking
  };

  /**
   * Note: Tracking is sent in the checkout request
   */
  const [tracking, setTracking] = useState(initialTracking);
  const [clientCheckout, setClientCheckout] = useState(null);

  return (
    <Provider
      value={{
        stripePromise,
        stripeStatus,
        hasStripeClientKey,
        canActivateSettings,
        setActivateSettings,
        isUnauthedBuilder,
        isAuthedBuilder,
        setTracking,
        setClientCheckout,
        stateKey,
        setStateKey,
        tracking,
        clientCheckout,
        focusPath,
        setFocusPath
      }}
    >
      {children}
    </Provider>
  );
});

/**
 * This context wraps the page editor so it is created with the known context of
 * - application and manifest (page) being edited
 * - test mode
 */
export const {
  Context: PageBuilderContext,
  ContextProvider: PageBuilderContextProvider,
  useContext: usePageBuilderContext
} = createUseContext((Provider) => ({ children }) => {
  const [blockRegistry, setBlockRegistry] = useState({});
  const linkId = useMemo(() => uuidv4(), []);
  /**
   * Use this shared key to "publish" change notifications to on page components which manage their own internal state (e.g. LegalContent)
   */
  const [componentRefreshKey, setComponentRefreshKey] = useState(uuidv4());

  const tracking = {
    link_id: linkId,
    ...useCheckoutTracking()
  };

  return (
    <Provider
      value={{
        setBlockRegistry,
        blockRegistry,
        tracking,
        componentRefreshKey,
        setComponentRefreshKey
      }}
    >
      {children}
    </Provider>
  );
});

const viewportContext = createContext({});

export const ViewportProvider = ({ children }) => {
  const ctxWindow = typeof window !== "undefined" ? window : {};

  const [width, setWidth] = useState(ctxWindow.innerWidth);
  const [height, setHeight] = useState(ctxWindow.innerHeight);

  const handleWindowResize = () => {
    setWidth(ctxWindow.innerWidth);
    setHeight(ctxWindow.innerHeight);
  };

  useEffect(() => {
    ctxWindow.addEventListener("resize", handleWindowResize);
    return () => ctxWindow.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);

  return {
    width,
    height,
    isSmall: width <= PHONE,
    isTablet: width > PHONE && width < DESKTOP,
    isDesktop: width >= DESKTOP
  };
};

ViewportProvider.propTypes = {
  children: PropTypes.any
};

/**
 * Share application context
 */
export const {
  Context: ApplicationContext,
  ContextProvider: ApplicationContextProvider,
  useContext: useApplicationContext
} = createUseContext(
  (Provider) => ({ children, application, merchantAccount, env }) => {
    return (
      <Provider
        value={{
          application,
          merchantAccount,
          env: env || process.env.NODE_ENV
        }}
      >
        {children}
      </Provider>
    );
  }
);

export const {
  ContextProvider: ExchangeShortLinkContextProvider,
  useContext: useExchangeShortLinkContext
} = createUseContext(
  (Provider) => ({
    children,
    admin,
    error,
    organization,
    account,
    config,
    capabilities,
    content,
    metadata,
    shortLink
  }) => {
    const clientKey = admin && admin.clientKey;

    const {
      stripePromise,
      stripeStatus,
      hasStripeClientKey
    } = useStripeStatusPromise(clientKey);

    return (
      <Provider
        value={{
          hasStripeClientKey,
          stripePromise,
          stripeStatus,
          organization,
          config,
          admin,
          account,
          capabilities,
          content,
          error,
          metadata,
          shortLink
        }}
      >
        {children}
      </Provider>
    );
  }
);
