import C from "constants/organizationShortLink";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_SHORT_LINK_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setOrganizationShortLinks = (payload) => {
  return {
    type: C.SET_ORGANIZATION_SHORT_LINKS,
    payload
  };
};

export const replaceOrganizationShortLinks = (payload) => {
  return {
    type: C.REPLACE_ORGANIZATION_SHORT_LINKS,
    payload
  };
};

/**
 * resetOrganizationShortLinkNotes
 */
export function resetOrganizationShortLinkNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_SHORT_LINK_STORE,
      payload
    });
  };
}

export function showOrganizationShortLinkNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ORGANIZATION_SHORT_LINK_STORE,
        message || `Success!`
      )
    );
  };
}
