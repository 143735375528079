import startCase from "lodash/startCase";

const DASHBOARD = "dashboard";
const ANALYTICS = "analytics";

export const TAX_RATE_TABS = {
  DASHBOARD,
  ANALYTICS
};

export const DISPLAY_NAME_TYPE = "display_name_type";
export const CLUSIVITY = "clusivity";
export const TAX_RATE_FORM_KEY = {
  DISPLAY_NAME_TYPE,
  CLUSIVITY
};

const SALES_TAX = "SALES_TAX";
const VAT = "VAT";
const GST = "GST";
const CUSTOM = "CUSTOM";

export const TAX_RATE_DISPLAY_NAME = {
  SALES_TAX,
  VAT,
  GST,
  CUSTOM
};

const INCLUSIVE = "INCLUSIVE";
const EXCLUSIVE = "EXCLUSIVE";

export const TAX_RATE_CLUSIVITY = {
  INCLUSIVE,
  EXCLUSIVE
};

export const TAX_RATE_CLUSIVITY_LABEL_MAP = {
  [INCLUSIVE]: "Inclusive",
  [EXCLUSIVE]: "Exclusive"
};

export const TAX_CODE = {
  NONTAXABLE: "txcd_00000000",
  SHIPPING: "txcd_92010001"
};

const UNSPECIFIED = "unspecified";

export const TAX_BEHAVIOR = {
  UNSPECIFIED,
  EXCLUSIVE: EXCLUSIVE.toLowerCase(),
  INCLUSIVE: INCLUSIVE.toLowerCase()
};

export const SPECIFIED_TAX_BEHAVIORS = [
  TAX_BEHAVIOR.EXCLUSIVE,
  TAX_BEHAVIOR.INCLUSIVE
];

export const TAX_BEHAVIOR_OPTIONS = Object.values(TAX_BEHAVIOR).map(
  (behavior) => ({
    uid: behavior,
    label: startCase(behavior)
  })
);

/**
 * https://stripe.com/docs/api/tax_rates/create
 * These tax rate types have a label which can't be edited (aligned to Stripe dashboard UX)
 * The User will not see a text input when tax rate is one of these types
 */
const RESTRICTED_TAX_RATE_DISPLAY_NAME_OPTIONS = [
  {
    uid: SALES_TAX,
    label: "Sales Tax"
  },
  {
    uid: VAT,
    label: "VAT"
  },
  {
    uid: GST,
    label: "GST"
  }
];

export const TAX_RATE_DISPLAY_NAME_OPTIONS = [
  ...RESTRICTED_TAX_RATE_DISPLAY_NAME_OPTIONS,
  {
    uid: CUSTOM,
    label: "Custom"
  }
];

export const TAX_RATE_CLUSIVITY_OPTIONS = Object.values(TAX_RATE_CLUSIVITY).map(
  (type) => ({
    uid: type,
    label: startCase(type.toLowerCase())
  })
);

const STANDARD = "standard";
const INTERNATIONAL = "international";

export const TAX_ID_COLLECTION_TYPE = {
  STANDARD,
  INTERNATIONAL
};

export const TAX_ID_COLLECTION_TYPE_LABEL = {
  [TAX_ID_COLLECTION_TYPE.STANDARD]: "EU, UK, +4 others",
  [TAX_ID_COLLECTION_TYPE.INTERNATIONAL]: "US, EU, +22 others"
};

export const TAX_ID_COLLECTION_TYPE_DESCRIPTION = {
  [TAX_ID_COLLECTION_TYPE.STANDARD]:
    "Support for the European Union, Norway, Switzerland, United Kingdom, Australia and New Zealand.",
  [TAX_ID_COLLECTION_TYPE.INTERNATIONAL]: `Supports all countries in "Standard" plus United States, Japan, India, Brazil, Canada and more. This value will trigger a pre-checkout form page to capture the id.`
};

export const TAX_ID_COLLECTION_TYPE_STANDARD_OPTION = {
  id: TAX_ID_COLLECTION_TYPE.STANDARD,
  label: startCase(TAX_ID_COLLECTION_TYPE.STANDARD),
  description:
    TAX_ID_COLLECTION_TYPE_DESCRIPTION[TAX_ID_COLLECTION_TYPE.STANDARD],
  icon: "flag"
};

export const TAX_ID_COLLECTION_TYPE_INTERNATIONAL_OPTION = {
  id: TAX_ID_COLLECTION_TYPE.INTERNATIONAL,
  label: startCase(TAX_ID_COLLECTION_TYPE.INTERNATIONAL),
  description:
    TAX_ID_COLLECTION_TYPE_DESCRIPTION[TAX_ID_COLLECTION_TYPE.INTERNATIONAL],
  icon: "globe"
};

export const TAX_ID_COLLECTION_TYPE_OPTIONS = [
  TAX_ID_COLLECTION_TYPE_STANDARD_OPTION,
  TAX_ID_COLLECTION_TYPE_INTERNATIONAL_OPTION
];

export const DEFAULT_TAX_IDS = [
  {
    code: "US",
    codeId: "US EIN",
    country: "United States",
    description: "United States EIN",
    example: "12-3456789",
    id: "us_ein"
  }
];
