import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import map from "lodash/map";
import isNull from "lodash/isNull";
import _ from "lodash";

export const valueFormatter = (format, val) => {
  let result = val;
  if (format === "currency") {
    result = (result / 100).toFixed(2);
  }

  return result;
};

/*
 * omitAndSanitizeParams
 * ensure only sending valid and non-null values in request
 * allow array types if its values are objects
 * only omits keys on the top level object
 */
export const omitAndSanitizeParams = (params, keys = []) => {
  return _.chain(params)
    .omit(keys)
    .reduce((memo, val, key) => {
      if (val) {
        const arrayOfObjects = isArray(val) && isObject(val[0]);
        const objectOnly = isObject(val) && !isArray(val);
        if (arrayOfObjects) {
          memo[key] = map(val, (subVal) => {
            return omitAndSanitizeParams(subVal);
          });
        } else if (objectOnly) {
          memo[key] = omitAndSanitizeParams(val);
        } else {
          memo[key] = val;
        }
      } else if (!isNull(val) && typeof val !== "undefined") {
        /* TODO: any reason to restrict other falsy values */
        memo[key] = val;
      }

      return memo;
    }, {})
    .value();
};

export const sanitizeParams = (values) => {
  const result = {};
  if (values) {
    for (const key in values) {
      const value = values[key];
      if (value || typeof value === "boolean") {
        result[key] = value;
      }
    }
  }

  return result;
};
