import C from "constants/shortLink";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { setOrganizationShortLinks } from "./organizationShortLink";
import { setUserShortLinks } from "./userShortLink";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";
import { setMetadatas } from "./metadata";

export function setLoading() {
  return {
    type: C.UPDATE_SHORT_LINK_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setShortLinks = (payload) => {
  return {
    type: C.SET_SHORT_LINKS,
    payload
  };
};

export const removeProductFromCollections = (payload) => {
  return {
    type: C.REMOVE_SHORT_LINKS,
    payload
  };
};

export const replaceShortLinks = (payload) => {
  return {
    type: C.REPLACE_SHORT_LINKS,
    payload
  };
};

/**
 * resetShortLinkNotes
 */
export function resetShortLinkNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_SHORT_LINK_STORE,
      payload
    });
  };
}

export function showShortLinkNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_SHORT_LINK_STORE,
        message || `Success!`
      )
    );
  };
}

export const SHORT_LINK_MODELS = ["shortLinks"];

export const SHORT_LINK_ACTIONS_MAP = {
  setShortLinks,
  setOrganizationShortLinks,
  setUserShortLinks
};

/**
 * createShortLink
 */
export function createShortLink(
  { resourceID, resourceUUID, resource, data, test, suppress },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_SHORT_LINK_STORE,
        "create",
        "shortLink",
        "Create short link"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID,
      test
    })}/short-links`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const shortLinks = [responseData.shortLink];
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          shortLinks
        });

        const actions = reduceModelActions({
          keys: SHORT_LINK_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: SHORT_LINK_ACTIONS_MAP
        });

        if (responseData.metadata) {
          actions.push(setMetadatas([responseData.metadata]));
        }

        if (!suppress) {
          actions.push(
            setTimeout(() => {
              dispatch(
                ActionHelpers.successNote(
                  C.UPDATE_SHORT_LINK_STORE,
                  "Link created"
                )
              );
            }, 250)
          );
        }

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SHORT_LINK_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteShortLink
 */
export const deleteShortLink = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/short-links/${uuid}`;
    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        dispatch(
          batchActions([
            removeProductFromCollections([responseData]),
            ActionHelpers.successNote(C.UPDATE_SHORT_LINK_STORE, "Link deleted")
          ])
        );

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SHORT_LINK_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateShortLink
 */
export function updateShortLink(
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_SHORT_LINK_STORE,
        "update",
        "shortLink",
        "Update short link"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/short-links/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [setShortLinks([responseData])];

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_SHORT_LINK_STORE,
                "Link updated"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SHORT_LINK_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchShortLinks
 */
export function fetchShortLinks(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_SHORT_LINK_STORE,
        "update",
        "shortLink",
        "Fetch short links"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/short-links`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          shortLinks: responseData
        });

        const actions = reduceModelActions({
          keys: SHORT_LINK_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: SHORT_LINK_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SHORT_LINK_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

export const fetchShortLink = async ({
  onError,
  resource,
  resourceUUID,
  resourceID,
  uuid,
  dispatch
}) => {
  try {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/short-links/${uuid}`;

    const response = await axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    });

    const responseData = [response.data];

    const preparedResponse = prepareModelAssociations({
      resource,
      resourceID,
      shortLinks: responseData
    });

    const actions = reduceModelActions({
      keys: SHORT_LINK_MODELS,
      action: ACTION_TYPE.SET,
      resource,
      data: preparedResponse,
      actionsMap: SHORT_LINK_ACTIONS_MAP
    });

    dispatch(batchActions(actions));

    return response.data;
  } catch (error) {
    onError &&
      onError("Failed to fetch short link", {
        appearance: "error",
        autoDismiss: false
      });
    dispatch(
      batchActions(
        ActionHelpers.errorAndClear(
          C.UPDATE_SHORT_LINK_STORE,
          error,
          false,
          dispatch
        )
      )
    );
    return error;
  }
};
