import { RECURRING_INTERVALS } from "./constants";
import {
  CURRENCIES,
  CURRENCY_CODES,
  THOUSAND_BASED_CODES,
  MONTH_COUNT,
  CURRENCY_FORMAT
} from "./constants/currency";
import round from "lodash/round";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { BILLING_SCHEME } from "utils/constants/payment";
import { RECURRING_USAGE_TYPE } from "utils/constants/stripe";

const getLanguage = () =>
  typeof window !== "undefined" ? window.navigator.language : "en";

const DEFAULT_CURRENCY_CONFIG = CURRENCIES.find(
  ({ code }) => code === CURRENCY_CODES.USD.toUpperCase()
);

export const roundAmount = (amount, places = 2) => round(amount, places);

const DEFAULT_LOCALE = "en-us";

export const getCurrentLocale = () =>
  typeof window !== "undefined" ? window.navigator.language : DEFAULT_LOCALE;

/**
 * When saving a price we need to convert it from the presented form within the UI to the normalized per unit price for the payment gateway
 * - i.e $1 is 100 cents so we need to:
 * Strip the symbol from the string and convert the dollar to 100 cents
 * However for some currencies (JPY) we dont need to convert the value.
 * - 1 dollar is 100 cents but
 * - 1 yen is 1 yen
 * @param {String} currency
 */
export const currencyFactorForUnitAmount = (currency) =>
  THOUSAND_BASED_CODES.indexOf(currency) > -1 ? 1 : 100;

/**
 * Intended to be imprecise - favoring pretty look over precision
 */
export const prettyRoundUnitAmountWithFactor = ({
  amount,
  currency,
  divisor
}) => {
  const factor = currencyFactorForUnitAmount(currency);
  const value = Math.floor(amount / divisor / 100);

  return round(value * factor);
};

/**
 * When presenting the payment gateway amount we need need to coverted the unit amount to the formated amount
 * This allows the user to intrepet and edit the value on intuitive terms
 * i.e a user doesnt describe a dollar as 100 cents - they describe and edit it as a dollar with a decimal value for cents
 * We convert the unit amount to a formatted amount by dividing it by 100 except in the case of thousand based currencies like Yen
 * This is important because we use the Intl format lib to format the currency amount string for the input on the FE
 * - Without conversion the user would see inflated amounts
 * @param {String} currency
 */
export const currencyFactorForFormattedAmount = (currency) =>
  THOUSAND_BASED_CODES.indexOf(currency) > -1 ? 1 : 1 / 100;

// Omits symbol and keeps decimal places
export const formatUnitAmountNoSymbol = ({
  amount,
  currency = CURRENCY_CODES.USD
}) => {
  const ctxCurrency = isEmpty(currency) ? CURRENCY_CODES.USD : currency;
  const factor = currencyFactorForFormattedAmount(ctxCurrency);
  /**
   * No need to round here as the number formatter will take care of that
   */
  const amountToFormat = amount * factor;
  const result = new Intl.NumberFormat("en", {
    style: "currency",
    currency: ctxCurrency
  }).format(amountToFormat);
  return result.substr(1);
};

export const FACTORS = {
  THOUSAND: 1,
  HUNDRED: 1 / 100
};

export const getAmountFormatOptions = ({
  options,
  amount,
  factor = FACTORS.HUNDRED
}) => {
  let formatOptions = { ...options };
  const singleCents = amount < 10;

  if (singleCents && isEmpty(formatOptions) && factor === FACTORS.HUNDRED) {
    formatOptions = CURRENCY_FORMAT.MICRO_CENTS;
  }
  return formatOptions;
};

export const formatUnitAmountFloat = ({
  amount,
  currency = CURRENCY_CODES.USD,
  options
}) => {
  const ctxCurrency = isEmpty(currency) ? CURRENCY_CODES.USD : currency;
  const factor = currencyFactorForFormattedAmount(ctxCurrency);
  const formatOptions = getAmountFormatOptions({ options, amount });
  /**
   * No need to round here as the number formatter will take care of that
   */
  const amountToFormat = amount * factor;

  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: ctxCurrency,
    ...formatOptions
  }).format(amountToFormat);
};

export const formatUnitAmount = ({ amount, currency = CURRENCY_CODES.USD }) => {
  const ctxCurrency = isEmpty(currency) ? CURRENCY_CODES.USD : currency;
  return formatUnitAmountFloat({ amount, currency: ctxCurrency }).replace(
    /\.00$/,
    ""
  );
};

export const formatPresentationAmount = ({
  currency,
  amount,
  presentation
}) => {
  const ctxAmount = amount || 0;

  const result = new Intl.NumberFormat(getLanguage(), {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 4
  }).format(Math.abs(ctxAmount) / 100);

  return ctxAmount < 0
    ? presentation === "accounting"
      ? `(${result})`
      : `-${result}`
    : result;
};

export const getPriceUnitAmount = (price) =>
  Number.isInteger(price.amount) ? price.amount : price.unit_amount;

export const getPriceMinUnitAmount = (price) => {
  if (Number.isInteger(price.amount)) {
    return price.amount;
  }

  if (price.billing_scheme === BILLING_SCHEME.TIERED) {
    const firstTier = price.tiers[0];
    if (Number.isInteger(firstTier.unit_amount)) {
      return firstTier.unit_amount;
    } else if (firstTier.unit_amount_decimal) {
      return parseFloat(firstTier.unit_amount_decimal);
    }
  }

  if (
    price.recurring &&
    price.recurring.usage_type === RECURRING_USAGE_TYPE.METERED
  ) {
    if (Number.isInteger(price.unit_amount)) {
      return price.unit_amount;
    } else if (price.unit_amount_decimal) {
      return parseFloat(price.unit_amount_decimal);
    }
  }

  return price.unit_amount;
};

export const getMonthlyUnitPrice = (price) => {
  if (!price) {
    return 0;
  } else {
    const unitAmount = getPriceUnitAmount(price);
    const unit =
      price.interval === RECURRING_INTERVALS.YEAR
        ? unitAmount / MONTH_COUNT
        : unitAmount;

    return unit;
  }
};

export const formatPriceMonthly = (price) => {
  return `${formatUnitAmount({
    ...price,
    amount: getMonthlyUnitPrice(price)
  })} ${price.currency.toUpperCase()}`;
};

export const formatPriceMonthlyNoCurrency = (price) =>
  `${formatUnitAmount({
    ...price,
    amount: getMonthlyUnitPrice(price)
  })}`;

export const sanitizeFormattedNumber = (formattedNumber) =>
  Number(formattedNumber.replace(/[^0-9.]/g, ""));

export const formattedPriceToUnitAmount = ({ formattedPrice, currency }) => {
  if (!formattedPrice) {
    return 0;
  } else {
    const ctxCurrency = isEmpty(currency) ? CURRENCY_CODES.USD : currency;
    let sanitizedPrice = sanitizeFormattedNumber(formattedPrice);
    const factor = currencyFactorForUnitAmount(ctxCurrency);

    if (THOUSAND_BASED_CODES.indexOf(ctxCurrency) > -1) {
      sanitizedPrice = round(sanitizedPrice);
    }

    const result = Number(sanitizedPrice) * factor;

    // Round value incase there are any hanging places due to multiplication
    return Number.isNaN(result) ? 0 : round(result);
  }
};

export const getConfigForCurrency = (currency = CURRENCY_CODES.USD) => {
  const ctxCurrency = isEmpty(currency) ? CURRENCY_CODES.USD : currency;
  return CURRENCIES.find(
    (config) => config.code.toLowerCase() === ctxCurrency.toLowerCase()
  );
};

export const getCurrencyConfigForLocale = (locale = getCurrentLocale()) => {
  const downCaseLocale = locale.toLowerCase();
  const currencyConfigMatch = CURRENCIES.find(({ locales }) => {
    const searchable = compact(locales);
    return (
      searchable.length > 0 &&
      searchable.map((loc) => loc.toLowerCase()).indexOf(downCaseLocale) > -1
    );
  });

  return currencyConfigMatch || DEFAULT_CURRENCY_CONFIG;
};

export const optionsFromCurrencies = (currencies) =>
  currencies.map((cur) => {
    const curConfig = getConfigForCurrency(cur);
    const label = `${curConfig.symbol} ${cur.toUpperCase()}`;
    return {
      uid: cur,
      label
    };
  });

export const applyFeePercent = (amount, percent = 0, places = 2) =>
  roundAmount(amount * (percent / 100), places);

// Tax amount = Value inclusive of tax X tax rate ÷ (100 + tax rate)
export const getInclusiveTax = (amount, percentage) =>
  (amount * percentage) / (100 + percentage);

export const getExclusiveTax = (amount, percentage) =>
  amount * (percentage / 100);

export const applyTaxesAndDiscounts = ({
  subtotal = 0,
  coupons = [],
  taxRates = []
}) => {
  const total = subtotal;

  const discountAmount = coupons.reduce((memo, coupon) => {
    memo += coupon.percent_off
      ? subtotal * (coupon.percent_off / 100)
      : coupon.amount_off;
    return memo;
  }, 0);
  const totalNetDiscounts = total - roundAmount(discountAmount, 0);

  let inclusiveTaxes = 0;
  taxRates.forEach((taxRate) => {
    if (taxRate.inclusive) {
      inclusiveTaxes += getInclusiveTax(totalNetDiscounts, taxRate.percentage);
    }
  });
  const taxable = totalNetDiscounts - inclusiveTaxes;
  let exclusiveTaxes = 0;
  taxRates.forEach((taxRate) => {
    if (!taxRate.inclusive) {
      exclusiveTaxes += getExclusiveTax(taxable, taxRate.percentage);
    }
  });

  return totalNetDiscounts + exclusiveTaxes;
};

export const getSumInclusiveTaxes = ({ taxRates, totalNetDiscounts }) =>
  taxRates.reduce((memo, taxRate) => {
    if (taxRate.inclusive) {
      memo += roundAmount(
        getInclusiveTax(totalNetDiscounts, taxRate.percentage),
        0
      );
    }
    return memo;
  }, 0);
