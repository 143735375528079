import CONSTANTS from "constants/onboarding";
import { fromJS } from "immutable";
import helpers from "./helpers";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  error: false,
  fields: {},
  config: [],
  data: []
});

const internals = {
  /**
   * SET_ONBOARDINGS
   *
   * @param {Object} payload - array of packages
   * @api public
   */
  [CONSTANTS.SET_ONBOARDINGS]: (state, { payload = [] }) => {
    return helpers.setIn(state, ["data"], "id", payload);
  },
  /**
   * UPDATE_ONBOARDING_STORE
   *
   * @param {Object} payload
   *  @param {Bool} isLoading
   *  @param {Object} error
   * @api public
   */
  [CONSTANTS.UPDATE_ONBOARDING_STORE]: (state, { payload }) => {
    return state.mergeDeep(fromJS(payload));
  },
  /**
   * UPDATE_ONBOARDING_STORE_FORM
   *
   * @param {Object} payload
   *  @param {Bool} isLoading
   *  @param {Object} error
   * @api public
   */
  [CONSTANTS.UPDATE_ONBOARDING_STORE_FORM]: (state, { payload }) => {
    return state.mergeDeepIn(["fields"], fromJS(payload));
  },
  /**
   * UPDATE_ONBOARDING_STORE_FORM
   *
   * @param {Object} payload
   *  @param {Bool} isLoading
   *  @param {Object} error
   * @api public
   */
  [CONSTANTS.REPLACE_ONBOARDING_FORM_SECTION_VALUES]: (state, { payload }) => {
    const { uid } = payload;
    // console.log("payload: ", payload);
    const sections = state.getIn(["fields", "sections"]);
    const sectionIx = sections.findIndex((section) => {
      return section.get("uid") === uid;
    });
    if (sectionIx > -1) {
      state = state.setIn(
        ["fields", "sections", sectionIx, "values"],
        fromJS(payload.values)
      );
    }
    return state;
  },
  /**
   * SET_OR_REMOVE_ONBOARDING_FORM_SECTION_VALUES
   *
   * @param {Object} payload
   *  @param {Bool} isLoading
   *  @param {Object} error
   * @api public
   */
  [CONSTANTS.SET_OR_REMOVE_ONBOARDING_FORM_SECTION_VALUES]: (
    state,
    { payload }
  ) => {
    const { uid, values: payVals } = payload;
    const sections = state.getIn(["fields", "sections"]);

    const sectionIx = sections.findIndex((section) => {
      return section.get("uid") === uid;
    });
    if (sectionIx > -1 && payVals && payVals.length) {
      const values = state.getIn(["fields", "sections", sectionIx, "values"]);
      for (var i = 0; i < payVals.length; i++) {
        const payVal = payVals[i];
        const valueIx =
          values &&
          values.findIndex((value) => {
            if (value) {
              return value.get("uid") === payVal.uid;
            }
          });
        const value = fromJS(payVal);
        /*
          Value not present - add it
        */
        if (valueIx < 0) {
          let dataValues = state.getIn([
            "fields",
            "sections",
            sectionIx,
            "values"
          ]);
          dataValues = dataValues.push(fromJS(value));
          state = state.setIn(
            ["fields", "sections", sectionIx, "values"],
            dataValues
          );
        } else {
          /*
            Value present - remove it
          */
          state = state.removeIn([
            "fields",
            "sections",
            sectionIx,
            "values",
            valueIx
          ]);
        }
      }
    }
    return state;
  },
  /**
   * REMOVE_ONBOARDING_FORM_SECTION_VALUES
   *
   * @param {Object} payload
   *  @param {Bool} isLoading
   *  @param {Object} error
   * @api public
   */
  [CONSTANTS.REMOVE_ONBOARDING_FORM_SECTION_VALUES]: (state, { payload }) => {
    const { uid, values: payVals } = payload;
    const sections = state.getIn(["fields", "sections"]);

    const sectionIx = sections.findIndex((section) => {
      return section.get("uid") === uid;
    });
    if (sectionIx > -1 && payVals && payVals.length) {
      const values = state.getIn(["fields", "sections", sectionIx, "values"]);
      for (var i = 0; i < payVals.length; i++) {
        const payVal = payVals[i];
        const valueIx = values.findIndex((value) => {
          if (value) {
            return value.get("uid") === payVal.uid;
          }
        });
        /*
          Value present - add it
        */
        if (valueIx > -1) {
          state = state.removeIn([
            "fields",
            "sections",
            sectionIx,
            "values",
            valueIx
          ]);
        }
      }
    }
    return state;
  },
  /**
   * SET_UPDATE_ONBOARDING_FORM_SECTION_VALUES
   *
   * @param {Object} payload
   *  @param {Bool} isLoading
   *  @param {Object} error
   * @api public
   */
  [CONSTANTS.SET_UPDATE_ONBOARDING_FORM_SECTION_VALUES]: (
    state,
    { payload }
  ) => {
    const { uid } = payload;
    const sections = state.getIn(["fields", "sections"]);

    if (sections) {
      const sectionIx = sections.findIndex((section) => {
        return section.get("uid") === uid;
      });
      if (sectionIx > -1 && payload.values && payload.values.length) {
        const values = state.getIn(["fields", "sections", sectionIx, "values"]);
        const valueIx = values.findIndex((value) => {
          if (value) {
            return value.get("uid") === payload.values[0].uid;
          }
        });
        const value = fromJS(payload.values[0]);
        if (valueIx < 0) {
          let dataValues = state.getIn([
            "fields",
            "sections",
            sectionIx,
            "values"
          ]);
          dataValues = dataValues.push(fromJS(value));
          state = state.setIn(
            ["fields", "sections", sectionIx, "values"],
            dataValues
          );
        } else {
          state = state.mergeDeepIn(
            ["fields", "sections", sectionIx, "values", valueIx],
            value
          );
        }
      }
    }

    // console.log("SECTIONS: ", state.setIn(["fields", "sections"]).toJS());
    return state;
  },
  /**
   * RESET_ONBOARDING_STORE_FORM
   *
   * @param {Object} payload
   *  @param {Bool} isLoading
   *  @param {Object} error
   * @api public
   */
  [CONSTANTS.RESET_ONBOARDING_STORE_FORM]: (state, { payload }) => {
    // console.log("INIT payload: ", payload);
    return state.setIn(["fields"], fromJS(payload));
  },
  /**
   * UPDATE_ONBOARDING
   *
   * @param {Object} payload
   * @api public
   */
  [CONSTANTS.UPDATE_ONBOARDING]: (state, { payload = {} }) => {
    const { id } = payload;
    const data = state.get("data");

    const obIndex = data.findIndex((ob) => {
      return ob.get("id") === id;
    });
    if (obIndex >= 0) {
      state = state.mergeDeepIn(["data", obIndex], fromJS(payload));
    }

    return state;
  },
  /**
   * SET_ONBOARDING_CONFIG
   *
   * @param {Object} payload
   * @api public
   */
  [CONSTANTS.SET_ONBOARDING_CONFIG]: (state, { payload }) => {
    return helpers.setIn(state, ["config"], "id", payload);
  },
  /**
   * SET_ONBOARDING_CONFIG_OPTIONS
   *
   * @param {Object} state - reducer state
   * @param {Object} payload - payload config
   * @param {Object} payload.id - onboarding id
   * @param {Object} payload.field_id - field to set options on
   * @param {Object} payload.section_id - onboarding section targeted
   * @param {Object} payload.options - options to set
   * @api public
   */
  [CONSTANTS.SET_ONBOARDING_CONFIG_OPTIONS]: (state, { payload }) => {
    const data = state.getIn(["config"]);
    const configIx = data.findIndex((config) => {
      return config.get("id") === payload.id;
    });

    const sections = state.getIn(["config", configIx, "sections"]);
    if (sections) {
      const sectIx = sections.findIndex((sect) => {
        return sect.get("uid") === payload.section_id;
      });
      const entities = state.getIn([
        "config",
        configIx,
        "sections",
        sectIx,
        "entities"
      ]);
      if (entities) {
        const entityIx = entities.findIndex((entity) => {
          const fields = entity.get("fields");
          const fieldIx = fields.findIndex((field) => {
            return field.get("id") === payload.field_id;
          });
          return fieldIx > -1;
        });

        const fields = state.getIn([
          "config",
          configIx,
          "sections",
          sectIx,
          "entities",
          entityIx,
          "fields"
        ]);
        if (fields) {
          const fieldIx = fields.findIndex((field) => {
            return field.get("id") === payload.field_id;
          });
          if (fieldIx > -1) {
            // If options.length and [0] has key uid - apply uniqBy else return options
            const optionsSet = get(payload, "options.[0].uid")
              ? uniqBy(payload.options, "uid")
              : payload.options;

            state = state.setIn(
              [
                "config",
                configIx,
                "sections",
                sectIx,
                "entities",
                entityIx,
                "fields",
                fieldIx,
                "options"
              ],
              optionsSet
            );
          }
        }
      }
    }

    return state;
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
