import startCase from "lodash/startCase";
import { STATUS } from "utils/constants/ui";
import {
  OFF_SESSION_METHOD_TYPE_LABELS,
  PAYMENT_MODE as PAYMENT_MODES,
  RECURRING_PAYMENT_METHOD_TYPE_LABELS
} from "./payment";

const DASHBOARD = "dashboard";
const ANALYTICS = "analytics";
const EMBED = "embed";

export const SHORT_LINK_TABS = {
  DASHBOARD,
  ANALYTICS,
  EMBED
};

// Tax identification number
const TIN = "tin";
const EMAIL = "email";
const PHONE = "phone";
export const SHORT_LINK_FIELDS = {
  TIN,
  EMAIL,
  PHONE
};

export const SHORT_LINK_FIELD_UIDS = Object.values(SHORT_LINK_FIELDS);

export const SHORT_LINK_FIELD_LABEL_MAP = {
  [SHORT_LINK_FIELDS.TIN]: "Tax id number",
  [SHORT_LINK_FIELDS.EMAIL]: "Email",
  [SHORT_LINK_FIELDS.PHONE]: "Phone"
};

export const SHORT_LINK_FIELD_KEYS = {
  PRE: "pre",
  POST: "post"
};

export const SHORT_LINK_ACTION_UID = {
  MERCHANT_CHECKOUT: "merchant_checkout"
};

const ACTIVE = "active";
const INACTIVE = "inactive";
const ARCHIVED = "archived";

export const SHORT_LINK_STATUS = {
  ACTIVE,
  INACTIVE,
  ARCHIVED
};

export const SHORT_LINK_STATUS_THEME = {
  [SHORT_LINK_STATUS.ACTIVE]: STATUS.DEFAULT,
  [SHORT_LINK_STATUS.INACTIVE]: STATUS.WARNING,
  [SHORT_LINK_STATUS.ARCHIVED]: STATUS.DANGER
};

export const SHORT_LINK_MODE_THEME = {
  [PAYMENT_MODES.SETUP]: STATUS.DEFAULT,
  [PAYMENT_MODES.PAYMENT]: STATUS.SUCCESS,
  [PAYMENT_MODES.SUBSCRIPTION]: STATUS.SUCCESS
};

export const SHORT_LINK_STATUS_OPTIONS = [
  {
    uid: SHORT_LINK_STATUS.ACTIVE,
    label: startCase(SHORT_LINK_STATUS.ACTIVE),
    description: "Link is active and ready to be used."
  },
  {
    uid: SHORT_LINK_STATUS.INACTIVE,
    label: startCase(SHORT_LINK_STATUS.INACTIVE),
    description: "Link is inactive and attempts to used will be blocked."
  },
  {
    uid: SHORT_LINK_STATUS.ARCHIVED,
    label: startCase(SHORT_LINK_STATUS.ARCHIVED),
    description: "Link is archived and not available to be used."
  }
];

export const PRICE_CURRENCY_CONFLICT_VALIDATION_MESSAGE =
  "All prices must be in the same currency.";

export const FIXED_COUPON_CURRENCY_CONFLICT_VALIDATION_MESSAGE =
  "All fixed amount coupon selections must be in the same currency as selected prices.";

export const SHIPPING_RATE_CURRENCY_CONFLICT_VALIDATION_MESSAGE =
  "All shipping option selections must be in the same currency as selected prices.";

export const SHIPPING_RATE_INTERVAL_CONFLICT_VALIDATION_MESSAGE =
  "Shipping options are only supported for one time charges.";

export const INTERVAL_CONFLICT_VALIDATION_MESSAGE =
  "All prices which are not one time charges, must have the same billing interval.";

export const PAYMENT_METHOD_TYPE_INTERVAL_CONFLICT_VALIDATION_MESSAGE = `Only the ${RECURRING_PAYMENT_METHOD_TYPE_LABELS} payment methods are allowed for recurring prices.`;

export const ZERO_QUANTITY_VALIDATION_MESSAGE =
  "All prices must have a minimum quantity of 1.";

export const ZERO_TOTAL_VALIDATION_MESSAGE =
  "The total of your selections must be greater than zero.";

export const SHIPPING_RATE_MISSING_TAX_BEHAVIOR_VALIDATION_MESSAGE =
  "Shipping options must have a tax behavior to be used with automatic taxes.";

export const PRICE_MISSING_TAX_BEHAVIOR_VALIDATION_MESSAGE =
  "Prices must have a specified tax behavior to be used with automatic taxes.";

export const TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE =
  "All price selections must have the same tax behavior when automatic tax collection is enabled.";

export const PRICE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE =
  "All price selections with tax behaviors must match the behaviors of selected tax rates.";

export const SHIPPING_RATE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE =
  "All shipping option selections must have the same tax behavior when automatic tax collection is enabled.";

export const PRICE_SHIPPING_RATE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE =
  "All prices and shipping option selections must have the same tax behavior when automatic tax collection is enabled.";

/**
 * Payment authorization
 */
export const CAPTURE_METHOD_MINIMUM_PAYMENT_METHOD_MESSAGE =
  "To use payment authorization, you must have the card payment method selected.";

export const CAPTURE_METHOD_CONFLICT_PAYMENT_METHOD_MESSAGE = `Only the "Card" payment method is allowed to be used for payment authorization.`;

export const CAPTURE_METHOD_CONFLICT_MODE_MESSAGE =
  "Payment authorization can only be used with one time charges. Recurring charges are not supported.";

/**
 * Future usage
 * * - only validate off_session usage
 */
export const SETUP_FUTURE_USAGE_CONFLICT_PAYMENT_METHOD_MESSAGE = `Only the ${OFF_SESSION_METHOD_TYPE_LABELS} payment methods are allowed for future usage.`;
export const SETUP_FUTURE_USAGE_MINIMUM_PAYMENT_METHOD_MESSAGE = `To setup a payment method for future usage you must select one or mote of these payment methods: ${OFF_SESSION_METHOD_TYPE_LABELS}.`;

export const SUBSCRIPTION_SCHEDULE_MODE_MESSAGE =
  "Installment payments require recurring prices.";

const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
const CHECKOUT_CANCEL = "CHECKOUT_CANCEL";
const SHIPPING_DESTINATIONS = "SHIPPING_DESTINATIONS";
const PAYMENT_MODE = "PAYMENT_MODE";
const PAYMENT_METHODS = "PAYMENT_METHODS";
const SUBSCRIPTION_SCHEDULE = "SUBSCRIPTION_SCHEDULE";
const CAPTURE_METHOD = "CAPTURE_METHOD";
const SETUP_FUTURE_USAGE = "SETUP_FUTURE_USAGE";

export const SHORT_LINK_SETTINGS = {
  CHECKOUT_SUCCESS,
  CHECKOUT_CANCEL,
  SHIPPING_DESTINATIONS,
  PAYMENT_MODE,
  PAYMENT_METHODS,
  SUBSCRIPTION_SCHEDULE,
  CAPTURE_METHOD,
  SETUP_FUTURE_USAGE
};

export const SHORT_LINK_FORM_TYPE = {
  SETUP: "setup",
  PRICE: "price",
  PRICES: "prices",
  PRODUCTS: "products",
  AUTHORIZE: "authorize"
};

export const SHORT_LINK_INTERSTITIAL_TITLE_COLLECTION = {
  AUTHORIZED: ["Authorize payment", "Charge later", "Securing form"],
  STANDARD: ["Starting checkout", "Securing form", "Preparing amount"]
};

const MESSAGES = "messages";
const DETAILS = "details";
const INFO = "info";
const PREVIEW = "preview";
const PRODUCTS = "products";
const COUPONS = "coupons";
const TAX = "tax";
const TRIAL = "trial";
const PAYMENT = "payment";
const RECEIVER = "receiver";
const SHIPPING = "shipping";
const ATTACHMENTS = "attachments";
const REDIRECT = "redirect";
const CONFIRMATION = "confirmation";
const CHECKOUT_PREVIEW = "checkout_preview";
export const SHORT_LINK_NODE_KEY = {
  MESSAGES,
  INFO,
  DETAILS,
  PREVIEW,
  PRODUCTS,
  COUPONS,
  ATTACHMENTS,
  TAX,
  TRIAL,
  PAYMENT,
  RECEIVER,
  SHIPPING,
  REDIRECT,
  CONFIRMATION,
  CHECKOUT_PREVIEW
};
