import {
  formattedPriceToUnitAmount,
  formatUnitAmount,
  formatUnitAmountFloat,
  getCurrencyConfigForLocale,
  getPriceMinUnitAmount,
  optionsFromCurrencies
} from "components/FormerEditor/common/currency";
import {
  getTemplatePrice,
  PRICE_TYPE,
  TRANSACTION_MAX,
  TRANSACTION_MIN,
  FULL_INTERVAL_SHORTHAND_MAP
} from "utils/constants/price";
import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import reject from "lodash/reject";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import startCase from "lodash/startCase";
import {
  BILLING_SCHEME,
  INTERVAL_LABELS_MAP,
  RECURRING_INTERVALS
} from "./constants/payment";
import { STATE_KEYS } from "./constants/state";
import { formatUpperFirst, pluralizeCopy } from "./copy";
import { getFieldPath } from "./form";
import { PRICES_SCHEMA } from "constants/validation";
import {
  prepareMetadataFieldArray,
  processCreateMetadata,
  processUpdateMetadata
} from "./metadata";
import { reduceKeyDifferences } from "./data";
import { validateSchema } from "./validate";
import { PRICE_VARIES_LABEL } from "components/FormerEditor/common/preview";

const INTERVAL_COUNT_MAX_MAP = {
  [RECURRING_INTERVALS.DAY]: 364,
  [RECURRING_INTERVALS.WEEK]: 52,
  [RECURRING_INTERVALS.MONTH]: 12,
  [RECURRING_INTERVALS.YEAR]: 1
};

// https://stripe.com/docs/api/prices/create
export const prepareCreatePrices = (prices) => {
  return omitAndSanitizeParams({
    prices: prices.map((values) => preparePriceFromValues(values))
  });
};

export const preparePriceFromValues = ({
  id,
  uuid,
  nickname,
  recurring,
  currency,
  formattedPrice,
  tax_behavior,
  unitAmount,
  product,
  active,
  metadata
}) => {
  const result = omitAndSanitizeParams({
    id,
    uuid,
    product,
    nickname,
    recurring,
    currency,
    active,
    tax_behavior
  });

  if (Number.isInteger(unitAmount)) {
    result.unit_amount = unitAmount;
  } else {
    result.unit_amount = formattedPriceToUnitAmount({
      formattedPrice,
      currency
    });
  }

  if (result.recurring && !result.recurring.interval) {
    delete result.recurring;
  }

  /**
   * Process metadata - setting null where values are empty so that they are unset within Stripe
   * https://stripe.com/docs/api/products/update#update_product-metadata
   */
  if (metadata) {
    result.metadata = processCreateMetadata(metadata);
  }

  return result;
};

export const prepareInitialValuesFromPrice = ({
  id,
  uuid,
  unit_amount,
  metadata,
  nickname,
  product,
  recurring,
  active,
  currency,
  tax_behavior
}) => {
  const result = {
    id,
    uuid,
    nickname: nickname || "",
    product,
    recurring,
    active,
    currency,
    metadata: prepareMetadataFieldArray(metadata),
    unit_amount,
    formattedPrice: formatUnitAmount({
      amount: unit_amount,
      currency
    })
  };
  if (tax_behavior) {
    result.tax_behavior = tax_behavior;
  }
  return result;
};

/**
 * Scaffold new prices
 * @param {String} uid - Optional product uid to use
 */
export const getCreatePricesInitialValues = (uid) => ({
  prices: [getTemplatePrice(uid)]
});

/**
 * Remove inactive prices from the primaries collection
 * @param {Array} prices
 * @param {Array} primaries
 */
export const removeInactivePricePrimaries = (prices, primaries) => {
  const inactivePriceIds = prices.reduce((memo, price) => {
    if (!price.active) {
      memo.push(price.id);
    }
    return memo;
  }, []);
  const removeInactivePrimaries = inactivePriceIds.length > 0;

  if (removeInactivePrimaries) {
    return reject(
      primaries,
      ({ price }) => inactivePriceIds.indexOf(price) >= 0
    );
  }
};

export const deconstructFormattedPrice = (price) => {
  const result = {
    symbol: "",
    formatted: ""
  };

  if (price) {
    var nbsp = String.fromCharCode(160);
    var space = String.fromCharCode(32);
    const priceParts = price.split(nbsp);

    if (priceParts.length > 1) {
      result.symbol = priceParts[0];
      result.formatted = priceParts[1].split(space)[0];
    } else {
      const chars = price.split(" ")[0].split("");
      let numIx;

      for (let charIx = 0; charIx < chars.length; charIx++) {
        const char = chars[charIx];
        const charInt = parseInt(char);

        if (!Number.isNaN(charInt)) {
          numIx = charIx;
          break;
        }
      }

      result.symbol = chars.slice(0, numIx).join("");
      result.formatted = chars.slice(numIx).join("");
    }
  }
  return result;
};

const priceToPrimary = (price) => ({
  product: price.product,
  price: price.id,
  interval: get(price, "recurring.interval"),
  currency: price.currency
});

export const getPrimariesWithNewPrices = ({ primaries, prices }) => {
  const result = cloneDeep(primaries);
  let hasMatches = false;
  primaries.forEach((primary, primaryIndex) => {
    const matchingPrice = prices.find(
      (price) =>
        primary.interval === get(price, "recurring.interval") &&
        primary.product === price.product &&
        primary.currency === price.currency
    );
    if (matchingPrice) {
      hasMatches = true;
      result.splice(primaryIndex, 1, priceToPrimary(matchingPrice));
    }
  });

  /**
   * If there havent been any matches
   * Then the product for the prices is transitioning from no prices to some prices
   * So then we should add one for every interval we can find
   */
  if (!hasMatches) {
    Object.values(RECURRING_INTERVALS).forEach((interval) => {
      const priceMatch = prices.find(
        (price) => get(price, "recurring.interval") === interval
      );
      if (priceMatch) {
        result.push(priceToPrimary(priceMatch));
      }
    });
  }

  return result;
};

export const resolvePrimariesOnPriceRemoval = ({
  pricePrimaries,
  prices,
  setFieldValue,
  setFieldTouched
}) => {
  const primaries = [...pricePrimaries];

  prices.forEach(({ id }) => {
    const priceMatchIndex = primaries.findIndex(
      (primary) => primary.price === id
    );
    if (priceMatchIndex > -1) {
      primaries.splice(priceMatchIndex, 1);
    }
  });

  setFieldValue(STATE_KEYS.MANIFEST.PRICING_PRIMARIES, primaries);
  setFieldTouched(STATE_KEYS.MANIFEST.PRICING_PRIMARIES, true);
};

export const getPriceCurrencies = (prices) =>
  prices.reduce((memo, { currency }) => {
    if (memo.indexOf(currency) === -1) {
      memo.push(currency);
    }
    return memo;
  }, []);

export const getPricesCurrencyOptions = (prices) => {
  const currencies = getPriceCurrencies(prices);
  return optionsFromCurrencies(currencies);
};

export const getPriceForCurrencyLocale = (prices = []) => {
  const currencyConfig = getCurrencyConfigForLocale();
  const localCurrency = currencyConfig.code.toLowerCase();
  return prices.find(({ currency }) => currency === localCurrency);
};

export const getInitialActiveCurrency = ({ price, product }) => {
  return price
    ? price.currency
    : product.prices.length
    ? product.prices[0].currency
    : null;
};

export const filterProductPricesStatusCurrency = ({
  productUid,
  currency,
  showInactive,
  prices
}) => {
  return prices.filter((price) => {
    const isProductMatch = price.product === productUid;
    // Filter by currency if a currency is active
    const currencyMatch = currency ? price.currency === currency : true;
    if (showInactive) {
      return isProductMatch && currencyMatch;
    } else {
      return isProductMatch && currencyMatch && price.active;
    }
  });
};

const getInitialMemoGroups = () => [
  {
    uid: PRICE_TYPE.ONE_TIME,
    label: formatUpperFirst(PRICE_TYPE.ONE_TIME),
    sections: [
      {
        label: "All",
        uid: "all",
        prices: []
      }
    ]
  },
  ...Object.values(RECURRING_INTERVALS).reduce((memo, interval) => {
    memo.push({
      uid: interval,
      label: startCase(INTERVAL_LABELS_MAP[interval]),
      sections: [
        {
          label: "All",
          uid: "all",
          prices: []
        }
      ]
    });
    return memo;
  }, [])
];

export const orderSectionPrices = (sections) =>
  sections.map((section) => {
    section.prices = orderBy(section.prices, ["unit_amount"], ["asc"]);
    return section;
  });

export const getRecurringLabelForIntervalGroup = (count, interval) => {
  const countInt = parseInt(count);
  const labelPart =
    interval === RECURRING_INTERVALS.MONTH && countInt === 3
      ? "quarter"
      : pluralizeCopy(interval, countInt);

  return `Every ${labelPart}`;
};

export const breakdownPricesByIntervalCounts = ({ uid, prices }) => {
  const countGroups = groupBy(prices, "recurring.interval_count");
  const countKeys = Object.keys(countGroups);
  let result;
  /**
   * Breakdown the sections by interval_count if:
   * - there are multiple interval counts
   * - there only interval count is greater than 1
   */
  const shouldBreakdown = countKeys.length > 1 || parseInt(countKeys[0]) > 1;
  if (shouldBreakdown) {
    result = [];
    countKeys.forEach((countKey) => {
      const label = getRecurringLabelForIntervalGroup(countKey, uid);

      result.push({
        label,
        prices: countGroups[countKey]
      });
    });
  }
  return result;
};

export const groupProductPrices = (prices) => {
  return prices
    .reduce((memo, price) => {
      const interval =
        price.type === PRICE_TYPE.ONE_TIME
          ? PRICE_TYPE.ONE_TIME
          : price.recurring.interval;
      const groupIndex = memo.findIndex(({ uid }) => uid === interval);
      if (groupIndex > -1) {
        memo[groupIndex].sections[0].prices.push(price);
      }

      return memo;
    }, getInitialMemoGroups())
    .reduce((memo, group) => {
      const hasAnyPrices = group.sections[0].prices.length > 0;
      if (hasAnyPrices) {
        const updatedGroup = { ...group };
        updatedGroup.sections = orderSectionPrices(updatedGroup.sections);

        if (updatedGroup.uid !== PRICE_TYPE.ONE_TIME) {
          const additionalSections = breakdownPricesByIntervalCounts({
            uid: updatedGroup.uid,
            prices: updatedGroup.sections[0].prices
          });
          if (additionalSections && additionalSections.length > 0) {
            updatedGroup.sections = updatedGroup.sections.concat(
              additionalSections
            );
          }
        }
        memo.push(updatedGroup);
      }

      return memo;
    }, []);
};

export const priceIntervalCountLabel = (price) => {
  if (price.recurring) {
    if (price.recurring.interval_count > 1) {
      return getRecurringLabelForIntervalGroup(
        price.recurring.interval_count,
        price.recurring.interval
      );
    } else {
      return startCase(INTERVAL_LABELS_MAP[price.recurring.interval]);
    }
  } else {
    return "One time";
  }
};

export const getCopyPriceIdProps = (price) => ({
  value: price.id,
  toast: price.nickname
    ? `Copied ${price.nickname} id to clipboard`
    : `Copied price id to clipboard`,
  tooltip: price.nickname ? `Copy ${price.nickname} id` : `Copy id`
});

export const getStripeFormattedAmount = (price) => {
  if (price.billing_scheme === BILLING_SCHEME.TIERED) {
    const firstTier = price.tiers[0];
    const startPrice = formatUnitAmountFloat({
      amount: firstTier.unit_amount,
      currency: price.currency
    });
    const priceParts = [`Starts at ${startPrice} per unit`];
    if (firstTier.flat_amount) {
      const flatPrice = formatUnitAmountFloat({
        amount: firstTier.flat_amount,
        currency: price.currency
      });
      priceParts.push(`+ ${flatPrice} / ${price.recurring.interval}`);
    }

    return priceParts.join(" ");
  } else {
    return formatUnitAmount({
      amount: getPriceMinUnitAmount(price),
      currency: price.currency
    });
  }
};

export const stripePricesRange = (prices) => {
  let result = "";

  if (prices.length) {
    const hasTieredPrice = prices.find(
      ({ billing_scheme }) => billing_scheme === BILLING_SCHEME.TIERED
    );
    if (hasTieredPrice) {
      result = PRICE_VARIES_LABEL;
    } else {
      const orderedPrices = uniqBy(
        orderBy(prices, "unit_amount"),
        "unit_amount"
      );
      const firstPrice = orderedPrices[0];

      const formattedFirstPrice = formatUnitAmount({
        amount: getPriceMinUnitAmount(firstPrice),
        currency: firstPrice.currency
      });
      const hasRange = orderedPrices.length > 1;

      if (hasRange) {
        const lastPrice = orderedPrices.pop();
        result = [
          formattedFirstPrice,
          formatUnitAmount({
            amount: getPriceMinUnitAmount(lastPrice),
            currency: lastPrice.currency
          })
        ].join(" - ");
      } else {
        result = formattedFirstPrice;
      }
    }
  }

  return result;
};

export const getIntervalLabel = (interval, count) => {
  let intervalLabel;
  const intervalShorthand = FULL_INTERVAL_SHORTHAND_MAP[interval];
  if (intervalShorthand && count) {
    const formattedShorthand =
      count > 1 ? pluralizeCopy(intervalShorthand, count) : intervalShorthand;

    intervalLabel = `/ ${formattedShorthand}`;
  }
  return intervalLabel;
};

export const getCheckoutIntervalLabel = (interval, count) => {
  let intervalLabel = interval;

  let delimeter = "per";
  if (count) {
    if (count > 1) {
      delimeter = "every";
      intervalLabel = pluralizeCopy(interval, count);
    } else {
      intervalLabel = interval;
    }
  }

  return `${delimeter} ${intervalLabel}`;
};

export const validatePriceFormattedPrice = (values, name) => {
  const formattedPricePath = getFieldPath(name, "formattedPrice");
  const recurringPath = getFieldPath(name, "recurring");
  const formattedPrice = get(values, formattedPricePath);
  const recurring = get(values, recurringPath);
  const currencyPath = getFieldPath(name, "currency");
  const currency = get(values, currencyPath);

  const amount = formattedPriceToUnitAmount({
    formattedPrice,
    currency
  });
  let error;

  /**
   * One time prices: must be greater than 0
   * Subscription prices: can be 0
   */
  const isOneTimePrice = !(recurring && recurring.interval);
  if (isOneTimePrice) {
    if (!amount) {
      error = `Amount must be greater than zero`;
    } else if (amount >= TRANSACTION_MAX) {
      const formattedMax = formatUnitAmount({
        amount: TRANSACTION_MAX,
        currency
      });
      const { formatted: maxValue } = deconstructFormattedPrice(formattedMax);
      error = `Amount must be less than ${maxValue}`;
    } else if (amount < TRANSACTION_MIN) {
      const formattedMin = formatUnitAmount({
        amount: TRANSACTION_MIN,
        currency
      });
      const { formatted: minValue } = deconstructFormattedPrice(formattedMin);
      error = `Amount minimum is ${minValue}`;
    }
  }

  return error;
};

export const validatePriceRecurringIntervalCount = (values, name) => {
  const recurringInterval = getFieldPath(name, "recurring.interval");
  const recurringIntervalCountPath = getFieldPath(
    name,
    "recurring.interval_count"
  );
  let error;
  const interval = get(values, recurringInterval);
  const intervalCount = get(values, recurringIntervalCountPath);
  const maxVal = INTERVAL_COUNT_MAX_MAP[interval];

  if (!intervalCount) {
    error = `Interval count must be greater than zero`;
  } else if (maxVal && intervalCount > maxVal) {
    error = `${startCase(interval)} interval max is ${maxVal}`;
  }

  return error;
};

export const validatePrice = (values, name = "", validations = {}) => {
  const result = cloneDeep(validations);

  const formattedPricePath = getFieldPath(name, "formattedPrice");
  const formattedPriceError = validatePriceFormattedPrice(values, name);
  if (formattedPriceError) {
    set(result, formattedPricePath, formattedPriceError);
  }

  const recurringPath = getFieldPath(name, "recurring");
  const recurring = get(values, recurringPath);
  if (recurring && recurring.interval) {
    const recurringIntervalCountPath = getFieldPath(
      name,
      "recurring.interval_count"
    );
    const recurringIntervalCountError = validatePriceRecurringIntervalCount(
      values,
      name
    );

    if (recurringIntervalCountError) {
      set(result, recurringIntervalCountPath, recurringIntervalCountError);
    }
  }

  return result;
};

export const validatePriceSchema = async (values) => {
  let validResult = await validateSchema(PRICES_SCHEMA, values);

  values.prices.forEach((_, priceIx) => {
    const rootPath = `prices[${priceIx}]`;
    validResult = validatePrice(values, rootPath, validResult);
  });

  return validResult;
};

export const getUpdatePriceParams = (initial, current) => {
  const result = reduceKeyDifferences(
    ["nickname", "active", "tax_behavior"],
    initial,
    current
  );

  /**
   * Process metadata - setting null where values are empty so that they are unset within Stripe
   * https://stripe.com/docs/api/products/update#update_product-metadata
   */
  result.metadata = processUpdateMetadata(initial.metadata, current.metadata);

  return !isEmpty(result)
    ? {
        id: initial.id,
        ...result
      }
    : null;
};

/**
 * The user facing price label for the picker
 * @param {Object} price
 * @returns
 */
export const getPricePickerPriceLabel = (price) => {
  let result = "Set a price";
  if (price) {
    if (price.formattedPrice) {
      result = price.formattedPrice;
    } else if (price.recurring && price.recurring.interval) {
      result = formatUnitAmount({
        amount: 0,
        currency: price.currency
      });
    }
  }
  return result;
};
