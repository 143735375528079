import axios from "axios";
import pick from "lodash/pick";
import isError from "lodash/isError";
import isEmpty from "lodash/isEmpty";
import PATHS, { API_PATHS } from "../paths";
import { getConfig } from "utils/env";
import { omitQueryStringParams } from "utils/url";
import { captureException } from "../error";
import { sanitizeParams } from "components/Form/fields/helpers";
import { extendQS } from "utils/route";
import { setStoreIdentityField } from "utils/identity";
import { STRIPE_SESSION_ID_QS_PARAM } from "../constants/stripe";

const QS_OMISSION_KEYS = ["celebrate"];

const getRequestParams = (props, base) => {
  const result = {
    ...base,
    alias: props.alias,
    subdomain: props.subdomain,
    cancelUrl: omitQueryStringParams(
      props.cancelUrl || window.location.href,
      QS_OMISSION_KEYS
    ),
    checkout: {
      /**
       * Includes client_reference_id (e.g. Rewardful)
       */
      ...props.clientCheckout,
      ...props.checkout
    },
    tracking: props.tracking
  };
  if (props.tracking && props.tracking.route && !result.route) {
    result.route = props.tracking.route;
  }

  return sanitizeParams(result);
};

const createStripeSessionAndRedirect = async ({
  url,
  data,
  stripe,
  setLoading,
  onError
}) => {
  let result;
  let error;
  setLoading(true);

  try {
    if (!stripe) {
      throw new Error("Stripe is not initialized");
    }
    const response = await axios({
      method: "post",
      url,
      data
    });

    if (isError(response)) {
      error = response;
    } else if (response.data && response.data.id) {
      result = response.data;
      stripe.redirectToCheckout({
        sessionId: response.data.id
      });
    } else {
      error = new Error("No valid session id for checkout");
    }
  } catch (err) {
    error = err;
  }

  if (error) {
    captureException(error);
    onError && onError(error);
  }

  setLoading(false);
  return result;
};

/**
 * Fetch checkout session for subdomain price
 * - Then redirect to checkout
 * @param {Object} params
 * @param {Object} params.stripe
 * @param {Function} params.setLoading
 * @param {String} params.alias
 * @param {String} params.subdomain
 * @param {String} params.price
 */
export const checkoutPrice = (props) => async () => {
  const contextProps = {
    price: props.price
  };
  return await createStripeSessionAndRedirect({
    stripe: props.stripe,
    setLoading: props.setLoading,
    onError: props.onError,
    url: API_PATHS.CHECKOUT,
    data: getRequestParams(props, contextProps)
  });
};

/**
 * Fetch checkout session for subdomain price
 * - The redirect to checkout
 * @param {Object} params
 * @param {Object} params.stripe
 * @param {Function} params.setLoading
 * @param {String} params.subdomain
 * @param {String} params.price
 */
export const checkoutFlexPrices = (props) => async () => {
  const contextProps = {
    prices: props.prices
  };
  return await createStripeSessionAndRedirect({
    stripe: props.stripe,
    setLoading: props.setLoading,
    onError: props.onError,
    url: API_PATHS.CHECKOUT_FLEX,
    data: getRequestParams(props, contextProps)
  });
};

/**
 * @param {Object} params
 * @param {Object} params.stripe
 * @param {Function} params.setLoading
 * @param {String} params.subdomain
 * @param {String} params.price
 */
export const checkoutCart = (props) => async () => {
  const contextProps = {
    cart: props.cart
  };
  return await createStripeSessionAndRedirect({
    stripe: props.stripe,
    setLoading: props.setLoading,
    onError: props.onError,
    url: API_PATHS.CHECKOUT_CART,
    data: getRequestParams(props, contextProps)
  });
};

/**
 * @param {Object} params
 * @param {Object} params.stripe
 * @param {Function} params.setLoading
 * @param {String} params.subdomain
 * @param {String} params.price
 */
export const checkoutForm = (props) => async () => {
  const contextProps = {
    formData: props.formData
  };
  return await createStripeSessionAndRedirect({
    stripe: props.stripe,
    setLoading: props.setLoading,
    onError: props.onError,
    url: API_PATHS.CHECKOUT_FORM,
    data: getRequestParams(props, contextProps)
  });
};

/**
 * Initiate a checkout request with url and params
 * - redirect to stripe checkout if there is a session id present
 * - else redirect to a redirect parameter if provided
 * - else set content for page
 * @param {Object} params
 * @param {Object} params.stripe - client initialized Stripe.js instance
 * @param {String} params.url - request url like /links/abc123?customer_email=something
 * @param {Function} params.setLoading
 * @param {Function} params.setError
 * @param {Function} params.setContent
 * @param {Object} params.headers - additional x headers
 * @returns
 */
export const checkoutShortLink = async ({
  stripe,
  url,
  setLoading,
  setError,
  setContent,
  headers
}) => {
  setLoading(true);
  try {
    if (!stripe) {
      throw new Error("Stripe is not initialized");
    }
    const requestInput = {
      method: "get",
      url
    };
    if (!isEmpty(headers)) {
      requestInput.headers = headers;
    }

    const { data } = await axios(requestInput);
    if (data) {
      if (data.session && data.session.id) {
        stripe.redirectToCheckout({
          sessionId: data.session.id
        });
        /**
         * Early return so that we dont trigger the setLoader update and a FOUC while browser is redirecting to external link
         */
        return;
      } else if (data.redirect) {
        window.location.href = data.redirect;
      } else if (data.content && setContent) {
        setContent(data.content);
      }
    }

    setLoading(false);
  } catch (error) {
    captureException(error);
    console.log(error);
    setLoading(false);
    setError(error);
  }
};

/**
 * Initiate a checkout request with url and params
 * - redirect to stripe checkout if there is a session id present
 * - else redirect to a redirect parameter if provided
 * - else set content for page
 * @param {Object} params
 * @param {Object} params.stripe - client initialized Stripe.js instance
 * @param {String} params.url - request url like /pay/1000/usd?customer_email=something
 * @param {Function} params.setLoading
 * @param {Function} params.setError
 * @param {Function} params.setContent
 * @param {Object} params.headers - additional x headers
 * @returns
 */
export const checkoutPay = async ({
  stripe,
  url,
  setLoading,
  setError,
  setContent,
  headers
}) => {
  setLoading(true);
  try {
    if (!stripe) {
      throw new Error("Stripe is not initialized");
    }
    const requestInput = {
      method: "get",
      url
    };
    if (!isEmpty(headers)) {
      requestInput.headers = headers;
    }

    const { data } = await axios(requestInput);
    if (data) {
      if (data.session && data.session.id) {
        stripe.redirectToCheckout({
          sessionId: data.session.id
        });
        /**
         * Early return so that we dont trigger the setLoader update and a FOUC while browser is redirecting to external link
         */
        return;
      } else if (data.redirect) {
        window.location.href = data.redirect;
      } else if (data.content && setContent) {
        setContent(data.content);
      }
    }

    setLoading(false);
  } catch (error) {
    captureException(error);
    console.log(error);
    setLoading(false);
    setError(error);
  }
};

// =================================================
// BILLING
// https://stripe.com/docs/api/customer_portal/create
// =================================================
export const buildBillingParams = ({ customer }) => ({
  customer,
  return_url: typeof window !== "undefined" ? window.location.href : ""
});

/**
 * Fetch billing session
 * - Then redirect to billing portal page
 * @param {Object} params
 * @param {Object} params.session
 * @param {Function} params.setLoading
 */
export const billing = ({ session, setLoading, setError }) => async () => {
  setLoading(true);
  try {
    const { data } = await axios({
      method: "post",
      url: API_PATHS.BILLING_SESSION,
      headers: {
        Authorization: `Bearer ${getConfig("PRICE_BLOCS_PUB_KEY")}`
      },
      data: {
        session
      }
    });
    if (isError(data)) {
      setError && setError(data);
      captureException(data);
    } else if (data && data.url) {
      window.location.href = data.url;
    }

    setLoading(false);
  } catch (error) {
    setError && setError(error);
    setLoading(false);
    throw error;
  }
};

/**
 * =================================================
 * IDENTITY
 * https://stripe.com/docs/identity/verify-identity-documents
 * =================================================
 * @param {Object} params
 * @param {Object} params.stripe
 * @param {Function} params.setLoading
 * @param {String} params.subdomain
 * @param {String} params.price
 */
export const checkoutIdentity = ({
  stripe,
  setLoading,
  onError,
  onSuccess,
  ...props
}) => async () => {
  let result;
  let error;
  setLoading(true);

  if (!stripe) {
    error = new Error("Stripe is not initialized");
  } else {
    try {
      /**
       * Steps
       * 1. Create a verification session
       * 2. Exchange the session client secret to open the document modal
       * -- client_secret OTT
       * -- [consumed] - when session is started but not completed
       * -- [not consumed] - when session is cancelled
       * 3. Pass any user action verification errors up to the parent
       * Note:
       * - A verification can be submitted and have an error on it but not throw an error
       * - Theres a difference between a failed verification request and a document which fails verification
       */

      // 1. Create verification session
      const { data } = await axios({
        method: "post",
        url: API_PATHS.CHECKOUT_IDENTITY,
        data: getRequestParams(props, {
          formData: props.formData,
          returnUrl: omitQueryStringParams(
            props.returnUrl || window.location.href,
            QS_OMISSION_KEYS
          )
        })
      });
      result = data;

      if (isError(result)) {
        error = result;
      } else if (result.client_secret) {
        // 2. Open modal to initiate verification
        const { error: verifyError } = await stripe.verifyIdentity(
          result.client_secret
        );
        /**
         * 3. Pass any user action verification errors up to the parent
         * verifyError
         * {
         *  type: 'user_action',
         *  code: 'session_cancelled' | 'consent_declined'
         * }
         */
        if (verifyError) {
          error = verifyError;
        } else {
          setStoreIdentityField(result.storage);
          onSuccess && onSuccess(result.storage.value);

          console.log("Verification submitted.");
        }
      } else {
        error = new Error("No valid session id for checkout");
      }
    } catch (err) {
      error = err;
    }
  }

  if (error) {
    if (isError(error)) {
      captureException(error);
    }
    onError && onError(error);
  }

  setLoading(false);
  return result;
};

// =================================================
// CHECKOUT
// https://stripe.com/docs/api/checkout/sessions/object
// - User of PriceBlocs is attempting to subscribe
// - Make sure to encode the subscription data into the platform request object
// ================================================
export const buildPlatformSubscriptionCheckoutParams = ({
  customer,
  resource,
  resourceUUID,
  successUrl
}) => {
  let success_url = successUrl
    ? `${successUrl}?${STRIPE_SESSION_ID_QS_PARAM}`
    : "";

  if (typeof window !== "undefined") {
    const hrefParts = window.location.href.split("?");
    if (!success_url) {
      success_url = `${hrefParts[0]}?${STRIPE_SESSION_ID_QS_PARAM}`;
      if (hrefParts[1]) {
        success_url += `&${hrefParts[1]}`;
      }
    } else {
      success_url += `&return_to=${window.location.pathname}`;
    }
  }

  return {
    customer,
    client_reference_id: resourceUUID,
    success_url,
    /**
     * Set resource in subscription metadata so that we can perform a lookup and association when processing webhooks
     */
    subscription_data: {
      metadata: {
        resourceUUID,
        resource
      }
    }
  };
};

/**
 * Pass the session params to the BE to encrypt
 * - It will then be used as a query string param when redirecting to a client side route
 * @param {Object} session
 * @returns String
 */
export const getSealedSessionPayload = async (session) => {
  const PUB_KEY = getConfig("PRICE_BLOCS_PUB_KEY");

  try {
    const { data } = await axios({
      method: "post",
      url: API_PATHS.CHECKOUTS_SESSION,
      headers: {
        Authorization: `Bearer ${PUB_KEY}`
      },
      data: {
        session
      }
    });

    return data;
  } catch (error) {
    return error;
  }
};

/**
 * Fetch checkout session
 * - Then redirect to pricing page with encrypted session
 * @param {Object} params
 * @param {Object} params.session
 * @param {Function} params.setLoading
 */
export const checkout = ({ session, setLoading, setError }) => async () => {
  setLoading(true);
  try {
    const sealedSession = await getSealedSessionPayload(session);
    let href = `${getConfig("WEB_CLIENT_ROOT")}${PATHS.PRICING}`;

    if (isError(sealedSession)) {
      setError && setError(sealedSession);
      captureException(sealedSession);
    } else {
      href += `?session=${sealedSession}`;
    }
    window.location.href = href;

    setLoading(false);
  } catch (error) {
    setError && setError(error);
    setLoading(false);
    throw error;
  }
};

/**
 * Pick out only the values for the fields uid keys (e.g. tin)
 * - These values will be sent in in the checkout formData request
 * @param {Obkect} formValues - form values object
 * @param {Array} fields - collection of content fields
 * @returns
 */
export const pickCheckoutFormDataFields = (formValues, fields) => {
  const fieldUIDs = fields.reduce((memo, { uid }) => {
    if (memo.indexOf(uid) === -1) {
      memo.push(uid);
    }
    return memo;
  }, []);

  return pick(formValues, fieldUIDs);
};

export const prepareCheckoutFieldsUrl = ({ formValues, url, fields }) => {
  return extendQS(url, {
    customer_email: formValues.customer_email,
    cancel_url: window.location.href,
    form_data: pickCheckoutFormDataFields(formValues, fields)
  });
};
