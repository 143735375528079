import * as Yup from "yup";

export const FORMATTED_PRICE = "formattedPrice";
export const NICKNAME = "nickname";
export const SCHEDULE = "schedule";
export const TAX_BEHAVIOR = "tax_behavior";

export const PRICE_FIELDS = {
  FORMATTED_PRICE,
  NICKNAME,
  SCHEDULE,
  TAX_BEHAVIOR
};

export const DEFAULT_PRICE_FIELDS = [
  FORMATTED_PRICE,
  NICKNAME,
  SCHEDULE,
  TAX_BEHAVIOR
];

export const BLOCK_TABS = {
  VALUES: "values",
  SETTINGS: "settings",
  VALIDATIONS: "validations"
};

export const RESERVED_PATTERNS = {
  EMAIL: "email",
  TIN: "tin"
};

export const PATTERN_OPTIONS = [
  {
    id: RESERVED_PATTERNS.EMAIL,
    label: "Email",
    description: "Value must be a valid email format.",
    pattern: RESERVED_PATTERNS.EMAIL
  }
];

const ADD_FIELD = "ADD_FIELD";
export const FIELD_GROUP_INTENT = {
  ADD_FIELD
};

export const FIELD_VALIDATORS = {
  [RESERVED_PATTERNS.EMAIL]: Yup.string().email("Invalid email address")
};

export const FIELD_KEYS = {
  DEFAULT: "default"
};
