import CONSTANTS from "constants/merchantAccount";
import { fromJS } from "immutable";
import { MOCK_UUID } from "utils/constants/request";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const internals = {
  [CONSTANTS.SET_MERCHANT_ACCOUNTS]: (state, { payload }) => {
    return helpers.setIn(state, ["data"], "id", payload);
  },
  [CONSTANTS.SET_MERCHANT_ACCOUNT_CONFIG]: (
    state,
    { payload: { uuid, config } }
  ) => {
    const merchantAccounts = state.getIn(["data"]);

    const merchantAccountIx = merchantAccounts.findIndex((model) => {
      return model.get("uuid") === uuid;
    });

    if (merchantAccountIx > -1) {
      state = state.setIn(
        ["data", merchantAccountIx, "merchant_account_config"],
        fromJS(config)
      );

      return state;
    }
    return state;
  },
  [CONSTANTS.UNSET_MOCK_MERCHANT_ACCOUNT]: (state) => {
    state = helpers.inActionAtIndex("remove", state, ["data"], MOCK_UUID, [
      "id"
    ]);
    return state;
  },
  [CONSTANTS.SET_MERCHANT_ACCOUNT_VALUES]: (
    state,
    { payload: { uuid, ...values } }
  ) => {
    const merchantAccounts = state.getIn(["data"]);
    const merchantAccountIx = merchantAccounts.findIndex((model) => {
      return model.get("uuid") === uuid;
    });

    if (merchantAccountIx > -1) {
      for (const key in values) {
        const value = values[key];
        state = state.setIn(["data", merchantAccountIx, key], fromJS(value));
      }

      return state;
    }
    return state;
  },
  [CONSTANTS.REMOVE_MERCHANT_ACCOUNTS]: (state, { payload }) => {
    for (let i = 0; i < payload.length; i++) {
      const model = payload[i];
      const { id } = model;
      state = helpers.inActionAtIndex("remove", state, ["data"], id, ["id"]);
    }

    return state;
  },
  [CONSTANTS.REPLACE_MERCHANT_ACCOUNTS]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  [CONSTANTS.UPDATE_MERCHANT_ACCOUNT_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
