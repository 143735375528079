const DEFAULT = "default";
const HIDDEN = "hidden";
const VIRTUAL = "virtual";
export const SCHEMA_CATEGORY = {
  DEFAULT,
  HIDDEN,
  VIRTUAL
};

const SELECT = "select";
const TEMPLATE_FIELD = "template-field";
const PICKER = "picker";
export const SCHEMA_COMPONENT = {
  TEMPLATE_FIELD,
  SELECT,
  PICKER
};
