import C from "constants/userEntitlementConfig";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_USER_ENTITLEMENT_CONFIG_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserEntitlementConfigs = (payload) => {
  return {
    type: C.SET_USER_ENTITLEMENT_CONFIGS,
    payload
  };
};

export const replaceUserEntitlementConfigs = (payload) => {
  return {
    type: C.REPLACE_USER_ENTITLEMENT_CONFIGS,
    payload
  };
};

/**
 * resetUserEntitlementConfigNotes
 */
export function resetUserEntitlementConfigNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_USER_ENTITLEMENT_CONFIG_STORE,
      payload
    });
  };
}

export function showUserEntitlementConfigNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_USER_ENTITLEMENT_CONFIG_STORE,
        message || `Success!`
      )
    );
  };
}
