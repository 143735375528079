import {
  PAYMENT_INTENT_STATUS,
  INVOICE_KEYS,
  PAYMENT_METHOD_CAPABILITY_KEYS,
  PAYMENT_METHOD_ALLOWED_COUNTRY_MAP,
  PAYMENT_METHOD_TYPE,
  CAPABILITY_STATUS,
  INTERVAL_MULTIPLES_MAP
} from "utils/constants/payment";
import { devLogger } from "utils/debug";
import get from "lodash/get";
import startCase from "lodash/startCase";
import {
  formatUnitAmount,
  roundAmount
} from "components/FormerEditor/common/currency";
import { CURRENCY_CODES } from "components/FormerEditor/common/constants/currency";
import { findProductForPrice } from "utils/product";
import { STATUS } from "./constants/ui";

export const requiresPaymentMethod = (data) =>
  data.latest_invoice.payment_intent.status ===
  PAYMENT_INTENT_STATUS.REQUIRES_PAYMENT_METHOD;

export const setInvoiceLocalStorageValues = (data) => {
  window.localStorage.setItem(INVOICE_KEYS.ID, data.latest_invoice.id);
  window.localStorage.setItem(
    INVOICE_KEYS.STATUS,
    data.latest_invoice.payment_intent.status
  );
};

export const paymentIntentWithRequiredAction = (
  { data, isRetry } = { isRetry: false }
) => {
  devLogger("paymentIntentWithRequiredAction.data", data, false);

  const paymentIntent =
    data.payment_intent || data.latest_invoice.payment_intent;

  if (
    paymentIntent &&
    (paymentIntent.status === PAYMENT_INTENT_STATUS.REQUIRES_ACTION ||
      (isRetry === true &&
        paymentIntent.status === PAYMENT_INTENT_STATUS.REQUIRES_PAYMENT_METHOD))
  ) {
    return paymentIntent;
  }
};

export const handleCardChange = ({
  paymentMethodId,
  setFieldError,
  setFieldValue
}) => {
  return async (event) => {
    devLogger("stripeCardChangeEvent", event, false);

    if (event.error && !paymentMethodId) {
      setFieldError("card", event.error.message);
    } else {
      setFieldError("card", "");
    }
    setFieldValue("complete", Boolean(event.complete));
  };
};

export const calculateInvoice = ({ products, items }) => {
  const totalPrice = {
    currency: CURRENCY_CODES.USD,
    amount: 0
  };
  const lineItems = items.map(({ price, quantity }) => {
    const product = findProductForPrice({ products, id: price });
    const priceMatch = product.prices.find(
      (prodPrice) => prodPrice.id === price
    );
    const sanitizedName = product.name;
    const label = `${sanitizedName} x ${quantity} licenses`;

    const itemAmount = priceMatch.amount * quantity;

    totalPrice.amount += itemAmount;
    return {
      label,
      amount: formatUnitAmount({
        ...priceMatch,
        amount: itemAmount
      })
    };
  });

  lineItems.push({
    label: "Due today",
    amount: formatUnitAmount(totalPrice)
  });
  return lineItems;
};

export const getPriceItemsForInterval = ({ products, items, interval }) => {
  const allPrices = products.reduce((memo, product) => {
    memo = memo.concat(product.prices);
    return memo;
  }, []);

  return items.reduce((memo, item) => {
    const priceMatch = allPrices.find(({ id }) => id === item.price);
    if (priceMatch) {
      const priceMatchForProductInterval = allPrices.find(
        (price) =>
          price.product === priceMatch.product && price.interval === interval
      );

      if (priceMatchForProductInterval) {
        memo.push({
          price: priceMatchForProductInterval.id,
          quantity: item.quantity
        });
      }
    }
    return memo;
  }, []);
};

export const getPriceForInterval = (prices, interval) =>
  prices.find((price) => price.interval === interval);

export const itemsSumTotal = ({ products, items }) => {
  const totalAmount = items.reduce((memo, { price, quantity }) => {
    const product = findProductForPrice({ products, id: price });

    if (product) {
      const priceMatch =
        product && product.prices.find((prodPrice) => prodPrice.id === price);

      if (priceMatch) {
        const itemAmount = roundAmount(priceMatch.amount * quantity);

        memo += itemAmount;
      }
    }
    return memo;
  }, 0);

  return formatUnitAmount({
    amount: totalAmount,
    currency: items[0].currency
  });
};

export const formatSubscriptionItems = ({ products, items }) => {
  return items.reduce((memo, { price, quantity }) => {
    const product = findProductForPrice({ products, id: price });

    if (product) {
      const priceMatch =
        product && product.prices.find((prodPrice) => prodPrice.id === price);

      if (priceMatch) {
        const label = `${product.name} x ${quantity}`;

        const itemAmount = roundAmount(priceMatch.amount * quantity);
        let formattedAmount = formatUnitAmount({
          ...priceMatch,
          amount: itemAmount
        });
        if (priceMatch.interval) {
          formattedAmount += ` / ${priceMatch.interval}`;
        }

        memo.push({
          label,
          amount: formattedAmount
        });
      }
    }
    return memo;
  }, []);
};

/**
 * Payment Method options are restricted depending on the originating account
 * - If your account origin is in Ireland, you are ineligible for Afterpay / Clearpay and it wont be shown
 * - Account capability status may be pending approval so it is disabled until then
 * - The list of options is limited to those which are allowed for Stripe checkout
 * - https://stripe.com/payments/payment-methods-guide
 * @param {Object} params
 * @param {Object} params.account - Stripe account
 * @param {Array<id, label>} params.options - collection of select options
 */
const UNKNOWN_CAPABILITY_PAYMENT_METHODS = {
  [PAYMENT_METHOD_TYPE.ALIPAY]: true,
  [PAYMENT_METHOD_TYPE.WECHAT_PAY]: true
};

const AVAILABLE = "available";
const UNAVAILABLE = "unavailable";

const AVAILABILITY_KEYS = {
  AVAILABLE,
  UNAVAILABLE
};

export const getPaymentMethodOptions = ({ account, options }) => {
  const capabilities = get(account, "capabilities", {});
  const originCountry = get(account, "country");

  return options.reduce(
    (memo, option) => {
      /**
       * 1. Pick the right stripe capability key
       */
      const capabilityKeyForOption = PAYMENT_METHOD_CAPABILITY_KEYS[option.id];
      /**
       * 2. Some digital wallet payment methods are not exposed by capability check
       */
      const isUnknown = UNKNOWN_CAPABILITY_PAYMENT_METHODS[option.id];
      /**
       * 3. Get payment method capability status for the account
       */
      const capabilityStatus = isUnknown
        ? CAPABILITY_STATUS.UNKNOWN
        : capabilities[capabilityKeyForOption] || CAPABILITY_STATUS.INACTIVE;
      const capabilityActive = capabilityStatus === CAPABILITY_STATUS.ACTIVE;
      /**
       * 4. Check whether type is restricted by account country
       */
      const countrySet = PAYMENT_METHOD_ALLOWED_COUNTRY_MAP[option.id] || [];
      const countryEnabled = countrySet.indexOf(originCountry) > -1;
      const unknownEnabled = isUnknown && countryEnabled;
      /**
       * Can request access if status is nether active or pending
       */
      const requestAccess =
        [CAPABILITY_STATUS.ACTIVE, CAPABILITY_STATUS.PENDING].indexOf(
          capabilityStatus
        ) === -1;

      let enabled = Boolean(countryEnabled && capabilityActive);

      if (unknownEnabled) {
        enabled = unknownEnabled;
      }
      const common = {
        ...option,
        disabled: !enabled,
        capabilityStatus,
        requestAccess
      };
      let pill = null;
      let key;

      if (countryEnabled) {
        key = AVAILABILITY_KEYS.AVAILABLE;

        if (requestAccess && capabilityStatus !== CAPABILITY_STATUS.UNKNOWN) {
          pill = {
            copy: "Request access"
          };
        } else if (capabilityStatus === CAPABILITY_STATUS.PENDING) {
          pill = {
            copy: startCase(CAPABILITY_STATUS.PENDING),
            status: STATUS.WARNING
          };
        }
      } else {
        key = AVAILABILITY_KEYS.UNAVAILABLE;
        pill = {
          copy: "Unavailable",
          status: STATUS.WARNING
        };
      }

      memo[key].push({
        ...common,
        pill
      });

      return memo;
    },
    {
      [AVAILABILITY_KEYS.AVAILABLE]: [],
      [AVAILABILITY_KEYS.UNAVAILABLE]: []
    }
  );
};

/**
 * Normalize current unit price to upgrade unit price
 * - Lookup the interval translation factor
 * - Use as an input into the calculation
 */
export const getUpgradeIntervalDiscount = ({ currentPrice, upgradePrice }) => {
  const normalizeFactor =
    INTERVAL_MULTIPLES_MAP[upgradePrice.recurring.interval][
      currentPrice.recurring.interval
    ];

  const normalizedUpgradePrice = upgradePrice.unit_amount * normalizeFactor;
  const normalizedSavings = currentPrice.unit_amount - normalizedUpgradePrice;

  const discountPct = (normalizedSavings / currentPrice.unit_amount) * 100;
  return Math.round(discountPct * 100) / 100;
};
