import css from "styled-jsx/css";
import { EMBED_LAYOUT, EMBED_CONTAINER_CLASS } from "utils/constants/embed";

/**
 * @param {Object} query
 * @param {Boolean} query.embed - true | false
 * @param {String} query.embed_layout - split | single
 * @returns
 */
export const getEmbedStyles = () => {
  /**
   * Optionally generate dynamic classes based on embed query props
   */
  return css.resolve`
    :global(.${EMBED_CONTAINER_CLASS}-${EMBED_LAYOUT.SPLIT}) {
      min-height: 560px;
    }
    :global(.${EMBED_CONTAINER_CLASS}-${EMBED_LAYOUT.SINGLE}) {
      min-height: 560px;
      max-width: 480px;
    }
  `;
};
