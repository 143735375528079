import C from "constants/app";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import ActionHelpers, { withMethod } from "./helpers";
import { parallel } from "async";
import { authBearerToken } from "utils/auth";
import { getConfig } from "utils/env";
import { METHODS } from "utils/constants/auth";

/**
 * setAppCapabilities
 */
export function setAppCapabilities(payload = []) {
  return {
    type: C.SET_APP_CAPABILITIES,
    payload
  };
}

/**
 * setAppProducts
 */
export function setAppProducts(payload = []) {
  return {
    type: C.SET_APP_PRODUCTS,
    payload
  };
}

/**
 * setAppEntities
 */
export function setAppEntities(payload = []) {
  return {
    type: C.SET_APP_ENTITIES,
    payload
  };
}

/**
 * setAppTemplates
 */
export function setAppTemplates(payload = {}) {
  return {
    type: C.SET_APP_TEMPLATES,
    payload
  };
}

/**
 * setAppVariables
 */
export function setAppVariables(payload = {}) {
  return {
    type: C.SET_APP_VARIABLES,
    payload
  };
}

/**
 * setAppFonts
 */
export function setAppFonts(payload = {}) {
  return {
    type: C.SET_APP_FONTS,
    payload
  };
}

export const setAppPagesTemplatesIndex = (payload = {}) => ({
  type: C.SET_APP_PAGES_TEMPLATES_INDEX,
  payload
});

export const setAppPagesTemplate = (payload) => ({
  type: C.SET_APP_PAGES_TEMPLATE,
  payload
});

/**
 * setAppRates
 */
export function setAppRates(payload = {}) {
  return {
    type: C.SET_APP_RATES,
    payload
  };
}

/**
 * setAppCheckout
 */
export function setAppCheckout(payload = {}) {
  return {
    type: C.SET_APP_CHECKOUT,
    payload
  };
}

/**
 * setAppBuilder
 */
export function setAppBuilder(payload = {}) {
  return {
    type: C.SET_APP_BUILDER,
    payload
  };
}

/**
 * setAppOnboarding
 */
export function setAppOnboarding(payload = []) {
  return {
    type: C.SET_APP_ONBOARDING,
    payload
  };
}

/** resetAppNotes
 */
export function resetAppNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_APP_STORE,
      payload
    });
  };
}

export function showAppNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_APP_STORE, message || `Success!`)
    );
  };
}

export function fetchAppEntities(callback = () => {}) {
  return (dispatch) =>
    withMethod({
      method: METHODS.GET,
      url: `${getConfig("API_ROOT")}/v1/app/entities`,
      onComplete: (data) => {
        const entities = Array.isArray(data) ? data : [data];
        return setAppEntities(entities);
      },
      constant: C.UPDATE_APP_STORE,
      dispatch,
      callback
    });
}

/**
 * fetchAppTemplates
 */
export function fetchAppTemplates(callback = () => {}) {
  return (dispatch) => {
    const REQUEST_URL = `${getConfig("API_ROOT")}/v1/app/templates`;

    return axios({
      method: METHODS.GET,
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_APP_STORE),
            setAppTemplates(data)
          ])
        );
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_APP_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

const fetchAppCapabilities = (callback = () => {}) => {
  const REQUEST_URL = `${getConfig("API_ROOT")}/v1/capabilities/app`;
  return axios
    .get(REQUEST_URL)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch((err) => {
      callback(err);
    });
};

const fetchOnboardingConfiguration = (callback = () => {}) => {
  const REQUEST_URL = `${getConfig("API_ROOT")}/v1/config/onboarding`;
  return axios
    .get(REQUEST_URL)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch((err) => {
      callback(err);
    });
};

export function fetchAppFonts(callback = () => {}) {
  return (dispatch) =>
    withMethod({
      method: METHODS.GET,
      url: `${getConfig("API_ROOT")}/v1/app/fonts`,
      onComplete: setAppFonts,
      constant: C.UPDATE_APP_STORE,
      dispatch,
      callback
    });
}

export function fetchAppPagesTemplatesIndex(callback = () => {}) {
  return (dispatch) =>
    withMethod({
      method: METHODS.GET,
      url: `${getConfig("API_ROOT")}/v1/templates/pages/index`,
      onComplete: setAppPagesTemplatesIndex,
      constant: C.UPDATE_APP_STORE,
      dispatch,
      callback
    });
}

export function fetchAppPagesTemplate(uuid, callback = () => {}) {
  return (dispatch) =>
    withMethod({
      method: METHODS.GET,
      url: `${getConfig("API_ROOT")}/v1/templates/pages/${uuid}`,
      onComplete: setAppPagesTemplate,
      constant: C.UPDATE_APP_STORE,
      dispatch,
      callback
    });
}

/**
 * fetchAppRates
 */
export function fetchAppRates(base, callback = () => {}) {
  return (dispatch) => {
    const REQUEST_URL = `${getConfig("API_ROOT")}/v1/app/rates?base=${base}`;

    return axios({
      method: METHODS.GET,
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_APP_STORE),
            setAppRates(data)
          ])
        );
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_APP_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchAppCheckout
 * - fetch necessary constants for checkout e.g.
 * -- shipping destinations
 */
export function fetchAppCheckout(callback = () => {}) {
  return (dispatch) => {
    const REQUEST_URL = `${getConfig("API_ROOT")}/v1/app/checkout`;

    return axios({
      method: METHODS.GET,
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_APP_STORE),
            setAppCheckout(data)
          ])
        );
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_APP_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchAppBuilder
 * - fetch stub collections of stripe records to sanitize / order the test records used within the builder
 * - only required for unauth resource builders (link | pages)
 */
export function fetchAppBuilder(callback = () => {}) {
  return (dispatch) => {
    const REQUEST_URL = `${getConfig("API_ROOT")}/v1/app/builder`;

    return axios({
      method: METHODS.GET,
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(C.UPDATE_APP_STORE),
            setAppBuilder(data)
          ])
        );
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_APP_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchAppConfiguration
 */
export function fetchAppConfiguration(callback = () => {}) {
  return (dispatch) => {
    return parallel(
      {
        // App capabilities deprecated in favor of resource capabilities?
        capabilities: fetchAppCapabilities,
        onboarding: fetchOnboardingConfiguration
      },
      (err, data) => {
        if (err) {
          /**
           * Unsure of why there is a cors error for certain resources
           */
          const suppress = true;

          dispatch(
            batchActions(
              ActionHelpers.errorAndClear(
                C.UPDATE_APP_STORE,
                err,
                suppress,
                dispatch
              )
            )
          );
        } else {
          dispatch(
            batchActions([
              ActionHelpers.clearLoader(C.UPDATE_APP_STORE),
              setAppCapabilities(data.capabilities || []),
              setAppOnboarding(data.onboarding || {})
            ])
          );
        }
        callback(err);
      }
    );
  };
}
