import format from "date-fns/format";
import { getToday } from "utils/date";

export const ISO_PATTERN = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const US_DATE_TIME_FORMAT = "MM-dd-yyyy";
export const DATE_TYPE_FORMAT = {
  [US_DATE_TIME_FORMAT]: true,
  // 1 MM/DD/YY Month-Day-Year with leading zeros (02/17/2009)
  //   [MONTH_DAY_YEAR_LEADING]:
  "MM/dd/yyyy": true,
  //   [DAY_MONTH_YEAR_LEADING]:
  // 2 DD/MM/YY Day-Month-Year with leading zeros (17/02/2009)
  "dd/MM/yyyy": true,
  //   [YEAR_MONTH_DAY_LEADING]:
  // 3 YY/MM/DD Year-Month-Day with leading zeros (2009/02/17)
  "yyyy/MM/dd": true,
  //   [DAY_MONTH_YEAR_LEADING]:
  // 2 DD/MM/YY Day-Month-Year with leading zeros (17/02/2009)
  "dd-MM-yyyy": true,
  //   [YEAR_MONTH_DAY_LEADING]:
  // 3 YY/MM/DD Year-Month-Day with leading zeros (2009/02/17)
  "yyyy-MM-dd": true,
  // 4 Month D, Yr Month name-Day-Year with no leading zeros (February 17, 2009)
  "MMMM d, yyyy": true,
  // 5 M/D/YY Month-Day-Year with no leading zeros (2/17/2009)
  "M/d/yyyy": true,
  // 6 D/M/YY Day-Month-Year with no leading zeros (17/2/2009)
  "d/M/yyyy": true,
  // 7 YY/M/D Year-Month-Day with no leading zeros (2009/2/17)
  "yyyy/d/M": true,
  "M-d-yyyy": true,
  // 6 D/M/YY Day-Month-Year with no leading zeros (17/2/2009)
  "d-M-yyyy": true,
  // 7 YY/M/D Year-Month-Day with no leading zeros (2009/2/17)
  "yyyy-d-M": true,
  // J D Month, Yr Day-Month name-Year (17 February, 2009)
  "d MMMM, yyyy": true,
  // K Yr, Month D Year-Month name-Day (2009, February 17)
  "yyyy, MMMM d": true,
  // L * Mon-DD-YYYY Month abbreviation, Day with leading zeros, Year (Feb 17, 2009)
  "MMM dd, yyyy": true,
  // M * DD-Mon-YYYY Day with leading zeros, Month abbreviation, Year 17 Feb, 2009.
  "dd MMM, yyyy": true,
  // N YYYYY-Mon-DD Year, Month abbreviation, Day with leading zero (2009, Feb 17)
  "yyyy, MMM dd": true,
  // Q YYYY, Mon DD Year, Month abbreviation, Day with leading zero (2014, Feb 17)
  "yyyy, MMM dd ": true,
  // B MMDDYY Month-Day-Year with no separators (02172009)
  "MMddyyyy": true,
  // C DDMMYY Day-Month-Year with no separators (17022009)
  "ddMMyyyy": true,
  // D YYMMDD Year-Month-Day with no separators (20090217)
  "yyyyMMdd": true,
  // E MonDDYY Month abbreviation-Day-Year with leading zeros (Feb172009)
  "MMMddyyyy": true,
  // F DDMonYY Day-Month abbreviation-Year with leading zeros (17Feb2009)
  "ddMMMyyyy": true,
  // G YYMonDD Year-Month abbreviation-Day with leading zeros (2009Feb17)
  "yyyyMMMdd": true,
  //   [ISO]:
  [ISO_PATTERN]: true
};

const TYPE_OPTIONS = [];

for (const pattern in DATE_TYPE_FORMAT) {
  TYPE_OPTIONS.push({
    label: pattern,
    uid: pattern,
    value: pattern,
    description: format(getToday(), pattern)
  });
}

export const DATEPICKER_FORMAT_TYPE_OPTIONS = TYPE_OPTIONS;

const ANY = "any";
const PAST = "past";
const FUTURE = "future";
const RANGE = "range";
export const DATEPICKER_TIMEFRAME = {
  ANY,
  PAST,
  FUTURE,
  RANGE
};

export const DATEPICKER_TIMEFRAME_OPTIONS = [
  {
    label: "Any",
    uid: DATEPICKER_TIMEFRAME.ANY,
    description: "Any date."
  },
  {
    label: "Past",
    uid: DATEPICKER_TIMEFRAME.PAST,
    description: "Only past dates."
  },
  {
    label: "Future",
    uid: DATEPICKER_TIMEFRAME.FUTURE,
    description: "Only future dates."
  }
  // {
  //   label: "Range",
  //   uid: DATEPICKER_TIMEFRAME.RANGE,
  //   description: "Only dates between a specified min and max range."
  // }
];

const DATE = "date";
const DATETIME_LOCAL = "datetime-local";

export const DATE_INPUT_TYPE = {
  DATE,
  DATETIME_LOCAL
};
