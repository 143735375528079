import { ONBOARDING_CATEGORIES } from "utils/onboarding";
import { reducerKeys } from "reducers/index";
import flatten from "lodash/flatten";
import { getEducationConfigForKey } from "../constants/education";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import { v4 as uuidv4 } from "uuid";
import {
  ORGANIZATION,
  USER,
  MODEL_RESOURCES,
  APPLICATION
} from "utils/constants/models";
import { API_KEY_OWNER } from "utils/constants/apiKey";
import isEmpty from "lodash/isEmpty";
import { getSubdomainRoot } from "./subdomain";
import { DEFAULT_THEME } from "./theme";
import { getAccountConnectStatusProps } from "utils/merchantAccount";
import { getManifestLiveVariationParams } from "utils/application";
import QS from "qs";
import { ROUTE_STATUS } from "./constants/application";
import { MOCK_UUID } from "./constants/request";
import { getModelIDFromQuery } from "./route";
import { useManifest } from "./selectors";
import { getManifestLiveUrl } from "./manifest";
import { ACCOUNT_SHOW_BANNER } from "./constants/state";

const getStateJS = (state, paths = [], fallback = null) => {
  let result = fallback;
  if (state && typeof state.getIn === "function") {
    const value = state.getIn(paths);
    if (value && typeof value.toJS === "function") {
      result = value.toJS();
    }
  }
  return result;
};

export const getUserQuiz = (state) => {
  return state
    .getIn(["onboarding", "data"])
    .find((onb) => onb.get("category") === ONBOARDING_CATEGORIES.QUIZ);
};

/**
 * Pick out values from the user's onboarding quiz
 * {
    "department": {
      "uid": "department_engineering",
      "name": "Engineering"
    },
    "subdepartment": {
      "uid": "subdepartment_software_engineering",
      "name": "Software Engineering"
    },
    "role": {
      "uid": "role_senior_software_engineer",
      "name": "Senior Software Engineer"
    }
  }
 */
export const getKeysFromQuiz = (state, keys) => {
  let result;

  if (state && state.getIn) {
    const quiz = getUserQuiz(state);

    if (quiz) {
      result = keys.reduce((memo, { uid, key }) => {
        const data = quiz
          .getIn(["sections"])
          .find((sect) => sect.get("uid") === uid);

        if (data) {
          const values = data.getIn(["values", 0]);

          if (values) {
            memo[key] = values.toJS();
          }
        }

        return memo;
      }, {});
    }
  }

  return result;
};

export const findSearchTokenForIndex = (tokens = [], indexName) => {
  if (Array.isArray(tokens)) {
    const foundToken = tokens.find(
      (token) => token.indices.indexOf(indexName) > -1
    );
    if (foundToken) {
      return foundToken.token;
    }
  }
};

export const getSearchToken = (state, index, fallback) => {
  let token = fallback;
  const secureToken = state
    .getIn(["user", "data", "secure_tokens"])
    .find((token) => {
      return token.get("indices").includes(index);
    });
  if (secureToken) {
    token = secureToken.get("token");
  }

  return token;
};

export const loadingLogger = (state) => {
  reducerKeys.forEach((reducerKey) => {
    const isLoading = state.getIn([reducerKey, "isLoading"]);
    if (isLoading) {
      // const logVal = isLoading.toJS ? isLoading.toJS() : isLoading;
      // console.log(`____ loader ${reducerKey}: `, logVal);
    }
  });
};

export const getDataByUUID = (state, key, uuid) =>
  getStateJS(state, [key, "data"], []).find((data) => data.uuid === uuid);

export const getDataByID = (state, key, id) =>
  state
    .getIn([key, "data"])
    .toJS()
    .find((data) => data.id === id);

/**
 * Note: can pass an acting org id when that concept exists
 * @param {Object} state
 * @param {String} domain
 */
export const getAppUserCapabilities = (state) => {
  let user = [];
  const orgUserData = state.getIn(["user", "data", "organization_users"]);
  if (orgUserData) {
    user = orgUserData.map((org_user) => org_user.get("capabilities")).toJS();
  }
  return {
    app: state.getIn(["app", "data", "capabilities"]).toJS(),
    user: flatten(user)
  };
};

/**
 * Get all user educations for keys
 * @param {Object} state - reducer state
 * @param {Array} educationKeys - collection of uid keys which map to education content
 */
export const getUserEducation = (state, educationKeys = []) => {
  const userID = state.getIn(["user", "data", "id"]);
  let educations = [];
  if (userID && Array.isArray(educationKeys) && educationKeys.length) {
    const userEducation = getStateJS(state, ["user", "data", "education"], []);

    educations = educationKeys.reduce((memo, key) => {
      const eduConfig = getEducationConfigForKey(key);

      if (eduConfig) {
        const educationMatch = userEducation.find(
          (education) =>
            education.feature === eduConfig.feature &&
            education.context === eduConfig.context
        );
        if (educationMatch) {
          memo.push(educationMatch);
        }
      } else {
        console.error(`Missing education configuration for key: ${key}`);
      }

      return memo;
    }, []);
  }

  return educations;
};

/**
 * Find the first remaining education for the set of keys passed in
 * @param {Object} state - reducer state
 * @param {Array} educationKeys - collection of uid keys which map to education content
 */
export const getRemainingUserEducationKeys = (state, educationKeys = []) => {
  const userID = state.getIn(["user", "data", "id"]);
  let remainingKeys = [];
  if (userID && Array.isArray(educationKeys) && educationKeys.length) {
    const userEducation = getStateJS(state, ["user", "data", "education"], []);
    remainingKeys = educationKeys.filter((key) => {
      const eduConfig = getEducationConfigForKey(key);
      if (eduConfig) {
        const relatedConfig = userEducation.find(
          (education) =>
            education.feature === eduConfig.feature &&
            education.context === eduConfig.context
        );
        /**
         * No related config would be present if the user hasnt interacted with the education yet
         * - it is the default state and then when they dismiss it the config object is saved including the dismissed key being set to true
         * - the dismissed key could be switched back to false on an alternate action
         */
        return !relatedConfig || !relatedConfig.dismissed;
      } else {
        console.error(`Missing education configuration for key: ${key}`);
        return false;
      }
    });
  }

  return remainingKeys;
};

/**
 * Find the first remaining education for the set of keys passed in
 * @param {Object} state - reducer state
 * @param {Array} educationKeys - collection of uid keys which map to education content
 */
export const getFirstRemainingUserEducationKey = (
  state,
  educationKeys = []
) => {
  const remaining = getRemainingUserEducationKeys(state, educationKeys);

  return remaining && remaining.length ? remaining[0] : null;
};

export const getOrderedDashboardLayoutViews = (state, { uuid, layouts }) => {
  const viewData = state.getIn(["view", "data"]);

  const dashboardViews = viewData
    .filter((view) => view.get("dashboard_uuid") === uuid)
    .toJS();
  return layouts.reduce((memo, layout) => {
    const view = dashboardViews.find((view) => view.uuid === layout.uuid);
    if (view) {
      memo.push({
        ...view,
        layout
      });
    }
    return memo;
  }, []);
};

export const getOnboardingDashboardFormInitialValues = (state, extra) => {
  return {
    uuid: uuidv4(),
    title: "My dashboard",
    description: "My dashboard description",
    url: "",
    layouts: [],
    views: [],
    ...getCtxResourceCapabilityAndIDs(state),
    ...extra
  };
};

export const getDashboardMiniFormInitialValues = () => {
  return {
    uuid: uuidv4(),
    url: "",
    layouts: [],
    views: []
  };
};

export const getDashboardFormInitialValues = (state, otherProps) => {
  const dashboardUUID = get(otherProps, "router.query.uuid");
  const dashboard = state
    .getIn(["dashboard", "data"])
    .find((dashboard) => dashboard.get("uuid") === dashboardUUID);

  if (dashboard) {
    const views = getStateJS(state, ["view", "data"], []).reduce(
      (memo, view) => {
        const dashUUIDs = view.dashboard_uuids;
        const viewOfDashboard = dashUUIDs.indexOf(dashboard.get("uuid")) > -1;
        const viewInLayout =
          dashboard
            .get("layouts")
            .findIndex((layout) => layout.get("uuid") === view.uuid) > -1;

        if (viewOfDashboard && viewInLayout) {
          const datasources = state
            .getIn(["datasource", "data"])
            .filter((datasource) => {
              const viewUUIDs = datasource.get("view_uuids").toJS();
              return viewUUIDs.indexOf(view.uuid) > -1;
            });

          view.datasources = datasources.toJS();

          memo.push(view);
        }

        return memo;
      },
      []
    );

    return {
      ...getCtxResourceCapabilityAndIDs(state),
      uuid: dashboard.get("uuid"),
      title: dashboard.get("title"),
      description: dashboard.get("description"),
      layouts: dashboard.get("layouts").toJS(),
      views
    };
  }
};

export const getOrgField = (state, field, fallback = null) => {
  const org = state.getIn(["organization", "data", 0]);
  if (org) {
    const value = org.getIn(field.split("."));
    if (value) {
      return value.toJS ? value.toJS() : value;
    }
  }
  return fallback;
};

export const orgCanSubscribe = (state) =>
  Boolean(getOrgField(state, "stripe_id") && getOrgField(state, "email"));

export const getOrgRawSubscription = (state) => {
  const rawSubscriptionID = getOrgField(state, "subscription.subscription_id");
  const rawSubs = state.getIn(["subscription", "raw"]);

  return rawSubs.find(
    (subscription) => subscription.get("id") === rawSubscriptionID
  );
};

// TODO: refactor this to be based on application state
// - should be a straight swap for useCtxReferences in hookable locations
export const getCtxResourceAndIDs = (state) => {
  const result = {
    resourceID: null,
    resourceUUID: null,
    resource: null,
    customerId: null
  };
  const orgID = state.getIn(["organization", "data", 0, "id"]);
  const orgUUID = state.getIn(["organization", "data", 0, "uuid"]);
  const userID = state.getIn(["user", "data", "id"]);
  const userUUID = state.getIn(["user", "data", "uuid"]);

  if (orgUUID) {
    result.resourceID = orgID;
    result.resourceUUID = orgUUID;
    result.resource = ORGANIZATION;
    result.customerId = state.getIn(["organization", "data", 0, "stripe_id"]);
  } else if (userUUID) {
    result.resourceID = userID;
    result.resourceUUID = userUUID;
    result.resource = USER;
    result.customerId = state.getIn(["user", "data", "stripe_id"]);
  }

  return result;
};

const getUserResourceData = ({ state, application }) => {
  const result = {};
  const userApps = state.getIn(["userApplication", "data"]);
  const matchingUserApp = userApps.find(
    (app) => app.get("application_id") === application.get("id")
  );

  if (matchingUserApp) {
    result.resource = USER;
    const userID = state.getIn(["user", "data", "id"]);
    const matchingUserAppUserID = matchingUserApp.get("user_id");

    if (userID === matchingUserAppUserID) {
      const userUUID = state.getIn(["user", "data", "uuid"]);
      result.resourceID = userID;

      if (userUUID) {
        result.resourceUUID = userUUID;
      }
      result.customerId = state.getIn(["user", "data", "stripe_id"]);
    }
  } else if (application.get("uuid") === MOCK_UUID) {
    result.resource = USER;
    result.resourceID = MOCK_UUID;
    result.resourceUUID = MOCK_UUID;
  }

  return result;
};

const getOrgResourceData = ({ state, application }) => {
  const result = {};
  result.resource = ORGANIZATION;

  const org = state
    .getIn(["organization", "data"])
    .find((org) => org.get("id") === application.get("organization_id"));

  if (org) {
    result.resourceID = org.get("id");
    result.resourceUUID = org.get("uuid");
    result.customerId = org.get("stripe_id");
  }
  return result;
};

const getExtendedContext = (state, application) => {
  const orgApps = state.getIn(["organizationApplication", "data"]);
  const matchingOrgApp =
    orgApps &&
    orgApps.find((app) => app.get("application_id") === application.get("id"));

  const extensionData = matchingOrgApp
    ? getOrgResourceData({ state, application: matchingOrgApp })
    : getUserResourceData({ state, application });

  const capabilities = getStateJS(state, ["capability", "data"], []);
  const capability =
    Array.isArray(capabilities) &&
    capabilities.find(
      ({ resourceUUID, resource }) =>
        extensionData.resource === resource &&
        extensionData.resourceUUID === resourceUUID
    );

  const counts = {};

  const enabled =
    capability && getEnabledCountResources(capability.policies, counts);

  return {
    ...extensionData,
    capability,
    counts,
    enabled
  };
};

/**
 * Get the most important references for CRUD on behalf of a User/Organization
 * The primary payment records rely on being associated with a:
 * - User / Organization
 * - MerchantAccount
 * - Application
 * @param {Object} state - App redux state
 * @param {String} applicationUUID - application uuid from context / route
 */
export const getCtxAppResourceAndIDs = (state, applicationUUID) => {
  let result = {
    application: null,
    resourceID: null,
    resourceUUID: null,
    resource: null
  };
  /**
   * Find the Application matching the uuid passed. Derived from either
   * - context
   * - query string
   * - store
   */
  const applications = state.getIn(["application", "data"]);
  const application =
    applications &&
    applications.find &&
    applications.find((app) => app.get("uuid") === applicationUUID);

  if (application) {
    result.application = application.toJS();

    const merchantAccounts = state.getIn(["merchantAccount", "data"]);
    const merchantAccountMatch =
      merchantAccounts &&
      merchantAccounts.find &&
      merchantAccounts.find(
        (merchantAccount) =>
          merchantAccount.get("id") === result.application.merchant_account_id
      );

    if (merchantAccountMatch) {
      result.merchantAccount = merchantAccountMatch.toJS();
      result.merchantAccountUUID = merchantAccountMatch.get("uuid");
    }

    const extendedContext = getExtendedContext(state, application);

    result = {
      ...result,
      ...extendedContext
    };
  }

  return result;
};

export const getCtxMerchantAccountUUID = (state) => {
  let uuid;
  const merchantAccounts = getStateJS(state, ["merchantAccount", "data"], []);
  if (merchantAccounts && merchantAccounts.length > 0) {
    uuid = merchantAccounts[0].uuid;
  }
  return uuid;
};

export const getCtxMerchantAccountProfile = (state) => {
  let profile;
  const merchantAccounts = getStateJS(state, ["merchantAccount", "data"], []);
  if (merchantAccounts && merchantAccounts.length > 0) {
    profile = merchantAccounts[0].profile;
  }
  return profile;
};

export const getApplicationTheme = (state, { applicationUUID }) => {
  const { application } = getCtxAppResourceAndIDs(state, applicationUUID);

  const themes = getStateJS(state, ["theme", "data"], []);
  const theme = themes.find((theme) => theme.id === application.theme_id);

  return isEmpty(theme.data)
    ? {
        ...theme,
        data: DEFAULT_THEME
      }
    : theme;
};

/**
 * Prepare the admin object for use in the manifest preparation
 * @param {Object} state
 * @param {String} applicationUUID
 * @returns {Object<{clientKey: String, account: Object, profile: Object, subdomain: String}>}
 */
export const getAdminContext = (state, { applicationUUID, manifestUUID }) => {
  if (!applicationUUID) {
    throw new Error("Application uuid required to get admin context");
  }
  const { application, resource } = getCtxAppResourceAndIDs(
    state,
    applicationUUID
  );
  const result = {};
  const merchantAccounts = getStateJS(state, ["merchantAccount", "data"], []);

  if (merchantAccounts && merchantAccounts.length > 0) {
    let ctxMerchantAccount =
      application &&
      application.merchant_account_id &&
      merchantAccounts.find(({ id }) => id === application.merchant_account_id);
    if (!ctxMerchantAccount) {
      ctxMerchantAccount = merchantAccounts[0];
    }

    const clientKey = ctxMerchantAccount.client_key;
    const assetUploadKey = ctxMerchantAccount.asset_upload_key;

    result.clientKey = clientKey || null;
    result.assetUploadKey = assetUploadKey || null;

    result.account = getAccountConnectStatusProps(ctxMerchantAccount);
    result.profile = ctxMerchantAccount.profile;
  }

  if (application) {
    if (application.subdomain) {
      result.subdomain = application.subdomain;
    }
    if (application.alias) {
      result.alias = application.alias;
    }
    const ctxManifest = state
      .getIn(["manifest", "data"])
      .find((manifest) => manifest.get("uuid") === manifestUUID);

    if (ctxManifest) {
      result.liveUrl = getManifestLiveUrl({
        manifest: ctxManifest.toJS(),
        application,
        resource
      });
    }
  }

  return result;
};

export const getAdminShowBanner = (admin) => get(admin, ACCOUNT_SHOW_BANNER);

/**
 * TODO: move to application
 */
export const getManifestLiveVariation = ({ id, application }) => {
  let liveVariation;
  if (application && application.routes) {
    for (const route in application.routes) {
      if (liveVariation) {
        break;
      }
      const config = application.routes[route];
      if (
        config.status === ROUTE_STATUS.ACTIVE &&
        config.variations &&
        config.variations.length
      ) {
        for (
          let variationIndex = 0;
          variationIndex < config.variations.length;
          variationIndex++
        ) {
          const variation = config.variations[variationIndex];
          if (variation && variation.weight > 0 && variation.manifest === id) {
            liveVariation = {
              ...variation,
              route
            };
            break;
          }
        }
      }
    }
  }
  return liveVariation;
};

export const getLiveRouteVariation = ({ route, application }) => {
  let liveVariation;
  const config = application.routes[route];
  if (
    config &&
    config.status === ROUTE_STATUS.ACTIVE &&
    config.variations &&
    config.variations.length
  ) {
    for (
      let variationIndex = 0;
      variationIndex < config.variations.length;
      variationIndex++
    ) {
      const variation = config.variations[variationIndex];
      if (variation && variation.weight > 0) {
        liveVariation = {
          ...variation,
          route
        };
        break;
      }
    }
  }

  return liveVariation;
};

export const getLiveRouteManifest = ({ route, application }) => {
  const liveVariation = getLiveRouteVariation({ route, application });
  const manifestId = liveVariation && liveVariation.manifest;
  const manifest = useManifest(manifestId, "id");

  return manifest;
};

export const getLiveManifestURL = (state, id, applicationUUID) => {
  const { application } = getCtxAppResourceAndIDs(state, applicationUUID);
  const liveVariation =
    application && getManifestLiveVariation({ id, application });
  const manifest = state
    .getIn(["manifest", "data"])
    .find((manifest) => manifest.get("id") === id);

  if (liveVariation && application) {
    const root = getSubdomainRoot(application.subdomain);
    const qsParams = {
      application: applicationUUID
    };
    if (manifest) {
      qsParams.page = manifest.get("uuid");
    }

    return `${root}${liveVariation.route}?${QS.stringify(qsParams)}`;
  }
};

/**
 * Separate live and offline manifests for a resource application
 * @param {Object} state - app reducer state
 * @param {String} resource user | organization
 */
export const getCtxResourceLiveOfflineAppManifests = ({
  state,
  resource,
  application
}) => {
  const resourceManifests = getStateJS(
    state,
    [`${resource}Manifest`, "data"],
    []
  );
  const manifests = getStateJS(state, ["manifest", "data"], []);

  return manifests.reduce(
    (memo, manifest) => {
      const manifestMatch = resourceManifests.find(
        (resourceManifest) => resourceManifest.manifest_id === manifest.id
      );

      if (manifestMatch) {
        const live = getManifestLiveVariationParams({
          id: manifest.id,
          application
        });

        if (live) {
          memo.live.push({
            ...manifest,
            live
          });
        } else {
          memo.offline.push(manifest);
        }
      }
      return memo;
    },
    {
      live: [],
      offline: []
    }
  );
};

/**
 * Only return the manifests for the targeted resource
 * @param {Object} state - app reducer state
 * @param {String} resource user | organization
 */
export const getCtxManifestsForResourceAndUUID = (state, resource) => {
  const resourceManifests = getStateJS(
    state,
    [`${resource}Manifest`, "data"],
    []
  );
  const manifests = getStateJS(state, ["manifest", "data"], []);

  return manifests.filter((manifest) => {
    return resourceManifests.find(
      (resourceManifest) => resourceManifest.manifest_id === manifest.id
    );
  });
};

export const getCtxPolicyResourceForModel = (
  state,
  { name, key },
  resource,
  id
) => {
  const modelPath = `${resource}${upperFirst(name)}`;
  const resourceModels = state.getIn([modelPath, "data"]);
  const ctxModel = resourceModels.find(
    (resourceModel) => resourceModel.get(`${key}_id`) === id
  );
  return ctxModel ? ctxModel.toJS() : null;
};

/**
 * Only return the manifests for the targeted resource
 * e.g. organization_short_links
 * @param {Object} state - app reducer state
 * @param {String} resource user | organization
 * @param {String} model manifest | feature
 */
export const getCtxModelsForResourceAndUUID = (
  state,
  { name, key },
  resource
) => {
  const modelPath = `${resource}${upperFirst(name)}`;
  const resourceModels = state.getIn([modelPath, "data"]);
  const storeModels = state.getIn([name, "data"]);

  if (resourceModels && storeModels) {
    return storeModels
      .filter((storeModel) => {
        return resourceModels.find(
          (resourceModel) =>
            resourceModel.get(`${key}_id`) === storeModel.get("id")
        );
      })
      .toJS();
  } else {
    return [];
  }
};

export const getUserProfile = (state) => {
  const result = {};
  const userData = state.getIn(["user", "data"]);
  const name = userData.get("display_name");
  const profiles = userData.get("profiles");

  result.email = userData.get("email");
  if (name) {
    result.name = name.split(" ")[0];
  }

  if (profiles && typeof profiles.find === "function") {
    const profile = profiles.find((profile) => profile.get("photo_url"));
    if (profile) {
      result.img = profile.get("photo_url");
    }
  }

  return result;
};

export const shouldFetchMerchantAccountAccounts = (state) => {
  const merchantAccounts = getStateJS(state, ["merchantAccount", "data"], []);

  let shouldFetch = false;
  if (!merchantAccounts || merchantAccounts.length === 0) {
    shouldFetch = true;
  } else if (merchantAccounts && merchantAccounts.length > 0) {
    for (let index = 0; index < merchantAccounts.length; index++) {
      const merchantAccount = merchantAccounts[index];

      if (merchantAccount && !merchantAccount.account) {
        shouldFetch = true;
        break;
      }
    }
  }

  return shouldFetch;
};

export const inferModelsForResource = (state, { name, key }) => {
  const { resource } = getCtxResourceAndIDs(state);

  return getCtxModelsForResourceAndUUID(
    state,
    {
      name,
      key
    },
    resource
  );
};

export const getCtxAppAndMerchantAccountUUID = (state, props) => {
  const applicationUUID =
    props.applicationUUID ||
    getModelIDFromQuery(APPLICATION, props.router.query);

  const result = {
    applicationUUID
  };

  if (props.merchantAccountUUID) {
    result.merchantAccountUUID = props.merchantAccountUUID;
  } else {
    const applications = state.getIn(["application", "data"]);
    const application =
      applications &&
      applications.find((app) => app.get("uuid") === applicationUUID);
    if (application) {
      const merchantAccounts = state.getIn(["merchantAccount", "data"]);

      const merchantAccount =
        merchantAccounts &&
        merchantAccounts.find(
          (merchantAccount) =>
            merchantAccount.get("id") === application.get("merchant_account_id")
        );

      if (merchantAccount) {
        result.merchantAccountUUID = merchantAccount.get("uuid");
      }
    }
  }

  return result;
};

export const getResourceAppMerchantData = (
  state,
  { router, application, resource, resourceUUID, resourceID }
) => {
  const applicationUUID = getModelIDFromQuery(APPLICATION, router.query);
  const ctxAppResources = getCtxAppResourceAndIDs(state, applicationUUID);

  return {
    application: application || ctxAppResources.application,
    resource: resource || ctxAppResources.resource,
    resourceUUID: resourceUUID || ctxAppResources.resourceUUID,
    resourceID: resourceID || ctxAppResources.resourceID,
    merchantAccountUUID: getCtxMerchantAccountUUID(state)
  };
};

export const getInitialCtxApplicationId = (state) => {
  const applications = getStateJS(state, ["application", "data"], []);
  const ctxModels = inferModelsForResource(state, MODEL_RESOURCES.APPLICATION);

  return ctxModels && ctxModels[0]
    ? ctxModels[0].id
    : applications && applications[0]
    ? applications[0].id
    : null;
};

export const getAPIKeys = (state) => {
  const user = getStateJS(state, ["user", "data"], {});
  const organizations = getStateJS(state, ["organization", "data"], []);

  const result = [
    {
      header: "Personal",
      owner: API_KEY_OWNER.USER,
      keys: user.api_keys
    }
  ];

  if (organizations && organizations.length) {
    organizations.forEach((org) => {
      result.unshift({
        header: "Organization",
        owner: API_KEY_OWNER.ORGANIZATION,
        domain: org.domain,
        keys: org.api_keys
      });
    });
  }
  return result;
};

export const getEnabledCountResources = (policies = {}, counts = {}) => {
  return Object.keys(policies).reduce((memo, key) => {
    const policyValue = policies[key];
    const countValue = counts[key];

    if (typeof policyValue !== "undefined" && countValue) {
      if (typeof policyValue === "boolean") {
        memo[key] = policyValue;
      } else if (isNaN(parseInt(policyValue))) {
        memo[key] = true;
      } else {
        const isEnabled = parseInt(countValue) < parseInt(policyValue);
        memo[key] = isEnabled;
      }
    } else {
      memo[key] = true;
    }
    return memo;
  }, {});
};

export const getCtxResourceCapabilityAndIDs = (state) => {
  const resourceCtx = getCtxResourceAndIDs(state);
  const capabilities = getStateJS(state, ["capability", "data"], []);
  const capability = capabilities.find(
    ({ resourceUUID, resource }) =>
      resourceCtx.resource === resource &&
      resourceCtx.resourceUUID === resourceUUID
  );

  const counts = {};

  const enabled =
    capability && getEnabledCountResources(capability.policies, counts);

  return {
    ...resourceCtx,
    capability,
    counts,
    enabled
  };
};

export const orderedApplications = (state) => {
  const appIds = [];
  /**
   * Show only org / personal application - not both
   * - append any org app ids to the collection
   * - else append user app data
   */
  state.getIn(["organizationApplication", "data"]).forEach((orgApp) => {
    const appId = orgApp.get("application_id");
    if (appId) {
      appIds.push(appId);
    }
  });
  if (Array.isArray(appIds) && appIds.length === 0) {
    state.getIn(["userApplication", "data"]).forEach((userApp) => {
      const appId = userApp.get("application_id");
      if (appId) {
        appIds.push(appId);
      }
    });
  }

  const apps = state.getIn(["application", "data"]);
  return appIds.reduce((memo, appId) => {
    const appMatch = apps && apps.find((app) => app.get("id") === appId);
    if (appMatch) {
      memo.push(appMatch.toJS());
    }
    return memo;
  }, []);
};
