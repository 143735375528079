import C from "constants/organization";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_STORE,
    payload: {
      isLoading: true
    }
  };
}

export function setOrganizations(payload) {
  return {
    type: C.SET_ORGANIZATIONS,
    payload
  };
}

export function setOrganizationSubscription(payload) {
  return {
    type: C.SET_ORGANIZATION_SUBSCRIPTION,
    payload
  };
}

export const setOrganizationLicenses = (payload) => {
  return {
    type: C.SET_ORGANIZATION_LICENSES,
    payload
  };
};

export function setOrganizationApiKeys(payload = {}) {
  return {
    type: C.SET_ORGANIZATION_API_KEYS,
    payload
  };
}

export function setOrganizationSubdomains(payload = {}) {
  return {
    type: C.SET_ORGANIZATION_SUBDOMAINS,
    payload
  };
}

export function resetOrganizationNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_STORE,
      payload
    });
  };
}
