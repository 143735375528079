import PATHS, { apiResourcePath, EXTERNAL } from "utils/paths";
import axios from "axios";
import { authBearerToken } from "utils/auth";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import get from "lodash/get";
import { MERCHANT_ACCOUNT_STATUS } from "./constants/merchantAccount";
import {
  STRIPE_CHECKOUT_DISABLED_MESSAGE,
  STRIPE_OAUTH_ERROR,
  STRIPE_OAUTH_ERROR_CONTENT,
  STRIPE_ROUTES
} from "utils/constants/stripe";
import { ENV } from "./constants/env";
import { STATE_KEYS } from "./constants/state";

export const getStripeDestination = (props) => {
  const path = props.path;
  const isTest = props.test || true;
  const account = props.account || null;
  const env = props.env || process.env.NODE_ENV;

  let destination;
  if (path) {
    const pathParts = [EXTERNAL.STRIPE];
    if (env !== ENV.PRODUCTION && isTest) {
      pathParts.push("test");
    }
    if (account) {
      pathParts.push(`${STRIPE_ROUTES.CONNECT_ACCOUNTS}/${account}`);
    }
    pathParts.push(path);

    destination = pathParts.join("/");
  } else {
    destination = EXTERNAL.STRIPE;
  }

  return destination;
};

export const stripeTransformErrorForModal = () => {
  console.log("stripeTransformErrorForModal");
};

/**
 * Make generic based on resource and resource uuid
 * - knock on to fetch merchant accounts within check
 */
export const getStripeOnboardAccountLink = async ({
  resource,
  resourceUUID,
  merchantAccountUUID,
  account
}) => {
  const REQUEST_URL = `${apiResourcePath({
    resource,
    resourceUUID
  })}/merchant-accounts/${merchantAccountUUID}/stripe/account-link`;
  const input = {
    account,
    // Need a route to push the user back through the onboarding flow on failure
    refreshUrl: window.location.href,
    // This is fine as it will trigger a refetch when it lands
    returnUrl: window.location.href
  };

  try {
    const { data } = await axios({
      method: "post",
      url: REQUEST_URL,
      headers: authBearerToken(),
      data: input
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const getCheckoutDisabledMessage = (account) => {
  const result = { copy: "", cta: "" };

  if (isEmpty(account)) {
    result.copy = STRIPE_CHECKOUT_DISABLED_MESSAGE.NO_ACCOUNT.copy;
  } else if (!account.detailsSubmitted) {
    result.copy = STRIPE_CHECKOUT_DISABLED_MESSAGE.NO_DETAILS_SUBMITTED.copy;
    result.cta = STRIPE_CHECKOUT_DISABLED_MESSAGE.NO_DETAILS_SUBMITTED.cta;
  } else if (!account.chargesEnabled) {
    result.copy = STRIPE_CHECKOUT_DISABLED_MESSAGE.NO_CHARGES_ENABLED.copy;
    result.cta = STRIPE_CHECKOUT_DISABLED_MESSAGE.NO_CHARGES_ENABLED.cta;
  } else if (account.status === MERCHANT_ACCOUNT_STATUS.AUTH_REQUIRED) {
    result.copy = STRIPE_CHECKOUT_DISABLED_MESSAGE.AUTH_REQUIRED.copy;
    result.cta = STRIPE_CHECKOUT_DISABLED_MESSAGE.AUTH_REQUIRED.cta;
  }
  return result;
};

export const getPermissionsCompletedMessage = ({
  detailsSubmitted,
  chargesEnabled,
  authAction
}) => {
  let copy =
    "Your Stripe account is setup and you can start charging for products with PriceBlocs.";

  if (!detailsSubmitted) {
    copy = `Your Stripe account onboarding has not been completed. You will need to complete your account setup before you can start charging for products with PriceBlocs.`;
  } else if (!chargesEnabled) {
    copy = `Your Stripe details have been submitted but charges are not yet enabled on your account. When Stripe confirms your details you can start charging for products with PriceBlocs.`;
  } else if (authAction) {
    copy = "You Stripe account onboarding is not yet complete.";
  }

  return copy;
};

export const getStripeAdminLink = (live, fragment) =>
  compact([EXTERNAL.STRIPE, !live ? "test" : "", fragment]).join("/");

export const STRIPE_ID_PATTERN = {
  PRICE: /^(price_|plan_)/,
  PRODUCT: /^product_/,
  SUBSCRIPTION: /^sub_/,
  CUSTOMER: /^cus_/,
  TAX_RATE: /^txr_/,
  SHIPPING_RATE: /^shr_/,
  PAYMENT_METHOD: /^pm_/,
  CARD: /^card_/,
  SOURCE: /^src_/
};

export const getStripeResourcePathForId = (id) => {
  let path = "";
  if (STRIPE_ID_PATTERN.SUBSCRIPTION.test(id)) {
    path = `subscriptions`;
  } else if (STRIPE_ID_PATTERN.CUSTOMER.test(id)) {
    path = `customers`;
  } else if (STRIPE_ID_PATTERN.PAYMENT_METHOD.test(id)) {
    path = `customers`;
  } else if (STRIPE_ID_PATTERN.CARD.test(id)) {
    path = `customers`;
  } else if (STRIPE_ID_PATTERN.SORUCE.test(id)) {
    path = `customers`;
  }

  if (path) {
    path = path += `/${id}`;
  }

  return path;
};

const getStripeOAuthErrorContent = ({ code, onClick }) => ({
  error: STRIPE_OAUTH_ERROR_CONTENT[code],
  actionCopy: "Retry",
  onClick
});

export const getStripeOAuthErrorProps = ({ router, stripeErrorCode }) => {
  let result = {
    onClick: () => router.replace(PATHS.ONBOARDING)
  };

  if (
    stripeErrorCode === STRIPE_OAUTH_ERROR.ACCESS_DENIED ||
    stripeErrorCode === STRIPE_OAUTH_ERROR.INVALID_REQUEST
  ) {
    result = getStripeOAuthErrorContent({
      code: stripeErrorCode,
      onClick: () => router.replace(PATHS.ONBOARDING)
    });
  }

  return result;
};

export const getAdjustedLineItemQuantity = (lineItem) => {
  let result = lineItem.quantity;
  const adjustEnabled = get(
    lineItem,
    STATE_KEYS.SESSION.ADJUSTABLE_QUANTITY_ENABLED
  );
  const isEnabled = adjustEnabled && typeof adjustEnabled === "boolean";
  if (isEnabled) {
    const adjustMin = get(
      lineItem,
      STATE_KEYS.SESSION.ADJUSTABLE_QUANTITY_MINIMUM
    );
    const hasMin = Number.isInteger(adjustMin);
    const failsMin = hasMin && result < adjustMin;
    if (failsMin) {
      result = adjustMin;
    }

    const adjustMax = get(
      lineItem,
      STATE_KEYS.SESSION.ADJUSTABLE_QUANTITY_MAXIMUM
    );
    const hasMax = Number.isInteger(adjustMax);
    const failsMax = hasMax && result > adjustMax;
    if (failsMax) {
      result = adjustMax;
    }
  }
  return result;
};
