export const CONNECT_ACTION_UUID = {
  STRIPE: {
    SET_EMAIL: "9c3a4430-c554-4535-833a-bd1e5a7ef6a7",
    SET_CUSTOMER: "8468c55d-aa26-4457-bd8a-a0e7d4cb060c",
    SET_CLIENT_REFERENCE_ID: "580dcbf8-0ad5-4b25-af76-9fb7107afaf6",
    SET_CUSTOMER_BY_EMAIL: "dd630372-d56c-42c7-8b71-b55471fc2025"
  }
};

export const CONNECT_EMAIL_FIELD = {
  [CONNECT_ACTION_UUID.STRIPE.SET_EMAIL]: true,
  [CONNECT_ACTION_UUID.STRIPE.SET_CUSTOMER_BY_EMAIL]: true
};
