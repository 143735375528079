// localStorage.removeItem("remove_branding-dev-upgrade-prompt")
const REMOVE_BRANDING = "remove_branding";
// localStorage.removeItem("attach_files-dev-upgrade-prompt")
const ATTACH_FILES = "attach_files";
const API_PAYMENT_LINKS = "api_payment_links";
const DEVELOPER_COMPONENTS = "developer_components";

const TRANSACTION_FEE = "transaction_fee";

export const PRODUCT_FEATURE_CAPABILITY = {
  REMOVE_BRANDING,
  ATTACH_FILES,
  API_PAYMENT_LINKS,
  DEVELOPER_COMPONENTS,
  TRANSACTION_FEE
};

const ENABLED = "enabled";
const DISABLED = "disabled";
export const FLAG_STATUS = {
  ENABLED,
  DISABLED
};

// All types of users
export const CONTEXT_ALL = "all";
// An application level feature
export const CONTEXT_APPLICATION = "application";
export const CONTEXTS = {
  ALL: CONTEXT_ALL,
  APPLICATION: CONTEXT_APPLICATION
};

// User capabilities
const BETA = "beta";
const CONTENT = "content";
const PAGES = "pages";
const PAGES_PRICING = "pages_pricing";
const INSIGHTS = "insights";
const FEATURES = "features";
const CAMPAIGNS = "campaigns";
const INTEGRATIONS = "integrations";
const SHIPPING_RATES = "shipping_rates";
const IDENTITY = "identity";
const UPLOAD = "upload";
const ADDRESS = "address";
const LEGAL = "legal";
const DEVELOPMENT = "development";
const INSTALLMENTS = "installments";
const WORKFLOWS = "workflows";

// Aligns to API getUserCapabilities
export const USER_CAPABILITY = {
  BETA,
  PAGES,
  PAGES_PRICING,
  CONTENT,
  INSIGHTS,
  FEATURES,
  DEVELOPMENT,
  INSTALLMENTS,
  CAMPAIGNS,
  INTEGRATIONS,
  SHIPPING_RATES,
  IDENTITY,
  ADDRESS,
  LEGAL,
  UPLOAD,
  WORKFLOWS
};
