import { ENTITY_TYPE } from "components/FormerEditor/common/constants/entity";
import get from "lodash/get";
import { getUUID } from "utils/uuid";

export const FORM_KEY = "_FORM";
export const UUID_VALUE_KEY = "uuid";
const EDIT = "edit";
const CREATE = "create";
const IMPORT = "import";
export const FORM_MODE = {
  EDIT,
  CREATE,
  IMPORT
};

export const TOGGLE_STATE = {
  YES: "yes",
  NO: "no"
};

export const DEFAULT_DEBOUNCE = 500;
export const SET_TOUCH_TIMEOUT = 100;

// Standard
const SELECT = "select";
const MULTISELECT = "multiselect";
const INPUT = "input";
const TEXTAREA = "textarea";
const TOGGLE = "toggle";
const CHECKBOX = "checkbox";
const DISCLAIMER = "disclaimer";
const DATEPICKER = "datepicker";
// Specialized
// Tax identification number
const TIN = "tin";
const PHONE = "phone";
const FILE = "file";
const IDENTITY = "identity";
const ADDRESS = "address";
const LEGAL = "legal";

export const FIELD_TYPES = {
  TIN,
  PHONE,
  SELECT,
  MULTISELECT,
  INPUT,
  TEXTAREA,
  TOGGLE,
  CHECKBOX,
  DISCLAIMER,
  DATEPICKER,
  FILE,
  IDENTITY,
  ADDRESS,
  LEGAL
};

export const CONFIG_FIELDS = [FIELD_TYPES.SELECT, FIELD_TYPES.MULTISELECT];

export const FIELD_TYPE_LABEL_MAP = {
  [FIELD_TYPES.TIN]: "Tax id number",
  [FIELD_TYPES.PHONE]: "Phone",
  [FIELD_TYPES.FILE]: "File",
  [FIELD_TYPES.IDENTITY]: "Identity",
  [FIELD_TYPES.SELECT]: "Select",
  [FIELD_TYPES.MULTISELECT]: "Multiselect",
  [FIELD_TYPES.INPUT]: "Input",
  [FIELD_TYPES.TEXTAREA]: "Textarea",
  [FIELD_TYPES.TOGGLE]: "Toggle",
  [FIELD_TYPES.CHECKBOX]: "Checkbox",
  [FIELD_TYPES.DISCLAIMER]: "Disclaimer",
  [FIELD_TYPES.DATEPICKER]: "Datepicker",
  [FIELD_TYPES.ADDRESS]: "Address",
  [FIELD_TYPES.LEGAL]: "Legal"
};

export const FIELD_TEMPLATES = {
  SELECT: {
    OPTION: (props) => {
      return {
        uuid: getUUID(),
        uid: "",
        label: get(props, "label") || "",
        linked: true
      };
    }
  }
};

/**
 * These fields can be factored into checkout selection result
 * e.g. when select option is a then checkout selection result is p_A@p_A_1
 */
export const COMBINATION_FIELDS = {
  [FIELD_TYPES.SELECT]: true,
  [FIELD_TYPES.CHECKBOX]: true,
  [FIELD_TYPES.TOGGLE]: true
};

export const ENTITY_TYPE_LABEL_MAP = {
  [ENTITY_TYPE.PRIMITIVE]: "Standard",
  [ENTITY_TYPE.SPECIALIZED]: "Specialized"
};
