const BILLING_TYPE = "BILLING_TYPE";
const FEATURE_LIST = "FEATURE_LIST";
const PRODUCT_ORDER = "PRODUCT_ORDER";
const PRODUCT_PRESENTATION = "PRODUCT_PRESENTATION";
const PRICE_ADD = "PRICE_ADD";
const PRODUCT_ADD = "PRODUCT_ADD";

const FIELD_GROUP_ORDER = "FIELD_GROUP_ORDER";
const FIELD_ORDER = "FIELD_ORDER";
const FIELD_GROUP_ADD = "FIELD_GROUP_ADD";
const FIELD_ADD = "FIELD_ADD";

export const PRICING_TABLE_INTENT = {
  PRICE_ADD,
  PRODUCT_ADD,
  BILLING_TYPE,
  FEATURE_LIST,
  PRODUCT_ORDER,
  PRODUCT_PRESENTATION
};

export const PRODUCT_ORDER_INTENT = {
  PRICE_ADD,
  PRODUCT_ADD
};

const ALL_PRICES = "ALL_PRICES";
const SHOW_PRICE = "SHOW_PRICE";
const NEW_PRICE = "NEW_PRICE";

export const PRODUCT_PRICE_INTENT = {
  ALL_PRICES,
  SHOW_PRICE,
  NEW_PRICE
};

/**
 * TODO: be better suited to a constants/blocks file
 */
/**
 * ProductCollection component
 */
// const LAYOUT_SETTINGS = "LAYOUT_SETTINGS";
// const PAYMENT_SETTINGS = "PAYMENT_SETTINGS";
const GENERAL_SETTINGS = "GENERAL_SETTINGS";

/**
 * Form component
 */
const LOGIC_SETTINGS = "LOGIC_SETTINGS";
const CHECKOUT_SETTINGS = "CHECKOUT_SETTINGS";
const FIELD_SETTINGS = "FIELD_SETTINGS";
const FIELD_GROUP_SETTINGS = "FIELD_GROUP_SETTINGS";
const PRESENTATION_SETTINGS = "PRESENTATION_SETTINGS";

/**
 * Form component
 */
export const FORM_INTENT = {
  FIELD_GROUP_ORDER,
  FIELD_ORDER,
  FIELD_GROUP_ADD,
  FIELD_ADD,
  LOGIC_SETTINGS,
  CHECKOUT_SETTINGS,
  FIELD_GROUP_SETTINGS,
  FIELD_SETTINGS,
  GENERAL_SETTINGS,
  PRESENTATION_SETTINGS
};

export const PRODUCT_COLLECTION_INTENT = {
  PRODUCT_ADD,
  PRODUCT_ORDER,
  // - Scan or click (contactless support)
  // PAYMENT_SETTINGS,
  // - These have mixed logic,
  // GENERAL_SETTINGS | LAYOUT_SETTINGS,
  CHECKOUT_SETTINGS,
  PRESENTATION_SETTINGS
};
