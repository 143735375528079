import {
  CREATE_COMBINATION_RESULT_SCHEMA,
  CREATE_CONSTANT_RESULT_SCHEMA,
  VALIDATION_CHECKOUT_KEY,
  VALIDATION_CHECKOUT_KEY_MAP
} from "constants/validation";
import { merchantCheckoutScaffoldBase } from "./checkout";
import { COMBINATION_FIELDS, FIELD_TYPES, FORM_KEY } from "./constants/form";
import {
  PRODUCT_SUBSCRIPTION_SCHEDULE,
  SHIPPING_ADDRESS_COLLECTION,
  TRIAL_PERIOD_DAYS
} from "./constants/state";
import {
  getShortLinkFormKeyInitialValues,
  validateShortLink
} from "./shortLink";
import { validateSchema } from "./validate";
import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import { findFieldForUUID, reduceFlatFieldGroupSelections } from "./form";
import cloneDeep from "lodash/cloneDeep";
import { composeCheckoutConfigsToShortLink } from "./checkout/merge";
import ENTITIES from "components/FormerEditor/common/constants/entity";
import { CHECKBOX_LABELS } from "./constants/ui";
import {
  getShippingAddressCollectionActiveFormKey,
  getTrialPeriodDaysActiveFormKey
} from "./checkout/form";

export const isBooleanFieldType = (uid) =>
  [FIELD_TYPES.CHECKBOX, FIELD_TYPES.TOGGLE].indexOf(uid) > -1;

export const isCombinationResultEmpty = (result = {}) => {
  let empty = true;
  for (const key in result) {
    const hasValue = !isEmpty(result[key]);
    if (hasValue) {
      empty = false;
      break;
    }
  }
  return empty;
};

export const prepareCombinationSelectionLabels = ({
  combination,
  fieldGroups
}) => {
  const selections = combination.selections;
  const result = [];

  const orderedFieldUUIDs =
    Array.isArray(fieldGroups) &&
    Object.keys(reduceFlatFieldGroupSelections(fieldGroups));

  for (let uuidIx = 0; uuidIx < orderedFieldUUIDs.length; uuidIx++) {
    const fieldUUID = orderedFieldUUIDs[uuidIx];

    if (Object.hasOwnProperty.call(selections, fieldUUID)) {
      const fieldMatch = findFieldForUUID(fieldGroups, fieldUUID);

      if (fieldMatch) {
        const fieldValue = selections[fieldUUID];
        const selectionLabel = {
          fieldLabel: fieldMatch.label,
          optionLabel: "",
          optionValue: fieldValue
        };

        if (isBooleanFieldType(fieldMatch.uid)) {
          selectionLabel.optionLabel = fieldValue
            ? CHECKBOX_LABELS.CHECKED
            : CHECKBOX_LABELS.NOT_CHECKED;
        } else if (fieldMatch.uid === FIELD_TYPES.SELECT) {
          const optionMatch = fieldMatch.options.find(({ uuid }) => {
            return fieldValue === uuid;
          });
          if (optionMatch) {
            selectionLabel.optionLabel = optionMatch.label;
          }
        }

        result.push(selectionLabel);
      }
    }
  }

  return result;
};

/**
 * Merge the current combo result with the common checkout to represent the full picture of the checkout
 * But we only want to "save" the combo results
 */
export const prepareCombinationResultInitialValues = (values = {}) => {
  const checkoutBase = {
    checkout: {},
    primaries: [],
    attachments: [],
    subscription_schedule: null
  };

  const mergedData = {
    product: merge(checkoutBase, values)
  };

  const formKeys = getShortLinkFormKeyInitialValues({
    data: mergedData
  });

  return {
    [FORM_KEY]: formKeys,
    data: mergedData
  };
};

export const prepareCommonCheckoutResultInitialValues = (
  values,
  extra = {},
  merchantAccount
) => {
  let scaffoldProps;
  if (merchantAccount) {
    scaffoldProps = {
      merchantAccountConfig: merchantAccount.merchant_account_config
    };
  }

  const mergedData = merge(
    {
      product: {
        checkout: merchantCheckoutScaffoldBase(scaffoldProps),
        primaries: [],
        attachments: [],
        subscription_schedule:
          get(values, PRODUCT_SUBSCRIPTION_SCHEDULE) || null
      }
    },
    values,
    extra
  );

  const preparedValues = {
    data: mergedData
  };

  return {
    [FORM_KEY]: {
      type: null,
      [SHIPPING_ADDRESS_COLLECTION]: getShippingAddressCollectionActiveFormKey(
        preparedValues
      ),
      [TRIAL_PERIOD_DAYS]: getTrialPeriodDaysActiveFormKey(preparedValues)
    },
    data: mergedData
  };
};

export const validateCreateConstantCheckout = ({
  prices,
  products,
  coupons,
  taxRates,
  shippingRates,
  common
}) => {
  return async (formValues) => {
    const result = await validateSchema(
      CREATE_CONSTANT_RESULT_SCHEMA,
      formValues
    );

    const evaluations = [
      {
        context: VALIDATION_CHECKOUT_KEY.CONSTANT,
        shortLink: composeCheckoutConfigsToShortLink({
          selectedCheckout: formValues.data.product,
          commonConfig: common
        })
      }
    ];
    const messages = processEvaluations({
      evaluations,
      resources: {
        prices,
        products,
        coupons,
        taxRates,
        shippingRates
      }
    });
    if (messages) {
      result.messages = messages;
    }

    return result;
  };
};

export const validateCreateCombinationResult = ({
  prices,
  products,
  coupons,
  taxRates,
  shippingRates,
  constant,
  common
}) => {
  return async (formValues) => {
    const result = await validateSchema(
      CREATE_COMBINATION_RESULT_SCHEMA,
      formValues
    );

    const evaluations = [
      {
        context: VALIDATION_CHECKOUT_KEY.COMBINATIONS,
        shortLink: composeCheckoutConfigsToShortLink({
          selectedCheckout: formValues.data.product,
          commonConfig: common,
          constantCheckout: constant
        })
      }
    ];
    const messages = processEvaluations({
      evaluations,
      resources: {
        prices,
        products,
        coupons,
        taxRates,
        shippingRates
      }
    });
    if (messages) {
      result.messages = messages;
    }

    return result;
  };
};

/**
 * @param {Array} selectedFields - array of field uuids
 * @param {Array} fieldGroups - array of field groups
 * @returns
 */
export const reduceSelectedFields = (selectedFields, fieldGroups) =>
  fieldGroups.reduce((memo, group) => {
    Array.isArray(group.fields) &&
      group.fields.forEach((field) => {
        if (COMBINATION_FIELDS[field.uid]) {
          const isSelected = selectedFields.indexOf(field.uuid) > -1;
          if (isSelected) {
            if (field.uid === FIELD_TYPES.SELECT) {
              if (Array.isArray(field.options) && field.options.length > 0) {
                memo[field.uuid] = field.options.map(({ uuid }) => uuid);
              }
            } else if (isBooleanFieldType(field.uid)) {
              memo[field.uuid] = [true, false];
            }
          }
        }
      });

    return memo;
  }, {});

const flattenValues = (result) =>
  result.reduce((memo, col) => {
    const redCol = col.reduce(
      (subMemo, subCol) => {
        subMemo.selections = {
          ...subMemo.selections,
          ...subCol
        };

        return subMemo;
      },
      { selections: {}, result: {} }
    );

    memo.push(redCol);

    return memo;
  }, []);

// https://stackoverflow.com/a/15310051
export const generateCombinations = (args) => {
  const cols = Object.values(args);
  const keys = Object.keys(args);
  const result = [];
  const colMax = cols.length - 1;

  /**
   * Alias matrix
   */
  const generateMatrices = (arr, arrIx) => {
    const collection = cols[arrIx];
    if (Array.isArray(collection) && collection.length > 0) {
      const colLength = collection.length;

      for (var j = 0, l = colLength; j < l; j++) {
        var arrCopy = arr.slice(0);

        const colVal = collection[j];

        arrCopy.push({
          [keys[arrIx]]: colVal
        });

        if (arrIx === colMax) {
          result.push(arrCopy);
        } else {
          generateMatrices(arrCopy, arrIx + 1);
        }
      }
    }
  };

  /**
   * Alias: generateMatrix
   */
  generateMatrices([], 0);

  return flattenValues(result);
};

export const scaffoldCombinations = (selectedFields, fieldGroups) => {
  const reduced = reduceSelectedFields(selectedFields, fieldGroups);

  return generateCombinations(reduced);
};

/**
 * { fieldUUID: 'option-one'}
 * We want to know which of the selection options still need to have their results set
 */
export const reduceFieldSelectionRequirements = ({ combinations, uuid }) => {
  return combinations.reduce((memo, combo, comboIx) => {
    const valueInSelection = combo.selections[uuid];
    if (typeof valueInSelection !== "undefined") {
      if (!memo[valueInSelection]) {
        memo[valueInSelection] = {
          hasResultIndexes: [],
          needsResultIndexes: []
        };
      }
      if (isCombinationResultEmpty(combo.result)) {
        memo[valueInSelection].needsResultIndexes.push(comboIx);
      } else {
        memo[valueInSelection].hasResultIndexes.push(comboIx);
      }
    }

    return memo;
  }, {});
};

const getSelectionValues = ({ selections, formFields }) => {
  const values = {};
  if (selections) {
    for (const fieldUUID in selections) {
      let value = selections[fieldUUID];
      const field = formFields.find(({ uuid }) => uuid === fieldUUID);
      if (field && typeof value !== "undefined") {
        if (
          (field.uid === ENTITIES.SELECT ||
            field.uid === ENTITIES.MULTISELECT) &&
          Array.isArray(field.options)
        ) {
          const optionMatch = field.options.find(({ uuid }) => uuid === value);
          if (optionMatch && optionMatch.label) {
            value = optionMatch.label;
          } else {
            console.error(`No option for field: ${field.uuid}`);
          }
        }
        values[field.label] = value;
      }
    }
  }

  return values;
};

export const reduceEvaluationErrors = (messages = {}) => {
  const result = {};
  if (messages) {
    for (const key in messages) {
      if (VALIDATION_CHECKOUT_KEY_MAP[key] && Array.isArray(messages[key])) {
        const contextMessages = messages[key];
        for (
          let messageIx = 0;
          messageIx < contextMessages.length;
          messageIx++
        ) {
          const message = contextMessages[messageIx];
          if (message && Array.isArray(message.errors)) {
            for (let errorIx = 0; errorIx < message.errors.length; errorIx++) {
              const error = message.errors[errorIx];
              if (error && Array.isArray(error.fields)) {
                error.fields.forEach((field) => {
                  set(result, field, true);
                });
              }
            }
          }
        }
      }
    }
  }

  return result;
};

const processEvaluations = ({ uuid, evaluations, resources, formFields }) => {
  let messages = null;
  let comboIx = 0;
  for (let evalIx = 0; evalIx < evaluations.length; evalIx++) {
    const { context, shortLink, meta } = evaluations[evalIx];
    const isCombo = context === VALIDATION_CHECKOUT_KEY.COMBINATIONS;
    if (isCombo) {
      comboIx++;
    }

    const validation = validateShortLink({
      shortLink,
      ...resources
    });

    const errors = Object.values(validation).reduce((memo, validation) => {
      if (validation.copy) {
        memo.push(validation);
      }

      return memo;
    }, []);

    if (errors.length) {
      if (!messages) {
        messages = {};
      }
      if (uuid && !messages.uuid) {
        messages.uuid = uuid;
      }
      if (!messages[context]) {
        messages[context] = [];
      }
      const addition = { errors, meta: meta || null };
      if (isCombo) {
        addition.index = comboIx - 1;
      }
      if (meta && meta.selections && Array.isArray(formFields)) {
        meta.values = getSelectionValues({
          selections: meta.selections,
          formFields
        });
      }

      messages[context] = messages[context].concat(addition);
    }
    if (messages) {
      messages.errors = reduceEvaluationErrors(messages);
    }
  }

  return messages;
};

export const validateCombinations = ({
  uuid,
  initial,
  combinations,
  formFields,
  constant,
  common,
  prices,
  products,
  coupons,
  taxRates,
  shippingRates
}) => {
  const resources = {
    prices,
    products,
    coupons,
    taxRates,
    shippingRates
  };
  const result = initial ? cloneDeep(initial) : {};

  /**
   * Build all the combination checkouts which combines:
   * - combination selection result checkout
   * - common checkout
   * - constant checkout
   */
  const evaluations = [];
  /**
   * Validate common on its own
   */
  const hasCommon = !isEmpty(common);
  if (hasCommon) {
    evaluations.push({
      context: VALIDATION_CHECKOUT_KEY.COMMON,
      shortLink: composeCheckoutConfigsToShortLink({
        commonConfig: common
      })
    });
  }

  /**
   * Validate constant in conjunction with common
   * If there is a constant checkout then unshift it to the combinations
   */
  const hasConstant = !isEmpty(constant);
  if (hasConstant) {
    evaluations.push({
      context: VALIDATION_CHECKOUT_KEY.CONSTANT,
      shortLink: composeCheckoutConfigsToShortLink({
        commonConfig: common,
        constantCheckout: constant
      })
    });
  }

  const ctxCombos = Array.isArray(combinations) ? combinations : [];
  ctxCombos.forEach((combination) => {
    if (!isCombinationResultEmpty(combination.result)) {
      evaluations.push({
        context: VALIDATION_CHECKOUT_KEY.COMBINATIONS,
        shortLink: composeCheckoutConfigsToShortLink({
          selectedCheckout: combination.result,
          commonConfig: common,
          constantCheckout: constant
        }),
        meta: {
          selections: combination.selections
        }
      });
    }
  });

  const messages = processEvaluations({
    uuid,
    evaluations,
    resources,
    formFields
  });
  if (messages) {
    result.messages = messages;
  }

  return result;
};

export const validateCommonCombinationCheckout = ({
  uuid,
  /**
   * Pass in constant and combinations to show validate side effects
   */
  constant,
  combinations,
  formFields,
  prices,
  products,
  coupons,
  taxRates,
  shippingRates
}) => {
  return async (commonCheckout) => {
    /**
     * Validate common values
     */
    const validResult = await validateSchema(
      CREATE_COMBINATION_RESULT_SCHEMA,
      commonCheckout
    );

    return validateCombinations({
      uuid,
      initial: validResult,
      combinations,
      formFields,
      constant,
      common: commonCheckout.data,
      prices,
      products,
      coupons,
      taxRates,
      shippingRates
    });
  };
};

export const completeCombination = (combination) =>
  !isEmpty(combination && combination.selections) &&
  !isEmpty(combination && combination.result);

export const getClearSelections = (selections) => {
  const result = {};
  if (selections) {
    for (const fieldUUID in selections) {
      result[fieldUUID] = null;
    }
  }

  return result;
};
