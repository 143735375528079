import get from "lodash/get";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import isError from "lodash/isError";
import compact from "lodash/compact";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import set from "lodash/set";
import { AUTO_ATTRS } from "components/Form/fields/constants";
import upperFirst from "lodash/upperFirst";
import {
  FIELD_TEMPLATES,
  FIELD_TYPES,
  FORM_MODE,
  TOGGLE_STATE
} from "./constants/form";
import { PAGE_ACTION_UIDS } from "components/FormerEditor/common/constants";
import { getCheckoutDisabledMessage } from "./stripe";
import { pluralizeCopy } from "./copy";
import { STATUS, UI_THEME } from "./constants/ui";
import { isBooleanFieldType, scaffoldCombinations } from "utils/combination";
import { notNothing } from "./data";
import { STATE_KEYS } from "./constants/state";
import { FIELD_VALIDATORS, FIELD_KEYS } from "./constants/fields";
import { validateIdentityTin } from "utils/identity";
import ENTITIES from "components/FormerEditor/common/constants/entity";
import { validateAddress, validateAddressReceiver } from "./address";
import { DATEPICKER_TIMEFRAME } from "./constants/datepicker";
import { getToday } from "./date";
import { LEGAL_CONTENT_ACCEPTANCE_TYPE } from "./constants/legalContent";
import { KEYCODE_MAP } from "constants/keycode";
import { validateManifestRequestField } from "./manifest";
import { getErrorMessage } from "./error";

const RESTRICTED_SECTIONS = [
  "profile.department",
  "profile.department.subdepartment"
];

const DATA_FIELD_GROUPS_KEY = ".data.fieldGroups";

export const flattenErrorKeys = (errors, name = "") => {
  return Object.keys(errors).reduce((memo, errorKey) => {
    const path = getFieldPath(name, errorKey);
    const errorVal = errors[errorKey];
    if (Array.isArray(errorVal)) {
      errorVal.forEach((error, errorIx) => {
        const indexPath = `${path}[${errorIx}]`;
        memo = memo.concat(flattenErrorKeys(error, indexPath));
      });
    } else if (typeof errorVal === "object") {
      memo = memo.concat(flattenErrorKeys(errorVal, path));
    } else {
      memo = memo.concat({
        path,
        value: errorVal
      });
    }
    return memo;
  }, []);
};

export function pathCategory(field) {
  const parts = get(field, "key", "").split(".");
  if (parts.length) {
    return parts.slice(parts.length - 1)[0];
  }
  return "";
}

/**
 * Restricted means that the rendered list is artificially suppressed based on parameters defined here
 */
export function collectionForKey(key, options) {
  const result = {
    restricted: false,
    data: options
  };
  if (RESTRICTED_SECTIONS.indexOf(key) > -1) {
    result.restricted = true;
    const ctx = key.split(".").pop();

    if (ctx === "department") {
      result.data = filter(options, { uid: "department_engineering" });
    } else if (ctx === "subdepartment") {
      result.data = filter(options, {
        uid: "subdepartment_software_engineering"
      });
    }
  }

  return result;
}

export const formatValidationError = (error) => {
  const trimmed = error.trim();
  const lastPathPart = /\./.test(trimmed)
    ? compact(trimmed.split(".")).pop()
    : trimmed;

  return upperFirst(lastPathPart).replace(/_/g, " ");
};

export const validEnterKeyPress = ({ key, currentTarget }) =>
  Boolean(key && key === "Enter" && currentTarget && currentTarget.value);

export const fieldProps = ({
  name,
  setFieldTouched,
  setFieldValue,
  transformer,
  ...rest
}) => ({
  name,
  ...AUTO_ATTRS,
  onChange: (evt) => {
    const { value } = evt.target;
    const valueToSet = transformer ? transformer(value) : value;
    // Note: order important for validation
    setFieldTouched(name, true);
    setFieldValue(name, valueToSet);
  },
  onBlur: () => {
    setFieldTouched(name, true);
  },
  ...rest
});

/**
 * Prevent reset if if the form hasnt been touched / is submitting
 * @param {Object} params
 * @param {Boolean} params.isSubmitting
 * @param {Object} params.touched
 */
export const disableReset = ({ isSubmitting, touched }) =>
  Boolean(isEmpty(touched) || isSubmitting);

export const errorsDisableSubmit = ({ isSubmitting, errors }) => {
  const errorValues = errors && Object.values(errors);
  const hasErrors = !isEmpty(compact(errorValues));
  // console.log("-------: disableSubmit");
  // console.table({
  //   errors,
  //   isSubmitting
  // });

  return Boolean(isSubmitting || hasErrors);
};
/**
 * Prevent submission if the form hasnt been touched / is submitting / errors are present
 * @param {Object} params
 * @param {Boolean} params.isSubmitting
 * @param {Object} params.touched
 * @param {Object} params.errors
 */
export const disableSubmit = ({ isSubmitting, touched, errors }) => {
  const notTouched = isEmpty(touched);
  const hasErrors = errors && !isEmpty(compact(Object.values(errors)));
  // console.log("-------: disableSubmit");
  // console.table({
  //   touched,
  //   errors,
  //   isSubmitting
  // });

  return Boolean(isSubmitting || notTouched || hasErrors);
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const INDEX_PATH_RE = /]$/;

export const getFieldNameIndex = (name) => {
  let result = null;
  if (INDEX_PATH_RE.test(name)) {
    const delimeter = "[";
    const nameParts = name.split(delimeter);
    const indexSection = nameParts.pop();
    const index = parseInt(indexSection.replace(/\D/g, ""), 10);
    if (Number.isInteger(index)) {
      result = index;
    }
  }

  return result;
};

/**
 * If path ends in index then pull off last index brackets
 * Else split to last dot
 */
export const getFieldNameRoot = (name = "") => {
  if (INDEX_PATH_RE.test(name)) {
    const delimeter = "[";
    const nameParts = name.split(delimeter);
    nameParts.pop();
    return nameParts.join(delimeter);
  } else {
    const delimeter = ".";
    const nameParts = name.split(delimeter);
    return nameParts.slice(0, nameParts.length - 1).join(delimeter);
  }
};

export const getFieldPath = (pathPrefix, path) =>
  pathPrefix ? compact([pathPrefix, path]).join(".") : path;

export const getFormCrudMode = (id) => (id ? FORM_MODE.EDIT : FORM_MODE.CREATE);

const checkoutOptionDisabled = (option, admin) =>
  option.uid === PAGE_ACTION_UIDS.STRIPE_CHECKOUT &&
  !get(admin, "account.chargesEnabled");

export const extendSelectOptions = (options, { admin }) => {
  return options.map((option) => {
    const result = { ...option };
    if (checkoutOptionDisabled(option, admin)) {
      result.disabled = true;
    }
    return result;
  });
};

export const getSelectNotice = (options, { user, admin }) => {
  let notice;
  for (let optionIndex = 0; optionIndex < options.length; optionIndex++) {
    const option = options[optionIndex];
    if (checkoutOptionDisabled(option, admin)) {
      const { cta, copy } = getCheckoutDisabledMessage(admin.account);
      notice = {
        ...admin.account,
        userUUID: user.uuid,
        uid: PAGE_ACTION_UIDS.STRIPE_CHECKOUT,
        header: "Stripe checkout disabled",
        subheader: copy,
        cta
      };
    }
  }
  return notice;
};

export const getToggleProps = ({ active, key, setFieldValue, disabled }) => {
  return {
    name: key,
    icons: false,
    type: "checkbox",
    checked: active,
    value: active ? TOGGLE_STATE.YES : TOGGLE_STATE.NO,
    disabled,
    onChange: (evt) => {
      const { value } = evt.currentTarget;
      const active = value !== TOGGLE_STATE.YES;
      setFieldValue(key, active);
    }
  };
};

export const getCheckboxProps = ({ active, key, setFieldValue, disabled }) => {
  return {
    name: key,
    icons: false,
    checked: active,
    disabled,
    onChange: (evt) => {
      const { checked } = evt.target;

      setFieldValue(key, checked);
    }
  };
};

export const submitOnReturn = ({ disabled, submitForm }) => ({
  key,
  currentTarget
}) => !disabled && validEnterKeyPress({ key, currentTarget }) && submitForm();

export const integerTransformer = (value, alternative = 0) => {
  const parsed = parseInt(value, 10);

  return Number.isNaN(parsed)
    ? alternative
    : Number(Math.floor(Math.abs(parsed)));
};

/**
 * Apply upper and lower bound to percentage field
 */
export const percentTransformer = (value) => {
  const parsed = value && parseInt(value.replace(/%/, ""), 10);
  if (parsed > 100) {
    return "100";
  } else if (parsed < 0) {
    return "0";
  } else {
    return value;
  }
};

export const focusRef = (ref) =>
  ref &&
  ref.current &&
  typeof ref.current.focus === "function" &&
  ref.current.focus();

export const focusInputRefEnd = (inputRef) => {
  if (inputRef.current && inputRef.current.focus) {
    inputRef.current.focus();
    inputRef.current.selectionStart = inputRef.current.selectionEnd =
      inputRef.current.value.length;
  }
};

export const getBoolSelectValue = (value) =>
  value ? TOGGLE_STATE.YES : TOGGLE_STATE.NO;

export const handleFormCb = (
  values,
  err,
  { setSubmitting, setErrors, resetForm }
) => {
  setSubmitting(false);
  if (err) {
    setErrors(err);
  } else {
    resetForm({ values });
  }
};

export const reduceFieldSelections = (fields) =>
  fields.reduce((memo, field) => {
    if (field.uuid) {
      if (isBooleanFieldType(field.uid)) {
        memo[field.uuid] = !!field.value;
      } else {
        memo[field.uuid] = field.value;
      }
    } else {
      console.error("field is missing uuid", field);
    }

    return memo;
  }, {});

export const reduceFieldGroupSelections = (fieldGroups) =>
  fieldGroups.reduce((memo, fieldGroup) => {
    if (fieldGroup && fieldGroup.uuid) {
      if (Array.isArray(fieldGroup.fields)) {
        memo[fieldGroup.uuid] = reduceFieldSelections(fieldGroup.fields);
      }
    }
    return memo;
  }, {});

export const reduceFlatFieldGroupSelections = (fieldGroups) =>
  fieldGroups.reduce((memo, fieldGroup) => {
    if (fieldGroup && fieldGroup.uuid) {
      if (Array.isArray(fieldGroup.fields)) {
        memo = {
          ...memo,
          ...reduceFieldSelections(fieldGroup.fields)
        };
      }
    }
    return memo;
  }, {});

export const findFieldForUUID = (fieldGroups, fieldUUID) => {
  let fieldMatch;
  for (let groupIx = 0; groupIx < fieldGroups.length; groupIx++) {
    const fieldGroup = fieldGroups[groupIx];
    if (fieldGroup && Array.isArray(fieldGroup.fields)) {
      fieldMatch = fieldGroup.fields.find(({ uuid }) => uuid === fieldUUID);
      if (fieldMatch) {
        break;
      }
    }
  }
  return fieldMatch;
};

export const getFieldConfigPill = (values) => {
  if (
    values.uid === FIELD_TYPES.SELECT ||
    values.uid === FIELD_TYPES.MULTISELECT
  ) {
    const optionsCount = values.options.length;
    const optionsLabel = pluralizeCopy("option", optionsCount);
    const optionsSets = get(values, STATE_KEYS.MULTISELECT.OPTIONS_SETS);
    const optionsSetsCount = optionsSets ? Object.keys(optionsSets).length : 0;
    const optionsSetsLabel = pluralizeCopy("set", optionsSetsCount);
    const labels = [];
    /**
     * If no options or sets then use the 0 options label
     * Else add labels for either present
     */
    if (!optionsCount && !optionsSetsCount) {
      labels.push(optionsLabel);
    } else {
      if (optionsCount) {
        labels.push(optionsLabel);
      }
      if (optionsSetsCount) {
        labels.push(optionsSetsLabel);
      }
    }
    const hasOptions = optionsCount || optionsSetsCount;

    return {
      status: hasOptions ? STATUS.SUCCESS : STATUS.WARNING,
      theme: UI_THEME.SLIM,
      copy: labels.join(" & ")
    };
  }
};

/**
 * Response shape should match that of the field value
 * @param {Object} field
 * @returns
 */
export const validateAddressField = (field) => {
  let result = null;

  if (field && field.validation && field.validation.required) {
    const address = get(field, "value.address");

    result = {
      address: validateAddress(address),
      receiver: null
    };

    const receiverMeta = get(field, "meta.receiver");
    const receiver = get(field, "value.receiver");
    if (receiverMeta && receiverMeta.enabled && receiverMeta.required) {
      result.receiver = validateAddressReceiver(receiver);
    }
  }

  return !result || isEmpty(result.address) ? null : result;
};

export const validateLegalField = (field) => {
  const acceptanceType = get(field, STATE_KEYS.LEGAL.ACCEPTANCE_TYPE);
  const clickRequired =
    acceptanceType === LEGAL_CONTENT_ACCEPTANCE_TYPE.CLICKWRAP;
  const clicked = get(field, `value.${STATE_KEYS.LEGAL.ACCEPTANCE_CLICKED}`);
  const clickFailed = Boolean(clickRequired && !clicked);
  return clickFailed
    ? {
        acceptance: {
          clicked: "Checkbox must be checked."
        }
      }
    : null;
};

/**
 * Response shape should match that of the field value
 * @param {Object} field
 * @returns
 */
export const validateDatepickerField = (field) => {
  let result = null;

  const date = field.value;
  if (field && field.validation && field.validation.required) {
    if (!date) {
      result = `${field.label} is required`;
    }
  }
  if (result) {
    return result;
  }

  if (date) {
    const today = getToday();
    const selectedDate = new Date(date);
    if (Number.isNaN(selectedDate.getTime())) {
      result = `${field.label} is invalid`;
    }

    if (!result) {
      const timeframeType = get(
        field,
        STATE_KEYS.DATEPICKER.PRESENTATION_TIMEFRAME_TYPE
      );
      if (timeframeType === DATEPICKER_TIMEFRAME.FUTURE) {
        if (selectedDate < today) {
          result = "Only future dates allowed";
        }
      } else if (timeframeType === DATEPICKER_TIMEFRAME.PAST) {
        if (selectedDate > today) {
          result = "Only past dates allowed";
        }
      }
    }
  }

  return result || null;
};

export const validateTinField = async (field) => {
  let errorMessage;
  const tinCode = get(field, "value.code");
  const tinValue = get(field, "value.value");
  const tinType = get(field, "value.type");
  const hasTinValues = tinCode && tinValue && tinType;

  if (field.validation && field.validation.required && !hasTinValues) {
    errorMessage = "Tax id is required.";
  }

  if (!errorMessage && hasTinValues) {
    let tinValidation = await validateIdentityTin({
      data: {
        value: tinValue,
        code: tinCode,
        type: tinType
      }
    });
    if (isError(tinValidation)) {
      tinValidation = {
        isValid: false,
        message: "Tax id is invalid. Please try again."
      };
    }

    if (!tinValidation.isValid) {
      errorMessage = tinValidation.message;
    }
  }
  return errorMessage;
};

export const validateSelectField = (field) => {
  let result = null;

  if (field && field.validation && field.validation.required) {
    if (field.value) {
      const selectedOption = field.options.find(
        ({ uuid }) => uuid === field.value
      );
      /**
       * If theres no option match then there is a problem in the form
       * - There should always be an id match between options and field.value
       *
       * If the selected option has no uid then its the default empty option which doesnt count as a selection
       */
      if (!selectedOption || !selectedOption.uid) {
        result = [field.label, "is required"];
      }
    } else {
      result = [field.label, "is required"];
    }
  }

  return upperFirst(compact(result).join(" "));
};

/**
 * NOTE: could be used within asyncValidateFieldFactory but we defer to perform server side
 */
export const validateRequestField = async ({ field, manifestId, headers }) => {
  let errorMessage;
  const value = get(field, "value");
  const enabled =
    field.validation &&
    field.validation.request &&
    field.validation.request.enabled;
  if (enabled) {
    if (!value) {
      errorMessage = "Value is required.";
    } else if (!manifestId) {
      errorMessage = "Manifest id is required.";
    }
  }

  if (!errorMessage && value) {
    let validation = await validateManifestRequestField({
      manifestId,
      fieldUUID: field.uuid,
      headers,
      data: {
        value
      }
    });

    if (isError(validation)) {
      validation = {
        isValid: false,
        message: getErrorMessage(validation)
      };
    }

    if (!validation.isValid) {
      errorMessage = validation.message;
    }
  }

  return errorMessage;
};

const validateFieldValuePattern = (field) => {
  const validator = FIELD_VALIDATORS[field.validation.pattern];
  let isValid = true;
  let result = [];
  if (validator) {
    try {
      validator.validateSync(field.value);
    } catch (err) {
      isValid = false;
    }
  } else {
    isValid = new RegExp(field.validation.pattern).test(field.value);
  }

  if (!isValid) {
    result = [field.label, "is not valid"];
  }

  return result;
};

export const validateField = (field) => {
  let result = [];
  if (field && field.validation) {
    const needsValidation = field.validation.pattern && field.value;

    if (field.validation.required) {
      if (!field.value) {
        result = [field.label, "is required"];
      } else if (Array.isArray(field.value) && field.value.length === 0) {
        result = [field.label, "is required"];
      } else if (typeof field.value === "object") {
        for (const key in field.value) {
          if (key !== FIELD_KEYS.DEFAULT) {
            const fieldKeyVal = field.value[key];
            if (!fieldKeyVal) {
              result = [field.label, key, "is required"];
            }
          }
        }
      } else if (needsValidation) {
        result = validateFieldValuePattern(field);
      }
    } else if (needsValidation) {
      result = validateFieldValuePattern(field);
    }
  }

  return upperFirst(compact(result).join(" "));
};

export const syncValidateFieldFactory = (field) => {
  let result = null;
  if (field.uid === ENTITIES.ADDRESS) {
    result = validateAddressField(field);
  } else if (field.uid === ENTITIES.DATEPICKER) {
    result = validateDatepickerField(field);
  } else if (field.uid === ENTITIES.LEGAL) {
    result = validateLegalField(field);
  } else if (field.uid === ENTITIES.SELECT) {
    result = validateSelectField(field);
  } else {
    result = validateField(field);
  }

  return result;
};

/**
 * async validation used in validateManifest fn
 * - Note: can't use async validation within component hook so we use a sync validation instead
 * @param {Object} field
 * @returns
 */
export const asyncValidateFieldFactory = async ({ field }) => {
  let result = null;
  if (field.uid === ENTITIES.TIN) {
    result = await validateTinField(field);
  } else {
    result = syncValidateFieldFactory(field);
  }

  return result;
};

export const getValidateFieldGroups = ({ fieldGroups, name, errors }) => {
  const result = {};
  for (let groupIx = 0; groupIx < fieldGroups.length; groupIx++) {
    const fieldGroup = fieldGroups[groupIx];
    if (Array.isArray(fieldGroup.fields)) {
      for (let fieldIx = 0; fieldIx < fieldGroup.fields.length; fieldIx++) {
        const field = fieldGroup.fields[fieldIx];
        const fieldPath = getFieldPath(name, `[${groupIx}].fields[${fieldIx}]`);

        const errorMessage = syncValidateFieldFactory(field);

        if (errorMessage) {
          result[fieldPath] = errorMessage;
        }

        const fieldError = get(errors, fieldPath);
        if (!result[fieldPath] && !isEmpty(fieldError)) {
          result[fieldPath] = fieldError;
        }
      }
    }
  }
  return result;
};

/**
 * setDefaultFormFieldValues
 * Use this to set the form field values which will be used to initialize the form
 * (likely important that boolean fields should be bools)
 * - validation.editable - user can edit the value but the initial input value should be the default
 * - !validation.editable - the value is set by the seller and is not editable by the user (i.e. disclaimer input) any default value is not used to overwrite the primary value
 */
export const setDefaultFormFieldValues = ({ values, entities }) => {
  const fieldGroups = Array.isArray(values.fieldGroups)
    ? values.fieldGroups.reduce((memo, group) => {
        const fields = Array.isArray(group.fields)
          ? group.fields.reduce((memo, field) => {
              const fieldValues = { ...field };
              const entityMatch = entities.find(({ uid }) => uid === field.uid);
              const resetValueToDefault =
                entityMatch &&
                entityMatch.validation &&
                entityMatch.validation.editable;

              if (resetValueToDefault) {
                /**
                 * Need to retain false values when default is present
                 */
                fieldValues.value = notNothing(field.default)
                  ? field.default
                  : "";
              }
              memo.push(fieldValues);
              return memo;
            }, [])
          : [];
        memo.push({
          ...group,
          fields
        });
        return memo;
      }, [])
    : [];

  return {
    ...values,
    fieldGroups
  };
};

export const simulateFormSelections = ({
  name,
  values,
  setFieldValue,
  selections
}) => {
  return () => {
    const fieldGroups = cloneDeep(get(values, name));

    if (fieldGroups && selections) {
      for (const fieldUUID in selections) {
        const fieldValue = selections[fieldUUID];

        fieldGroups.forEach((group, groupIx) => {
          const fieldIx = group.fields.findIndex(
            ({ uuid }) => uuid === fieldUUID
          );
          if (fieldIx > -1) {
            set(
              fieldGroups,
              `[${groupIx}].fields[${fieldIx}].value`,
              fieldValue
            );
          }
        });
      }

      setFieldValue(name, fieldGroups);
    }
  };
};

export const mergeCombinations = (current, updated) => {
  return updated.map((combo) => {
    const result = { ...combo };
    const currentMatch = current.find(({ selections: currentSelections }) =>
      isEqual(currentSelections, combo.selections)
    );

    if (currentMatch && !isEmpty(currentMatch.result)) {
      result.result = currentMatch.result;
    }
    return result;
  });
};

/**
 * Append a new option and update combinations
 */
export const addSelectOptionAndCombination = ({
  arrayHelpers,
  setFieldValue,
  setFieldTouched,
  touched,
  // manifest.data.content.056b51c1-3a15-46d0-9242-4be0199cfade.data.fieldGroups[0].fields[0]
  name,
  fieldGroups,
  selectedFields,
  combinations
}) => {
  return (props) => {
    /**
     * Inject the new option into the field group field
     */
    const updatedFieldGroups = cloneDeep(fieldGroups);
    const [
      // manifest.data.content['someUUID']
      entityRoot,
      // fields[0]
      fieldPath
    ] = name.split(DATA_FIELD_GROUPS_KEY);
    const currentField = get(updatedFieldGroups, fieldPath);
    const newOption = FIELD_TEMPLATES.SELECT.OPTION(props);

    currentField.options.unshift(newOption);
    set(updatedFieldGroups, fieldPath, currentField);

    /**
     * Rescaffold options with updated groups
     */
    const updatedCombinations = scaffoldCombinations(
      selectedFields,
      updatedFieldGroups
    );

    const mergedCombos = mergeCombinations(combinations, updatedCombinations);

    const configCombinationsPath = `${entityRoot}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`;
    setFieldValue(configCombinationsPath, mergedCombos);

    if (!get(touched, configCombinationsPath)) {
      setFieldTouched(configCombinationsPath, true);
    }

    arrayHelpers.unshift(newOption);
  };
};

/**
 * Remove any combination which has a selection key that matches the option uuid
 */
export const removeSelectOptionAndCombination = ({
  arrayHelpers,
  setFieldValue,
  uuid,
  index,
  touched,
  // manifest.data.content.056b51c1-3a15-46d0-9242-4be0199cfade.data.fieldGroups[0].fields[0]
  name,
  fieldName,
  setFieldTouched,
  combinations
}) => {
  return () => {
    /**
     * Get all the combinations where the option uuid is not present within selections values
     */
    const updatedCombinations = combinations.filter((combination) => {
      const selectionValues = Object.values(combination.selections);
      const keepCombo = selectionValues.indexOf(uuid) === -1;
      return keepCombo;
    });

    const [
      // manifest.data.content['someUUID']
      entityRoot
    ] = fieldName.split(DATA_FIELD_GROUPS_KEY);

    const configCombinationsPath = `${entityRoot}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`;
    setFieldValue(configCombinationsPath, updatedCombinations);

    const nameTouched = get(touched, name);

    if (!nameTouched) {
      setFieldTouched(name, true);
    }

    const combosTouched = get(touched, configCombinationsPath);
    if (combosTouched) {
      setFieldTouched(configCombinationsPath, true);
    }
    arrayHelpers.remove(index);
  };
};

export const onCommandEnter = (cb) => (evt) => {
  const isEnter = evt.keyCode === KEYCODE_MAP.ENTER;
  if (isEnter || (isEnter && evt.metaKey)) {
    cb();
  }
};

export const getUpdatedMultiselectValues = ({ active, valueMap, uuid }) => {
  const updatedValueMap = valueMap ? cloneDeep(valueMap) : {};

  if (active && !updatedValueMap[uuid]) {
    updatedValueMap[uuid] = true;
  } else if (!active && updatedValueMap[uuid]) {
    delete updatedValueMap[uuid];
  }

  return Object.keys(updatedValueMap).filter(
    (val) => typeof val === "string" && val
  );
};

export const generateSelectOptions = (options) =>
  options.map((option) => {
    const result = { ...option };
    const id = option.id || option.uuid;
    if (id) {
      ["value", "id", "uuid"].forEach((key) => {
        if (!result[key]) {
          result[key] = id;
        }
      });
    }

    return result;
  });
