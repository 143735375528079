import { COMMAND_CONTENT_ID } from "components/CommandPalette/constants";
import IMAGES from "utils/constants/image";
import { BUTTON_TAGS } from "utils/constants/ui";
import PATHS from "utils/paths";

const BRAND = "PriceBlocs";
export const TAG_LINE_PRIMARY = "The no code checkout platform";
export const TAG_LINE_SECONDARY = "Capture data and payments at the same time";
export const PRODUCT_TITLE = `${BRAND} - ${TAG_LINE_PRIMARY}.`;

export const PRODUCT_TAG_HEADER =
  "PriceBlocs helps businesses build custom Stripe checkout forms they can embed anywhere. No coding required.";

export const PRODUCT_TAG_HEADER_SPANS = [
  {
    copy: "PriceBlocs helps"
  },
  {
    className: "color-primary underline fw6 ph1",
    copy: "businesses"
  },
  {
    copy:
      "build custom Stripe checkout forms they can embed anywhere. No coding required."
  }
];

export const PRODUCT_TAG_SUBHEADER =
  "Capture data and payments in a single flow, no codiing required. Connect your form and sale data to any other service using our API and automations.";

export const NO_CODE_EDITOR_VIDEO_SECTION = {
  header: {
    title: "Build any checkout form with our no-code editor.",
    body: [
      "Capture the data you need and set any payment terms in just a few clicks."
    ]
  },
  image: IMAGES.FEATURES_EDITOR_CHROME,
  commandId: COMMAND_CONTENT_ID.FUNDAMENTALS.BUILD_A_FORM,
  action: {
    copy: "Build a form",
    href: PATHS.BUILDER_APPLICATIONS,
    tag: BUTTON_TAGS.LINK
  }
};
