import { useEffect } from "react";
import get from "lodash/get";
import { useFormikContext } from "formik";
import { STATE_KEYS } from "utils/constants/state";
import ENTITIES from "components/FormerEditor/common/constants/entity";
import { usePageFactoryContext } from "utils/context";
import {
  useCurrentManifestLiveVariation,
  useEditorViewport
} from "utils/hooks";
import { getFieldPath } from "utils/form";
import { EDITOR_MODE } from "utils/constants/editor";
import { useUserFeature } from "./capabilities";
import { USER_CAPABILITY } from "utils/constants/capability";
import { SECTIONS, SECTIONS_LABEL_MAP } from "components/FormerEditor/utils";
import { MANIFEST_CONTEXT_TYPE } from "utils/constants/manifest";
import { EDITOR_PREVIEW_ID } from "utils/constants/ui";
import { useEmbed } from "./embed";
import { useIsAuthed } from "utils/selectors";
import { useStripe } from "@stripe/react-stripe-js";
import { useRouter } from "next/router";
import PATHS from "utils/paths";

export const useIsUnauthedBuilder = () => {
  const router = useRouter();
  const isAuthed = useIsAuthed();
  const builderPathRe = new RegExp(`^${PATHS.BUILDER_APPLICATIONS}`);
  const isBuilder = builderPathRe.test(router.pathname);

  return Boolean(!isAuthed && isBuilder);
};

export const useLocationModelContext = () => {
  const { pathname } = useRouter();
  return new RegExp(PATHS.FEATURES_LINKS_BUILDER).test(pathname) ||
    /\/links\/create/.test(pathname)
    ? "payment link"
    : "page";
};

export const useDirtyEditor = () => {
  const { values, touched } = useFormikContext();
  const entities = get(values, STATE_KEYS.MANIFEST.ENTITIES, []);
  const formEntity = entities.find((entity) => entity.uid === ENTITIES.FORM);
  const productCollectionEntity = entities.find(
    (entity) => entity.uid === ENTITIES.PRODUCT_COLLECTION
  );
  const result = [];

  if (formEntity && formEntity.uuid) {
    const formEntityStateKey = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntity.uuid}`;

    /**
     * Checkout combinations changes
     */
    const configCombinationsPath = `${formEntityStateKey}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`;
    const commonFormEntity = {
      uid: ENTITIES.FORM,
      uuid: formEntity.uuid
    };
    if (get(touched, configCombinationsPath)) {
      result.push({
        ...commonFormEntity,
        key: configCombinationsPath,
        value: get(touched, configCombinationsPath)
      });
    }

    /**
     * Form field group changes
     */
    const fieldGroupsPath = `${formEntityStateKey}.${STATE_KEYS.FORM.FIELD_GROUPS}`;
    if (get(touched, fieldGroupsPath)) {
      result.push({
        ...commonFormEntity,
        key: fieldGroupsPath,
        value: get(touched, fieldGroupsPath)
      });
    }
  }

  if (productCollectionEntity && productCollectionEntity.uuid) {
    const prodColEntityStateKey = `${STATE_KEYS.MANIFEST.CONTENT}.${productCollectionEntity.uuid}`;
    const commonProdCheckoutPath = `${prodColEntityStateKey}.${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_CHECKOUT_COMMON}`;
    const commonProdColEntity = {
      uid: ENTITIES.PRODUCT_COLLECTION,
      uuid: productCollectionEntity.uuid
    };

    if (get(touched, commonProdCheckoutPath)) {
      result.push({
        ...commonProdColEntity,
        key: commonProdCheckoutPath,
        value: get(touched, commonProdCheckoutPath)
      });
    }

    const productsCheckoutsPath = `${prodColEntityStateKey}.${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_PRODUCTS_CHECKOUTS}`;
    if (get(touched, productsCheckoutsPath)) {
      result.push({
        ...commonProdColEntity,
        key: productsCheckoutsPath,
        value: get(touched, productsCheckoutsPath)
      });
    }
  }

  return result;
};

export const useEditorSubmitting = () => {
  const { isSubmitting } = useFormikContext();
  const { canActivateSettings } = usePageFactoryContext();

  return Boolean(isSubmitting && canActivateSettings);
};

export const useFormModeDisabled = () => {
  const { values } = useFormikContext();
  let disabled = false;
  const mode = get(values, STATE_KEYS.EDITOR.MODE);
  if (mode && mode !== EDITOR_MODE.USE) {
    disabled = true;
  }

  return disabled;
};

export const useCommonEditorEntityParams = ({ name, menuIntent, entity }) => {
  const isUnauthedBuilder = useIsUnauthedBuilder();
  const sharedFocus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    },
    {
      key: STATE_KEYS.EDITOR.MENU_INTENT,
      value: menuIntent
    }
  ];
  const fieldGroupIndexFocus = [
    ...sharedFocus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
      value: null
    }
  ];

  const {
    canActivateSettings,
    stateKey,
    stripeStatus
  } = usePageFactoryContext();
  const stripe = useStripe();
  const { values, errors, setFieldValue } = useFormikContext();

  const editorViewport = useEditorViewport();
  const embed = useEmbed();

  /**
   * When the form entity index within layout is:
   * - [last] - set it to be full screen fixed so that the split container stretches to the bottom
   * - [has prev index] - set hasNavPrev true so that we can adjust the left side top value on desktop to accomodate for the content above
   */
  let isLast = false;
  let hasNavPrev = false;
  // If entity before is navigation then adjust the top
  if (
    !canActivateSettings &&
    Array.isArray(values.layout) &&
    Array.isArray(values.entities)
  ) {
    const layoutLength = values.layout.length;
    const lastIndex = layoutLength - 1;
    const indexInLayout = values.layout.findIndex(
      ({ uuid }) => uuid === entity.uuid
    );
    isLast = indexInLayout === lastIndex;
    const prevLayout = values.layout[indexInLayout - 1];
    const prevUuid = prevLayout && prevLayout.uuid;
    const prevEntityMatch = values.entities.find(
      (entity) => entity.uuid === prevUuid
    );
    hasNavPrev = Boolean(
      prevEntityMatch && prevEntityMatch.uid === ENTITIES.NAVIGATION
    );
  }
  const formIsFullScreen = !canActivateSettings && isLast;
  const adminLogoPath = getFieldPath(stateKey, "admin.profile.businessLogo");
  const adminIconPath = getFieldPath(stateKey, "admin.profile.businessIcon");
  const brandAssets = {
    icon: get(values, adminIconPath),
    logo: get(values, adminLogoPath)
  };

  const manifestDataPath = getFieldPath(stateKey, "data");
  const manifestData = get(values, manifestDataPath);

  const contentRoot = `content.${name}`;
  const contentPath = getFieldPath(stateKey, contentRoot);
  const dataPath = `${contentPath}.data`;
  const configPath = `${contentPath}.config`;
  /**
   * Show the branding based on:
   * - config flag
   * - presence of data
   */
  const showBrandPath = `${configPath}.${STATE_KEYS.ENTITY_CONFIG.BRAND_SHOW}`;
  const showBrand = Boolean(get(values, showBrandPath));
  const hasBrandingAssets = Boolean(
    showBrand && (brandAssets.icon || brandAssets.logo)
  );
  const showInitialPreviewPath = `${configPath}.${STATE_KEYS.ENTITY_CONFIG.INITIAL_PREVIEW_SHOW}`;
  const showInitialPreview = Boolean(get(values, showInitialPreviewPath));
  const liveVariation = useCurrentManifestLiveVariation();
  const isLive = canActivateSettings ? Boolean(liveVariation) : true;

  const stripeRefreshRequired =
    !stripe && stripeStatus.isRejected && !canActivateSettings;

  return {
    sharedFocus,
    fieldGroupIndexFocus,
    canActivateSettings,
    values,
    errors,
    editorViewport,
    isLive,
    isUnauthedBuilder,
    hasNavPrev,
    formIsFullScreen,
    brandAssets,
    hasBrandingAssets,
    manifestData,
    dataPath,
    configPath,
    contentPath,
    showInitialPreview,
    embed,
    setFieldValue,
    stripeRefreshRequired
  };
};

export const useSectionConfig = () => {
  const { values } = useFormikContext();
  const isAuthed = useIsAuthed();
  const context = get(values, STATE_KEYS.MANIFEST.CONTEXT);
  const canUseWorkflows = useUserFeature(USER_CAPABILITY.WORKFLOWS);
  const isPricing = context === MANIFEST_CONTEXT_TYPE.PRICING;

  const sections = [SECTIONS.PAGE_BUILDER];
  if (isAuthed) {
    [SECTIONS.PRODUCTS, SECTIONS.SETTINGS].forEach((section) =>
      sections.push(section)
    );

    if (canUseWorkflows) {
      sections.push(SECTIONS.WORKFLOWS);
    }

    if (isPricing) {
      sections.splice(2, 0, SECTIONS.FEATURES);
    }
  }

  return sections.map((uid) => ({
    label: SECTIONS_LABEL_MAP[uid],
    uid
  }));
};

export const useScrollToElement = (name) => {
  useEffect(() => {
    const element = document.getElementById(name);
    if (!element) {
      console.warn(`No element found for name: ${name}`);
    }
    const container = document.getElementById(EDITOR_PREVIEW_ID);
    if (!container) {
      console.warn(`No container found for name: ${EDITOR_PREVIEW_ID}`);
    }

    if (element && container) {
      const containerTop = container.getBoundingClientRect().top;
      const targetOffsetFromVisualTop = 64;
      const offset = containerTop + targetOffsetFromVisualTop;
      const containerScrollTop = container.scrollTop;
      const nodeTop = element.getBoundingClientRect().top;
      const adjustment = nodeTop - offset;
      const top = containerScrollTop + adjustment;

      container.scrollTo({
        top,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [name]);
};

export const useActiveEditorFocus = (focus) => {
  const { values } = useFormikContext();
  const currentFocus = [
    "editor.activeBlock",
    "editor.menu.intent",
    "editor.menu.activeIndex",
    "editor.menu.subIntent",
    "editor.menu.activeSubIndex"
  ].reduce((memo, focusKey) => {
    memo[focusKey] = get(values, focusKey);
    return memo;
  }, {});

  const hasFocus = Array.isArray(focus) && focus.length;
  let result = false;
  if (hasFocus) {
    result = true;
    for (let focusIx = 0; focusIx < focus.length; focusIx++) {
      const focusConfig = focus[focusIx];
      if (currentFocus[focusConfig.key] !== focusConfig.value) {
        result = false;
        break;
      }
    }
  }

  return result;
};

export const getMessagesError = (errors, path) => {
  return get(errors, `messages.errors.${path}`);
};

export const useMessagesError = (path) => {
  const { errors } = useFormikContext();
  return getMessagesError(errors, path);
};
