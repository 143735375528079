import format from "date-fns/format";
import get from "lodash/get";
import compact from "lodash/compact";
import {
  formatUnitAmount,
  formatUnitAmountFloat
} from "components/FormerEditor/common/currency";
import startCase from "lodash/startCase";
import { formatUTCTime, orderByDate } from "utils/date";
import { getStripeAdminLink } from "utils/stripe";
import { getConfig } from "utils/env";
import SERVICES from "utils/constants/services";
import { CHECKBOX_LABELS, TOGGLE_LABELS, VIEW_ON_STRIPE } from "./constants/ui";
import { saleCheckoutPreview } from "components/FormerEditor/common/preview";
import {
  FULFILLMENT_STATUS_THEME,
  FULLFILLMENT_STATUS_LABEL_MAP
} from "./constants/checkout";
import {
  PAYMENT_INTENT_STATUS,
  PAYMENT_MODE,
  PLATFORM_SOURCE
} from "./constants/payment";
import { getSessionDiscountReferences } from "./session";
import { getSubdomainOrAliasRoot } from "./url";
import { FIELD_TYPES } from "./constants/form";
import { isEmpty } from "lodash";
import {
  IDENTITY_DOCUMENT_KEYS,
  IDENTITY_ID_NUMBER_KEYS
} from "./constants/identity";
import { STATE_KEYS } from "./constants/state";

export const NA_FALLBACK = "N/A";

const FIRST_CHARGE_PATH = "payment_intent.charges.data[0]";
const IDENTITY_META_REPORT_PATH = "meta.last_verification_report";

const getSessionResourcePath = (subscription, path) => {
  return subscription ? `subscription.latest_invoice.${path}` : path;
};

export const getSessionPaymentId = (session) => {
  const subId = get(session, "subscription.id");
  if (subId) {
    return subId;
  }

  const paymentIntentId = get(session, "payment_intent.id");
  if (paymentIntentId) {
    return paymentIntentId;
  }

  const setupIntentId = get(session, "setup_intent.id");
  if (setupIntentId) {
    return setupIntentId;
  }
};

export const getSessionAdminUrl = (session) => {
  const subId = get(session, "subscription.id");
  if (subId) {
    return getStripeAdminLink(session.livemode, `subscriptions/${subId}`);
  }

  const paymentIntentId = get(session, "payment_intent.id");
  if (paymentIntentId) {
    return getStripeAdminLink(session.livemode, `payments/${paymentIntentId}`);
  }

  const setupIntentId = get(session, "setup_intent.id");
  if (setupIntentId) {
    return getStripeAdminLink(
      session.livemode,
      `setup_intents/${setupIntentId}`
    );
  }
};

const getSessionName = (session) => {
  const namePath = getSessionResourcePath(
    session.subscription,
    `${FIRST_CHARGE_PATH}.billing_details.name`
  );

  return get(session, "customer.name") || get(session, namePath);
};

const getSessionEmail = ({ session, sale }) =>
  get(session, "customer.email") ||
  get(session, "customer_details.email") ||
  get(sale, "data.customer_email");

const getSaleSourceUrl = (sale) => {
  const platform = sale.data.platform_source;
  const shortId = sale.data.short_link_short_id;

  if (platform === PLATFORM_SOURCE.LINK && shortId) {
    return `${getConfig("WEB_CLIENT_ROOT")}/links/${shortId}`;
  } else {
    return `${getSubdomainOrAliasRoot({
      subdomain: sale.data.subdomain,
      alias: sale.data.alias
    })}${sale.data.route}?silent=true`;
  }
};

export const getSessionPaymentStatus = (session) => {
  return session.subscription
    ? session.subscription.status
    : session.payment_intent
    ? session.payment_intent.status
    : session.setup_intent
    ? session.setup_intent.status
    : "";
};

export const getSessionPaidTime = (session) => {
  if (session.subscription && session.subscription.created) {
    return formatUTCTime(session.subscription.created);
  } else if (session.payment_intent && session.payment_intent.created) {
    return formatUTCTime(session.payment_intent.created);
  } else if (session.setup_intent && session.setup_intent.created) {
    return formatUTCTime(session.setup_intent.created);
  }
  return "";
};

const defaultFieldFormatter = ({ field }) => field.value;

export const FIELD_VALUE_COMPONENT = {
  [FIELD_TYPES.LEGAL]: "content_editor"
};

const legalFieldFormatter = ({ field, name }) => {
  return {
    key: name,
    component: FIELD_VALUE_COMPONENT[field.uid],
    value: get(field, "value.content.inline.html") || NA_FALLBACK
  };
};

const addressFieldFormatter = ({ field, name }) => {
  const value = field.value;
  return compact([
    value && value.receiver && value.receiver.name
      ? {
          key: `${name}.value.receiver.name`,
          label: "Receiver",
          value: value.receiver.name || NA_FALLBACK
        }
      : null,
    {
      key: `${name}.value.address.city`,
      label: "City",
      value: value.address.city || NA_FALLBACK
    },
    {
      key: `${name}.value.receiver.country`,
      label: "Country",
      value: value.address.country || NA_FALLBACK
    },
    {
      key: `${name}.value.receiver.line1`,
      label: "Line 1",
      value: value.address.line1 || NA_FALLBACK
    },
    {
      key: `${name}.value.receiver.line2`,
      label: "Line 2",
      value: value.address.line2 || NA_FALLBACK
    },
    {
      key: `${name}.value.receiver.postal_code`,
      label: "Postal Code",
      value: value.address.postal_code || NA_FALLBACK
    },
    {
      key: `${name}.value.receiver.state`,
      label: "State",
      value: value.address.state || NA_FALLBACK
    }
  ]);
};

const identityFieldFormatter = ({ field, name }) => {
  const report = get(field, IDENTITY_META_REPORT_PATH);
  let result;
  if (!isEmpty(report)) {
    if (report.type === "document") {
      const documentPath = `${name}.${IDENTITY_META_REPORT_PATH}.document`;
      result = compact([
        ...IDENTITY_DOCUMENT_KEYS.map((key) => ({
          key: `${documentPath}.${key}`,
          label: startCase(key),
          value: get(report, `document.${key}`) || NA_FALLBACK
        })),
        {
          key: `${documentPath}.address.city`,
          label: "City",
          value: report.document.address.city || NA_FALLBACK
        },
        {
          key: `${documentPath}.receiver.country`,
          label: "Country",
          value: report.document.address.country || NA_FALLBACK
        },
        {
          key: `${documentPath}.receiver.line1`,
          label: "Line 1",
          value: report.document.address.line1 || NA_FALLBACK
        },
        {
          key: `${documentPath}.receiver.line2`,
          label: "Line 2",
          value: report.document.address.line2 || NA_FALLBACK
        },
        {
          key: `${documentPath}.receiver.postal_code`,
          label: "Postal Code",
          value: report.document.address.postal_code || NA_FALLBACK
        },
        {
          key: `${documentPath}.receiver.state`,
          label: "State",
          value: report.document.address.state || NA_FALLBACK
        }
      ]);
    } else if (report.type === "id_number") {
      const idNumberPath = `${name}.${IDENTITY_META_REPORT_PATH}.id_number`;
      result = compact([
        ...IDENTITY_ID_NUMBER_KEYS.map((key) => ({
          key: `${idNumberPath}.${key}`,
          label: startCase(key),
          value: get(report, `id_number.${key}`) || NA_FALLBACK
        }))
      ]);
    }
  } else {
    result = {
      key: name,
      copy: field.value,
      value: field.value
    };
  }

  return result;
};

const fileFieldFormatter = ({ field, name }) => {
  return {
    key: name,
    copy: get(field, "meta.filename"),
    url: field.value
  };
};
const multiselectFieldFormatter = ({ field }) =>
  Array.isArray(field.value) && field.value.length
    ? field.value.join(", ")
    : NA_FALLBACK;

const checkboxFieldFormatter = ({ field }) =>
  field.value ? CHECKBOX_LABELS.CHECKED : CHECKBOX_LABELS.NOT_CHECKED;

const toggleFieldFormatter = ({ field }) =>
  field.value ? TOGGLE_LABELS.ON : TOGGLE_LABELS.OFF;

export const FIELD_FORMATTERS = {
  [FIELD_TYPES.LEGAL]: legalFieldFormatter,
  [FIELD_TYPES.ADDRESS]: addressFieldFormatter,
  [FIELD_TYPES.TIN]: defaultFieldFormatter,
  [FIELD_TYPES.IDENTITY]: identityFieldFormatter,
  [FIELD_TYPES.FILE]: fileFieldFormatter,
  [FIELD_TYPES.SELECT]: defaultFieldFormatter,
  [FIELD_TYPES.MULTISELECT]: multiselectFieldFormatter,
  [FIELD_TYPES.DATEPICKER]: defaultFieldFormatter,
  [FIELD_TYPES.PHONE]: defaultFieldFormatter,
  [FIELD_TYPES.INPUT]: defaultFieldFormatter,
  [FIELD_TYPES.TEXTAREA]: defaultFieldFormatter,
  [FIELD_TYPES.TOGGLE]: toggleFieldFormatter,
  [FIELD_TYPES.CHECKBOX]: checkboxFieldFormatter,
  [FIELD_TYPES.DISCLAIMER]: defaultFieldFormatter
};

export const prepareSaleShow = ({
  /**
   * Note: Sale passed here is actually and Event model
   * - It will represent the merchant_account_checkout_completed event
   */
  sale,
  session,
  integration,
  products = [],
  prices = [],
  coupons = [],
  taxRates = [],
  shippingRates = []
}) => {
  const currency = session.currency || "";

  const adminUrl = getSessionAdminUrl(session);
  const formattedAmountTotal = formatUnitAmountFloat({
    amount: session.amount_total,
    currency
  });

  const header = {
    formattedAmountTotal,
    currency: currency.toUpperCase(),
    status: getSessionPaymentStatus(session),
    createdAt: getSessionPaidTime(session),
    actions: [
      {
        url: adminUrl
      }
    ]
  };

  const { collapseImages, checkout } = saleCheckoutPreview({
    sale,
    products,
    prices,
    coupons,
    taxRates,
    shippingRates,
    session
  });

  checkout.actions = [
    {
      url: adminUrl,
      tooltip: VIEW_ON_STRIPE,
      copy: " "
    }
  ];

  const paymentId = getSessionPaymentId(session);
  const platformSource = sale.data.platform_source;

  const paymentDetails = {
    actions: [
      {
        url: adminUrl,
        tooltip: VIEW_ON_STRIPE,
        copy: " "
      }
    ],
    fields: [
      {
        key: "id",
        label: "ID",
        value: {
          copy: paymentId,
          url: adminUrl
        }
      },
      {
        key: "sale.data.payment_status",
        label: "Status",
        value: startCase(session.payment_status)
      },
      {
        key: "sale.data.platform_source",
        label: "Source",
        value: startCase(platformSource)
      }
    ]
  };

  if (platformSource === PLATFORM_SOURCE.LINK) {
    paymentDetails.fields.push({
      key: "sale.data.short_link_short_id",
      label: "URL",
      value: {
        copy: sale.data.short_link_short_id || "View",
        url: getSaleSourceUrl(sale)
      }
    });
  } else if (platformSource === PLATFORM_SOURCE.PAGE) {
    paymentDetails.fields.push({
      key: "sale.data.route",
      label: "URL",
      value: {
        copy: sale.data.route || "View",
        url: getSaleSourceUrl(sale)
      }
    });
  }

  const receiptPath = getSessionResourcePath(
    session.subscription,
    `${FIRST_CHARGE_PATH}.receipt_url`
  );
  const receiptUrl = get(session, receiptPath);
  if (receiptUrl) {
    paymentDetails.fields.push({
      key: `session.${receiptPath}`,
      label: "Receipt",
      value: {
        copy: "View",
        url: receiptUrl
      }
    });
  }

  const hostedInvoicePath = "subscription.latest_invoice.hosted_invoice_url";
  const hostedInvoiceURL = get(
    session,
    "subscription.latest_invoice.hosted_invoice_url"
  );
  if (hostedInvoiceURL) {
    paymentDetails.fields.push({
      key: `session.${hostedInvoicePath}`,
      label: "Hosted invoice",
      value: {
        copy: "View",
        url: hostedInvoiceURL
      }
    });
  }

  const pdfInvoicePath = "subscription.latest_invoice.invoice_pdf";
  const pdfInvoice = get(session, "subscription.latest_invoice.invoice_pdf");
  if (pdfInvoice) {
    paymentDetails.fields.push({
      key: `session.${pdfInvoicePath}`,
      label: "Invoice PDF",
      value: {
        copy: "Download",
        url: pdfInvoice
      }
    });
  }

  const customer = {
    actions: [
      {
        url: getStripeAdminLink(
          session.livemode,
          `customers/${sale.data.customer}`
        ),
        tooltip: VIEW_ON_STRIPE,
        copy: " "
      }
    ],
    fields: [
      {
        key: "sale.data.customer",
        label: "ID",
        value: {
          copy: sale.data.customer,
          url: getStripeAdminLink(
            session.livemode,
            `customers/${sale.data.customer}`
          )
        }
      }
    ]
  };

  const fulfillment = sale.data.fulfillment && {
    fields: [
      {
        key: "sale.data.fulfillment.requires_shipping",
        label: "Requires shipping",
        value: {
          copy: sale.data.fulfillment.requires_shipping ? "Yes" : "No"
        }
      }
    ]
  };

  const name = getSessionName(session);

  if (name) {
    customer.fields.push({
      key: "Name",
      value: name
    });
  }
  const email = getSessionEmail({ session, sale });
  if (email) {
    customer.fields.push({
      key: "Email",
      value: email
    });
  }

  if (session.shipping && session.shipping.address) {
    customer.fields.push({
      key: "Shipping address",
      value: [
        {
          key: "shipping.address.name",
          label: "Receiver",
          value: session.shipping.name || NA_FALLBACK
        },
        {
          key: "shipping.address.city",
          label: "City",
          value: session.shipping.address.city || NA_FALLBACK
        },
        {
          key: "shipping.address.country",
          label: "Country",
          value: session.shipping.address.country || NA_FALLBACK
        },
        {
          key: "shipping.address.line1",
          label: "Line 1",
          value: session.shipping.address.line1 || NA_FALLBACK
        },
        {
          key: "shipping.address.line2",
          label: "Line 2",
          value: session.shipping.address.line2 || NA_FALLBACK
        },
        {
          key: "shipping.address.postal_code",
          label: "Postal Code",
          value: session.shipping.address.postal_code || NA_FALLBACK
        },
        {
          key: "shipping.address.state",
          label: "State",
          value: session.shipping.address.state || NA_FALLBACK
        }
      ]
    });
  }
  let integrationDetails;
  if (integration && integration.service === SERVICES.SQUARESPACE) {
    integrationDetails = {
      actions: [
        {
          url: integration.raw.url
        }
      ],
      fields: [
        {
          key: "service",
          label: "Service",
          value: startCase(integration.service)
        }
      ]
    };
    if (integration.raw) {
      integrationDetails.fields.push({
        key: "store",
        label: "Store",
        value: {
          copy: integration.raw.siteId,
          url: integration.raw.url
        }
      });
    }

    if (sale.data.integration_order_id) {
      integrationDetails.fields.push({
        key: "sale.data.integration_order_id",
        label: "Order Id",
        value: sale.data.integration_order_id
      });
    }
  }

  let formData = null;
  if (Array.isArray(sale.data.form_data)) {
    formData = {
      actions: [
        {
          url: adminUrl,
          tooltip: VIEW_ON_STRIPE,
          copy: " "
        }
      ],
      fields: sale.data.form_data.reduce((memo, fieldGroup, fieldGroupIx) => {
        if (Array.isArray(fieldGroup)) {
          fieldGroup.forEach((field, fieldIx) => {
            const valueFormatter = FIELD_FORMATTERS[field.uid];
            if (valueFormatter) {
              const name = `sale.${STATE_KEYS.SALE.FORM_DATA}[${fieldGroupIx}][${fieldIx}]`;
              memo.push({
                key: name,
                label: field.label,
                value: valueFormatter({ field, name })
              });
            }
          });
        }
        return memo;
      }, [])
    };
  }

  return {
    collapseImages,
    header,
    checkout,
    payment: paymentDetails,
    customer,
    integration: integrationDetails,
    fulfillment,
    formData
  };
};

const getProductImagesForSale = ({ sale, products }) =>
  get(sale, STATE_KEYS.SALE.LINE_ITEMS, []).reduce((memo, item) => {
    const productMatch = products.find(({ id }) => id === item.product);
    if (productMatch && productMatch.images && productMatch.images[0]) {
      memo.push(productMatch.images[0]);
    }
    return memo;
  }, []);

export const getSaleTitle = ({ sale, products }) => {
  const saleProducts = get(sale, STATE_KEYS.SALE.LINE_ITEMS, []).reduce(
    (memo, { product }) => {
      const productMatch = products.find(({ id }) => id === product);
      if (productMatch) {
        memo.push(productMatch.name);
      }
      return memo;
    },
    []
  );

  let title = "";
  const productsCount = saleProducts.length;
  if (productsCount) {
    title = saleProducts[0];
  }

  return title;
};

export const prepareSaleListItem = ({
  sale,
  products,
  session,
  integration
}) => {
  const images = getProductImagesForSale({ sale, products });
  const title =
    session.mode === PAYMENT_MODE.SETUP
      ? startCase(session.mode)
      : getSaleTitle({ sale, products });

  const paymentId = getSessionPaymentId(session);

  const fulfillmentStatus = sale.data.fulfillment.status;

  return {
    id: sale.id,
    title,
    status: getSessionPaymentStatus(session),
    paymentId: paymentId,
    productCount: get(sale, STATE_KEYS.SALE.LINE_ITEMS, []).length,
    amounts: {
      subtotal: formatUnitAmount({
        amount: sale.data.amount_subtotal,
        currency: sale.data.currency
      }),
      total: formatUnitAmount({
        amount: sale.data.amount_total,
        currency: sale.data.currency
      }),
      discount: formatUnitAmount({
        amount: sale.data.amount_discount,
        currency: sale.data.currency
      })
    },
    source: {
      copy: sale.data.short_link_short_id,
      url: getSaleSourceUrl(sale)
    },
    shipping: session.shipping && session.shipping.address,
    fulfillment: {
      copy: FULLFILLMENT_STATUS_LABEL_MAP[fulfillmentStatus],
      status: FULFILLMENT_STATUS_THEME[fulfillmentStatus]
    },
    images,
    customer: {
      id: sale.data.customer,
      name: getSessionName(session),
      email: getSessionEmail({ session, sale })
    },
    adminUrl: getSessionAdminUrl(session),
    integration: {
      service: integration && integration.service
    },
    createdAt: format(new Date(sale.created_at), "MMM do, yyyy")
  };
};

export const orderSaleList = ({ sales, shouldOrder }) => {
  return shouldOrder ? orderByDate(sales) : sales;
};

export const prepareSaleList = ({ sales, shouldOrder }) => {
  const collection = shouldOrder ? orderByDate(sales) : sales;

  return collection.map((sale) => {
    const paymentId =
      sale.data.payment_intent_id ||
      sale.data.setup_intent_id ||
      sale.data.subscription;

    const currency = sale.data.currency;

    const paymentStatus =
      sale.data.payment_status === PAYMENT_INTENT_STATUS.NO_PAYMENT_REQUIRED
        ? PAYMENT_INTENT_STATUS.SETUP
        : sale.data.payment_status;

    const result = {
      id: sale.id,
      uuid: sale.uuid,
      paymentId,
      paymentStatus,
      platformSource: sale.data.platform_source,
      subtotal: formatUnitAmount({
        amount: sale.data.amount_subtotal || 0,
        currency: sale.data.currency
      }),
      total: formatUnitAmount({
        amount: sale.data.amount_total || 0,
        currency: sale.data.currency
      }),
      discount: formatUnitAmount({
        amount: sale.data.amount_discount || 0,
        currency: sale.data.currency
      }),
      type: startCase(sale.data.mode),
      integrationId: sale.data.integration_id,
      sessionId: sale.data.session_id,
      shortId: sale.data.short_link_short_id,
      previewUrl: getSaleSourceUrl(sale),
      fulfillmentStatus: sale.data.fulfillment.status,
      customer: sale.data.customer,
      customerEmail: sale.data.customer_email,
      ownerEmail: sale.data.owner_email,
      createdAt: format(new Date(sale.created_at), "MM/dd/yy"),
      source: sale.data.platform_source
    };

    if (currency) {
      result.currency = currency.toUpperCase();
    }

    return result;
  });
};

/**
 * Only certain fields can be edited for a sale
 * @param {Object} sale
 */
export const getSaleInitialValues = (sale) => ({
  fulfillment: {
    status: sale.data.fulfillment.status
  }
});

/**
 * @param {Object} sale - merchant_account_checkout_completed
 * @param {Object} session - Stripe session
 * @returns
 */
export const getSaleDiscounts = (sale, session) => {
  /**
   * coupon:123,coupon:456
   */
  let result = [];
  const discountIdIndex = {};
  const discountsValue = get(sale, STATE_KEYS.SALE.DISCOUNTS) || "";
  if (discountsValue) {
    result = discountsValue.split(",").reduce((memo, discount) => {
      const discountParts = compact(discount.split(":"));
      if (discountParts.length === 2) {
        const resource = discountParts[0];
        const id = discountParts[1];
        if (!discountIdIndex[id]) {
          discountIdIndex[id] = true;
        }
        memo.push({
          [resource]: id
        });
      }
      return memo;
    }, []);
  }

  /**
   * Customer may have entered a promotion code at time of purchase
   * - Such dynamic values are only present in the session and not the sale
   * - So we want to parse the associated coupon id and return is so that if can be used in preview generation
   * - we only need to return the coupon reference as if a promotion code has been applied then the parent coupon will be attached
   */
  const discountReferences = getSessionDiscountReferences(session);
  if (
    discountReferences &&
    discountReferences.coupon &&
    !discountIdIndex[discountReferences.coupon]
  ) {
    result.push({
      coupon: discountReferences.coupon
    });
  }

  return result;
};

export const getSaleShippingRate = (sale) =>
  get(sale, STATE_KEYS.SALE.SHIPPING_RATE, "");

export const getSaleShippingOptions = (sale) =>
  get(sale, STATE_KEYS.SALE.SHIPPING_OPTIONS, "")
    .split(",")
    .map((shippingRate) => shippingRate.trim());

export const getSaleTaxes = (sale) =>
  get(sale, STATE_KEYS.SALE.TAX_RATES, "")
    .split(",")
    .map((taxRate) => taxRate.trim());

export const getSaleTrial = (sale) => ({
  trial_period_days: get(sale, STATE_KEYS.SALE.TRIAL_PERIOD_DAYS, ""),
  trial_end: get(sale, STATE_KEYS.SALE.TRIAL_END, "")
});

export const saleLineItemsToSessionLineItems = (sale) =>
  sale.data.line_items.map(({ quantity, price, product, currency }) => ({
    quantity,
    price: {
      id: price,
      product,
      currency
    }
  }));
