import get from "lodash/get";
import compact from "lodash/compact";
import omit from "lodash/omit";
import upperFirst from "lodash/upperFirst";
import trimEnd from "lodash/trimEnd";
import trimStart from "lodash/trimStart";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import {
  getExclusiveTax,
  getInclusiveTax,
  formatUnitAmountFloat,
  roundAmount,
  applyTaxesAndDiscounts,
  getSumInclusiveTaxes,
  formattedPriceToUnitAmount,
  formatUnitAmount
} from "components/FormerEditor/common/currency";
import orderBy from "lodash/orderBy";
import {
  filterForwardApplyCoupons,
  filterForwardApplyCouponsWithExpiry
} from "../../../utils/coupon";
import { COUPON_DURATION } from "../../../utils/constants/coupon";
import { STATE_KEYS } from "utils/constants/state";
import { getCheckoutIntervalLabel } from "utils/price";
import { getShortLinkTrialValues } from "utils/shortLink";
import { RECURRING_INTERVALS } from "./constants";
import { pluralizeCopy } from "utils/copy";
import {
  BILLING_SCHEME,
  INTERVAL_LABELS_MAP,
  PAYMENT_MODE
} from "utils/constants/payment";
import {
  getSaleDiscounts,
  getSaleShippingOptions,
  getSaleTaxes,
  getSaleTrial
} from "utils/sale";
import { TAX_BEHAVIOR } from "utils/constants/taxRate";
import {
  getSessionShippingRate,
  getSessionSubscriptionSchedule,
  getSessionTotalDetails,
  sessionLineItemsToPrimaries
} from "utils/session";
import { getShippingDeliveryEstimateLabel } from "utils/shippingRate";
import { reduceShippingOptionsRates } from ".";
import { SHIPPING_RATE_TYPE } from "utils/constants/shippingRate";
import { formatMilliseconds, getToday, TS_FORMAT } from "utils/date";
import add from "date-fns/add";
import format from "date-fns/format";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import { reduceIdMap } from "utils/data";
import { getAdjustedLineItemQuantity } from "utils/stripe";

const SETUP_CHECKOUT_PREVIEW = {
  collapseImages: true,
  total: 0,
  checkout: {
    actions: [],
    lineItems: [],
    discountItems: [],
    taxItems: [],
    shippingItems: [],
    subscriptionScheduleItems: [],
    banner: {
      label: "Setup",
      sublabel: "Collect payment details only"
    },
    amountItems: [
      {
        sublabel: "No charge now",
        value: ""
      }
    ]
  }
};

const EMPTY_CHECKOUT_PREVIEW = {
  collapseImages: true,
  total: 0,
  checkout: {
    actions: [],
    lineItems: [],
    discountItems: [],
    taxItems: [],
    shippingItems: [],
    banner: {},
    amountItems: []
  }
};

const PREVIEW_ITEM_KEYS = ["title", "body", "label", "sublabel"];

export const PRICE_VARIES_LABEL = "Price varies";

const TOTAL_AFTER_TRIAL_LABEL = "Total after trial";
const TOTAL_DUE_TODAY_LABEL = "Total due today";
const TOTAL_DUE_LABEL = "Total due";
const TOTAL = "Total";
const UNTIL_COUPON_EXPIRES = "until coupon expires";
const TRY = "Try";
const SUBSCRIBE_TO = "Subscribe to";

const TAX_BEHAVIOR_SHORTHAND = {
  EXCLUDING: "excl",
  INCLUSIVE: "incl"
};

const getPreFinalSaleCalculationSubvalue = ({
  autoTaxes,
  hasMultipleShippingOptions,
  exclusiveTaxBehavior
}) => {
  const parts = [];
  if (autoTaxes) {
    const clusivityShorthand = exclusiveTaxBehavior
      ? TAX_BEHAVIOR_SHORTHAND.EXCLUDING
      : TAX_BEHAVIOR_SHORTHAND.INCLUSIVE;
    parts.push(`${clusivityShorthand}. taxes`);
  }
  if (hasMultipleShippingOptions) {
    parts.push("excl. shipping");
  }

  return `(${parts.join(" & ")})`;
};

/**
 * Sublabel "projects" the next interval cost
 * - Then $x per month
 * This default behavior is overriden when there is a trial amount
 * - Then the sublabel represents the first payment where no intervals have elapsed
 * - Therefore we dont need to subtract 1 from the default duration count comparison
 *
 * Standard
 * $100
 * Then $100 per month
 *
 * Trial
 * 7 days free
 * Then $100 per month
 *
 * Coupon
 * $50 (%50 off 1 months)
 * Then $100 per month
 *
 * $50 (%50 off 2 months)
 * Then $50 per month until coupon expires
 * Eventually $100 per month
 *
 * Trial and Coupon
 * 7 days free (%50 off 1 month)
 * Then $50 per month until coupon expires
 * Eventually $100 per month
 *
 * 7 days free (%50 off 2 months)
 * Then $50 per month until coupon expires
 * Eventually $100 per month
 */
export const getCheckoutBannerSublabel = ({
  currency,
  recurring,
  coupons,
  trial,
  taxRates
}) => {
  const hasTrial = trial && trial.trial_period_days > 0;
  const futureRecurringAmount = applyTaxesAndDiscounts({
    subtotal: recurring.subtotal,
    coupons: hasTrial ? coupons : filterForwardApplyCoupons(coupons),
    taxRates
  });

  const sublabelParts = [
    `Then ${formatUnitAmountFloat({
      amount: futureRecurringAmount,
      currency
    })}`,
    getCheckoutIntervalLabel(recurring.interval, recurring.interval_count)
  ];

  /**
   * When there is a free trial we subtract 1 from the duration count
   * so that the "next" expected value is actually the "first" expected value
   * i.e. $X today not $X next (month| year etc.)
   * Review the tests for clarification - this is generally confusing :)
   */
  let durationCount = 1;
  if (hasTrial) {
    durationCount -= 1;
  }
  const expiresEventually = filterForwardApplyCouponsWithExpiry({
    coupons,
    durationCount
  });

  if (expiresEventually.length > 0) {
    sublabelParts.push(UNTIL_COUPON_EXPIRES);
  }

  return sublabelParts.join(" ");
};

const getCheckoutBanner = ({
  lineItems,
  formattedAmountTotal,
  recurring,
  currency,
  coupons,
  taxRates,
  trial,
  pricesVaried
}) => {
  /**
   * Note: only include coupons which are valid for next period
   * - check recurring count and redemption restraints etc.
   */
  let sublabel = null;
  let currentRecurringAmount = null;
  let futureRecurringAmount = null;
  if (pricesVaried) {
    sublabel = `per ${recurring.interval}`;
  } else if (recurring.subtotal) {
    currentRecurringAmount = applyTaxesAndDiscounts({
      subtotal: recurring.subtotal,
      coupons,
      taxRates
    });
    futureRecurringAmount = applyTaxesAndDiscounts({
      subtotal: recurring.subtotal,
      coupons: filterForwardApplyCoupons(coupons),
      taxRates
    });

    sublabel = getCheckoutBannerSublabel({
      currency,
      recurring,
      coupons,
      taxRates,
      trial
    });
  }

  let label;
  if (pricesVaried) {
    label = PRICE_VARIES_LABEL;
  } else if (trial && trial.trial_period_days && recurring.subtotal) {
    label = `${pluralizeCopy("day", trial.trial_period_days)} free`;
  } else {
    label = formattedAmountTotal;
  }

  let title = null;
  let body = null;
  if (lineItems.length === 1) {
    const titleVal = lineItems[0].label;
    title = recurring.subtotal
      ? `${SUBSCRIBE_TO} ${titleVal}`
      : lineItems[0].label;
    body = lineItems[0].body;
  }

  return {
    title,
    body,
    label,
    sublabel,
    futureRecurringAmount,
    currentRecurringAmount
  };
};

const getAutoTaxItems = ({ currency, amount, behavior }) => {
  return [
    {
      label: `Tax (${behavior})`,
      value: `${formatUnitAmountFloat({
        amount,
        currency
      })}`
    }
  ];
};

const getCheckoutTaxRateItems = ({
  taxRates,
  currency,
  totalNetDiscounts,
  taxable
}) =>
  taxRates.reduce(
    (memo, taxRate) => {
      let taxItemAmount = 0;
      if (taxRate.inclusive) {
        taxItemAmount = getInclusiveTax(totalNetDiscounts, taxRate.percentage);
      } else {
        taxItemAmount = getExclusiveTax(taxable, taxRate.percentage);
        memo.exclusiveTaxes += taxItemAmount;
      }
      const taxItemLabel = [`${taxRate.display_name} (${taxRate.percentage}%`];
      const labelPart = taxRate.inclusive ? " inclusive)" : ")";
      taxItemLabel.push(labelPart);

      memo.taxItems.push({
        label: taxItemLabel.join(""),
        value: `${formatUnitAmountFloat({
          amount: taxItemAmount,
          currency
        })}`
      });

      return memo;
    },
    {
      taxItems: [],
      exclusiveTaxes: 0
    }
  );

const getCheckoutShippingRateItems = ({ shippingRates, currency }) =>
  shippingRates.reduce(
    (memo, shippingRate) => {
      if (shippingRate.type === SHIPPING_RATE_TYPE.FIXED_AMOUNT) {
        const shippingItemAmount = shippingRate.fixed_amount.amount;
        memo.shippingAmount += shippingItemAmount;

        const shippingItemSublabel = [shippingRate.display_name];
        if (shippingRate.delivery_estimate) {
          const estimateLabel = getShippingDeliveryEstimateLabel(shippingRate);
          if (estimateLabel) {
            shippingItemSublabel.push(estimateLabel);
          }
        }
        if (
          shippingRate.tax_behavior === TAX_BEHAVIOR.EXCLUSIVE &&
          !memo.shippingExclusiveTaxBehavior
        ) {
          memo.shippingExclusiveTaxBehavior = true;
        }

        memo.shippingItems.push({
          label: "Shipping",
          value: `${formatUnitAmountFloat({
            amount: shippingItemAmount,
            currency
          })}`,
          sublabel: shippingItemSublabel.join(" ")
        });
      }

      return memo;
    },
    {
      shippingItems: [],
      shippingAmount: 0,
      shippingExclusiveTaxBehavior: false
    }
  );

export const getOrderedShippingRateMatches = ({
  shippings = [],
  shippingRates
}) => {
  let result = [];
  if (Array.isArray(shippings)) {
    result = orderBy(
      shippings.reduce((rateMemo, shippingRateId) => {
        const shippingRateMatch =
          shippingRates &&
          shippingRates.length &&
          shippingRates.find(
            (shippingRate) => shippingRate.id === shippingRateId
          );
        if (shippingRateMatch) {
          rateMemo.push(shippingRateMatch);
        }
        return rateMemo;
      }, []),
      ["amount"]
    );
  }
  return result;
};

/**
 * Note: auto taxes get appended for sales via the session.total_details.amount_tax
 * @param {Object} params
 * @returns
 */
export const getOrderedTaxRateMatches = ({ taxes, taxRates, autoTaxes }) => {
  let result = [];
  if (Array.isArray(taxes) && !autoTaxes) {
    result = orderBy(
      taxes.reduce((rateMemo, taxRateId) => {
        const taxRateMatch =
          taxRates &&
          taxRates.length &&
          taxRates.find((taxRate) => taxRate.id === taxRateId);
        if (taxRateMatch && taxRateMatch.percentage) {
          rateMemo.push(taxRateMatch);
        }
        return rateMemo;
      }, []),
      ["percentage"]
    );
  }
  return result;
};

export const getCouponSublabel = ({ coupon, currency, recurring }) => {
  let sublabel = coupon.percent_off
    ? `${coupon.percent_off}% off`
    : `${formatUnitAmountFloat({
        amount: coupon.amount_off,
        currency
      })} off`;

  const interval = recurring && recurring.interval;
  if (interval) {
    if (coupon.duration === COUPON_DURATION.ONCE) {
      sublabel += ` for a ${interval}`;
    } else if (coupon.duration === COUPON_DURATION.REPEATING) {
      const durInMonths = coupon.duration_in_months;
      if (interval === RECURRING_INTERVALS.YEAR) {
        const adjustedInterval = Math.ceil(durInMonths / 12);
        sublabel += ` for ${pluralizeCopy(interval, adjustedInterval)}`;
      } else if (interval === RECURRING_INTERVALS.MONTH) {
        sublabel += ` for ${durInMonths} ${interval}s`;
      } else if (interval === RECURRING_INTERVALS.WEEK) {
        const adjustedInterval = Math.floor(durInMonths * 4);
        sublabel += ` for ${pluralizeCopy(interval, adjustedInterval)}`;
      } else if (interval === RECURRING_INTERVALS.DAY) {
        const adjustedInterval = Math.floor(durInMonths * 30);
        sublabel += ` for ${pluralizeCopy(interval, adjustedInterval)}`;
      }
    }
  }

  return sublabel;
};

/**
 * Coupon behavior
 * [One time]
 * - Coupons with duration repeating get treated like duration once when applied to one time prices
 * [Subscription]
 * - Duration in months is used as an interval factor applied to the recurring values but rounded up for years
 * - e.g. when duration_in_months: 3
 * - days: 3 months * 30 days = 90 days
 * - weeks: 3 months * 4 weeks = 12 weeks
 * - months: 3 months = 3 mmonths
 * - years: 3 months = 1 year
 * - years: 13 months = 2 years
 * https://stripe.com/docs/billing/subscriptions/discounts#creating-coupons
 */
export const getCheckoutDiscountItems = ({
  total,
  currency,
  coupons,
  recurring
}) => {
  const result = {
    discountAmount: 0,
    discountItems: []
  };
  if (Array.isArray(coupons)) {
    for (let couponIx = 0; couponIx < coupons.length; couponIx++) {
      const coupon = coupons[couponIx];
      const couponDiscount = coupon.percent_off
        ? total * (coupon.percent_off / 100)
        : coupon.amount_off;

      /**
       * Prevent discount from being greater than subtotal
       * - Can't have a negative checkout value
       */
      result.discountAmount += couponDiscount;
      if (result.discountAmount >= total) {
        result.discountAmount = total;
      }

      const sublabel = getCouponSublabel({ coupon, currency, recurring });

      if (couponDiscount > 0) {
        result.discountItems.push({
          label:
            /**
             * Use promotion code if present else use coupon name
             */
            coupon.promotion_code && coupon.promotion_code.code
              ? coupon.promotion_code.code
              : coupon.name,
          sublabel,
          value: `-${formatUnitAmountFloat({
            amount: couponDiscount,
            currency
          })}`
        });
      }
    }
  }
  /**
   * Rounding rules
   * https://support.stripe.com/questions/rounding-rules-for-stripe-fees
   *
   * Note: not an exact reference for how they treat discount rounding but we assume its the case for cents treatment
   * - we round the output here so that discounts represet non-decimal amounts (full cents only)
   */
  result.discountAmount = roundAmount(result.discountAmount, 0);

  return result;
};

const getDiscountsCoupons = ({ discounts, coupons, promotionCodes }) => {
  const result = [];
  if (Array.isArray(discounts)) {
    discounts.forEach((discount) => {
      if (discount.coupon && Array.isArray(coupons)) {
        const couponMatch = coupons.find(
          (coupon) => coupon.id === discount.coupon
        );
        if (couponMatch) {
          result.push(couponMatch);
        }
      } else if (discount.promotion_code && Array.isArray(promotionCodes)) {
        const promotionCodeMatch = promotionCodes.find(
          (promotionCode) => promotionCode.id === discount.promotion_code
        );
        if (promotionCodeMatch && promotionCodeMatch.coupon) {
          result.push({
            ...promotionCodeMatch.coupon,
            promotion_code: omit(promotionCodeMatch, "coupon")
          });
        }
      }
    });
  }

  return result;
};

export const sanitizeLabels = (labels) => {
  const labelCount = labels.length;
  const joined = compact(labels)
    .map((label, labelIx) => {
      if (labelCount > 1 && labelIx !== labelCount - 1) {
        return trimEnd(label.trim(), ".");
      } else {
        return label;
      }
    })
    .join(". ");

  return joined.replace(/. ,/g, ". ");
};

const reduceNewPricesBase = (newPrices = [], currency) =>
  newPrices.reduce(
    (memo, newPrice) => {
      const result = {};
      result.label = "Price";
      const amount = formattedPriceToUnitAmount({
        formattedPrice: newPrice.formattedPrice,
        currency
      });

      memo.subtotal += amount;
      if (newPrice.recurring && newPrice.recurring.interval) {
        memo.recurring.subtotal += amount;
        memo.recurring.interval = newPrice.recurring.interval;
        memo.recurring.interval_count = newPrice.recurring.interval_count;

        memo.totalLabel = TOTAL_DUE_TODAY_LABEL;

        result.sublabel = `Billed ${getCheckoutIntervalLabel(
          newPrice.recurring.interval,
          newPrice.recurring.interval_count
        )}`;
      }
      /**
       * Convert it back for consistent formatting with precision n=2
       */
      result.value = formatUnitAmountFloat({
        amount,
        currency
      });

      memo.lineItems.push(result);

      return memo;
    },
    {
      subtotal: 0,
      lineItems: [],
      recurring: {
        subtotal: 0,
        interval: "",
        interval_count: 1
      }
    }
  );

export const getCheckoutLineItems = ({
  primaries,
  currency,
  products,
  prices,
  newPrices
}) => {
  const newPricesBase =
    newPrices && newPrices.length
      ? reduceNewPricesBase(newPrices, currency)
      : {};

  const checkoutMemo = {
    subtotal: 0,
    lineItems: [],
    collapseImages: true,
    totalLabel: TOTAL_DUE_LABEL,
    recurring: {
      subtotal: 0,
      interval: "",
      interval_count: 1
    },
    pricesExclusiveTaxBehavior: false,
    pricesVaried: false,
    ...newPricesBase
  };

  return primaries && primaries.reduce
    ? primaries.reduce((memo, item) => {
        const productMatch =
          Array.isArray(products) &&
          products.find((product) => item.product === product.id);
        const priceMatch =
          Array.isArray(prices) &&
          prices.find((price) => item.price === price.id);
        if (productMatch && priceMatch) {
          const result = {};
          if (productMatch.images && productMatch.images.length) {
            if (memo.collapseImages) {
              memo.collapseImages = false;
            }
            result.image = productMatch.images[0];
          }
          result.label = productMatch.name;

          const quantity = getAdjustedLineItemQuantity(item) || 1;

          result.sublabel = compact([
            productMatch.description,
            quantity > 1 && `Qty: ${quantity}`
          ]);

          result.body = productMatch.description || null;
          if (priceMatch) {
            if (
              priceMatch.tax_behavior === TAX_BEHAVIOR.EXCLUSIVE &&
              !memo.pricesExclusiveTaxBehavior
            ) {
              memo.pricesExclusiveTaxBehavior = true;
            }

            const priceIsTiered =
              priceMatch.billing_scheme === BILLING_SCHEME.TIERED;
            if (priceIsTiered && !memo.pricesVaried) {
              memo.pricesVaried = true;
            }
            const priceIsRecurring =
              priceMatch.recurring && priceMatch.recurring.interval;

            const amount = !priceIsTiered
              ? quantity * priceMatch.unit_amount
              : 0;
            memo.subtotal += amount;

            if (priceIsRecurring) {
              memo.recurring.subtotal += amount;
              memo.recurring.interval = priceMatch.recurring.interval;
              memo.recurring.interval_count =
                priceMatch.recurring.interval_count;

              memo.totalLabel = TOTAL_DUE_TODAY_LABEL;

              if (priceIsTiered) {
                result.sublabel.push(
                  `Billed ${
                    INTERVAL_LABELS_MAP[priceMatch.recurring.interval]
                  } based on usage`
                );
              } else {
                result.sublabel.push(
                  `Billed ${getCheckoutIntervalLabel(
                    priceMatch.recurring.interval,
                    priceMatch.recurring.interval_count
                  )}`
                );
              }
            }
            // Sanitize any period comma combinations
            result.sublabel = sanitizeLabels(result.sublabel);

            result.value = priceIsTiered
              ? PRICE_VARIES_LABEL
              : formatUnitAmountFloat({
                  amount,
                  currency
                });
            if (quantity > 1) {
              result.subvalue = `${formatUnitAmountFloat({
                amount: priceMatch.unit_amount,
                currency
              })} each`;
            }
          }
          memo.lineItems.push(result);
        }
        return memo;
      }, checkoutMemo)
    : checkoutMemo;
};

/**
 * Currency from a Price record is the source of truth rather than from a primary
 * - Selected primary _should_ have a currency which aligns to the Price record
 * - but there is a potential for misalignment due to developer error
 * - newPrices are flex prices
 */
const getCheckoutPreviewBaseCurrency = ({ primaries, prices, newPrices }) => {
  let samplePrice;
  if (primaries && primaries.length > 0) {
    const firstPriceId = get(primaries, "[0].price");
    samplePrice =
      prices && prices.find && prices.find(({ id }) => id === firstPriceId);
  } else if (newPrices && newPrices.length) {
    samplePrice = newPrices[0];
  }

  return samplePrice && samplePrice.currency;
};

const INSTALLMENT_LABELS = {
  NUMBER_OF_PAYMENTS: "Number of payments",
  FREQUENCY: "Frequency",
  FIRST_PAYMENT_AMOUNT: "First payment",
  FIRST_PAYMENT_DATE: "First payment date",
  LAST_PAYMENT_DATE: "Last payment date",
  REMAINING_PER_PAYMENT: "Remaining per payment",
  PER_PAYMENT: "Per payment",
  TOTAL: "Total amount"
};

const getProjectedAppliedCost = ({ current, future, iterations }) =>
  current + future * (iterations - 1);

const getAmountNumber = (val) =>
  Number.isFinite(val) && val > 0 ? roundAmount(val, 0) : 0;

export const getSubscriptionScheduleItems = ({
  schedule,
  prices,
  /**
   * The recurring subtotal with discounts and taxes applied
   */
  currentRecurringAmount,
  futureRecurringAmount,
  recurring,
  trial,
  currency,
  preFinalSaleCalculationSubvalue
}) => {
  const result = [];

  /**
   * TODO: need to account for first phase being a trial
   */
  const ctxPhase =
    schedule && schedule.phases && schedule.phases.find(({ trial }) => !trial);
  if (ctxPhase && futureRecurringAmount) {
    const currentRecurring = getAmountNumber(currentRecurringAmount);
    const futureRecurring = getAmountNumber(futureRecurringAmount);

    let numberOfPayments;
    let paymentFrequency;
    let firstPaymentAmount;
    let perPaymentAmount;
    let firstPaymentDate;
    let lastPaymentDate;
    let totalAmount;

    if (ctxPhase.iterations) {
      numberOfPayments = {
        label: INSTALLMENT_LABELS.NUMBER_OF_PAYMENTS,
        value: `${ctxPhase.iterations}`
      };
      paymentFrequency = {
        label: INSTALLMENT_LABELS.FREQUENCY,
        value: upperFirst(INTERVAL_LABELS_MAP[recurring.interval])
      };
      let today = getToday();

      /**
       * Adjust today timestamp for any trial period days
       */
      if (trial && trial.trial_period_days) {
        today = add(today, {
          days: trial.trial_period_days
        });
      }
      const countAfterFirst = ctxPhase.iterations - 1;
      const lastPayment = add(today, {
        [`${recurring.interval}s`]: countAfterFirst
      });

      firstPaymentDate = {
        label: INSTALLMENT_LABELS.FIRST_PAYMENT_DATE,
        value: format(today, TS_FORMAT.MDY)
      };
      lastPaymentDate = {
        label: INSTALLMENT_LABELS.LAST_PAYMENT_DATE,
        value: format(lastPayment, TS_FORMAT.MDY)
      };
      let perPaymentLabel = INSTALLMENT_LABELS.PER_PAYMENT;
      if (currentRecurring && currentRecurring !== futureRecurring) {
        firstPaymentAmount = {
          label: INSTALLMENT_LABELS.FIRST_PAYMENT_AMOUNT,
          value: formatUnitAmount({
            amount: currentRecurring,
            currency
          })
        };
        perPaymentLabel = INSTALLMENT_LABELS.REMAINING_PER_PAYMENT;
      }
      perPaymentAmount = {
        label: perPaymentLabel,
        value: formatUnitAmount({
          amount: futureRecurringAmount,
          currency
        })
      };
      totalAmount = {
        label: INSTALLMENT_LABELS.TOTAL,
        subvalue: preFinalSaleCalculationSubvalue,
        value: formatUnitAmount({
          amount: getProjectedAppliedCost({
            current: currentRecurring,
            future: futureRecurring,
            iterations: ctxPhase.iterations
          }),
          currency
        })
      };
    } else if (schedule.id) {
      const logInput = {
        subscription_schedule_id: schedule.id,
        subscription: schedule.subscription
      };
      let interval = null;

      const priceMap = reduceIdMap(prices);

      for (let index = 0; index < ctxPhase.items.length; index++) {
        const { price } = ctxPhase.items[index];

        if (
          priceMap[price] &&
          priceMap[price].recurring &&
          priceMap[price].recurring.interval
        ) {
          interval = priceMap[price].recurring.interval;
          break;
        }
      }
      const startDate = new Date(formatMilliseconds(ctxPhase.start_date));
      const endDate = new Date(formatMilliseconds(ctxPhase.end_date));
      const duration = intervalToDuration({
        start: startDate,
        end: endDate
      });

      if (interval) {
        /**
         * NOTE:
         * - iterations is not a value which is exposed to the requestor
         * - its used as a convenience input for stripe to auto set start and end dates
         * - requestors will only receive start and end date attributes as a result
         */
        const formattedDuration = formatDuration(duration, {
          format: [`${interval}s`]
        });
        const iterations = parseInt(formattedDuration.replace(/\D/g, ""), 10);

        if (Number.isInteger(iterations)) {
          numberOfPayments = {
            label: INSTALLMENT_LABELS.NUMBER_OF_PAYMENTS,
            value: `${iterations}`
          };

          totalAmount = {
            label: INSTALLMENT_LABELS.TOTAL,
            subvalue: preFinalSaleCalculationSubvalue,
            value: formatUnitAmount({
              amount: getProjectedAppliedCost({
                current: currentRecurring,
                future: futureRecurring,
                iterations
              }),
              currency
            })
          };
        } else {
          console.error("Subscription schedule: no iteration count", logInput);
        }
      } else {
        console.error(
          "Subscription schedule: no interval for phase[0]",
          logInput
        );
      }
      paymentFrequency = {
        label: INSTALLMENT_LABELS.FREQUENCY,
        value: upperFirst(INTERVAL_LABELS_MAP[interval])
      };
      let perPaymentLabel = INSTALLMENT_LABELS.PER_PAYMENT;
      if (currentRecurring && currentRecurring !== futureRecurring) {
        firstPaymentAmount = {
          label: INSTALLMENT_LABELS.FIRST_PAYMENT_AMOUNT,
          value: formatUnitAmount({
            amount: currentRecurring,
            currency
          })
        };
        perPaymentLabel = INSTALLMENT_LABELS.REMAINING_PER_PAYMENT;
      }
      perPaymentAmount = {
        label: perPaymentLabel,
        value: formatUnitAmount({
          amount: futureRecurringAmount,
          currency
        })
      };
      firstPaymentDate = {
        label: INSTALLMENT_LABELS.FIRST_PAYMENT_DATE,
        value: format(startDate, TS_FORMAT.MDY)
      };
      lastPaymentDate = {
        label: INSTALLMENT_LABELS.LAST_PAYMENT_DATE,
        value: format(endDate, TS_FORMAT.MDY)
      };
    }

    [
      numberOfPayments,
      paymentFrequency,
      firstPaymentAmount,
      perPaymentAmount,
      firstPaymentDate,
      lastPaymentDate,
      totalAmount
    ].forEach((item) => item && result.push(item));
  }

  return result;
};

export const getHasPurchasableProducts = ({ primaries, newPrices }) => {
  const hasPrimaries = Array.isArray(primaries) && primaries.length > 0;
  const hasNewPrices = Array.isArray(newPrices) && newPrices.length > 0;
  return hasPrimaries || hasNewPrices;
};

/**
 * When there is only one product in the checkout but also other extend lines for discounts | taxes etc
 * We need to adjust the banner title presentation
 * That way we can ensure consistency with Stripe Checkout
 */
const complexSingleProductCheckoutBanner = ({
  checkout,
  hasTrial,
  recurring
}) => {
  const bannerBody = checkout.lineItems[0].body;
  checkout.banner.body = bannerBody;
  const sublabel = checkout.lineItems[0].sublabel;

  let lineItemSublabel = null;
  if (sublabel) {
    const parts = sublabel.split(bannerBody);
    const processedSublabel = compact(parts)[0];
    if (processedSublabel) {
      lineItemSublabel = trimStart(processedSublabel, ".").trim();
    }
  }

  checkout.lineItems[0].sublabel = null;
  checkout.lineItems[0].subvalue = lineItemSublabel;
  const titleParts = [checkout.lineItems[0].label];
  if (hasTrial) {
    titleParts.unshift(TRY);
  } else if (recurring.subtotal) {
    titleParts.unshift(SUBSCRIBE_TO);
  }

  checkout.banner.title = titleParts.join(" ");
};

/**
 * ==========================
 * CheckoutPreview
 * ==========================
 * Generate the checkout preview based on all of the checkout inputs
 * ==========================
 * 1. LineItems - determine recurring prices, image presence, price tax behavior and running total
 * 2. Discounts - reduce checkout items and subtract from running total
 * 3. Taxes - calculate taxable amounts net of discounts and apply result to running total
 * 4. Shippings - process shippings and determin rate tax behavior
 * 5. AutoTaxes - add tax line items for any valid auto taxes and add exclusive amounts to running total
 * 6. Formatting - format line items based on trial and totals
 * 7. Subtotals - add any relevant subtotal line items
 * 8. Banner - generate result checkout banner
 */
export const getResourceCheckoutPreview = ({
  /**
   * Selections
   * - Checkout references to Stripe models
   * - ids or objects with nested keys that are ids
   */
  primaries = [],
  discounts = [],
  taxes = [],
  shippings = [],
  shippingOptionRates = [],
  // Flex prices
  newPrices = [],
  /*
   * Meta
   * - mode - payment mode of the link
   * - trial - object of trial period days / end
   * - autoTaxes - flag of whether the automatic taxes are enabled
   * - totalDetails - stripe session total_details
   */
  mode,
  trial = {},
  autoTaxes = false,
  totalDetails,
  subscriptionSchedule,
  /**
   * Models
   * - Real Stripe models
   */
  products = [],
  prices = [],
  coupons = [],
  taxRates = [],
  shippingRates = [],
  promotionCodes = []
}) => {
  const currency = getCheckoutPreviewBaseCurrency({
    primaries,
    prices,
    newPrices
  });

  const checkout = {
    actions: [],
    lineItems: [],
    discountItems: [],
    taxItems: [],
    shippingItems: [],
    subscriptionScheduleItems: []
  };

  const hasPurchasableProducts = getHasPurchasableProducts({
    primaries,
    newPrices
  });

  if (mode === PAYMENT_MODE.SETUP) {
    return SETUP_CHECKOUT_PREVIEW;
  } else if (!hasPurchasableProducts) {
    return EMPTY_CHECKOUT_PREVIEW;
  } else {
    const hasTrial = trial && trial.trial_period_days > 0;
    /**
     * 1. Line Items
     * - Create a line item for each product / price / quantity / currency combo
     * - Calculate the cumulative subtotal
     * - Adjust the labels to reflect recurring state
     */
    const {
      recurring,
      lineItems,
      subtotal,
      totalLabel,
      collapseImages,
      pricesExclusiveTaxBehavior,
      pricesVaried
    } = getCheckoutLineItems({
      primaries,
      products,
      prices,
      currency,
      newPrices
    });

    /**
     * Total will be added to / subtracted from depending on discounts and taxes
     */
    let total = subtotal;

    /**
     * 2. Discounts
     * - Create a line item for each coupon to be applied
     * - Calculate the cumulative subtotal
     * - Adjust the labels to reflect recurring state
     */
    const couponMatches = getDiscountsCoupons({
      discounts,
      coupons,
      promotionCodes
    });
    const { discountAmount, discountItems } = getCheckoutDiscountItems({
      total,
      currency,
      coupons: couponMatches,
      recurring
    });
    checkout.discountItems = discountItems;

    /**
     * Subtract discounts from total
     */
    const totalNetDiscounts = total - discountAmount;

    if (discountAmount) {
      total -= discountAmount;
    }

    /**
     * 3. Taxes
     * - Create a tax line item for each applied tax
     * - Apply the correct treatment and calculation depending on inclusive / exclusive tax type
     * - Exclusive taxes are charged on the taxable amount which is:
     * - subtotal - (discounts + inclusive taxes)
     * - Inclusive taxes are added to the total
     * - Shipping is not taxed so it happens after
     */
    const taxRateMatches = getOrderedTaxRateMatches({
      taxes,
      taxRates
    });

    if (taxRateMatches.length) {
      const sumInclusive = getSumInclusiveTaxes({
        taxRates: taxRateMatches,
        totalNetDiscounts
      });
      const taxable = total - sumInclusive;
      const { taxItems, exclusiveTaxes } = getCheckoutTaxRateItems({
        taxRates: taxRateMatches,
        totalNetDiscounts,
        currency,
        taxable
      });

      checkout.taxItems = taxItems;

      total += exclusiveTaxes;
    }

    /**
     * 4. Shippings
     * - Sum the total shipping amount and add to the total
     */
    const shippingRateMatches = getOrderedShippingRateMatches({
      shippings,
      shippingRates
    });
    let shippingExclusiveTaxBehavior = false;
    if (shippingRateMatches.length) {
      const shippingRateCheckout = getCheckoutShippingRateItems({
        shippingRates: shippingRateMatches,
        currency
      });

      shippingExclusiveTaxBehavior =
        shippingRateCheckout.shippingExclusiveTaxBehavior;

      checkout.shippingItems = shippingRateCheckout.shippingItems;

      total += shippingRateCheckout.shippingAmount;
    }
    const exclusiveTaxBehavior =
      pricesExclusiveTaxBehavior || shippingExclusiveTaxBehavior;

    /**
     * 5. AutoTaxes
     * If auto taxes and total details present
     * - append tax line item
     * - add any exlusive taxes
     */
    if (autoTaxes && totalDetails && totalDetails.tax) {
      checkout.taxItems = getAutoTaxItems({
        currency: totalDetails.currency,
        amount: totalDetails.tax,
        behavior: exclusiveTaxBehavior
          ? TAX_BEHAVIOR.EXCLUSIVE
          : TAX_BEHAVIOR.INCLUSIVE
      });

      if (exclusiveTaxBehavior) {
        total += totalDetails.tax;
      }
    }

    /**
     * 6. Formatting
     * - Add the final amount items
     * - Update the subtotal line to relflect and recurring forward charges
     * - Create the preview banner amount
     */

    /**
     * Pre checkout (i.e no final sale calculations have been made)
     * - Add the (excl | incl) taxes label
     * Post checkout
     * - Don't add this label - the subtotal will be "all in"
     */
    const preFinalSale = isEmpty(totalDetails);
    const hasMultipleShippingOptions =
      Array.isArray(shippingOptionRates) && shippingOptionRates.length > 1;
    const hasAmbiguousAmount = autoTaxes || hasMultipleShippingOptions;

    const preFinalSaleCalculationSubvalue =
      hasAmbiguousAmount && preFinalSale
        ? getPreFinalSaleCalculationSubvalue({
            autoTaxes,
            hasMultipleShippingOptions,
            exclusiveTaxBehavior
          })
        : "";

    const totalAmount = total > 0 ? total : 0;
    const formattedAmountTotal = formatUnitAmountFloat({
      amount: totalAmount,
      currency
    });
    const subtotalWithTrial = Boolean(
      recurring.subtotal && trial && trial.trial_period_days
    );
    if (subtotalWithTrial) {
      const subtotalAmountItem = {
        label: TOTAL_AFTER_TRIAL_LABEL,
        value: formattedAmountTotal
      };
      if (preFinalSaleCalculationSubvalue) {
        subtotalAmountItem.subvalue = preFinalSaleCalculationSubvalue;
      }

      checkout.amountItems = [
        subtotalAmountItem,
        {
          label: preFinalSale ? TOTAL_DUE_TODAY_LABEL : TOTAL,
          value: formatUnitAmountFloat({
            amount: 0,
            currency
          })
        }
      ];
    } else {
      const amountItem = {
        label: preFinalSale ? totalLabel : TOTAL,
        value: formattedAmountTotal
      };
      if (preFinalSaleCalculationSubvalue) {
        amountItem.subvalue = preFinalSaleCalculationSubvalue;
      }

      checkout.amountItems = [amountItem];
    }

    /**
     * 7. Subtotals
     * - Add subtotal line item if required
     */
    const needsSubtotalLine =
      checkout.discountItems.length > 0 ||
      checkout.taxItems.length > 0 ||
      checkout.shippingItems.length > 0;
    if (needsSubtotalLine) {
      lineItems.push({
        label: "Subtotal",
        value: `${formatUnitAmountFloat({
          amount: subtotal,
          currency
        })}`
      });
    }

    /**
     * 8. Banner
     * - Generate result checkout banner
     */
    if (totalAmount || recurring.subtotal || pricesVaried) {
      const checkoutBanner = getCheckoutBanner({
        lineItems,
        formattedAmountTotal,
        currency,
        recurring,
        taxRates: taxRateMatches,
        coupons: couponMatches,
        trial
      });
      checkout.banner = pick(checkoutBanner, PREVIEW_ITEM_KEYS);

      if (subscriptionSchedule) {
        checkout.subscriptionScheduleItems = getSubscriptionScheduleItems({
          schedule: subscriptionSchedule,
          prices,
          futureRecurringAmount: checkoutBanner.futureRecurringAmount,
          currentRecurringAmount: checkoutBanner.currentRecurringAmount,
          recurring,
          trial,
          totalAmount,
          currency,
          preFinalSaleCalculationSubvalue
        });
      }
    }

    checkout.lineItems = lineItems;
    const hasComplexSingleProductCheckout =
      checkout.banner && needsSubtotalLine && checkout.lineItems.length === 2;
    if (hasComplexSingleProductCheckout) {
      complexSingleProductCheckoutBanner({
        checkout,
        hasTrial,
        recurring
      });
    }

    return {
      collapseImages,
      exclusiveTaxBehavior,
      autoTaxes,
      checkout,
      total: roundAmount(totalAmount, 0),
      currency
    };
  }
};

export const shortLinkCheckoutPreview = ({
  shortLink,
  products = [],
  prices = [],
  coupons = [],
  taxRates = [],
  shippingRates = [],
  promotionCodes = []
}) => {
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
  const newPrices = get(shortLink, STATE_KEYS.SHORT_LINK.PRICES) || [];
  const discounts = get(shortLink, STATE_KEYS.SHORT_LINK.DISCOUNTS) || [];
  const taxes = get(shortLink, STATE_KEYS.SHORT_LINK.TAX_RATES) || [];
  const subscriptionSchedule = get(
    shortLink,
    STATE_KEYS.SHORT_LINK.SUBSCRIPTION_SCHEDULE
  );
  /**
   * Include cost of shipping in preview if there is only one option
   * If there are multiple we exclude the cost but should provide a range in total sublabel
   */
  const shippingOptions =
    get(shortLink, STATE_KEYS.SHORT_LINK.SHIPPING_OPTIONS) || [];
  const shippingOptionRates = reduceShippingOptionsRates(shippingOptions);
  const shippings = shippingOptionRates.length === 1 ? shippingOptionRates : [];
  const mode = get(shortLink, STATE_KEYS.SHORT_LINK.MODE);
  const autoTaxes = Boolean(
    get(shortLink, STATE_KEYS.SHORT_LINK.AUTOMATIC_TAX_ENABLED)
  );
  const trial = getShortLinkTrialValues({ shortLink });
  const totalDetails = null;

  return getResourceCheckoutPreview({
    /**
     * Selections
     * - Checkout references to Stripe models
     * - ids or objects with nested keys that are ids
     */
    primaries,
    discounts,
    taxes,
    shippings,
    shippingOptionRates,
    /**
     * Meta
     * - mode - payment mode of the link
     * - trial - object of trial period days / end
     * - autoTaxes - flag of whether the automatic taxes are enabled
     * - totalDetails - stripe session total_details
     */
    mode,
    trial,
    autoTaxes,
    totalDetails,
    subscriptionSchedule,
    /**
     * Models
     * - Real Stripe models
     */
    products,
    prices,
    coupons,
    taxRates,
    shippingRates,
    promotionCodes,
    newPrices
  });
};

export const saleCheckoutPreview = ({
  sale,
  products,
  prices,
  coupons,
  taxRates = [],
  shippingRates = [],
  session
}) => {
  /**
   * Pull and convert session line items because they are the source of truth
   * Don't use sale start because adjustable quantities can cause a sync issue
   * i.e. start checkout with 1 item, customer updates quantities to 3
   */
  const sessionPrimaries = sessionLineItemsToPrimaries(session);
  /**
   * Sales wont have new prices because they will have already been created
   * So we pass an empty collection here for consistency
   */
  const newPrices = [];

  const taxes = getSaleTaxes(sale);
  const shippingRate = getSessionShippingRate(session);
  const shippings = shippingRate ? [shippingRate] : [];
  const discounts = getSaleDiscounts(sale, session);
  const shippingOptionRates = getSaleShippingOptions(sale);
  const mode = get(sale, "data.mode", "");
  const autoTaxes = get(sale, "data.automatic_tax");
  const trial = getSaleTrial(sale);
  const totalDetails = getSessionTotalDetails(session);
  const subscriptionSchedule = getSessionSubscriptionSchedule(session);

  return getResourceCheckoutPreview({
    /**
     * Selections
     * - Checkout references to Stripe models
     * - ids or objects with nested keys that are ids
     */
    primaries: sessionPrimaries,
    discounts,
    taxes,
    shippings,
    shippingOptionRates,
    /**
     * Meta
     * - mode - payment mode of the link
     * - trial - object of trial period days / end
     * - autoTaxes - flag of whether the automatic taxes are enabled
     * - totalDetails - stripe session total_details
     */
    mode,
    trial,
    autoTaxes,
    totalDetails,
    subscriptionSchedule,
    /**
     * Models
     * - Real Stripe models
     */
    products,
    prices,
    coupons,
    taxRates,
    shippingRates,
    newPrices
  });
};

export const getCheckoutPreviewSublabel = ({ banner, preview }) => {
  let sublabel = banner && banner.sublabel;
  if (!sublabel && preview.lineItems && preview.lineItems[0]) {
    const sublabelParts = [];
    if (preview.lineItems[0].label) {
      sublabelParts.push(preview.lineItems[0].label);
    }
    /**
     * Check for more than 2 lines because when one item is in the cart:
     * line 1 - item
     * line 2 - subtotal
     */
    if (preview.lineItems.length > 2) {
      sublabelParts.push(`+ ${preview.lineItems.length - 1} more`);
    }

    sublabel = sublabelParts.join(" ");
  }

  return sublabel;
};
