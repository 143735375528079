import { VIEWPORT_SIZE } from "./viewport";

export const EMBED_CONTAINER_CLASS = "pb__embed--container";

const SPLIT = "split";
const SINGLE = "single";
export const EMBED_LAYOUT = {
  SPLIT,
  SINGLE
};

export const EMBED_LAYOUT_OPTIONS = [
  {
    uid: SINGLE,
    label: "Single page",
    description: [
      "Checkout preview and page elements are always presented vertically.",
      "Best suited for partial page embeds."
    ]
  },
  {
    uid: SPLIT,
    label: "Split page",
    description: [
      "Checkout preview and page elements are presented in a split layout on large screens and vertically on small screens.",
      "Best suited for full page embeds."
    ]
  }
];

export const EMBED_LAYOUT_KEYS = {
  [SPLIT]: SPLIT,
  [SINGLE]: SINGLE
};

export const EMBED_LAYOUT_VIEWPORT_MAP = {
  [SPLIT]: VIEWPORT_SIZE.DESKTOP,
  [SINGLE]: VIEWPORT_SIZE.MOBILE
};

export const EMBED_META_SNIPPET = `<meta name='viewport' content='width=device-width, initial-scale=1'>`;
export const EMBED_CODE_ID = "embed-code-preview";
export const EMBED_KEYS = {
  PARAMS_EXTEND_QS: "params.extendQS",
  PARAMS_INCLUDE_CANCEL_URL: "params.includeCancelUrl"
};
