import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { METADATA_KEYS } from "utils/constants/meta";
import { TAX_BEHAVIOR } from "utils/constants/taxRate";
import { CAPTURE_METHOD } from "utils/constants/payment";

export const UUID_PATTERN = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
export const ALIAS_PATTERN = /^[a-z0-9-]{3,}$/i;
export const ALIAS_VALIDATION_MESSAGE =
  "Only letters, numbers and dashes are allowed.";

const URL_TYPE = Yup.string()
  .url("URL must be valid")
  .required("URL is required");

export const DOMAIN_TYPE = Yup.string()
  .url("Domain must be valid")
  .required("Domain is required");

const OPTIONAL_URL_TYPE = Yup.string().url("URL must be valid").nullable();

export const URL_SCHEMA = Yup.object().shape({
  url: URL_TYPE
});

export const METADATA_SCHEMA = Yup.object()
  .shape(
    METADATA_KEYS.reduce((memo, key) => {
      memo[key] = Yup.string().nullable();
      return memo;
    }, {})
  )
  .nullable();

const LAZY_DEV_URL_NULLABLE = Yup.lazy(() =>
  process.env.NODE_ENV === "development"
    ? Yup.string().nullable()
    : OPTIONAL_URL_TYPE
);

const LAZY_DEV_URL_REQUIRED = Yup.lazy(() =>
  process.env.NODE_ENV === "development" ? Yup.string().required() : URL_TYPE
);

export const LAZY_URL_REQUIRED_SCHEMA = Yup.object().shape({
  url: LAZY_DEV_URL_REQUIRED
});

export const OPTIONAL_EMAIL_SCHEMA = Yup.object().shape({
  email: Yup.string().email("Invalid email address").nullable()
});

export const REQUIRED_EMAIL_SHAPE = {
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
};

export const EMAIL_SCHEMA = Yup.object().shape(REQUIRED_EMAIL_SHAPE);

/**
 * Note: the when key path (i.e recurring)
 * - is cloned and passed to the is condition
 */
const FORMATTED_PRICE_SCHEMA = Yup.string().when("recurring", {
  is: (recurring) => Boolean(recurring && recurring.interval),
  then: Yup.string().nullable(),
  otherwise: Yup.string().required("Price must be greater than 0")
});

const BASE_SUBSCRIPTION_SHAPE = {
  ...REQUIRED_EMAIL_SHAPE,
  complete: Yup.boolean()
    .required("Card details are incomplete")
    .oneOf([true], "Card details are incomplete"),
  orgUUID: Yup.string().required("An org uuid must be present"),
  userUUID: Yup.string().required("A user uuid must be present"),
  interval: Yup.string().required("An interval must be present"),
  items: Yup.array()
    .of(
      Yup.object().shape({
        price: Yup.string().required(),
        quantity: Yup.number().required().integer().min(0)
      })
    )
    .min(1)
    .required("A price must be chosen"),
  customerId: Yup.string().required("A valid customer must be present")
};

export const CREATE_SUBSCRIPTION_SCHEMA = Yup.object().shape(
  BASE_SUBSCRIPTION_SHAPE
);

export const UPDATE_SUBSCRIPTION_SCHEMA = Yup.object().shape({
  ...BASE_SUBSCRIPTION_SHAPE,
  subscriptionId: Yup.string().required("A subscription id must be present"),
  subscriptionUUID: Yup.string().required(
    "A subscription uuid must be present"
  ),
  paymentMethodId: Yup.string()
});

export const LICENSES_SCHEMA = Yup.object().shape({
  licenses: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        organization_user_id: Yup.string().required(),
        product_id: Yup.string().required()
      })
    )
    .required("Licenses must be set")
});

export const CREATE_PAYMENT_METHOD_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  complete: Yup.boolean()
    .required("Card details are incomplete")
    .oneOf([true], "Card details are incomplete"),
  orgUUID: Yup.string().required("An org uuid must be present"),
  userUUID: Yup.string().required("A user uuid must be present"),
  customerId: Yup.string().required("A valid customer must be present")
});

export const FEATURES_SCHEMA = Yup.object().shape({
  features: Yup.array().of(
    Yup.object().shape({
      title: Yup.string("Feature title must be present").required(),
      description: Yup.string().nullable()
    })
  )
});

export const FEATURE_GROUPS_SCHEMA = Yup.object().shape({
  featureGroups: Yup.array().of(
    Yup.object().shape({
      title: Yup.string("Feature group title must be present").required(),
      description: Yup.string().nullable()
    })
  )
});

export const CREATE_PRICE_SCEHEMA = Yup.object().shape({
  product: Yup.string().required(),
  recurring: Yup.object()
    .shape({
      interval: Yup.string().required(),
      interval_count: Yup.number().min(1),
      usage_type: Yup.string().required()
    })
    .nullable(),
  currency: Yup.string().required(),
  formattedPrice: FORMATTED_PRICE_SCHEMA
});

export const PRICES_SCHEMA = Yup.object().shape({
  prices: Yup.array().of(CREATE_PRICE_SCEHEMA).required()
});

export const UPDATE_PRICE_SCHEMA = Yup.object().shape({
  id: Yup.string().required(),
  product: Yup.string().required(),
  active: Yup.boolean(),
  recurring: Yup.object()
    .shape({
      interval: Yup.string().required(),
      interval_count: Yup.number().min(1),
      usage_type: Yup.string().required()
    })
    .nullable(),
  currency: Yup.string().required(),
  formattedPrice: FORMATTED_PRICE_SCHEMA,
  tax_behavior: Yup.string().oneOf(
    Object.values(TAX_BEHAVIOR),
    "Tax behavior is invalid"
  )
});

/**
 * Create Product
 * - no product id is present yet so we dont validate for its presence in the price shape
 */
const CREATE_PRODUCT_PRICE_SCEHEMA = Yup.object().shape({
  recurring: Yup.object()
    .shape({
      interval: Yup.string().required(),
      usage_type: Yup.string().required()
    })
    .nullable(),
  currency: Yup.string().required(),
  formattedPrice: FORMATTED_PRICE_SCHEMA
});

export const CREATE_PRODUCT_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().nullable(),
  prices: Yup.array().of(CREATE_PRODUCT_PRICE_SCEHEMA),
  images: Yup.array().of(URL_TYPE),
  metadata: METADATA_SCHEMA
});

export const CREATE_PRODUCTS_SCHEMA = Yup.object()
  .shape({
    products: Yup.array().of(CREATE_PRODUCT_SCHEMA).required().min(1)
  })
  .required();

export const UPDATE_PRODUCT_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().nullable(),
  statement_descriptor: Yup.lazy((value) =>
    !value
      ? Yup.string().nullable()
      : Yup.string()
          .matches(/^[a-zA-Z\s0-9]+$/, "Only numbers and letters are allowed")
          .max(22, "Statement descriptor max length is 22 characters.")
  )
});

export const UPDATE_PRODUCT_FORM_SCHEMA = Yup.object().shape({
  product: UPDATE_PRODUCT_SCHEMA
});

export const ENTITY_SCHEMA = Yup.object().shape({
  uuid: Yup.string().required("UUID is required")
});

export const TEMPLATE_PAGE_BUILDER_SCHEMA = Yup.object()
  .shape({
    entities: Yup.array().of(ENTITY_SCHEMA).required().min(1),
    products: Yup.array().of(CREATE_PRODUCT_SCHEMA)
  })
  .required();

export const TEMPLATE_LINK_BUILDER_SCHEMA = Yup.object()
  .shape({
    products: Yup.array().of(CREATE_PRODUCT_SCHEMA).required().min(1)
  })
  .required();

const AUTOMATIC_TAX_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().required()
});

const TAX_ID_COLLECTION_SCHEMA = Yup.object().shape({
  enabled: Yup.boolean().required()
});

export const MERCHANT_ACCOUNT_CONFIG_TRIAL_SCHEMA = Yup.object().shape({
  trial_period_days: Yup.number().nullable(),
  return_url: LAZY_DEV_URL_NULLABLE,
  success_url: LAZY_DEV_URL_NULLABLE,
  cancel_url: LAZY_DEV_URL_NULLABLE,
  return_copy: Yup.string().nullable(),
  automatic_tax: AUTOMATIC_TAX_SCHEMA,
  tax_id_collection: TAX_ID_COLLECTION_SCHEMA
});

const PRIMARY_SCHEMA = Yup.object().shape({
  product: Yup.string().required(),
  price: Yup.string().required(),
  quantity: Yup.string().required()
});

const DISCOUNT_SCHEMA = {
  coupon: Yup.string(),
  promotion_code: Yup.string()
};

const CREATE_FLEX_PRICE_SCEHEMA = Yup.object().shape({
  recurring: Yup.object()
    .shape({
      interval: Yup.string().nullable(),
      interval_count: Yup.number().min(1),
      usage_type: Yup.string().required()
    })
    .nullable(),
  currency: Yup.string().required(),
  formattedPrice: FORMATTED_PRICE_SCHEMA
});

// TODO: Does this represent the expected shape as defined by the API
export const CREATE_SHORT_LINK_SCHEMA = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  active: Yup.boolean(),
  data: Yup.object().shape({
    application_id: Yup.string().required(),
    merchant_account_id: Yup.string().required(),
    action_uid: Yup.string().required(),
    success_url: LAZY_DEV_URL_REQUIRED,
    cancel_url: LAZY_DEV_URL_REQUIRED,
    product: Yup.object()
      .shape({
        checkout: Yup.object().shape({
          allow_promotion_codes: Yup.boolean(),
          discounts: Yup.array().of(Yup.object().shape(DISCOUNT_SCHEMA)).max(1),
          payment_intent_data: Yup.object().shape({
            capture_method: Yup.string().oneOf(
              Object.values(CAPTURE_METHOD),
              "Capture method is invalid"
            ),
            /**
             * Allow it to be null
             * Need a way to check null or onOf string types
             */
            setup_future_usage: Yup.string().nullable()
          })
        }),
        attachments: Yup.array().of(Yup.string()),
        primaries: Yup.array().of(PRIMARY_SCHEMA).nullable(),
        prices: Yup.array().of(CREATE_FLEX_PRICE_SCEHEMA).nullable()
      })
      .required(),
    campaign_id: Yup.string(),
    promotion_id: Yup.string(),
    request_url: LAZY_DEV_URL_NULLABLE,
    request_method: Yup.string(),
    request_params: Yup.object()
  })
});

export const CREATE_COMBINATION_RESULT_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    product: Yup.object()
      .shape({
        checkout: Yup.object().shape({
          allow_promotion_codes: Yup.boolean(),
          discounts: Yup.array().of(Yup.object().shape(DISCOUNT_SCHEMA)).max(1)
        }),
        attachments: Yup.array().of(Yup.string()),
        primaries: Yup.array().of(PRIMARY_SCHEMA).nullable(),
        prices: Yup.array().of(CREATE_FLEX_PRICE_SCEHEMA).nullable()
      })
      .required()
  })
});

export const CREATE_CONSTANT_RESULT_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    product: Yup.object()
      .shape({
        checkout: Yup.object().shape({
          allow_promotion_codes: Yup.boolean(),
          discounts: Yup.array().of(Yup.object().shape(DISCOUNT_SCHEMA)).max(1)
        }),
        attachments: Yup.array().of(Yup.string()),
        primaries: Yup.array().of(PRIMARY_SCHEMA).min(1),
        prices: Yup.array().of(CREATE_FLEX_PRICE_SCEHEMA).nullable()
      })
      .required()
  })
});

export const UPDATE_SHORT_LINK_SCHEMA = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  active: Yup.boolean(),
  data: Yup.object().shape({
    success_url: LAZY_DEV_URL_REQUIRED,
    cancel_url: LAZY_DEV_URL_REQUIRED,
    product: Yup.object().shape({
      attachments: Yup.array().of(Yup.string()),
      checkout: Yup.object().shape({
        customer_email: Yup.string().email("Invalid email address").nullable()
      })
    })
  })
});

export const UPDATE_EVENT_SCHEMA = Yup.object().shape({
  id: Yup.string().required(),
  uuid: Yup.string().required(),
  name: Yup.string().required(),
  data: Yup.object().shape({
    fulfillment: Yup.object().shape({
      status: Yup.string().required()
    })
  })
});

/**
 * Coupons and Promotion Codes
 * https://stripe.com/docs/api/coupons/create
 */
const PROMOTION_CODE_SCHEMA = Yup.object().shape({
  coupon: Yup.string().required(),
  code: Yup.string(),
  customer: Yup.string(),
  expires_at: Yup.number(),
  max_redemptions: Yup.number(),
  restrictions: Yup.object().shape({
    first_time_transaction: Yup.boolean(),
    minimum_amount: Yup.string(),
    minimum_amount_currency: Yup.string()
  })
});

export const CREATE_COUPON_SCHEMA = Yup.object().shape({
  name: Yup.string().required().max(40, "Name max length is 40 characters."),
  percent_off: Yup.string(),
  currency: Yup.string(),
  amount_off: Yup.string(),
  duration: Yup.string(),
  duration_in_months: Yup.number(),
  applies_to: Yup.object().shape({
    products: Yup.array().of(Yup.string())
  }),
  max_redemptions: Yup.number(),
  redeem_by: Yup.date(),
  promotion_codes: Yup.array().of(PROMOTION_CODE_SCHEMA)
});

/**
 * Note Stripe API only permits edit of name and metadata
 * So we disable update of these fields when in edit mode
 * https://stripe.com/docs/api/coupons/update?lang=node
 */
export const UPDATE_COUPON_SCHEMA = Yup.object().shape({
  name: Yup.string().required(),
  metadata: METADATA_SCHEMA
});

export const CREATE_COUPONS_SCHEMA = Yup.object()
  .shape({
    coupons: Yup.array().of(CREATE_COUPON_SCHEMA).required().min(1)
  })
  .required();

export const SHOPIFY_CONNECT_SCHEMA = Yup.object().shape({
  shop: Yup.string().required("Shop name is required.")
});

export const REWARDFUL_SCHEMA = Yup.object().shape({
  token: Yup.string().required("API Key is required.")
});

const SHOPIFY_METAFIELDS = Yup.object().shape({
  key: Yup.string().required(),
  value: Yup.string().required(),
  value_type: Yup.string().required(),
  namespace: Yup.string().required()
});

export const EXPORT_PRODUCT_TO_SHOPIFY_SCHEMA = Yup.object().shape({
  product: Yup.object().shape({
    title: Yup.string().required(),
    status: Yup.string().required(),
    variants: Yup.array().of(
      Yup.object().shape({
        price: Yup.string().required(),
        sku: Yup.string().required(),
        title: Yup.string().required(),
        option1: Yup.string().required(),
        metafields: Yup.array().of(SHOPIFY_METAFIELDS)
      })
    ),
    images: Yup.array().of(
      Yup.object().shape({
        src: Yup.string().required()
      })
    )
  }),
  merchantAccount: Yup.object().shape({
    uuid: Yup.string().required(),
    model_uid: Yup.string().required(),
    model: Yup.string().required(),
    service: Yup.string().required()
  })
});

export const SQUARESPACE_CONNECT_SCHEMA = Yup.object().shape({
  shop: Yup.string().required("Shop name is required."),
  key: Yup.string()
    .trim()
    .matches(UUID_PATTERN, "Key is not in the right format")
    .required()
});

export const EXPORT_PRODUCT_TO_SQUARESPACE_SCHEMA = Yup.object().shape({
  product: Yup.object().shape({
    type: Yup.string().required(),
    name: Yup.string().required(),
    storePageId: Yup.string().required(),
    variants: Yup.array().of(
      Yup.object().shape({
        sku: Yup.string().required(),
        pricing: Yup.object().shape({
          basePrice: Yup.object().shape({
            currency: Yup.string().required(),
            value: Yup.string().required()
          })
        })
      })
    ),
    description: Yup.string()
  }),
  merchantAccount: Yup.object().shape({
    uuid: Yup.string().required(),
    model_uid: Yup.string().required(),
    model: Yup.string().required(),
    service: Yup.string().required()
  })
});

export const CREATE_TAX_RATE_SCHEMA = Yup.object().shape({
  display_name: Yup.string().required(),
  inclusive: Yup.bool().required(),
  percentage: Yup.string().required(),
  active: Yup.bool(),
  // Single
  country: Yup.string().nullable(),
  description: Yup.string().nullable(),
  jurisdiction: Yup.string().nullable(),
  metadata: METADATA_SCHEMA,
  state: Yup.string().nullable()
});

/**
 * Note Stripe API only permits edit of name and metadata
 * So we disable update of these fields when in edit mode
 * https://stripe.com/docs/api/coupons/update?lang=node
 */
export const UPDATE_TAX_RATE_SCHEMA = Yup.object().shape({
  active: Yup.bool(),
  // Single
  description: Yup.string().nullable(),
  jurisdiction: Yup.string().nullable(),
  metadata: METADATA_SCHEMA,
  state: Yup.string().nullable()
});

export const CREATE_TAX_RATES_SCHEMA = Yup.object()
  .shape({
    taxRates: Yup.array().of(CREATE_TAX_RATE_SCHEMA).required().min(1)
  })
  .required();

export const CREATE_INTEGRATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(),
  // Add more key validations
  raw: Yup.object().required()
});

export const UPDATE_INTEGRATION_SCHEMA = Yup.object().shape({
  name: Yup.string(),
  raw: Yup.object()
});

export const CREATE_INTEGRATIONS_SCHEMA = Yup.object()
  .shape({
    integrations: Yup.array().of(CREATE_INTEGRATION_SCHEMA).required().min(1)
  })
  .required();

const SHIPPING_RATE_DELIVERY_ESTIMATE_LIMIT = Yup.object().shape({
  unit: Yup.string(),
  value: Yup.string()
});

const SHIPPING_RATE_DELIVERY_ESTIMATE = Yup.object().shape({
  maximum: SHIPPING_RATE_DELIVERY_ESTIMATE_LIMIT,
  minimum: SHIPPING_RATE_DELIVERY_ESTIMATE_LIMIT
});

export const CREATE_SHIPPING_RATE_SCHEMA = Yup.object().shape({
  display_name: Yup.string().required("Display name is required"),
  type: Yup.string().required(),
  active: Yup.bool().required(),
  delivery_estimate: SHIPPING_RATE_DELIVERY_ESTIMATE,
  fixed_amount: Yup.object().shape({
    formattedPrice: Yup.string(),
    currency: Yup.string()
  }),
  tax_behavior: Yup.string(),
  tax_code: Yup.string(),
  metadata: METADATA_SCHEMA
});

/**
 * https://stripe.com/docs/api/shipping-rates/update?lang=node
 */
export const UPDATE_SHIPPING_RATE_SCHEMA = Yup.object().shape({
  active: Yup.bool(),
  metadata: METADATA_SCHEMA
});

export const CREATE_SHIPPING_RATES_SCHEMA = Yup.object()
  .shape({
    shippingRates: Yup.array().of(CREATE_SHIPPING_RATE_SCHEMA).required().min(1)
  })
  .required();

const ATTACHMENT_DATA_SCHEMA = Yup.object().shape({
  active: Yup.bool(),
  filename: Yup.string(),
  handle: Yup.string(),
  mimetype: Yup.string(),
  originalPath: Yup.string(),
  size: Yup.number(),
  source: Yup.string(),
  url: URL_TYPE,
  uploadId: Yup.string(),
  originalFile: Yup.object().shape({
    name: Yup.string(),
    type: Yup.string(),
    size: Yup.number()
  }),
  status: Yup.string()
});

export const UPDATE_ATTACHMENT_SCHEMA = Yup.object().shape({
  data: ATTACHMENT_DATA_SCHEMA
});

export const CREATE_ATTACHMENT_SCHEMA = Yup.object().shape({
  uuid: Yup.string().required(),
  service: Yup.string().required(),
  data: ATTACHMENT_DATA_SCHEMA
});

export const CREATE_ATTACHMENTS_SCHEMA = Yup.object()
  .shape({
    attachments: Yup.array().of(CREATE_ATTACHMENT_SCHEMA).required().min(1)
  })
  .required();

export const UPDATE_CAMPAIGN_SCHEMA = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string(),
  active: Yup.boolean(),
  data: Yup.object().shape({
    product: Yup.object({
      attachments: Yup.array().of(Yup.string())
    }),
    audience: Yup.object({
      datasources: Yup.array().of(Yup.string())
    })
  })
});

export const CREATE_CAMPAIGN_SCHEMA = Yup.object().shape({
  uuid: Yup.string().required(),
  title: Yup.string().required(),
  description: Yup.string(),
  status: Yup.string(),
  data: Yup.object().shape({
    application_id: Yup.string().required(),
    merchant_account_id: Yup.string().required(),
    product: Yup.object({
      checkout: Yup.object().shape({
        allow_promotion_codes: Yup.boolean(),
        discounts: Yup.array().of(Yup.object().shape(DISCOUNT_SCHEMA)).max(1)
      }),
      attachments: Yup.array().of(Yup.string())
    }),
    audience: Yup.object({
      datasources: Yup.array().of(Yup.string())
    })
  })
});

export const CREATE_CAMPAIGNS_SCHEMA = Yup.object()
  .shape({
    campaigns: Yup.array().of(CREATE_CAMPAIGN_SCHEMA).required().min(1)
  })
  .required();

export const UPDATE_DATASOURCE_SCHEMA = Yup.object().shape({
  title: Yup.string(),
  source: Yup.string(),
  description: Yup.string().nullable(),
  url: Yup.string().url("URL must be valid").nullable(),
  uid: Yup.string(),
  attachment: Yup.object().when("url", {
    is: (val) => isEmpty(val),
    then: CREATE_ATTACHMENT_SCHEMA,
    otherwise: Yup.object()
  })
});

export const CREATE_DATASOURCE_SCHEMA = Yup.object().shape({
  uuid: Yup.string().required(),
  title: Yup.string().required(),
  source: Yup.string(),
  description: Yup.string(),
  uid: Yup.string(),
  url: Yup.string().url("URL must be valid").nullable(),
  attachment: Yup.object().when("url", {
    is: (val) => isEmpty(val),
    then: CREATE_ATTACHMENT_SCHEMA,
    otherwise: Yup.object()
  })
});

export const CREATE_DATASOURCES_SCHEMA = Yup.object()
  .shape({
    datasources: Yup.array().of(CREATE_DATASOURCE_SCHEMA).required().min(1)
  })
  .required();

export const UPDATE_AUDIENCE_SCHEMA = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string(),
  data: Yup.object().shape({
    contacts: Yup.array()
  })
});

const CONTACT_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  amount: Yup.string().required("Amount is required"),
  currency: Yup.string().required("Currency is required"),
  interval: Yup.string().required("Interval is required")
});

export const CREATE_AUDIENCE_SCHEMA = Yup.object().shape({
  uuid: Yup.string().required(),
  title: Yup.string().required(),
  description: Yup.string(),
  data: Yup.object().shape({
    contacts: Yup.array().of(CONTACT_SCHEMA)
  })
});

export const CREATE_AUDIENCES_SCHEMA = Yup.object()
  .shape({
    audiences: Yup.array().of(CREATE_AUDIENCE_SCHEMA).required().min(1)
  })
  .required();

export const APPLICATION_UPDATE_SCHEMA = Yup.object().shape({
  alias: Yup.string()
    .matches(ALIAS_PATTERN, ALIAS_VALIDATION_MESSAGE)
    .min(3, "Brand name must be at least 3 characters long."),
  fallback: Yup.string().nullable()
});

export const SETUP_PAYMENT_SCHEMA = Yup.object().shape({
  customer_email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
});

const LEGAL_CONTENT_DATA_SCHEMA = Yup.object().shape({
  version: Yup.object()
    .shape({
      main: Yup.string().nullable(),
      history: Yup.object()
    })
    .nullable()
});

export const UPDATE_LEGAL_CONTENT_SCHEMA = Yup.object().shape({
  data: LEGAL_CONTENT_DATA_SCHEMA
});

export const CREATE_LEGAL_CONTENT_SCHEMA = Yup.object().shape({
  uuid: Yup.string().required(),
  title: Yup.string().required(),
  description: Yup.string(),
  type: Yup.string().required(),
  data: LEGAL_CONTENT_DATA_SCHEMA
});

export const CREATE_LEGAL_CONTENTS_SCHEMA = Yup.object()
  .shape({
    legalContents: Yup.array().of(CREATE_LEGAL_CONTENT_SCHEMA).required().min(1)
  })
  .required();

const CREATE_WORKFLOW_STEP_SCEHEMA = Yup.object().shape({
  uuid: Yup.string().required(),
  integrationId: Yup.string().required(),
  service: Yup.string().required(),
  action: Yup.string().required(),
  triggers: Yup.object().required(),
  config: Yup.object().required()
});

export const WORKFLOW_SCHEMA = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  data: Yup.object().shape({
    steps: Yup.array().of(CREATE_WORKFLOW_STEP_SCEHEMA)
  })
});

const COMMON = "common";
const CONSTANT = "constant";
const COMBINATIONS = "combinations";
export const VALIDATION_CHECKOUT_KEY = {
  COMMON,
  CONSTANT,
  COMBINATIONS
};

export const VALIDATION_CHECKOUT_KEY_MAP = {
  [COMMON]: COMMON,
  [CONSTANT]: CONSTANT,
  [COMBINATIONS]: COMBINATIONS
};

export const VALIDATION_CHECKOUT_KEY_TITLE_MAP = {
  [COMMON]: "Checkout common",
  [CONSTANT]: "Checkout default",
  [COMBINATIONS]: "Checkout Combinations"
};
