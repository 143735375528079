import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { CAMPAIGN_CONFIG_PRIORITY } from "utils/constants/campaign";
import { FIRST_PHASE_ITERATIONS_PATH, STATE_KEYS } from "utils/constants/state";
import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import { COUPON_LIMIT } from "utils/constants/coupon";
import { STRIPE_CHECKOUT_MERGE_PARAMS } from "utils/constants/stripe";
import { SHIPPING_OPTION_LIMIT } from "utils/constants/shippingRate";
import { notNothing } from "utils/data";

const SUPPORTED_COUNTRY_CODES = [];

const getHasCheckoutDiscount = (values) =>
  !isEmpty(get(values, "checkout.discounts[0]"));

const MERGE_PATHS = {
  CHECKOUT: {
    DISCOUNTS: "checkout.discounts",
    TRIAL_PERIOD_DAYS: "checkout.trial_period_days",
    TAX_RATES: "checkout.tax_rates",
    AUTOMATIC_TAX: "checkout.automatic_tax",
    AUTOMATIC_TAX_ENABLED: "checkout.automatic_tax.enabled",
    TRIAL_END: "checkout.trial_end",
    METADATA: "checkout.metadata",
    PAYMENT_METHOD_TYPES: "checkout.payment_method_types",
    ALLOW_PROMOTION_CODES: "checkout.allow_promotion_codes",
    SHIPPING_OPTIONS: "checkout.shipping_options",
    SHIPPING_WORLDWIDE: "checkout.shipping_worldwide",
    SHIPPING_ADDRESS_COLLECTION_ALLOWED_COUNTRIES:
      "checkout.shipping_address_collection.allowed_countries"
  }
};

// Alias mergeCheckouts
export const mergeCheckoutConfigs = (configs = []) => {
  if (configs.length === 1) {
    return configs[0];
  }

  const initialCheckout = cloneDeep(configs[0]);

  const mergedCheckouts = configs.slice(1).reduce((memo, data) => {
    /**
     * Primaries
     * - concatenate primaries
     * - default / constant checkout can exist with form selection checkout
     */
    if (Array.isArray(data.primaries)) {
      const primariesBase = Array.isArray(memo.primaries) ? memo.primaries : [];
      memo.primaries = uniqBy(primariesBase.concat(data.primaries), "price");
    }

    /**
     * Subscription schedule
     */
    if (data.subscription_schedule) {
      memo.subscription_schedule = data.subscription_schedule;
    }

    /**
     * Attachments
     * - concatenate attachments
     */
    if (Array.isArray(data.attachments)) {
      const attachmentsBase = Array.isArray(data.attachments)
        ? data.attachments
        : [];
      memo.attachments = uniqBy(attachmentsBase.concat(data.attachments), "id");
    }

    /**
     * Shipping
     * - allowed_countries
     * - shipping_options
     */
    const shippingWorldwide = Boolean(
      get(data, MERGE_PATHS.CHECKOUT.SHIPPING_WORLDWIDE)
    );
    if (!memo.checkout.shipping_worldwide && shippingWorldwide) {
      memo.checkout.shipping_worldwide = shippingWorldwide;
    }

    if (shippingWorldwide) {
      set(
        memo,
        MERGE_PATHS.CHECKOUT.SHIPPING_ADDRESS_COLLECTION_ALLOWED_COUNTRIES,
        SUPPORTED_COUNTRY_CODES
      );
    } else {
      const checkoutAllowedCountries = get(
        data,
        MERGE_PATHS.CHECKOUT.SHIPPING_ADDRESS_COLLECTION_ALLOWED_COUNTRIES
      );
      if (Array.isArray(checkoutAllowedCountries)) {
        set(
          memo,
          MERGE_PATHS.CHECKOUT.SHIPPING_ADDRESS_COLLECTION_ALLOWED_COUNTRIES,
          checkoutAllowedCountries
        );
      }
    }

    /**
     * Shipping rate options
     */
    const coShippingOptions = get(data, MERGE_PATHS.CHECKOUT.SHIPPING_OPTIONS);
    if (Array.isArray(coShippingOptions)) {
      set(
        memo,
        MERGE_PATHS.CHECKOUT.SHIPPING_OPTIONS,
        coShippingOptions.slice(0, SHIPPING_OPTION_LIMIT)
      );
    }

    /**
     * Discounts
     * - max allowed is 1
     * - last one wins
     */
    const coDiscounts = get(data, MERGE_PATHS.CHECKOUT.DISCOUNTS);
    const coAllowPromoCodes = get(
      data,
      MERGE_PATHS.CHECKOUT.ALLOW_PROMOTION_CODES
    );
    if (coAllowPromoCodes && typeof coAllowPromoCodes === "boolean") {
      set(memo, MERGE_PATHS.CHECKOUT.ALLOW_PROMOTION_CODES, true);
      set(memo, MERGE_PATHS.CHECKOUT.DISCOUNTS, null);
    } else if (Array.isArray(coDiscounts)) {
      const updatedDiscounts = coDiscounts.slice(0, COUPON_LIMIT);
      set(memo, MERGE_PATHS.CHECKOUT.ALLOW_PROMOTION_CODES, null);
      set(memo, MERGE_PATHS.CHECKOUT.DISCOUNTS, updatedDiscounts);
    }

    /**
     * Taxes
     */
    const coAutoTaxes = get(data, MERGE_PATHS.CHECKOUT.AUTOMATIC_TAX_ENABLED);
    const coTaxRates = get(data, MERGE_PATHS.CHECKOUT.TAX_RATES);
    const autoTaxesEnabled = coAutoTaxes && typeof coAutoTaxes === "boolean";
    if (autoTaxesEnabled) {
      set(memo, MERGE_PATHS.CHECKOUT.AUTOMATIC_TAX_ENABLED, true);
      set(memo, MERGE_PATHS.CHECKOUT.TAX_RATES, null);
    } else if (Array.isArray(coTaxRates)) {
      set(memo, MERGE_PATHS.CHECKOUT.AUTOMATIC_TAX, null);
      set(memo, MERGE_PATHS.CHECKOUT.TAX_RATES, coTaxRates);
    }

    /**
     * Trial
     */
    const result = {};
    const checkoutTrialPeriodDays = get(
      data,
      MERGE_PATHS.CHECKOUT.TRIAL_PERIOD_DAYS
    );
    // Allow any number (incl. 0) to be used as an override
    const useCheckout = Number.isInteger(checkoutTrialPeriodDays);
    if (useCheckout) {
      set(
        memo,
        MERGE_PATHS.CHECKOUT.TRIAL_PERIOD_DAYS,
        checkoutTrialPeriodDays
      );
      result.trial_period_days = checkoutTrialPeriodDays;
      const checkoutTrialEnd = get(data, MERGE_PATHS.CHECKOUT.TRIAL_END);
      if (checkoutTrialEnd) {
        result.trial_end = checkoutTrialEnd;
      }
    }

    /**
     * Payment method types
     */
    const paymentMethodTypes = get(
      data,
      MERGE_PATHS.CHECKOUT.PAYMENT_METHOD_TYPES
    );
    if (Array.isArray(paymentMethodTypes)) {
      set(memo, MERGE_PATHS.CHECKOUT.PAYMENT_METHOD_TYPES, paymentMethodTypes);
    }

    /**
     * Metadata
     * - if present then merge it
     */
    const metadata = get(data, MERGE_PATHS.CHECKOUT.METADATA);
    if (!isEmpty(metadata)) {
      const currentMetadata = get(memo, MERGE_PATHS.CHECKOUT.METADATA) || {};
      set(memo, MERGE_PATHS.CHECKOUT.METADATA, {
        ...currentMetadata,
        ...metadata
      });
    }

    /**
     * Set other checkout params
     */
    STRIPE_CHECKOUT_MERGE_PARAMS.forEach(({ key, rename }) => {
      const customerVal = get(data, `checkout.${key}`);

      if (notNothing(customerVal)) {
        const setPath = `checkout.${rename || key}`;
        set(memo, setPath, customerVal);
      }
    });

    return memo;
  }, initialCheckout);

  if (
    mergedCheckouts.subscription_schedule &&
    !get(mergedCheckouts.subscription_schedule, FIRST_PHASE_ITERATIONS_PATH)
  ) {
    delete mergedCheckouts.subscription_schedule;
  }

  return omitAndSanitizeParams(mergedCheckouts);
};

/**
 * Checkout configs are ordered least to most specific
 * - LIO - last in overrides the previous one
 * - The client pickers will control which configuration values can be based on context
 * - i.e. no price pickers in entity common config
 * - Some checkout objects are expected to only have certain fields
 * -- e.g. campaign checkout only has discounts currently
 * @param {Object} params
 * @returns
 */
export const composeCheckoutConfigs = ({
  constantCheckout,
  commonConfig,
  selectedCheckout,
  connect,
  checkoutSession,
  campaignCheckout
}) => {
  /**
   * 1. Common product collection entity checkout config
   */
  const allCheckouts = [];
  if (!isEmpty(commonConfig)) {
    allCheckouts.push(commonConfig.product);
  }

  /**
   * 2. Constant / default checkout values
   */
  if (!isEmpty(constantCheckout)) {
    if (!constantCheckout.checkout) {
      throw new Error("Constant checkout invalid shape");
    }
    allCheckouts.push(constantCheckout);
  }

  /**
   * 3. Selected checkout
   */
  if (!isEmpty(selectedCheckout)) {
    if (!selectedCheckout.checkout) {
      throw new Error("Selected checkout invalid shape");
    }
    allCheckouts.push(selectedCheckout);
  }

  /**
   * 4. Checkout values derived from connect config
   * - can have derived Stripe customer params like customer | customer_email
   */
  const connectCheckout = get(
    connect,
    STATE_KEYS.CONNECT.STRIPE_CHECKOUT_VALUES
  );
  if (!isEmpty(connectCheckout)) {
    allCheckouts.push({ checkout: connectCheckout });
  }

  let hasCheckoutDiscount = false;
  for (let chkIx = 0; chkIx < allCheckouts.length; chkIx++) {
    const config = allCheckouts[chkIx];
    if (getHasCheckoutDiscount(config)) {
      hasCheckoutDiscount = true;
      break;
    }
  }

  if (!isEmpty(checkoutSession)) {
    allCheckouts.push({ checkout: checkoutSession });
  }

  /**
   * 5. Campaign checkout
   * Append campaign checkout when
   * - !discounts && campaign has discount then push
   * - discounts && campaign has discount and campaign is piority
   * - campaign has a priority discount with priority
   * Then
   * - push campaign.values into allCheckouts so it overrides
   * {
   *    priority: 'secondary',
   *    value: null,
   *    index: 0
   *  }
   */
  if (campaignCheckout) {
    const campaignHasDiscount = getHasCheckoutDiscount(campaignCheckout.value);
    const meetsMin = Boolean(!hasCheckoutDiscount && campaignHasDiscount);

    const canOverride = Boolean(
      hasCheckoutDiscount &&
        campaignCheckout.priority === CAMPAIGN_CONFIG_PRIORITY.PRIMARY &&
        campaignHasDiscount
    );
    const shouldAddCampaign = meetsMin || canOverride;
    if (shouldAddCampaign) {
      allCheckouts.push(campaignCheckout.value);
    }
  }
  const checkoutsCount = allCheckouts.length;

  return checkoutsCount > 1
    ? mergeCheckoutConfigs(allCheckouts)
    : checkoutsCount === 1
    ? allCheckouts[0]
    : null;
};

export const composedCheckoutToShortLink = (composedCheckout) => ({
  data: {
    product: composedCheckout
  }
});

export const composeCheckoutConfigsToShortLink = (props) =>
  composedCheckoutToShortLink(composeCheckoutConfigs(cloneDeep(props)));
