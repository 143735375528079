import C from "constants/userFeatureGroup";
import ActionHelpers from "./helpers";
// import axios from "axios";
// import { batchActions } from "redux-batched-actions";
// import { authBearerToken } from "utils/auth";

export function setLoading() {
  return {
    type: C.UPDATE_USER_FEATURE_GROUP_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserFeatureGroups = (payload) => {
  return {
    type: C.SET_USER_FEATURE_GROUPS,
    payload
  };
};

export const replaceUserFeatureGroups = (payload) => {
  return {
    type: C.REPLACE_USER_FEATURE_GROUPS,
    payload
  };
};

/**
 * resetUserFeatureNotes
 */
export function resetUserFeatureNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_USER_FEATURE_GROUP_STORE,
      payload
    });
  };
}

export function showUserFeatureNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_USER_FEATURE_GROUP_STORE,
        message || `Success!`
      )
    );
  };
}
