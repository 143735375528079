import reduce from "lodash/reduce";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import set from "lodash/set";
import compact from "lodash/compact";
import omit from "lodash/omit";
import {
  schemaPropertiesToValues,
  fillSchemaObjectsWithData
} from "components/FormerEditor/common";
import { extractNewVariables } from "components/FormerEditor/common/template";
import { checkPinnedEntities } from "utils/entity";
import { reorder } from "utils/drag";
import { prepareCreateProducts } from "utils/product";
import {
  CONFIG_CHECKOUT_COMMON,
  CONTENT_DATA,
  ENTITY_DATA,
  STATE_KEYS
} from "utils/constants/state";
import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import Analytics from "utils/analytics";
import { formatTime, orderByDate } from "./date";
import { getManifestLiveVariationParams } from "./application";
import {
  ensureLeadingSlash,
  getTestTrackingQS,
  interpolateRoute
} from "./route";
import PATHS, { API_PATHS, PLATFORM_PAGE_ROUTES } from "./paths";
import { SECTIONS } from "components/FormerEditor/utils";
import { getUUID } from "utils/uuid";
import { getManifestLiveVariation } from "./mapStateToProps";
import {
  getProtocol,
  getSubdomainRoot,
  getWebClientRoot,
  isPlatformSubdomain
} from "./subdomain";
import QS from "qs";
import { ROUTES } from "./constants/application";
import ENTITIES from "components/FormerEditor/common/constants/entity";
import {
  MANIFEST_CONTEXT_TYPE_ENTITIES,
  MANIFEST_STATUS
} from "./constants/manifest";
import cloneDeep from "lodash/cloneDeep";
import { REFERENCE_KEYS, STRIPE_REFERENCES_STORE } from "./constants/stripe";
import { reduceShortLinkReferences } from "./shortLink";
import { MODEL_MIN_KEYS } from "components/FormerEditor/common/constants";
import { ORGANIZATION } from "./constants/models";
import { getConfig } from "./env";
import {
  setDefaultFormFieldValues,
  getFieldPath,
  asyncValidateFieldFactory,
  getFieldNameRoot,
  getFieldNameIndex,
  mergeCombinations
} from "./form";
import { getManifestFormFields, getFormEntity } from "./hooks/manifest";
import { scaffoldCombinations, validateCombinations } from "./combination";
import { EMBED_LAYOUT } from "./constants/embed";
import { without } from "lodash";
import axios from "axios";
import { METHODS } from "./constants/auth";

const PRODUCT_ENTITIES = [ENTITIES.PRODUCT_COLLECTION];
const DATA_KEY = "data";

export const createManifestParams = ({ uuid, title, description }) => {
  return {
    uuid,
    title,
    description
  };
};

export const createManifestFromTemplateParams = ({ title, entities }) => {
  const newEntities = entities.map(({ component, uid }) => ({
    component,
    uid,
    uuid: getUUID()
  }));

  return {
    uuid: getUUID(),
    title,
    data: {
      entities: newEntities,
      layout: newEntities.map(({ uuid }) => ({ uuid }))
    }
  };
};

export const createManifestFromTemplateBuilderParams = ({
  copyManifestUUID,
  context,
  title,
  entities,
  products,
  primaries,
  features,
  featureGroups,
  merchantAccountUUID
}) => {
  const newEntities = entities.map(({ component, uid }) => ({
    component,
    uid,
    uuid: getUUID()
  }));

  const result = {
    manifest: {
      uuid: getUUID(),
      title,
      context,
      data: {
        entities: newEntities,
        layout: newEntities.map(({ uuid }) => ({ uuid }))
      }
    },
    merchantAccountUUID,
    primaries,
    /**
     * User will select either
     * - existing
     * - template
     * - new products
     * Whatever their selection - we run them through the same prepare function
     * - the API will then separate new vs existing for creation
     */
    ...prepareCreateProducts(products),
    features,
    featureGroups
  };

  if (copyManifestUUID) {
    result.copyManifestUUID = copyManifestUUID;
  }

  return result;
};

/**
 * Avoid primaries bloat for products by scoping primaries only to the products within the pricing layout config
 * @param {Object} config
 */
export const sanitizeConfig = (config) => {
  if (!config.pricing) {
    return config;
  }
  const layout = get(config, "pricing.layout") || [];
  const productsInLayout = layout.map(({ uid }) => uid);
  const primaries = get(config, "pricing.primaries") || [];
  const filteredPrimaries = primaries.filter(
    (primary) => productsInLayout.indexOf(primary.product) > -1
  );

  return {
    ...config,
    pricing: {
      ...config.pricing,
      primaries: filteredPrimaries
    }
  };
};

export const updateManifestParams = ({
  manifest: { uuid, title, description, data },
  theme,
  variables,
  appEntities = []
}) => {
  const result = {
    manifest: {},
    variables: [],
    theme: omitAndSanitizeParams(theme)
  };
  if (uuid) {
    result.manifest.uuid = uuid;
  }
  if (title) {
    result.manifest.title = title;
  }
  if (description) {
    result.manifest.description = description;
  }

  if (data) {
    const { config, entities, content, layout } = data;

    result.manifest.data = {
      config: sanitizeConfig(config),
      layout,
      /**
       * Ensure we exclude the certain keys like schema which is defined by the back end
       */
      entities: entities.map(({ uid, component, version, uuid }) => ({
        uid,
        component,
        version: version || "0.0.1",
        uuid
      })),
      content: reduce(
        content,
        (memo, entityContent, entityUUID) => {
          memo[entityUUID] = reduce(
            entityContent,
            (innerMemo, innerVal, innerKey) => {
              let value;
              const entityMatch = entities.find(
                ({ uuid }) => uuid === entityUUID
              );
              const isProductCollection =
                entityMatch && entityMatch.uid === ENTITIES.PRODUCT_COLLECTION;
              const isForm = entityMatch && entityMatch.uid === ENTITIES.FORM;

              /**
               * Reset cart to empty so checkout pages don't start with a prefilled cart
               */
              if (innerKey === "config" && entityMatch && isProductCollection) {
                const updatedVal = cloneDeep(innerVal);
                set(updatedVal, "cart.products", []);
                value = updatedVal;
              } else if (innerKey === DATA_KEY) {
                if (entityMatch) {
                  if (isForm) {
                    value = setDefaultFormFieldValues({
                      values: innerVal,
                      entities: appEntities
                    });
                  } else {
                    const schemaFields = schemaPropertiesToValues(
                      entityMatch.schema.properties
                    );
                    const filled = fillSchemaObjectsWithData(
                      schemaFields,
                      innerVal,
                      variables
                    );

                    value = filled;
                  }
                }
              } else {
                value = innerVal;
              }

              if (value) {
                const extract = extractNewVariables(value, variables);
                if (extract && extract.length) {
                  result.variables = result.variables.concat(extract);
                }
                innerMemo[innerKey] = value;
              }

              return innerMemo;
            },
            {}
          );

          return memo;
        },
        {}
      )
    };
  }

  return result;
};

/**
 * Reorder the component blocks within the manifest
 * @param {Object} params
 * @param {Array} params.entities - collection of entities
 * @param {Array} params.layout - user defined layout of entities
 * @param {Function} params.setFieldValue - formik set value function
 * @param {Function} params.setFieldTouched - formik set field touched function
 */
export const reorderManifestLayout = ({
  entities,
  layout,
  setFieldValue,
  setFieldTouched,
  tracking
}) => ({ source, destination }) => {
  const { firstPinned, lastPinned } = checkPinnedEntities({
    entities
  });
  const droppedOffList = !destination;
  if (droppedOffList) {
    return;
  }
  tracking && Analytics.trackEventWithData(tracking);

  let targetIndex = destination.index;
  const targetingFirst = targetIndex === 0;
  const targetingLast = targetIndex === entities.length - 1;
  if (targetingFirst && firstPinned) {
    targetIndex += 1;
  } else if (targetingLast && lastPinned) {
    targetIndex -= 1;
  }

  const canReorder = source.index !== targetIndex;

  if (canReorder) {
    const updatedLayout = reorder(layout, source.index, targetIndex);

    setFieldValue(STATE_KEYS.MANIFEST.LAYOUT, updatedLayout);
    setFieldTouched(STATE_KEYS.MANIFEST.LAYOUT, true);
  }
};

/**
 * An application has a set of routes
 */
const prepareManfestShow = ({ application, manifest }) => {
  const live = getManifestLiveVariationParams({
    id: manifest.id,
    application
  });

  const showPath = interpolateRoute(PATHS.APPLICATIONS_PAGES_SHOW_SECTION, {
    application,
    page: {
      uuid: manifest.uuid
    },
    section: {
      uuid: SECTIONS.PAGE_BUILDER
    }
  });

  return {
    id: manifest.id,
    uuid: manifest.uuid,
    title: manifest.title,
    description: manifest.description,
    context: manifest.context,
    status: !isEmpty(live) ? MANIFEST_STATUS.LIVE : MANIFEST_STATUS.OFFLINE,
    previewUrl: get(live, "url", ""),
    showPath,
    ownerEmail: get(manifest, "owner.email", ""),
    createdAt: formatTime(manifest.created_at),
    ...live
  };
};

export const prepareManifestList = ({
  application,
  manifests,
  shouldOrder
}) => {
  const ctxModels = shouldOrder ? orderByDate(manifests) : manifests;
  return ctxModels.map((manifest) => {
    return prepareManfestShow({ application, manifest });
  });
};

export const getManifestCheckoutUrlVariations = ({
  subdomain,
  application,
  route,
  manifest
}) => {
  const result = {
    subdomain: {
      original: "",
      test: ""
    },
    alias: {
      original: "",
      test: ""
    }
  };

  if (!route) {
    console.error("Payment link short id is required");
    return result;
  }
  if (!subdomain) {
    console.error("Application subdomain is required");
    return result;
  }

  const shortRoot = getConfig("WEB_CLIENT_ROOT");
  const subdomainRoot = getSubdomainRoot(subdomain);

  const pathname = ensureLeadingSlash(route);
  const trackingInput = {};
  if (application && application.uuid) {
    trackingInput.application = application.uuid;
  }
  if (manifest && manifest.uuid) {
    trackingInput.page = manifest.uuid;
  }

  const testQSParams = getTestTrackingQS(trackingInput);

  const aliasFragment = ensureLeadingSlash(application.alias);

  const aliasOriginal = `${shortRoot}${aliasFragment}${pathname}`;
  const subdomainOriginal = `${subdomainRoot}${pathname}`;

  result.subdomain.original = subdomainOriginal;
  result.subdomain.test = `${subdomainOriginal}${testQSParams}`;
  result.alias.original = aliasOriginal;
  result.alias.test = `${aliasOriginal}${testQSParams}`;

  return result;
};

/**
 * Pick the right type of manifest url based on the context
 * Platform - need to use the app alias in the url because dynamic path fragments will fail with platform subdomain
 * Org - can use the subdomain
 * User - needs to use the alias
 */
export const getManifestLiveUrl = ({
  manifest,
  application,
  resource,
  env = process.env.NODE_ENV
}) => {
  const liveVariation = getManifestLiveVariation({
    id: manifest.id,
    application
  });
  let liveUrl = "";

  if (liveVariation && liveVariation.route) {
    const qsParams = {
      application: application.uuid,
      page: manifest.uuid
    };
    const isPlatform = isPlatformSubdomain(
      application && application.subdomain
    );
    const isOrg = resource === ORGANIZATION;
    const protocol = getProtocol(env);
    const webClientRoot = getWebClientRoot();
    const root = getSubdomainRoot(application.subdomain, env);

    let parts = [];
    if (isPlatform || !isOrg) {
      parts = [
        protocol,
        webClientRoot,
        ensureLeadingSlash(application.alias),
        liveVariation.route
      ];
    } else {
      parts = [root, liveVariation.route];
    }
    const urlRoot = parts.join("");

    liveUrl = `${urlRoot}${QS.stringify(qsParams, {
      addQueryPrefix: true
    })}`;
  }
  return liveUrl;
};

export const getManifestRouteForEntities = (manifest) => {
  let result = ROUTES.PRICING;
  const entities = get(manifest, ENTITY_DATA, []);
  for (let entIx = 0; entIx < entities.length; entIx++) {
    const entity = entities[entIx];
    if (entity.uid === ENTITIES.PAYMENT) {
      result = ROUTES.TIP;
    }
  }
  return result;
};

export const reduceManifestEntitiesImages = (entities = [], manifest) =>
  entities.reduce((memo, { uuid, uid }) => {
    if (uid === ENTITIES.SOCIAL_PROFILE) {
      const fieldKey = "image";
      const profileImage = get(
        manifest,
        `data.content.${uuid}.data.${fieldKey}.src`
      );

      if (profileImage) {
        memo.push(profileImage);
      }
    }

    return memo;
  }, []);

export const getManifestAssociations = ({ manifest, products }) => {
  const productLayout = get(
    { manifest },
    STATE_KEYS.MANIFEST.PRICING_LAYOUT,
    []
  );

  const entities = get({ manifest }, STATE_KEYS.MANIFEST.ENTITIES, []);

  const entityImages = reduceManifestEntitiesImages(entities, manifest);

  const result = productLayout.reduce(
    (memo, { uid }) => {
      const productMatch = products.find(({ id }) => uid === id);
      if (productMatch) {
        memo.products.push(productMatch);
        if (productMatch.images) {
          memo.images = memo.images.concat(productMatch.images);
        }
      }
      return memo;
    },
    {
      images: cloneDeep(entityImages),
      products: []
    }
  );

  return result;
};

export const reducePageShortLinkIds = (pageValues) => {
  const content = get(pageValues, STATE_KEYS.MANIFEST.CONTENT, {});

  return get(pageValues, STATE_KEYS.MANIFEST.ENTITIES, [])
    .reduce((memo, entity) => {
      if (PRODUCT_ENTITIES.indexOf(entity.uid) > -1) {
        memo.push(entity.uuid);
      }
      return memo;
    }, [])
    .reduce((memo, entityUUID) => {
      const entityContentData = get(content, `${entityUUID}.data`);

      if (entityContentData && entityContentData.products) {
        entityContentData.products.forEach(({ shortId }) => {
          if (shortId && memo.indexOf(shortId) === -1) {
            memo.push(shortId);
          }
        });
      }

      return memo;
    }, []);
};

/**
 * Filter shortLinks based on shortIds present on the page
 * @param {Object} pageValues
 * @param {Object} pageValues .manifest
 * @param {Array} shortLinks
 * @returns
 */
export const filterPageShortLinks = (pageValues, shortLinks) => {
  const shortIds = reducePageShortLinkIds(pageValues);

  return shortIds.reduce((memo, shortId) => {
    const shortLinkMatch = shortLinks.find(
      (shortLink) => shortLink.short_id === shortId
    );
    if (shortLinkMatch) {
      memo.push(shortLinkMatch);
    }
    return memo;
  }, []);
};

export const separatePrimariesPricesAndProducts = (primaries) =>
  primaries.reduce(
    (memo, { price, product }) => {
      if (price && memo.prices.indexOf(price) === -1) {
        memo.prices.push(price);
      }
      if (product && memo.products.indexOf(product) === -1) {
        memo.products.push(product);
      }
      return memo;
    },
    {
      prices: [],
      products: []
    }
  );

export const extendMemoReferences = (memo, references) => {
  for (const referenceKey in references) {
    if (
      memo[referenceKey] &&
      references[referenceKey] &&
      references[referenceKey].length
    ) {
      references[referenceKey].forEach((resourceId) => {
        if (memo[referenceKey].indexOf(resourceId) === -1) {
          memo[referenceKey].push(resourceId);
        }
      });
    }
  }
};

export const reduceEntityContentReferences = ({ content, entities, store }) => {
  const ctxStore = store || cloneDeep(STRIPE_REFERENCES_STORE);

  return entities.reduce((memo, entity) => {
    if (entity.uid === ENTITIES.PRODUCT_COLLECTION && content[entity.uuid]) {
      const common = get(
        content,
        `${entity.uuid}.${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_CHECKOUT_COMMON}`,
        []
      );
      if (common) {
        const commonReferences = reduceShortLinkReferences({
          data: common
        });
        extendMemoReferences(memo, commonReferences);
      }
      const checkouts = get(
        content,
        `[${entity.uuid}].${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_PRODUCTS_CHECKOUTS}`,
        []
      );

      for (const productUUID in checkouts) {
        const productCheckoutConfig = checkouts[productUUID];
        if (!isEmpty(productCheckoutConfig)) {
          const references = reduceShortLinkReferences({
            data: {
              product: productCheckoutConfig
            }
          });
          extendMemoReferences(memo, references);
        }
      }
    } else if (entity.uid === ENTITIES.FORM && content[entity.uuid]) {
      const common = get(
        content,
        `${entity.uuid}.${STATE_KEYS.FORM.CONFIG_CHECKOUT_COMMON}`,
        []
      );

      if (common) {
        const commonReferences = reduceShortLinkReferences({
          data: common
        });
        extendMemoReferences(memo, commonReferences);
      }

      const constant = get(
        content,
        `${entity.uuid}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_CONSTANT}`,
        []
      );
      if (constant) {
        const constantReferences = reduceShortLinkReferences({
          data: {
            product: constant
          }
        });
        extendMemoReferences(memo, constantReferences);
      }

      const combos = get(
        content,
        `[${entity.uuid}].${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`,
        []
      );

      if (combos && combos.length) {
        combos.forEach(({ result }) => {
          if (result) {
            const references = reduceShortLinkReferences({
              data: {
                product: result
              }
            });
            extendMemoReferences(memo, references);
          }
        });
      }
    }
    return memo;
  }, ctxStore);
};

export const reducePageResourceReferences = ({ manifest, shortLinks = [] }) => {
  const references = {
    products: [],
    prices: [],
    taxRates: [],
    shippingRates: [],
    coupons: [],
    promotionCodes: []
  };

  const primaries = get(manifest, "data.config.pricing.primaries", []);
  const layout = get(manifest, "data.config.pricing.layout", []);

  references.products = layout.map(({ uid }) => uid);
  const filteredPrimaries = primaries.filter(
    ({ product }) => references.products.indexOf(product) > -1
  );

  const separated = separatePrimariesPricesAndProducts(filteredPrimaries);
  separated.products.forEach((separatedProduct) => {
    if (references.products.indexOf(separatedProduct) === -1) {
      references.products.push(separatedProduct);
    }
  });
  separated.prices.forEach((separatedPrice) => {
    if (references.prices.indexOf(separatedPrice) === -1) {
      references.prices.push(separatedPrice);
    }
  });

  Array.isArray(shortLinks) &&
    shortLinks.forEach((shortLink) => {
      const shortLinkReferences = reduceShortLinkReferences(shortLink);
      REFERENCE_KEYS.forEach((referenceKey) => {
        const collection = shortLinkReferences[referenceKey];
        collection.forEach((reference) => {
          if (references[referenceKey].indexOf(reference) === -1) {
            references[referenceKey].push(reference);
          }
        });
      });
    });

  const entities = get(manifest, ENTITY_DATA, []);
  const content = get(manifest, CONTENT_DATA, []);

  /**
   * Parse the references from entities and content
   * Then extend the already found references with unique values
   */
  const result = reduceEntityContentReferences({
    entities,
    content,
    store: references
  });

  /**
   * Pull out references from manifest common checkout config
   */
  const common = get(manifest, `data.${CONFIG_CHECKOUT_COMMON}`);
  if (common) {
    const commonReferences = reduceShortLinkReferences({
      data: common
    });

    extendMemoReferences(result, commonReferences);
  }

  return result;
};

export const reducePageMinimumData = ({
  manifest,
  shortLinks = [],
  ...props
}) => {
  const references = reducePageResourceReferences({
    manifest,
    shortLinks
  });

  return REFERENCE_KEYS.reduce((memo, referenceKey) => {
    if (!memo[referenceKey]) {
      memo[referenceKey] = [];
    }

    if (
      references[referenceKey] &&
      references[referenceKey].length &&
      props[referenceKey] &&
      props[referenceKey].length
    ) {
      references[referenceKey].forEach((referenceId) => {
        const refMatch = props[referenceKey].find(
          ({ id }) => id === referenceId
        );
        if (refMatch) {
          /**
           * Pick out the minimum information needed to render the page
           * - Only the products and prices on the page
           * - Only the attributes of those records needed
           */
          const values = MODEL_MIN_KEYS[referenceKey]
            ? pick(refMatch, MODEL_MIN_KEYS[referenceKey])
            : refMatch;
          memo[referenceKey].push(values);
        }
      });
    }

    return memo;
  }, {});
};

export const getEntitiesForManifestContext = (entities = [], context) => {
  const contextEntities = MANIFEST_CONTEXT_TYPE_ENTITIES[context] || [];
  return entities.filter(({ uid }) => contextEntities.indexOf(uid) > -1);
};

export const getLiveManifestResourceUrl = ({
  application,
  urlVariations,
  liveVariation
}) => {
  const route = liveVariation && liveVariation.route;
  if (!route) {
    return "";
  }

  const isPlatform = application && isPlatformSubdomain(application.subdomain);
  let isOrg = application.resource === ORGANIZATION;
  /**
   * Published platform pages are rendered at the alias route
   * So we need to flip org to false when application is platform and page is not a platform route
   */
  const usePlatformAlias = isPlatform && !PLATFORM_PAGE_ROUTES[route];
  if (usePlatformAlias) {
    isOrg = false;
  }

  return isOrg
    ? `${urlVariations.subdomain}${route}`
    : `${urlVariations.alias}${route}`;
};

/**
 * NOTE:
 * - Most simple validation is done on the client (presence and pattern)
 * - More complex request based validation is done on the server checkout submit action
 * - Exception is the TIN field
 * - admin.{KEY_PREFIX}Key can be used here to pick and pass a header for a client side validation call
 */
export const validateFormFieldGroups = async ({ values, stateKey }) => {
  const contentPath = getFieldPath(stateKey, "content");
  const entitiesPath = getFieldPath(stateKey, "entities");
  const entities = get(values, entitiesPath) || [];
  const formEntity = entities.find(({ uid }) => uid === ENTITIES.FORM);
  const result = {};

  if (formEntity) {
    const formEntityUUID = formEntity.uuid;
    const formContentRoot = `${contentPath}.${formEntityUUID}`;

    const fieldGroups = get(
      values,
      `${formContentRoot}.${STATE_KEYS.FORM.FIELD_GROUPS}`
    );

    for (let groupIx = 0; groupIx < fieldGroups.length; groupIx++) {
      const fieldGroup = fieldGroups[groupIx];
      for (let fieldIx = 0; fieldIx < fieldGroup.fields.length; fieldIx++) {
        const path = `data.fieldGroups[${groupIx}].fields[${fieldIx}]`;
        const field = fieldGroup.fields[fieldIx];
        const errorMessage = await asyncValidateFieldFactory({
          field
        });

        if (errorMessage) {
          set(result, getFieldPath(formContentRoot, path), {
            value: errorMessage
          });
        }
      }
    }
  }

  return result;
};

export const validateManifest = ({
  prices = [],
  products = [],
  coupons = [],
  taxRates = [],
  shippingRates = []
} = {}) => {
  return async (values) => {
    let result = {};

    if (values.manifest && values.manifest.context === ENTITIES.FORM) {
      const formEntity = getFormEntity(values);

      if (formEntity) {
        const formContentRoot = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntity.uuid}`;
        const formFields = getManifestFormFields(values);

        const combinations = get(
          values,
          `${formContentRoot}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`
        );
        const constant = get(
          values,
          `${formContentRoot}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_CONSTANT}`
        );
        const commonConfig = get(
          values,
          `${formContentRoot}.${STATE_KEYS.FORM.CONFIG_CHECKOUT_COMMON}`
        );

        result = validateCombinations({
          uuid: formEntity.uuid,
          combinations,
          formFields,
          constant,
          common: commonConfig,
          prices,
          products,
          coupons,
          taxRates,
          shippingRates
        });

        /**
         * Decorate with field group validations
         * If enabled within the editor
         */
        const validateFields = Boolean(
          get(values, STATE_KEYS.EDITOR.VALIDATIONS_FIELDS_ENABLED)
        );
        if (validateFields) {
          const fieldGroupValidations = await validateFormFieldGroups({
            values,
            stateKey: STATE_KEYS.MANIFEST.DATA
          });
          for (const fieldValidationKey in fieldGroupValidations) {
            result[fieldValidationKey] =
              fieldGroupValidations[fieldValidationKey];
          }
        }
      }
    }

    return result;
  };
};

export const validateManifestRequestField = async ({
  headers,
  manifestId,
  fieldUUID,
  data,
  setLoading,
  onError
}) => {
  setLoading && setLoading(true);

  try {
    const params = {
      method: METHODS.GET,
      headers,
      url: `${API_PATHS.MANIFESTS_FIELDS_VALIDATE({
        manifestId,
        fieldUUID
      })}${QS.stringify(data, { addQueryPrefix: true })}`
    };
    const response = await axios(params);
    setLoading && setLoading(false);

    return response.data;
  } catch (error) {
    setLoading && setLoading(false);

    onError && onError(error);
    return error;
  }
};

/**
 * Omit content related errors / like value validation errors
 */
export const getManifestPublishErrors = ({ values, errors }) => {
  const result = {};
  /**
   * Ignore all content errors for now
   */
  // const contentErrors = get(errors, STATE_KEYS.MANIFEST.CONTENT);
  const configErrors = get(errors, STATE_KEYS.MANIFEST.CONFIG);
  const entityErrors = get(errors, STATE_KEYS.MANIFEST.ENTITIES);
  if (configErrors) {
    set(result, STATE_KEYS.MANIFEST.CONFIG, configErrors);
  }

  if (entityErrors) {
    set(result, STATE_KEYS.MANIFEST.ENTITIES, entityErrors);
  }

  return !isEmpty(result) && result;
};

export const parseManifestEntityUUIDFromName = (name) => {
  // Ensure passes the uuid pattern test
  const rootFragment = compact(
    name.split(`${STATE_KEYS.MANIFEST.CONTENT}.`)
  )[0];
  const target = rootFragment ? compact(rootFragment.split("."))[0] : "";

  return target;
};

/**
 * Retain the current manifest values
 * @param {Object} params
 * @param {Object} params.initialValues
 * @param {Object} params.values
 * @returns
 */
export const getManifestResetValues = ({ initialValues, values }) => {
  const result = cloneDeep(initialValues);
  /**
   * Retain the editor values like focus etc
   */
  result.editor = cloneDeep(get(values, "editor"));

  return result;
};

/**
 * Presence of embed and layout type used to drive viewport classes
 */
export const getEmbedState = (embed = {}) => {
  const enabled = Boolean(get(embed, "enabled"));
  const layout = get(embed, "layout") || null;

  return {
    disabledOrSplitLayout: !enabled || layout === EMBED_LAYOUT.SPLIT,
    enabledSingleLayout: enabled && layout === EMBED_LAYOUT.SINGLE,
    enabled,
    layout
  };
};

export const getManifestEntity = ({ values, uuid }) => {
  const entities = get(values, STATE_KEYS.MANIFEST.ENTITIES) || [];

  return entities.find((entity) => entity.uuid === uuid);
};

export const removeManifestFormField = ({
  values,
  touched,
  setValues,
  setTouched,
  name
}) => {
  const field = get(values, name);
  const fieldsPath = getFieldNameRoot(name);
  const fieldIndex = getFieldNameIndex(name);
  const formEntity = getFormEntity(values);
  /**
   * Reset focus
   */
  const focus = [
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: null
    }
  ];

  /**
   * Remove model from collection
   */
  const updatedValues = cloneDeep(values);
  const updatedTouched = cloneDeep(touched);

  if (fieldIndex > -1) {
    const models = get(updatedValues, fieldsPath) || [];
    models.splice(fieldIndex, 1);
    set(updatedValues, fieldsPath, models);
    set(updatedTouched, fieldsPath, true);
  }
  if (Array.isArray(focus)) {
    focus.forEach(({ key, value }) => set(updatedValues, key, value));
  }

  /**
   * Remove field from from content
   * - checkout fields
   * - selections
   */
  const formPath = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntity.uuid}`;
  const logicFieldsPath = `${formPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_FIELDS}`;
  const updatedCheckoutFields = get(updatedValues, logicFieldsPath) || [];

  const fieldIx = updatedCheckoutFields.indexOf(field.uuid);
  if (fieldIx > -1) {
    updatedCheckoutFields.splice(fieldIx, 1);
    set(updatedValues, logicFieldsPath, updatedCheckoutFields);
    set(updatedTouched, logicFieldsPath, true);
  }

  const fieldGroupsPath = `${formPath}.${STATE_KEYS.FORM.FIELD_GROUPS}`;
  const fieldGroups = get(values, fieldGroupsPath, []);
  const logicCombosPath = `${formPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`;
  const currentCombos = get(updatedValues, logicCombosPath) || [];

  const fieldUUIDs = [field.uuid];
  let combosResult = [];
  if (updatedCheckoutFields.length) {
    // 1. Re-scaffold
    const updatedCombos = scaffoldCombinations(
      updatedCheckoutFields,
      /**
       * Note: ok to use current field groups because we are filtering by the set of updatedCheckoutFields uuids
       */
      fieldGroups
    );

    // 2. Process
    const processedCombos = currentCombos.reduce((memo, combo) => {
      const updatedSelections = omit(combo.selections, fieldUUIDs);

      if (!isEmpty(updatedSelections)) {
        memo.push({
          ...combo,
          selections: updatedSelections
        });
      }
      return memo;
    }, []);

    // 3. Merge combinations
    combosResult = mergeCombinations(processedCombos, updatedCombos);
  }

  set(updatedValues, logicCombosPath, combosResult);

  setValues(updatedValues);
  setTouched(updatedTouched);
};

// Add a note to confirmation about checkout combination effects
export const removeManifestFormFieldGroup = ({
  values,
  touched,
  setValues,
  setTouched,
  name
}) => {
  const model = get(values, name);
  const modelsPath = getFieldNameRoot(name);
  const modelIx = getFieldNameIndex(name);
  const formEntity = getFormEntity(values);

  /**
   * Reset focus
   */
  const focus = [
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
      value: null
    }
  ];

  /**
   * Remove model from collection
   */
  const updatedValues = cloneDeep(values);
  const updatedTouched = cloneDeep(touched);

  if (modelIx > -1) {
    const models = get(updatedValues, modelsPath) || [];
    models.splice(modelIx, 1);
    set(updatedValues, modelsPath, models);
    set(updatedTouched, modelsPath, true);
  }
  if (Array.isArray(focus)) {
    focus.forEach(({ key, value }) => set(updatedValues, key, value));
  }

  /**
   * Remove field from from content
   * - checkout fields
   * - selections
   */
  if (model && Array.isArray(model.fields) && model.fields.length) {
    /**
     * Checkout logic fields
     */
    const fieldUUIDs = model.fields.map(({ uuid }) => uuid);
    const formPath = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntity.uuid}`;
    const logicFieldsPath = `${formPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_FIELDS}`;
    const currentLogicCheckoutFields =
      get(updatedValues, logicFieldsPath) || [];

    const updatedCheckoutFields = without(
      currentLogicCheckoutFields,
      ...fieldUUIDs
    );
    set(updatedValues, logicFieldsPath, updatedCheckoutFields);
    set(updatedTouched, logicFieldsPath, true);

    /**
     * Process combinations
     * - Re-scaffold combinations using new set of checkout field uuids
     * - Process current combos by pruning all removed field uuids from combo selections
     * - Merge new with processed combos
     */
    const fieldGroupsPath = `${formPath}.${STATE_KEYS.FORM.FIELD_GROUPS}`;
    const fieldGroups = get(values, fieldGroupsPath, []);
    const logicCombosPath = `${formPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`;
    const currentCombos = get(updatedValues, logicCombosPath) || [];

    let combosResult = [];
    if (updatedCheckoutFields.length) {
      // 1. Re-scaffold
      const updatedCombos = scaffoldCombinations(
        updatedCheckoutFields,
        /**
         * Note: ok to use current field groups because we are filtering by the set of updatedCheckoutFields uuids
         */
        fieldGroups
      );

      // 2. Process
      const processedCombos = currentCombos.reduce((memo, combo) => {
        const updatedSelections = omit(combo.selections, fieldUUIDs);

        if (!isEmpty(updatedSelections)) {
          memo.push({
            ...combo,
            selections: updatedSelections
          });
        }
        return memo;
      }, []);

      // 3. Merge combinations
      combosResult = mergeCombinations(processedCombos, updatedCombos);
    }

    set(updatedValues, logicCombosPath, combosResult);
  }

  setValues(updatedValues);
  setTouched(updatedTouched);
};

export const hasAnyFormFieldSelections = (selection) => {
  let result = false;
  for (const fieldUUID in selection) {
    result = selection[fieldUUID];
    if (result) {
      break;
    }
  }
  return result;
};
