import {
  SHIPPING_RATE_FORM_KEY,
  SHIPPING_RATE_TYPE,
  SHIPPING_RATE_DELIVERY_ESTIMATE_UNIT,
  templateShippingRateFixedAmount
} from "./constants/shippingRate";
import get from "lodash/get";
import set from "lodash/set";
import uniq from "lodash/uniq";
import orderBy from "lodash/orderBy";
import startCase from "lodash/startCase";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import { formatUTCTime } from "utils/date";
import { FORM_KEY } from "utils/constants/form";
import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import { reduceKeyDifferences } from "./data";
import { prepareMetadataFieldArray, processUpdateMetadata } from "./metadata";
import { PRODUCT_STATUS } from "./constants/product";
import { getUUID } from "./uuid";
import { getStripeAdminLink } from "utils/stripe";
import {
  formattedPriceToUnitAmount,
  formatUnitAmount
} from "components/FormerEditor/common/currency";
import { TAX_BEHAVIOR, TAX_CODE } from "./constants/taxRate";
import { pluralizeCopy } from "utils/copy";
import { CREATE_SHIPPING_RATES_SCHEMA } from "constants/validation";
import { validateSchema } from "./validate";
import { getFieldPath, integerTransformer } from "./form";
import { v4 as uuidv4 } from "uuid";
import { getPriceTaxBehaviorLabel } from "./taxRate";

export const getSelectShippingOptionScaffold = (uuid = uuidv4()) => {
  return {
    key: uuid,
    uuid,
    shipping_rate: ""
  };
};

export const templateShippingRateDeliveryEstimate = () => ({
  minimum: {
    unit: SHIPPING_RATE_DELIVERY_ESTIMATE_UNIT.BUSINESS_DAY,
    value: ""
  },
  maximum: {
    unit: SHIPPING_RATE_DELIVERY_ESTIMATE_UNIT.BUSINESS_DAY,
    value: ""
  }
});

export const getCreateShippingRateScaffold = (uuid = getUUID()) => {
  return {
    [FORM_KEY]: {
      [SHIPPING_RATE_FORM_KEY.DELIVERY_ESTIMATE]: false
    },
    key: uuid,
    uuid,
    type: SHIPPING_RATE_TYPE.FIXED_AMOUNT,
    active: true,
    fixed_amount: templateShippingRateFixedAmount(),
    delivery_estimate: templateShippingRateDeliveryEstimate(),
    tax_behavior: TAX_BEHAVIOR.UNSPECIFIED,
    tax_code: TAX_CODE.SHIPPING
  };
};

export const prepareEditShippingRateInitialValues = ({ shippingRate }) => {
  return {
    [FORM_KEY]: {
      [SHIPPING_RATE_FORM_KEY.DELIVERY_ESTIMATE]: !isEmpty(
        get(shippingRate, "delivery_estimate")
      )
    },
    id: shippingRate.id,
    active: shippingRate.active,
    type: shippingRate.type,
    display_name: shippingRate.display_name,
    fixed_amount: {
      formattedPrice: formatUnitAmount({
        amount: shippingRate.fixed_amount.amount,
        currency: shippingRate.fixed_amount.currency
      }),
      currency: shippingRate.fixed_amount.currency
    },
    delivery_estimate: {
      minimum: get(shippingRate, "delivery_estimate.minimum"),
      maximum: get(shippingRate, "delivery_estimate.maximum")
    },
    tax_behavior: shippingRate.tax_behavior,
    tax_code: shippingRate.tax_code,
    metadata: prepareMetadataFieldArray(shippingRate.metadata)
  };
};

export const prepareCreateShippingRateInitialValues = (uuid) => {
  return {
    shippingRates: [getCreateShippingRateScaffold(uuid)]
  };
};

const prepareShippingRateFromValues = (shippingRate) => {
  const result = {
    display_name: shippingRate.display_name,
    type: shippingRate.type,
    active: shippingRate.active,
    fixed_amount: {
      amount: formattedPriceToUnitAmount({
        formattedPrice: shippingRate.fixed_amount.formattedPrice,
        currency: shippingRate.fixed_amount.currency
      }),
      currency: shippingRate.fixed_amount.currency
    }
  };

  const minConfig = get(shippingRate, `delivery_estimate.minimum`);
  const maxConfig = get(shippingRate, `delivery_estimate.maximum`);
  const validMinConfig = minConfig && minConfig.value > 0 && minConfig.unit;
  const validMaxConfig = maxConfig && maxConfig.value > 0 && maxConfig.unit;
  const hasValidConfig = Boolean(validMinConfig || validMaxConfig);

  if (hasValidConfig) {
    result.delivery_estimate = {
      minimum: validMinConfig ? minConfig : null,
      maximum: validMaxConfig ? maxConfig : null
    };
  }
  if (shippingRate.tax_behavior) {
    result.tax_behavior = shippingRate.tax_behavior;
  }
  if (shippingRate.tax_code) {
    result.tax_code = shippingRate.tax_code;
  }

  return result;
};

export const prepareCreateShippingRates = (shippingRates) => {
  return omitAndSanitizeParams({
    shippingRates: shippingRates.map((values) =>
      prepareShippingRateFromValues(values)
    )
  });
};

/**
 * Compare changes on keys for update
 * Note: db will merge the data field but that should be deprecated
 * @param {Object} initial form values
 * @param {Object} current form values
 */
export const prepareUpdateShippingRate = (initial, current) => {
  const result = reduceKeyDifferences(["active"], initial, current);

  /**
   * Process metadata - setting null where values are empty so that they are unset within Stripe
   * https://stripe.com/docs/api/products/update#update_product-metadata
   */
  result.metadata = processUpdateMetadata(initial.metadata, current.metadata);

  return result;
};

export const isShippingRateId = (id) => /^shr_/.test(id);

export const getShippingDeliveryEstimateLabel = (
  shippingRate,
  options = { parentheses: true }
) => {
  let result = "";
  let estimateLabels = [];

  const minUnit = get(shippingRate, "delivery_estimate.minimum.unit");
  const minValue = get(shippingRate, "delivery_estimate.minimum.value");

  const maxUnit = get(shippingRate, "delivery_estimate.maximum.unit");
  const maxValue = get(shippingRate, "delivery_estimate.maximum.value");

  const sameUnit = minUnit && maxUnit && minUnit === maxUnit;
  if (sameUnit) {
    if (minValue > 0) {
      estimateLabels.push(minValue);
    }
    if (maxValue > 0) {
      estimateLabels.push(maxValue);
    }
    estimateLabels = uniq(estimateLabels);

    const estimateRange = estimateLabels.join(" - ");
    const lastValue = estimateLabels.pop();
    const unitLabel = minUnit.replace(/_/g, " ");
    const pluralized = `${unitLabel}${lastValue === 1 ? "" : "s"}`;
    const mergedLabel = `${estimateRange} ${pluralized}`;
    result = options && options.parentheses ? `(${mergedLabel})` : mergedLabel;
  } else {
    if (minValue > 0) {
      const minUnitLabel = pluralizeCopy(minUnit.replace(/_/g, " "), minValue);
      estimateLabels.push(minUnitLabel);
    }
    if (maxValue > 0) {
      const maxUnitLabel = pluralizeCopy(maxUnit.replace(/_/g, " "), maxValue);
      estimateLabels.push(maxUnitLabel);
    }
    if (estimateLabels.length) {
      const mergedLabel = estimateLabels.join(" - ");
      result =
        options && options.parentheses ? `(${mergedLabel})` : mergedLabel;
    }
  }

  return result;
};

export const prepareShippingRateShow = ({ shippingRate }) => {
  const result = {
    id: shippingRate.id,
    formattedPrice: formatUnitAmount({
      amount: shippingRate.fixed_amount.amount,
      currency: shippingRate.fixed_amount.currency
    }),
    currency: shippingRate.fixed_amount.currency,
    displayName: shippingRate.display_name,
    arrivalEstimate: shippingRate.delivery_estimate
      ? getShippingDeliveryEstimateLabel(shippingRate, {
          parentheses: false
        })
      : "",
    status: shippingRate.active
      ? startCase(PRODUCT_STATUS.ACTIVE)
      : startCase(PRODUCT_STATUS.INACTIVE),
    adminUrl: getStripeAdminLink(
      shippingRate.livemode,
      `shippingRates/${shippingRate.id}`
    ),
    createdAt: formatUTCTime(shippingRate.created * 1000),
    taxBehavior: getPriceTaxBehaviorLabel({
      tax_behavior: shippingRate.tax_behavior
    }),
    taxCode: shippingRate.tax_code
  };

  return result;
};

export const prepareShippingRateList = ({ shippingRates, shouldOrder }) => {
  const ctxModels = shouldOrder
    ? orderBy(shippingRates, ["fixed_amount.amount"], ["asc"])
    : shippingRates;

  return ctxModels.map((shippingRate) => {
    return prepareShippingRateShow({
      shippingRate
    });
  });
};

export const validateShippingRate = (values, name = "", validations = {}) => {
  const result = cloneDeep(validations);

  const deliveryEstimateEnabledPath = getFieldPath(
    name,
    `${FORM_KEY}.${SHIPPING_RATE_FORM_KEY.DELIVERY_ESTIMATE}`
  );
  const deliveryEstimateEnabled = get(values, deliveryEstimateEnabledPath);

  if (deliveryEstimateEnabled) {
    ["minimum", "maximum"].forEach((limitKey) => {
      const estValuePath = getFieldPath(
        name,
        `delivery_estimate.${limitKey}.value`
      );
      const limitVal = get(values, estValuePath);

      if (limitVal !== "" && !integerTransformer(limitVal)) {
        set(result, estValuePath, "Value must be greater than 0");
      }
    });
  }

  return result;
};

export const validateShippingRateSchema = async (values) => {
  let validResult = await validateSchema(CREATE_SHIPPING_RATES_SCHEMA, values);

  values.shippingRates.forEach((_, priceIx) => {
    const rootPath = `shippingRates[${priceIx}]`;
    validResult = validateShippingRate(values, rootPath, validResult);
  });

  return validResult;
};
