import axios from "axios";
import { API_PATHS } from "./paths";

const IDENTITY_KEY =
  process.env.NODE_ENV === "production"
    ? "priceblocs-identity"
    : "priceblocs-dev-identity";

/**
 * Manually clear all matching storage keys
 * Prod
 * Object.keys(window.localStorage).filter((key) => /^priceblocs-identity-/.test(key)).forEach((key) => window.localStorage.removeItem(key));
 * Dev
 * Object.keys(window.localStorage).filter((key) => /^priceblocs-dev-identity-/.test(key)).forEach((key) => window.localStorage.removeItem(key));
 */

export const validateIdentityTin = async ({ data, setLoading, onError }) => {
  setLoading && setLoading(true);

  try {
    const response = await axios({
      method: "get",
      url: API_PATHS.IDENTITY_TIN_VALIDATE,
      params: data
    });
    setLoading && setLoading(false);
    return response.data;
  } catch (error) {
    setLoading && setLoading(false);

    onError && onError(error);
    return error;
  }
};

const handleVerificationAction = async ({
  method,
  url,
  params,
  headers,
  setLoading,
  onError
}) => {
  setLoading && setLoading(true);

  try {
    const response = await axios({
      method,
      url,
      params,
      headers
    });
    setLoading && setLoading(false);
    return response.data;
  } catch (error) {
    setLoading && setLoading(false);

    onError && onError(error);
    return error;
  }
};

export const fetchIdentityVerificationStatus = async ({
  data,
  headers,
  setLoading,
  onError
}) => {
  return await handleVerificationAction({
    method: "get",
    url: API_PATHS.IDENTITY_VERIFICATION_STATUS,
    params: data,
    headers,
    setLoading,
    onError
  });
};

export const cancelIdentityVerificationStatus = async ({
  data,
  setLoading,
  headers,
  onError
}) => {
  return await handleVerificationAction({
    method: "post",
    url: API_PATHS.IDENTITY_VERIFICATION_CANCEL,
    params: data,
    headers,
    setLoading,
    onError
  });
};

export const redactIdentityVerificationStatus = async ({
  data,
  setLoading,
  onError
}) => {
  return await handleVerificationAction({
    method: "post",
    url: API_PATHS.IDENTITY_VERIFICATION_REDACT,
    params: data,
    setLoading,
    onError
  });
};

const getIdentityKey = (key) => `${IDENTITY_KEY}-${key}`;

export const setStoreIdentityField = ({ key, value }) =>
  typeof window !== "undefined" &&
  window.localStorage.setItem(getIdentityKey(key), value);

export const clearStoreIdentityField = (key) =>
  typeof window !== "undefined" &&
  window.localStorage.removeItem(getIdentityKey(key));

export const getStoreIdentityField = (key) =>
  typeof window !== "undefined" &&
  window.localStorage.getItem(getIdentityKey(key));
