import C from "constants/application";
import ActionHelpers from "./helpers";
import { setManifests } from "./manifest";
import { setDeployments } from "./deployment";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { setThemes } from "./theme";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_APPLICATION_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setApplications = (payload) => {
  return {
    type: C.SET_APPLICATIONS,
    payload
  };
};

export const unsetMockApplication = (payload) => {
  return {
    type: C.UNSET_MOCK_APPLICATION,
    payload
  };
};

export const replaceApplications = (payload) => {
  return {
    type: C.REPLACE_APPLICATIONS,
    payload
  };
};

/**
 * resetApplicationNotes
 */
export function resetApplicationNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_APPLICATION_STORE,
      payload
    });
  };
}

export function showApplicationNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_APPLICATION_STORE,
        message || `Success!`
      )
    );
  };
}

/**
 * publishApplicationManifest
 */
export const publishApplicationManifest = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/applications/${uuid}/publish`;
    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { application, manifest, deployment, theme } }) => {
        const actions = [
          setApplications([application]),
          setDeployments([deployment]),
          setManifests([manifest]),
          ActionHelpers.successNote(C.UPDATE_APPLICATION_STORE, "Published App")
        ];
        if (theme) {
          actions.push(setThemes([theme]));
        }

        dispatch(batchActions(actions));

        callback(null, {
          application,
          manifest,
          deployment,
          theme
        });
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_APPLICATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchApplications
 */
export const fetchApplications = (
  { resourceUUID, resource },
  callback = () => {}
) => {
  return (dispatch) => {
    dispatch(ActionHelpers.loadingAction(C.UPDATE_APPLICATION_STORE));
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/applications`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            setApplications(data),
            ActionHelpers.clearLoader(C.UPDATE_APPLICATION_STORE)
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_APPLICATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateApplication
 */
export const updateApplication = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    dispatch(ActionHelpers.loadingAction(C.UPDATE_APPLICATION_STORE));
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/applications/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      headers: authBearerToken(),
      data
    })
      .then(({ data }) => {
        const actions = [
          setApplications([data]),
          ActionHelpers.clearLoader(C.UPDATE_APPLICATION_STORE)
        ];

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_APPLICATION_STORE,
                "Application updated"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_APPLICATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
