import {
  PRICE_CURRENCY_CONFLICT_VALIDATION_MESSAGE,
  INTERVAL_CONFLICT_VALIDATION_MESSAGE,
  PAYMENT_METHOD_TYPE_INTERVAL_CONFLICT_VALIDATION_MESSAGE,
  ZERO_QUANTITY_VALIDATION_MESSAGE,
  ZERO_TOTAL_VALIDATION_MESSAGE,
  SHORT_LINK_ACTION_UID,
  FIXED_COUPON_CURRENCY_CONFLICT_VALIDATION_MESSAGE,
  SHIPPING_RATE_CURRENCY_CONFLICT_VALIDATION_MESSAGE,
  SHIPPING_RATE_INTERVAL_CONFLICT_VALIDATION_MESSAGE,
  PRICE_MISSING_TAX_BEHAVIOR_VALIDATION_MESSAGE,
  TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE,
  PRICE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE,
  SHIPPING_RATE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE,
  PRICE_SHIPPING_RATE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE,
  SHIPPING_RATE_MISSING_TAX_BEHAVIOR_VALIDATION_MESSAGE,
  CAPTURE_METHOD_MINIMUM_PAYMENT_METHOD_MESSAGE,
  CAPTURE_METHOD_CONFLICT_PAYMENT_METHOD_MESSAGE,
  CAPTURE_METHOD_CONFLICT_MODE_MESSAGE,
  SUBSCRIPTION_SCHEDULE_MODE_MESSAGE,
  SHORT_LINK_FORM_TYPE,
  SHORT_LINK_FIELDS,
  SHORT_LINK_FIELD_UIDS,
  SHORT_LINK_FIELD_KEYS,
  SHORT_LINK_INTERSTITIAL_TITLE_COLLECTION,
  SETUP_FUTURE_USAGE_CONFLICT_PAYMENT_METHOD_MESSAGE,
  SETUP_FUTURE_USAGE_MINIMUM_PAYMENT_METHOD_MESSAGE
} from "./constants/shortLink";
import {
  CREATE_SHORT_LINK_SCHEMA,
  UPDATE_SHORT_LINK_SCHEMA,
  OPTIONAL_EMAIL_SCHEMA
} from "constants/validation";
import { getConfig } from "utils/env";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import set from "lodash/set";
import xor from "lodash/xor";
import omit from "lodash/omit";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
import PATHS, { LEARN_PATHS } from "./paths";
import QS from "qs";
import { ENV } from "./constants/env";
import {
  formatUnitAmount,
  roundAmount
} from "components/FormerEditor/common/currency";
import { interpolateRoute, isTestRoute } from "./route";
import { formatTime, orderByDate } from "./date";
import { CURRENCY_CODES } from "components/FormerEditor/common/constants/currency";
import { getShortLinkCheckoutScaffold, getRedirectUrls } from "./checkout";
import { FORM_KEY, TOGGLE_STATE } from "utils/constants/form";
import {
  STATE_KEYS,
  SHIPPING_ADDRESS_COLLECTION,
  TRIAL_PERIOD_DAYS,
  TRIAL_END
} from "utils/constants/state";
import { buildMetadataData } from "./metadata";
import { X_API_KEY } from "./constants/header";
import axios from "axios";
import { nanoid } from "nanoid";
import { isMissingTaxBehavior, isTaxRateId } from "./taxRate";
import { shortLinkCheckoutPreview } from "components/FormerEditor/common/preview";
import { INTERVAL_LABELS_MAP } from "components/FormerEditor/common/constants";
import {
  PAYMENT_METHOD_ALLOWED_CURRENCY_MAP,
  PAYMENT_METHOD_LABEL_MAP,
  PAYMENT_METHOD_TYPE,
  PAYMENT_METHODS_WITH_RANGE,
  PAYMENT_METHOD_TOTAL_RANGE,
  PAYMENT_MODE,
  CAPTURE_METHOD,
  PLATFORM_SOURCE,
  SETUP_FUTURE_USAGE,
  RECURRING_PAYMENT_METHOD_TYPES,
  SETUP_FUTURE_USAGE_PAYMENT_METHOD_TYPES,
  SHIPPING_REQUIRED_PAYMENT_METHODS
} from "./constants/payment";
import { getFlexTemplatePrice } from "utils/constants/price";
import { validatePrice, preparePriceFromValues } from "./price";
import { getUUID } from "./uuid";
import {
  reduceCouponsPromotionCodes,
  reduceShippingOptionsRates
} from "components/FormerEditor/common";
import { captureException } from "utils/error";
import { validateUrlQS } from "utils/url";
import { useCtxReferences } from "./selectors";
import { ORGANIZATION } from "./constants/models";
import { getSubdomainRoot } from "./subdomain";
import { validateSchema } from "./validate";
import isEqual from "lodash/isEqual";
import difference from "lodash/difference";
import { getStripeDestination } from "./stripe";
import { TAX_RATE_CLUSIVITY } from "./constants/taxRate";
import {
  CONFIRMATION_TEMPLATE,
  CONFIRMATION_TEMPLATE_FACTORY
} from "./constants/confirmation";
import { isShippingRateId } from "./shippingRate";
import { SHIPPING_RATE_TYPE } from "./constants/shippingRate";
import {
  getShippingAddressCollectionActiveFormKey,
  getRecurringTrialPeriodDaysActiveFormKey
} from "./checkout/form";

const SHORT_LINK_META_KEYS = ["title", "description"];
const EDIT_ON_STRIPE = "Edit on Stripe";
const CREATE_ON_STRIPE = "Create on Stripe";

const getInvalidRecurringPaymentMethods = (paymentMethodTypes) =>
  paymentMethodTypes.filter((type) => !RECURRING_PAYMENT_METHOD_TYPES[type]);

const getInvalidFutureUsagePaymentMethods = (paymentMethodTypes) =>
  paymentMethodTypes.filter(
    (type) => !SETUP_FUTURE_USAGE_PAYMENT_METHOD_TYPES[type]
  );

export const getPrimaryScaffold = (product, price) => {
  return {
    // Add a unique key for list rendering
    key: getUUID(),
    product: product ? product.id : "",
    price: price ? price.id : "",
    currency: price ? price.currency : CURRENCY_CODES.USD.toLowerCase(),
    interval: price && price.recurring ? price.recurring.interval : "",
    quantity: 1
  };
};

export const prepareCreateShortLinkInitialValues = ({
  short_id = nanoid(),
  uuid = getUUID(),
  application,
  merchantAccount,
  product,
  productPrices
}) => {
  let primaries = [];
  if (product) {
    const ctxPrice = productPrices.length === 1 && productPrices[0];
    primaries = [getPrimaryScaffold(product, ctxPrice)];
  }

  const redirectUrls = getRedirectUrls({
    application,
    merchantAccount
  });

  return {
    [FORM_KEY]: {
      type: null,
      [SHIPPING_ADDRESS_COLLECTION]: false,
      [TRIAL_PERIOD_DAYS]: false
    },
    uuid,
    short_id,
    title: "",
    description: "",
    data: {
      application_id: application.id,
      merchant_account_id: merchantAccount.id,
      action_uid: SHORT_LINK_ACTION_UID.MERCHANT_CHECKOUT,
      success_url: redirectUrls.successUrl,
      cancel_url: redirectUrls.cancelUrl,
      product: {
        checkout: getShortLinkCheckoutScaffold({
          merchantAccountConfig: merchantAccount.merchant_account_config
        }),
        primaries,
        attachments: [],
        prices: []
      },
      fields: {
        pre: [],
        post: []
      },
      confirmation: {
        template: CONFIRMATION_TEMPLATE.NOTE,
        data: CONFIRMATION_TEMPLATE_FACTORY[CONFIRMATION_TEMPLATE.NOTE]()
      }
    }
  };
};

export const prepareCreateShortLinkFlexInitialValues = ({
  short_id = nanoid(),
  uuid = getUUID(),
  application,
  merchantAccount,
  product,
  productPrices
}) => {
  const result = prepareCreateShortLinkInitialValues({
    short_id,
    uuid,
    application,
    merchantAccount,
    product,
    productPrices
  });

  result[FORM_KEY] = {
    type: SHORT_LINK_FORM_TYPE.PRICE
  };

  set(result, STATE_KEYS.SHORT_LINK.PRICES, [getFlexTemplatePrice(10000)]);

  return result;
};

export const getShortLinkFormKeyInitialValues = (values) => {
  return {
    type: null,
    [SHIPPING_ADDRESS_COLLECTION]: getShippingAddressCollectionActiveFormKey(
      values
    ),
    [TRIAL_PERIOD_DAYS]: getRecurringTrialPeriodDaysActiveFormKey(values)
  };
};

/**
 * Pattern
 * - represent the full model in the form
 * - forward changed values to the API
 * - API merges params with the model / unsets and unsets keys which are null
 * - db swaps out data key
 * TODO
 * - Should the client check any of the values for changes or just forward the full payload?
 * - Would be simpler to forward the full payload
 * Note:
 * - validateUpdateShortLinkForm
 * - prepareUpdateShortLink
 * @param {Object} shortLink
 * @returns
 */
export const prepareEditShortLinkInitialValues = (shortLink) => ({
  [FORM_KEY]: getShortLinkFormKeyInitialValues(shortLink),
  ...cloneDeep(shortLink)
});

/**
 * Compare changes on keys for update
 * NOTE:
 * - Changes here may also require API updates: @getShortLinkUpdateParams
 * @param {Object} initial form values
 * @param {Object} current form values
 */
export const prepareUpdateShortLink = (initial, current) => {
  const result = [
    STATE_KEYS.SHORT_LINK.TITLE,
    STATE_KEYS.SHORT_LINK.DESCRIPTION,
    STATE_KEYS.SHORT_LINK.STATUS,
    STATE_KEYS.SHORT_LINK.SUCCESS_URL,
    STATE_KEYS.SHORT_LINK.CANCEL_URL,
    STATE_KEYS.SHORT_LINK.ALLOW_PROMOTION_CODES
  ].reduce((memo, fieldKey) => {
    const initialVal = get(initial, fieldKey);
    const currentVal = get(current, fieldKey);
    const differentValues = currentVal !== initialVal;
    if (differentValues) {
      set(memo, fieldKey, currentVal);
    }
    return memo;
  }, {});

  /**
   * Handle shipping_address_collection
   */
  const initialCountries = get(
    initial,
    STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES,
    []
  );
  const currentCountries = get(
    current,
    STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES,
    []
  );
  const currentShippingWorldwide = get(
    current,
    STATE_KEYS.SHORT_LINK.SHIPPING_WORLDWIDE,
    false
  );

  const unsetCountries = initialCountries.length && !currentCountries.length;

  /**
   * - If shipping field is inactive
   *  - unset countries
   *  - set shipping worldwide is false
   * - If shipping worldwide is active
   *  - set shipping worldwide true
   *  - unset countries
   * - If countries are set
   *  - set shipping worldwide false
   *  - set countries
   * - If countries have transition to unset
   *  - unset countries
   */
  const currentShippingActive = current[FORM_KEY].shipping_address_collection;
  if (!currentShippingActive) {
    set(result, STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES, []);
    set(result, STATE_KEYS.SHORT_LINK.SHIPPING_WORLDWIDE, false);
  } else if (currentShippingWorldwide) {
    set(result, STATE_KEYS.SHORT_LINK.SHIPPING_WORLDWIDE, true);
    set(result, STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES, []);
  } else if (currentCountries.length) {
    set(result, STATE_KEYS.SHORT_LINK.SHIPPING_WORLDWIDE, false);
    set(
      result,
      STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES,
      currentCountries
    );
  } else if (unsetCountries) {
    set(result, STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES, []);
  }

  /**
   * payment_method_types
   */
  const initialPaymentMethodTypes = get(
    initial,
    STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES,
    []
  );
  const currentPaymentMethodTypes = get(
    current,
    STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES,
    []
  );
  const differentPaymentMethodTypes =
    xor(initialPaymentMethodTypes, currentPaymentMethodTypes).length > 0;

  if (differentPaymentMethodTypes) {
    set(
      result,
      STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES,
      currentPaymentMethodTypes
    );
  }

  /**
   * Attachments
   */
  const initialAttachments = get(
    initial,
    STATE_KEYS.SHORT_LINK.ATTACHMENTS,
    []
  );
  const currentAttachments = get(
    current,
    STATE_KEYS.SHORT_LINK.ATTACHMENTS,
    []
  );
  const differentAttachments =
    xor(initialAttachments, currentAttachments).length > 0;

  if (differentAttachments) {
    set(result, STATE_KEYS.SHORT_LINK.ATTACHMENTS, currentAttachments);
  }

  /**
   * Fields for pre / post
   * - Note: only pre tax id currently supported
   */
  const initialFields = get(initial, STATE_KEYS.SHORT_LINK.FIELDS, {});
  const currentFields = get(current, STATE_KEYS.SHORT_LINK.FIELDS, {});
  if (!isEqual(initialFields, currentFields)) {
    set(result, STATE_KEYS.SHORT_LINK.FIELDS, currentFields);
  }

  /**
   * Confirmation message
   */
  const initialConfirmation = get(
    initial,
    STATE_KEYS.SHORT_LINK.CONFIRMATION,
    {}
  );
  const currentConfirmation = get(
    current,
    STATE_KEYS.SHORT_LINK.CONFIRMATION,
    {}
  );
  if (!isEqual(initialConfirmation, currentConfirmation)) {
    set(result, STATE_KEYS.SHORT_LINK.CONFIRMATION, currentConfirmation);
    if (!get(result, STATE_KEYS.SHORT_LINK.CONFIRMATION_TEMPLATE)) {
      set(
        result,
        STATE_KEYS.SHORT_LINK.CONFIRMATION_TEMPLATE,
        CONFIRMATION_TEMPLATE.NOTE
      );
    }
  }

  /**
   * Process trial periods and allow ones which are enabled
   */
  const trialPeriodDaysFormPath = `${FORM_KEY}.${TRIAL_PERIOD_DAYS}`;
  const trialPeriodDaysActive = get(current, trialPeriodDaysFormPath);
  const initialTrialDays = get(
    initial,
    STATE_KEYS.SHORT_LINK.TRIAL_PERIOD_DAYS
  );
  const currentTrialDays = get(
    current,
    STATE_KEYS.SHORT_LINK.TRIAL_PERIOD_DAYS
  );
  const hasRecurring = shortLinkHasRecurringPrices(current);
  if (!hasRecurring || !trialPeriodDaysActive) {
    set(result, STATE_KEYS.SHORT_LINK.TRIAL_PERIOD_DAYS, null);
  } else if (!isEqual(initialTrialDays, currentTrialDays)) {
    set(
      result,
      STATE_KEYS.SHORT_LINK.TRIAL_PERIOD_DAYS,
      /**
       * Must be a min of 1 or fallback to null when 0
       */
      currentTrialDays || null
    );
  }

  /**
   * Payment intent data
   */
  const initialPaymentIntentData = get(
    initial,
    STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA,
    {}
  );
  const currentPaymentIntentData =
    get(current, STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA) || null;
  if (!isEqual(initialPaymentIntentData, currentPaymentIntentData)) {
    set(
      result,
      STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA,
      currentPaymentIntentData
    );
  }

  /**
   * Subscription schedule
   */
  const initialSubSchedule = get(
    initial,
    STATE_KEYS.SHORT_LINK.SUBSCRIPTION_SCHEDULE,
    {}
  );
  const currentSubSchedule =
    get(current, STATE_KEYS.SHORT_LINK.SUBSCRIPTION_SCHEDULE) || null;
  if (!isEqual(initialSubSchedule, currentSubSchedule)) {
    set(
      result,
      STATE_KEYS.SHORT_LINK.SUBSCRIPTION_SCHEDULE,
      currentSubSchedule
    );
  }

  return result;
};

export const getShippingConfig = (values) => {
  const shippingCountries = get(
    values,
    STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES,
    []
  );

  const shippingWorldwide = get(
    values,
    STATE_KEYS.SHORT_LINK.SHIPPING_WORLDWIDE,
    false
  );
  const shippingEnabled = Boolean(
    shippingCountries.length || shippingWorldwide
  );

  return {
    enabled: shippingEnabled,
    countries: shippingCountries,
    worldwide: shippingWorldwide
  };
};

/**
 * Get the checkout url for the short link
 * @param {String} id - short id of the link
 * @param {Bool} test - if in test include the test fragment in the url path
 * @param {String} mode - control what query string params are used for tracking and analytics purposes
 */
export const getShortLinkCheckoutUrl = ({ id, test, mode = ENV.TEST }) => {
  const url = `${getConfig("WEB_CLIENT_ROOT")}${interpolateRoute(
    test ? PATHS.TEST_LINKS_SHOW : PATHS.LINKS_SHOW,
    {
      short: {
        id
      }
    }
  )}`;
  if (mode === ENV.TEST) {
    const previewQSParams = {
      cancel_url: typeof window !== "undefined" ? window.location.href : ""
    };
    return `${url}?${QS.stringify(previewQSParams)}`;
  } else {
    return url;
  }
};

export const getIsShortLinkSetupMode = (shortLink) => {
  const mode = get(shortLink, STATE_KEYS.SHORT_LINK.MODE);

  return mode === PAYMENT_MODE.SETUP;
};

export const getShortLinkCheckoutUrlVariations = ({
  alias,
  subdomain,
  shortId
}) => {
  const result = {
    subdomain: {
      root: "",
      original: "",
      test: ""
    },
    alias: {
      root: "",
      original: "",
      test: ""
    },
    standard: {
      root: "",
      original: "",
      test: ""
    }
  };
  if (!shortId) {
    console.error("Payment link short id is required");
    return result;
  }

  const shortRoot = getConfig("WEB_CLIENT_ROOT");
  const subdomainRoot = getSubdomainRoot(subdomain);
  const interpolateParams = {
    short: {
      id: shortId
    }
  };
  const pathname = interpolateRoute(PATHS.LINKS_SHOW, interpolateParams);
  const testPathname = interpolateRoute(
    PATHS.TEST_LINKS_SHOW,
    interpolateParams
  );
  // const testQSParams = getTestTrackingQS()
  const subdomainOriginal = `${subdomainRoot}${pathname}`;
  const subdomainTestOriginal = `${subdomainRoot}${testPathname}`;
  result.subdomain.root = subdomainRoot;
  result.subdomain.original = subdomainOriginal;
  result.subdomain.test = subdomainTestOriginal;

  const aliasRoot = `${shortRoot}/${alias}`;
  const aliasOriginal = `${aliasRoot}${pathname}`;
  const aliasTestOriginal = `${aliasRoot}${testPathname}`;
  result.alias.root = aliasRoot;
  result.alias.original = aliasOriginal;
  result.alias.test = aliasTestOriginal;

  const standardOriginal = `${shortRoot}${pathname}`;
  const standardTestOriginal = `${shortRoot}${testPathname}`;
  result.standard.root = shortRoot;
  result.standard.original = standardOriginal;
  result.standard.test = standardTestOriginal;

  return result;
};

export const useShortLinkCheckoutResourceUrl = (shortId) => {
  const { resource, application } = useCtxReferences();
  const isOrg = resource === ORGANIZATION;
  const urlVariants = getShortLinkCheckoutUrlVariations({
    subdomain: get(application, "subdomain"),
    alias: get(application, "alias"),
    shortId
  });
  return isOrg ? urlVariants.subdomain.original : urlVariants.standard.original;
};

export const reduceDiscountsSimple = ({ discounts = [], coupons = [] }) => {
  return discounts.reduce(
    (memo, { coupon }) => {
      const couponMatch = coupons.find(({ id }) => id === coupon);
      if (couponMatch) {
        if (couponMatch.amount_off) {
          memo.amount_off += couponMatch.amount_off;
        }
        if (couponMatch.percent_off) {
          memo.percent_off += couponMatch.percent_off;
        }
      }
      return memo;
    },
    { amount_off: 0, percent_off: 0 }
  );
};

export const simpleDiscountsToString = ({ simpleDiscounts, primaries }) => {
  const result = [];
  if (simpleDiscounts.amount_off) {
    result.push(
      `-${formatUnitAmount({
        amount: simpleDiscounts.amount_off,
        currency: primaries[0].currency
      })}`
    );
  }
  if (simpleDiscounts.percent_off) {
    result.push(`-${simpleDiscounts.percent_off}%`);
  }
  return result.length ? `(${result.join(" & ")})` : "";
};

export const getTrialPeriodParams = ({ days, end }) => {
  const result = {};
  if (Number.isInteger(days)) {
    result.trial_period_days = days;
    // Optionally validate that the value is 48hrs in the future - which is required by Stripe
    if (Number.isInteger(end)) {
      result.trial_end = end;
    }
  }
  return result;
};

/**
 * getMerchantCheckoutTrialValues
 * - pull the trial_period_days from provided resources
 * - checkout params are highest
 * - fallback to the global settings within merchantAccountConfig
 * - else nothing
 * @param {Object} params
 * @param {Object} params.checkout
 * @param {Object} params.merchantAccountConfig
 * @returns
 */
export const getMerchantCheckoutTrialValues = ({
  checkout,
  merchantAccountConfig
}) => {
  const days =
    get(checkout, TRIAL_PERIOD_DAYS) ||
    get(
      merchantAccountConfig,
      STATE_KEYS.MERCHANT_ACCOUNT_CONFIG.TRIAL_PERIOD_DAYS
    );
  const end = get(checkout, TRIAL_END);

  return getTrialPeriodParams({
    days,
    end
  });
};

export const getShortLinkTrialValues = ({ shortLink }) => {
  const shortLinkCheckoutConfig = get(
    shortLink,
    STATE_KEYS.SHORT_LINK.CHECKOUT,
    {}
  );
  const checkout = pick(shortLinkCheckoutConfig, [
    TRIAL_PERIOD_DAYS,
    TRIAL_END
  ]);

  return getTrialPeriodParams({
    days: get(checkout, TRIAL_PERIOD_DAYS),
    end: get(checkout, TRIAL_END)
  });
};

export const getShortLinkPreviewTitle = ({
  shortLink,
  products,
  prices,
  coupons,
  taxRates = [],
  shippingRates = [],
  promotionCodes
}) => {
  const mode = get(shortLink, STATE_KEYS.SHORT_LINK.MODE);
  if (mode === PAYMENT_MODE.SETUP) {
    return "Setup payment";
  } else {
    const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
    const newPrices = get(shortLink, STATE_KEYS.SHORT_LINK.PRICES) || [];
    const sampleProductId = get(primaries, "[0].product");

    const productMatch = products.find(({ id }) => id === sampleProductId);
    let formattedProductName;
    if (productMatch) {
      const { name } = productMatch;
      const extraProductCount = primaries.length - 1;
      formattedProductName = extraProductCount
        ? `${name} +${extraProductCount}`
        : name;
    } else if (newPrices.length) {
      formattedProductName = "Pay now";
    }

    const checkoutPreview = shortLinkCheckoutPreview({
      shortLink,
      products,
      prices,
      coupons,
      taxRates,
      newPrices,
      shippingRates
    });
    const amountValue = get(checkoutPreview, "checkout.banner.label");

    return compact([formattedProductName, amountValue]).join(" | ");
  }
};

export const getShortLinkUnitAmount = ({ shortLink, prices }) => {
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];

  if (primaries && primaries.length > 0) {
    return primaries.reduce((memo, primary) => {
      const modelMatch = prices.find((price) => price.id === primary.price);
      if (modelMatch) {
        memo += roundAmount(modelMatch.unit_amount * primary.quantity);
      }
      return memo;
    }, 0);
  } else {
    return null;
  }
};

export const getShortLinkFormattedValue = ({ shortLink, prices }) => {
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];

  if (primaries && primaries.length > 0) {
    const primary = primaries[0];
    const modelMatch = prices.find(({ id }) => id === primary.price);
    const amount = getShortLinkUnitAmount({ shortLink, prices });

    return formatUnitAmount({
      amount,
      currency: modelMatch && modelMatch.currency
    });
  } else {
    return null;
  }
};

export const getPaymentTypeCurrencyConflict = (types, currencies) => {
  return types.reduce((memo, type) => {
    const allowed = PAYMENT_METHOD_ALLOWED_CURRENCY_MAP[type];
    if (allowed && allowed.length > 0) {
      currencies.forEach((currency) => {
        const notAllowed = allowed.indexOf(currency) === -1;
        if (notAllowed) {
          if (!memo[type]) {
            memo[type] = [];
          }
          memo[type].push(currency);
        }
      });
    }
    return memo;
  }, {});
};

const reduceValidationReferences = ({
  primaries,
  products,
  prices,
  flexPrices,
  autoTaxesEnabled
}) => {
  const currencies = [];
  const intervals = [];
  const zeroQuantityProducts = [];
  const incompletePrimaries = [];
  const pricesMissingTaxBehaviors = [];
  const priceTaxBehaviorIntervals = {};

  if (flexPrices && flexPrices.length) {
    flexPrices.forEach(({ recurring, currency }) => {
      if (currency) {
        if (currencies.indexOf(currency) === -1) {
          currencies.push(currency);
        }
      }
      if (recurring && recurring.interval) {
        if (intervals.indexOf(recurring.interval) === -1) {
          intervals.push(recurring.interval);
        }
      }
    });
  }

  for (let primaryIndex = 0; primaryIndex < primaries.length; primaryIndex++) {
    const primary = primaries[primaryIndex];
    const { price, product, quantity } = primary;
    const productMatch = products.find(({ id }) => id === product);
    const modelMatch = prices.find(({ id }) => id === price);

    if (!price || !product) {
      incompletePrimaries.push(primary);
    }

    /**
     * Collect zero quantity products
     */
    if (
      productMatch &&
      !quantity &&
      zeroQuantityProducts.indexOf(productMatch.name) === -1
    ) {
      zeroQuantityProducts.push(productMatch.name);
    }

    if (modelMatch) {
      /**
       * Collect currencies
       */
      const { currency, recurring } = modelMatch;
      if (currencies.indexOf(currency) === -1) {
        currencies.push(currency);
      }
      /**
       * Collect intervals
       */
      if (recurring && recurring.interval) {
        const interval = recurring.interval;
        if (intervals.indexOf(interval) === -1) {
          intervals.push(interval);
        }
      }

      const taxBehavior = modelMatch.tax_behavior;
      if (autoTaxesEnabled) {
        const isMissing = isMissingTaxBehavior(taxBehavior);
        if (isMissing) {
          pricesMissingTaxBehaviors.push(modelMatch.id);
        }
      }
      if (taxBehavior) {
        if (!priceTaxBehaviorIntervals[taxBehavior]) {
          priceTaxBehaviorIntervals[taxBehavior] = [];
        }
        priceTaxBehaviorIntervals[taxBehavior].push(modelMatch.id);
      }
    }
  }

  return {
    currencies,
    intervals,
    zeroQuantityProducts,
    incompletePrimaries,
    pricesMissingTaxBehaviors,
    priceTaxBehaviorIntervals
  };
};

const getConflictPaymentMethodIntervals = ({
  paymentMethodTypes,
  intervals
}) => {
  const subject = paymentMethodTypes.length > 1 ? "are" : "is";

  return {
    copy: `${PAYMENT_METHOD_TYPE_INTERVAL_CONFLICT_VALIDATION_MESSAGE} ${paymentMethodTypes
      .map((method) => PAYMENT_METHOD_LABEL_MAP[method])
      .join(", ")} ${subject} incompatible with ${intervals
      .map((interval) => INTERVAL_LABELS_MAP[interval])
      .join(", ")} prices.`,
    url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_METHODS_RESTRICTIONS
  };
};

const getConflictPaymentMethodCurrencies = ({
  paymentTypeCurrencyConflict
}) => {
  const curPmConflicts = Object.keys(paymentTypeCurrencyConflict).reduce(
    (memo, pmKey) => {
      paymentTypeCurrencyConflict[pmKey].forEach((cur) => {
        if (!memo[cur]) {
          memo[cur] = [];
        }
        if (memo[cur].indexOf(pmKey) === -1) {
          memo[cur].push(pmKey);
        }
      });

      return memo;
    },
    {}
  );
  const pmConflictLabels = Object.keys(curPmConflicts).reduce(
    (memo, curKey) => {
      const methodLabels = curPmConflicts[curKey].map(
        (type) => PAYMENT_METHOD_LABEL_MAP[type]
      );

      memo.push(
        `${curKey.toUpperCase()} prices are not compatible with ${methodLabels.join(
          ", "
        )}.`
      );
      return memo;
    },
    []
  );

  return {
    copy: `${pmConflictLabels.join(". ")}`,
    url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
  };
};

const getMinMaxTotal = ({ currencies, paymentMethodsWithRange, total = 0 }) => {
  const currencyCodes = currencies.map((code) => code);
  const minMaxMessages = paymentMethodsWithRange.reduce((memo, type) => {
    const currencyRestrictions = PAYMENT_METHOD_TOTAL_RANGE[type];

    currencyCodes.forEach((currency) => {
      const restrictions = currencyRestrictions[currency];

      if (restrictions) {
        const { min, max } = restrictions;
        const belowMin = total < min;
        const aboveMax = total > max;
        if (belowMin || aboveMax) {
          const notes = [];
          if (belowMin) {
            notes.push(
              `minimum of ${formatUnitAmount({
                amount: min,
                currency
              })}`
            );
          }
          if (aboveMax) {
            notes.push(
              `maximum of ${formatUnitAmount({
                amount: max,
                currency
              })}`
            );
          }
          memo.push(
            `${
              PAYMENT_METHOD_LABEL_MAP[PAYMENT_METHOD_TYPE.AFTERPAY_CLEARPAY]
            } has an allowed total value ${notes.join(" and ")}.`
          );
        }
      }
    });

    return memo;
  }, []);

  return minMaxMessages.length
    ? {
        copy: minMaxMessages.join(". "),
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_METHODS_RESTRICTIONS
      }
    : {
        copy: "",
        url: ""
      };
};

const getConflictCoupons = ({
  discounts,
  coupons,
  currencies,
  currencyList
}) => {
  const couponCurrencies = [];
  let hasConflictCouponCurrencies = false;
  discounts.forEach(({ coupon }) => {
    const couponMatch = coupons.find(
      ({ id, amount_off }) => id === coupon && amount_off
    );
    if (
      couponMatch &&
      couponMatch.currency &&
      couponCurrencies.indexOf(couponMatch.currency) === -1
    ) {
      if (currencies.indexOf(couponMatch.currency) === -1) {
        hasConflictCouponCurrencies = true;
      }

      couponCurrencies.push(couponMatch.currency);
    }
  });

  return hasConflictCouponCurrencies
    ? {
        copy: `${FIXED_COUPON_CURRENCY_CONFLICT_VALIDATION_MESSAGE} Your coupon is in ${couponCurrencies
          .map((code) => code.toUpperCase())
          .join(", ")}, but your price selection includes: ${currencyList}.`,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
      }
    : {
        copy: "",
        url: ""
      };
};

const getConflictShippingRates = ({
  shippings,
  shippingRates,
  currencies,
  currencyList
}) => {
  const rateCurrencies = [];
  let hasConflict = false;
  shippings.forEach((shippingRateId) => {
    const modelMatch = shippingRates.find(({ id }) => id === shippingRateId);

    if (modelMatch && modelMatch.type === SHIPPING_RATE_TYPE.FIXED_AMOUNT) {
      const currency = modelMatch.fixed_amount.currency;
      if (rateCurrencies.indexOf(currency) === -1) {
        if (currencies.indexOf(currency) === -1) {
          hasConflict = true;
        }

        rateCurrencies.push(currency);
      }
    }
  });

  return hasConflict
    ? {
        copy: `${SHIPPING_RATE_CURRENCY_CONFLICT_VALIDATION_MESSAGE} Your shipping rate is in ${rateCurrencies
          .map((code) => code.toUpperCase())
          .join(", ")}, but your price selection includes: ${currencyList}.`,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
      }
    : {
        copy: "",
        url: ""
      };
};

const getMissingShippingRateTaxBahaviors = ({ shippings, shippingRates }) => {
  return shippings.reduce((memo, shippingRateId) => {
    const modelMatch = shippingRates.find(({ id }) => id === shippingRateId);
    if (modelMatch && isMissingTaxBehavior(modelMatch.tax_behavior)) {
      memo.push(shippingRateId);
    }
    return memo;
  }, []);
};

export const getMissingTaxBehavior = ({
  priceIds,
  shippingRateIds,
  prices
  // shippingRates
}) => {
  let result;

  if (shippingRateIds.length) {
    result = {
      copy: `${SHIPPING_RATE_MISSING_TAX_BEHAVIOR_VALIDATION_MESSAGE} To continue using automatic taxes, create another shipping rate with a tax behavior which matches the items in your checkout, then retry.`,
      url: getStripeDestination({ path: `shipping-rates` }),
      linkCopy: CREATE_ON_STRIPE
    };
  } else if (priceIds.length) {
    const priceMatches = priceIds.reduce((memo, priceId) => {
      const modelMatch = prices.find(({ id }) => id === priceId);
      if (modelMatch) {
        memo.push(modelMatch);
      }
      return memo;
    }, []);
    const multiplePrices = priceMatches.length > 1;

    const joinedPriceIds = priceMatches.map(({ id }) => id).join(", ");
    result = {
      copy: `${PRICE_MISSING_TAX_BEHAVIOR_VALIDATION_MESSAGE} To continue using automatic taxes, add a tax behavior to the ${joinedPriceIds} price${
        multiplePrices ? "s" : ""
      }, then retry.`,
      url: getStripeDestination({ path: `prices/${priceIds[0]}` }),
      linkCopy: EDIT_ON_STRIPE
    };
  }

  return result;
};

export const getConflictPriceTaxBehavior = ({
  taxes,
  taxRates,
  priceTaxBehaviorIntervals
}) => {
  let result;
  /**
   * Can apply exlcusive vs inclusive tax rates in general?
   * If not handle here
   */
  const taxRateBehaviors = taxes.reduce((memo, taxRate) => {
    const taxRateMatch = taxRates.find(({ id }) => id === taxRate);
    if (taxRateMatch) {
      const clusivity = taxRateMatch.inclusive
        ? TAX_RATE_CLUSIVITY.INCLUSIVE
        : TAX_RATE_CLUSIVITY.EXCLUSIVE;
      if (memo.indexOf(clusivity) === -1) {
        memo.push(clusivity.toLowerCase());
      }
    }
    return memo;
  }, []);
  const priceTaxBehaviors = Object.keys(priceTaxBehaviorIntervals);
  if (priceTaxBehaviors.length > 0 && taxRateBehaviors.length > 0) {
    const priceTaxBehaviorDifference = difference(
      priceTaxBehaviors,
      taxRateBehaviors
    );
    if (priceTaxBehaviorDifference.length > 0) {
      result = {
        copy: `${PRICE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE} Your current selection includes tax rates with ${priceTaxBehaviors.join(
          ", "
        )} behaviors but your tax rate selections include ${taxRateBehaviors} behaviors.`,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
      };
    }
  }

  return result;
};

export const getConflictTaxBehavior = ({
  hasShippingRates,
  priceTaxBehaviorIntervals,
  shippingRates,
  shippings
}) => {
  let result;
  const priceTaxBehaviorKeys = Object.keys(priceTaxBehaviorIntervals);
  const pricesHaveConflictingIntervals = priceTaxBehaviorKeys.length > 1;

  if (pricesHaveConflictingIntervals) {
    result = {
      copy: `${TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE} Your current selection includes prices with ${priceTaxBehaviorKeys.join(
        ", "
      )} tax behaviors.`,
      url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
    };
  } else if (hasShippingRates) {
    const shippingTaxBehaviorIntervals = shippings.reduce(
      (memo, shipingRateId) => {
        const modelMatch = shippingRates.find(({ id }) => id === shipingRateId);
        const behavior = modelMatch && modelMatch.tax_behavior;
        if (modelMatch && behavior) {
          if (!memo[behavior]) {
            memo[behavior] = [];
          }
          memo[behavior].push(shipingRateId);
        }
        return memo;
      },
      {}
    );

    const shippingRateTaxBehaviorKeys = Object.keys(
      shippingTaxBehaviorIntervals
    );
    const shippingRatesHaveConflictingIntervals =
      shippingRateTaxBehaviorKeys.length > 1;

    const hasDifference = difference(
      priceTaxBehaviorKeys,
      shippingRateTaxBehaviorKeys
    ).length;

    if (shippingRatesHaveConflictingIntervals) {
      result = {
        copy: `${SHIPPING_RATE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE} Your current selection includes shipping options with ${shippingRateTaxBehaviorKeys.join(
          ", "
        )} tax behaviors.`,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
      };
    } else if (hasDifference) {
      result = {
        copy: `${PRICE_SHIPPING_RATE_TAX_BEHAVIOR_CONFLICT_VALIDATION_MESSAGE} Your current selection includes resources with ${uniq(
          [...priceTaxBehaviorKeys, ...shippingRateTaxBehaviorKeys]
        ).join(", ")} tax behaviors.`,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
      };
    }
  }

  return result;
};

/**
 * Note: the docs say that methods other than card are supported but the checkout API only allows for card
 * @param {Object} params
 * @returns
 */
export const getCaptureMethodPaymentMethodConflict = ({
  paymentMethodTypes,
  paymentIntentCaptureMethod,
  mode
}) => {
  let result;
  const methodTypeCount = paymentMethodTypes.length;
  const minMethodRequired = !methodTypeCount;
  const cardMethodPresent =
    paymentMethodTypes.indexOf(PAYMENT_METHOD_TYPE.CARD) > -1;
  const preAuth = paymentIntentCaptureMethod === CAPTURE_METHOD.MANUAL;
  const isPayment = mode === PAYMENT_MODE.PAYMENT;
  const canCaptureManually = preAuth && isPayment;

  const invalidMethods = methodTypeCount > 1 || !cardMethodPresent;

  if (preAuth) {
    if (canCaptureManually) {
      if (minMethodRequired) {
        result = {
          copy: CAPTURE_METHOD_MINIMUM_PAYMENT_METHOD_MESSAGE,
          url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
        };
      } else if (invalidMethods) {
        const methodLabels = paymentMethodTypes.map(
          (type) => PAYMENT_METHOD_LABEL_MAP[type]
        );
        result = {
          copy: `${CAPTURE_METHOD_CONFLICT_PAYMENT_METHOD_MESSAGE} Your current selection includes ${methodLabels.join(
            ", "
          )}.`,
          url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
        };
      }
    } else {
      result = {
        copy: CAPTURE_METHOD_CONFLICT_MODE_MESSAGE,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
      };
    }
  }

  return result;
};

export const getSetupFutureUsagePaymentMethodConflict = ({
  paymentMethodTypes,
  paymentIntentSetupFutureUsage,
  mode
}) => {
  let result;

  const isPayment = mode === PAYMENT_MODE.PAYMENT;
  const invalidMethods = getInvalidFutureUsagePaymentMethods(
    paymentMethodTypes
  );
  const minNotMet = !paymentMethodTypes.length;
  const hasInvalidMethods = invalidMethods.length > 0;
  const hasSetupFutureUsage = paymentIntentSetupFutureUsage && isPayment;

  if (hasSetupFutureUsage) {
    if (hasInvalidMethods) {
      const methodLabels = invalidMethods.map(
        (type) => PAYMENT_METHOD_LABEL_MAP[type]
      );

      result = {
        copy: `${SETUP_FUTURE_USAGE_CONFLICT_PAYMENT_METHOD_MESSAGE} Your current selection includes ${methodLabels.join(
          ", "
        )}.`,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
      };
    } else if (minNotMet) {
      result = {
        copy: SETUP_FUTURE_USAGE_MINIMUM_PAYMENT_METHOD_MESSAGE,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
      };
    }
  }

  return result;
};

/**
 * Validate tax_beahvior presence when omitted from price / tax_rates
 */
export const validateShortLink = ({
  shortLink,
  prices = [],
  products = [],
  coupons = [],
  taxRates = [],
  shippingRates = []
}) => {
  /**
   * ShortLink validation requirements
   *
   * conflictCurrencies: prices must be all the same currency
   * conflictIntervals: all recurring prices must be the same interval
   * zeroQuantity: prices have a minimum quantity of 1
   * zeroTotal: sum total must be greater than zero if all prices are non recurring
   * conflictCouponCurrencies: coupon currency must match price currency
   */
  const validations = {
    conflictCurrencies: {
      copy: "",
      url: "",
      param: "currency",
      fields: [STATE_KEYS.SHORT_LINK.PRIMARIES]
    },
    conflictIntervals: {
      copy: "",
      url: "",
      param: "interval",
      fields: [STATE_KEYS.SHORT_LINK.PRIMARIES]
    },
    zeroQuantity: {
      copy: "",
      url: "",
      param: "quantity",
      fields: [STATE_KEYS.SHORT_LINK.PRIMARIES]
    },
    zeroTotal: {
      copy: "",
      url: "",
      param: "unit_amount",
      fields: [STATE_KEYS.SHORT_LINK.PRIMARIES]
    },
    conflictCouponCurrencies: {
      copy: "",
      url: "",
      param: "currency",
      fields: [STATE_KEYS.SHORT_LINK.PRIMARIES, STATE_KEYS.SHORT_LINK.DISCOUNTS]
    },
    conflictPaymentMethodIntervals: {
      copy: "",
      url: "",
      param: "interval",
      fields: [
        STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES,
        STATE_KEYS.SHORT_LINK.PRIMARIES
      ]
    },
    conflictPaymentMethodCurrencies: {
      copy: "",
      url: "",
      param: "currency",
      fields: [
        STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES,
        STATE_KEYS.SHORT_LINK.PRIMARIES
      ]
    },
    conflictCaptureMethodPaymentMethod: {
      copy: "",
      url: "",
      param: "payment_method_types",
      fields: [
        STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES,
        STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA_CAPTURE_METHOD
      ]
    },
    conflictSetupFutureUsagePaymentMethod: {
      copy: "",
      url: "",
      param: "payment_method_types",
      fields: [
        STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES,
        STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA_SETUP_FUTURE_USAGE
      ]
    },
    conflictSubscriptionScheduleMode: {
      copy: "",
      url: "",
      param: "subscription_schedule",
      fields: [STATE_KEYS.SHORT_LINK.SUBSCRIPTION_SCHEDULE]
    },
    conflictShippingRateCurrencies: {
      copy: "",
      url: "",
      param: "currency",
      fields: [
        STATE_KEYS.SHORT_LINK.PRIMARIES,
        STATE_KEYS.SHORT_LINK.SHIPPING_OPTIONS
      ]
    },
    conflictShippingRateIntervals: {
      copy: "",
      url: "",
      param: "interval",
      fields: [
        STATE_KEYS.SHORT_LINK.PRIMARIES,
        STATE_KEYS.SHORT_LINK.SHIPPING_OPTIONS
      ]
    },
    conflictTaxBehavior: {
      copy: "",
      url: "",
      param: "tax_behavior",
      fields: [
        STATE_KEYS.SHORT_LINK.PRIMARIES,
        STATE_KEYS.SHORT_LINK.SHIPPING_OPTIONS
      ]
    },
    conflictPriceTaxBehavior: {
      copy: "",
      url: "",
      param: "tax_behavior",
      fields: [STATE_KEYS.SHORT_LINK.PRIMARIES]
    },
    missingTaxBehavior: {
      copy: "",
      url: "",
      param: "tax_behavior",
      fields: [
        STATE_KEYS.SHORT_LINK.PRIMARIES,
        STATE_KEYS.SHORT_LINK.SHIPPING_OPTIONS
      ]
    },
    requiresShipping: {
      copy: "",
      url: "",
      param: "allowed_countries",
      fields: [STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES]
    },
    minMaxTotal: {
      copy: "",
      url: "",
      param: [STATE_KEYS.SHORT_LINK.PRIMARIES]
    }
  };

  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES, []) || [];
  const hasPrimaries = primaries.length > 0;
  const flexPrices = get(shortLink, STATE_KEYS.SHORT_LINK.PRICES, []) || [];
  const autoTaxesEnabled = get(
    shortLink,
    STATE_KEYS.SHORT_LINK.AUTOMATIC_TAX_ENABLED,
    false
  );

  const discounts = get(shortLink, STATE_KEYS.SHORT_LINK.DISCOUNTS, []) || [];
  const paymentMethodTypes =
    get(shortLink, STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES, []) || [];

  const mode = getShortLinkMode(shortLink);

  const shippingCountries =
    get(shortLink, STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES, []) || [];
  const shippingWorldwide =
    get(shortLink, STATE_KEYS.SHORT_LINK.SHIPPING_WORLDWIDE) || false;
  const hasShippingAllowedCountries = shippingCountries.length > 0;
  const shippingEnabled = Boolean(
    hasShippingAllowedCountries || shippingWorldwide
  );
  /**
   * Validate all shipping options, dont just pull the first if theres only one
   */
  const shippingOptions =
    get(shortLink, STATE_KEYS.SHORT_LINK.SHIPPING_OPTIONS) || [];
  const shippings = reduceShippingOptionsRates(shippingOptions);
  const taxes = get(shortLink, STATE_KEYS.SHORT_LINK.TAX_RATES) || [];

  const {
    currencies,
    intervals,
    zeroQuantityProducts,
    incompletePrimaries,
    pricesMissingTaxBehaviors,
    priceTaxBehaviorIntervals
  } = reduceValidationReferences({
    primaries,
    products,
    prices,
    flexPrices,
    autoTaxesEnabled
  });

  const hasRecurringCharges = intervals.length > 0;
  const hasCurrencies = currencies.length > 0;
  const hasShippingRates = shippings.length > 0;

  /**
   * If there are any new prices we append their currencies and intervals to the reduced collections from the primaries
   * - Also manage their tax behaviors if auto taxes are enabled
   */
  const newPrices = get(shortLink, STATE_KEYS.SHORT_LINK.PRICES, []) || [];
  newPrices.forEach((price) => {
    if (
      price.recurring &&
      price.recurring.interval &&
      intervals.indexOf(price.recurring.interval) === -1
    ) {
      intervals.push(price.recurring.interval);
    }
    if (price.currency && currencies.indexOf(price.currency) === -1) {
      currencies.push(price.currency);
    }

    if (autoTaxesEnabled) {
      const taxBehavior = price.tax_behavior;
      const isMissing = isMissingTaxBehavior(taxBehavior);
      if (isMissing) {
        pricesMissingTaxBehaviors.push(price.uuid);
      }

      if (!priceTaxBehaviorIntervals[taxBehavior]) {
        priceTaxBehaviorIntervals[taxBehavior] = [];
      }
      priceTaxBehaviorIntervals[taxBehavior].push(price.uuid);
    }
  });

  const checkoutPreview = shortLinkCheckoutPreview({
    shortLink,
    products,
    prices,
    coupons,
    taxRates,
    shippingRates
  });

  /**
   * Validate sum total
   * - Show only for
   * -- all one time charges
   * -- when selections are complete
   */
  if (
    !checkoutPreview.total &&
    !intervals.length &&
    incompletePrimaries.length === 0 &&
    primaries.length > 0
  ) {
    validations.zeroTotal = {
      ...validations.zeroTotal,
      copy: ZERO_TOTAL_VALIDATION_MESSAGE,
      url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
    };
  }

  /**
   * Validate currencies
   */
  const currencyList = currencies.map((code) => code.toUpperCase()).join(", ");
  if (Array.isArray(currencies) && currencies.length > 1) {
    validations.conflictCurrencies = {
      ...validations.conflictCurrencies,
      copy: `${PRICE_CURRENCY_CONFLICT_VALIDATION_MESSAGE} Your current selection includes: ${currencyList}.`,
      url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
    };
  }

  /**
   * Validate intervals
   */
  if (Array.isArray(intervals) && intervals.length > 1) {
    validations.conflictIntervals = {
      ...validations.conflictIntervals,
      copy: `${INTERVAL_CONFLICT_VALIDATION_MESSAGE} Your current selection includes: ${intervals
        .map((interval) => startCase(INTERVAL_LABELS_MAP[interval]))
        .join(", ")}.`,
      url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
    };
  }

  /**
   * Validate Payment Method Intervals
   */
  const invalidRecurringPaymentMethods = getInvalidRecurringPaymentMethods(
    paymentMethodTypes
  );
  const hasInvalidRecurringPaymentMethodTypes =
    invalidRecurringPaymentMethods.length > 0;
  if (hasRecurringCharges && hasInvalidRecurringPaymentMethodTypes) {
    validations.conflictPaymentMethodIntervals = {
      ...validations.conflictPaymentMethodIntervals,
      ...getConflictPaymentMethodIntervals({
        paymentMethodTypes: invalidRecurringPaymentMethods,
        intervals
      })
    };
  }

  /**
   * Validate Payment Method currencies
   */
  if (
    Array.isArray(paymentMethodTypes) &&
    Array.isArray(currencies) &&
    paymentMethodTypes.length &&
    currencies.length
  ) {
    const paymentTypeCurrencyConflict = getPaymentTypeCurrencyConflict(
      paymentMethodTypes,
      currencies
    );

    if (!isEmpty(paymentTypeCurrencyConflict)) {
      validations.conflictPaymentMethodCurrencies = {
        ...validations.conflictPaymentMethodCurrencies,
        ...getConflictPaymentMethodCurrencies({
          paymentTypeCurrencyConflict
        })
      };
    }
  }

  /**
   * Validate payment intent data capture method
   */
  const paymentIntentCaptureMethod = get(
    shortLink,
    STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA_CAPTURE_METHOD
  );
  const paymentTypeCaptureMethodConflict = getCaptureMethodPaymentMethodConflict(
    {
      paymentMethodTypes,
      paymentIntentCaptureMethod,
      mode
    }
  );

  if (!isEmpty(paymentTypeCaptureMethodConflict)) {
    validations.conflictCaptureMethodPaymentMethod = {
      ...validations.conflictCaptureMethodPaymentMethod,
      ...paymentTypeCaptureMethodConflict
    };
  }

  /**
   * Validate payment intent setup future usage
   */
  const paymentIntentSetupFutureUsage = get(
    shortLink,
    STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA_SETUP_FUTURE_USAGE
  );
  const paymentTypeSetupFutureUsage = getSetupFutureUsagePaymentMethodConflict({
    paymentMethodTypes,
    paymentIntentSetupFutureUsage,
    mode
  });
  if (!isEmpty(paymentTypeSetupFutureUsage)) {
    validations.conflictSetupFutureUsagePaymentMethod = {
      ...validations.conflictSetupFutureUsagePaymentMethod,
      ...paymentTypeSetupFutureUsage
    };
  }

  /**
   * Validate subscription schedule / installments
   */
  const subscriptionSchedule = get(
    shortLink,
    STATE_KEYS.SHORT_LINK.SUBSCRIPTION_SCHEDULE
  );
  if (
    !isEmpty(subscriptionSchedule) &&
    mode !== PAYMENT_MODE.SUBSCRIPTION &&
    hasPrimaries
  ) {
    validations.conflictSubscriptionScheduleMode = {
      ...validations.conflictSubscriptionScheduleMode,
      copy: SUBSCRIPTION_SCHEDULE_MODE_MESSAGE,
      url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
    };
  }

  /**
   * Validate payment method requires shipping destination
   */
  SHIPPING_REQUIRED_PAYMENT_METHODS.forEach((type) => {
    const paymentMethodRequiresShipping =
      Array.isArray(paymentMethodTypes) &&
      paymentMethodTypes.indexOf(type) > -1;
    if (paymentMethodRequiresShipping && !shippingEnabled) {
      validations.requiresShipping = {
        ...validations.requiresShipping,
        copy: `The ${PAYMENT_METHOD_LABEL_MAP[type]} payment method requires that you collect shipping information. Set each country you ship to or select worldwide shipping.`,
        url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_METHODS_RESTRICTIONS
      };
    }
  });

  /**
   * Validate shipping rate requires shipping destination
   */
  const noDestinationsButHasRates = !shippingEnabled && shippings.length > 0;
  if (noDestinationsButHasRates) {
    validations.requiresShipping = {
      ...validations.requiresShipping,
      copy: `You have selected a shipping rate but set no valid shipping destinations. Set each country you ship to or select worldwide shipping.`,
      url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_METHODS_RESTRICTIONS
    };
  }

  /**
   * Validate payment method min max ranges
   */
  const paymentMethodsWithRange = paymentMethodTypes.filter(
    (type) => PAYMENT_METHODS_WITH_RANGE.indexOf(type) > -1
  );
  const hasRangeRestrictedMethods = paymentMethodsWithRange.length > 0;
  if (hasRangeRestrictedMethods) {
    validations.minMaxTotal = {
      ...validations.minMaxTotal,
      ...getMinMaxTotal({
        currencies,
        paymentMethodsWithRange,
        total: checkoutPreview.total
      })
    };
  }

  /**
   * Validate Quantities
   */
  const hasProductsWithZeroQuantity = zeroQuantityProducts.length > 0;
  if (hasProductsWithZeroQuantity) {
    validations.zeroQuantity = {
      ...validations.zeroQuantity,
      copy: `${ZERO_QUANTITY_VALIDATION_MESSAGE} Update the quantity for: ${zeroQuantityProducts.join(
        ", "
      )}.`,
      url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
    };
  }

  /**
   * Validate coupon currencies
   * - e.g. your price currencies are USD but your fixed amount off coupon is GBP
   */
  if (discounts.length > 0 && hasCurrencies) {
    validations.conflictCouponCurrencies = {
      ...validations.conflictCouponCurrencies,
      ...getConflictCoupons({
        discounts,
        coupons,
        currencies,
        currencyList
      })
    };
  }

  /**
   * Validate shipping rate currencies
   * - e.g. cost of shipping rate must match that of checkout denomination
   */
  if (hasShippingRates && hasCurrencies) {
    validations.conflictShippingRateCurrencies = {
      ...validations.conflictShippingRateCurrencies,
      ...getConflictShippingRates({
        shippings,
        shippingRates,
        currencies,
        currencyList
      })
    };
  }

  /**
   * Validate shipping rate intervals
   */
  if (hasShippingRates && hasRecurringCharges) {
    validations.conflictShippingRateIntervals = {
      ...validations.conflictShippingRateIntervals,
      copy: `${SHIPPING_RATE_INTERVAL_CONFLICT_VALIDATION_MESSAGE} Your current selection includes ${intervals
        .map((interval) => INTERVAL_LABELS_MAP[interval])
        .join(", ")} prices, which cannot have a shipping rate applied.`,
      url: LEARN_PATHS.PAYMENT_LINKS.PAYMENT_DETAILS_RESTRICTIONS
    };
  }

  /**
   * Validate missing tax behaviors
   */
  let shippingsMissingTaxBehaviors = [];
  if (autoTaxesEnabled && hasShippingRates) {
    shippingsMissingTaxBehaviors = getMissingShippingRateTaxBahaviors({
      shippings,
      shippingRates
    });
  }

  const hasMissingTaxBehaviors =
    pricesMissingTaxBehaviors.length || shippingsMissingTaxBehaviors.length;
  if (autoTaxesEnabled && hasMissingTaxBehaviors) {
    const missingTaxBehavior = getMissingTaxBehavior({
      priceIds: pricesMissingTaxBehaviors,
      shippingRateIds: shippingsMissingTaxBehaviors,
      prices,
      shippingRates
    });
    if (missingTaxBehavior) {
      validations.missingTaxBehavior = {
        ...validations.missingTaxBehavior,
        ...missingTaxBehavior
      };
    }
  }

  /**
   * Validate conflicting tax behaviors
   */
  if (autoTaxesEnabled) {
    const conflictTaxBehavior = getConflictTaxBehavior({
      hasShippingRates,
      priceTaxBehaviorIntervals,
      shippingRates,
      shippings
    });
    if (conflictTaxBehavior) {
      validations.conflictTaxBehavior = {
        ...validations.conflictTaxBehavior,
        ...conflictTaxBehavior
      };
    }
  } else if (taxes.length) {
    const conflictPriceTaxBehavior = getConflictPriceTaxBehavior({
      taxes,
      taxRates,
      priceTaxBehaviorIntervals
    });
    if (conflictPriceTaxBehavior) {
      validations.conflictPriceTaxBehavior = {
        ...validations.conflictPriceTaxBehavior,
        ...conflictPriceTaxBehavior
      };
    }
  }

  return validations;
};

export const reduceShortLinkValidations = (validations) =>
  Object.values(validations).reduce((memo, validation) => {
    if (validation && validation.copy) {
      memo.push(validation);
    }

    return memo;
  }, []);

const validateShortLinkSchema = async ({
  formValues,
  prices,
  products,
  coupons,
  taxRates,
  shippingRates,
  schema
}) => {
  let validResult = await validateSchema(schema, formValues);

  const linkValidations = validateShortLink({
    shortLink: formValues,
    prices,
    products,
    coupons,
    taxRates,
    shippingRates
  });

  const validations = reduceShortLinkValidations(linkValidations);

  if (validations.length > 0) {
    validResult.messages = validations;
  }

  const shortLinkPrimaries = get(
    formValues,
    STATE_KEYS.SHORT_LINK.PRIMARIES,
    []
  );

  const mode = get(formValues, STATE_KEYS.SHORT_LINK.MODE);
  const shortLinkPrices = get(formValues, STATE_KEYS.SHORT_LINK.PRICES) || [];
  const minProductPriceRequired =
    !shortLinkPrimaries.length &&
    !shortLinkPrices.length &&
    mode !== PAYMENT_MODE.SETUP;
  if (minProductPriceRequired) {
    validResult.error = "Need at least one primary or price";
  }

  if (shortLinkPrices.length) {
    shortLinkPrices.forEach((_, slpIx) => {
      const rootPath = `${STATE_KEYS.SHORT_LINK.PRICES}[${slpIx}]`;
      validResult = validatePrice(formValues, rootPath, validResult);
    });
  }

  /**
   * Validate redirect QS params
   */
  [STATE_KEYS.SHORT_LINK.SUCCESS_URL, STATE_KEYS.SHORT_LINK.CANCEL_URL].forEach(
    (stateKey) => {
      const stateValue = get(formValues, stateKey, "");
      const currentStateValueError = get(validResult, stateKey);
      if (stateValue && !currentStateValueError) {
        const newStateValueError = validateUrlQS(stateValue);
        if (newStateValueError) {
          set(validResult, stateKey, newStateValueError);
        }
      }
    }
  );

  /**
   * Validate receiver
   */
  const customerEmail = get(
    formValues,
    STATE_KEYS.SHORT_LINK.CUSTOMER_EMAIL,
    ""
  );
  if (customerEmail) {
    const validatedReceiver = await validateSchema(OPTIONAL_EMAIL_SCHEMA, {
      email: customerEmail
    });
    if (validatedReceiver.email) {
      set(
        validResult,
        STATE_KEYS.SHORT_LINK.CUSTOMER_EMAIL,
        validatedReceiver.email
      );
    }
  }

  return validResult;
};

export const validateCreateShortLinkFlexForm = ({
  prices,
  products,
  coupons,
  taxRates,
  shippingRates
}) => {
  return async (formValues) => {
    let validResult = await validateShortLinkSchema({
      formValues,
      prices,
      products,
      coupons,
      taxRates,
      shippingRates,
      schema: CREATE_SHORT_LINK_SCHEMA
    });

    const shortLinkPrices = get(formValues, STATE_KEYS.SHORT_LINK.PRICES) || [];
    if (!shortLinkPrices.length) {
      validResult.error = "Need at least one price";
    }

    if (shortLinkPrices.length) {
      shortLinkPrices.forEach((_, slpIx) => {
        const rootPath = `${STATE_KEYS.SHORT_LINK.PRICES}[${slpIx}]`;
        validResult = validatePrice(formValues, rootPath, validResult);
      });
    }

    return validResult;
  };
};

export const validateCreateShortLinkForm = ({
  prices,
  products,
  coupons,
  taxRates,
  shippingRates
}) => {
  return async (formValues) => {
    const result = await validateShortLinkSchema({
      formValues,
      prices,
      products,
      coupons,
      taxRates,
      shippingRates,
      schema: CREATE_SHORT_LINK_SCHEMA
    });
    return result;
  };
};

export const validateUpdateShortLinkForm = ({
  prices = [],
  products = [],
  coupons = [],
  taxRates = [],
  shippingRates = []
} = {}) => {
  return async (formValues) => {
    const result = await validateShortLinkSchema({
      formValues,
      prices,
      products,
      coupons,
      taxRates,
      shippingRates,
      schema: UPDATE_SHORT_LINK_SCHEMA
    });

    return result;
  };
};

/**
 * Pull the first image of every product
 */
export const getShortLinkImages = ({ shortLink, products = [] }) => {
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];

  return primaries.reduce((memo, primary) => {
    const productMatch = products.find(
      (product) => product.id === primary.product
    );

    if (productMatch) {
      const prodHasImages =
        productMatch && productMatch.images && productMatch.images.length > 0;
      if (prodHasImages) {
        memo = memo.concat(productMatch.images[0]);
      }
    }

    return memo;
  }, []);
};

export const shortLinkHasRecurringPrices = (shortLink) => {
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
  const prices = get(shortLink, STATE_KEYS.SHORT_LINK.PRICES) || [];

  const primaryWithInterval = primaries.find(({ interval }) => interval);
  const priceWithInterval = prices.find(
    ({ recurring }) => recurring && recurring.interval
  );
  return Boolean(primaryWithInterval || priceWithInterval);
};

export const prepareShortLinkShow = ({
  shortLink,
  subdomain,
  alias,
  products,
  prices,
  coupons,
  taxRates,
  shippingRates
}) => {
  const checkoutPreview = shortLinkCheckoutPreview({
    shortLink,
    products,
    prices,
    coupons,
    taxRates,
    shippingRates
  });
  const images = getShortLinkImages({
    shortLink,
    products,
    prices
  });

  const result = {
    id: shortLink.id,
    uuid: shortLink.uuid,
    status: shortLink.status,
    title: shortLink.title,
    livemode: shortLink.livemode,
    description: shortLink.description,
    discount: get(checkoutPreview, "checkout.discountItems[0].value", null),
    shortId: shortLink.short_id,
    amount: checkoutPreview.total,
    previewUrl: getShortLinkCheckoutUrl({ id: shortLink.short_id }),
    createdAt: formatTime(shortLink.created_at),
    customerEmail: get(shortLink, STATE_KEYS.SHORT_LINK.CUSTOMER_EMAIL, ""),
    images,
    ownerEmail: get(shortLink, "owner.email", ""),
    // Pseudo reference for table
    copy: true,
    analytics: true
  };

  const variations = getShortLinkCheckoutUrlVariations({
    subdomain,
    alias,
    shortId: shortLink.short_id
  });
  result.subdomainOriginal = variations.subdomain.original;
  result.standardOriginal = variations.standard.original;

  /**
   * Mode
   */
  const mode = getShortLinkMode(shortLink);
  const isSetup = mode === PAYMENT_MODE.SETUP;
  result.mode = mode;

  /**
   * Attachments
   */
  const attachmentsCount = get(shortLink, STATE_KEYS.SHORT_LINK.ATTACHMENTS, [])
    .length;
  result.hasAttachments =
    attachmentsCount > 0 ? TOGGLE_STATE.YES : TOGGLE_STATE.NO;
  /**
   * Trial
   */
  const hasTrial =
    get(shortLink, STATE_KEYS.SHORT_LINK.TRIAL_PERIOD_DAYS) ||
    get(shortLink, STATE_KEYS.SHORT_LINK.TRIAL_END);
  result.hasTrial = hasTrial ? TOGGLE_STATE.YES : TOGGLE_STATE.NO;

  /**
   * Formatted value
   */
  const formattedValue = get(checkoutPreview, "checkout.banner.label");
  const currency = get(
    shortLink,
    `${STATE_KEYS.SHORT_LINK.PRIMARIES}[0].currency`,
    ""
  );
  result.currency = currency.toUpperCase();
  result.formattedValue = isSetup
    ? startCase(mode)
    : hasTrial
    ? formatUnitAmount({
        amount: checkoutPreview.total,
        currency
      })
    : formattedValue;

  result.formattedValueNoSymbol =
    !isSetup && formattedValue ? formattedValue.slice(1) : "";
  result.formattedValueSublabel = !isSetup
    ? get(checkoutPreview, "checkout.banner.sublabel") || "One time"
    : "";

  return result;
};

export const prepareShortLinkList = ({
  subdomain,
  alias,
  shortLinks,
  prices,
  products,
  coupons,
  taxRates,
  shippingRates,
  shouldOrder
}) => {
  const ctxModels = shouldOrder ? orderByDate(shortLinks) : shortLinks;
  return ctxModels.map((shortLink) => {
    return prepareShortLinkShow({
      subdomain,
      alias,
      shortLink,
      products,
      prices,
      coupons,
      taxRates,
      shippingRates
    });
  });
};

const SHORT_LINK_PRIMARY_KEYS = [
  "price",
  "product",
  "currency",
  "interval",
  "quantity",
  "adjustable_quantity"
];

const sanitizePrimary = (primary) =>
  Object.keys(primary).reduce((memo, key) => {
    if (primary[key] && SHORT_LINK_PRIMARY_KEYS.indexOf(key) > -1) {
      memo[key] = primary[key];
    }
    return memo;
  }, {});

const prepareShortLinkPrimaries = ({ primaries, prices }) =>
  primaries.map((primary) => {
    const primaryResult = sanitizePrimary(primary);

    const modelMatch = prices.find((price) => price.id === primaryResult.price);
    if (!modelMatch) {
      throw new Error("No matching price when preparing short link");
    }
    primaryResult.currency = modelMatch.currency;
    const interval = modelMatch.recurring && modelMatch.recurring.interval;
    if (interval) {
      primaryResult.interval = interval;
    }

    return primaryResult;
  });

export const reduceShortLinkDiscounts = (discounts) => {
  let result = null;

  if (Array.isArray(discounts) && discounts.length) {
    if (discounts.length > 1) {
      throw new Error("Only one discount currently supported");
    }
    const discount = discounts[0];
    if (discount) {
      const { coupon, promotion_code } = discount;
      if (coupon) {
        result = [{ coupon }];
      } else if (promotion_code) {
        result = [{ promotion_code }];
      }
    }
  }

  return result;
};

export const invalidShortLinkPaymentMethodForFutureUsage = (values) => {
  const paymentMethodTypes =
    get(values, STATE_KEYS.SHORT_LINK.PAYMENT_METHOD_TYPES) || [];

  let result = false;
  if (Array.isArray(paymentMethodTypes) && paymentMethodTypes.length > 0) {
    for (let typeIx = 0; typeIx < paymentMethodTypes.length; typeIx++) {
      const type = paymentMethodTypes[typeIx];
      if (!SETUP_FUTURE_USAGE_PAYMENT_METHOD_TYPES[type]) {
        result = true;
        break;
      }
    }
  }
  return result;
};

export const prepareCreateShortLink = ({
  values,
  prices,
  products,
  merchantAccount,
  coupons,
  promotionCodes,
  taxRates,
  shippingRates,
  attachments
}) => {
  const shortLink = cloneDeep(omit(values, [FORM_KEY]));
  const title = getShortLinkPreviewTitle({
    shortLink,
    products,
    prices,
    coupons,
    taxRates,
    shippingRates,
    promotionCodes
  });

  const images = getShortLinkProductPrimariesImages({
    shortLink,
    products
  });

  const result = {
    shortLink: {
      ...shortLink,
      title: title.split("|")[0].trim()
    },
    metadata: buildMetadataData({
      title,
      shortLink,
      merchantAccount,
      images,
      products
    })
  };

  /**
   * Process products and prices
   * - ensure chosen products / primaries have a valid:
   * (1) currency
   * (2) interval
   */
  const primaries = get(
    result,
    `shortLink.${STATE_KEYS.SHORT_LINK.PRIMARIES}`,
    []
  );
  const shortLinkPrimaries =
    primaries &&
    primaries.filter((primary) => {
      return primary.product && primary.price && primary.quantity;
    });
  if (shortLinkPrimaries && shortLinkPrimaries.length) {
    result.shortLink.data.product.primaries = prepareShortLinkPrimaries({
      primaries: shortLinkPrimaries,
      prices
    });
  } else {
    delete result.shortLink.data.product.primaries;
  }

  /**
   * Process discounts and sanitize selections
   */
  const shortLinkDiscounts = result.shortLink.data.product.checkout.discounts;
  if (shortLinkDiscounts && shortLinkDiscounts.length) {
    result.shortLink.data.product.checkout.discounts = shortLinkDiscounts.reduce(
      (memo, { coupon, promotion_code }) => {
        if (coupon && promotion_code) {
          throw new Error(
            "Can't co-locate coupon and promotion code on same discount object"
          );
        } else if (coupon) {
          memo.push({ coupon });
        } else if (promotion_code) {
          memo.push({ promotion_code });
        }
        return memo;
      },
      []
    );
    /**
     * Set to be false when coupons already present
     * - Similar to API logic for getSessionDiscounts
     */
    result.shortLink.data.product.checkout.allow_promotion_codes = false;
  } else {
    delete result.shortLink.data.product.checkout.discounts;
  }

  /**
   * Process tax rates and allow only valid tax ids
   */
  const shortLinkTaxRates = result.shortLink.data.product.checkout.tax_rates;
  if (shortLinkTaxRates && shortLinkTaxRates.length) {
    result.shortLink.data.product.checkout.tax_rates = shortLinkTaxRates.filter(
      (id) => isTaxRateId(id)
    );
  } else {
    delete result.shortLink.data.product.checkout.tax_rates;
  }

  /**
   * Sanitize customer fields if not present
   */
  if (!result.shortLink.data.product.checkout.customer) {
    delete result.shortLink.data.product.checkout.customer;
  }
  if (!result.shortLink.data.product.checkout.customer_email) {
    delete result.shortLink.data.product.checkout.customer_email;
  }

  /**
   * If shipping worldwide - delete shipping address collection
   */
  if (result.shortLink.data.product.checkout.shipping_worldwide) {
    delete result.shortLink.data.product.checkout.shipping_address_collection;
  }
  /**
   * Process shipping options rates and allow only valid shipping ids
   */
  const shortLinkShippingOptions =
    result.shortLink.data.product.checkout.shipping_options;
  if (shortLinkShippingOptions && shortLinkShippingOptions.length) {
    result.shortLink.data.product.checkout.shipping_options = shortLinkShippingOptions.reduce(
      (memo, { shipping_rate }) => {
        if (isShippingRateId(shipping_rate)) {
          memo.push({
            shipping_rate
          });
        }
        return memo;
      },
      []
    );
  } else {
    delete result.shortLink.data.product.checkout.shipping_options;
  }

  /**
   * Process attachments and allow only ones present and unique by uuid
   */
  const shortLinkAttachments = result.shortLink.data.product.attachments;
  if (shortLinkAttachments && shortLinkAttachments.length) {
    result.shortLink.data.product.attachments = uniqBy(
      shortLinkAttachments.filter((selectedUUID) =>
        attachments.find((attachment) => attachment.uuid === selectedUUID)
      ),
      "uuid"
    );
  } else {
    delete result.shortLink.data.product.attachments;
  }

  /**
   * Process fields and allow only ones present
   */
  const shortLinkFields = result.shortLink.data.fields;
  if (shortLinkFields) {
    Object.values(SHORT_LINK_FIELD_KEYS).forEach((fieldKey) => {
      if (shortLinkFields[fieldKey] && shortLinkFields[fieldKey].length) {
        result.shortLink.data.fields[fieldKey] = shortLinkFields[
          fieldKey
        ].filter(({ uid }) => {
          return SHORT_LINK_FIELD_UIDS.indexOf(uid) > -1;
        });
      } else {
        delete result.shortLink.data.fields[fieldKey];
      }
    });
    if (isEmpty(result.shortLink.data.fields)) {
      delete result.shortLink.data.fields;
    }
  }

  /**
   * Payment intent data
   * - capture_method (manual | authorized)
   * - setup_future_usage (on_session | off_session)
   */
  const paymentIntentData =
    result.shortLink.data.product.checkout.payment_intent_data;
  if (paymentIntentData) {
    const captureMethod = paymentIntentData.capture_method;
    const validMethod =
      Object.values(CAPTURE_METHOD).indexOf(captureMethod) > -1;
    if (!validMethod) {
      delete result.shortLink.data.product.checkout.payment_intent_data
        .capture_method;
    }

    const setupFutureUsage = paymentIntentData.setup_future_usage;
    const validFutureUsageType =
      Object.values(SETUP_FUTURE_USAGE).indexOf(setupFutureUsage) > -1;
    if (!validFutureUsageType) {
      delete result.shortLink.data.product.checkout.payment_intent_data
        .setup_future_usage;
    }

    if (isEmpty(result.shortLink.data.product.checkout.payment_intent_data)) {
      delete result.shortLink.data.product.checkout.payment_intent_data;
    }
  }

  /**
   * Process trial periods and allow ones which are enabled
   */
  const trialPeriodDaysFormPath = `${FORM_KEY}.${TRIAL_PERIOD_DAYS}`;
  const trialPeriodDaysActive = get(values, trialPeriodDaysFormPath);
  const trialPeriodDaysValue = get(
    values,
    STATE_KEYS.SHORT_LINK.TRIAL_PERIOD_DAYS
  );

  const trialPeriodEndFormPath = `${FORM_KEY}.${TRIAL_END}`;
  const trialPeriodEndActive = get(values, trialPeriodEndFormPath);
  const trialPeriodEndValue = get(values, STATE_KEYS.SHORT_LINK.TRIAL_END);
  const hasTrialDays = trialPeriodDaysActive && trialPeriodDaysValue;
  const hasTrialEnd = trialPeriodEndActive && trialPeriodEndValue;
  const hasRecurring = shortLinkHasRecurringPrices(values);
  const trialSet = hasTrialDays || hasTrialEnd;
  if (hasRecurring && trialSet) {
    if (hasTrialDays) {
      result.shortLink.data.product.checkout.trial_period_days = trialPeriodDaysValue;
      delete result.shortLink.data.product.checkout.trial_end;
    } else {
      result.shortLink.data.product.checkout.trial_end = trialPeriodEndValue;
      delete result.shortLink.data.product.checkout.trial_period_days;
    }
  } else {
    delete result.shortLink.data.product.checkout.trial_period_days;
    delete result.shortLink.data.product.checkout.trial_end;
  }

  /**
   * Process flex prices
   */
  const shortLinkPrices = result.shortLink.data.product.prices;
  if (shortLinkPrices && shortLinkPrices.length) {
    const preparedPrices = shortLinkPrices
      .map((shortLinkPrice) => preparePriceFromValues(shortLinkPrice))
      .filter(({ unit_amount }) => unit_amount > 0);

    if (preparedPrices.length > 0) {
      result.shortLink.data.product.prices = preparedPrices;
    } else {
      delete result.shortLink.data.product.prices;
    }
  } else {
    delete result.shortLink.data.product.prices;
  }

  /**
   * Process subscription schedules
   * - only allow for subscription mode short links
   * - require that an iteation count have a min value of 1
   */
  const mode = getShortLinkMode(result.shortLink);
  const disableSubscriptionSchedule = mode !== PAYMENT_MODE.SUBSCRIPTION;
  const iterationCount = get(
    result,
    "shortLink.data.product.subscription_schedule.phases[0].iterations"
  );
  if (disableSubscriptionSchedule || !iterationCount) {
    delete result.shortLink.data.product.subscription_schedule;
  }

  return result;
};

/**
 * prepareUnauthedCreateShortLink
 * - prepre shortLink for creation but override the cancel and success urls for the context of the unauthed builder
 * @param {Object} props
 */
export const prepareUnauthedCreateShortLink = (props) => {
  const result = prepareCreateShortLink(props);

  /**
   * Cancel - go back to the success created page
   */
  const cancelUrl = `${getConfig("WEB_CLIENT_ROOT")}${
    PATHS.FEATURES_LINKS_BUILDER
  }/${result.shortLink.short_id}`;
  result.shortLink.data.cancel_url = cancelUrl;

  /**
   * Success - go to the unauthed demo checkout success page
   */
  const successUrl = `${getConfig("WEB_CLIENT_ROOT")}${
    PATHS.TEST_CHECKOUT_SUCCESS_DEMO
  }`;
  result.shortLink.data.success_url = successUrl;

  return result;
};

export const fetchTestLink = async ({ apiKey, shortId }) => {
  const url = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/test/short-links/short-id/${shortId}`;

  try {
    const headers = {
      [X_API_KEY]: apiKey
    };
    const { data } = await axios({
      method: "get",
      url,
      headers
    });

    return { shortLink: data };
  } catch (error) {
    captureException(error);
    console.error("[ERROR] fetchTestLink error %s with error %s", error);
    return error;
  }
};

/**
 * Check for the minimum presence of values for short link attribution like application and merchant account
 */
export const shortLinkFormLoading = ({
  loading,
  application,
  merchantAccount
}) => loading || isEmpty(application) || isEmpty(merchantAccount);

export const getShortLinkProductPrimariesImages = ({ shortLink, products }) => {
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
  return primaries.reduce((memo, primary) => {
    const productMatch = products.find(({ id }) => id === primary.product);
    if (productMatch) {
      if (productMatch.images && productMatch.images.length) {
        memo = memo.concat(productMatch.images);
      }
    }
    return memo;
  }, []);
};

export const getDefaultShortLinkMetadataDescription = ({
  shortLink,
  products
}) => {
  let description = "";
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
  for (let primIx = 0; primIx < primaries.length; primIx++) {
    const primary = primaries[primIx];
    const productMatch = products.find(({ id }) => id === primary.product);
    if (productMatch && productMatch.description) {
      description = productMatch.description;
    }
  }

  return description;
};

export const reduceShortLinkReferences = (shortLink) => {
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
  const prices = uniq(primaries.map(({ price }) => price));
  const products = uniq(primaries.map(({ product }) => product));

  /**
   * Discounts - (coupons / promotion_codes)
   */
  const discounts = get(shortLink, STATE_KEYS.SHORT_LINK.DISCOUNTS) || [];
  /**
   * Taxes
   */
  const taxRates = get(shortLink, STATE_KEYS.SHORT_LINK.TAX_RATES) || [];
  const dynamicTaxRates =
    get(shortLink, STATE_KEYS.SHORT_LINK.DYNAMIC_TAX_RATES) || [];
  /**
   * Shipping Rtes
   */
  const shippingOptions =
    get(shortLink, STATE_KEYS.SHORT_LINK.SHIPPING_OPTIONS) || [];
  const shippingRates = reduceShippingOptionsRates(shippingOptions);

  const {
    coupons,
    promotion_codes: promotionCodes
  } = reduceCouponsPromotionCodes(discounts);
  const allTaxRates = uniq(compact([...taxRates, ...dynamicTaxRates]));

  return {
    prices,
    products,
    coupons,
    promotionCodes,
    taxRates: allTaxRates,
    shippingRates: shippingRates
  };
};

export const getShortLinkProductMeta = (shortLink, products) => {
  const result = SHORT_LINK_META_KEYS.reduce((memo, key) => {
    if (shortLink[key]) {
      memo[key] = shortLink[key];
    }
    return memo;
  }, {});

  const references = reduceShortLinkReferences(shortLink);

  for (let refIx = 0; refIx < references.products.length; refIx++) {
    const prodId = references.products[refIx];
    const productMatch = products.find(({ id }) => id === prodId);

    if (productMatch) {
      if (productMatch.name) {
        result.title = productMatch.name;
      }
      if (productMatch.description) {
        result.description = productMatch.description;
      }

      break;
    }
  }

  return result;
};

/**
 * We need to strictly enfoce API url structure regardless of dynamic inputs like
 * - test
 * - subdomain
 * - alias
 * - setup
 * - pre
 * Valid output examples
 * - https://priceblocs.com/v1/links/123
 * - https://priceblocs.com/v1/test/links/123
 * - https://priceblocs.com/v1/co/123
 * - https://priceblocs.com/v1/test/co/123
 */
const API_URL_FRAGMENTS = {
  TEST: "test",
  LINKS: "links",
  CHECKOUTS: "co"
};
export const getCheckoutAPIURL = ({ url, id }) => {
  const urlParts = [`${process.env.NEXT_PUBLIC_API_ROOT}/v1`];
  if (isTestRoute(url)) {
    urlParts.push(API_URL_FRAGMENTS.TEST);
  }

  if (new RegExp(`/${API_URL_FRAGMENTS.LINKS}`).test(url)) {
    urlParts.push(API_URL_FRAGMENTS.LINKS);
  } else if (new RegExp(`/${API_URL_FRAGMENTS.CHECKOUTS}`).test(url)) {
    urlParts.push(API_URL_FRAGMENTS.CHECKOUTS);
  }
  urlParts.push(id);

  return urlParts.join("/");
};

export const isSetupShortLink = (shortLink) => {
  const mode = get(shortLink, STATE_KEYS.SHORT_LINK.MODE);
  return mode === PAYMENT_MODE.SETUP;
};

/**
 * NOTE: Could move to API
 */
const FIELD_INITIAL_VALUES = {
  [SHORT_LINK_FIELDS.TIN]: { type: "", value: "", code: "" }
};

const FIELD_VALIDATORS = {
  [SHORT_LINK_FIELDS.TIN]: ({ value, code, type }, currentValidations) => {
    const result = { ...currentValidations };
    if (!type && !result.type) {
      result.type = "Type is required";
    }
    if (!value && !result.value) {
      result.value = "Value is required";
    }
    if (!code && !result.code) {
      result.code = "Code is required";
    }

    return result;
  }
};

export const getShortLinkDynamicFieldsInitialValues = ({
  fields,
  stripe,
  shortId
}) => {
  return fields.reduce(
    (memo, field) => {
      memo[field.uid] = FIELD_INITIAL_VALUES[field.uid] || "";

      return memo;
    },
    {
      stripe,
      shortId
    }
  );
};

export const validateDynamicShortLinkFields = (fields) => {
  return async (formValues) => {
    const validatedFields = fields.reduce((memo, { uid, label, required }) => {
      const currentVal = formValues[uid];
      if (required && isEmpty(formValues[uid])) {
        memo[uid] = `${label} is required`;
      } else if (currentVal && FIELD_VALIDATORS[uid]) {
        const validations = FIELD_VALIDATORS[uid](currentVal, memo[uid]);
        if (!isEmpty(validations)) {
          memo[uid] = validations;
        }
      }

      return memo;
    }, {});

    return validatedFields;
  };
};

export const isAuthorizedShortLink = (shortLink) =>
  get(shortLink, STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA_CAPTURE_METHOD) ===
  CAPTURE_METHOD.MANUAL;

export const isOffSessionShortLink = (shortLink) =>
  get(
    shortLink,
    STATE_KEYS.SHORT_LINK.PAYMENT_INTENT_DATA_SETUP_FUTURE_USAGE
  ) === SETUP_FUTURE_USAGE.OFF_SESSION;

export const getInterstitialTitleCollection = (shortLink) => {
  const isAuthorizedPayment = isAuthorizedShortLink(shortLink);

  return isAuthorizedPayment
    ? SHORT_LINK_INTERSTITIAL_TITLE_COLLECTION.AUTHORIZED
    : SHORT_LINK_INTERSTITIAL_TITLE_COLLECTION.STANDARD;
};

export const getInterstitialIcon = (shortLink) =>
  isAuthorizedShortLink(shortLink) ? "flashTimer" : "shield";

export const getShortLinkOptions = ({ links, user }) =>
  links.map(({ title, short_id, test_short_id, owner }) => ({
    source: PLATFORM_SOURCE.LINK,
    mine: get(user, "uuid") === get(owner, "uuid"),
    name: title,
    id: short_id,
    testId: test_short_id
  }));

export const getShortLinkMode = (shortLink) => {
  let mode = get(shortLink, STATE_KEYS.SHORT_LINK.MODE);
  if (mode !== PAYMENT_MODE.SETUP) {
    const hasRecurring = shortLinkHasRecurringPrices(shortLink);
    mode = hasRecurring ? PAYMENT_MODE.SUBSCRIPTION : PAYMENT_MODE.PAYMENT;
  }
  return mode;
};
