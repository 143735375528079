import ENTITIES, {
  TEMPLATE_PAGE_TYPE
} from "components/FormerEditor/common/constants/entity";
import { STATUS } from "utils/constants/ui";

export const DEFAULT_PAGE_TITLE = "New page";
export const DEFAULT_PRICING_PAGE_TITLE = "Pricing page";
export const DEFAULT_TIP_PAGE_TITLE = "Tip page";
export const DEFAULT_CAMPAIGN_TITLE = "Payment campaign";
export const DEFAULT_CHECKOUT_TITLE = "Checkout form page";
export const DEFAULT_STORE_TITLE = "Store page";
const DASHBOARD = "dashboard";
const ANALYTICS = "analytics";

export const TEMPLATE_PAGE_TYPE_TITLE = {
  [TEMPLATE_PAGE_TYPE.PRICING]: DEFAULT_PRICING_PAGE_TITLE,
  [TEMPLATE_PAGE_TYPE.TIP]: DEFAULT_TIP_PAGE_TITLE,
  [TEMPLATE_PAGE_TYPE.FORM]: DEFAULT_CHECKOUT_TITLE,
  [TEMPLATE_PAGE_TYPE.STORE]: DEFAULT_STORE_TITLE
};

export const MANIFEST_TABS = {
  DASHBOARD,
  ANALYTICS
};

const LIVE = "live";
const OFFLINE = "offline";
const ACTIVE = "active";
const INACTIVE = "inactive";
const ARCHIVED = "archived";

export const MANIFEST_STATUS = {
  LIVE,
  OFFLINE,
  ACTIVE,
  INACTIVE,
  ARCHIVED
};

export const MANIFEST_STATUS_THEME = {
  [MANIFEST_STATUS.LIVE]: STATUS.SUCCESS,
  [MANIFEST_STATUS.OFFLINE]: STATUS.DEFAULT,
  [MANIFEST_STATUS.ACTIVE]: STATUS.DEFAULT,
  [MANIFEST_STATUS.INACTIVE]: STATUS.WARNING,
  [MANIFEST_STATUS.ARCHIVED]: STATUS.DANGER
};

const PRICING = "pricing";
const FORM = "form";
const STORE = "store";
const PAYMENT = "payment";
const SOCIAL = "social";

export const MANIFEST_CONTEXT_TYPE = {
  PRICING,
  FORM,
  STORE,
  PAYMENT,
  SOCIAL
};

const MANIFEST_CONTEXT_COMMON_ENTITIES = [
  ENTITIES.NAVIGATION,
  ENTITIES.FOOTER,
  ENTITIES.BANNER
];

export const MANIFEST_CONTEXT_TYPE_ENTITIES = {
  [MANIFEST_CONTEXT_TYPE.PRICING]: [
    ...MANIFEST_CONTEXT_COMMON_ENTITIES,
    ENTITIES.PRICING_TABLE,
    ENTITIES.FEATURE_COMPARISON
  ],
  [MANIFEST_CONTEXT_TYPE.FORM]: [
    ENTITIES.NAVIGATION,
    ENTITIES.FOOTER,
    ENTITIES.FORM
  ],
  [MANIFEST_CONTEXT_TYPE.STORE]: [
    ...MANIFEST_CONTEXT_COMMON_ENTITIES,
    ENTITIES.PRODUCT_COLLECTION
  ],
  [MANIFEST_CONTEXT_TYPE.PAYMENT]: [
    ...MANIFEST_CONTEXT_COMMON_ENTITIES,
    ENTITIES.PAYMENT
  ],
  [MANIFEST_CONTEXT_TYPE.SOCIAL]: [
    ...MANIFEST_CONTEXT_COMMON_ENTITIES,
    ENTITIES.SOCIAL_PROFILE
  ]
};

/**
 * ================
 * Logic type
 * ================
 * - Determines the conditions under which checkout button should be enabled
 * - Default is that checkout is required
 * - Optional that both default (constant) and selected (combination) are required
 * - No checkout (i.e. form submission only) not yet supported
 */
const CHECKOUT_REQUIRED = "checkout_required";
const CONSTANT_AND_SELECTED_CHECKOUT_REQUIRED =
  "CONSTANT_AND_SELECTED_CHECKOUT_REQUIRED";
const CHECKOUT_NOT_REQUIRED = "checkout_not_required";
// Legacy types have been deprecated
const CONDITIONAL = "conditional";
const UNCONDITIONAL = "unconditional";

export const MANIFEST_LOGIC_TYPE_MAP = {
  [CONDITIONAL]: CHECKOUT_REQUIRED,
  [UNCONDITIONAL]: CHECKOUT_REQUIRED,
  [CHECKOUT_REQUIRED]: CHECKOUT_REQUIRED,
  [CONSTANT_AND_SELECTED_CHECKOUT_REQUIRED]: CONSTANT_AND_SELECTED_CHECKOUT_REQUIRED,
  [CHECKOUT_NOT_REQUIRED]: CHECKOUT_NOT_REQUIRED
};

export const MANIFEST_LOGIC_TYPE = {
  CHECKOUT_REQUIRED,
  CONSTANT_AND_SELECTED_CHECKOUT_REQUIRED,
  CHECKOUT_NOT_REQUIRED
};

const IDENTITY = "identity";
const FILE = "file";
const ADDRESS = "address";
const LEGAL = "legal";

export const RESTRICTED_FIELDS = {
  IDENTITY,
  FILE,
  ADDRESS,
  LEGAL
};

export const getManifestLogicTypeOptions = ({ hasConstant, hasCombos }) => {
  return [
    {
      label: "Required",
      uid: MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED,
      description: "Minimum of one checkout value must be present in the cart."
    },
    {
      label: "Default and selected",
      uid: MANIFEST_LOGIC_TYPE.CONSTANT_AND_SELECTED_CHECKOUT_REQUIRED,
      description:
        "Both a default and selected checkout value must be present in the cart.",
      disabled: !(hasConstant && hasCombos)
    }
    // {
    //   label: "Not required",
    //   uid: MANIFEST_LOGIC_TYPE.CHECKOUT_NOT_REQUIRED,
    //   description: "No checkout value needs to be in the cart. This setting",
    //   disabled: true
    // }
  ];
};
