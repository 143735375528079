import get from "lodash/get";
import {
  DEFERRED_KEY,
  MERCHANT_ACCOUNT_AUTH_ACTION,
  MERCHANT_ACCOUNT_STATUS
} from "./constants/merchantAccount";
import { getCheckoutDisabledMessage } from "./stripe";

export const hasConnectedTestKeys = (merchantAccount) =>
  Boolean(merchantAccount.test_secret_key && merchantAccount.test_client_key);

export const isDeferredAccountMerchantAccount = (merchantAccount) =>
  merchantAccount.status === MERCHANT_ACCOUNT_STATUS.DEFERRED;

export const getAuthAction = ({
  detailsSubmitted,
  chargesEnabled,
  isDeferredAccount,
  authRequired
}) => {
  let result = null;
  const authRequiredEquivalent =
    detailsSubmitted && chargesEnabled && isDeferredAccount;
  if (authRequired || authRequiredEquivalent) {
    result = MERCHANT_ACCOUNT_AUTH_ACTION.REAUTH;
  } else if (!detailsSubmitted) {
    result = isDeferredAccount
      ? MERCHANT_ACCOUNT_AUTH_ACTION.COMPLETE_WITH_CONNECT
      : MERCHANT_ACCOUNT_AUTH_ACTION.REVIEW_ON_DASHBOARD;
  } else if (!chargesEnabled) {
    result = MERCHANT_ACCOUNT_AUTH_ACTION.REVIEW_ON_DASHBOARD;
  }

  return result;
};

/**
 * Note: These derived attributes are a little overloaded to reduce the need for multiple utils used across components
 * - It is also tailored towards Stripe - other merchant account capabilities for "detailsSubmitted" and "chargesEnabled" would need to be made consistent
 * @param {Object} merchantAccount
 * @returns
 */
export const getAccountConnectStatusProps = (merchantAccount) => {
  const account = get(merchantAccount, "account");
  const status = get(merchantAccount, "status");
  const serviceUid = get(merchantAccount, "service_uid");
  const detailsSubmitted = get(account, "details_submitted") || false;
  const chargesEnabled = get(account, "charges_enabled") || false;
  const uuid = get(merchantAccount, "uuid");
  const clientKey = get(merchantAccount, "client_key");
  const testModeEnabled = Boolean(get(merchantAccount, "test_client_key"));
  const allDisabled = !detailsSubmitted && !chargesEnabled;
  const chargesDisabled = !detailsSubmitted && !chargesEnabled;
  const allEnabled = detailsSubmitted && chargesEnabled;

  const authRequired = status === MERCHANT_ACCOUNT_STATUS.AUTH_REQUIRED;
  const hasDeferredApiKey = clientKey === DEFERRED_KEY.CLIENT_KEY;
  const isDeferredAccount =
    status === MERCHANT_ACCOUNT_STATUS.DEFERRED || hasDeferredApiKey;
  const authRequiredEquivalent =
    detailsSubmitted && chargesEnabled && isDeferredAccount;

  const authAction = getAuthAction({
    detailsSubmitted,
    chargesEnabled,
    isDeferredAccount,
    authRequired
  });
  const showBanner = Boolean(authAction);

  return {
    merchantAccount,
    account,
    serviceUid,
    uuid,
    allDisabled,
    allEnabled,
    detailsNeeded: !detailsSubmitted,
    status,
    authRequired,
    authRequiredEquivalent,
    isDeferredAccount,
    detailsSubmitted,
    chargesDisabled,
    chargesEnabled,
    showBanner,
    authAction,
    testModeEnabled,
    ...getCheckoutDisabledMessage({
      detailsSubmitted,
      chargesEnabled,
      status
    })
  };
};
