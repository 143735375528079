import Color from "color";
import { COLORS } from "utils/styles";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
export const DEFAULT_SYSTEM_FONT_FAMILY = "system-ui";
export const DEFAULT_FONT_FAMILY = "Avenir Next";

export const DEFAULT_THEME = {
  font: {
    family: DEFAULT_FONT_FAMILY,
    variants: []
  },
  text: {
    primary: COLORS.primary,
    secondary: COLORS.secondary
  },
  color: {
    primary: COLORS.blue,
    secondary: COLORS.blue_hover,
    highlight: COLORS.emphasis,
    danger: COLORS.danger
  }
};

export const isSystemFont = (family) =>
  family && [DEFAULT_FONT_FAMILY].indexOf(family) >= 0;

export const getStandardVariants = (variants) => {
  if (variants) {
    if (variants.indexOf("regular") > -1) {
      return [];
    } else {
      return variants.filter((variant) => !variant.match(/italic/));
    }
  } else {
    return [];
  }
};

export const getFontFamilyUrl = ({ family, variants }) => {
  if (family && !isSystemFont(family)) {
    const GOOGLE_FONTS_ROOT = `https://fonts.googleapis.com/css2`;

    let processedFamily = family.trim().replace(/ /g, "+");
    if (variants && variants.length) {
      const weights = `:wght@${variants.join(";")}`;
      processedFamily += weights;
    }

    return `${GOOGLE_FONTS_ROOT}?family=${processedFamily}&display=swap`;
  } else {
    return "";
  }
};

export const getWebFontConfig = ({ family, variants }) => {
  let result = family;
  if (variants && variants.length) {
    const weights = `:${variants.join(",")}`;
    result += weights;
  }
  return result;
};

export const getBrandTheme = (config) => {
  const theme = merge(cloneDeep(DEFAULT_THEME), config);

  return {
    "font-family": theme.font.family,
    "bg-primary": theme.color.primary,
    "bg-primary--hover": theme.color.secondary,
    "bg-primary--fade": theme.color.secondary,
    "color-primary": theme.color.primary,
    "color-highlight": theme.color.highlight,
    "color-text--primary": theme.text.primary,
    "color-text--secondary": theme.text.secondary,
    "color-error": theme.color.danger,
    "b-primary": theme.color.primary
  };
};

/**
 * WebFont loader will add fontname classes to the html class list
 * - So we check that for the state of whether the font has been loaded or not
 * - docs https://github.com/typekit/webfontloader#events
 * @param {String} family
 */
export const fontFamilyIsLoaded = (family) => {
  let isLoaded = false;
  if (typeof window !== "undefined") {
    const sanitizedName = family.replace(/-|\s/g, "").toLowerCase();
    const matches = window.document
      .getElementsByTagName("html")[0]
      .className.match(sanitizedName);

    isLoaded = Boolean(matches);
  }

  return isLoaded;
};

export const loadFontFamily = ({ family, variants }) => {
  if (typeof window !== "undefined" && !fontFamilyIsLoaded(family)) {
    const WebFont = require("webfontloader");

    const input = {
      google: {
        families: [
          getWebFontConfig({
            family,
            variants: getStandardVariants(variants)
          })
        ]
      }
    };
    WebFont.load(input);
  }
};

export const TRANSFORM_DARKEN = "darken";
const darken = (code) => Color(code).darken(0.1).hex();

export const COLOR_TRANSFORMS = {
  [TRANSFORM_DARKEN]: darken
};

export const processTheme = (theme) => {
  const result = { ...theme };
  if (result.data.color.primary) {
    result.data.color.secondary = darken(result.data.color.primary);
  }
  return result;
};
