import find from "lodash/find";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import uniq from "lodash/uniq";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import reduce from "lodash/reduce";
import isEmpty from "lodash/isEmpty";
import reject from "lodash/reject";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import fuzzy from "fuzzy";
import _ from "lodash";
import QS from "qs";
// import { COMPLETE } from "utils/constants/status";
import { ONBOARDING_STATUSES, CONFIRM_STEP } from "utils/constants/onboarding";
import PATHS from "./paths";

const COMPLETE = "complete";

export const ONBOARDING_CATEGORIES = {
  QUIZ: "quiz",
  SETUP: "setup",
  GOAL: "goal",
  NOMINATE: "nominate",
  OPTION_SELECT: "option_select",
  PAGE_BUILDER: "page_builder",
  LINK_BUILDER: "link_builder",
  PATH_SELECT: "path_select",
  APP_SETUP: "app_setup"
};
export const DIRECTIONS = {
  PREVIOUS: "prev",
  NEXT: "next"
};
/**
 * NOTE: for some reason `goal` is one facet too much and causes a render error
 * - Tags which are not included in the quiz section e.g. subdiscipline, miscellaneous
 */
export const TECH_SECTION_UID = "technology";
export const CATEGORY_SECTION_UID = "category";
export const QUIZ_SECTION_UIDS = [
  "profile.department",
  "profile.department.subdepartment",
  "profile.department.subdepartment.role",
  "discipline",
  "language",
  TECH_SECTION_UID,
  CATEGORY_SECTION_UID
];

export const APP = "app";
export const GOOGLE = "google";
export const SLACK = "slack";
export const STRIPE = "stripe";
export const SHOPIFY = "shopify";
export const SQUARESPACE = "squarespace";
export const CUSTOM = "custom";
export const SERVICES = {
  APP,
  GOOGLE,
  SLACK,
  STRIPE,
  SHOPIFY,
  SQUARESPACE,
  CUSTOM
};

export function deconstructTraits(traits) {
  return _.reduce(
    traits,
    (memo, trait) => {
      _.each(trait.subtraits, (subtrait) => {
        memo.measurements = memo.measurements.concat(
          _.map(subtrait.measurements, (measure) => {
            return _.assign({}, measure, {
              parent: {
                id: subtrait.id,
                model: "subtraits"
              }
            });
          })
        );
        memo.subtraits.push(
          _.assign({}, _.omit(subtrait, "measurements"), {
            parent: {
              id: trait.id,
              model: "traits"
            }
          })
        );
      });
      memo.traits.push(_.omit(trait, "subtraits"));

      return memo;
    },
    {
      traits: [],
      subtraits: [],
      measurements: []
    }
  );
}

/**
 * Find the goals which have a target that matches one of the models
 * @param {Array} goals
 * @param {Object} role
 */
export function modelsFromRoleTraits(goals, role) {
  const filteredGoals = reject(goals, (goal) => {
    return isEmpty(goal.target);
  });

  const deconstructed = deconstructTraits(role.traits);

  return _.reduce(
    filteredGoals,
    (memo, goal) => {
      const { id, class_name: cName } = goal.target;
      const keyName = cName.toLowerCase() + "s";
      const hasTargetMatch = find(deconstructed[keyName], { id });
      if (hasTargetMatch) {
        memo.push(goal);
      }

      return memo;
    },
    []
  );
}

export const currentRequiredOnboarding = (onboardingConfig, onboardings) => {
  const allRemainingOnbs = onboardings.filter(
    (onboarding) =>
      onboarding.status === ONBOARDING_STATUSES.INCOMPLETE &&
      onboarding.required
  );
  const remainingServiceTypes = uniq(map(allRemainingOnbs, "service"));
  const ctxService =
    onboardingConfig.order &&
    [...onboardingConfig.order]
      .reverse()
      .find((serviceType) => remainingServiceTypes.indexOf(serviceType) > -1);
  const remainingServiceOnbs = allRemainingOnbs.filter(
    (onboarding) => onboarding.service === ctxService
  );

  const orderedServiceFlows = onboardingConfig[ctxService];
  let ctxOnb;

  orderedServiceFlows &&
    orderedServiceFlows.forEach((flow) => {
      if (ctxOnb) {
        return false;
      }
      remainingServiceOnbs.forEach((remOnb) => {
        if (flow.category === remOnb.category) {
          ctxOnb = {
            ...remOnb,
            config: flow.scenes.find(
              (scene) => remOnb.steps.indexOf(scene.step) === -1
            )
          };
          return false;
        }
      });
    });

  return ctxOnb;
};

/**
 * Determine the % of section completion
 * - dot path sections are not counted as extra completion
 * @param {Array} sections
 * @param {String} activeSection
 */
export function quizProgress(sections = [], activeSection = "") {
  const activeStep = activeSection.split(".")[0];
  const firstOrders = _.chain(sections)
    .map((section) => {
      return section.uid.split(".")[0];
    })
    .uniq()
    .value();
  const position = firstOrders.indexOf(activeStep);
  const progress = (position + 1) / firstOrders.length;
  return _.round(progress, 2) * 100;
}

export function validateSectionFields(
  activeSection,
  configSections,
  fieldsSelections
) {
  const validations = activeSectionEntities(activeSection, configSections);

  const fieldValues = activeFieldValues(activeSection, fieldsSelections);

  return genValidationErrors(validations, fieldValues);
}

function activeSectionEntities(activeSectionId, config) {
  const re = new RegExp(`^(${activeSectionId}\\.)`, "g");

  return _.chain(config)
    .map((section) => {
      const { uid, entities } = section;
      const hasEntities = entities && entities.length > 0;
      const hasUIDMatch = !_.isNull(uid.match(re));
      const isMatchingSection = uid === activeSectionId;
      const validSection = hasUIDMatch || isMatchingSection;

      if (validSection && hasEntities) {
        return _.chain(section.entities)
          .map((entity) => {
            return _.chain(entity.fields)
              .map((field) => {
                if (!_.isEmpty(field.validation)) {
                  const { key, validation } = field;
                  return {
                    key,
                    validation
                  };
                }
              })
              .compact()
              .flatten()
              .value();
          })
          .compact()
          .flatten()
          .value();
      }
    })
    .compact()
    .flatten()
    .value();
}

function activeFieldValues(activeSectionId, config) {
  const re = new RegExp(`^(${activeSectionId}\\.)`, "g");

  return _.chain(config)
    .map((field) => {
      const { uid } = field;
      if ((uid && !_.isNull(uid.match(re))) || uid === activeSectionId) {
        return _.chain(field.values)
          .map((value) => {
            return {
              key: field.uid,
              ...value
            };
          })
          .compact()
          .flatten()
          .value();
      }
    })
    .compact()
    .flatten()
    .value();
}

export function valuesForKeyMatch(values, key) {
  return filter(values, { key }).concat(filter(values, { uid: key }));
}

export function genValidationErrors(validations, fieldValues) {
  return _.map(validations, ({ key, validation }) => {
    const result = {
      key,
      validation: _.assign({}, validation, { error: false })
    };

    const values = valuesForKeyMatch(fieldValues, key);
    if (values.length) {
      const failsMin = validation.min && values.length < validation.min;
      const failsMax = validation.max && values.length > validation.max;

      if (failsMin || failsMax) {
        result.validation.error = true;
      } else {
        const value = values[0];
        if (!value.name) {
          result.validation.error = true;
        }
        if (_.isObject(value.name) && _.isEmpty(value.name)) {
          result.validation.error = true;
        }
      }
    } else {
      result.validation.error = true;
    }

    return result;
  });
}

/**
 *
 * @param {Object} config
 * @param {Object} activeSection
 * @param {String} direction
 */
export function getQuizSectionForDirection(config, activeSection, direction) {
  let section, primaryKey, lastMatchIx, sections, sectionIds;
  const primaryKeys = activeSection.split(".");

  if (direction === DIRECTIONS.NEXT) {
    sections = _.get(config, "sections", []).slice().reverse();
    sectionIds = _.map(sections, "uid");
    primaryKey = primaryKeys[0];
    lastMatchIx = findIndex(sectionIds, (sectId) => {
      const match = sectId.split(".")[0];
      return match === primaryKey;
    });
    let newIx;
    if (lastMatchIx > -1) {
      newIx = lastMatchIx - 1;
      section = sections[newIx];
    }
  } else if (direction === DIRECTIONS.PREVIOUS) {
    sections = config.sections.slice();
    sectionIds = _.map(sections, "uid");

    lastMatchIx = sectionIds.indexOf(activeSection);
    const rem = sectionIds.slice(0, lastMatchIx);
    const remRev = rem.slice().reverse();

    let targetIx;
    for (const sect of remRev) {
      if (sect.split(".").length === primaryKeys.length) {
        targetIx = rem.indexOf(sect);
        break;
      }
    }

    if (targetIx > -1) {
      section = sections[targetIx];
    }
  }

  return section;
}

export function applyFuzzyFilter(searchTerm, collection = [], options) {
  const flattenedCollection = _.chain(collection).values().flatten().value();
  const matches = fuzzy.filter(searchTerm, flattenedCollection, options);
  return _.map(matches, (match) => {
    return match.original;
  });
}

/**
 * generateOptionsUrl
 * @param {Object} params - values we pull the template properties from
 * @param {String} templateUrl - e.g. /v1/users/{user.id}/onboardings/{onboarding.id}/quiz/options
 */
export function generateOptionsUrl(params, templateUrl) {
  let optionsUrl = "";
  const regex = new RegExp(/{\s*(.*?)\s*}/g);
  const matches = templateUrl.match(regex);

  if (matches && matches.length) {
    optionsUrl = _.reduce(
      matches,
      (memo, fragment) => {
        const attrPath = fragment.replace(/{|}/g, "");
        const sub = _.get(params, attrPath);
        if (sub) {
          const parts = memo.slice().split(fragment);
          parts.splice(1, 0, sub);
          memo = parts.join("");
        }
        return memo;
      },
      templateUrl
    );
  }

  return optionsUrl;
}

/**
 * The next section of the onboarding flow can be driven by selections from the previous sections
 * We will pull out the previous form selections and send them in a request to the BE so the response can be tailored
 * This is configuration driven - the discriminator_keys which define what field values to select are defined within the BE quiz configuration
 * - Sample of field configuration shape {
    component: 'list.ordered',
    key: 'discipline',
    meta: {
      options_url:
        '/v1/users/{user.id}/onboardings/{onboarding.id}/quiz/options',
      query: {
        id,
        section_id: 'discipline',
        discriminator_keys: [
          'profile.department',
          'profile.department.subdepartment',
          'profile.department.subdepartment.role'
        ]
      }
    }
  }
 * @param {Object} fieldQueryConfig
 * @param {Array} fieldSelections
 */
export const getNextSectionOptionParams = (
  fieldQueryConfig,
  fieldSelections
) => {
  let discriminatorKeys;
  if (fieldQueryConfig) {
    discriminatorKeys = fieldQueryConfig.discriminator_keys;
  }
  let discriminators = [];

  if (discriminatorKeys && discriminatorKeys.length) {
    discriminators = _.chain(discriminatorKeys)
      .map((disc) => {
        return find(fieldSelections, { uid: disc });
      })
      .compact()
      .value();
  }

  return _.assign({}, _.omit(fieldQueryConfig, "discriminator_keys"), {
    discriminators
  });
};

/**
 * Determine whether the validation max has been reached
 * @param {Object} validation - valdiation configuration object
 * @param {Number} count - current count to reference
 */
export const canUpdateValidatedPayload = (validation, count) => {
  let canUpdate = true;
  const maxVal = _.get(validation, "max", null);
  if (!_.isNull(maxVal) && count >= maxVal) {
    canUpdate = false;
  }
  return canUpdate;
};

const getNameOrPlaceholder = (value, placeholder) =>
  value && value.name ? value.name : placeholder || "";

/**
 * Get any validation error for the supplied key
 * @param {Array} validations  - collection of validations to check
 * @param {String} key - key to use as lookup
 */
export const getValidationError = (validations, key) => {
  const validation = find(validations, (validation) => {
    return validation.key === key;
  });

  return validation ? validation.validation.error : false;
};

export const getValueForValueKey = (
  values = [],
  key,
  valueKey,
  placeholder
) => {
  let result = "";
  const value = find(values, { uid: key }, {});
  if (value && _.isObject(value.name)) {
    result = _.get(value.name, valueKey, placeholder);
  } else {
    result = getNameOrPlaceholder(value, placeholder);
  }

  return result;
};

export const getValueForSectionKey = (values = [], placeholder) => {
  if (!values) {
    console.error("** Error: no quiz valueForSectionKey");
  }
  const value = values && values[0];
  const result = getNameOrPlaceholder(value, placeholder);

  return result;
};

export const getValueForFieldKey = (values = [], key = "", placeholder) => {
  const value = find(values, { uid: key }, {});
  return getNameOrPlaceholder(value, placeholder);
};

/**
 * Get the previous subsection dot path if present
 * - Used in the Progress header to determine which content section to go to
 * @param {String} sectionID - current section ID - profile.department.subdepartment
 */
export const getPreviousSubsectionPath = (sectionID) => {
  if (sectionID) {
    const parts = sectionID.split(".");
    parts.pop();
    return parts.join(".");
  }
};
/**
 *
 * @param {Array} options collection of list options
 * @param {Object} config - configuration for transforming options
 * @param {Object} config.group - gorup configuration
 * @param {String} config.group.by - key to group by
 * @param {Array} config.sort - keys to sort by in order
 */
export const groupAndSortOptions = (options, config) => {
  const { group, sort } = config;
  if (group && group.by) {
    const grouped = groupBy(options, group.by);
    if (sort && sort.length) {
      return reduce(
        grouped,
        (memo, val, key) => {
          memo[key] = uniqBy(sortBy(val, sort), "uid");
          return memo;
        },
        {}
      );
    } else {
      return grouped;
    }
  }

  return uniqBy(sortBy(options, sort), "uid");
};

export const activeConfigSection = (config, sid) => {
  if (config && config.sections && config.sections.length) {
    return find(config.sections, { uid: sid }) || config.sections[0];
  }
};

export const extractParams = (params = {}, data = {}) =>
  Object.keys(params).reduce((memo, key) => {
    const paramsVal = get(data, params[key]);
    if (paramsVal) {
      memo[key] = paramsVal;
    }
    return memo;
  }, {});

export const extendActionHrefWithQueryData = ({ query, href }, data) => {
  const queryParams = extractParams(query, data);

  return `${href}?${QS.stringify(queryParams)}`;
};

export const calculateProgress = (onboardings) => {
  const completeCount = onboardings.filter((onb) => onb.status === COMPLETE)
    .length;
  const totalCount = onboardings.length;
  const completionPct = (completeCount / totalCount) * 100;
  return !completionPct ? 5 : completionPct;
};

/**
 * Component key
 * if user is not yet confirmed - then key is confirm
 * else get from router query param
 * else get from current onboarding if present
 */
export const getComponentKey = ({
  router,
  userConfirmed,
  currentOnboarding
}) => {
  let componentKey;
  const routerComponent = router.query.component;

  const currentOnboardingComponent =
    currentOnboarding && currentOnboarding.config.component;

  if (!userConfirmed) {
    componentKey = CONFIRM_STEP;
  } else if (routerComponent) {
    componentKey = routerComponent;
  } else if (currentOnboardingComponent) {
    componentKey = currentOnboardingComponent;
  }
  return componentKey;
};

export const getCurrentOnboardingRoute = ({ router, currentOnboarding }) =>
  `${PATHS.ONBOARDING}?${QS.stringify({
    ...router.query,
    category: currentOnboarding.category,
    component: currentOnboarding.config.component
  })}`;
