import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import get from "lodash/get";
import startCase from "lodash/startCase";
import { EXPORT_OPTION } from "utils/constants/insights";
import { orderByDate } from "./date";
import format from "date-fns/format";
import { TS_FORMAT } from "utils/date";

const INSIGHT_KEYS = ["id", "uuid", "type", "created_at", "updated_at"];
const CONTROL_KEYS = ["currency"];

/**
 * Flatten the matched data onto the response
 * - matchedInsight.data = insight.data.data[0]
 */
const formatInsight = (insight, data) => ({
  ...pick(insight, INSIGHT_KEYS),
  ...data
});

const getSubInsights = (insight) => get(insight, "data.data") || [];

/**
 * Insights have a data field of jsonb
 * - That data field has a data field which contains an array of subinsights (usually one per currency)
 */
const getInsightControls = (insight) => {
  const controls = {};
  const subInsights = getSubInsights(insight);

  CONTROL_KEYS.forEach((filterKey) => {
    const currencyOptions = subInsights
      .reduce((memo, subInsight) => {
        const viewCurrency = get(subInsight, `view.filterData.${filterKey}`);

        if (viewCurrency && memo.indexOf(viewCurrency) === -1) {
          memo.push(viewCurrency);
        }

        return memo;
      }, [])
      .map((viewCurrency) => ({
        uid: viewCurrency,
        label: viewCurrency.toUpperCase()
      }));

    if (currencyOptions.length) {
      controls[filterKey] = currencyOptions;
    }
  });

  return controls;
};

export const prepareInsightExport = (insight) => {
  const tableColumns = get(insight, "data.table.columns") || [];
  const tableRows = get(insight, "data.table.rows") || [];
  const computedAt = get(insight, "view.computedAt");
  const insightRows = tableRows.map((row) => {
    return {
      values: tableColumns.reduce((memo, column) => {
        const { title, accessor } = column;

        memo[title] = row[accessor].title || row[accessor].value;
        return memo;
      }, {})
    };
  });

  let updatedAtTS;
  if (computedAt) {
    try {
      updatedAtTS = format(new Date(computedAt), TS_FORMAT.DATE_AND_TIME);
    } catch (error) {
      console.error(error);
    }
  }
  const filenameParts = [startCase(insight.type)];
  if (updatedAtTS) {
    filenameParts.push(updatedAtTS);
  }

  return {
    types: [EXPORT_OPTION.CSV],
    tooltip: "Export table",
    rows: insightRows,
    filename: filenameParts.join(" ")
  };
};

/**
 * Note: the insight that is returned is a sub insight
 * - there are multiple insights to account for different currencies etc.
 * data.data[0]
 * @param {Note} param0
 * @returns
 */
export const findLatestInsightAndControls = ({
  subdomain,
  insights,
  type,
  filterData
}) => {
  const result = {
    insight: null,
    controls: null,
    exportConfig: null
  };
  if (insights.length) {
    const typedInsights = insights.filter((insight) => {
      let isMatch = insight.type === type;
      if (subdomain && isMatch && insight.subdomain) {
        isMatch = insight.subdomain === subdomain;
      }
      return isMatch;
    });
    const orderedInsights = orderByDate(typedInsights, "created_at", "desc");

    if (isEmpty(filterData)) {
      const latestInsight = orderedInsights[0];
      const firstSubInsight = getSubInsights(latestInsight)[0];

      if (firstSubInsight) {
        result.controls = getInsightControls(latestInsight);
        result.insight = formatInsight(latestInsight, firstSubInsight);
      }
    } else {
      for (let insightIx = 0; insightIx < orderedInsights.length; insightIx++) {
        const ctxInsight = orderedInsights[insightIx];

        const subInsights = getSubInsights(ctxInsight);

        const match = subInsights.find((subInsight) => {
          let match = true;
          for (const filterKey in filterData) {
            const filterVal = filterData[filterKey];
            const subFilterData = get(subInsight, "view.filterData");
            if (subFilterData && subFilterData[filterKey] !== filterVal) {
              match = false;
              break;
            }
          }

          return match;
        });
        if (match) {
          result.controls = getInsightControls(ctxInsight);
          result.insight = formatInsight(ctxInsight, match);
          break;
        }
      }
    }
  }

  if (result.insight) {
    result.exportConfig = prepareInsightExport(result.insight);
  }

  return result;
};
