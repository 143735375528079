import C from "constants/paymentMethod";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import ActionHelpers from "./helpers";
import { authBearerToken } from "utils/auth";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_PAYMENT_METHOD_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setPaymentMethods = (payload) => {
  return {
    type: C.SET_PAYMENT_METHODS,
    payload
  };
};

export const replacePaymentMethods = (payload) => {
  return {
    type: C.REPLACE_PAYMENT_METHODS,
    payload
  };
};

export const removePaymentMethods = (payload) => {
  return {
    type: C.REMOVE_PAYMENT_METHODS,
    payload
  };
};

/** resetPaymentMethodNotes
 */
export function resetPaymentMethodNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_PAYMENT_METHOD_STORE,
      payload
    });
  };
}

export function showPaymentMethodNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_PAYMENT_METHOD_STORE,
        message || `Success!`
      )
    );
  };
}

/**
 * fetchPaymentMethods
 */
export const fetchPaymentMethods = (
  { resourceUUID, resource, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/payment-methods`;
    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const paymentMethods = Array.isArray(data) ? data : [data];

        dispatch(
          batchActions([
            replacePaymentMethods(paymentMethods),
            ActionHelpers.clearLoader(C.UPDATE_PAYMENT_METHOD_STORE)
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PAYMENT_METHOD_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * deletePaymentMethod
 */
export const deletePaymentMethod = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/payment-methods/${uuid}`;
    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const paymentMethods = Array.isArray(data) ? data : [data];

        dispatch(
          batchActions([
            removePaymentMethods(paymentMethods),
            ActionHelpers.clearLoader(C.UPDATE_PAYMENT_METHOD_STORE)
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PAYMENT_METHOD_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
