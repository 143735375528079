import { prepareManifestForEditor } from "components/FormerEditor/common";
// import {
//   generateVariableSet,
//   getHydratedVariables
// } from "components/FormerEditor/common/template";

import ENTITIES from "components/FormerEditor/common/constants/entity";
import {
  RiMindMap,
  RiRecordCircleLine,
  RiFormatClear,
  RiTestTubeLine,
  RiBracketsLine,
  RiTimerFlashLine,
  RiFilter3Line,
  RiPercentLine,
  RiAlarmWarningLine,
  RiShieldCheckLine,
  RiMapPinLine,
  RiCoupon3Line,
  RiStopLine,
  RiCheckboxMultipleLine,
  RiShoppingCartLine,
  RiDeleteBin7Line,
  RiTableLine,
  RiPencilLine,
  RiSettingsLine,
  RiNodeTree,
  RiCloseLine,
  RiFlag2Line,
  RiAddLine,
  RiArrowLeftLine,
  RiShoppingBasketLine,
  RiCodeSSlashLine,
  RiClipboardLine,
  RiEdit2Line,
  RiEyeLine,
  RiEyeOffLine,
  RiFileTextLine,
  RiFlashlightLine,
  RiAlertLine,
  RiFileListLine,
  RiArrowDownSLine,
  RiInformationLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiArrowLeftSLine,
  RiMoreLine,
  RiStarLine,
  RiRefreshLine,
  RiSearchLine,
  RiSettings2Line,
  RiPaletteLine,
  RiPriceTag3Line,
  RiPriceTag3Fill,
  RiMedalLine,
  RiMoneyDollarBoxLine,
  RiCalendarEventLine,
  RiCalendarCheckLine,
  RiGroupLine,
  RiDragDropLine,
  RiUserLine,
  RiCalculatorLine,
  RiLineChartLine,
  RiStickyNoteLine,
  RiHome4Line,
  RiBarChartBoxLine,
  RiLinksFill,
  RiDatabase2Line,
  RiLayoutGridLine,
  RiMenu2Line,
  RiCheckboxCircleLine,
  RiStore2Line,
  RiBankLine,
  RiGlobalLine,
  RiTimerLine,
  RiQrCodeLine,
  RiQrScan2Line,
  RiArrowRightLine,
  RiLockLine,
  RiLockUnlockLine,
  RiImageAddLine,
  RiUploadCloud2Line,
  RiQuestionLine,
  RiCloseCircleLine,
  RiMagicLine,
  RiProfileLine,
  RiPlayCircleLine,
  RiCursorLine,
  RiMoreFill,
  RiArrowDownLine,
  RiArrowUpLine,
  RiDownloadCloud2Line,
  RiFileList2Line,
  RiComputerLine,
  RiSmartphoneLine,
  RiToggleLine,
  RiCheckboxLine,
  RiAuctionLine,
  RiPhoneLine,
  RiBuilding4Line,
  RiListCheck,
  RiText,
  RiApps2Line,
  RiTextWrap,
  RiLinkM,
  RiLinkUnlinkM,
  RiFileDownloadLine,
  RiSubtractLine,
  RiContactsLine,
  RiPassportLine,
  RiPhoneCameraLine,
  RiArchiveLine,
  RiImage2Line,
  RiVideoLine,
  RiBold,
  RiItalic,
  RiStrikethrough2,
  RiParagraph,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiListUnordered,
  RiListOrdered,
  RiPulseLine,
  RiCodeBoxLine,
  RiDoubleQuotesL,
  RiQuillPenFill,
  RiSplitCellsHorizontal,
  RiSplitCellsVertical,
  RiCheckFill,
  RiSubscript2,
  RiSuperscript2
} from "@hacknug/react-icons/ri";
import {
  SiGithub,
  SiGoogledrive,
  SiBox,
  SiMicrosoftonedrive,
  SiDropbox
} from "@hacknug/react-icons/si";
import { CgSpaceBetweenV } from "@hacknug/react-icons/cg";
import { VscMegaphone, VscHorizontalRule } from "@hacknug/react-icons/vsc";
import {
  IoMdColorWand,
  IoIosCheckmarkCircle,
  IoIosWarning,
  IoMdOpen,
  IoMdBusiness,
  IoMdUndo,
  IoMdRedo
} from "@hacknug/react-icons/io";
import { MdLocationOn, MdSwapCalls } from "@hacknug/react-icons/md";
import { FaShippingFast } from "@hacknug/react-icons/fa";
import { STATE_KEYS } from "utils/constants/state";
import { BUILDER_INTENT } from "utils/constants/builder";
import get from "lodash/get";
import { RECURRING_INTERVALS } from "./common/constants";
import { HUB_APP_SUB_SECTION } from "utils/constants/navigation";

import { VIEWPORT_SIZE } from "utils/constants/viewport";
import { EDITOR_MODE } from "utils/constants/editor";
import { ADDRESS_INPUT_TYPE } from "utils/constants/address";

const PAGE_BUILDER = "page-builder";
const SETTINGS = "settings";
const FEATURES = "features";
const PRODUCTS = "products";
const WORKFLOWS = "workflows";

export const SECTIONS = {
  PAGE_BUILDER,
  SETTINGS,
  FEATURES,
  PRODUCTS,
  WORKFLOWS
};

export const SECTIONS_LABEL_MAP = {
  [PAGE_BUILDER]: "Builder",
  [SETTINGS]: "Settings",
  [FEATURES]: "Features",
  [PRODUCTS]: "Products",
  [WORKFLOWS]: "Workflows"
};

/**
 * Source documentation:
 * https://react-icons.github.io/react-icons/icons?name=ri
 */
const ICON_MAP = {
  [ENTITIES.NAVIGATION]: RiMapPinLine,
  [ENTITIES.FOOTER]: RiStopLine,
  [ENTITIES.BANNER]: RiFlag2Line,
  [ENTITIES.PRICING_TABLE]: RiTableLine,
  [ENTITIES.FEATURE_COMPARISON]: RiCheckboxMultipleLine,
  [ENTITIES.SOCIAL_PROFILE]: RiProfileLine,
  [ENTITIES.PRODUCT_COLLECTION]: RiPriceTag3Line,
  [ENTITIES.PAYMENT]: RiMoneyDollarBoxLine,
  [SECTIONS.PAGE_BUILDER]: RiPencilLine,
  [SECTIONS.SETTINGS]: RiSettingsLine,
  [SECTIONS.FEATURES]: RiFileListLine,
  [SECTIONS.PRODUCTS]: RiPriceTag3Line,
  [SECTIONS.WORKFLOWS]: RiNodeTree,
  [RECURRING_INTERVALS.MONTH]: RiCalendarEventLine,
  [RECURRING_INTERVALS.YEAR]: RiCalendarCheckLine,
  table: RiTableLine,
  application: RiDatabase2Line,
  coupons: RiCoupon3Line,
  upload: RiUploadCloud2Line,
  download: RiDownloadCloud2Line,
  discount: RiPriceTag3Fill,
  medal: RiMedalLine,
  shield: RiShieldCheckLine,
  gear: RiSettings2Line,
  search: RiSearchLine,
  grid: RiLayoutGridLine,
  list: RiMenu2Line,
  magic: RiMagicLine,
  checkmark: IoIosCheckmarkCircle,
  check: RiCheckFill,

  closeCircle: RiCloseCircleLine,
  checkmarkCircle: RiCheckboxCircleLine,
  refresh: RiRefreshLine,
  close: RiCloseLine,
  addImage: RiImageAddLine,
  external: IoMdOpen,
  delete: RiDeleteBin7Line,
  help: RiInformationLine,
  question: RiQuestionLine,
  add: RiAddLine,
  subtract: RiSubtractLine,
  down: RiArrowDownSLine,
  form: RiFileList2Line,
  left: RiArrowLeftSLine,
  right: RiArrowRightSLine,
  leftArrow: RiArrowLeftLine,
  rightArrow: RiArrowRightLine,
  downArrow: RiArrowDownLine,
  upArrow: RiArrowUpLine,
  up: RiArrowUpSLine,
  dragDrop: RiDragDropLine,
  back: RiArrowLeftLine,
  forward: RiArrowRightLine,
  copy: RiClipboardLine,
  edit: RiEdit2Line,
  eye: RiEyeLine,
  eyeClosed: RiEyeOffLine,
  star: RiStarLine,
  lock: RiLockLine,
  unlock: RiLockUnlockLine,
  file: RiFileTextLine,
  flash: RiFlashlightLine,
  [ADDRESS_INPUT_TYPE.MANUAL]: RiText,
  [ADDRESS_INPUT_TYPE.AUTOCOMPLETE]: RiFlashlightLine,
  alert: RiAlertLine,
  more: RiMoreLine,
  theme: RiPaletteLine,
  products: RiShoppingBasketLine,
  dollar: RiMoneyDollarBoxLine,
  sales: RiMoneyDollarBoxLine,
  store: RiStore2Line,
  bank: RiBankLine,
  globe: RiGlobalLine,
  individual: RiUserLine,
  group: RiGroupLine,
  calculator: RiCalculatorLine,
  warning: IoIosWarning,
  alarm: RiAlarmWarningLine,
  chart: RiBarChartBoxLine,
  link: RiLinksFill,
  taxRates: RiPercentLine,
  shippingRates: FaShippingFast,
  time: RiTimerLine,
  qrCode: RiQrCodeLine,
  scan: RiQrScan2Line,
  click: RiCursorLine,
  shipping: FaShippingFast,
  filter: RiFilter3Line,
  [HUB_APP_SUB_SECTION.ANALYTICS]: RiLineChartLine,
  [HUB_APP_SUB_SECTION.INSIGHTS]: RiTestTubeLine,
  [HUB_APP_SUB_SECTION.PAGES]: RiStickyNoteLine,
  [HUB_APP_SUB_SECTION.HOME]: RiHome4Line,
  [HUB_APP_SUB_SECTION.LINKS]: RiLinksFill,
  [HUB_APP_SUB_SECTION.FILES]: RiUploadCloud2Line,
  [HUB_APP_SUB_SECTION.CAMPAIGNS]: VscMegaphone,
  [HUB_APP_SUB_SECTION.CONTACTLESS]: RiQrCodeLine,
  // [HUB_APP_SUB_SECTION.CAMPAIGNS]: AiOutlineNotification,
  page: RiStickyNoteLine,
  link_builder: RiLinksFill,
  page_builder: RiStickyNoteLine,
  play: RiPlayCircleLine,
  fallback: RiMoreFill,
  business: IoMdBusiness,
  desktop: RiComputerLine,
  mobile: RiSmartphoneLine,
  flag: RiFlag2Line,
  flashTimer: RiTimerFlashLine,
  //
  select: RiListCheck,
  multiselect: RiListOrdered,
  input: RiText,
  standard: RiText,
  textarea: RiTextWrap,
  toggle: RiToggleLine,
  checkbox: RiCheckboxLine,
  disclaimer: RiAuctionLine,
  phone: RiPhoneLine,
  tin: RiBuilding4Line,
  datepicker: RiCalendarEventLine,
  fieldGroup: RiApps2Line,
  email: RiApps2Line,
  // Modes
  inspect: RiBracketsLine,
  use: RiCursorLine,
  linked: RiLinkM,
  unlinked: RiLinkUnlinkM,
  csv: RiFileDownloadLine,
  clearFormatting: RiFormatClear,
  wand: IoMdColorWand,
  cart: RiShoppingCartLine,
  document: RiFileTextLine,
  identity: RiContactsLine,
  contact: RiContactsLine,
  passport: RiPassportLine,
  id_number: RiContactsLine,
  id_card: RiContactsLine,
  driving_license: RiFileTextLine,
  selfie: RiPhoneCameraLine,
  address: MdLocationOn,
  location: MdLocationOn,
  archive: RiArchiveLine,
  local_file_system: RiComputerLine,
  googledrive: SiGoogledrive,
  dropbox: SiDropbox,
  box: SiBox,
  onedrive: SiMicrosoftonedrive,
  onedriveforbusiness: SiMicrosoftonedrive,
  github: SiGithub,
  image: RiImage2Line,
  video: RiVideoLine,
  app: RiApps2Line,
  text: RiFileTextLine,
  /**
   * Editable content icons
   */
  bold: RiBold,
  italic: RiItalic,
  strike: RiStrikethrough2,
  code: RiCodeSSlashLine,
  paragraph: RiParagraph,
  heading_1: RiH1,
  heading_2: RiH2,
  heading_3: RiH3,
  heading_4: RiH4,
  heading_5: RiH5,
  heading_6: RiH6,
  bulletList: RiListUnordered,
  orderedList: RiListOrdered,
  codeBlock: RiCodeBoxLine,
  blockquote: RiDoubleQuotesL,
  horizontalRule: VscHorizontalRule,
  hardBreak: CgSpaceBetweenV,
  undo: IoMdUndo,
  redo: IoMdRedo,
  legal: RiQuillPenFill,
  pulse: RiPulseLine,
  subscript: RiSubscript2,
  superscript: RiSuperscript2,
  split: RiSplitCellsHorizontal,
  single: RiSplitCellsVertical,
  replace: MdSwapCalls,
  record: RiRecordCircleLine,
  clickwrap: RiCheckboxLine,
  browsewrap: RiEyeLine,
  logic: RiMindMap
};

export const getIconForUID = (uid) => {
  let icon = ICON_MAP[uid];
  if (!icon) {
    if (process.env.NODE_ENV === "development") {
      // console.log(`[WARN]: No icon found for ${uid}`);
    }
    icon = ICON_MAP.fallback;
  }
  return icon;
};

export const preparedEditor = ({ isBlank = true }) => ({
  intent: isBlank ? BUILDER_INTENT.ENTITY_ADD : null,
  activeSection: SECTIONS.PAGE_BUILDER,
  mode: EDITOR_MODE.USE,
  viewport: VIEWPORT_SIZE.DESKTOP,
  activeBlock: null,
  visibility: {
    panel: !!isBlank
  },
  validations: {
    fields: {
      enabled: false
    }
  },
  pricing: {
    activeProduct: null,
    activePrice: null,
    intent: null
  },
  feature: {
    activeProduct: null,
    intent: null
  },
  publish: {
    showMenu: false
  }
});

export const getEditorInitialValues = ({
  manifest,
  admin,
  theme,
  products,
  prices,
  shortLinks,
  taxRates,
  coupons,
  shippingRates,
  features,
  featureGroups,
  appEntities,
  entitlements,
  variables
}) => {
  const input = {
    manifest,
    admin,
    products,
    prices,
    features,
    featureGroups,
    shortLinks,
    taxRates,
    shippingRates,
    coupons,
    appEntities,
    entitlements
  };

  // const generatedVariables = generateVariableSet({
  //   persisted: variables,
  //   template: [],
  //   product: products,
  //   price: prices
  // });

  // const hydratedVariables = getHydratedVariables(generatedVariables, {
  //   product: products,
  //   price: prices
  // });
  const isBlank = get(input, STATE_KEYS.MANIFEST.ENTITIES, []).length === 0;
  const preparedManifest = prepareManifestForEditor(input);

  return {
    editor: preparedEditor({ isBlank }),
    manifest: preparedManifest,
    theme: theme,
    // variables: hydratedVariables
    variables: []
  };
};
