import { COLORS } from "utils/styles";

/**
 * Entity UIDS
 */
const NAVIGATION = "navigation";
const PRICING_TABLE = "pricing-table";
const BANNER = "banner";
const FEATURE_COMPARISON = "feature-comparison";
const SOCIAL_PROFILE = "social-profile";
const PRODUCT_COLLECTION = "product-collection";
const FORM = "form";
const PAYMENT = "payment";
const FAQ = "faq";
const TESTIMONIAL = "testimonial";
const CTA = "cta";
const FOOTER = "footer";

// Primitive
const SELECT = "select";
const MULTISELECT = "multiselect";
const INPUT = "input";
const TEXTAREA = "textarea";
const TOGGLE = "toggle";
const CHECKBOX = "checkbox";
const DISCLAIMER = "disclaimer";
const DATEPICKER = "datepicker";
// Specialized
const PHONE = "phone";
const TIN = "tin";
const FILE = "file";
const IDENTITY = "identity";
const ADDRESS = "address";
const LEGAL = "legal";

/**
 * Defined pages
 */
const PRICING = "pricing";
const TIP = "tip";
const STORE = "store";
// Future placeholders

export const TEMPLATE_PAGE_TYPE = {
  PRICING,
  FORM,
  TIP,
  PAYMENT,
  STORE
};

/**
 * Entity Labels
 */
const LABEL_NAVIGATION = "Navigation";
const LABEL_PRICING_TABLE = "Pricing Table";
const LABEL_BANNER = "Banner";
const LABEL_FEATURE_COMPARISON = "Feature Comparison";
const LABEL_SOCIAL_PROFILE = "Social Profile";
const LABEL_PRODUCT_COLLECTION = "Product Collection";
const LABEL_PAYMENT = "Payment";
const LABEL_FAQ = "FAQ";
const LABEL_TESTIMONIAL = "Testimonial";
const LABEL_CTA = "CTA";
const LABEL_FOOTER = "Footer";
const LABEL_FORM = "Form";

/**
 * =======================
 * Primitives
 * =======================
 */
const LABEL_SELECT = "Select";
const LABEL_MULTISELECT = "Multiselect";
const LABEL_INPUT = "Input";
const LABEL_TEXTAREA = "Textarea";
const LABEL_TOGGLE = "Toggle";
const LABEL_CHECKBOX = "Checkbox";
const LABEL_DISCLAIMER = "Disclaimer";
const LABEL_DATEPICKER = "Datepicker";

/**
 * =======================
 * Specialized
 * =======================
 */
const LABEL_PHONE = "Phone";
const LABEL_TIN = "Tax id";
const LABEL_FILE = "File";
const LABEL_IDENTITY = "Identity";
const LABEL_ADDRESS = "Address";
const LABEL_LEGAL = "Legal";

export const ENTITY_LABELS = {
  [NAVIGATION]: LABEL_NAVIGATION,
  [PRICING_TABLE]: LABEL_PRICING_TABLE,
  [BANNER]: LABEL_BANNER,
  [FEATURE_COMPARISON]: LABEL_FEATURE_COMPARISON,
  [SOCIAL_PROFILE]: LABEL_SOCIAL_PROFILE,
  [PRODUCT_COLLECTION]: LABEL_PRODUCT_COLLECTION,
  [PAYMENT]: LABEL_PAYMENT,
  [FAQ]: LABEL_FAQ,
  [TESTIMONIAL]: LABEL_TESTIMONIAL,
  [CTA]: LABEL_CTA,
  [FOOTER]: LABEL_FOOTER,
  [FORM]: LABEL_FORM,
  // Primitives
  [SELECT]: LABEL_SELECT,
  [MULTISELECT]: LABEL_MULTISELECT,
  [INPUT]: LABEL_INPUT,
  [TEXTAREA]: LABEL_TEXTAREA,
  [TOGGLE]: LABEL_TOGGLE,
  [CHECKBOX]: LABEL_CHECKBOX,
  [DISCLAIMER]: LABEL_DISCLAIMER,
  [DATEPICKER]: LABEL_DATEPICKER,
  [FILE]: LABEL_FILE,
  [IDENTITY]: LABEL_IDENTITY,
  [ADDRESS]: LABEL_ADDRESS,
  [LEGAL]: LABEL_LEGAL,
  [PHONE]: LABEL_PHONE,
  [TIN]: LABEL_TIN
};

export const ENTITY_COLOR_UID_MAP = {
  [NAVIGATION]: COLORS.primary,
  [BANNER]: COLORS.royal_blue,
  [PRICING_TABLE]: COLORS.gold,
  [FEATURE_COMPARISON]: COLORS.forest_green,
  [SOCIAL_PROFILE]: "#6AA9C3",
  [PRODUCT_COLLECTION]: COLORS.blue,
  [PAYMENT]: "#6B5BD1",
  [FORM]: "#C7160C",
  [FOOTER]: COLORS.primary
};

/**
 * The order of these entities is important as it will be the order they appear on the page
 * ============
 * NOTE
 * ============
 * - When making additions here also update the TEMPLATE_PAGE_TYPE_BUILDER_SECTIONS mapping
 */
export const TEMPLATE_PAGE_ENTITY_GROUPS = {
  [TEMPLATE_PAGE_TYPE.PRICING]: [
    NAVIGATION,
    BANNER,
    PRICING_TABLE,
    FEATURE_COMPARISON,
    FOOTER
  ],
  [TEMPLATE_PAGE_TYPE.TIP]: [SOCIAL_PROFILE, PAYMENT],
  [TEMPLATE_PAGE_TYPE.STORE]: [PRODUCT_COLLECTION],
  [TEMPLATE_PAGE_TYPE.PAYMENT]: [PAYMENT],
  [TEMPLATE_PAGE_TYPE.FORM]: [FORM]
};

export const ENTITY_TYPE = {
  /**
   * Components with multiple sub-components / complexity
   * - Banner / PricingTable
   */
  COMPOUND: "compound",
  /**
   * Components that align to html primitives
   * - input / textareas etc
   */
  PRIMITIVE: "primitive",
  /**
   * Specializzed information capture with optional special validation
   * - tin / phone / identity / address / file / legal etc.
   */
  SPECIALIZED: "specialized"
};

const ENTITIES = {
  // Compound
  NAVIGATION,
  PRICING_TABLE,
  BANNER,
  FEATURE_COMPARISON,
  SOCIAL_PROFILE,
  PRODUCT_COLLECTION,
  PAYMENT,
  FAQ,
  TESTIMONIAL,
  CTA,
  FOOTER,
  FORM,
  // Primitives
  SELECT,
  MULTISELECT,
  INPUT,
  TEXTAREA,
  TOGGLE,
  CHECKBOX,
  DISCLAIMER,
  DATEPICKER,
  // Specialized
  PHONE,
  TIN,
  FILE,
  IDENTITY,
  ADDRESS,
  LEGAL
};

export default ENTITIES;
