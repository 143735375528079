import { priceToPrimary } from "components/FormerEditor/common";
import { CURRENCY_CODES } from "components/FormerEditor/common/constants/currency";
import get from "lodash/get";

export const getSessionTotalDetails = (session) =>
  session && session.total_details
    ? {
        discount: get(session, "total_details.amount_discount", 0),
        shipping: get(session, "total_details.amount_shipping", 0),
        tax: get(session, "total_details.amount_tax", 0),
        currency: get(session, "currency", CURRENCY_CODES.USD)
      }
    : null;

export const sessionLineItemsToPrimaries = (session) => {
  const sessionLineItems = get(session, "line_items.data") || [];
  return sessionLineItems.map(({ price, quantity }) => ({
    ...priceToPrimary(price),
    quantity
  }));
};

/**
 * Subscriptions sessions will have the discount set on the latest invoice
 * One time sessions has it available on line items (when expanded session is fetched)
 */
export const getSessionDiscountReferences = (session = {}) => {
  const isRecurring = session.subscription;

  const result = {
    coupon: null,
    promotion_code: null
  };

  if (isRecurring) {
    result.coupon = get(
      session,
      "subscription.latest_invoice.discount.coupon.id"
    );
  } else {
    const LINE_ITEM_DISCOUNT_PATH = "line_items.data[0].discounts[0].discount";
    result.coupon = get(session, `${LINE_ITEM_DISCOUNT_PATH}.coupon.id`);

    result.promotion_code = get(
      session,
      `${LINE_ITEM_DISCOUNT_PATH}.promotion_code`
    );
  }
  return result;
};

export const getSessionShippingRate = (session) =>
  get(session, "shipping_rate");

export const getSessionSubscriptionSchedule = (session) =>
  session &&
  session.subscription &&
  session.subscription.schedule &&
  session.subscription.schedule.id
    ? session.subscription.schedule
    : null;
