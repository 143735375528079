import C from "constants/organizationEntitlementConfig";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_ENTITLEMENT_CONFIG_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setOrganizationEntitlementConfigs = (payload) => {
  return {
    type: C.SET_ORGANIZATION_ENTITLEMENT_CONFIGS,
    payload
  };
};

export const replaceOrganizationEntitlementConfigs = (payload) => {
  return {
    type: C.REPLACE_ORGANIZATION_ENTITLEMENT_CONFIGS,
    payload
  };
};

/**
 * resetOrganizationEntitlementConfigNotes
 */
export function resetOrganizationEntitlementConfigNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_ENTITLEMENT_CONFIG_STORE,
      payload
    });
  };
}

export function showOrganizationEntitlementConfigNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ORGANIZATION_ENTITLEMENT_CONFIG_STORE,
        message || `Success!`
      )
    );
  };
}
