const GET = "get";
const PUT = "put";
const POST = "post";
const DEL = "del";

export const METHODS = {
  GET,
  PUT,
  POST,
  DEL
};

export const AUTH_TYPE = {
  OAUTH_20: "oauth_2.0"
};

export const AUTH_GRANT_TYPE = {
  CLIENT_CREDENTIALS: "client_credentials"
};
