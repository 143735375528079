import CONSTANTS from "constants/integration";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const internals = {
  [CONSTANTS.SET_INTEGRATIONS]: (state, { payload }) => {
    return helpers.setIn(state, ["data"], "id", payload);
  },
  [CONSTANTS.REPLACE_INTEGRATIONS]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  [CONSTANTS.UPDATE_INTEGRATION_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  },
  [CONSTANTS.UPDATE_INTEGRATION]: (state, { payload = {} }) => {
    const { id } = payload;
    const data = state.get("data");

    const obIndex = data.findIndex((ob) => {
      return ob.get("id") === id;
    });
    if (obIndex >= 0) {
      state = state.mergeDeepIn(["data", obIndex], fromJS(payload));
    }

    return state;
  },
  [CONSTANTS.REMOVE_INTEGRATIONS]: (state, { payload }) => {
    for (let i = 0; i < payload.length; i++) {
      const model = payload[i];
      const { id } = model;
      state = helpers.inActionAtIndex("remove", state, ["data"], id, ["id"]);
    }

    return state;
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
