// Routes
const PRICING = "/pricing";
const TIP = "/tip";
// Status
const ACTIVE = "active";
const INACTIVE = "inactive";
const ARCHIVED = "archived";
const SUSPENDED = "suspended";

export const ROUTES = {
  PRICING,
  TIP
};

export const ROUTE_STATUS = {
  ACTIVE,
  INACTIVE
};

export const APPLICATION_STATUS = {
  INACTIVE,
  ACTIVE,
  SUSPENDED,
  ARCHIVED
};

export const ALIAS_FIELD_COPY =
  "Choose a unique name for your branded payment links, which represents you or your business.";

export const FALLBACK_FIELD_COPY =
  "Choose which of your pages will show when your branded link is visited.";

export const SUBDOMAIN_FIELD_COPY =
  "Your application subdomain is automatically generated based on your email domain. Contact support to update it.";

export const APP_ALIAS_MIN_CHAR_COUNT = 3;

const NEW = "new";
const EXISTING = "existing";

export const APP_PUBLISH_INTENT = {
  NEW,
  EXISTING
};

const LOCATION = "location";
const EMBED = "embed";
export const APP_PUBLISH_TAB = {
  LOCATION,
  EMBED
};
