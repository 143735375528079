import { COMBINATION_FIELDS, FIELD_TYPE_LABEL_MAP } from "utils/constants/form";

export const PAYMENT_METHODS_DOCS_INFO = [
  {
    title: "Payment methods",
    header:
      "Select which payment methods to make available within checkout for this payment link.",
    subheader: `There are restrictions around which payment methods can be used with different price types and currencies. Some methods may be unavailable due to your Stripe account's geographic origin. AliPay and WeChat Pay are listed as "unknown" because we're unable to determine their eligibility via the Stripe API. However, if those methods are enabled on your account within your Stripe dashboard, then they'll work within PriceBlocs. Make sure that every payment method you want to use is enabled for both live and test mode within your Stripe account.`
  }
];

export const SETUP_PAYMENT_METHODS_DOCS_INFO = [
  {
    title: "Payment methods",
    header:
      "Select the set of payment method options your customers can choose from.",
    subheader:
      "There are restrictions around which payment methods can be used for payment setup. Currently the only supported payment methods for setup mode are card and SEPA direct debit. Accounts may have to request access to certain payment methods (e.g. BACS debit) from within their Stripe dashboard. You can learn more about those restrictions within the documentation."
  }
];

export const CHECKOUT_PRODUCTS_COMBINATIONS_INFO = [
  {
    title: "Combinations",
    header:
      "Set which products will be added to the checkout cart when different form value combinations are selected."
  }
];

export const CHECKOUT_PRODUCTS_LOGIC_INFO = [
  {
    title: "Logic",
    header:
      "Set the checkout details for each combination of form field values.",
    subheader:
      "e.g. when the customer selects options A & B then the checkout details are X."
  }
];

export const ELIGIBLE_LOGIC_FIELDS = [];
for (const type in COMBINATION_FIELDS) {
  ELIGIBLE_LOGIC_FIELDS.push(FIELD_TYPE_LABEL_MAP[type].toLowerCase());
}
export const ELIGIBLE_LOGIC_FIELDS_CSV = `${ELIGIBLE_LOGIC_FIELDS.slice(
  0,
  ELIGIBLE_LOGIC_FIELDS.length - 1
).join(", ")} or ${ELIGIBLE_LOGIC_FIELDS.pop()}`;
export const CHECKOUT_PRODUCTS_FIELDS_INFO = [
  {
    title: "Fields",
    header:
      "Each form field you select will be used to generate a set of configurable checkout options. A checkout option will be created for every combination of field type and their field options if available.",
    subheader:
      "e.g. If you have 2 select fields, each of which have 2 options, you would generate 4 checkout options (2 x 2). If you had 3 similar select fields, you would generate 6 checkout options (3 x 2).",
    tertiary: `Note: only some field types can be used to generate configurable checkout options. These types include ${ELIGIBLE_LOGIC_FIELDS_CSV}.`
  }
];

export const CODE_PREVIEW_MAX_HEIGHT = {
  0: 180,
  1: 260,
  2: 380
};

const CURL = "cURL";
const JAVASCRIPT = "javascript";
const JSX = "jsx";
const HTML = "html";
const JSON = "json";
export const LANGUAGE_TYPE = {
  CURL,
  JSX,
  JAVASCRIPT,
  HTML,
  JSON
};

export const LANGUAGE_LABEL_MAP = {
  [LANGUAGE_TYPE.CURL]: CURL,
  [LANGUAGE_TYPE.JAVASCRIPT]: "JavaScript",
  [LANGUAGE_TYPE.JSX]: JSX,
  [LANGUAGE_TYPE.HTML]: HTML,
  [LANGUAGE_TYPE.JSON]: JSON
};

export const NO_INDENT_LANGUAGES = [
  LANGUAGE_TYPE.CURL,
  LANGUAGE_TYPE.JAVASCRIPT,
  LANGUAGE_TYPE.JSX,
  LANGUAGE_TYPE.HTML,
  LANGUAGE_TYPE.JSON
];

export const DOCS_ZONE_LINK = {
  LEARN_PAYMENT_LINKS_OVERVIEW: "/learn/payment-links/overview",
  LEARN_PAYMENT_LINKS_DETAILS_RESTRICTIONS:
    "/learn/payment-links/payment-details#restrictions",
  LEARN_CHECKOUT_OVERVIEW: "/learn/checkout/overview",
  LEARN_SALES_OVERVIEW: "/learn/sales/overview"
};
