import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import { EDITOR_FLOW, EDITOR_FLOW_DIRECTION } from "utils/constants/editor";
import { VIEWPORT_SIZE } from "./constants/viewport";

export const getEditorFlowLayout = (flow, index, breakpoint = 2) => {
  let layout = EDITOR_FLOW_DIRECTION.LEFT;
  const invert = index && index % breakpoint;
  if (flow === EDITOR_FLOW.LEFT_RIGHT) {
    layout = invert ? EDITOR_FLOW_DIRECTION.RIGHT : EDITOR_FLOW_DIRECTION.LEFT;
  } else if (flow === EDITOR_FLOW.RIGHT_LEFT) {
    layout = invert ? EDITOR_FLOW_DIRECTION.LEFT : EDITOR_FLOW_DIRECTION.RIGHT;
  }
  return layout;
};

const NOT_SMALL_RE = /-ns$/;
const LARGE_RE = /-l$/;

export const transformClassesForViewport = (classes, viewport) => {
  if (VIEWPORT_SIZE.MOBILE === viewport) {
    return classes
      .split(" ")
      .filter(
        (className) =>
          !NOT_SMALL_RE.test(className) && !LARGE_RE.test(className)
      )
      .join(" ");
  } else {
    return classes;
  }
};

export const processViewportClasses = (classes, viewport) => {
  const result = {};
  for (const key in classes) {
    result[key] = transformClassesForViewport(classes[key], viewport);
  }

  return result;
};
export const getPageCheckoutNotice = ({ chargesEnabled, detailsSubmitted }) => {
  let notice;
  if (!detailsSubmitted) {
    notice =
      "Checkout is disabled until business details are submitted and verified by Stripe.";
  } else if (!chargesEnabled) {
    notice =
      "Checkout is disabled until submitted business details have been verified by Stripe.";
  }

  return notice;
};

export const getEditorCheckoutNotice = ({
  isLive,
  dirtyEditor,
  canActivateSettings
}) => {
  let notice;
  if (canActivateSettings) {
    if (!isLive) {
      notice =
        "Checkout is disabled until this page has been published to a route.";
    } else if (
      (typeof dirtyEditor === "boolean" && Boolean(dirtyEditor)) ||
      !isEmpty(dirtyEditor)
    ) {
      notice =
        "You have unsaved page edits which may affect checkout. Save your page to update it.";
    }
  }

  return notice;
};

export const reduceInnerText = (nodes) => {
  const result = [];
  if (nodes) {
    for (const node of nodes) {
      if (node.childNodes && node.childNodes.length > 0) {
        result.push(reduceInnerText(node.childNodes));
      } else if (node.innerText) {
        result.push(node.innerText);
      } else if (node.textContent) {
        result.push(node.textContent);
      }
    }
  }

  return result;
};

export const prepareInnerText = (nodes) => {
  const result = reduceInnerText(nodes);

  return compact(flatten(result)).join(" ");
};

export const getReducedHTMLText = (html) => {
  if (!html) {
    return "";
  }

  const reconstructedFragment = document
    .createRange()
    .createContextualFragment(html);

  return prepareInnerText(reconstructedFragment.childNodes);
};
