import React, { useRef, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";
import { getWebFontConfig, fontFamilyIsLoaded, isSystemFont } from "../theme";
import { useRouter } from "next/router";
import { getConfig } from "utils/env";
import { v4 as uuidv4 } from "uuid";
import { usePageFactoryContext, useViewport } from "utils/context";
import { useToasts } from "react-toast-notifications";
import { CELEBRATE_MESSAGES } from "utils/constants/celebrate";
import FormattedToast from "components/FormattedToast";
import { getFirstPageGuidance } from "utils/guides";
import { getModelIDFromQuery, interpolateRouteWithParams } from "utils/route";
import {
  useAppShortLinks,
  useCouponPromotionCodes,
  useCtxReferences,
  useEvents,
  useFeatureGroupFeatures,
  useIntegrations,
  useMerchantAccounts,
  usePayment,
  useProductsPrices,
  useResourceApiKeys,
  useSession,
  useAppEntities,
  useAppTemplates,
  useTaxRates,
  useSubscriptions,
  useApplications,
  useAttachments,
  useCampaigns,
  useDatasources,
  useAudiences,
  useAppManifests,
  useAppRouteMetadatas,
  useShippingRates,
  useUserRole,
  useHasTestClientKey,
  useManifest,
  useCapabilities,
  useAppContactlessConfigs,
  useAppEntitlementConfigs,
  useResourceSubdomains,
  useInsights,
  useIsAuthed,
  useAppData,
  useLegalContents,
  useEditableContents,
  useWorkflows,
  useAppPagesTemplatesIndex,
  useAppPagesTemplate,
  useMockApplication,
  useUser,
  useWebhooks,
  useEvent
} from "../selectors";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { setCSRF } from "utils/csrf";
import QS from "qs";
import {
  getCtxResourceAndIDs,
  getManifestLiveVariation
} from "utils/mapStateToProps";
import { batchActions } from "redux-batched-actions";
import { unsetMockApplication } from "actions/application";
import { unsetMockMerchantAccount } from "actions/merchantAccount";
import { crispHide, crispShow } from "utils/crisp";
import { CELEBRATION_DURATION } from "utils/constants/ui";
import { APPLICATION, ORGANIZATION, PAGE } from "utils/constants/models";
import { PLATFORM_SOURCE } from "utils/constants/payment";
import PATHS from "utils/paths";
import { getIntervalIndex } from "utils/interval";
import useInterval from "utils/useInterval";
import {
  DESKTOP_SCROLL_CONTAINER,
  scrollTopForId,
  scrollWindowTop
} from "utils/scroll";
import { defaultHttps } from "utils/url";
import { READER } from "utils/constants/role";
import { PREVENT_TEST_MODE } from "utils/constants/merchantAccount";
import { useFormikContext } from "formik";
import { STATE_KEYS } from "utils/constants/state";
import { useAppUrlVariations } from "./application";
import { isPlatformSubdomain } from "utils/subdomain";
import { API_KEY_MODE, API_KEY_TYPE } from "utils/constants/apiKey";
import { SET_TOUCH_TIMEOUT } from "utils/constants/form";
import axios from "axios";
import { authBearerToken } from "utils/auth";
import { setAppVariables } from "actions/app";
import { useEmbed } from "./embed";
import { EMBED_LAYOUT_VIEWPORT_MAP } from "utils/constants/embed";
import { unsetMockUserApplication } from "actions/userApplication";

export const useNotOnMount = () => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      console.log("prepareManifestForEditor");
    } else {
      didMountRef.current = true;
    }
    // observe some values here
  }, []);
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useCtxResourceAndIDs = () =>
  useSelector((state) => getCtxResourceAndIDs(state));

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return isMounted;
};

export const withWebfont = ({ font }) => {
  useEffect(() => {
    if (typeof window !== "undefined" && font) {
      if (!fontFamilyIsLoaded(font.family) && !isSystemFont(font.family)) {
        const WebFont = require("webfontloader");

        WebFont.load({
          google: {
            families: [getWebFontConfig(font)]
          }
        });
      }
    }
  }, [font]);
};

export const usePagePreviewUrl = (url) => {
  const applicationUUID = useModelQueryId(APPLICATION);
  const manifestUUID = useModelQueryId(PAGE);
  const canEdit = Boolean(applicationUUID && manifestUUID);

  return canEdit
    ? `${url}?application=${applicationUUID}&page=${manifestUUID}`
    : url;
};

export const usePageEditMode = () => {
  const router = useRouter();
  const applicationUUID = router.query.application;
  const manifestUUID = router.query.page;

  return {
    editable: Boolean(applicationUUID && manifestUUID),
    applicationUUID,
    manifestUUID,
    builderUrl: `${getConfig(
      "WEB_CLIENT_ROOT"
    )}/applications/${applicationUUID}/pages/${manifestUUID}`
  };
};

export const useErrorDialog = () => {
  const user = useSelector((state) => ({
    name: state.getIn(["user", "data", "display_name"]),
    email: state.getIn(["user", "data", "email"])
  }));

  return (error) => {
    const eventId = uuidv4();
    Sentry.captureException(error);
    Sentry.showReportDialog({
      eventId,
      ...user
    });
  };
};

/**
 * Delete this
 */
export const useHoverProps = ({ hover, setHover }) => {
  const { isSmall } = useViewport();

  return isSmall
    ? {}
    : {
        onMouseEnter: () => !hover && setHover(true),
        onMouseLeave: () => hover && setHover(false)
      };
};

export const useCelebrate = ({ hideToast, message }) => {
  const router = useRouter();
  const { builderUrl } = usePageEditMode();
  const { addToast } = useToasts();
  const celebrateMessage =
    message ||
    (router.query.celebrate && CELEBRATE_MESSAGES[router.query.celebrate]);
  const [celebrate, setCelebrate] = useState(false);

  useEffect(() => {
    if (celebrateMessage && !celebrate) {
      /**
       * Hide crisp due to interaction effect with confetti
       */
      crispHide();

      setCelebrate(!celebrate);

      setTimeout(() => {
        crispShow();
      }, CELEBRATION_DURATION + 1500);

      if (!hideToast) {
        setTimeout(() => {
          const guidance = getFirstPageGuidance({
            builderUrl,
            copy: celebrateMessage
          });

          addToast(<FormattedToast type="info" {...guidance} />, {
            appearance: "info",
            autoDismiss: false
          });
        }, SET_TOUCH_TIMEOUT);
      }
    }
  }, [celebrate]);
  return celebrate;
};

export const useFieldFocusRef = () => {
  const firstNameRef = useRef(null);
  useEffect(() => {
    firstNameRef.current && firstNameRef.current.focus();
  }, []);
  return firstNameRef;
};

export const ensurePayment = ({ fetchMerchantAccountPayment, id }) => {
  const { resourceUUID, resource, merchantAccountUUID } = useCtxReferences();
  const [loading, setLoading] = useState(false);
  const payment = usePayment(id);
  const canRequest = Boolean(resourceUUID && resource);

  useEffect(() => {
    if (canRequest && !payment && !loading && id && merchantAccountUUID) {
      setLoading(true);
      fetchMerchantAccountPayment(
        {
          resourceUUID,
          resource,
          merchantAccountUUID,
          id
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    payment
  };
};

export const ensureSession = ({ fetchMerchantAccountSession, id }) => {
  const { resourceUUID, resource, merchantAccountUUID } = useCtxReferences();
  const canRequest = Boolean(
    resourceUUID && resource && merchantAccountUUID && id
  );
  const [loading, setLoading] = useState(false);
  const session = useSession(id);

  useEffect(() => {
    if (canRequest && !session && !loading && id) {
      setLoading(true);
      fetchMerchantAccountSession(
        {
          resourceUUID,
          resource,
          merchantAccountUUID,
          id
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    session
  };
};

export const ensureEvent = (props) => {
  const { fetchMerchantAccountEvent, uuid } = props;
  const { resourceUUID, resource, merchantAccountUUID } = useCtxReferences();
  const canRequest = Boolean(
    resourceUUID && resource && merchantAccountUUID && uuid
  );
  const [loading, setLoading] = useState(false);
  const force = Boolean(props.force);

  const event = useEvent(uuid, "uuid");

  useEffect(() => {
    if (canRequest && (!event || force) && !loading) {
      setLoading(true);
      fetchMerchantAccountEvent(
        {
          resourceUUID,
          resource,
          merchantAccountUUID,
          uuid
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest, force]);

  return {
    loading,
    event
  };
};

export const ensureEvents = ({ fetchMerchantAccountEvents, data }) => {
  const { resourceUUID, resource, merchantAccountUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resource && merchantAccountUUID);
  const [loading, setLoading] = useState(false);

  const events = useEvents(data.name);

  useEffect(() => {
    if (canRequest && !events.length && !loading) {
      setLoading(true);
      fetchMerchantAccountEvents(
        {
          resourceUUID,
          resource,
          merchantAccountUUID,
          data
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    events
  };
};

export const ensureCapabilities = (props) => {
  const {
    resource: ctxResource,
    resourceUUID: ctxResourceUUID
  } = useCtxReferences();
  const { fetchCapabilities } = props;

  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const canRequest = Boolean(resourceUUID && resource);
  const [loading, setLoading] = useState(false);
  const force = Boolean(props.force);
  const capabilities = useCapabilities({ resource, resourceUUID });

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchCapabilities(
        {
          resourceUUID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest, force]);

  return {
    loading,
    capabilities
  };
};

export const ensureAppConfiguration = (props) => {
  const {
    resource: ctxResource,
    resourceUUID: ctxResourceUUID
  } = useCtxReferences();
  const { fetchAppConfiguration } = props;

  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const [loading, setLoading] = useState(false);
  const force = Boolean(props.force);
  const data = useAppData();
  const needsData = isEmpty(data.onboarding);
  const canRequest = Boolean(resourceUUID && resource && needsData);

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchAppConfiguration(() => {
        setLoading(false);
      });
    }
  }, [canRequest, force]);

  return {
    loading,
    data
  };
};

export const ensureApplications = (props) => {
  const { resourceUUID, resource } = useCtxReferences();
  const { fetchApplications } = props;
  const canRequest = Boolean(resourceUUID && resource);
  const [loading, setLoading] = useState(false);
  const applications = useApplications();
  const force = Boolean(props.force);

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchApplications(
        {
          resourceUUID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest, force]);

  return {
    loading,
    applications
  };
};

export const ensureSubscriptions = ({ fetchSubscriptions }) => {
  const { resourceUUID, resource } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resource);
  const [loading, setLoading] = useState(false);
  const subscriptions = useSubscriptions();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchSubscriptions(
        {
          resourceUUID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    subscriptions
  };
};

export const ensureProductsPrices = (props) => {
  const { fetchMerchantAccountProductsPrices, force } = props;
  const { resourceUUID, resource, merchantAccountUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resource && merchantAccountUUID);

  const [loading, setLoading] = useState(false);

  const { products, prices } = useProductsPrices();

  useEffect(() => {
    const needsProdPrices = !products.length || !prices.length;
    if (canRequest && (needsProdPrices || force) && !loading) {
      setLoading(true);
      fetchMerchantAccountProductsPrices(
        {
          resourceUUID,
          resource,
          uuid: merchantAccountUUID
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    products,
    prices
  };
};

export const ensureMetadatas = (props) => {
  const { fetchMetadatas, force } = props;
  const { resourceUUID, resource, merchantAccountUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resource && merchantAccountUUID);

  const [loading, setLoading] = useState(false);

  const metadatas = useAppRouteMetadatas();

  useEffect(() => {
    const needsModels = !metadatas.length;
    if (canRequest && (needsModels || force) && !loading) {
      setLoading(true);
      fetchMetadatas(
        {
          resourceUUID,
          resource,
          uuid: merchantAccountUUID
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    metadatas
  };
};

export const ensureAppCheckout = ({ fetchAppCheckout }) => {
  const [loading, setLoading] = useState(false);

  const checkout = useSelector((state) =>
    state.getIn(["app", "data", "checkout"])
  );

  useEffect(() => {
    if (isEmpty(checkout.toJS()) && !loading) {
      setLoading(true);
      fetchAppCheckout(() => {
        setLoading(false);
      });
    }
  }, []);

  return { loading, checkout: checkout.toJS() };
};

export const ensureAppVariables = () => {
  let error = null;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const variables = useSelector((state) =>
    state.getIn(["app", "data", "variables"])
  );

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await axios({
        method: "get",
        url: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/app/variables`,
        headers: authBearerToken()
      });
      dispatch(setAppVariables(data));
    } catch (err) {
      error = err;
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isEmpty(variables.toJS()) && !loading) {
      fetchData();
    }
  }, []);

  return {
    loading,
    variables: variables.toJS(),
    error
  };
};

export const ensureAppBuilder = ({ fetchAppBuilder }) => {
  const [loading, setLoading] = useState(false);

  const builder = useSelector((state) =>
    state.getIn(["app", "data", "builder"])
  );

  useEffect(() => {
    if (isEmpty(builder.toJS()) && !loading) {
      setLoading(true);
      fetchAppBuilder(() => {
        setLoading(false);
      });
    }
  }, []);

  return { loading, builder };
};

export const ensureCouponsPromotionCodes = ({
  fetchMerchantAccountCouponsPromotionCodes
}) => {
  const { resourceUUID, resource, merchantAccountUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resource && merchantAccountUUID);
  const [loading, setLoading] = useState(false);

  const { coupons, promotionCodes } = useCouponPromotionCodes();

  useEffect(() => {
    if (canRequest && !coupons.length && !promotionCodes.length && !loading) {
      setLoading(true);
      fetchMerchantAccountCouponsPromotionCodes(
        {
          resourceUUID,
          resource,
          uuid: merchantAccountUUID
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    coupons,
    promotionCodes
  };
};

export const ensureTaxRates = (props) => {
  const {
    resource: ctxResource,
    resourceUUID: ctxResourceUUID,
    merchantAccount: ctxMerchantAccount
  } = useCtxReferences();
  const { fetchMerchantAccountTaxRates, force } = props;

  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const merchantAccount = props.merchantAccount || ctxMerchantAccount;

  const canRequest = Boolean(
    resourceUUID && resource && merchantAccount && merchantAccount.uuid
  );
  const [loading, setLoading] = useState(false);

  const taxRates = useTaxRates({ env: props.env });

  useEffect(() => {
    const needsTaxRates = !taxRates.length;
    if ((needsTaxRates || force) && canRequest && !loading) {
      setLoading(true);
      fetchMerchantAccountTaxRates(
        {
          resourceUUID,
          resource,
          uuid: merchantAccount.uuid
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest, Boolean(force)]);

  return {
    loading,
    taxRates
  };
};

export const ensureShippingRates = (props) => {
  const {
    resource: ctxResource,
    resourceUUID: ctxResourceUUID,
    merchantAccount: ctxMerchantAccount
  } = useCtxReferences();
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  const { fetchMerchantAccountShippingRates, force } = props;

  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const merchantAccount = props.merchantAccount || ctxMerchantAccount;

  const canRequest = Boolean(
    resourceUUID &&
      resource &&
      merchantAccount &&
      merchantAccount.uuid &&
      // Force will allow for override of already fetched control
      (!fetched || force)
  );

  const shippingRates = useShippingRates({ env: props.env });

  useEffect(() => {
    const needsShippingRates = !shippingRates.length;
    if ((needsShippingRates || force) && !loading && canRequest) {
      setLoading(true);
      setFetched(true);
      fetchMerchantAccountShippingRates(
        {
          resourceUUID,
          resource,
          uuid: merchantAccount.uuid
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    shippingRates
  };
};

export const ensureManifests = ({ fetchManifests }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);

  const manifests = useAppManifests();

  useEffect(() => {
    if (canRequest && !manifests.length && !loading) {
      setLoading(true);
      fetchManifests(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    manifests
  };
};

export const ensureShortLinks = ({ fetchShortLinks }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const shortLinks = useAppShortLinks();

  useEffect(() => {
    if (canRequest && !shortLinks.length && !loading) {
      setLoading(true);
      fetchShortLinks(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    shortLinks
  };
};

/**
 * Manage these requests
 */
export const ensureAttachments = (props) => {
  const { fetchAttachments, force } = props;
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const attachments = useAttachments();

  useEffect(() => {
    const needsAttachments = !attachments.length;
    if ((needsAttachments || force) && !loading && canRequest) {
      setLoading(true);
      fetchAttachments(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest, Boolean(force)]);

  return {
    loading,
    attachments
  };
};

export const ensureCampaigns = ({ fetchCampaigns }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const campaigns = useCampaigns();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchCampaigns(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    campaigns
  };
};

export const ensureWorkflows = ({ fetchWorkflows }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const workflows = useWorkflows();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchWorkflows(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    workflows
  };
};

export const ensureWebhooks = ({ fetchWebhooks }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const webhooks = useWebhooks();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchWebhooks(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    webhooks
  };
};

export const ensureLegalContents = ({ fetchLegalContents }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const legalContents = useLegalContents();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchLegalContents(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    legalContents
  };
};

export const ensureEditableContents = ({ fetchEditableContents }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const editableContents = useEditableContents();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchEditableContents(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    editableContents
  };
};

export const ensureContactlessConfigs = ({ fetchContactlessConfigs }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const contactlessConfigs = useAppContactlessConfigs();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchContactlessConfigs(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    contactlessConfigs
  };
};

export const ensureEntitlementConfigs = ({ fetchEntitlementConfigs }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const entitlementConfigs = useAppEntitlementConfigs();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchEntitlementConfigs(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    entitlementConfigs
  };
};

export const ensureDatasources = ({ fetchDatasources }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const datasources = useDatasources();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchDatasources(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    datasources
  };
};

export const ensureAudiences = ({ fetchAudiences }) => {
  const { resource, resourceID, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);
  const audiences = useAudiences();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchAudiences(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    audiences
  };
};

export const ensureLatestInsights = (props) => {
  const {
    resource: ctxResource,
    resourceID: ctxResourceID,
    resourceUUID: ctxResourceUUID
  } = useCtxReferences();
  const resourceID = props.resourceID || ctxResourceID;
  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const canRequest = Boolean(resourceUUID && resourceID && resource);
  const force = Boolean(props.force);

  const { fetchLatestInsights } = props;
  const [loading, setLoading] = useState(false);
  const insights = useInsights();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchLatestInsights(
        {
          resourceUUID,
          resourceID,
          resource,
          subdomain: props.subdomain
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest, force]);

  return {
    loading,
    insights
  };
};

export const ensureMerchantAccounts = (props) => {
  const {
    resource: ctxResource,
    resourceID: ctxResourceID,
    resourceUUID: ctxResourceUUID
  } = useCtxReferences();
  const { fetchMerchantAccounts } = props;
  const force = Boolean(props.force);

  const resourceID = props.resourceID || ctxResourceID;
  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const canRequest = Boolean(resourceUUID && resourceID && resource);

  const [loading, setLoading] = useState(false);

  const merchantAccounts = useMerchantAccounts();

  useEffect(() => {
    if (canRequest && !loading) {
      setLoading(true);
      fetchMerchantAccounts(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest, force]);

  return {
    loading,
    merchantAccounts
  };
};

export const ensureApiKeys = ({ fetchApiKeys }) => {
  const { resource, resourceUUID, resourceID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resourceID && resource);
  const [loading, setLoading] = useState(false);

  const apiKeys = useResourceApiKeys();

  useEffect(() => {
    if (!apiKeys.length && !loading && canRequest) {
      setLoading(true);
      fetchApiKeys(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    apiKeys
  };
};

export const useApiKey = ({
  mode = API_KEY_MODE.LIVE,
  type = API_KEY_TYPE.PUBLIC
}) => {
  let result;
  const apiKeys = useResourceApiKeys();
  if (apiKeys && apiKeys.length) {
    result = apiKeys.find(
      (apiKey) => apiKey.mode === mode && apiKey.type === type
    );
  }

  return result;
};

/**
 * Only the Platform should fetch connect subdomains
 */
export const ensureConnectSubdomains = ({ fetchConnectSubdomains }) => {
  const {
    resource,
    resourceUUID,
    resourceID,
    application
  } = useCtxReferences();

  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const isPlatform = Boolean(
    application &&
      application.subdomain &&
      isPlatformSubdomain(application.subdomain)
  );
  const subdomains = useResourceSubdomains();
  const canRequest = Boolean(
    fetchConnectSubdomains &&
      resourceUUID &&
      resourceID &&
      resource === ORGANIZATION &&
      isPlatform &&
      !fetched &&
      !loading &&
      !subdomains.length
  );

  useEffect(() => {
    if (canRequest) {
      setLoading(true);
      setFetched(true);
      fetchConnectSubdomains(
        {
          resourceUUID,
          resourceID,
          resource
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    subdomains
  };
};

export const ensureFeatureGroupFeatures = (props) => {
  const {
    resource: ctxResource,
    resourceID: ctxResourceID,
    resourceUUID: ctxResourceUUID,
    merchantAccount: ctxMerchantAccount
  } = useCtxReferences();

  const { fetchFeatureGroupFeatures, force } = props;
  const resource = props.resource || ctxResource;
  const resourceID = props.resourceID || ctxResourceID;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const merchantAccount = props.merchantAccount || ctxMerchantAccount;

  const canRequest = Boolean(
    resourceUUID &&
      resource &&
      resourceID &&
      merchantAccount &&
      merchantAccount.uuid
  );
  const [loading, setLoading] = useState(false);
  const { features, featureGroups } = useFeatureGroupFeatures();

  useEffect(() => {
    const needsFeatureGroupFeature = !featureGroups.length || !features.length;

    if ((needsFeatureGroupFeature || force) && !loading && canRequest) {
      setLoading(true);

      fetchFeatureGroupFeatures(
        {
          resourceUUID,
          resourceID,
          resource,
          test: props.test
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canRequest]);

  return {
    loading,
    features,
    featureGroups
  };
};

export const ensureAppEntities = (props) => {
  const fetchAppEntities = props.fetchAppEntities;
  const {
    resource: ctxResource,
    resourceUUID: ctxResourceUUID
  } = useCtxReferences();
  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const [loading, setLoading] = useState(false);
  const entities = useAppEntities();
  const canRequest = Boolean(
    resourceUUID && resource && !loading && !entities.length
  );

  useEffect(() => {
    if (canRequest) {
      setLoading(true);

      fetchAppEntities(() => {
        setLoading(false);
      });
    }
  }, [canRequest]);

  return {
    loading,
    entities
  };
};

export const ensureAppTemplates = ({ fetchAppTemplates }) => {
  const { resource, resourceUUID } = useCtxReferences();
  const [loading, setLoading] = useState(false);
  const templates = useAppTemplates();
  const needsTemplates = isEmpty(templates);

  const canRequest = Boolean(
    resourceUUID && resource && !loading && needsTemplates
  );

  useEffect(() => {
    if (canRequest) {
      setLoading(true);

      fetchAppTemplates(() => {
        setLoading(false);
      });
    }
  }, [canRequest]);

  return {
    loading,
    templates
  };
};

export const ensureAppPagesTemplatesIndex = (props) => {
  const { fetchAppPagesTemplatesIndex } = props;
  const {
    resource: ctxResource,
    resourceUUID: ctxResourceUUID
  } = useCtxReferences();
  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const [loading, setLoading] = useState(false);
  const index = useAppPagesTemplatesIndex();

  const needsIndex = isEmpty(index);

  const canRequest = Boolean(
    resourceUUID && resource && !loading && needsIndex
  );

  useEffect(() => {
    if (canRequest) {
      setLoading(true);

      fetchAppPagesTemplatesIndex(() => setLoading(false));
    }
  }, [canRequest]);

  return {
    loading,
    index
  };
};

export const ensureAppPagesTemplate = (props) => {
  const { fetchAppPagesTemplate, uuid } = props;
  const {
    resource: ctxResource,
    resourceUUID: ctxResourceUUID
  } = useCtxReferences();
  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const [loading, setLoading] = useState(false);
  const [requested, setRequested] = useState(false);

  const template = useAppPagesTemplate(uuid);

  const needsTemplate = isEmpty(template);

  const canRequest = Boolean(
    uuid && resourceUUID && resource && !loading && needsTemplate && !requested
  );

  useEffect(() => {
    if (canRequest) {
      setLoading(true);
      setRequested(true);

      fetchAppPagesTemplate(uuid, () => setLoading(false));
    }
  }, [canRequest]);

  return {
    loading,
    template
  };
};

export const ensureMockUserDetails = (props) => {
  const { fetchMockUserDetails } = props;

  const [loading, setLoading] = useState(false);
  const [requested, setRequested] = useState(false);

  const user = useUser();
  const mockApp = useMockApplication();
  const hasMockApp = Boolean(mockApp && mockApp.id);
  const needsData = !hasMockApp;

  const canRequest = Boolean(!loading && !requested);

  useEffect(() => {
    if (canRequest && needsData) {
      setLoading(true);
      setRequested(true);

      fetchMockUserDetails(false, () => setLoading(false));
    }
  }, [canRequest, needsData]);

  return {
    loading,
    user
  };
};

export const ensureProductsPricesCouponsPromotionCodes = (props) => {
  const {
    resource: ctxResource,
    resourceUUID: ctxResourceUUID,
    merchantAccount: ctxMerchantAccount
  } = useCtxReferences();
  const {
    fetchMerchantAccountProductsPrices,
    fetchMerchantAccountCouponsPromotionCodes,
    force
  } = props;

  const resource = props.resource || ctxResource;
  const resourceUUID = props.resourceUUID || ctxResourceUUID;
  const merchantAccount = props.merchantAccount || ctxMerchantAccount;

  const canRequest = Boolean(
    resourceUUID && resource && merchantAccount && merchantAccount.uuid
  );
  const [loadingProductsPrices, setLoadingProductsPrices] = useState(false);
  const [
    loadingCouponsPromotionCodes,
    setLoadingCouponsPromotionCodes
  ] = useState(false);
  /**
   * Enable the override of env via props
   */
  const envProps = { env: props.env };
  const { products, prices } = useProductsPrices(envProps);
  const { coupons, promotionCodes } = useCouponPromotionCodes(envProps);

  useEffect(() => {
    const needsProdPrices = !products.length || !prices.length;

    if ((needsProdPrices || force) && !loadingProductsPrices && canRequest) {
      setLoadingProductsPrices(true);
      fetchMerchantAccountProductsPrices(
        {
          resourceUUID,
          resource,
          uuid: merchantAccount.uuid,
          test: props.test,
          force
        },
        () => {
          setLoadingProductsPrices(false);
        }
      );
    }
    const needsCoupons = !coupons.length;
    if (needsCoupons && !loadingCouponsPromotionCodes && canRequest) {
      setLoadingCouponsPromotionCodes(true);
      fetchMerchantAccountCouponsPromotionCodes(
        {
          resourceUUID,
          resource,
          uuid: merchantAccount.uuid,
          test: props.test
        },
        () => {
          setLoadingCouponsPromotionCodes(false);
        }
      );
    }
  }, [canRequest, Boolean(force)]);

  return {
    loadingProductsPrices,
    loadingCouponsPromotionCodes,
    coupons,
    promotionCodes,
    products,
    prices
  };
};

export const ensureIntegrations = ({ fetchIntegrations }) => {
  const { resource, resourceUUID } = useCtxReferences();
  const canRequest = Boolean(resourceUUID && resource);
  const [loading, setLoading] = useState(false);

  const integrations = useIntegrations();
  /**
   * An integration needs details when its raw values from the source are not present
   */
  const needsDetails = integrations.find(({ raw }) => isEmpty(raw));

  useEffect(() => {
    if (canRequest) {
      if (!integrations.length || needsDetails || !loading) {
        setLoading(true);
        fetchIntegrations(
          {
            resourceUUID,
            resource
          },
          () => {
            setLoading(false);
          }
        );
      }
    }
  }, [canRequest]);

  return {
    loading,
    integrations
  };
};

export const useOAuthInitiateUrl = ({ service, url, params }) => {
  /**
   * Set a CSRF token on the client to check on return to validate request
   */
  const csrfState = useMemo(() => uuidv4(), []);
  useEffect(() => {
    if (service) {
      setCSRF(service, csrfState);
    }
  }, [service, url]);

  if (service && url) {
    const stringified = QS.stringify(
      {
        ...params,
        state: csrfState
      },
      { addQueryPrefix: true }
    );

    return `${url}${stringified}`;
  } else {
    return "";
  }
};

export const useTabPathPrefix = () => {
  const { query } = useRouter();
  return query.tab || "";
};

export const useModelQueryId = (model) => {
  const { query } = useRouter();

  return getModelIDFromQuery(model, query);
};

export const clearMockReducers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      batchActions([
        unsetMockApplication(),
        unsetMockMerchantAccount(),
        unsetMockUserApplication()
      ])
    );
  }, []);
};

export const useContentQueryParams = () => {
  const { query } = useRouter();
  const isPlatformPageSource = query.platform_source === PLATFORM_SOURCE.PAGE;
  const hasReturnTo = query && query.return_to;

  return {
    isPlatformPageSource,
    hasReturnTo,
    query
  };
};

export const useAppManifestSectionDestination = (section) => {
  const appId = useModelQueryId(APPLICATION);
  const pageId = useModelQueryId(PAGE);

  return interpolateRouteWithParams(PATHS.APPLICATIONS_PAGES_SHOW_SECTION, {
    application: {
      uuid: appId
    },
    page: {
      uuid: pageId
    },
    section: {
      uuid: section
    }
  });
};

export const useCardMaxWidth = () => {
  const { isSmall, isTablet } = useViewport();
  let pageWidth;
  if (typeof window !== "undefined") {
    const PH_THREE_GUTTERS = 32;
    pageWidth = window.innerWidth - PH_THREE_GUTTERS;
  }

  return isTablet ? 300 : isSmall ? pageWidth : 380;
};

const DEFAULT_INTERVAL = 4000;

export const useIntervalValue = (values, interval = DEFAULT_INTERVAL) => {
  const [counter, setCounter] = useState(0);
  const valuesCount = values.length;

  const targetIndex = getIntervalIndex(counter, valuesCount);

  useInterval(() => {
    setCounter(counter + 1);
  }, interval);

  return Number.isInteger(targetIndex) && values[targetIndex]
    ? values[targetIndex]
    : values[0];
};

export const useScrollTopOnMount = (id = DESKTOP_SCROLL_CONTAINER) => {
  useEffect(() => {
    scrollTopForId(id);
  }, []);
};

export const useScrollWindowTop = () => {
  useEffect(() => {
    scrollWindowTop();
  }, []);
};

export const useFallbackRedirectUrls = () => {
  const { resource, merchantAccount } = useCtxReferences();
  const isOrg = resource === ORGANIZATION;
  const urlVariations = useAppUrlVariations();

  const pathRoot = isOrg ? urlVariations.subdomain : urlVariations.alias;
  const merchantRootURL = get(merchantAccount, "profile.url");

  // Success URL
  const fallbackSuccessURL = `${pathRoot}${PATHS.CHECKOUT_SUCCESS}`;
  // Cancel URL
  const fallbackErrorURL = `${pathRoot}${PATHS.CHECKOUT_ERROR}`;
  const cancelUrl = defaultHttps(merchantRootURL || fallbackErrorURL);

  return {
    successUrl: fallbackSuccessURL,
    cancelUrl: cancelUrl
  };
};

export const useCanEnableTestMode = () => {
  const { merchantAccount } = useCtxReferences();
  const role = useUserRole();
  const isAuthed = useIsAuthed();
  const hasTestClientKey = useHasTestClientKey();

  /**
   * Statuses like suspended / canceled / archived / deferred cannot enter test mode
   */
  const canEnterTestMode = Boolean(
    merchantAccount &&
      merchantAccount.status &&
      PREVENT_TEST_MODE.indexOf(merchantAccount.status) === -1
  );

  return (
    isAuthed &&
    role !== READER &&
    merchantAccount &&
    !hasTestClientKey &&
    canEnterTestMode
  );
};

export const ensureResources = (actions, references) => {
  ensureAppCheckout({
    fetchAppCheckout: actions.fetchAppCheckout
  });

  const {
    products,
    prices,
    coupons,
    promotionCodes,
    loadingProductsPrices,
    loadingCouponsPromotionCodes
  } = ensureProductsPricesCouponsPromotionCodes({
    fetchMerchantAccountProductsPrices:
      actions.fetchMerchantAccountProductsPrices,
    fetchMerchantAccountCouponsPromotionCodes:
      actions.fetchMerchantAccountCouponsPromotionCodes,
    ...references
  });
  const { taxRates, loading: loadingTaxRates } = ensureTaxRates({
    fetchMerchantAccountTaxRates: actions.fetchMerchantAccountTaxRates,
    ...references
  });
  const { shippingRates, loading: loadingShippingRates } = ensureShippingRates({
    fetchMerchantAccountShippingRates:
      actions.fetchMerchantAccountShippingRates,
    ...references
  });
  const { attachments, loading: loadingAttachments } = ensureAttachments({
    fetchAttachments: actions.fetchAttachments,
    ...references
  });

  return {
    products,
    prices,
    coupons,
    promotionCodes,
    taxRates,
    shippingRates,
    attachments,
    loading: Boolean(
      loadingProductsPrices ||
        loadingCouponsPromotionCodes ||
        loadingTaxRates ||
        loadingShippingRates ||
        loadingAttachments
    )
  };
};

export const useEditorViewport = () => {
  const formContext = useFormikContext();
  const pageContext = usePageFactoryContext();
  const embed = useEmbed();

  let editorViewport;
  if (formContext && pageContext) {
    const { values } = formContext;
    const { canActivateSettings } = pageContext;

    if (canActivateSettings) {
      editorViewport = get(values, STATE_KEYS.EDITOR.VIEWPORT);
    } else if (embed && embed.layout) {
      editorViewport = EMBED_LAYOUT_VIEWPORT_MAP[embed.layout];
    }
  }

  return editorViewport;
};

export const useCurrentManifestLiveVariation = () => {
  const ctxReferences = useCtxReferences();
  const ctxForm = useFormikContext();
  const uuid = get(ctxForm, "values.manifest.uuid");
  const manifest = useManifest(uuid, "uuid");
  if (ctxReferences && ctxForm && manifest) {
    const { application } = ctxReferences;
    return getManifestLiveVariation({
      id: manifest.id,
      application
    });
  }
};
