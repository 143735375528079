import C from "constants/metadata";
import { devLogger } from "utils/debug";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { getConfig } from "utils/env";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_METADATA_STORE,
    payload: {
      isLoading: true
    }
  };
}

export function setMetadatas(payload) {
  return {
    type: C.SET_METADATAS,
    payload
  };
}

export function resetMetadataNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_METADATA_STORE,
      payload
    });
  };
}

export const METADATA_MODELS = ["metadatas"];

export const METADATA_ACTIONS_MAP = {
  setMetadatas
};

/**
 * updateMetadata
 */
export const updateMetadata = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/metadatas/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: metadata }) => {
        const metadatas = Array.isArray(metadata) ? metadata : [metadata];

        const actions = [
          ActionHelpers.clearLoader(C.UPDATE_METADATA_STORE),
          setMetadatas(metadatas),
          ActionHelpers.successNote(C.UPDATE_METADATA_STORE, "Updated")
        ];

        dispatch(batchActions(actions));
        callback(null, metadata);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_METADATA_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchMetadatas
 */
export const fetchMetadatas = (
  { resourceUUID, resource },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/metadatas`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: metadata }) => {
        const metadatas = Array.isArray(metadata) ? metadata : [metadata];

        const actions = [
          ActionHelpers.clearLoader(C.UPDATE_METADATA_STORE),
          setMetadatas(metadatas)
        ];

        dispatch(batchActions(actions));
        callback(null, metadata);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_METADATA_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

export const importMetadata = async (data, onError) => {
  try {
    const response = await axios({
      method: "post",
      url: `${getConfig("API_ROOT")}/v1/metadata/import`,
      data,
      headers: authBearerToken()
    });
    devLogger("metadata.import", response.data);
    return response.data;
  } catch (error) {
    onError &&
      onError("Unable to import from that url", {
        appearance: "error",
        autoDismiss: false
      });
    return error;
  }
};

/**
 * createMetadata
 */
export const createMetadata = (
  { resourceUUID, resource, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/metadatas`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: metadata }) => {
        const metadatas = Array.isArray(metadata) ? metadata : [metadata];

        const actions = [
          ActionHelpers.clearLoader(C.UPDATE_METADATA_STORE),
          setMetadatas(metadatas),
          ActionHelpers.successNote(C.UPDATE_METADATA_STORE, "Created")
        ];

        dispatch(batchActions(actions));
        callback(null, metadata);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_METADATA_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
