const APPLICATION = "application";
const EMAIL = "email";
const MERCHANT_ACCOUNTS = "merchant_accounts";
const BILLING = "billing";
const INTEGRATIONS = "integrations";
const METADATA = "metadata";
const CHECKOUT = "checkout";
const CONTACTLESS = "contactless";
const DEVELOPMENT = "development";
const BRANDING = "branding";

export const SETTINGS_TABS = {
  APPLICATION,
  MERCHANT_ACCOUNTS,
  EMAIL,
  INTEGRATIONS,
  BILLING,
  METADATA,
  CHECKOUT,
  CONTACTLESS,
  DEVELOPMENT,
  BRANDING
};
