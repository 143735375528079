import CONSTANTS from "constants/computation";
import { fromJS } from "immutable";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: {
    /**
     * Each application has its assets keyed with computations beneath it
     * priceblocs: {
     *  /pricing: [
     *  {view_1}, {view_2}, {view_3}
     * ]
     *  shortId123: [
     *  {view_1}, {view_2}, {view_3}
     * ]
     * }
     */
  }
});

const internals = {
  [CONSTANTS.SET_SUBDOMAIN_COMPUTATIONS]: (
    state,
    { payload: { subdomain, data, route, shortId } }
  ) => {
    const dataKey = route || shortId;
    const input = {
      [dataKey]: data
    };

    return state.setIn(["data", subdomain], fromJS(input));
  },
  [CONSTANTS.UPDATE_COMPUTATION_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
