import get from "lodash/get";
import { STATE_KEYS } from "utils/constants/state";
import { shortLinkHasRecurringPrices } from "utils/shortLink";

export const getShippingAddressCollectionActiveFormKey = (values) => {
  const hasAllowedCountries = get(
    values,
    STATE_KEYS.SHORT_LINK.SHIPPING_ALLOWED_COUNTRIES,
    []
  ).length;
  const hasWorldwideShipping = get(
    values,
    STATE_KEYS.SHORT_LINK.SHIPPING_WORLDWIDE,
    false
  );

  return Boolean(hasAllowedCountries || hasWorldwideShipping);
};

export const getTrialPeriodDaysActiveFormKey = (values) => {
  const trialPeriodDays = get(values, STATE_KEYS.SHORT_LINK.TRIAL_PERIOD_DAYS);
  return Number.isInteger(trialPeriodDays) && trialPeriodDays > 0;
};

export const getRecurringTrialPeriodDaysActiveFormKey = (values) => {
  const hasTrialPeriodDays = getTrialPeriodDaysActiveFormKey(values);
  const hasRecurring = shortLinkHasRecurringPrices(values);

  return Boolean(hasTrialPeriodDays && hasRecurring);
};
