import C from "constants/user";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const INITIAL_USER = {
  id: null,
  uuid: null,
  email: null,
  display_name: null,
  secured_search_key: null,
  stripe_id: null,
  updated_at: null,
  created_at: null,
  confirmed: false,
  organizations: null,
  profiles: [],
  metadatas: [],
  secure_tokens: [],
  api_keys: [],
  organization_users: [],
  /**
   * These are actually thin User records not ManagerUser records
   */
  managers: [],
  subordinates: [],

  // Deconstruct these two things to their own reducers
  education: [],
  subscriptions: [],
  charges: [],
  creators: [],
  accounts: [],
  plans: [],
  onboardings: [],
  subdomains: []
};

export const initialState = fromJS({
  isLoading: false,
  loading: false,
  note: null,
  data: INITIAL_USER
});

const internals = {
  /**
   * UPDATE_USER_STORE
   *
   * @param {Object} payload
   *  @param {Bool} isLoading
   *  @param {Object} error
   * @api public
   */
  [C.UPDATE_USER_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  },
  /**
   * UPDATE_USER
   *
   * @param {Object} payload
   * @api public
   */
  [C.UPDATE_USER]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  /**
   * UPDATE_USER_EDUCATION
   *
   * @param {Object} payload
   * @api public
   */
  [C.UPDATE_USER_EDUCATION]: (state, { payload }) => {
    return state.setIn(["data", "education"], fromJS(payload));
  },
  /**
   * REMOVE_USER_MANAGER_USERS
   *
   * @param {Object} payload
   * @api public
   */
  [C.REMOVE_USER_MANAGER_USERS]: (state, { payload }) => {
    const collection = Array.isArray(payload) ? payload : [payload];

    for (let i = 0; i < collection.length; i++) {
      const element = collection[i];
      const { id } = element;
      state = helpers.inActionAtIndex(
        "remove",
        state,
        ["data", "managers"],
        id,
        ["id"]
      );
      state = helpers.inActionAtIndex(
        "remove",
        state,
        ["data", "subordinates"],
        id,
        ["id"]
      );
    }

    return state;
  },
  /**
   * SET_USER_MANAGER_USERS
   *
   * @param {Object} payload
   * @param {Object} payload.managers
   * @param {Object} payload.subordinates
   * @api public
   */
  [C.SET_USER_MANAGER_USERS]: (state, { payload }) => {
    const managers = payload.managers || [];
    const subordinates = payload.subordinates || [];

    state = helpers.setIn(state, ["data", "managers"], "id", managers);
    state = helpers.setIn(state, ["data", "subordinates"], "id", subordinates);

    return state;
  },

  /**
   * SET_USER_API_KEYS
   *
   * @param {Object} payload
   * @api public
   */
  [C.SET_USER_API_KEYS]: (state, { payload: { apiKeys } }) => {
    return helpers.setIn(state, ["data", "api_key"], "id", apiKeys);
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
