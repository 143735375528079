const CHURN_PROTECTION_EXPIRATION_SUBSCRIPTION =
  "churn_protection_expiration_subscription";
const PAGES_CHECKOUT_PERFORMANCE = "pages_checkout_performance";
const PRODUCTS_CHECKOUT_PERFORMANCE = "products_checkout_performance";
const AFFILIATES_CHECKOUT_PERFORMANCE = "affiliates_checkout_performance";

export const INSIGHT_TYPE = {
  CHURN_PROTECTION_EXPIRATION_SUBSCRIPTION,
  PAGES_CHECKOUT_PERFORMANCE,
  PRODUCTS_CHECKOUT_PERFORMANCE,
  AFFILIATES_CHECKOUT_PERFORMANCE
};

export const INSIGHT_TYPE_LABEL_MAP = {
  [PAGES_CHECKOUT_PERFORMANCE]: "Pages ranked",
  [PRODUCTS_CHECKOUT_PERFORMANCE]: "Products ranked",
  [AFFILIATES_CHECKOUT_PERFORMANCE]: "Affiliates ranked",
  [CHURN_PROTECTION_EXPIRATION_SUBSCRIPTION]: "Expiration protection"
};

const CSV = "csv";
export const EXPORT_OPTION = {
  CSV
};

export const EXPORT_OPTION_LABEL_MAP = {
  [EXPORT_OPTION.CSV]: "CSV"
};

export const INSIGHT_TOTAL_KEYS = [
  {
    id: "formattedArr",
    label: "Annual total"
  },
  {
    id: "formattedAtRiskArr",
    label: "Expiry risk"
  },
  {
    id: "riskPct",
    label: "Risk percentage"
  }
];
