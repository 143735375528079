import React from "react";
import FormattedToast from "components/FormattedToast";
import upperFirst from "lodash/upperFirst";
import cloneDeep from "lodash/cloneDeep";
import { devLogger } from "./debug";
import { getValue } from "./data";
import { defaultHttps } from "./url";
import { getLinkGuidance } from "./guides";
import { BUTTON_TAGS } from "./constants/ui";

export const reduceModelActions = (props) => {
  const { keys, action, resource, data, actionsMap } = props;
  const actions = [];
  keys.forEach((modelKey) => {
    /**
     * Example values for assoc action key construction
     * 1. action - set
     * 2. resource - user
     * 3. modelKey - merchantAccounts
     */

    /**
     * Process model
     */
    const models = data[modelKey];
    const actionKey = `${action}${upperFirst(modelKey)}`;
    devLogger(`models ${actionKey}`, models);

    const modelAction = actionsMap[actionKey];
    if (!modelAction) {
      throw new Error(`No action registered for ${actionKey}`);
    }
    if (models && modelAction) {
      actions.push(modelAction(models));
    }

    /**
     * Process associations
     */
    const assocModelKey = `${resource}${upperFirst(modelKey)}`;
    const assocModels = data[assocModelKey];
    const assocActionKey = `${action}${upperFirst(resource)}${upperFirst(
      modelKey
    )}`;

    const assocAction = actionsMap[assocActionKey];
    if (!assocAction) {
      throw new Error(`No assoc action registered for ${assocActionKey}`);
    }

    if (assocModels && assocAction) {
      devLogger(`assocModels ${assocActionKey}`, assocModels);
      actions.push(assocAction(assocModels));
    }
  });

  return actions;
};

export const NO_OP = () => {};

export const getLinkActionButtonProps = (
  props,
  { href, canActivateSettings, editable, builderUrl, canGiveGuidance, addToast }
) => {
  const result = cloneDeep(props);
  result.tag = BUTTON_TAGS.ANCHOR;
  const linkValue = defaultHttps(getValue(href));

  if (linkValue) {
    result.href = linkValue;
    result.disabled = false;
  } else {
    if (canGiveGuidance) {
      result.disabled = false;
      result.tag = BUTTON_TAGS.BUTTON;
      const guidance = getLinkGuidance({
        isBuilder: canActivateSettings,
        isPreview: editable,
        builderUrl
      });
      result.onClick = () => {
        addToast(<FormattedToast {...guidance} />, {
          appearance: "error",
          autoDismiss: false
        });
      };
    } else {
      result.disabled = true;
    }
  }

  return result;
};
