import CONSTANTS from "constants/userApplication";
import { fromJS } from "immutable";
import { MOCK_UUID } from "utils/constants/request";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const APPLICATION_ID = "application_id";
const USER_ID = "user_id";

const internals = {
  [CONSTANTS.SET_USER_APPLICATIONS]: (state, { payload }) => {
    return helpers.setAssociationsIn(state, payload, APPLICATION_ID, USER_ID);
  },
  [CONSTANTS.REPLACE_USER_APPLICATIONS]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  [CONSTANTS.UNSET_MOCK_USER_APPLICATION]: (state) => {
    state = helpers.inActionAtIndex("remove", state, ["data"], MOCK_UUID, [
      "application_id"
    ]);
    return state;
  },
  [CONSTANTS.UPDATE_USER_APPLICATION_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
