const NO_SUBDOMAIN_MESSAGE = "No subdomain value found for your application.";
const STRIPE_CHARGES_DISABLED_MESSAGE = {
  PREVIEW:
    "Stripe charges are not yet enabled on your account. Complete your Stripe onboarding or edit this action type to be a link within your page builder.",
  BUILDER:
    "Stripe charges are not yet enabled on your account. Complete your Stripe onboarding or edit this action type to be a link."
};

const getEditPageAction = ({ builderUrl }) => ({
  copy: "Edit page",
  href: builderUrl
});

export const getCheckoutGuidance = ({
  isBuilder,
  isPreview,
  builderUrl,
  chargesEnabled,
  subdomain
  // isLive,
  // formData,
  // alias
}) => {
  const result = {
    copy: "",
    actions: []
  };
  if (isPreview) {
    if (!chargesEnabled) {
      result.copy = STRIPE_CHARGES_DISABLED_MESSAGE.PREVIEW;
    } else if (!subdomain) {
      result.copy = NO_SUBDOMAIN_MESSAGE;
    }
    if (builderUrl) {
      result.actions = [getEditPageAction({ builderUrl })];
    }
  } else if (isBuilder) {
    if (!chargesEnabled) {
      result.copy = STRIPE_CHARGES_DISABLED_MESSAGE.BUILDER;
    } else if (!subdomain) {
      result.copy = NO_SUBDOMAIN_MESSAGE;
    }
  }

  return result;
};

export const getLinkGuidance = ({ isPreview, builderUrl }) => {
  const result = {
    copy:
      "No url set for this link. You can set one within the link settings menu.",
    actions: [],
    type: "error"
  };
  if (isPreview) {
    if (builderUrl) {
      result.actions = [getEditPageAction({ builderUrl })];
    }
  }
  return result;
};

export const getFirstPageGuidance = ({ copy, builderUrl }) => {
  const result = {
    copy
  };
  if (builderUrl) {
    result.copy += ` You can edit this page using the page builder`;
    result.actions = [getEditPageAction({ builderUrl })];
  }
  return result;
};
