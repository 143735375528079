import React from "react";
import { COLORS } from "utils/styles";

const COLOR_MAP = {
  error: "#BF2600",
  info: COLORS.royal_blue
};

const FormattedToast = ({ copy, actions, type }) => {
  return (
    <div className="f6">
      {copy}
      {actions &&
        actions.map(({ href, copy }, actionIx) => (
          <span key={actionIx}>
            <a
              className="link__underline pl1 fw5"
              style={{ color: COLOR_MAP[type] }}
              href={href}
            >
              {copy}
            </a>
          </span>
        ))}
    </div>
  );
};
export default FormattedToast;
