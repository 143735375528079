import { CAPTURE_METHOD } from "./payment";
import { TAX_ID_COLLECTION_TYPE } from "./taxRate";

export const STRIPE_SESSION_ID_QS_PARAM = "session_id={CHECKOUT_SESSION_ID}";

const ACCESS_DENIED = "access_denied";
const INVALID_SCOPE = "invalid_scope";
const INVALID_REDIRECT_URI = "invalid_redirect_uri";
const INVALID_REQUEST = "invalid_request";
const UNSUPPORTED_RESPONSE_TYPE = "unsupported_response_type";

export const STRIPE_OAUTH_ERROR_CONTENT = {
  [ACCESS_DENIED]: {
    title: "Connect with Stripe",
    body: [
      `Your recent attempt to connect a Stripe account was interrupted.`,
      `If you'd like to try again, click below.`
    ]
  },
  [INVALID_REQUEST]: {
    title: "Request error",
    body: [`There was a problem with that request. Click to try again.`]
  }
};

// https://stripe.com/docs/connect/oauth-reference#get-authorize-error-codes
export const STRIPE_OAUTH_ERROR = {
  ACCESS_DENIED,
  INVALID_SCOPE,
  INVALID_REDIRECT_URI,
  INVALID_REQUEST,
  UNSUPPORTED_RESPONSE_TYPE
};

export const STRIPE_ROUTES = {
  CONNECT_ACCOUNTS: "connect/accounts",
  SETTINGS: "settings",
  ACCOUNT: "settings/account",
  BRANDING: "settings/branding",
  CHECKOUT: "settings/checkout",
  UPDATE: "settings/update",
  VERIFICATIONS: "settings/verifications",
  EMAILS: "settings/emails",
  PRODUCTS: "products",
  CUSTOMERS: "customers",
  PRICES: "prices",
  PAYMENTS: "settings/payments",
  PAYMENT_METHODS: "settings/payment_methods",
  CHARGEBACK_PROTECTION_ACTIVATE: "settings/chargeback_protection/activate",
  TAX_ACTIVATE: "settings/tax/activate",
  TAX: "settings/tax"
};

export const STRIPE_BENEFITS_LIST = [
  {
    icon: "checkmarkCircle",
    copy: "PCI DSS compliance Level 1 certification."
  },
  {
    icon: "checkmarkCircle",
    copy: "Money Transmitter Licenses across US."
  },
  {
    icon: "checkmarkCircle",
    copy: "PSD2 and Strong Customer Authentication (SCA) compliant."
  }
];

export const STRIPE_IDENTITY_LIST = [
  {
    icon: "store",
    copy: "Business or personal identification."
  },
  {
    icon: "bank",
    copy: "Bank account information."
  },
  {
    icon: "globe",
    copy: "Website information and verification."
  }
];

export const SELL_ANYTHING_LIST = [
  {
    icon: "checkmarkCircle",
    copy: "Create one time or recurring charges."
  },
  {
    icon: "checkmarkCircle",
    copy: "Set prices in different currencies."
  },
  {
    icon: "checkmarkCircle",
    copy: "Accept Apple Pay and Google Pay."
  },
  {
    icon: "checkmarkCircle",
    copy: "Apply discounts, tax rates and more."
  }
];

export const SELL_ANYWHERE_LIST = [
  {
    icon: "checkmarkCircle",
    copy:
      "Accept payments in your customers preferred payment method including Apple Pay and Google Wallet."
  },
  {
    icon: "checkmarkCircle",
    copy: "Compatible with most CRM and marketing apps."
  },
  {
    icon: "checkmarkCircle",
    copy: "QR codes to enable contactless payments."
  }
];

export const STRIPE_LIST_SECTIONS = [
  {
    header: "Sell anything",
    notation: "*",
    body:
      "Accept payments for any of your products with payment links. Each link can be configured for your use case.",
    list: SELL_ANYTHING_LIST
  },
  {
    header: "Sell anywhere",
    body:
      "Commerce is happening internationally, across multiple channels. Payment links are global and omnichannel ready, allowing you to sell across email, social, in app etc. Payment links can help you sell wherever your customers are.",
    list: SELL_ANYWHERE_LIST
  },
  {
    header: "Simple and secure",
    body:
      "Meet the latest regulatory and compliance requirements through Stripe including built in fraud detection.",
    list: STRIPE_BENEFITS_LIST
  }
];

export const METADATA_FLEX_PRODUCT = "__PB_FLEX_PRODUCT";
export const TEST_RESOURCE_ID_PREFIX = "pb_test_";
export const METADATA_PAIR_ID = "__PB_METADATA_PAIR_ID";
export const TEST_RESOURCE_ID_RE = new RegExp(`^${TEST_RESOURCE_ID_PREFIX}`);

export const REFERENCE_KEYS = [
  "prices",
  "products",
  "coupons",
  "promotionCodes",
  "taxRates",
  "shippingRates"
];

export const STRIPE_REFERENCES_STORE = {
  products: [],
  prices: [],
  taxRates: [],
  shippingRates: [],
  coupons: [],
  promotionCodes: []
};

const TAX = "tax";
export const STRIPE_FEATURES = {
  TAX
};

export const TEST_STRIPE_PAYMENT_LINK =
  "https://buy.stripe.com/test_fZebJt44D3mS0fe6oq";

export const STRIPE_PAYMENT_LINK_RE = new RegExp("https://buy.stripe.com/");

export const RECURRING_USAGE_TYPE = {
  LICENSED: "licensed",
  METERED: "metered"
};

export const STRIPE_CHECKOUT_DISABLED_MESSAGE = {
  NO_ACCOUNT: {
    copy: "No stripe account present",
    /**
     * No cta because theres no external location to navigate to
     * User must create another account within the app
     */
    cta: null
  },
  NO_DETAILS_SUBMITTED: {
    copy:
      "Checkout is disabled until your business verification has been completed.",
    cta: "Complete verification"
  },
  NO_CHARGES_ENABLED: {
    copy:
      "Your Stripe details have been submitted but charges are not yet enabled on your account. You can review all outstanding Stripe account requirements on your dashboard.",
    cta: "See dashboard"
  },
  AUTH_REQUIRED: {
    copy:
      "Your Stripe account details have been confirmed. Please login with Stripe once more so we can validate these details and enable your PriceBlocs account.",
    cta: "Login to Stripe"
  }
};

export const STRIPE_LINE_ITEM_KEYS = [
  "price",
  "price_data",
  "adjustable_quantity",
  "dynamic_tax_rates",
  "quantity",
  "tax_rates"
];

export const STRIPE_DOCS_LINKS = {
  PAYMENT_INTENTS_FUTURE_USAGE:
    "https://stripe.com/docs/payments/payment-intents#future-usage",
  PAYMENT_METHOD_SETUP:
    "https://stripe.com/docs/payments/save-and-reuse?platform=web&ui=checkout#create-checkout-session"
};

const MODE = "mode";
const PAYMENT_METHOD_TYPES = "payment_method_types";
const CLIENT_REFERENCE_ID = "client_reference_id";
const CUSTOMER = "customer";
const CUSTOMER_EMAIL = "customer_email";
const DISCOUNTS = "discounts";
const SHIPPING_ADDRESS_COLLECTION = "shipping_address_collection";
const SHIPPING_OPTIONS = "shipping_options";
const LINE_ITEMS = "line_items";
const ADJUSTABLE_QUANTITY = "adjustable_quantity";
const SUCCESS_URL = "success_url";
const CANCEL_URL = "cancel_url";
const METADATA = "metadata";
const SUBSCRIPTION_DATA = "subscription_data";
const PAYMENT_INTENT_DATA = "payment_intent_data";
const SETUP_INTENT_DATA = "setup_intent_data";
const BILLING_ADDRESS_COLLECTION = "billing_address_collection";
const PAYMENT_METHOD_OPTIONS = "payment_method_options";
const ALLOW_PROMOTION_CODES = "allow_promotion_codes";
const AUTOMATIC_TAX = "automatic_tax";
const CUSTOMER_UPDATE = "customer_update";
const LOCALE = "locale";
const SUBMIT_TYPES = "submit_type";
const TAX_ID_COLLECTION = "tax_id_collection";
const CONSENT_COLLECTION = "consent_collection";
const AFTER_EXPIRATION = "after_expiration";
const EXPIRES_AT = "expires_at";
const TRIAL_PERIOD_DAYS = "trial_period_days";
/**
 * Virtual attributes
 * - accepted as checkout params but are attached to each line_item
 */
const TAX_RATES = "tax_rates";
const DYNAMIC_TAX_RATES = "dynamic_tax_rates";
const SHIPPING_WORLDWIDE = "shipping_worldwide";

export const CHECKOUT_SESSION = {
  CANCEL_URL,
  MODE,
  PAYMENT_METHOD_TYPES,
  SUCCESS_URL,
  CLIENT_REFERENCE_ID,
  CUSTOMER,
  CUSTOMER_EMAIL,
  LINE_ITEMS,
  METADATA,
  ALLOW_PROMOTION_CODES,
  AUTOMATIC_TAX,
  BILLING_ADDRESS_COLLECTION,
  CUSTOMER_UPDATE,
  DISCOUNTS,
  LOCALE,
  PAYMENT_INTENT_DATA,
  PAYMENT_METHOD_OPTIONS,
  SETUP_INTENT_DATA,
  SHIPPING_ADDRESS_COLLECTION,
  SHIPPING_OPTIONS,
  SUBMIT_TYPES,
  SUBSCRIPTION_DATA,
  TAX_ID_COLLECTION,
  ADJUSTABLE_QUANTITY,
  CONSENT_COLLECTION,
  AFTER_EXPIRATION,
  EXPIRES_AT,
  TRIAL_PERIOD_DAYS
};

export const VIRTUAL_CHECKOUT_SESSION = {
  // Virtual attributes
  TAX_RATES,
  DYNAMIC_TAX_RATES,
  SHIPPING_WORLDWIDE
};

export const STRIPE_CHECKOUT_CUSTOMER_MERGE_PARAMS = [
  {
    key: CHECKOUT_SESSION.CUSTOMER
  },
  {
    key: "email",
    rename: CHECKOUT_SESSION.CUSTOMER_EMAIL
  },
  {
    key: CHECKOUT_SESSION.CUSTOMER_EMAIL
  },
  {
    key: CHECKOUT_SESSION.CLIENT_REFERENCE_ID
  }
];

export const STRIPE_CHECKOUT_MERGE_PARAMS = [
  ...STRIPE_CHECKOUT_CUSTOMER_MERGE_PARAMS,
  ...[
    CHECKOUT_SESSION.SUCCESS_URL,
    CHECKOUT_SESSION.CANCEL_URL,
    CHECKOUT_SESSION.METADATA,
    CHECKOUT_SESSION.SUBSCRIPTION_DATA,
    CHECKOUT_SESSION.PAYMENT_INTENT_DATA,
    CHECKOUT_SESSION.SETUP_INTENT_DATA,
    CHECKOUT_SESSION.BILLING_ADDRESS_COLLECTION,
    CHECKOUT_SESSION.PAYMENT_METHOD_OPTIONS,
    CHECKOUT_SESSION.ALLOW_PROMOTION_CODES,
    CHECKOUT_SESSION.CUSTOMER_UPDATE,
    CHECKOUT_SESSION.LOCALE,
    CHECKOUT_SESSION.SUBMIT_TYPES,
    CHECKOUT_SESSION.TAX_ID_COLLECTION,
    CHECKOUT_SESSION.CONSENT_COLLECTION,
    CHECKOUT_SESSION.AFTER_EXPIRATION,
    CHECKOUT_SESSION.EXPIRES_AT,
    // Virtual attributes
    VIRTUAL_CHECKOUT_SESSION.SHIPPING_WORLDWIDE
  ].map((key) => ({ key }))
];

export const DEFAULT_CHECKOUT_SESSION_ATTRIBUTE_VALUES = {
  // Trial fields
  [CHECKOUT_SESSION.TRIAL_PERIOD_DAYS]: 0,
  // Tax fields
  [CHECKOUT_SESSION.TAX_ID_COLLECTION]: {
    enabled: false,
    type: TAX_ID_COLLECTION_TYPE.STANDARD
  },
  [CHECKOUT_SESSION.AUTOMATIC_TAX]: {
    enabled: false
  },
  [VIRTUAL_CHECKOUT_SESSION.TAX_RATES]: [],
  [VIRTUAL_CHECKOUT_SESSION.DYNAMIC_TAX_RATES]: [],
  [CHECKOUT_SESSION.PAYMENT_METHOD_TYPES]: [],
  // Payment fields
  [CHECKOUT_SESSION.PAYMENT_INTENT_DATA]: {
    capture_method: CAPTURE_METHOD.AUTOMATIC,
    setup_future_usage: null
  },
  subscription_schedule: {},
  // Discounts
  [CHECKOUT_SESSION.ALLOW_PROMOTION_CODES]: false,
  [CHECKOUT_SESSION.DISCOUNTS]: [],
  // Shipping
  [VIRTUAL_CHECKOUT_SESSION.SHIPPING_WORLDWIDE]: false,
  [CHECKOUT_SESSION.SHIPPING_ADDRESS_COLLECTION]: {},
  [CHECKOUT_SESSION.SHIPPING_OPTIONS]: [],
  // Customer
  [CHECKOUT_SESSION.CUSTOMER]: "",
  [CHECKOUT_SESSION.CUSTOMER_EMAIL]: ""
};
