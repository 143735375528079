import get from "lodash/get";
import omit from "lodash/omit";
import last from "lodash/last";
import upperFirst from "lodash/upperFirst";
import cloneDeep from "lodash/cloneDeep";
import snakeCase from "lodash/snakeCase";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import { singularize } from "./text";
import deepmerge from "deepmerge";

// Update to accept data array
export const deconstructReducerData = (
  data,
  primaryKey,
  collectionKeys = []
) => {
  if (Array.isArray(data)) {
    throw new Error("Data input should be an object");
  }
  const pickKeys = collectionKeys.map((key) => key.split(".")[0]);
  const topLevelCollectionKeys = collectionKeys.map((key) =>
    key.split(".").pop()
  );
  const startMemo = topLevelCollectionKeys.reduce(
    (memo, key) => {
      memo[key] = [];
      return memo;
    },
    { [primaryKey]: omit(data, pickKeys) }
  );

  return collectionKeys.reduce((memo, key) => {
    const keyParts = key.split(".");
    if (data[keyParts[0]]) {
      if (keyParts.length === 1) {
        memo[key] = data[key];
      } else {
        let source;
        keyParts.forEach((keyPart, keyPartIx) => {
          if (keyPartIx < keyParts.length) {
            if (!source) {
              source = get(data, keyPart);
            } else {
              source = source.reduce((sourceMemo, sourceValue) => {
                sourceMemo = sourceMemo.concat(get(sourceValue, keyPart));
                return sourceMemo;
              }, []);
            }
          }
        });

        const lastKey = last(keyParts);
        if (memo[lastKey]) {
          memo[lastKey] = memo[lastKey].concat(source);
        } else {
          memo[lastKey] = source;
        }

        const previousKey = keyParts[keyParts.length - 2];
        memo[previousKey] = memo[previousKey].map((val) => omit(val, lastKey));
      }
    }

    return memo;
  }, startMemo);
};

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export const mergeReplace = (existing, updated) => {
  return deepmerge(existing, updated, { arrayMerge: overwriteMerge });
};

export const markForRemoval = ({ id, uuid }) => ({
  id,
  uuid,
  _destroy: "1"
});

export const getMarkedForRemoval = (initial, current) =>
  initial
    .filter((initialModel) => {
      return !current.find(
        (currentModel) => currentModel.id === initialModel.id
      );
    })
    .map(markForRemoval);

export const hasAnyUpdates = (data) => {
  let hasUpdates = false;
  for (const key in data) {
    const values = data[key];
    if (values && values.length) {
      hasUpdates = true;
      break;
    }
  }

  return hasUpdates;
};

export const reduceKeyDifferences = (keys, initial, current) =>
  keys.reduce((memo, key) => {
    if (!isEqual(initial[key], current[key])) {
      memo[key] = current[key];
    }
    return memo;
  }, {});

export const findIndexForModel = (model, collection) =>
  collection &&
  collection.findIndex(
    (storeProduct) =>
      (storeProduct.uuid && model.uuid && storeProduct.uuid === model.uuid) ||
      (storeProduct.id && model.id && storeProduct.id === model.id)
  );

/**
 * Generate the association records for common models like features and featureGroups
 * - Common means that they can be owned by either users || organizations through a join table like user_features etc.
 * @param {Object} params
 * @param {String} params.resourceID
 * @param {String} params.resource
 */
export const prepareModelAssociations = ({
  resourceID,
  resource,
  ...remainder
}) => {
  const remKeys = Object.keys(remainder);
  return remKeys.reduce((memo, key) => {
    const collection = remainder[key];
    const association = `${resource}${upperFirst(key)}`;

    memo[key] = collection;

    memo[association] =
      collection &&
      collection.map &&
      collection.map((model) => {
        const singularKey = singularize(key);

        return {
          [`${snakeCase(singularKey)}_id`]: model.id,
          [`${resource}_id`]: resourceID
        };
      });
    return memo;
  }, {});
};

const clone = (obj) => Object.assign({}, obj);

export const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;

  return clonedObj;
};

export const snakeKeys = (data) =>
  Object.keys(data).reduce((memo, key) => {
    memo[snakeCase(key)] = data[key];
    return memo;
  }, {});

export const notNothing = (value) =>
  !isNull(value) && typeof value !== "undefined";

export const isNothing = (value) =>
  isNull(value) || typeof value === "undefined";

/**
 * Assumes that there is a value key on the data when its an object
 * - Else return string
 * - Ideally this would be driven by schema
 */
export const getValue = (data) => {
  if (typeof data === "string") {
    return data;
  } else {
    const value = get(data, "value");
    if (!value) {
      console.error(value);
    }
    return typeof value === "string" ? value : "";
  }
};

export const reduceIdMap = (models = [], key = "id") =>
  models.reduce((memo, model) => {
    memo[model[key]] = model;

    return memo;
  }, {});

export const omitDeep = (values, path) => omit(cloneDeep(values), path);
