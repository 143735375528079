// import { PRICE_TYPE } from "utils/constants/price";
import { CURRENCY_CODES } from "components/FormerEditor/common/constants/currency";
import { getConfig } from "utils/env";
/**
 * Configuration needs
 * Layout fields
 * - Boolean for showing feature list (inclusive / exclusive)
 * - Control order of features in list
 * Prices
 * - Ability to set current active price
 * Virtual tiers (ones not represented in Payment Gateway)
 * - Enterprise Let's talk / contact sales column
 */

// Should be resolved from a promise at the entity generation step
export const STUB_ALL_PRODUCT_FEATURES = [
  {
    uuid: "1234-view-dashboards",
    title: "View team dashboards",
    description: "Long form description",
    icon: "checkmark",
    groups: [
      {
        title: "Analytics"
      }
    ],
    productUIDs: [
      getConfig("STRIPE_PRODUCT_ID_VIEWER"),
      getConfig("STRIPE_PRODUCT_ID_CREATOR")
    ]
  },
  {
    uuid: "1234-create-dashboards",
    title: "Create team dashboards",
    description: "Long form description",
    icon: "checkmark",
    groups: [
      {
        title: "Analytics"
      }
    ],
    productUIDs: [getConfig("STRIPE_PRODUCT_ID_CREATOR")]
  },
  {
    uuid: "1234-create-projects",
    title: "Create projects for teams",
    description: "Long form description",
    icon: "checkmark",
    groups: [
      {
        title: "Analytics"
      }
    ],
    productUIDs: [getConfig("STRIPE_PRODUCT_ID_CREATOR")]
  },
  {
    uuid: "1234-share-dashboards",
    title: "Share in Slack",
    description: "Long form description",
    icon: "checkmark",
    groups: [
      {
        title: "Collaboration"
      }
    ],
    productUIDs: [
      getConfig("STRIPE_PRODUCT_ID_VIEWER"),
      getConfig("STRIPE_PRODUCT_ID_CREATOR")
    ]
  },
  {
    uuid: "1234-project-dashboards",
    title: "Project to TV",
    icon: "checkmark",
    groups: [
      {
        title: "Collaboration"
      }
    ],
    description: "Long form description",
    productUIDs: [getConfig("STRIPE_PRODUCT_ID_CREATOR")]
  }
];

export const DEFAULT_TABLE_DISPLAY_CONFIG = {
  currency: {
    symbol: true,
    units: true
  }
};

const INCLUSIVE = "inclusive";
const EXCLUSIVE = "exclusive";
const INCREMENTAL = "incremental";
export const CLUSIVITY_TYPES = {
  INCLUSIVE,
  EXCLUSIVE,
  INCREMENTAL
};

// Move to config object
export const STUB_FEATURE_LIST_CONFIG = {
  common: {
    clusivity: CLUSIVITY_TYPES.INCLUSIVE
  },
  [getConfig("STRIPE_PRODUCT_ID_VIEWER")]: {
    title: "Collaborate with other team members:"
  },
  [getConfig("STRIPE_PRODUCT_ID_CREATOR")]: {
    title: "Create and distribute analytics:"
  }
};

export const DEFAULT_FEATURE_LIST_TITLE = "Top Features";

const DAY = "day";
const WEEK = "week";
const MONTH = "month";
const YEAR = "year";

export const RECURRING_INTERVALS = {
  DAY,
  WEEK,
  MONTH,
  YEAR
};

export const INTERVAL_LABELS_MAP = {
  one_time: "once",
  [RECURRING_INTERVALS.DAY]: "daily",
  [RECURRING_INTERVALS.WEEK]: "weekly",
  [RECURRING_INTERVALS.MONTH]: "monthly",
  [RECURRING_INTERVALS.YEAR]: "yearly"
};

export const INTERVAL_SHORTHAND_MAP = {
  [RECURRING_INTERVALS.DAY]: "day",
  [RECURRING_INTERVALS.WEEK]: "wk",
  [RECURRING_INTERVALS.MONTH]: "mo",
  /**
   * The selection of shorthand should alternate between how billing is presented
   * - billing_presentation_type
   * - (month) - $25 per month, when billed yearly.
   * - (interval) - $300 per year
   */
  [RECURRING_INTERVALS.YEAR]: "mo"
};

const ACTION_LINK = "link";
const STRIPE_CHECKOUT = "stripe_checkout";

export const PAGE_ACTION_UIDS = {
  LINK: ACTION_LINK,
  STRIPE_CHECKOUT
};

export const PRICING_TABLE_PRODUCT_LIMIT_MAX = 4;
export const PRICING_TABLE_PRODUCT_LIMIT_START = 3;

// User will enter a value for this and its persisted
const DEFAULT = "default";
// Value will be derived from context for this (e.g. product id) and its persisted
const HIDDEN = "hidden";
// Value will be derived from context and its NOT persisted (e.g. formattedPrice)
const VIRTUAL = "virtual";
export const SCHEMA_CATEGORY = {
  DEFAULT,
  HIDDEN,
  VIRTUAL
};
const TEMPLATE_FIELD = "template-field";
export const SCHEMA_COMPONENT = {
  TEMPLATE_FIELD
};

const PRODUCT = "product";
const PRICE = "price";
const FEATURE = "feature";
export const MODELS = {
  PRODUCT,
  PRICE,
  FEATURE
};

export const MIN_PRODUCT_KEYS = [
  "id",
  "name",
  "images",
  "description",
  "tax_behavior"
];
export const MIN_PRICE_KEYS = [
  "id",
  "currency",
  "product",
  "recurring",
  "unit_amount",
  "session",
  "tax_behavior"
];

export const MODEL_MIN_KEYS = {
  [`${MODELS.PRODUCT}s`]: MIN_PRODUCT_KEYS,
  [`${MODELS.PRICE}s`]: MIN_PRICE_KEYS
};

export const SCHEMA_TYPES = {
  STRING: "string",
  ARRAY: "array",
  BOOLEAN: "boolean",
  OBJECT: "object",
  NUMBER: "number",
  DATE: "date"
};

export const KEY_TYPES = {
  TEMPLATE: "template"
};

export const DEFAULT_CONTENT = {
  CTA: {
    COPY: {
      TITLE: "Copy",
      VALUE: "Click here"
    },
    HREF: {
      TITLE: "Link",
      VALUE: "#"
    }
  }
};

export const DEFAULT_FEATURE_LIMIT = 5;

export const FEATURE_ORDER_KEY_MAP = {
  active: "Included in product"
};

export const FEATURE_ORDER_KEY_TOOLTIP_MAP = {
  active: "Order by features included in the product"
};

export const ORDER_BY_KEYS = {
  ACTIVE: "active",
  TITLE: "title"
};

export const DEFAULT_FEATURE_ORDER = [
  {
    key: ORDER_BY_KEYS.ACTIVE,
    active: true,
    value: "desc",
    toggle: false
  },
  {
    key: ORDER_BY_KEYS.TITLE,
    active: true,
    value: "asc",
    toggle: true
  }
];

const GROUP = "group";
const INDIVIDUAL = "individual";

export const LICENSE_TYPE = {
  INDIVIDUAL,
  GROUP
};

export const DEFAULT_FEATURES_COMMON_CONFIG = {
  clusivity: CLUSIVITY_TYPES.INCREMENTAL,
  limit: DEFAULT_FEATURE_LIMIT,
  order: DEFAULT_FEATURE_ORDER,
  show: true
};

export const DEFAULT_PRICING_CONFIG = {
  defaultCurrency: CURRENCY_CODES.USD.toLowerCase(),
  defaultInterval: RECURRING_INTERVALS.MONTH,
  licenseType: LICENSE_TYPE.INDIVIDUAL,
  showImagery: true
};
