export const INTEGRATION_MODEL_ADMIN_URL = "integration_model_admin_url";
export const INTEGRATION_MODEL = "integration_model";
export const INTEGRATION_MODEL_UID = "integration_model_uid";
export const INTEGRATION_SERVICE_UID = "integration_service_uid";
export const INTEGRATION_SERVICE = "integration_service";
export const INTEGRATION_PARENT_MODEL = "integration_parent_model";
export const INTEGRATION_PARENT_MODEL_UID = "integration_parent_model_uid";
export const INTEGRATION_ID = "integration_id";
export const INTEGRATION_ORDER_ID = "integration_order_id";

export const INTEGRATION_FIELDS = {
  INTEGRATION_ID,
  INTEGRATION_MODEL_ADMIN_URL,
  INTEGRATION_MODEL,
  INTEGRATION_MODEL_UID,
  INTEGRATION_SERVICE_UID,
  INTEGRATION_SERVICE
};

export const INTEGRATION_OMISSION_KEYS = [
  INTEGRATION_ORDER_ID,
  INTEGRATION_ID,
  INTEGRATION_SERVICE_UID,
  INTEGRATION_MODEL_ADMIN_URL,
  INTEGRATION_MODEL,
  INTEGRATION_MODEL_UID,
  INTEGRATION_SERVICE_UID,
  INTEGRATION_SERVICE,
  INTEGRATION_PARENT_MODEL,
  INTEGRATION_PARENT_MODEL_UID
];

export const METADATA_KEY = {
  SHORT_ID: "short_id",
  ROUTE: "route"
};
