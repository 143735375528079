import { ELIGIBLE_LOGIC_FIELDS_CSV } from "constants/docs";
import { STATUS } from "utils/constants/ui";

const CANCELED = "canceled";
const ERROR = "error";
const FAILURE = "failure";
const OPEN = "open";
const PENDING = "pending";
const SUCCESS = "success";

export const FULFILLMENT_STATUS = {
  CANCELED,
  // ERROR,
  // FAILURE,
  // OPEN,
  PENDING,
  SUCCESS
};

export const FULLFILLMENT_STATUS_LABEL_MAP = {
  [CANCELED]: "Canceled",
  // ERROR,
  // FAILURE,
  // OPEN,
  [PENDING]: "Pending",
  [SUCCESS]: "Completed"
};

export const FULFILLMENT_STATUS_THEME = {
  [CANCELED]: STATUS.DANGER,
  [ERROR]: STATUS.DANGER,
  [FAILURE]: STATUS.DANGER,
  [OPEN]: STATUS.WARNING,
  [PENDING]: STATUS.WARNING,
  [SUCCESS]: STATUS.SUCCESS
};

export const FULFILLMENT_STATUS_OPTIONS = Object.values(FULFILLMENT_STATUS).map(
  (status) => ({
    uid: status,
    label: FULLFILLMENT_STATUS_LABEL_MAP[status]
  })
);

export const MENU_ROOT = "root";
export const CHECKOUT_SETTINGS_INTENT = {
  /**
   * Constant | default (i.e. the products always in the cart)
   */
  SET_CONSTANT: "SET_CONSTANT",
  SET_PRODUCTS: "SET_PRODUCTS",
  SET_COMMON: "SET_COMMON"
};

const SET_FIELDS = "SET_FIELDS";
const SET_COMBINATIONS = "SET_COMBINATIONS";
const SET_SETTINGS = "SET_SETTINGS";
export const CHECKOUT_PRODUCT_SETTINGS_INTENT = {
  SET_FIELDS,
  SET_COMBINATIONS,
  SET_SETTINGS
};

// Checkout settings menu
export const SET_CONSTANT_GUIDANCE =
  "Set one or more products to be included with every checkout.";
export const SET_PRODUCTS_GUIDANCE =
  "Set which form value combinations add products to your customers cart.";
export const SET_CHECKOUT_COMMON_GUIDANCE =
  "Set common configuration values to apply to each checkout option.";

// Checkout products settings menu
export const SET_PRODUCTS_FIELDS_GUIDANCE =
  "Select which form fields will control product additions to the checkout cart.";
export const SET_PRODUCTS_COMBINATIONS_GUIDANCE =
  "Set which form field value combinations will determine each checkout option.";
export const SET_PRODUCTS_SETTINGS_GUIDANCE =
  "Additional settings and restrictions for your checkout.";

// Misc
export const SET_CONDITIONAL_GUIDANCE =
  "Require that your customer selects a combination of form values before checkout button is enabled.";
export const DISABLED_CONDITIONAL_GUIDANCE =
  "Checkout combinations can only be conditional when there is a default checkout value present.";
export const RESET_COMBINATIONS_WARNING =
  "Changing selected fields will reset current checkout combinations.";
export const NO_ELIGIBLE_COMBINATION_FIELD_WARNING = `You need at least one field with a type of ${ELIGIBLE_LOGIC_FIELDS_CSV} to set checkout combination logic.`;
