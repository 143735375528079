import startCase from "lodash/startCase";
import compact from "lodash/compact";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";
import { formatUnitAmount } from "components/FormerEditor/common/currency";
import { RECURRING_INTERVALS } from "components/FormerEditor/common/constants";
import { MONTH_COUNT } from "components/FormerEditor/common/constants/currency";

const MODEL_PATH_VALUE_TRANSFORMERS = {
  product: {},
  price: {
    unit_amount: ({ model }) => {
      return formatUnitAmount({
        amount: model.unit_amount,
        currency: model.currency
      });
    },
    unit_amount_monthly: ({ model }) => {
      const yearlyInterval =
        model.recurring &&
        model.recurring.interval === RECURRING_INTERVALS.YEAR;
      const unit_amount = yearlyInterval
        ? model.unit_amount / MONTH_COUNT
        : model.unit_amount;

      return [
        `${formatUnitAmount({
          amount: unit_amount,
          currency: model.currency
        })} ${model.currency.toUpperCase()} per person, per month`,
        yearlyInterval ? "when billed yearly" : ""
      ]
        .join(" ")
        .trim();
    }
  }
};

const MODEL_SHORTCODE_TRANSFORMER = {
  product: {},
  price: {
    recurring: {
      interval: ({ model, variable }) => {
        return `${priceToDollars(model)} ${getLastPathPart(variable.path)}`;
      }
    },
    currency: ({ model, variable }) => {
      return `${priceToDollars(model)} ${getLastPathPart(variable.path)}`;
    },
    id: ({ model, variable }) => {
      return `${priceToDollars(model)} ${getLastPathPart(variable.path)}`;
    },
    unit_amount: ({ model }) => {
      return priceToDollars(model);
    },
    unit_amount_monthly: ({ model }) => {
      const yearlyInterval =
        model.recurring &&
        model.recurring.interval === RECURRING_INTERVALS.YEAR;
      const unit_amount = yearlyInterval
        ? model.unit_amount / MONTH_COUNT
        : model.unit_amount;

      return `${formatUnitAmount({
        amount: unit_amount,
        currency: model.currency
      })} ${model.currency.toUpperCase()} (monthly)`;
    }
  }
};

const requiresInterpolation = (value) =>
  typeof value === "string" && /\{{{/g.test(value);

export const getInterpolatedValue = ({ value, variables }) => {
  return requiresInterpolation(value)
    ? interpolateTemplateString(value, variables)
    : value;
};

const getShortcode = ({ variable, model, prefix }, { product }) => {
  const shortcodeKey = `${variable.model}.${variable.path}`;
  const scorecodeTransformer = get(MODEL_SHORTCODE_TRANSFORMER, shortcodeKey);
  const shortCodeParts = [`${startCase(variable.model)}:`, prefix];

  if (scorecodeTransformer) {
    shortCodeParts.push(scorecodeTransformer({ variable, model }, { product }));
  } else if (variable.path) {
    const pathParts = variable.path.split(".");

    const pathPart =
      pathParts.length > 1 ? pathParts.slice(1).join(" ") : pathParts[0];
    shortCodeParts.push(pathPart);
  }

  return compact(shortCodeParts).join(" ");
};

const MODEL_INFO_TRANSFORMERS = {
  product: ({ variable, model }, { product }) => {
    const shortcode = getShortcode(
      { variable, model, prefix: model.name },
      { product }
    );
    return {
      shortcode,
      description: model.description
    };
  },
  price: ({ variable, model }, { product }) => {
    const productMatch = product.find(({ id }) => model.product === id);
    const prefix = (productMatch && productMatch.name) || "";
    const shortcode = getShortcode({ variable, model, prefix }, { product });

    return {
      shortcode,
      description: productMatch ? productMatch.description : ""
    };
  }
};

const STATIC = "static";
const DYNAMIC = "dynamic";
const VARIABLE_TYPES = {
  STATIC,
  DYNAMIC
};

const getLastPathPart = (path) => {
  const pathParts = path.split(".");

  return pathParts.length > 1 ? pathParts.slice(1).join(" ") : pathParts[0];
};

const priceToDollars = (model) =>
  formatUnitAmount({
    amount: model.unit_amount,
    currency: model.currency
  });

/**
 * Extend the variables with the dynamic values they need to be rendered on the page
 * @param {Array} variables - collection of variables saved for use in the document
 * @param {Array} references - data references to pull values from
 */

/**
 * TODO pluralize the references keys and add s to model
 */
export const getHydratedVariables = (variables, references) => {
  return variables.reduce((memo, variable) => {
    const collection = references[variable.model];
    if (collection && collection.length) {
      const modelMatch = collection.find(({ id }) => id === variable.uid);
      if (modelMatch) {
        let base = {
          ...variable
        };
        if (base.path && base.type === VARIABLE_TYPES.DYNAMIC) {
          const valueTransformer =
            MODEL_PATH_VALUE_TRANSFORMERS[variable.model] &&
            MODEL_PATH_VALUE_TRANSFORMERS[variable.model][variable.path];

          if (valueTransformer) {
            base.value = valueTransformer(
              { variable, model: modelMatch },
              references
            );
          } else {
            base.value = get(modelMatch, variable.path);
          }
          const infoTransformer = MODEL_INFO_TRANSFORMERS[variable.model];
          if (infoTransformer) {
            const info = infoTransformer(
              { variable, model: modelMatch },
              references
            );
            base = Object.assign({}, base, info);
          }
        }

        memo.push(base);
      }
    } else {
      memo.push(variable);
    }

    return memo;
  }, []);
};

export const reduceNodesToTemplateString = (nodes) => {
  return nodes.reduce((memo, node) => {
    if (node.component) {
      memo += `{{{${node.model}:${node.uuid}}}}`;
    } else if (node.children) {
      memo += reduceNodesToTemplateString(node.children);
    } else if (typeof node.text === "string") {
      memo += node.text;
    } else {
      console.warn("Unhandled node:", node);
    }
    return memo;
  }, "");
};

const UUID_REG_EXP = new RegExp(
  /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g
);

const getVariablesFromFragment = (fragment, variables = []) => {
  const matches = fragment.match(UUID_REG_EXP);
  return variables.filter(({ uuid }) => matches && matches.indexOf(uuid) > -1);
};

/**
 * Recurse over values and extract unsaved variables
 * @param {Object} values
 * @param {Array} variables
 */
export const extractNewVariables = (values, variables) => {
  return Object.keys(values).reduce((memo, key) => {
    const value = values[key];
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          memo = memo.concat(extractNewVariables(val, variables));
        });
      } else if (typeof value === "object") {
        memo = memo.concat(extractNewVariables(value, variables));
      } else if (typeof value === "string") {
        const newFragmentVariables = getVariablesFromFragment(
          value,
          variables
        ).filter(({ id }) => !id);

        if (newFragmentVariables && newFragmentVariables.length) {
          memo = memo.concat(newFragmentVariables);
        }
      }
    }

    return memo;
  }, []);
};

// Split by '\n' for multiple lines
const templateToArray = (template) =>
  template.split("{{{").reduce((memo, part) => {
    const rightParts = part.split("}}}");
    memo = memo.concat(rightParts);

    return memo;
  }, []);

export const interpolateTemplateString = (
  templateString,
  hydratedVariables
) => {
  return templateToArray(templateString).reduce((memo, fragment) => {
    const hasUUID = UUID_REG_EXP.test(fragment);
    if (hasUUID) {
      const variableMatch = getVariablesFromFragment(
        fragment,
        hydratedVariables
      )[0];
      if (variableMatch && variableMatch.value) {
        memo += variableMatch.value;
      }
    } else {
      memo += fragment;
    }
    return memo;
  }, "");
};

const MENTION = "mention";
const SLATE_COMPONENT_TYPE = {
  MENTION
};

export const nodesFromTemplateString = (template, hydratedVariables) => {
  return [templateToArray(template)].reduce((memo, template, templateIx) => {
    if (!memo[templateIx]) {
      memo.push({
        children: []
      });
    }
    template.forEach((fragment) => {
      const hasUUID = UUID_REG_EXP.test(fragment);
      if (hasUUID) {
        const variableMatch = getVariablesFromFragment(
          fragment,
          hydratedVariables
        )[0];
        if (variableMatch && variableMatch.value) {
          memo[0].children.push({
            ...variableMatch,
            component: SLATE_COMPONENT_TYPE.MENTION,
            children: [
              {
                text: ""
              }
            ]
          });
        } else {
          console.warn(`No variable found for fragment:${fragment}`);
        }
      } else {
        memo[0].children.push({
          text: fragment
        });
      }
    });

    return memo;
  }, []);
};

export const MODEL_ATTR_PATHS = {
  product: {
    static: ["id", "name", "description"],
    dynamic: []
  },
  price: {
    static: ["id", "unit_amount", "recurring.interval", "currency"],
    dynamic: ["unit_amount_monthly"]
  }
};

const getModelAttrPaths = (key) => {
  const pathPairs = MODEL_ATTR_PATHS[key];

  return Object.keys(pathPairs).reduce((attrPathsMemo, modelAttrKey) => {
    attrPathsMemo = attrPathsMemo.concat(pathPairs[modelAttrKey]);

    return attrPathsMemo;
  }, []);
};

export const generateVariableSet = ({ persisted, template, ...remainder }) => {
  const base = [...persisted, ...template];
  const modelKeys = Object.keys(remainder);

  return modelKeys.reduce((memo, modelKey) => {
    const collection = remainder[modelKey];
    if (collection && collection.length > 0) {
      collection.forEach((model) => {
        const attrPaths = getModelAttrPaths(modelKey);

        if (attrPaths && attrPaths.length > 0) {
          attrPaths.forEach((attrPath) => {
            const modelMatch = memo.find((memoData) => {
              return (
                memoData.type === VARIABLE_TYPES.DYNAMIC &&
                memoData.model === modelKey &&
                memoData.path === attrPath &&
                memoData.uid === model.id
              );
            });

            if (!modelMatch) {
              memo.push({
                uuid: uuidv4(),
                uid: model.id,
                type: VARIABLE_TYPES.DYNAMIC,
                model: modelKey,
                path: attrPath
              });
            }
          });
        }
      });
    }

    return memo;
  }, base);
};
