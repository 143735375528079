import axios from "axios";
import { authBearerToken } from "utils/auth";
import {
  X_API_KEY,
  X_REFERER,
  X_USER_AGENT,
  USER_AGENT
} from "./constants/header";
import QS from "qs";
import isEmpty from "lodash/isEmpty";
import { sanitizeParams } from "components/Form/fields/helpers";
import { QUERY_PREFIX } from "./constants/request";
import {
  hidePlatformScriptsOnRoute,
  hidePlatformSecurityScriptsOnRoute
} from "./url";
import { getFontFamilyUrl } from "./theme";
import get from "lodash/get";
import { STATE_KEYS } from "./constants/state";

export const prepareQueryUrl = ({ url, query }) => {
  const sanitized = sanitizeParams(query);

  return isEmpty(sanitized)
    ? url
    : `${url}${QS.stringify(sanitized, QUERY_PREFIX)}`;
};

export const makeRequest = async ({ method, url, data, headers }) => {
  let endpoint = url;
  const ctxMethod = method.toUpperCase();
  const options = {
    method: ctxMethod,
    headers
  };
  const result = {
    error: null,
    data: null
  };
  if (data) {
    if (ctxMethod === "GET" || ctxMethod === "DELETE") {
      const query = new URLSearchParams(data).toString();
      endpoint = `${endpoint}?${query}`;
    } else if (
      ctxMethod === "POST" ||
      ctxMethod === "PUT" ||
      ctxMethod === "PATCH"
    ) {
      if (headers["Content-Type"] === "application/x-www-form-urlencoded") {
        var formBody = [];
        for (var property in data) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        options.body = formBody.join("&");
      } else {
        options.body = JSON.stringify(data);
      }
    }
  }

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      result.error = {
        status: response.status,
        data: await response.json()
      };
      return result;
    }

    try {
      result.data = await response.json();
      return result;
    } catch (err) {
      result.error = {
        status: err.status,
        message: err.message
      };

      return result;
    }
  } catch (err) {
    result.error = {
      status: 403,
      message: err.message
    };
    return result;
  }
};

export const postRequest = async ({ url, data }, callback) => {
  try {
    const params = {
      method: "post",
      url,
      headers: authBearerToken()
    };
    if (data) {
      params.data = data;
    }
    const response = await axios(params);

    callback(null, response.data);
  } catch (error) {
    callback(error);
    return error;
  }
};

export const putRequest = async ({ url, data }, callback) => {
  try {
    const params = {
      method: "put",
      url,
      headers: authBearerToken()
    };
    if (data) {
      params.data = data;
    }
    const response = await axios(params);

    callback(null, response.data);
  } catch (error) {
    callback(error);
    return error;
  }
};

export const getXHeaders = (headers) => {
  const xHeaders = {};

  if (headers.referer) {
    xHeaders[X_REFERER] = headers.referer;
  }
  if (headers[USER_AGENT]) {
    xHeaders[X_USER_AGENT] = headers[USER_AGENT];
  }

  return xHeaders;
};

export const fetchPageResponse = async ({ url, apiKey, headers }) => {
  try {
    const allHeaders = {
      ...headers,
      [X_API_KEY]: apiKey
    };
    const root = url.split("?")[0];
    console.log("fetch:", root);
    const { data } = await axios({
      method: "get",
      url,
      headers: allHeaders
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const isMalformedValue = (value) =>
  /%5Bobject/i.test(value) ||
  /\[object/i.test(value) ||
  /%5Bshort-id/i.test(value) ||
  /\[short-id/i.test(value);

/**
 *
 * @param {Object} props
 * @param {Object} props.subdomain
 * @returns
 */
export const getContentSecurityPolicy = (props = {}) => {
  const allowedDomains =
    get(props, `application.${STATE_KEYS.APPLICATION.ALLOWLIST_DOMAINS}`) || [];
  const ancestorParts = [`frame-ancestors`, `'self'`];
  if (Array.isArray(allowedDomains) && allowedDomains.length > 0) {
    ancestorParts.push(allowedDomains.join(" "));
  }

  return `
      ${ancestorParts.join(" ")};
    `
    .replace(/\s{2,}/g, " ")
    .trim();
};

export const extendDocumentProps = (props) => {
  const initialEnvSubdomain = process.env.APP_SUBDOMAIN;
  const initialEnvAlias = process.env.APP_ALIAS;
  const hasMinimum = props.subdomain || props.alias;

  if (hasMinimum) {
    const isPlatform =
      props.subdomain === initialEnvSubdomain ||
      props.alias === initialEnvAlias;
    if (isPlatform && props.route) {
      if (!hidePlatformScriptsOnRoute(props.route)) {
        props.showPlatformTags = true;
      }
    }
    if (!hidePlatformSecurityScriptsOnRoute(props.route)) {
      props.showPlatformSecurityTags = true;
    }
  } else {
    console.error("Document - missing min parameters");
    props.showPlatformTags = true;
    props.showPlatformSecurityTags = true;
  }

  const fontConfig = props.theme && props.theme.data && props.theme.data.font;
  if (fontConfig) {
    props.fontFamilyUrl = getFontFamilyUrl(fontConfig);
  } else {
    props.fontFamilyUrl = null;
  }

  return props;
};

export const extendDocumentHeaders = (ctx, props) => {
  const csp = getContentSecurityPolicy(props);

  ctx && ctx.res && ctx.res.setHeader("Content-Security-Policy", csp);
};
