import C from "constants/entitlementConfig";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { setOrganizationEntitlementConfigs } from "./organizationEntitlementConfig";
import { setUserEntitlementConfigs } from "./userEntitlementConfig";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_ENTITLEMENT_CONFIG_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setEntitlementConfigs = (payload) => {
  return {
    type: C.SET_ENTITLEMENT_CONFIGS,
    payload
  };
};

export const removeEntitlementConfigs = (payload) => {
  return {
    type: C.REMOVE_ENTITLEMENT_CONFIGS,
    payload
  };
};

export const replaceEntitlementConfigs = (payload) => {
  return {
    type: C.REPLACE_ENTITLEMENT_CONFIGS,
    payload
  };
};

/**
 * resetEntitlementConfigNotes
 */
export function resetEntitlementConfigNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ENTITLEMENT_CONFIG_STORE,
      payload
    });
  };
}

export function showEntitlementConfigNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ENTITLEMENT_CONFIG_STORE,
        message || `Success!`
      )
    );
  };
}

export const ENTITLEMENT_CONFIG_MODELS = ["entitlementConfigs"];

export const ENTITLEMENT_CONFIG_ACTIONS_MAP = {
  setEntitlementConfigs,
  setOrganizationEntitlementConfigs,
  setUserEntitlementConfigs
};

export const updateEntitlementConfigRequest = (
  { resourceID, resourceUUID, resource, data, uuid, dispatch },
  callback = () => {}
) => {
  dispatch(
    ActionHelpers.loadingAction(
      C.UPDATE_ENTITLEMENT_CONFIG_STORE,
      "update",
      "entitlementConfig",
      "Update entitlementConfig"
    )
  );
  const REQUEST_URL = `${apiResourcePath({
    resource,
    resourceUUID
  })}/entitlement-configs/${uuid}`;

  return axios({
    method: "put",
    url: REQUEST_URL,
    data,
    headers: authBearerToken()
  })
    .then(({ data: responseData }) => {
      const preparedResponse = prepareModelAssociations({
        resource,
        resourceID,
        entitlementConfigs: [responseData]
      });

      const actions = reduceModelActions({
        keys: ENTITLEMENT_CONFIG_MODELS,
        action: ACTION_TYPE.SET,
        resource,
        data: preparedResponse,
        actionsMap: ENTITLEMENT_CONFIG_ACTIONS_MAP
      });

      dispatch(batchActions(actions));

      callback(null, responseData);
    })
    .catch((err) => {
      dispatch(
        batchActions(
          ActionHelpers.errorAndClear(
            C.UPDATE_ENTITLEMENT_CONFIG_STORE,
            err,
            false,
            dispatch
          )
        )
      );
      callback(err);
    });
};

/**
 * updateEntitlementConfig
 */
export function updateEntitlementConfig(
  { resourceUUID, resource, resourceID, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    updateEntitlementConfigRequest(
      { resourceUUID, resource, resourceID, uuid, data, dispatch },
      (err, data) => {
        if (err) {
          callback(err, null);
        } else {
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_ENTITLEMENT_CONFIG_STORE,
                "Entitlements updated"
              )
            );
          }, 250);
          callback(null, data);
        }
      }
    );
  };
}

/**
 * fetchEntitlementConfigs
 */
export function fetchEntitlementConfigs(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ENTITLEMENT_CONFIG_STORE,
        "update",
        "entitlementConfig",
        "Fetch entitlementConfigs"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/entitlement-configs`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          entitlementConfigs: responseData
        });

        const actions = reduceModelActions({
          keys: ENTITLEMENT_CONFIG_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: ENTITLEMENT_CONFIG_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ENTITLEMENT_CONFIG_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
