import pick from "lodash/pick";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import isEqual from "lodash/isEqual";
import { STATE_KEYS } from "./constants/state";
import { reduceFlatFieldGroupSelections } from "./form";

const LOGIC_CHECKOUTS_COMBOS = "logic.checkouts.combinations";

export const getFormLogicSelections = ({
  contentPath,
  values,
  fieldGroups
}) => {
  const fieldsPath = `${contentPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_FIELDS}`;
  const fieldsValues = get(values, fieldsPath);
  const allCurrentFieldValues = reduceFlatFieldGroupSelections(fieldGroups);
  return pick(allCurrentFieldValues, fieldsValues);
};

/**
 * Find the combination which has a matching set of selection values
 * Then return the combination index
 * @param {Object} selections
 * @param {Object} config
 * @returns
 */
export const getConfigLogicCheckoutSelectionsComboIx = (selections, config) => {
  let result;

  const combos = get(config, LOGIC_CHECKOUTS_COMBOS, []);
  for (let comboIx = 0; comboIx < combos.length; comboIx++) {
    const combo = combos[comboIx];
    if (isEqual(combo.selections, selections)) {
      result = comboIx;
    }
  }

  return result;
};

export const getAllCombinationPrimaries = (config) => {
  let result = [];
  const combos = get(config, LOGIC_CHECKOUTS_COMBOS, []);
  for (let comboIx = 0; comboIx < combos.length; comboIx++) {
    const combo = combos[comboIx];

    if (combo && combo.result && Array.isArray(combo.result.primaries)) {
      result = result.concat(combo.result.primaries);
    }
  }

  return result;
};

/**
 * Find the combination which has a matching set of selection values
 * Then return it's result (i.e. checkout config)
 * @param {Object} selections
 * @param {Object} config
 * @returns
 */
export const getConfigLogicCheckoutSelectionsResult = (selections, config) => {
  let result;
  let hasPrimaries = false;
  let allPrimaries = [];
  const combos = get(config, LOGIC_CHECKOUTS_COMBOS, []);
  for (let comboIx = 0; comboIx < combos.length; comboIx++) {
    const combo = combos[comboIx];

    if (combo && combo.result && Array.isArray(combo.result.primaries)) {
      if (!hasPrimaries) {
        hasPrimaries = combo.result.primaries.length > 0;
      }
      allPrimaries = allPrimaries.concat(combo.result.primaries);
    }

    if (isEqual(combo.selections, selections)) {
      result = combo.result;
    }
  }

  return { result, hasPrimaries, primaries: uniqBy(allPrimaries, "product") };
};
