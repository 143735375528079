import cloneDeep from "lodash/cloneDeep";
import { getTemplatePrice } from "utils/constants/price";

const PRODUCTS = "products";
const COUPONS = "coupons";
const TAX_RATES = "tax_rates";
const SHIPPING_RATES = "shipping_rates";

export const PRODUCT_PRICES_TABS = {
  PRODUCTS,
  COUPONS,
  TAX_RATES,
  SHIPPING_RATES
};

export const TEMPLATE_PRODUCT = {
  name: "",
  description: "",
  prices: [getTemplatePrice()]
};

export const getTemplateProduct = () => {
  return cloneDeep(TEMPLATE_PRODUCT);
};

const ACTIVE = "active";
const INACTIVE = "inactive";
const ARCHIVED = "archived";

export const PRODUCT_STATUS = {
  INACTIVE,
  ACTIVE,
  ARCHIVED
};
