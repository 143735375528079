const PAGE_VIEW = "page_view";
const MERCHANT_ACCOUNT_CHECKOUT_STARTED = "merchant_account_checkout_started";
const MERCHANT_ACCOUNT_CHECKOUT_COMPLETED =
  "merchant_account_checkout_completed";

export const EVENT_NAME = {
  PAGE_VIEW,
  MERCHANT_ACCOUNT_CHECKOUT_STARTED,
  MERCHANT_ACCOUNT_CHECKOUT_COMPLETED
};
