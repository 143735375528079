import C from "constants/feature";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_FEATURE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setFeatures = (payload) => {
  return {
    type: C.SET_FEATURES,
    payload
  };
};

export const replaceFeatures = (payload) => {
  return {
    type: C.REPLACE_FEATURES,
    payload
  };
};

export function resetFeatureNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_FEATURE_STORE,
      payload
    });
  };
}

export function showFeatureNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_FEATURE_STORE, message || `Success!`)
    );
  };
}

/**
 * fetchFeatures
 */
export const fetchFeatures = (
  { resourceUUID, resource, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/features`;
    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const features = Array.isArray(data) ? data : [data];

        dispatch(batchActions([setFeatures(features)]));

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_FEATURE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * bulkUpdateFeatures
 */
export const bulkUpdateFeatures = (
  { resourceUUID, resource, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/features/bulk`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(batchActions([replaceFeatures(data.features)]));

        setTimeout(() => {
          dispatch(
            ActionHelpers.successNote(
              C.UPDATE_FEATURE_STORE,
              "Features updated"
            )
          );
        }, 250);

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_FEATURE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
