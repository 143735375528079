import { useState, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { StatusPromise } from "utils/promise";
import { useCapabilities } from "utils/selectors";

export const useStripeFeature = ({ feature }) => {
  const capabilities = useCapabilities();

  return Boolean(
    capabilities && capabilities.stripe && capabilities.stripe[feature]
  );
};

export const useStripeCapabilities = () => {
  const capabilities = useCapabilities();

  return capabilities && capabilities.stripe;
};

export const useStripeStatusPromise = (clientKey) => {
  const hasStripeClientKey = Boolean(clientKey);
  const [isResolved, setIsResolved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const stripePromise = useMemo(
    () =>
      clientKey &&
      StatusPromise({
        promise: loadStripe(clientKey),
        setIsRejected,
        setIsResolved
      }),
    [clientKey]
  );
  const stripeStatus = {
    isResolved: isResolved,
    isRejected: isRejected,
    isFulfilled: isResolved || isRejected
  };

  return {
    stripePromise,
    stripeStatus,
    hasStripeClientKey
  };
};
