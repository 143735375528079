import { captureException } from "./error";

export const StatusPromise = ({ promise, setIsRejected, setIsResolved }) => {
  if (promise.isResolved) return promise;

  let isResolved = false;
  let isRejected = false;

  const result = promise.then(
    (value) => {
      setIsResolved(true);
      isResolved = true;
      return value;
    },
    (err) => {
      setIsRejected(true);
      captureException(err);
      isRejected = true;
      throw err;
    }
  );
  result.isFulfilled = () => isResolved || isRejected;
  result.isResolved = () => isResolved;
  result.isRejected = () => isRejected;

  return result;
};
