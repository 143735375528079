/**
 * Models
 */
import { setOnboardings } from "./onboarding";
import { setLicenses } from "./license";
import { setSubscriptions } from "./subscription";
import { setThemes } from "./theme";
import { setMerchantAccounts } from "./merchantAccount";
import { setApplications } from "./application";
import { setDeployments } from "./deployment";
import { setManifests } from "./manifest";
import { setMetadatas } from "./metadata";
import { setPaymentMethods } from "./paymentMethod";
import { setFeatures } from "./feature";
import { setVariables } from "./variable";
import { setFeatureGroups } from "./featureGroup";
import { setShortLinks } from "./shortLink";
import { setIntegrations } from "./integration";
/**
 * User
 */
import { setUserApplications } from "./userApplication";
import { setUserManifests } from "./userManifest";
import { setUserMerchantAccounts } from "./userMerchantAccount";
import { setUserFeatures } from "./userFeature";
import { setUserFeatureGroups } from "./userFeatureGroup";
import { setUserShortLinks } from "./userShortLink";
/**
 * Organizations
 */
import { setOrganizations } from "./organization";
import { setOrganizationApplications } from "./organizationApplication";
import { setOrganizationMerchantAccounts } from "./organizationMerchantAccount";
import { setOrganizationManifests } from "./organizationManifest";
import { setOrganizationFeatures } from "./organizationFeature";
import { setOrganizationFeatureGroups } from "./organizationFeatureGroup";
import { setOrganizationShortLinks } from "./organizationShortLink";
// ============
import { batchActions } from "redux-batched-actions";
import ActionHelpers, { withMethod } from "./helpers";
import AUTH_CONST from "constants/auth";
import USER_CONST from "constants/user";
import axios from "axios";
import { setJWT, clearJWT, decodeTokenUUID, authBearerToken } from "utils/auth";
import Analytics, { USER_SIGNUP } from "utils/analytics";
import { deconstructReducerData } from "utils/data";
import { replaceCapabilities } from "./capability";
import { METHODS } from "utils/constants/auth";
import { getConfig } from "utils/env";
export const USER_ASSOC_KEYS = ["onboardings", "organizations"];
// const DEFAULT_LOGOUT_MSG = "Logged Out Successfully.";
/**
 * resetAuthNotes
 *
 */
export function resetAuthNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: AUTH_CONST.UPDATE_AUTH_STORE,
      payload
    });
  };
}

/**
 * Logout
 *
 * @api public
 */
export function logout(callback = () => {}) {
  return (dispatch) => {
    logoutWithDispatch(dispatch, callback);
  };
}

export function logoutAction() {
  return {
    type: AUTH_CONST.LOGOUT,
    payload: null
  };
}

export function logoutWithDispatch(dispatch) {
  dispatch(logoutAction());
}

export function loginSuccess(payload) {
  return {
    type: USER_CONST.UPDATE_USER,
    payload
  };
}

/**
 * emailSignup
 */
export function emailSignup(params, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        AUTH_CONST.UPDATE_AUTH_STORE,
        "create",
        "user",
        "Create user record"
      )
    );
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/signup`;
    axios({
      method: "post",
      url: REQUEST_URL,
      data: {
        ...params,
        password_confirmation: params.password
      }
    })
      .then(({ data }) => {
        setJWT(data.jwt);
        const uuid = decodeTokenUUID(data.jwt);
        Analytics.aliasUser({ uuid });
        Analytics.track(USER_SIGNUP);

        dispatch(
          batchActions([
            ActionHelpers.clearLoader(AUTH_CONST.UPDATE_AUTH_STORE)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              AUTH_CONST.UPDATE_AUTH_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * emailLogin
 */
export function emailLogin(params, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        AUTH_CONST.UPDATE_AUTH_STORE,
        "create",
        "user",
        "Login User"
      )
    );
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/sign-in`;

    axios({
      method: "post",
      url: REQUEST_URL,
      data: params
    })
      .then(({ data }) => {
        setJWT(data.jwt);
        const uuid = decodeTokenUUID(data.jwt);
        Analytics.identifyUser({ uuid });

        dispatch(
          batchActions([
            ActionHelpers.clearLoader(AUTH_CONST.UPDATE_AUTH_STORE)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.authErrorAndClear(
              AUTH_CONST.UPDATE_AUTH_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * exchangeToken
 */
export function exchangeToken(ott, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        AUTH_CONST.UPDATE_AUTH_STORE,
        "create",
        "auth",
        "Token exchange"
      )
    );
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/token-exchange?ott=${ott}`;

    axios
      .get(REQUEST_URL)
      .then(({ data }) => {
        setJWT(data.jwt);
        const uuid = decodeTokenUUID(data.jwt);
        Analytics.identifyUser({ uuid });

        dispatch(
          batchActions([
            ActionHelpers.clearLoader(AUTH_CONST.UPDATE_AUTH_STORE)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              AUTH_CONST.UPDATE_AUTH_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

function handleAuthResponse(
  dispatch,
  callback,
  message = "",
  suppress = false
) {
  return (err, data) => {
    if (err) {
      dispatch(
        batchActions(
          ActionHelpers.errorAndClear(
            AUTH_CONST.UPDATE_AUTH_STORE,
            err,
            suppress,
            dispatch
          )
        )
      );
    } else {
      let actions = [ActionHelpers.clearLoader(AUTH_CONST.UPDATE_AUTH_STORE)];

      if (data) {
        const reducers = deconstructReducerData(data, "user", [
          "onboardings",
          "organizations",
          "licenses",
          "applications",
          "deployments",
          "manifests",
          "metadatas",
          "capabilities",
          "features",
          "variables",
          "feature_groups",
          "themes",
          "subscriptions",
          "short_links",
          "payment_methods",
          "merchant_accounts",
          "integrations",
          "user_applications",
          "user_manifests",
          "user_merchant_accounts",
          "user_features",
          "user_feature_groups",
          "user_short_links",
          "organizations.licenses",
          "organizations.organization_applications",
          "organizations.organization_manifests",
          "organizations.applications",
          "organizations.deployments",
          "organizations.subscriptions",
          "organizations.manifests",
          "organizations.integrations",
          "organizations.metadatas",
          "organizations.features",
          "organizations.variables",
          "organizations.themes",
          "organizations.feature_groups",
          "organizations.short_links",
          "organizations.payment_methods",
          "organizations.merchant_accounts",
          "organizations.organization_merchant_accounts",
          "organizations.organization_features",
          "organizations.organization_feature_groups",
          "organizations.organization_short_links"
        ]);

        actions = actions.concat([
          ActionHelpers.clearLoader(AUTH_CONST.UPDATE_AUTH_STORE),
          loginSuccess(reducers.user),
          setOnboardings(reducers.onboardings),
          setOrganizations(reducers.organizations),
          setOrganizationApplications(reducers.organization_applications),
          setUserApplications(reducers.user_applications),
          setOrganizationManifests(reducers.organization_manifests),
          setOrganizationFeatures(reducers.organization_features),
          setOrganizationShortLinks(reducers.organization_short_links),
          setOrganizationFeatureGroups(reducers.organization_feature_groups),
          setOrganizationMerchantAccounts(
            reducers.organization_merchant_accounts
          ),
          setUserMerchantAccounts(reducers.user_merchant_accounts),
          setUserManifests(reducers.user_manifests),
          setUserFeatures(reducers.user_features),
          setUserFeatureGroups(reducers.user_feature_groups),
          setUserShortLinks(reducers.user_short_links),
          setMetadatas(reducers.metadatas),
          setManifests(reducers.manifests),
          setIntegrations(reducers.integrations),
          setLicenses(reducers.licenses),
          setThemes(reducers.themes),
          setShortLinks(reducers.short_links),
          setApplications(reducers.applications),
          setDeployments(reducers.deployments),
          setPaymentMethods(reducers.payment_methods),
          setMerchantAccounts(reducers.merchant_accounts),
          setFeatures(reducers.features),
          setVariables(reducers.variables),
          setFeatureGroups(reducers.feature_groups),
          setSubscriptions(reducers.subscriptions),
          replaceCapabilities(reducers.capabilities),
          {
            type: AUTH_CONST.SET_AUTH,
            payload: {
              email: reducers.user.email
            }
          }
        ]);
      }

      dispatch(batchActions(actions));
    }
    callback(err);
  };
}

export function fetchUserDetails(suppress, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        AUTH_CONST.UPDATE_AUTH_STORE,
        "create",
        "login",
        "Logging in"
      )
    );

    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/login`;
    const requestHeaders = { headers: authBearerToken() };

    return axios
      .get(REQUEST_URL, requestHeaders)
      .then(({ data }) => {
        handleAuthResponse(dispatch, callback, null, suppress)(null, data);
      })
      .catch((err) => {
        clearJWT();

        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              AUTH_CONST.UPDATE_AUTH_STORE,
              err,
              suppress,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

export function fetchMockUserDetails(suppress, callback = () => {}) {
  return (dispatch) => {
    withMethod({
      method: METHODS.GET,
      url: `${getConfig("API_ROOT")}/v1/login/mock`,
      onComplete: (data) => {
        handleAuthResponse(dispatch, callback, null, suppress)(null, data);
        return null;
      },
      constant: AUTH_CONST.UPDATE_APP_STORE,
      dispatch,
      callback
    });
  };
}

export function emailResetRequest(params, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        AUTH_CONST.UPDATE_AUTH_STORE,
        "reset",
        "user",
        "Reset user email"
      )
    );
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/reset/email/request`;

    axios({
      method: "post",
      url: REQUEST_URL,
      data: params
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(AUTH_CONST.UPDATE_AUTH_STORE),
            ActionHelpers.successNote(
              AUTH_CONST.UPDATE_AUTH_STORE,
              "Check your inbox for reset instructions. Can't find it? Check your spam folder or reach out to live chat support.",
              { autoClose: false }
            )
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              AUTH_CONST.UPDATE_AUTH_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

export function emailResetConfirm(params, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        AUTH_CONST.UPDATE_AUTH_STORE,
        "reset",
        "user",
        "Confirm user email reset"
      )
    );

    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/reset/email/confirm`;

    axios({
      method: "post",
      url: REQUEST_URL,
      data: params
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(AUTH_CONST.UPDATE_AUTH_STORE),
            ActionHelpers.successNote(
              AUTH_CONST.UPDATE_AUTH_STORE,
              "Password reset successful. Please sign in again."
            )
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              AUTH_CONST.UPDATE_AUTH_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
