import C from "constants/userMerchantAccount";
import ActionHelpers from "./helpers";
// import axios from "axios";
// import { batchActions } from "redux-batched-actions";
// import { authBearerToken } from "utils/auth";

export function setLoading() {
  return {
    type: C.UPDATE_USER_MERCHANT_ACCOUNT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserMerchantAccounts = (payload) => {
  return {
    type: C.SET_USER_MERCHANT_ACCOUNTS,
    payload
  };
};

export const replaceUserMerchantAccounts = (payload) => {
  return {
    type: C.REPLACE_USER_MERCHANT_ACCOUNTS,
    payload
  };
};

/**
 * resetUserMerchantAccountNotes
 */
export function resetUserMerchantAccountNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_USER_MERCHANT_ACCOUNT_STORE,
      payload
    });
  };
}

export function showUserMerchantAccountNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_USER_MERCHANT_ACCOUNT_STORE,
        message || `Success!`
      )
    );
  };
}
