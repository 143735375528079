const FIRST_PAGE = "first_page";
const BUILDER_PAYMENT_LINK = "builder_payment_link";
const CONTENT_DOWNLOAD = "content_download";

export const CELEBRATE_MESSAGES = {
  [FIRST_PAGE]: "Congratulations on publishing your first page!",
  [BUILDER_PAYMENT_LINK]: "Congratulations on creating a payment link!",
  [CONTENT_DOWNLOAD]: "Your content is ready to download!"
};

export const CELEBRATE = {
  FIRST_PAGE,
  BUILDER_PAYMENT_LINK,
  CONTENT_DOWNLOAD
};
