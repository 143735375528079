import C from "constants/theme";
import ActionHelpers from "./helpers";
// import axios from "axios";
// import { batchActions } from "redux-batched-actions";
// import { authBearerToken } from "utils/auth";

export function setLoading() {
  return {
    type: C.UPDATE_THEME_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setThemes = (payload) => {
  return {
    type: C.SET_THEMES,
    payload
  };
};

export const replaceThemes = (payload) => {
  return {
    type: C.REPLACE_THEMES,
    payload
  };
};

/**
 * resetThemeNotes
 */
export function resetThemeNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_THEME_STORE,
      payload
    });
  };
}

export function showThemeNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_THEME_STORE, message || `Success!`)
    );
  };
}
