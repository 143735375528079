import C from "constants/promotionCode";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { REQUEST_METHOD } from "utils/constants/request";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_PROMOTION_CODE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setPromotionCodes = (payload) => {
  return {
    type: C.SET_PROMOTION_CODES,
    payload
  };
};

export const replacePromotionCodes = (payload) => {
  return {
    type: C.REPLACE_PROMOTION_CODES,
    payload
  };
};

export const removePromotionCodes = (payload) => {
  return {
    type: C.REMOVE_PROMOTION_CODES,
    payload
  };
};

/**
 * resetPromotionCodeNotes
 */
export function resetPromotionCodeNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_PROMOTION_CODE_STORE,
      payload
    });
  };
}

export function showPromotionCodeNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_PROMOTION_CODE_STORE,
        message || `Success!`
      )
    );
  };
}

/**
 * createMerchantAccountPromotionCodes
 */
export const createMerchantAccountPromotionCodes = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/promotion-codes`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        if (responseData.promotion_codes.length > 0) {
          dispatch(
            batchActions([
              setPromotionCodes(responseData.promotion_codes),
              setTimeout(() => {
                dispatch(
                  ActionHelpers.successNote(
                    C.UPDATE_PROMOTION_CODE_STORE,
                    "PromotionCodes created"
                  )
                );
              }, 250)
            ])
          );
        }
        callback(null, responseData.promotion_codes);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PROMOTION_CODE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateMerchantAccountPromotionCodes
 */
export const updateMerchantAccountPromotionCodes = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/promotion-codes`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        if (responseData.promotion_codes.length > 0) {
          dispatch(
            batchActions([
              setPromotionCodes(responseData.promotion_codes),
              setTimeout(() => {
                dispatch(
                  ActionHelpers.successNote(
                    C.UPDATE_PROMOTION_CODE_STORE,
                    `PromotionCode${
                      responseData.promotion_codes.length > 1 ? "s" : ""
                    } updated`
                  )
                );
              }, 250)
            ])
          );
        }
        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PROMOTION_CODE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * deleteMerchantAccountPromotionCodes
 */
export const deleteMerchantAccountPromotionCodes = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/promotion-codes`;

    return axios({
      method: REQUEST_METHOD.DELETE,
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        if (responseData.promotion_codes.length > 0) {
          dispatch(
            batchActions([removePromotionCodes(responseData.promotion_codes)])
          );
        }
        callback(null, responseData.promotion_codes);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PROMOTION_CODE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
