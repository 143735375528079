import get from "lodash/get";
import omit from "lodash/omit";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import { ENV } from "utils/constants/env";
import { INTEGRATION_OMISSION_KEYS } from "utils/constants/metadata";
import {
  METADATA_KEYS,
  TITLE,
  DESCRIPTION,
  NAME,
  IMAGE,
  OG_IMAGE,
  OG_TITLE,
  OG_URL,
  OG_DESCRIPTION,
  OG_TYPE,
  OG_IMAGE_WIDTH,
  OG_IMAGE_HEIGHT,
  OG_SITE_NAME,
  TWITTER_CARD,
  TWITTER_TITLE,
  TWITTER_DESCRIPTION,
  TWITTER_IMAGE,
  TWITTER_SITE,
  TWITTER_CREATOR,
  ICON,
  ICON_SIZES,
  APPLE_TOUCH_ICON,
  MASK_ICON,
  MASK_ICON_COLOR,
  DEFAULT_TWITTER_CARD,
  DEFAULT_ICON_SIZES,
  DEFAULT_MASK_ICON_COLOR,
  DEFAULT_OG_TYPE,
  DEFAULT_OG_IMAGE_WIDTH,
  DEFAULT_OG_IMAGE_HEIGHT
} from "utils/constants/meta";
import { ASSET_PATHS } from "./paths";
import { defaultHttps } from "./url";
import {
  getDefaultShortLinkMetadataDescription,
  getShortLinkImages,
  getShortLinkCheckoutUrl,
  getShortLinkProductPrimariesImages
} from "./shortLink";
import { getUUID } from "./uuid";
import { getManifestAssociations } from "./manifest";

export const getMetadataInitialValues = (admin) => ({
  url: get(admin, "profile.url", "")
});
/**
 * Prevent the user from seeing hidden metadata keys
 * We dont want them overwriting these protected keys that facilitate references and associations
 * @param {Object} metadata
 */
export const getEditableMetadataFields = (
  metadata,
  env = process.env.NODE_ENV
) => {
  if (metadata) {
    if (env === ENV.PRODUCTION) {
      return Object.keys(omit(metadata, INTEGRATION_OMISSION_KEYS));
    } else {
      return Object.keys(metadata);
    }
  } else {
    return [];
  }
};

/**
 * Prevent the user from seeing hidden metadata keys
 * We dont want them overwriting these protected keys that facilitate references and associations
 * @param {Object} metadata
 */
export const prepareMetadataFieldArray = (
  metadata,
  env = process.env.NODE_ENV
) => {
  let result = [];
  if (metadata) {
    if (env === ENV.PRODUCTION) {
      result = Object.keys(omit(metadata, INTEGRATION_OMISSION_KEYS));
    } else {
      result = Object.keys(metadata);
    }
  }

  return compact(result).map((key) => ({
    uuid: getUUID(),
    key,
    value: metadata[key]
  }));
};

const HTTPS_KEYS = [OG_URL, IMAGE, OG_IMAGE, TWITTER_IMAGE];

export const filterMetadataKeys = (values) =>
  Object.keys(pick(values, METADATA_KEYS));

export const prepareMetadataValues = (values) => {
  return {
    data: Object.keys(values).reduce((memo, metaKey) => {
      let trimmedValue = values[metaKey] ? values[metaKey].trim() : "";

      if (HTTPS_KEYS.indexOf(metaKey) > -1 && trimmedValue) {
        trimmedValue = defaultHttps(trimmedValue);
      }
      memo[metaKey] = trimmedValue;

      return memo;
    }, {})
  };
};

export const buildMetadataData = ({
  title,
  description,
  shortLink,
  merchantAccount,
  images,
  products
}) => {
  /**
   * Icon and logo fallback getters on merchant account
   */
  let icon;
  const bizIcon = get(merchantAccount, "profile.businessIcon");
  if (bizIcon) {
    icon = bizIcon;
  } else {
    const fileIcon = get(merchantAccount, "account.settings.branding.icon");
    if (fileIcon) {
      icon = `${ASSET_PATHS.STRIPE}/${fileIcon}`;
    }
  }

  let logo;
  const bizLogo = get(merchantAccount, "profile.businessLogo");
  if (bizLogo) {
    logo = bizLogo;
  } else {
    const fileLogo = get(merchantAccount, "account.settings.branding.logo");
    if (fileLogo) {
      logo = `${ASSET_PATHS.STRIPE}/${fileLogo}`;
    }
  }
  const profileName = get(merchantAccount, "profile.name");
  const name = profileName && profileName.trim();

  const profileUrl = get(merchantAccount, "profile.url");
  const url = profileUrl && profileUrl.trim();

  const result = {
    [OG_TYPE]: DEFAULT_OG_TYPE,
    [TWITTER_CARD]: DEFAULT_TWITTER_CARD,
    [ICON_SIZES]: DEFAULT_ICON_SIZES,
    [MASK_ICON_COLOR]: DEFAULT_MASK_ICON_COLOR,
    [OG_IMAGE_WIDTH]: DEFAULT_OG_IMAGE_WIDTH,
    [OG_IMAGE_HEIGHT]: DEFAULT_OG_IMAGE_HEIGHT
  };
  if (name) {
    result[NAME] = name;
    result[OG_SITE_NAME] = name;
  }

  const shortLinkId = shortLink && shortLink.short_id;
  if (url) {
    result[OG_URL] = defaultHttps(url);
  } else if (shortLinkId) {
    result[OG_URL] = getShortLinkCheckoutUrl({
      id: shortLinkId,
      mode: ENV.PRODUCTION
    });
  }
  const trimmedTitle = title && title.trim();
  if (trimmedTitle) {
    result[TITLE] = trimmedTitle;
    result[OG_TITLE] = trimmedTitle;
    result[TWITTER_TITLE] = trimmedTitle;
  }

  const trimmedIcon = icon && icon.trim();
  if (trimmedIcon) {
    result[ICON] = trimmedIcon;
    result[APPLE_TOUCH_ICON] = trimmedIcon;
    result[MASK_ICON] = trimmedIcon;
  }

  const ctxImage = images && images.length ? images[0] : logo || icon;
  const trimmedImage = ctxImage && ctxImage.trim();

  if (trimmedImage) {
    const httpsImage = defaultHttps(trimmedImage);
    result[IMAGE] = httpsImage;
    result[OG_IMAGE] = httpsImage;
    result[TWITTER_IMAGE] = httpsImage;
  }

  let trimmedDescription;
  if (description) {
    trimmedDescription = description && description.trim();
  } else if (shortLinkId && products && products.length > 0) {
    trimmedDescription = getDefaultShortLinkMetadataDescription({
      shortLink,
      products
    });
  }
  if (trimmedDescription) {
    result[DESCRIPTION] = trimmedDescription;
    result[OG_DESCRIPTION] = trimmedDescription;
    result[TWITTER_DESCRIPTION] = trimmedDescription;
  }

  return result;
};

export const processCreateMetadata = (metadata) => {
  let result = Array.isArray(metadata)
    ? reduceMetadata(metadata)
    : { ...metadata };
  if (isEmpty(result)) {
    result = null;
  } else {
    for (const key in result) {
      const value = result[key];
      if (!value) {
        result[key] = null;
      }
    }
  }

  return result;
};

const reduceMetadata = (metadata = {}) =>
  metadata && metadata.reduce
    ? metadata.reduce((memo, data) => {
        memo[data.key] = data.value;
        return memo;
      }, {})
    : {};

/**
 * Reduce aray of metadata key:val pairs into an object
 * @param {Array} metadata
 */
export const processUpdateMetadata = (initial, current) => {
  const reducedInitial = reduceMetadata(initial);
  const reducedCurrent = reduceMetadata(current);

  const result = Object.keys(reducedInitial).reduce((memo, initialKey) => {
    const trimmedKey = initialKey && initialKey.trim();
    if (trimmedKey) {
      const currentValueForInitialKey = reducedCurrent[trimmedKey];
      // Removed from current
      if (!currentValueForInitialKey) {
        delete reducedCurrent[reducedInitial];
        memo[trimmedKey] = null;
      } else {
        // Present in current
        delete reducedCurrent[reducedInitial];
        memo[trimmedKey] = currentValueForInitialKey.trim();
      }
    }
    return memo;
  }, {});

  Object.keys(reducedCurrent).forEach((currentKey) => {
    const trimmedKey = currentKey && currentKey.trim();
    if (trimmedKey) {
      const currentValue = reducedCurrent[trimmedKey].trim();
      if (currentValue) {
        result[trimmedKey] = currentValue;
      }
    }
  });

  return isEmpty(result) ? null : result;
};

export const getStaticContainerMeta = ({ navConfig, frontMatter }) => ({
  navConfig: navConfig,
  title: `${frontMatter.title} – PriceBlocs`,
  description: frontMatter.summary,
  image: `https://priceblocs.com${frontMatter.image}`,
  date: new Date(frontMatter.publishedAt).toISOString(),
  type: "article"
});

export const parseMinMetadataValues = (metadata) => ({
  title: get(metadata, "data.title", ""),
  description: get(metadata, "data.description", ""),
  twitter: get(metadata, `data["twitter:site"]`, "")
});

export const getUpdateMetadataInitialValues = ({
  metadata,
  shortLink,
  manifest,
  products
}) => {
  const images = shortLink
    ? getShortLinkImages({
        shortLink,
        products
      })
    : getManifestAssociations({ manifest, products }).images;

  const liveUrl = shortLink
    ? getShortLinkCheckoutUrl({
        id: shortLink.short_id,
        mode: ENV.PRODUCTION
      })
    : "";

  const result = parseMinMetadataValues(metadata);
  if (liveUrl) {
    result.url = liveUrl;
  }
  if (images && images.length) {
    result.image = images[0];
  }

  return result;
};

export const prepareUpdateMetadataValues = ({
  values,
  metadata,
  products,
  merchantAccount,
  shortLink,
  manifest
}) => {
  const images = shortLink
    ? getShortLinkProductPrimariesImages({ shortLink, products })
    : getManifestAssociations({ manifest, products }).images;

  const defaultValues = buildMetadataData({
    shortLink,
    manifest,
    images,
    merchantAccount,
    products
  });

  const result = {
    ...defaultValues,
    ...metadata.data
  };

  const trimmedTitle = values.title && values.title.trim();
  if (trimmedTitle) {
    result[TITLE] = trimmedTitle;
    result[OG_TITLE] = trimmedTitle;
    result[TWITTER_TITLE] = trimmedTitle;
  }

  const trimmedDescription = values.description && values.description.trim();
  if (trimmedDescription) {
    result[DESCRIPTION] = trimmedDescription;
    result[OG_DESCRIPTION] = trimmedDescription;
    result[TWITTER_DESCRIPTION] = trimmedDescription;
  }
  const twitter = values.twitter && values.twitter.trim();
  if (twitter) {
    result[TWITTER_SITE] = twitter;
    result[TWITTER_CREATOR] = twitter;
  }

  return prepareMetadataValues(result);
};

export const getTemplateStripeMetadata = () => ({
  uuid: getUUID(),
  key: "",
  value: ""
});
