const MANUAL = "manual";
const AUTOCOMPLETE = "autocomplete";

const GOOGLE = "google";

export const ADDRESS_SERVICE = {
  GOOGLE
};

export const ADDRESS_SERVICE_OPTIONS = [
  {
    label: "Google",
    uid: ADDRESS_SERVICE.GOOGLE,
    description: "Google Places API"
  }
];

export const ADDRESS_INPUT_TYPE = {
  MANUAL,
  AUTOCOMPLETE
};

export const ADDRESS_INPUT_TYPE_OPTIONS = [
  {
    label: "Manual",
    uid: ADDRESS_INPUT_TYPE.MANUAL,
    description: "Manual address entry."
  },
  {
    label: "Autocomplete",
    uid: ADDRESS_INPUT_TYPE.AUTOCOMPLETE,
    description: "Autocomplete address entry via the Google Maps API."
  }
];

const CITY = "city";
const COUNTRY = "country";
const LINE_ONE = "line1";
const LINE_TWO = "line2";
const POSTAL_CODE = "postalCode";
const STATE = "state";
export const ADDRESS_KEYS = {
  CITY,
  COUNTRY,
  LINE_ONE,
  LINE_TWO,
  POSTAL_CODE,
  STATE
};

export const ADDRESS_KEY_LABEL_MAP = {
  [CITY]: "City",
  [COUNTRY]: "Country",
  [LINE_ONE]: "Address line one",
  [LINE_TWO]: "Address line two",
  [POSTAL_CODE]: "ZIP / postal code",
  [STATE]: "State"
};

export const REQUIRED_ADDRESS_KEYS = {
  CITY,
  COUNTRY,
  LINE_ONE,
  STATE,
  POSTAL_CODE
};

const LAT = "lat";
const LNG = "lng";
export const ADDRESS_LOCATION_KEYS = [LAT, LNG];

export const ADDRESS_LABEL_KEYS = [
  ADDRESS_KEYS.LINE_ONE,
  ADDRESS_KEYS.CITY,
  [ADDRESS_KEYS.STATE, ADDRESS_KEYS.POSTAL_CODE],
  ADDRESS_KEYS.COUNTRY
];

const NAME = "name";
export const ADDRESS_RECEIVER_KEYS = {
  NAME
};
export const ADDRESS_RECEIVER_KEY_LABEL_MAP = {
  [NAME]: "Receiver name"
};
export const REQUIRED_ADDRESS_RECEIVER_KEYS = {
  NAME
};

export const US_COUNTRY_CODE = "US";
