import React, { useState } from "react";
import {
  getFormLogicSelections,
  getConfigLogicCheckoutSelectionsResult
} from "utils/logic";
import { PAGE_ACTION_UIDS } from "components/FormerEditor/common/constants";
import { useFormikContext } from "formik";
import { getCheckoutActionButtonProps } from "utils/checkout";
import {
  ADMIN,
  CAMPAIGN_CHECKOUT,
  CONNECT,
  STATE_KEYS
} from "utils/constants/state";
import get from "lodash/get";
import { usePageFactoryContext } from "utils/context";
import FormattedToast from "components/FormattedToast";
import { getFieldPath, getValidateFieldGroups } from "utils/form";
import { useQuerySessionParams } from "utils/route";
import { usePageEditMode } from ".";
import { getEditorCheckoutNotice, getPageCheckoutNotice } from "utils/editor";
import { useDirtyEditor, useIsUnauthedBuilder } from "utils/hooks/editor";
import { getLinkActionButtonProps } from "utils/action";
import { getErrorMessage } from "utils/error";
import { useToasts } from "react-toast-notifications";
import { BUTTON_TAGS } from "utils/constants/ui";
import {
  ERROR_RESPONSE_MESSAGE,
  ERROR_RESPONSE_PATH
} from "utils/constants/error";
import { useStripe } from "@stripe/react-stripe-js";
import { composeCheckoutConfigsToShortLink } from "utils/checkout/merge";

const SUBDOMAIN = "subdomain";
const ALIAS = "alias";

/**
 * Return form current selections config
 * @param {Object} params
 * @param {String} params.name
 * @returns
 */
export const useMergedFormSelectionsCheckoutConfig = ({
  name,
  fieldGroups
}) => {
  const { stateKey } = usePageFactoryContext();
  const { values } = useFormikContext();

  const contentRoot = `content.${name}`;
  const contentPath = getFieldPath(stateKey, contentRoot);

  const configPath = `${contentPath}.config`;
  const configValues = get(values, configPath);

  const selections = getFormLogicSelections({
    contentPath,
    values,
    fieldGroups
  });

  const commonCheckoutPath = `${contentPath}.${STATE_KEYS.FORM.CONFIG_CHECKOUT_COMMON}`;
  const commonCheckoutValues = get(values, commonCheckoutPath);

  const checkoutConstantPath = `${contentPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_CONSTANT}`;
  const checkoutConstant = get(values, checkoutConstantPath);

  const connectPath = getFieldPath(stateKey, CONNECT);
  const connect = get(values, connectPath);

  const campaignCheckoutPath = getFieldPath(stateKey, CAMPAIGN_CHECKOUT);
  const campaignCheckout = get(values, campaignCheckoutPath);

  const { result: selectedCheckout } = getConfigLogicCheckoutSelectionsResult(
    selections,
    configValues
  );

  return composeCheckoutConfigsToShortLink({
    commonConfig: commonCheckoutValues,
    constantCheckout: checkoutConstant,
    connect,
    campaignCheckout,
    selectedCheckout
  });
};

export const useAdminRootPath = () => {
  const { stateKey } = usePageFactoryContext();
  return getFieldPath(stateKey, ADMIN);
};

export const useManifestAdminProps = () => {
  const { values } = useFormikContext();
  const adminRootPath = useAdminRootPath();

  const chargesEnabled =
    get(values, `${adminRootPath}.${STATE_KEYS.ACCOUNT.CHARGES_ENABLED}`) ||
    false;
  const detailsSubmitted =
    get(values, `${adminRootPath}.${STATE_KEYS.ACCOUNT.DETAILS_SUBMITTED}`) ||
    false;
  const subdomain = get(values, `${adminRootPath}.${SUBDOMAIN}`);
  const alias = get(values, `${adminRootPath}.${ALIAS}`);

  return {
    chargesEnabled,
    detailsSubmitted,
    subdomain,
    alias,
    hasMinAssociation: Boolean(subdomain || alias)
  };
};

/**
 * Show conditional guidance node depending on
 * - Account enabled status
 * - Editor status
 */
export const getCheckoutNotice = ({
  canActivateSettings,
  dirtyEditor,
  isLive,
  chargesEnabled,
  detailsSubmitted,
  isSubmitting,
  isUnauthedBuilder
}) => {
  const isEditor = canActivateSettings;
  const pageNotice = getPageCheckoutNotice({
    chargesEnabled,
    detailsSubmitted
  });
  const editorNotice = getEditorCheckoutNotice({
    isLive,
    dirtyEditor,
    canActivateSettings
  });
  const hasAnyNotice = editorNotice || pageNotice;
  const canShowPageNotice = pageNotice && !isEditor;
  const canShowEditorNotice =
    hasAnyNotice && isEditor && !isSubmitting && !isUnauthedBuilder;

  let result = null;
  if (canShowPageNotice) {
    result = pageNotice;
  } else if (canShowEditorNotice) {
    result = editorNotice || pageNotice;
  }

  return result;
};

/**
 * Validate fields against defined configuration
 * - required: need to have a value
 * - format: need to fit a format e.g email
 * - type: address | tin etc.
 */
export const useFieldValidationErrors = ({ name }) => {
  const { values, errors } = useFormikContext();
  const { stateKey } = usePageFactoryContext();
  const fieldGroupsPath = getFieldPath(
    stateKey,
    `content.${name}.${STATE_KEYS.FORM.FIELD_GROUPS}`
  );
  const fieldGroups = get(values, fieldGroupsPath, []);

  return getValidateFieldGroups({
    name: fieldGroupsPath,
    fieldGroups,
    errors
  });
};

export const getCheckoutActionProps = (
  {
    action,
    alias,
    addToast,
    builderUrl,
    editable,
    checkout,
    clientCheckout,
    canActivateSettings,
    customClasses,
    disabled,
    index,
    isSecondary,
    loading,
    setLoading,
    setFieldError,
    setFieldTouched,
    stripe,
    stateKey,
    subdomain,
    tracking,
    styles
  },
  { checkoutAction, checkoutParams }
) => {
  const { copy, href, theme, action_uid: type } = action;

  /**
   * VIP: Client side setting of server generated validations
   * - err.response.data.path: will be the path to the validation error for the client
   */
  const onError = (err) => {
    const errPath = get(err, ERROR_RESPONSE_PATH);
    const errMessage = get(err, ERROR_RESPONSE_MESSAGE);

    /**
     * If theres an err path use it.
     * Else fallback to a general error toast
     */
    if (errPath) {
      const adjustedPath = getFieldPath(stateKey, errPath);
      /**
       * Order here is important so that error message is not prematurely cleared
       * - touched
       * - then error message
       */
      setFieldTouched(adjustedPath, true);
      setTimeout(() => setFieldError(adjustedPath, errMessage), 0);
    } else {
      addToast(<FormattedToast type="error" copy={getErrorMessage(err)} />, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };

  let result = getCheckoutActionButtonProps({
    copy,
    loading,
    disabled,
    theme,
    index,
    type,
    styles,
    customClasses,
    isSecondary
  });

  const canGiveGuidance = canActivateSettings || editable;

  if (PAGE_ACTION_UIDS && type === PAGE_ACTION_UIDS.LINK) {
    result = getLinkActionButtonProps(result, {
      href,
      canActivateSettings,
      editable,
      builderUrl,
      canGiveGuidance,
      addToast
    });
  } else if (PAGE_ACTION_UIDS && type === PAGE_ACTION_UIDS.STRIPE_CHECKOUT) {
    result.tag = BUTTON_TAGS.BUTTON;

    result.onClick = checkoutAction({
      stripe,
      setLoading,
      onError,
      /**
       * The remainder of these props get passed to get request params call
       */
      subdomain,
      alias,
      checkout,
      clientCheckout,
      tracking,
      /**
       * Spread last to override. Key examples
       * - formData
       * - price
       * - onError | onSuccess
       */
      ...checkoutParams
    });
  }

  return result;
};

/**
 * Get button props for each context
 * - Control conditional disabled state
 * - Also get guidance & notices for user based on account admin status etc.
 * @param {Object} common - props shared across various checkout action contexts
 * @param {Object} overrides - overrides per context - e.g. form action checkout for a form / identity action checkout for identity etc.
 * @returns
 */
export const useCheckoutActionProps = (
  { name, index, action, disabled, isLive, isSecondary, customClasses },
  { checkoutAction, checkoutParams }
) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const checkout = useQuerySessionParams();
  const {
    isSubmitting,
    setFieldError,
    setFieldTouched,
    values
  } = useFormikContext();
  const { editable, builderUrl } = usePageEditMode();
  const isUnauthedBuilder = useIsUnauthedBuilder();

  const {
    canActivateSettings,
    stateKey,
    tracking,
    clientCheckout
  } = usePageFactoryContext();
  /**
   * Stripe instance needs to pulled from useStripe
   * Assumes the Stripe lib has been instantiated via loadStripe and this call comes from a point within <Elemenets></Elemenets> context
   * Remove if need to support no payment form
   */
  const stripe = useStripe();

  const dirtyEditor = useDirtyEditor();
  const {
    chargesEnabled,
    detailsSubmitted,
    subdomain,
    alias,
    hasMinAssociation
  } = useManifestAdminProps();

  const notice = getCheckoutNotice({
    canActivateSettings,
    dirtyEditor,
    isLive,
    chargesEnabled,
    detailsSubmitted,
    isSubmitting: Boolean(isSubmitting || loading),
    isUnauthedBuilder
  });

  /**
   * ============
   * Disabled check
   * ============
   * [Common]
   * - action is type of stripe checkout
   * - not disabled
   * - Stripe charges are enabled
   * - has account minAssociation (subdomain | alias)
   * [Form pages]
   * - formData is not empty
   * - page is live (circumvent under certain circumstances)
   * - no field validation errors (TODO: hoist to form level validations)
   * [Note]
   * liveOrBuilder
   * - manifests which are live can checkout
   * -- Why? we lookup the manifest on the BE to fetch fields and saved config
   * - unauthed builder
   * -- manifest doesn't need to be published when using functional component checkout
   */
  const liveOrBuilder = isLive || isUnauthedBuilder;
  const isCheckoutAction =
    action.action_uid === PAGE_ACTION_UIDS.STRIPE_CHECKOUT;
  const ctxDisabled =
    disabled ||
    !isCheckoutAction ||
    !chargesEnabled ||
    !liveOrBuilder ||
    !hasMinAssociation ||
    !stripe;

  /**
   * User specified stylings used to extend component stylings
   */
  const actionStylesPath = getFieldPath(
    stateKey,
    `content.${name}.ui.${action.uuid}`
  );
  const styles = get(values, actionStylesPath);

  const props = getCheckoutActionProps(
    {
      action,
      alias,
      addToast,
      builderUrl,
      editable,
      checkout,
      clientCheckout,
      canActivateSettings,
      customClasses,
      disabled: ctxDisabled,
      index,
      isSecondary,
      loading,
      setLoading,
      setFieldError,
      setFieldTouched,
      stripe,
      stateKey,
      subdomain,
      styles,
      tracking
    },
    {
      checkoutAction,
      checkoutParams
    }
  );

  return {
    loading,
    notice,
    props,
    canActivateSettings,
    isUnauthedBuilder,
    isSubmitting
  };
};
