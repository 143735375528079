import C from "constants/merchantAccount";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { setOnboardings } from "./onboarding";
import { setProducts } from "./product";
import { setPrices } from "./price";
import { setCoupons } from "./coupon";
import { setPromotionCodes } from "./promotionCode";
import { apiResourcePath } from "utils/paths";
import {
  replaceOrganizationMerchantAccounts,
  setOrganizationMerchantAccounts
} from "./organizationMerchantAccount";
import {
  replaceUserMerchantAccounts,
  setUserMerchantAccounts
} from "./userMerchantAccount";
import { prepareModelAssociations } from "utils/data";
import { reduceModelActions } from "utils/action";
import { ACTION_TYPE } from "utils/constants/action";

export function setLoading() {
  return {
    type: C.UPDATE_MERCHANT_ACCOUNT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setMerchantAccounts = (payload) => {
  return {
    type: C.SET_MERCHANT_ACCOUNTS,
    payload
  };
};

export const unsetMockMerchantAccount = (payload) => {
  return {
    type: C.UNSET_MOCK_MERCHANT_ACCOUNT,
    payload
  };
};

export const setMerchantAccountConfig = (payload) => {
  return {
    type: C.SET_MERCHANT_ACCOUNT_CONFIG,
    payload
  };
};

export const removeMerchantAccounts = (payload) => {
  return {
    type: C.REMOVE_MERCHANT_ACCOUNTS,
    payload
  };
};

export const setMerchantAccountValues = (payload) => {
  return {
    type: C.SET_MERCHANT_ACCOUNT_VALUES,
    payload
  };
};

export const replaceMerchantAccounts = (payload) => {
  return {
    type: C.REPLACE_MERCHANT_ACCOUNTS,
    payload
  };
};

export function resetMerchantAccountNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_MERCHANT_ACCOUNT_STORE,
      payload
    });
  };
}

export function showMerchantAccountNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_MERCHANT_ACCOUNT_STORE,
        message || `Success!`
      )
    );
  };
}

export const MERCHANT_ACCOUNT_MODELS = ["merchantAccounts"];

export const MERCHANT_ACCOUNT_ACTIONS_MAP = {
  setMerchantAccounts,
  setOrganizationMerchantAccounts,
  setUserMerchantAccounts
};

/**
 * exchangeAndCreateStripeMerchantAccount
 */
export const exchangeAndCreateStripeMerchantAccount = (
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/stripe`;
    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { merchantAccount, onboarding } }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          merchantAccounts: [merchantAccount]
        });

        const actions = reduceModelActions({
          keys: MERCHANT_ACCOUNT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: MERCHANT_ACCOUNT_ACTIONS_MAP
        });

        actions.push(
          ActionHelpers.successNote(
            C.UPDATE_MERCHANT_ACCOUNT_STORE,
            `Connected Stripe merchant account`
          )
        );

        if (onboarding) {
          actions.push(setOnboardings([onboarding]));
        }

        dispatch(batchActions(actions));

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * exchangeAndUpdateStripeTestMerchantAccount
 */
export const exchangeAndUpdateStripeTestMerchantAccount = (
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/stripe/test`;
    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { merchantAccount, onboarding } }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          merchantAccounts: [merchantAccount]
        });

        const actions = reduceModelActions({
          keys: MERCHANT_ACCOUNT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: MERCHANT_ACCOUNT_ACTIONS_MAP
        });

        actions.push(
          ActionHelpers.successNote(
            C.UPDATE_MERCHANT_ACCOUNT_STORE,
            `Enabled test payments mode`
          )
        );

        if (onboarding) {
          actions.push(setOnboardings([onboarding]));
        }

        dispatch(batchActions(actions));

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchMerchantAccountProductsPrices
 */
export function fetchMerchantAccountProductsPrices(
  { resourceUUID, resource, uuid, test, force },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_MERCHANT_ACCOUNT_STORE,
        "get",
        "app",
        "Fetch all merchant account product prices"
      )
    );

    let REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID,
      test
    })}/merchant-accounts/${uuid}/products/prices`;
    if (typeof force === "boolean") {
      REQUEST_URL += `?force=${force}`;
    }

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const { products, prices } = data;
        const actions = [
          ActionHelpers.clearLoader(C.UPDATE_MERCHANT_ACCOUNT_STORE)
        ];
        if (Array.isArray(products) && products.length) {
          actions.push(setProducts(products));
        }
        if (Array.isArray(prices) && prices.length) {
          actions.push(setPrices(prices));
        }

        dispatch(batchActions(actions));
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchMerchantAccounCouponsPromotionCodess
 */
export function fetchMerchantAccountCouponsPromotionCodes(
  { resourceUUID, resource, uuid, test },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_MERCHANT_ACCOUNT_STORE,
        "get",
        "app",
        "Fetch all merchant account coupons promition codes"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID,
      test
    })}/merchant-accounts/${uuid}/coupons/promotion-codes`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const { coupons, promotionCodes } = data;
        const actions = [
          ActionHelpers.clearLoader(C.UPDATE_MERCHANT_ACCOUNT_STORE)
        ];
        if (Array.isArray(coupons) && coupons.length) {
          actions.push(setCoupons(coupons));
        }
        if (Array.isArray(promotionCodes) && promotionCodes.length) {
          actions.push(setPromotionCodes(promotionCodes));
        }

        dispatch(batchActions(actions));
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * Update to be resource based fetch
 * fetchMerchantAccounts
 */
export function fetchMerchantAccounts(
  { resource, resourceUUID, resourceID },
  callback = () => {}
) {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts`;
    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          merchantAccounts: responseData
        });

        const actions = reduceModelActions({
          keys: MERCHANT_ACCOUNT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: MERCHANT_ACCOUNT_ACTIONS_MAP
        });

        dispatch(batchActions(actions));
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchMerchantAccountProfile
 */
export function fetchMerchantAccountProfile(
  { resourceUUID, resource, uuid },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_MERCHANT_ACCOUNT_STORE,
        "get",
        "app",
        "Fetch merchant account profile"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/profile`;
    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const actions = [
          setMerchantAccountValues({ uuid, ...data }),
          ActionHelpers.clearLoader(C.UPDATE_MERCHANT_ACCOUNT_STORE)
        ];

        dispatch(batchActions(actions));
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteMerchantAccount
 */
export function deleteMerchantAccount(
  { resource, resourceUUID, uuid },
  data,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_MERCHANT_ACCOUNT_STORE,
        "delete",
        "merchantAccount",
        "Delete merchant account"
      )
    );

    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}`;
    return axios({
      method: "delete",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const merchantAccounts = Array.isArray(data) ? data : [data];
        const actions = [
          removeMerchantAccounts(merchantAccounts),
          replaceUserMerchantAccounts([]),
          replaceOrganizationMerchantAccounts([]),
          ActionHelpers.clearLoader(C.UPDATE_MERCHANT_ACCOUNT_STORE),
          ActionHelpers.successNote(
            C.UPDATE_MERCHANT_ACCOUNT_STORE,
            `Disconnected Stripe merchant account`
          )
        ];

        dispatch(batchActions(actions));
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * createDeferredMerchantAccount
 */
export function createDeferredMerchantAccount(
  { resource, resourceID },
  callback = () => {}
) {
  return (dispatch) => {
    const REQUEST_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/merchant-accounts/stripe/deferred`;
    return axios({
      method: "post",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const merchantAccounts = Array.isArray(data) ? data : [data];

        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          merchantAccounts
        });

        const actions = reduceModelActions({
          keys: MERCHANT_ACCOUNT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: MERCHANT_ACCOUNT_ACTIONS_MAP
        });

        actions.push(
          ActionHelpers.successNote(
            C.UPDATE_MERCHANT_ACCOUNT_STORE,
            `Created Stripe merchant account - verification now required.`
          )
        );

        dispatch(batchActions(actions));
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * updateMerchantAccountConfig
 */
export function updateMerchantAccountConfig(
  { resourceUUID, resource, merchantAccountUUID, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_MERCHANT_ACCOUNT_STORE,
        "update",
        "merchantAccount",
        "Update merchant account config"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${merchantAccountUUID}/merchant-account-configs/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: merchantAccountConfig }) => {
        const actions = [
          setMerchantAccountConfig({
            uuid: merchantAccountUUID,
            config: merchantAccountConfig
          }),
          ActionHelpers.clearLoader(C.UPDATE_MERCHANT_ACCOUNT_STORE)
        ];
        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_MERCHANT_ACCOUNT_STORE,
                "Updated configuration"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));
        callback(null, merchantAccountConfig);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MERCHANT_ACCOUNT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
