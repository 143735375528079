import { COLORS } from "./styles";
import classnames from "classnames";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import Analytics from "utils/analytics";
import { SET_TOUCH_TIMEOUT } from "./constants/form";

const PRICING_TABLE = "pricingTable";
const PRODUCT_STORE = "productStore";
const FEATURE_PRODUCT_ORDER = "featureProductOrder";
const FEATURE_GROUP_TABLE = "featureGroupTable";
const FEATURE_GROUP_STORE = "featureGroupStore";
const PRODUCT_COLLECTION = "paymentLinkCollection";
const FORM = "FORM";
const OPTIONS = "options";
export const LIST_DESTINATIONS = {
  PRICING_TABLE,
  PRODUCT_STORE,
  FEATURE_PRODUCT_ORDER,
  FEATURE_GROUP_TABLE,
  FEATURE_GROUP_STORE,
  PRODUCT_COLLECTION,
  FORM,
  OPTIONS
};

const FEATURE_LIST = "featureList";
const FEATURE_GROUP = "featureGroup";
export const FEATURE_GROUP_LIST_DESTINATIONS = {
  FEATURE_LIST,
  FEATURE_GROUP
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getDragListStyle = (styles) => ({
  minHeight: 64,
  ...styles
});

export const getDroppableProps = ({ provided, styles }) => {
  return {
    ...provided.droppableProps,
    ref: provided.innerRef,
    style: getDragListStyle(styles)
  };
};

const getDragStyle = (style, snapshot) => {
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`
  };
};

export const getDraggableProps = ({ provided, snapshot }) => {
  return {
    ref: provided.innerRef,
    ...provided.draggableProps,
    ...provided.dragHandleProps,
    // isDragging: snapshot.isDragging && !snapshot.isDropAnimating,
    style: getDragStyle(get(provided, "draggableProps.style", {}), snapshot)
  };
};

export const deconstructSourceAndDestination = ({ source, destination }) => {
  const {
    droppableId: destinationDropId,
    index: destinationIndex
  } = destination;
  const { droppableId: sourceDropId, index: sourceIndex } = source;

  return {
    destinationDropId,
    destinationIndex,
    sourceDropId,
    sourceIndex
  };
};

export const listItemDragProps = (provided, style = {}) => ({
  className: "user-select-n cursor-auto outline-0",
  ref: provided.innerRef,
  ...provided.draggableProps,
  ...provided.dragHandleProps,
  style: {
    ...get(provided, "draggableProps.style", {}),
    ...get(provided, "dragHandleProps.style", {}),
    ...style
  }
});

export const getItemDraggingProps = (props, provided, snapshot) => {
  if (provided && provided.draggableProps && snapshot && snapshot.isDragging) {
    props.style.height = provided.draggableProps.style.height;
    props.style.backgroundColor = COLORS.emphasis;
    props.style.borderColor = COLORS.blue;
    props.style.borderWidth = 1;
  }

  return props;
};

const DRAGGING_OVER_STYLE = {
  borderWidth: "1px",
  borderStyle: "dashed",
  borderRadius: "2px",
  borderColor: COLORS.blue,
  backgroundColor: COLORS.emphasis
};

/**
 * Highlight the list being dragged over
 * @param {Object} params
 * @returns
 */
export const listDragProps = ({
  provided,
  snapshot,
  classes = "ma0 pa0 list w-100 bh--inset"
}) => ({
  ...provided.droppableProps,
  ref: provided.innerRef,
  style: getDragListStyle(snapshot.isDraggingOver ? DRAGGING_OVER_STYLE : {}),
  className: classnames(classes, {
    ba: snapshot.isDraggingOver
  })
});

export const reorderModelsCollection = ({
  setFieldValue,
  setFieldTouched,
  models,
  modelsPath,
  tracking
}) => ({ source, destination }) => {
  const droppedOffList = !destination;
  if (droppedOffList) {
    return;
  }
  tracking && Analytics.trackEventWithData(tracking);
  const {
    droppableId: destinationDropId,
    index: destinationIndex
  } = destination;
  const { droppableId: sourceDropId, index: sourceIndex } = source;

  const cachedModels = cloneDeep(models);

  /**
   * Drop has happened between two lists so an object is being moved
   */
  const changedList = sourceDropId !== destinationDropId;

  if (!changedList) {
    const reorderedModels = reorder(
      cachedModels,
      sourceIndex,
      destinationIndex
    );
    setFieldValue(modelsPath, reorderedModels);
    setFieldTouched(modelsPath, true);
  } else {
    console.error("List transfer not supported");
  }
};

export const removeModel = ({
  values,
  index,
  setValues,
  setFieldTouched,
  modelsPath,
  focus
}) => {
  return () => {
    const models = [...get(values, modelsPath, [])];

    if (index > -1) {
      models.splice(index, 1);
      set(values, modelsPath, models);
    }
    if (Array.isArray(focus)) {
      focus.forEach(({ key, value }) => set(values, key, value));
    }

    setValues(values);
    setTimeout(() => setFieldTouched(modelsPath, true), SET_TOUCH_TIMEOUT);
  };
};

export const addModel = ({
  values,
  setValues,
  setFieldTouched,
  template,
  modelsPath,
  stateKey
}) => {
  return () => {
    const updatedModels = [...get(values, modelsPath, [])];

    updatedModels.push(template);
    const nextIndex = updatedModels.length - 1;
    set(values, modelsPath, updatedModels);
    set(values, stateKey, nextIndex);

    setValues(values);
    setFieldTouched(modelsPath, true);
  };
};
