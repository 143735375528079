import C from "constants/userShortLink";

export function setLoading() {
  return {
    type: C.UPDATE_USER_SHORT_LINK_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserShortLinks = (payload) => {
  return {
    type: C.SET_USER_SHORT_LINKS,
    payload
  };
};

export const replaceUserShortLinks = (payload) => {
  return {
    type: C.REPLACE_USER_SHORT_LINKS,
    payload
  };
};
