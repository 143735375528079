import {
  getCurrencyConfigForLocale,
  formatUnitAmount
} from "components/FormerEditor/common/currency";
import isNumber from "lodash/isNumber";

const DASHBOARD = "dashboard";
const ANALYTICS = "analytics";

export const SHIPPING_RATE_TABS = {
  DASHBOARD,
  ANALYTICS
};

const FIXED_AMOUNT = "fixed_amount";

export const SHIPPING_RATE_TYPE = {
  FIXED_AMOUNT
};

const HOUR = "hour";
const DAY = "day";
const BUSINESS_DAY = "business_day";
const WEEK = "week";
const MONTH = "month";

export const SHIPPING_RATE_DELIVERY_ESTIMATE_UNIT = {
  HOUR,
  DAY,
  BUSINESS_DAY,
  WEEK,
  MONTH
};

export const DELIVERY_ESTIMATE = "delivery_estimate";
export const SHIPPING_RATE_FORM_KEY = {
  DELIVERY_ESTIMATE
};

export const CHECKOUT_SHIPPING_OPTION_MAX = 5;

export const templateShippingRateFixedAmount = ({ currency, amount } = {}) => {
  const ctxAmount = isNumber(amount) ? amount : 0;
  const ctxCurrency =
    currency || getCurrencyConfigForLocale().code.toLowerCase();

  return {
    currency: ctxCurrency,
    formattedPrice:
      ctxAmount > 0
        ? formatUnitAmount({
            amount: ctxAmount,
            currency: ctxCurrency
          })
        : ""
  };
};

export const SHIPPING_OPTION_LIMIT = 5;

export const SHIPPING_OPTION_COPY = {
  INFO: `Add up to ${CHECKOUT_SHIPPING_OPTION_MAX} shipping rate options for your customers to choose
  from during checkout.`
};
