import QS from "qs";
import compact from "lodash/compact";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import trimStart from "lodash/trimStart";
import pick from "lodash/pick";
import cloneDeep from "lodash/cloneDeep";
import {
  BUILDER_LINKS_RE,
  HIDE_PLATFORM_SCRIPT_PATHS,
  HIDE_PLATFORM_SECURITY_SCRIPT_PATHS,
  SETUP_LINKS_RE
} from "./paths";
import { getSubdomainRoot } from "./subdomain";
import { getConfig } from "./env";

const HTTPS_RE = new RegExp(/^(https:\/\/|https:\/|)/gi);
const HTTP_RE = new RegExp(/^http:\/\//gi);
const HASH_START_RE = new RegExp(/^#/);
const HTTPS = "https://";
const HTTP = "http://";
export const QS_RE = /[a-z0-9=_\-&.;/?:@+$,*#%^]/gi;
export const REDIRECT_KEYS = ["success_url", "cancel_url", "return_url"];

export const createURL = (state) => `?${QS.stringify(state)}`;

export const defaultHttps = (href) => {
  if (href && !HASH_START_RE.test(href)) {
    const isInsecureTarget = compact(href.match(HTTP_RE));
    const prefix = isInsecureTarget.length ? HTTP : HTTPS;

    const secureMatches = compact(href.match(HTTPS_RE));

    let suffix;

    if (secureMatches.length) {
      const memoCopy = href;

      suffix = secureMatches.reduce((memo, match) => {
        memo = memo.replace(match, "");
        return memo;
      }, memoCopy);
    } else {
      const parts = href.split(":/");
      const sliceIndex = parts.length > 1 ? 1 : 0;
      suffix = parts.slice(sliceIndex).join("");
    }

    return `${prefix}${trimStart(suffix, "/")}`;
  } else {
    return "";
  }
};

export const HREF_TYPE = {
  LINK: "link",
  MAILTO: "mailto",
  TEL: "tel"
};

export const setHrefWithType = ({ href, hrefType }) => {
  return hrefType && hrefType !== HREF_TYPE.LINK
    ? `${hrefType}:${href}`
    : defaultHttps(href);
};

export const omitQueryStringParams = (url, keys) => {
  const urlParts = url.split("?");
  if (urlParts[1]) {
    const parts = QS.parse(urlParts[1]);
    const remainder = omit(parts, keys);

    return isEmpty(remainder)
      ? urlParts[0]
      : `${urlParts[0]}?${QS.stringify(remainder)}`;
  } else {
    return url;
  }
};

export const parseQueryStringParams = (url, keys = []) => {
  const urlParts = url.split("?");
  if (urlParts[1]) {
    const params = QS.parse(urlParts[1]);
    return keys && keys.length ? pick(params, keys) : params;
  } else {
    return {};
  }
};

/**
 * Dont load platform scripts like crisp facebook pixel etc. on link show page
 */
export const hidePlatformScriptsOnRoute = (route) => {
  if (BUILDER_LINKS_RE.test(route)) {
    return false;
  }

  let hide = false;
  for (let index = 0; index < HIDE_PLATFORM_SCRIPT_PATHS.length; index++) {
    hide = HIDE_PLATFORM_SCRIPT_PATHS[index].test(route);
    if (hide) {
      break;
    }
  }

  return hide;
};

/**
 * Dont load recaptcha on link show and test link show page
 * * except do allow on links setup page
 */
export const hidePlatformSecurityScriptsOnRoute = (route) => {
  /**
   * Allow on setup - i.e. dont hide
   */
  if (SETUP_LINKS_RE.test(route) || BUILDER_LINKS_RE.test(route)) {
    return false;
  }

  /**
   * Else check other patterns
   */
  let hide = false;
  for (
    let index = 0;
    index < HIDE_PLATFORM_SECURITY_SCRIPT_PATHS.length;
    index++
  ) {
    hide = HIDE_PLATFORM_SECURITY_SCRIPT_PATHS[index].test(route);
    if (hide) {
      break;
    }
  }

  return hide;
};

export const stripeInvalidQS = (url = "") => {
  const parts = compact(url.split("?"));
  const qsPart = parts[1];
  if (qsPart) {
    const matches = parts[1].match(QS_RE);
    return `${parts[0]}?${matches.join("")}`;
  } else {
    return parts[0];
  }
};

export const validateUrlQS = (url = "") => {
  let result;
  if (url && /\?/.test(url)) {
    const qsParam = compact(url.split("?"))[1];

    if (qsParam) {
      const matches = qsParam.match(QS_RE);
      if (matches && matches.length < qsParam.length) {
        result = "Query string has invalid characters";
      }
    }
  }
  return result;
};

export const validateQSKeys = ({
  validations,
  values,
  keys = REDIRECT_KEYS
}) => {
  const result = cloneDeep(validations);

  keys.forEach((qsKey) => {
    const value = values[qsKey];
    if (!result[qsKey] && value) {
      const validationError = validateUrlQS(value);
      if (validationError) {
        result[qsKey] = validationError;
      }
    }
  });
  return result;
};

export const getSubdomainOrAliasRoot = ({ subdomain, alias }) => {
  const root = getConfig("WEB_CLIENT_ROOT");
  return subdomain ? getSubdomainRoot(subdomain) : `${root}/${alias}`;
};
