import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import { useCtxReferences } from "utils/selectors";
import { interpolateRouteWithParams } from "utils/route";
import PATHS from "utils/paths";
import { useModelQueryId } from ".";
import { SECTIONS } from "components/FormerEditor/utils";
import { APPLICATION, PAGE, SECTION } from "utils/constants/models";
import { useFormikContext } from "formik";
import ENTITIES from "components/FormerEditor/common/constants/entity";
import get from "lodash/get";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import compact from "lodash/compact";
import { STATE_KEYS } from "utils/constants/state";
import {
  getLiveManifestResourceUrl,
  getManifestPublishErrors
} from "utils/manifest";
import { EDITOR_MODE } from "utils/constants/editor";
import {
  MANIFEST_CONTEXT_TYPE,
  MANIFEST_LOGIC_TYPE,
  MANIFEST_LOGIC_TYPE_MAP
} from "utils/constants/manifest";
import { usePageFactoryContext } from "utils/context";
import { getManifestLiveVariation } from "utils/mapStateToProps";
import { useAppUrlVariations } from "./application";
import { getFieldPath, reduceFlatFieldGroupSelections } from "utils/form";
import {
  getAllCombinationPrimaries,
  getConfigLogicCheckoutSelectionsResult
} from "utils/logic";
import { useFieldValidationErrors } from "./checkout";
import { CONNECT_EMAIL_FIELD } from "utils/constants/connect";
import { composeCheckoutConfigsToShortLink } from "utils/checkout/merge";
import { reduceIdMap } from "utils/data";
import { COMBINATION_FIELDS, FIELD_TYPES } from "utils/constants/form";
import { setEditorFocus } from "utils/builder";
import { getFormCheckoutSettingsFocus } from "./focus";
import {
  CHECKOUT_PRODUCT_SETTINGS_INTENT,
  CHECKOUT_SETTINGS_INTENT
} from "utils/constants/checkout";
import { reduceFieldSelectionRequirements } from "utils/combination";
import { PAYMENT_MODE } from "utils/constants/payment";

/**
 * TODO: when used on page the path needs to be just 'entities' not 'manifest.data.entities'
 */
export const getFormEntity = (values) => {
  const manifestEntities = get(values, STATE_KEYS.MANIFEST.ENTITIES) || [];
  return manifestEntities.find(({ uid }) => uid === ENTITIES.FORM);
};

export const getPricingTableEntity = (values) => {
  const manifestEntities = get(values, STATE_KEYS.MANIFEST.ENTITIES) || [];
  return manifestEntities.find(({ uid }) => uid === ENTITIES.PRICING_TABLE);
};

export const getProductCollectionEntity = (values) => {
  const manifestEntities = get(values, STATE_KEYS.MANIFEST.ENTITIES) || [];
  return manifestEntities.find(
    ({ uid }) => uid === ENTITIES.PRODUCT_COLLECTION
  );
};

export const getProductCollectionCheckouts = (values, uuid) => {
  const checkoutsPath = `${STATE_KEYS.MANIFEST.CONTENT}.${uuid}.${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_PRODUCTS_CHECKOUTS}`;
  return get(values, checkoutsPath) || [];
};

export const getProductCollectionProducts = (values, uuid) => {
  const productsPath = `${STATE_KEYS.MANIFEST.CONTENT}.${uuid}.${STATE_KEYS.PRODUCT_COLLECTION.PRODUCTS}`;
  return get(values, productsPath) || [];
};

export const getEmptyProductCollectionCheckoutsIndexes = (values, uuid) => {
  const products = getProductCollectionProducts(values, uuid);
  const checkouts = getProductCollectionCheckouts(values, uuid);

  return products.reduce((memo, product, prodIx) => {
    if (isEmpty(checkouts[product.uuid])) {
      memo.push(prodIx);
    }

    return memo;
  }, []);
};

export const getEntity = (values, uid) => {
  const manifestEntities = get(values, STATE_KEYS.MANIFEST.ENTITIES) || [];
  return manifestEntities.find((entity) => entity.uid === uid);
};

export const getFormFieldGroups = ({ values, formEntityUUID }) => {
  const fieldGroupsPath = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntityUUID}.${STATE_KEYS.FORM.FIELD_GROUPS}`;
  return get(values, fieldGroupsPath) || [];
};

export const getFormComboFields = ({ values, formEntityUUID }) => {
  const fieldGroups = getFormFieldGroups({ values, formEntityUUID });
  const result = [];

  for (let groupIx = 0; groupIx < fieldGroups.length; groupIx++) {
    const group = fieldGroups[groupIx];
    for (let fieldIx = 0; fieldIx < group.length; fieldIx++) {
      const field = group[fieldIx];

      if (field && COMBINATION_FIELDS[field.uid]) {
        result.push(field);
      }
    }
  }

  return result;
};

export const useFormFieldGroups = () => {
  const { values } = useFormikContext();
  const formEntity = getFormEntity(values);
  const formEntityUUID = formEntity && formEntity.uuid;

  return getFormFieldGroups({ values, formEntityUUID });
};

export const getFormCombinations = ({ values, formEntityUUID }) => {
  const configCombinationsPath = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntityUUID}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`;
  return get(values, configCombinationsPath) || [];
};

export const useFormCombinations = () => {
  const { values } = useFormikContext();
  const formEntity = getFormEntity(values);
  const formEntityUUID = formEntity && formEntity.uuid;

  return getFormCombinations({ values, formEntityUUID });
};

export const getFormLogicFields = ({ values, formEntityUUID }) => {
  const logicFieldsPath = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntityUUID}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_FIELDS}`;
  return get(values, logicFieldsPath) || [];
};

export const useFormLogicFields = () => {
  const { values } = useFormikContext();
  const formEntity = getFormEntity(values);
  const formEntityUUID = formEntity && formEntity.uuid;

  return getFormLogicFields({ values, formEntityUUID });
};

export const useFormCollections = () => {
  const { values } = useFormikContext();
  const formEntity = getFormEntity(values);
  const formEntityUUID = formEntity && formEntity.uuid;

  return {
    combinations: getFormCombinations({ values, formEntityUUID }),
    fieldGroups: getFormFieldGroups({ values, formEntityUUID }),
    selectedFields: getFormLogicFields({ values, formEntityUUID })
  };
};

export const getFormCommonCheckout = ({ values, formEntityUUID }) => {
  const path = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntityUUID}.${STATE_KEYS.FORM.CONFIG_CHECKOUT_COMMON}`;
  return get(values, path);
};

export const getFormConstantCheckout = ({ values, formEntityUUID }) => {
  const path = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntityUUID}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_CONSTANT}`;
  return get(values, path);
};

export const useFormMergedShortLinkConfig = (checkout) => {
  const { values } = useFormikContext();
  const formEntity = getFormEntity(values);
  const formEntityUUID = formEntity && formEntity.uuid;

  const common = getFormCommonCheckout({ values, formEntityUUID });
  const constant = getFormConstantCheckout({ values, formEntityUUID });

  return composeCheckoutConfigsToShortLink({
    selectedCheckout: checkout,
    commonConfig: common,
    constantCheckout: constant
  });
};

export const redirectDefaultManifestSection = () => {
  const { application } = useCtxReferences();
  const { replace } = useRouter();

  const pageUUID = useModelQueryId(PAGE);
  const sectionUUID = useModelQueryId(SECTION);

  useEffect(() => {
    if (application && !sectionUUID) {
      const destination = `${interpolateRouteWithParams(
        PATHS.APPLICATIONS_PAGES_SHOW_SECTION,
        {
          application,
          page: {
            uuid: pageUUID
          },
          section: {
            uuid: SECTIONS.PAGE_BUILDER
          }
        }
      )}`;

      replace(destination);
    }
  }, [application, sectionUUID]);
};

export const useManifestSection = (section) => {
  const applicationUUID = useModelQueryId(APPLICATION);
  const manifestUUID = useModelQueryId(PAGE);

  return interpolateRouteWithParams(PATHS.APPLICATIONS_PAGES_SHOW_SECTION, {
    application: {
      uuid: applicationUUID
    },
    page: {
      uuid: manifestUUID
    },
    section: {
      uuid: section
    }
  });
};

export const useIsManfestPath = () => {
  const { pathname } = useRouter();

  return /\/pages\//.test(pathname);
};

export const getManifestFormFields = (values) => {
  let result = [];
  const formEntity = getFormEntity(values);
  if (formEntity) {
    const fieldGroupsKey = `${STATE_KEYS.MANIFEST.CONTENT}.${formEntity.uuid}.${STATE_KEYS.FORM.FIELD_GROUPS}`;
    const fieldGroups = get(values, fieldGroupsKey) || [];

    result = fieldGroups.reduce((memo, group) => {
      if (group.fields && group.fields.length) {
        memo = memo.concat(group.fields);
      }
      return memo;
    }, []);
  }

  return result;
};

export const useManifestFormFields = () => {
  const { values } = useFormikContext();
  return getManifestFormFields(values);
};

export const useDisableManifestPublish = () => {
  const { values, errors, isSubmitting } = useFormikContext();
  const manifestPublishErrors = getManifestPublishErrors({ values, errors });
  const manifestEntities = get(values, STATE_KEYS.MANIFEST.ENTITIES) || [];
  const hasEntities =
    Array.isArray(manifestEntities) && manifestEntities.length > 0;

  return !isEmpty(manifestPublishErrors) || !hasEntities || isSubmitting;
};

export const useManifestFormEntity = () => {
  const { values } = useFormikContext();
  return getFormEntity(values);
};

export const useEditorMode = () => {
  const { values } = useFormikContext();
  return get(values, STATE_KEYS.EDITOR.MODE);
};

export const useIsManifestEditMode = () => {
  const { values } = useFormikContext();
  const mode = get(values, STATE_KEYS.EDITOR.MODE);
  return mode === EDITOR_MODE.EDIT;
};

export const useConstantCheckout = (name) => {
  const { values } = useFormikContext();
  const checkoutConstantPath = `${name}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_CONSTANT}`;
  const checkoutConstantValue = get(values, checkoutConstantPath);
  const constantPrimaries = get(checkoutConstantValue, "primaries") || [];
  const hasConstantCheckoutPrimaries = !isEmpty(constantPrimaries);

  return {
    hasPrimaries: hasConstantCheckoutPrimaries,
    primaries: constantPrimaries,
    checkout: checkoutConstantValue
  };
};

export const useSetManifestLogicType = ({ name }) => {
  const { values, touched, setFieldValue } = useFormikContext();
  const typePath = `${name}.${STATE_KEYS.FORM.CONFIG_LOGIC_TYPE}`;
  const type = get(values, typePath);
  const typeTouched = get(touched, typePath);
  const checkoutRequired = type === MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED;
  const { hasPrimaries: hasConstantCheckoutPrimaries } = useConstantCheckout(
    name
  );
  /**
   * If type is unconditional and theres no constant checkout primaries
   * Then make type conditional
   * i.e. require that a product in the cart is derived from a form selection
   */
  useEffect(() => {
    const setCheckoutRequired =
      !checkoutRequired && !hasConstantCheckoutPrimaries;

    if (setCheckoutRequired) {
      setFieldValue(typePath, MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED);
    }
  }, [hasConstantCheckoutPrimaries, checkoutRequired]);

  return {
    hasConstantCheckoutPrimaries,
    checkoutRequired,
    type,
    typeTouched
  };
};

export const usePageElementNameRoot = (name) => {
  const { stateKey } = usePageFactoryContext();
  const contentName = `content.${name}`;
  return `${stateKey}.${contentName}`;
};

export const useEditorManifestLiveUrl = () => {
  const { values } = useFormikContext();
  const { application } = useCtxReferences();
  const urlVariations = useAppUrlVariations();

  const manifest = values.manifest;
  const manifestLiveVariation = getManifestLiveVariation({
    id: manifest && manifest.id,
    application
  });

  return getLiveManifestResourceUrl({
    application,
    urlVariations,
    liveVariation: manifestLiveVariation
  });
};

const getIsConnectEmailInputField = (field) => {
  let result = false;
  if (
    field &&
    field.uid === ENTITIES.INPUT &&
    field.connect &&
    field.connect.enabled &&
    field.connect.actions
  ) {
    for (const action in field.connect.actions) {
      if (CONNECT_EMAIL_FIELD[action]) {
        result = true;
        break;
      }
    }
  }
  return result;
};

const getFormConnectCustomerParams = ({ fieldGroups, values }) => {
  let result = null;
  const customerEligibleFields = [];

  if (Array.isArray(fieldGroups)) {
    for (let groupIx = 0; groupIx < fieldGroups.length; groupIx++) {
      const group = fieldGroups[groupIx];

      if (group && Array.isArray(group.fields) && group.fields.length) {
        for (let fieldIx = 0; fieldIx < group.fields.length; fieldIx++) {
          const field = group.fields[fieldIx];
          if (getIsConnectEmailInputField(field)) {
            customerEligibleFields.push(field);
          }
        }
      }
    }
  }

  if (customerEligibleFields.length > 0) {
    const field = customerEligibleFields[0];
    const email = values[field.uuid];

    result = {
      customer_email: email || null
    };
  }

  return result;
};

export const useManifestUniqPrimaries = () => {
  const { values } = useFormikContext();

  const context = values.manifest.context;
  const isFormContext = context === MANIFEST_CONTEXT_TYPE.FORM;
  const isPricingContext = context === MANIFEST_CONTEXT_TYPE.PRICING;

  let primaryEntity;
  if (isFormContext) {
    primaryEntity = getFormEntity(values);
  } else if (isPricingContext) {
    primaryEntity = getPricingTableEntity(values);
  }

  const contentRoot = `content.${primaryEntity.uuid}`;
  const contentPath = getFieldPath(STATE_KEYS.MANIFEST.DATA, contentRoot);

  const { primaries: constantPrimaries } = useConstantCheckout(contentPath);

  const configPath = `${contentPath}.config`;
  let result = [];
  if (isFormContext) {
    const configValues = get(values, configPath);
    const comboPrimaries = getAllCombinationPrimaries(configValues);

    result = [...constantPrimaries, ...comboPrimaries];
  }

  return uniqBy(result, "product");
};

/**
 * ======================
 * Checkout Golden Rules
 * ======================
 * - There must be something to buy (unless mode is setup)
 * - Minimum of one product must be present constant || selection checkout value must be present
 * ======================
 * Content TOC
 * Fields which are part of the logic should have their values checked against the combinations on load
 * ======================
 * fieldsValues - fields which are part of the checkout derivation
 * allCurrentFieldValues - all active field values
 * selections - subset of current fields based on checkout fields
 * checkoutSelectionsResult - matching checkout values for selections
 * typeValue - logic type for form
 * comboValues - combinations for form
 * checkoutConstantValue - the default checkout value for the form
 */
export const useFormCheckout = ({ name, fieldGroups }) => {
  const { stateKey } = usePageFactoryContext();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const validationErrors = useFieldValidationErrors({
    name
  });

  const hasValidationErrors = !isEmpty(validationErrors);
  let disabled = hasValidationErrors;
  const contentRoot = `content.${name}`;
  const contentPath = getFieldPath(stateKey, contentRoot);
  const configPath = `${contentPath}.config`;

  const fieldsPath = `${contentPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_FIELDS}`;
  const fieldsValues = get(values, fieldsPath);

  const combosPath = `${contentPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_COMBOS}`;
  const comboValues = get(values, combosPath, []);
  const hasComboConfig = Boolean(comboValues && comboValues.length);

  const comboFields = getFormComboFields({ values, formEntityUUID: name });

  const checkoutCommonPath = `${contentPath}.${STATE_KEYS.FORM.CONFIG_CHECKOUT_COMMON}`;
  const checkoutCommon = get(values, checkoutCommonPath);
  const isSetupMode =
    get({ data: checkoutCommon }, STATE_KEYS.SHORT_LINK.MODE) ===
    PAYMENT_MODE.SETUP;

  /**
   * ===========
   * Checkout logic checks
   * ===========
   * Types
   * - checkout_required
   * -- there must be at least one checkout whether from constant config / form selection
   * - CONSTANT_AND_SELECTED_CHECKOUT_REQUIRED
   * -- both constant and selection checkout values must be set
   * - checkout_not_required
   * -- no checkout required
   */
  const {
    hasPrimaries: hasConstantCheckoutPrimaries,
    primaries: constantPrimaries,
    checkout: checkoutConstantValue
  } = useConstantCheckout(contentPath);

  const hasFields = fieldGroups
    ? Boolean(
        fieldGroups.find(
          (group) => group && Array.isArray(group.fields) && group.fields.length
        )
      )
    : false;
  const allCurrentFieldValues =
    fieldGroups && reduceFlatFieldGroupSelections(fieldGroups);
  const selections = pick(allCurrentFieldValues, fieldsValues);

  const customer = getFormConnectCustomerParams({
    fieldGroups,
    values: allCurrentFieldValues
  });

  const configValues = get(values, configPath);
  const allComboPrimaries = getAllCombinationPrimaries(configValues);
  const {
    result: checkoutSelectionsResult,
    hasPrimaries: hasLogicCheckoutPrimaries,
    primaries: logicCheckoutPrimaries
  } = getConfigLogicCheckoutSelectionsResult(selections, configValues);

  const hasCheckoutSelectionsResult = !isEmpty(checkoutSelectionsResult);

  const hasCheckout =
    hasCheckoutSelectionsResult || hasConstantCheckoutPrimaries;
  const hasConstantAndSelectedCheckout =
    hasCheckoutSelectionsResult && hasConstantCheckoutPrimaries;
  const typePath = `${contentPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_TYPE}`;
  const typeValue = get(values, typePath);
  const logicType =
    MANIFEST_LOGIC_TYPE_MAP[typeValue] || MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED;

  /**
   * Ensure payment mode logic type
   * - setup mode - checkout_not_required
   * - payment | subscription - checkout_required as default
   */
  useEffect(() => {
    const checkoutRequired =
      logicType === MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED;
    const checkoutNotRequired =
      logicType === MANIFEST_LOGIC_TYPE.CHECKOUT_NOT_REQUIRED;
    const setNotRequired = isSetupMode && checkoutRequired;
    const setRequired = !isSetupMode && checkoutNotRequired;

    if (setNotRequired) {
      setTimeout(() => {
        setFieldValue(typePath, MANIFEST_LOGIC_TYPE.CHECKOUT_NOT_REQUIRED);
        setFieldTouched(typePath, true);
      }, 0);
    } else if (setRequired) {
      /** Set default to be checkout_required */
      setTimeout(() => {
        setFieldValue(typePath, MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED);
        setFieldTouched(typePath, true);
      }, 0);
    }
  }, [isSetupMode, logicType]);

  if (!disabled) {
    if (logicType === MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED && !hasCheckout) {
      disabled = true;
    } else if (
      logicType ===
        MANIFEST_LOGIC_TYPE.CONSTANT_AND_SELECTED_CHECKOUT_REQUIRED &&
      !hasConstantAndSelectedCheckout
    ) {
      disabled = true;
    } else if (logicType === MANIFEST_LOGIC_TYPE.CHECKOUT_NOT_REQUIRED) {
      disabled = false;
    }
  }

  if (process.env.NODE_ENV === "development") {
    // console.table({
    //   hasCheckout,
    //   checkoutRequired,
    //   hasConstantCheckoutPrimaries,
    //   hasCheckoutSelectionsResult
    // });
  }

  /**
   * When no primaries present we prompt to set default checkout value
   */
  const hasAnyComboPrimaries = allComboPrimaries.length > 0;
  const hasAnyPrimaries = hasAnyComboPrimaries || hasConstantCheckoutPrimaries;

  return {
    disabled,
    customer,
    /**
     * ===========
     * Constant checkout
     * ===========
     */
    hasConstantCheckoutPrimaries,
    constantPrimaries,
    checkoutConstantValue,
    hasFields,
    hasLogicCheckoutPrimaries,
    hasAnyPrimaries,
    logicCheckoutPrimaries,
    /**
     * ===========
     * Common checkout
     * ===========
     */
    isSetupMode,
    /**
     * ===========
     * Selections
     * ===========
     * formData - All form data
     * selections - Form data for selection fields only
     */
    formData: allCurrentFieldValues,
    selections,
    hasCheckoutSelectionsResult,
    checkoutSelectionsResult,
    /**
     * Collections
     */
    comboFields,
    /**
     * References
     */
    hasComboConfig,
    hasCheckout,
    hasValidationErrors,
    validationErrors
  };
};

/**
 * Find any combination for fields within this group
 */
export const useFirstComboFromFields = (fields) => {
  const combinations = useFormCombinations();
  const fieldUUIDMap = reduceIdMap(fields, "uuid");

  return useMemo(() => {
    let result;
    if (Array.isArray(combinations) && Array.isArray(fields)) {
      result = combinations.find((combo) => {
        let match = false;
        if (combo && combo.selections) {
          for (const fieldUUID in combo.selections) {
            match = fieldUUIDMap[fieldUUID];
            if (match) {
              break;
            }
          }
        }
        return match;
      });
    }
    return result;
  }, [combinations.length, fields.length]);
};

export const useCurrentFormFieldSelection = () => {
  const formFields = useManifestFormFields();
  const fieldMap = reduceIdMap(formFields, "uuid");
  const logicFields = useFormLogicFields();
  return logicFields.reduce((memo, fieldUUID) => {
    const match = fieldMap[fieldUUID];
    if (match) {
      memo[fieldUUID] = match.value;
    }
    return memo;
  }, {});
};

export const useFormFieldLogicConfig = ({ name }) => {
  const { values, setValues } = useFormikContext();

  const combinations = useFormCombinations();
  const field = get(values, name);
  const uuid = field.uuid;

  /**
   * Object is keyed by option value
   * e.g. true | false | one | two etc.
   */
  const valueResultsMap = useMemo(
    () => reduceFieldSelectionRequirements({ combinations, uuid }),
    [combinations.length, uuid]
  );
  let needsResult;
  if (!isEmpty(valueResultsMap)) {
    for (const key in valueResultsMap) {
      if (
        valueResultsMap[key] &&
        Array.isArray(valueResultsMap[key].needsResultIndexes) &&
        valueResultsMap[key].needsResultIndexes.length
      ) {
        needsResult = valueResultsMap[key];
        break;
      }
    }
  }

  const formEntity = getFormEntity(values);
  const formEntityUUID = formEntity && formEntity.uuid;
  const logicFields = getFormLogicFields({ values, formEntityUUID });
  const active = logicFields.indexOf(field.uuid) > -1;
  let disabled = false;
  let suffix = null;
  if (
    field.uid === FIELD_TYPES.SELECT &&
    (!Array.isArray(field.options) || !field.options.length)
  ) {
    disabled = true;
    suffix = "Minimum of one option required.";
  }

  let message;
  if (!active) {
    message = "Field not included in combination logic.";
  } else {
    message = "Field included in combination logic.";
  }

  return {
    active,
    disabled,
    message: compact([message, suffix]).join(" "),
    isCombo: COMBINATION_FIELDS[field.uid],
    needsResult,
    setFocus: setEditorFocus({
      values,
      setValues,
      focus: getFormCheckoutSettingsFocus({
        uuid: formEntityUUID,
        menuSubIntent: CHECKOUT_SETTINGS_INTENT.SET_PRODUCTS,
        menuThirdIntent: active
          ? CHECKOUT_PRODUCT_SETTINGS_INTENT.SET_COMBINATIONS
          : CHECKOUT_PRODUCT_SETTINGS_INTENT.SET_FIELDS
      })
    })
  };
};
