import startCase from "lodash/startCase";
// Requires user input before processing can continue.
const REQUIRES_INPUT = "requires_input";
// The session has been submitted and is being processed. Most verification checks take a few minutes to process.
const PROCESSING = "processing";
// Processing of all the verification checks are complete and successfully verified.
const VERIFIED = "verified";
const CANCELED = "canceled";
const REDACTED = "redacted";

export const VERIFICATION_STATUS = {
  REQUIRES_INPUT,
  PROCESSING,
  VERIFIED,
  CANCELED
};

export const REDACTION_STATUS = {
  REDACTED,
  PROCESSING
};

const USER_ACTION = "user_action";
export const VERIFY_IDENTITY_ERROR_TYPE = {
  USER_ACTION
};

const SESSION_CANCELLED = "session_cancelled";
const CONSENT_DECLINED = "consent_declined";
export const VERIFY_IDENTITY_ERROR_CODE = {
  SESSION_CANCELLED,
  CONSENT_DECLINED
};

const DOCUMENT = "document";
const ID_NUMBER = "id_number";

export const IDENTITY_VERIFICATION_TYPE = {
  DOCUMENT,
  ID_NUMBER
};

const DRIVING_LICENSE = "driving_license";
const PASSPORT = "passport";
const ID_CARD = "id_card";

export const IDENTITY_DOCUMENT_TYPE = {
  DRIVING_LICENSE,
  PASSPORT,
  ID_CARD
};

export const IDENTITY_LABEL_MAP = {
  [DRIVING_LICENSE]: startCase(DRIVING_LICENSE),
  [PASSPORT]: startCase(PASSPORT),
  [ID_CARD]: "ID Card",
  [DOCUMENT]: startCase(DOCUMENT),
  [ID_NUMBER]: "ID Number"
};

export const IDENTITY_DOCUMENT_KEYS = [
  "type",
  "status",
  "first_name",
  "last_name",
  "issuing_country"
];

export const IDENTITY_ID_NUMBER_KEYS = [
  "id_number_type",
  "status",
  "first_name",
  "last_name"
];
