import C from "constants/featureGroup";
import ActionHelpers from "./helpers";
import axios from "axios";
import async from "async";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import { reduceModelActions } from "utils/action";
import { replaceFeatures } from "./feature";
import { updateEntitlementConfigRequest } from "./entitlementConfig";
import { replaceUserFeatures } from "./userFeature";
import { replaceUserFeatureGroups } from "./userFeatureGroup";
import { replaceOrganizationFeatures } from "./organizationFeature";
import { replaceOrganizationFeatureGroups } from "./organizationFeatureGroup";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_FEATURE_GROUP_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setFeatureGroups = (payload) => {
  return {
    type: C.SET_FEATURE_GROUPS,
    payload
  };
};

export const replaceFeatureGroups = (payload) => {
  return {
    type: C.REPLACE_FEATURE_GROUPS,
    payload
  };
};

export function resetFeatureGroupNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_FEATURE_GROUP_STORE,
      payload
    });
  };
}

export function showFeatureGroupNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_FEATURE_GROUP_STORE,
        message || `Success!`
      )
    );
  };
}

export const FEATURE_MODELS = ["featureGroups", "features"];

export const FEATURE_ACTIONS_MAP = {
  replaceFeatures,
  replaceFeatureGroups,
  replaceUserFeatures,
  replaceUserFeatureGroups,
  replaceOrganizationFeatures,
  replaceOrganizationFeatureGroups
};

export const bulkUpdateFeatureGroupsRequest = (
  { resourceID, resourceUUID, resource, data, dispatch },
  callback = () => {}
) => {
  const REQUEST_URL = `${apiResourcePath({
    resource,
    resourceUUID
  })}/feature-groups/bulk`;

  return axios({
    method: "post",
    url: REQUEST_URL,
    data,
    headers: authBearerToken()
  })
    .then(({ data: responseData }) => {
      const preparedResponse = prepareModelAssociations({
        resource,
        resourceID,
        featureGroups: responseData.featureGroups,
        features: responseData.features
      });

      const actions = reduceModelActions({
        keys: FEATURE_MODELS,
        action: ACTION_TYPE.REPLACE,
        resource,
        data: preparedResponse,
        actionsMap: FEATURE_ACTIONS_MAP
      });

      dispatch(batchActions(actions));

      callback(null, responseData);
    })
    .catch((err) => {
      dispatch(
        batchActions(
          ActionHelpers.errorAndClear(
            C.UPDATE_FEATURE_GROUP_STORE,
            err,
            false,
            dispatch
          )
        )
      );
      callback(err);
    });
};

/**
 * bulkUpdateFeatureGroups
 */
export const bulkUpdateFeatureGroups = (
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) => {
  return (dispatch) => {
    return bulkUpdateFeatureGroupsRequest(
      {
        resourceID,
        resourceUUID,
        resource,
        data,
        dispatch
      },
      (err, data) => {
        if (err) {
          callback(err, null);
        } else {
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_FEATURE_GROUP_STORE,
                "Features groups updated"
              )
            );
          }, 250);
          callback(null, data);
        }
      }
    );
  };
};

/**
 * bulkUpdateFeatureGroupsEntitlements
 */
export const bulkUpdateFeatureGroupsEntitlements = (
  { resourceID, resourceUUID, resource, featureGroups, entitlementConfig },
  callback = () => {}
) => {
  return (dispatch) => {
    const requestInput = {};
    const common = {
      resourceID,
      resourceUUID,
      resource,
      dispatch
    };

    if (featureGroups) {
      requestInput.featureGroups = (featureGroupCb) => {
        bulkUpdateFeatureGroupsRequest(
          {
            ...common,
            data: featureGroups
          },
          featureGroupCb
        );
      };
    }
    if (entitlementConfig) {
      requestInput.entitlementConfig = (entitlementCb) => {
        updateEntitlementConfigRequest(
          {
            ...common,
            data: entitlementConfig,
            uuid: entitlementConfig.uuid
          },
          entitlementCb
        );
      };
    }

    async.parallel(requestInput, (err, results) => {
      if (err) {
        callback(err, null);
      } else {
        setTimeout(() => {
          dispatch(
            ActionHelpers.successNote(C.UPDATE_FEATURE_GROUP_STORE, "Updated")
          );
        }, 250);
        callback(null, results);
      }
    });
  };
};

/**
 * fetchFeatureGroupFeatures
 */
export const fetchFeatureGroupFeatures = (
  { resourceID, resourceUUID, resource },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/feature-groups/features`;
    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          featureGroups: responseData.featureGroups,
          features: responseData.features
        });

        const actions = reduceModelActions({
          keys: FEATURE_MODELS,
          action: ACTION_TYPE.REPLACE,
          resource,
          data: preparedResponse,
          actionsMap: FEATURE_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_FEATURE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
