import keymirror from "keymirror";

export default keymirror({
  UPDATE_USER: null,
  UPDATE_USER_EDUCATION: null,
  UPDATE_USER_STORE: null,
  REMOVE_USER_MANAGER_USERS: null,
  SET_USER_MANAGER_USERS: null,
  SET_USER_API_KEYS: null
});
