// https://help.crisp.chat/en/article/how-to-use-dollarcrisp-javascript-sdk-10ud15y/

export const WHAT_INTEGRATION = "What integration would you like?";
export const HELP_DISABLED_ACCOUNT =
  "Has your account been incorrectly disabled? Let us know why.";
export const HELP_SUSPENDED_SUBSCRIPTION =
  "Has your subscription been suspended incorrectly? Let us know why.";
export const INTEGRATION_REQUEST_FIELD = {
  id: "integration-request",
  text: "What integration would you like?",
  explain: "Enter an integration name..."
};
export const ACCOUNT_HELP_MESSAGE = "Need help with your account?";

const DEFAULT_HELP_MESSAGE = "What can we help with?";

export const crispComposerWithMessage = (message = DEFAULT_HELP_MESSAGE) => {
  if (typeof window !== "undefined" && window.$crisp) {
    window.$crisp.push(["do", "message:show", ["text", message]]);
  }
};

export const crispField = (field) => {
  if (typeof window !== "undefined" && window.$crisp) {
    window.$crisp.push(["do", "message:show", ["field", field]]);
  }
};

export const crispHide = () => {
  if (typeof window !== "undefined" && window.$crisp) {
    window.$crisp.push(["do", "chat:hide"]);
  }
};

export const crispShow = () => {
  if (typeof window !== "undefined" && window.$crisp) {
    window.$crisp.push(["do", "chat:show"]);
  }
};
