import C from "constants/license";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import ActionHelpers from "./helpers";
import { setOrganizationLicenses } from "./organization";
import { authBearerToken } from "utils/auth";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_LICENSE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setLicenses = (payload) => {
  return {
    type: C.SET_LICENSES,
    payload
  };
};

export const replaceLicenses = (payload) => {
  return {
    type: C.REPLACE_LICENSES,
    payload
  };
};

/** resetLicenseNotes
 */
export function resetLicenseNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_LICENSE_STORE,
      payload
    });
  };
}

export function showLicenseNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_LICENSE_STORE, message || `Success!`)
    );
  };
}

/**
 * reassignOrgLicenses
 */
export const reassignOrgLicenses = (
  { resourceUUID, resource, data: licenseData },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/organization-licenses/reassign`;
    return axios({
      method: "put",
      url: REQUEST_URL,
      data: licenseData,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const licenses = Array.isArray(data) ? data : [data];

        dispatch(
          batchActions([
            setOrganizationLicenses({
              uuid: resourceUUID,
              licenses
            }),
            ActionHelpers.clearLoader(C.UPDATE_LICENSE_STORE)
          ])
        );
        setTimeout(() => {
          dispatch(
            ActionHelpers.successNote(
              C.UPDATE_LICENSE_STORE,
              "Licenses updated"
            )
          );
        }, 250);
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_LICENSE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchLicenses
 */
export const fetchLicenses = (
  { resourceUUID, resource },
  callback = () => {}
) => {
  return (dispatch) => {
    dispatch(ActionHelpers.loadingAction(C.UPDATE_LICENSE_STORE));

    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/licenses`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const licenses = Array.isArray(data) ? data : [data];

        dispatch(
          batchActions([
            replaceLicenses(licenses),
            ActionHelpers.clearLoader(C.UPDATE_LICENSE_STORE)
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_LICENSE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
