import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import compact from "lodash/compact";

export const MONTH_COUNT = 12;
export const COUNTRY_US = "US";

// Good locales reference
// https://stackoverflow.com/a/28357857
export const CURRENCIES = [
  {
    code: "USD",
    value: "US",
    label: "US Dollar",
    symbol: "$",
    locales: ["en-US"]
  },
  {
    code: "AED",
    value: "AE",
    label: "United Arab Emirates Dirham",
    symbol: "دإ",
    locales: ["ae"]
  },
  {
    code: "AFN",
    value: "؋",
    label: "Afghan Afghani",
    symbol: "",
    locales: ["afn"]
  },
  {
    code: "ALL",
    value: "AL",
    label: "Albanian Lek",
    symbol: "Lek",
    locales: ["al"]
  },
  {
    code: "AMD",
    value: "AM",
    label: "Armenian Dram",
    symbol: "դր",
    locales: ["am"]
  },
  {
    code: "ANG",
    value: "",
    label: "Netherlands Antillean Guilder",
    symbol: "",
    locales: [""]
  },
  {
    code: "AOA",
    value: "AO",
    label: "Angolan Kwanza",
    symbol: "",
    locales: ["ao"]
  },
  {
    code: "ARS",
    value: "AR",
    label: "Argentine Peso",
    symbol: "$",
    locales: ["es-AR"]
  },
  {
    label: "Australian Dollar",
    value: "AU",
    code: "AUD",
    symbol: "$",
    locales: ["en-AU"]
  },
  {
    code: "AWG",
    value: "AW",
    label: "Aruban Florin",
    symbol: "",
    locales: [""]
  },
  {
    code: "AZN",
    value: "AZ",
    label: "Azerbaijani Manat",
    symbol: "ман",
    locales: [""]
  },
  {
    code: "BAM",
    value: "BA",
    label: "Bosnia-Herzegovina Convertible Mark",
    symbol: "KM",
    locales: [""]
  },
  {
    code: "BBD",
    value: "BB",
    label: "Barbadian Dollar",
    symbol: "",
    locales: [""]
  },
  {
    code: "BDT",
    value: "BD",
    label: "Bangladeshi Taka",
    symbol: "৳",
    locales: [""]
  },
  {
    code: "BGN",
    value: "BG",
    label: "Bulgarian Lev",
    symbol: "‎‎лв",
    locales: ["bg-BG", "bg"]
  },
  {
    code: "BIF",
    value: "BI",
    label: "Burundian Franc",
    symbol: "FBu",
    locales: [""]
  },
  {
    code: "BMD",
    value: "BM",
    label: "Bermudan Dollar",
    symbol: "",
    locales: [""]
  },
  {
    code: "BND",
    value: "BN",
    label: "Brunei Dollar",
    symbol: "$",
    locales: [""]
  },
  {
    code: "BOB",
    value: "BO",
    label: "Bolivian Boliviano",
    symbol: "Bs",
    locales: [""]
  },
  {
    label: "Brazilian Real",
    value: "BR",
    code: "BRL",
    symbol: "R$",
    locales: ["ptBR"]
  },
  {
    code: "BSD",
    value: "BS",
    label: "Bahamian Dollar",
    symbol: "$",
    locales: [""]
  },
  {
    code: "BWP",
    value: "BW",
    label: "Botswanan Pula",
    symbol: "P",
    locales: [""]
  },
  {
    code: "BZD",
    value: "$",
    label: "Belize Dollar",
    symbol: "BZ",
    locales: [""]
  },
  {
    label: "Canadian Dollar",
    value: "CA",
    code: "CAD",
    symbol: "$",
    locales: ["en-CA", "fr-CA"]
  },
  {
    code: "CDF",
    value: "CG",
    label: "Congolese Franc",
    symbol: "FC",
    locales: [""]
  },
  {
    label: "Swiss Franc",
    value: "CH",
    code: "CHF",
    // Note: Important to not include period in symbol
    symbol: "Fr",
    locales: ["fr-CH"]
  },
  {
    code: "CLP",
    value: "CL",
    label: "Chilean Peso",
    symbol: "$",
    locales: [""]
  },
  {
    code: "CNY",
    value: "CN",
    label: "Chinese Yuan",
    symbol: "CN¥",
    locales: [""]
  },
  {
    code: "COP",
    value: "CO",
    label: "Colombian Peso",
    symbol: "$",
    locales: [""]
  },
  {
    code: "CRC",
    value: "CR",
    label: "Costa Rican Colón",
    symbol: "₡",
    locales: [""]
  },
  {
    code: "CVE",
    value: "CV",
    label: "Cape Verdean Escudo",
    symbol: "CV$",
    locales: [""]
  },
  {
    code: "CZK",
    value: "CZ",
    label: "Czech Koruna",
    symbol: "Kč",
    locales: ["cs", "cs-CZ"]
  },
  {
    code: "DJF",
    value: "DJ",
    label: "Djiboutian Franc",
    symbol: "Fdj",
    locales: [""]
  },
  {
    code: "DKK",
    value: "DK",
    label: "Danish Krone",
    symbol: "kr",
    locales: ["da-DK", "da"]
  },
  {
    code: "DOP",
    value: "DO",
    label: "Dominican Peso",
    symbol: "RD$",
    locales: [""]
  },
  {
    code: "DZD",
    value: "DZ",
    label: "Algerian Dinar",
    symbol: "دج",
    locales: [""]
  },
  {
    code: "EGP",
    value: "EG",
    label: "Egyptian Pound",
    symbol: "جم",
    locales: [""]
  },
  {
    code: "ETB",
    value: "ET",
    label: "Ethiopian Birr",
    symbol: "Br",
    locales: [""]
  },
  {
    code: "EUR",
    value: "EU",
    label: "Euro",
    symbol: "€",
    locales: [
      "de-AT",
      "nl-BE",
      "en-BE",
      "fr-BE",
      "de-BE",
      "el-CY",
      "et-EE",
      "et",
      "fi-FI",
      "sv-FI",
      "fr",
      "de",
      "de-DE",
      "el-CY",
      "el-GR",
      "el",
      "en-IE",
      "ga",
      "ga-IE",
      "it",
      "it-IT",
      "lv",
      "lv-LV",
      "lt",
      "lt-LT",
      "de-LU",
      "fr-LU",
      "es-MT",
      "nl-NL",
      "nl",
      "pt-PT",
      "sl",
      "sl-SI",
      "en-ES"
    ]
  },
  {
    code: "FJD",
    value: "FJ",
    label: "Fijian Dollar",
    symbol: "$",
    locales: [""]
  },
  {
    code: "FKP",
    value: "FK",
    label: "Falkland Islands Pound",
    symbol: "£",
    locales: [""]
  },
  {
    code: "GBP",
    value: "UK",
    label: "British Pound",
    symbol: "£",
    locales: ["en-GB"]
  },
  {
    code: "GEL",
    value: "GE",
    label: "Georgian Lari",
    symbol: "GEL",
    locales: [""]
  },
  {
    code: "GIP",
    value: "GI",
    label: "Gibraltar Pound",
    symbol: "£",
    locales: [""]
  },
  {
    code: "GMD",
    value: "GM",
    label: "Gambian Dalasi",
    symbol: "",
    locales: [""]
  },
  {
    code: "GNF",
    value: "GN",
    label: "Guinean Franc",
    symbol: "FG",
    locales: [""]
  },
  {
    code: "GTQ",
    value: "GT",
    label: "Guatemalan Quetzal",
    symbol: "Q",
    locales: [""]
  },
  {
    code: "GYD",
    value: "GY",
    label: "Guyanaese Dollar",
    symbol: "$",
    locales: [""]
  },
  {
    code: "HKD",
    value: "HK",
    label: "Hong Kong Dollar",
    symbol: "$",
    locales: ["yue-Hant-HK", "zh-Hans-HK", "zh-Hant-HK", "en-HK"]
  },
  {
    code: "HNL",
    value: "HN",
    label: "Honduran Lempira",
    symbol: "L",
    locales: [""]
  },
  {
    code: "HRK",
    value: "HR",
    label: "Croatian Kuna",
    symbol: "kn",
    locales: [""]
  },
  {
    code: "HTG",
    value: "HT",
    label: "Haitian Gourde",
    symbol: "",
    locales: [""]
  },
  {
    code: "HUF",
    value: "HU",
    label: "Hungarian Forint",
    symbol: "Ft",
    locales: [""]
  },
  {
    code: "IDR",
    value: "ID",
    label: "Indonesian Rupiah",
    symbol: "Rp",
    locales: [""]
  },
  {
    code: "ILS",
    value: "IL",
    label: "Israeli New Shekel",
    symbol: "₪",
    locales: [""]
  },
  {
    label: "Indian Rupee",
    value: "IN",
    code: "INR",
    symbol: "‎₹‎",
    locales: [
      "as-IN",
      "bn-IN",
      "en-IN",
      "gu-IN",
      "hi-IN",
      "kn-IN",
      "kok-IN",
      "ml-IN",
      "mr-IN",
      "ne-IN",
      "or-IN",
      "ta-IN",
      "te-IN",
      "bo-IN",
      "ur-IN"
    ]
  },
  {
    code: "ISK",
    value: "IS",
    label: "Icelandic Króna",
    symbol: "kr",
    locales: [""]
  },
  {
    code: "JMD",
    value: "JM",
    label: "Jamaican Dollar",
    symbol: "$",
    locales: [""]
  },
  {
    code: "JPY",
    value: "JP",
    label: "Japanese Yen",
    symbol: "¥",
    locales: ["ja", "ja-JP"]
  },
  {
    code: "KES",
    value: "KE",
    label: "Kenyan Shilling",
    symbol: "Ksh",
    locales: [""]
  },
  {
    code: "KGS",
    value: "KG",
    label: "Kyrgystani Som",
    symbol: "",
    locales: [""]
  },
  {
    code: "KHR",
    value: "KH",
    label: "Cambodian Riel",
    symbol: "៛",
    locales: [""]
  },
  {
    code: "KMF",
    value: "KM",
    label: "Comorian Franc",
    symbol: "FC",
    locales: [""]
  },
  {
    code: "KRW",
    value: "KR",
    label: "South Korean Won",
    symbol: "₩",
    locales: [""]
  },
  {
    code: "KYD",
    value: "KY",
    label: "Cayman Islands Dollar",
    symbol: "$",
    locales: [""]
  },
  {
    code: "KZT",
    value: "KZ",
    label: "Kazakhstani Tenge",
    symbol: "тңг",
    locales: [""]
  },
  {
    code: "LAK",
    value: "LA",
    label: "Laotian Kip",
    symbol: "",
    locales: [""]
  },
  // {
  //   code: "LBP",
  //   value: "LB",
  //   label: "Lebanese Pound",
  //   symbol: "LB£",
  //   locales: [""]
  // },
  {
    code: "LKR",
    value: "LK",
    label: "Sri Lankan Rupee",
    symbol: "SLRs",
    locales: [""]
  },
  {
    code: "LRD",
    value: "LR",
    label: "Liberian Dollar",
    symbol: "$",
    locales: [""]
  },
  {
    code: "LSL",
    value: "LS",
    label: "Lesotho Loti",
    symbol: "",
    locales: [""]
  },
  {
    code: "MAD",
    value: "MA",
    label: "Moroccan Dirham",
    symbol: "دم",
    locales: [""]
  },
  {
    code: "MDL",
    value: "MD",
    label: "Moldovan Leu",
    symbol: "MDL",
    locales: [""]
  },
  {
    code: "MGA",
    value: "MG",
    label: "Malagasy Ariary",
    symbol: "MGA",
    locales: [""]
  },
  {
    code: "MKD",
    value: "MK",
    label: "Macedonian Denar",
    symbol: "MKD",
    locales: [""]
  },
  {
    code: "MMK",
    value: "MM",
    label: "Myanmar Kyat",
    symbol: "",
    locales: [""]
  },
  {
    code: "MNT",
    value: "MN",
    label: "Mongolian Tugrik",
    symbol: "",
    locales: [""]
  },
  {
    code: "MOP",
    value: "MO",
    label: "Macanese Pataca",
    symbol: "MOP$",
    locales: [""]
  },
  // {
  //   code: "MRO",
  //   value: "MR",
  //   label: "Mauritanian Ouguiya (1973–2017)",
  //   symbol: "",
  //   locales: [""]
  // },
  {
    code: "MUR",
    value: "MU",
    label: "Mauritian Rupee",
    symbol: "MURs",
    locales: [""]
  },
  {
    code: "MVR",
    value: "MV",
    label: "Maldivian Rufiyaa",
    symbol: "",
    locales: [""]
  },
  {
    code: "MWK",
    value: "MW",
    label: "Malawian Kwacha",
    symbol: "",
    locales: [""]
  },
  {
    label: "Mexican Peso",
    value: "MX",
    code: "MXN",
    symbol: "$",
    locales: ["es-MX"]
  },
  {
    code: "MYR",
    value: "MY",
    label: "Malaysian Ringgit",
    symbol: "RM",
    locales: ["ms", "ms-MY", "ms-BN"]
  },
  {
    code: "MZN",
    value: "MZ",
    label: "Mozambican Metical",
    symbol: "MTn",
    locales: [""]
  },
  {
    code: "NAD",
    value: "",
    label: "Namibian Dollar",
    symbol: "N$",
    locales: [""]
  },
  {
    code: "NGN",
    value: "NG",
    label: "Nigerian Naira",
    symbol: "₦",
    locales: [""]
  },
  {
    code: "NIO",
    value: "NI",
    label: "Nicaraguan Córdoba",
    symbol: "C$",
    locales: [""]
  },
  {
    code: "NOK",
    value: "NO",
    label: "Norwegian Krone",
    symbol: "kr",
    locales: ["nn", "nb", "nb-NO", "nn-NO"]
  },
  {
    code: "NPR",
    value: "NP",
    label: "Nepalese Rupee",
    symbol: "नेरू",
    locales: [""]
  },
  {
    code: "NZD",
    value: "NZ",
    label: "New Zealand Dollar",
    symbol: "$",
    locales: ["en-NZ"]
  },
  {
    code: "PAB",
    value: "PA",
    label: "Panamanian Balboa",
    symbol: "B/",
    locales: [""]
  },
  {
    code: "PEN",
    value: "PE",
    label: "Peruvian Sol",
    symbol: "S/",
    locales: [""]
  },
  {
    code: "PGK",
    value: "PG",
    label: "Papua New Guinean Kina",
    symbol: "",
    locales: [""]
  },
  {
    code: "PHP",
    value: "PH",
    label: "Philippine Piso",
    symbol: "₱",
    locales: [""]
  },
  {
    code: "PKR",
    value: "PK",
    label: "Pakistani Rupee",
    symbol: "₨",
    locales: [""]
  },
  {
    code: "PLN",
    value: "PO",
    label: "Polish Zloty",
    symbol: "zł",
    locales: ["pl", "pl-PL"]
  },
  {
    code: "PYG",
    value: "PY",
    label: "Paraguayan Guarani",
    symbol: "₲",
    locales: [""]
  },
  {
    code: "QAR",
    value: "QA",
    label: "Qatari Rial",
    symbol: "رق",
    locales: [""]
  },
  {
    label: "Romanian Leu",
    value: "RO",
    code: "RON",
    symbol: "L",
    locales: ["ro", "ro-RO"]
  },
  {
    code: "RSD",
    value: "CS",
    label: "Serbian Dinar",
    symbol: "дин",
    locales: [""]
  },
  {
    code: "RUB",
    value: "RU",
    label: "Russian Ruble",
    symbol: "₽",
    locales: [""]
  },
  {
    code: "RWF",
    value: "RW",
    label: "Rwandan Franc",
    symbol: "FR",
    locales: [""]
  },
  {
    code: "SAR",
    value: "SA",
    label: "Saudi Riyal",
    symbol: "رس",
    locales: [""]
  },
  {
    code: "SBD",
    value: "SB",
    label: "Solomon Islands Dollar",
    symbol: "",
    locales: [""]
  },
  {
    code: "SCR",
    value: "SC",
    label: "Seychellois Rupee",
    symbol: "",
    locales: [""]
  },
  {
    code: "SEK",
    value: "SE",
    label: "Swedish Krona",
    symbol: "kr",
    locales: ["sv", "sv-SE"]
  },
  {
    label: "Singapore Dollar",
    value: "SG",
    code: "SGD",
    symbol: "$",
    locales: ["en-SG", "zh-Hans-SG"]
  },
  {
    code: "SHP",
    value: "SH",
    label: "St. Helena Pound",
    symbol: "",
    locales: [""]
  },
  {
    code: "SLL",
    value: "SL",
    label: "Sierra Leonean Leone",
    symbol: "",
    locales: [""]
  },
  // {
  //   code: "SOS",
  //   value: "SO",
  //   label: "Somali Shilling",
  //   symbol: "Ssh",
  //   locales: [""]
  // },
  {
    code: "SRD",
    value: "SR",
    label: "Surinamese Dollar",
    symbol: "",
    locales: [""]
  },
  // {
  //   code: "STD",
  //   value: "",
  //   label: "São Tomé & Príncipe Dobra (1977–2017)",
  //   symbol: "",
  //   locales: [""]
  // },
  {
    code: "SZL",
    value: "SZ",
    label: "Swazi Lilangeni",
    symbol: "",
    locales: [""]
  },
  {
    code: "THB",
    value: "TH",
    label: "Thai Baht",
    symbol: "฿",
    locales: [""]
  },
  {
    code: "TJS",
    value: "TJ",
    label: "Tajikistani Somoni",
    symbol: "",
    locales: [""]
  },
  {
    code: "TOP",
    value: "TO",
    label: "Tongan Paʻanga",
    symbol: "",
    locales: [""]
  },
  {
    code: "TRY",
    value: "TR",
    label: "Turkish Lira",
    symbol: "TL",
    locales: [""]
  },
  {
    code: "TTD",
    value: "TT",
    label: "Trinidad & Tobago Dollar",
    symbol: "$",
    locales: [""]
  },
  {
    code: "TWD",
    value: "TW",
    label: "New Taiwan Dollar",
    symbol: "NT$",
    locales: [""]
  },
  {
    code: "TZS",
    value: "TZ",
    label: "Tanzanian Shilling",
    symbol: "TSh",
    locales: [""]
  },
  {
    code: "UAH",
    value: "UA",
    label: "Ukrainian Hryvnia",
    symbol: "₴",
    locales: [""]
  },
  {
    code: "UGX",
    value: "UG",
    label: "Ugandan Shilling",
    symbol: "USh",
    locales: [""]
  },
  {
    code: "UYU",
    value: "UY",
    label: "Uruguayan Peso",
    symbol: "$",
    locales: [""]
  },
  {
    code: "UZS",
    value: "UZ",
    label: "Uzbekistani Som",
    symbol: "",
    locales: [""]
  },
  {
    code: "VND",
    value: "VN",
    label: "Vietnamese Dong",
    symbol: "₫",
    locales: [""]
  },
  {
    code: "VUV",
    value: "VU",
    label: "Vanuatu Vatu",
    symbol: "",
    locales: [""]
  },
  {
    code: "WST",
    value: "WS",
    label: "Samoan Tala",
    symbol: "",
    locales: [""]
  },
  {
    code: "XAF",
    value: "",
    label: "Central African CFA Franc",
    symbol: "",
    locales: [""]
  },
  {
    code: "XCD",
    value: "",
    label: "East Caribbean Dollar",
    symbol: "",
    locales: [""]
  },
  {
    code: "XOF",
    value: "",
    label: "West African CFA Franc",
    symbol: "",
    locales: [""]
  },
  {
    code: "XPF",
    value: "",
    label: "CFP Franc",
    symbol: "",
    locales: [""]
  },
  {
    code: "YER",
    value: "YE",
    label: "Yemeni Rial",
    symbol: "ري",
    locales: [""]
  },
  {
    code: "ZAR",
    value: "ZA",
    label: "South African Rand",
    symbol: "R",
    locales: ["en-ZA"]
  },
  {
    code: "ZMW",
    value: "ZM",
    label: "Zambian Kwacha",
    symbol: "ZK",
    locales: [""]
  }
];

export const CURRENCY_CODES = CURRENCIES.reduce((memo, currency) => {
  memo[currency.code] = currency.code.toLowerCase();
  return memo;
}, {});

export const CURRENCY_SYMBOLS_MAP = CURRENCIES.reduce((memo, currency) => {
  memo[currency.code.toLowerCase()] = currency.symbol;
  return memo;
}, {});

export const CURRENCY_CODE_SET = uniq(CURRENCIES.map(({ code }) => code));

export const CURRENCY_OPTIONS = uniqBy(
  CURRENCIES.map((currency) => ({
    uid: currency.code.toLowerCase(),
    label: [currency.code, currency.label].join(" - ")
  })),
  "uid"
);

export const THOUSAND_BASED_CODES = compact([
  CURRENCY_CODES.BIF,
  CURRENCY_CODES.CLP,
  CURRENCY_CODES.DJF,
  CURRENCY_CODES.GNF,
  CURRENCY_CODES.JPY,
  CURRENCY_CODES.KMF,
  CURRENCY_CODES.KRW,
  CURRENCY_CODES.MGA,
  CURRENCY_CODES.PYG,
  CURRENCY_CODES.RWF,
  CURRENCY_CODES.UGX,
  CURRENCY_CODES.VND,
  CURRENCY_CODES.VUV,
  CURRENCY_CODES.XAF,
  CURRENCY_CODES.XOF,
  CURRENCY_CODES.XPF
]);

export const CURRENCY_FORMAT = {
  DOLLARS_CENTS: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  },
  MICRO_CENTS: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4
  }
};
