import keymirror from "keymirror";

export default keymirror({
  SET_APP_CAPABILITIES: null,
  SET_APP_PRODUCTS: null,
  SET_APP_ENTITIES: null,
  SET_APP_TEMPLATES: null,
  SET_APP_PAGES_TEMPLATES_INDEX: null,
  SET_APP_PAGES_TEMPLATE: null,
  SET_APP_FONTS: null,
  SET_APP_RATES: null,
  SET_APP_CHECKOUT: null,
  SET_APP_BUILDER: null,
  SET_APP_VARIABLES: null,
  SET_APP_ONBOARDING: null,
  UPDATE_APP_STORE: null
});
