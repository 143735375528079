const SUSPENDED = "suspended";
const INACTIVE = "inactive";
const CANCELED = "canceled";
const ARCHIVED = "archived";
const DEFERRED = "deferred";
const ACTIVE = "active";
const REVIEW = "review";
const AUTH_REQUIRED = "auth_required";

export const PREVENT_TRANSITION_STATUS = [SUSPENDED, CANCELED, ARCHIVED];
export const PREVENT_TEST_MODE = [
  ...PREVENT_TRANSITION_STATUS,
  DEFERRED,
  INACTIVE
];

export const MERCHANT_ACCOUNT_STATUS = {
  INACTIVE,
  ACTIVE,
  SUSPENDED,
  REVIEW,
  AUTH_REQUIRED,
  CANCELED,
  DEFERRED,
  ARCHIVED
};

export const DEFERRED_KEY = {
  CLIENT_KEY: "DEFERRED_CLIENT_KEY"
};

/**
 * Seller had pre-existing Stripe account
 */
const REVIEW_ON_DASHBOARD = "review_on_dashboard";
/**
 * Seller created Stripe account on PriceBlocs
 * - COMPLETE_WITH_CONNECT - need to progress through Stripe connect again
 * - REAUTH - details submitted but now need to re-auth
 */
const COMPLETE_WITH_CONNECT = "complete_with_connect";
const REAUTH = "reauth";

export const MERCHANT_ACCOUNT_AUTH_ACTION = {
  REVIEW_ON_DASHBOARD,
  COMPLETE_WITH_CONNECT,
  REAUTH
};
