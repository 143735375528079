import startCase from "lodash/startCase";
import compact from "lodash/compact";
import { USER_CAPABILITY } from "./capability";
import { useUserFeature } from "utils/hooks/capabilities";
const HOME = "home";
const ANALYTICS = "analytics";
const PAGES = "pages";
const INSIGHTS = "insights";
const LINKS = "links";
const FILES = "files";
const CAMPAIGNS = "campaigns";
const PRODUCTS = "products";
const COUPONS = "coupons";
const CONTACTLESS = "contactless";
const SETTINGS = "settings";
const SALES = "sales";
const TAX_RATES = "taxRates";
const SHIPPING_RATES = "shippingRates";
const FEATURES = "features";
const LEGAL_CONTENTS = "legalContents";
const INTEGRATIONS = "integrations";

/**
 * Note: reflect additions in the SECTION_MAP within
 * - src/components/ApplicationSection/index.jsx
 */
export const HUB_APP_SUB_SECTION = {
  HOME,
  ANALYTICS,
  PAGES,
  INSIGHTS,
  LINKS,
  CONTACTLESS,
  PRODUCTS,
  COUPONS,
  SETTINGS,
  SALES,
  TAX_RATES,
  SHIPPING_RATES,
  FILES,
  CAMPAIGNS,
  FEATURES,
  LEGAL_CONTENTS,
  INTEGRATIONS
};

const HOME_CONFIG = {
  id: HOME,
  label: startCase(HOME),
  icon: HOME
};

const ANALYTICS_CONFIG = {
  id: ANALYTICS,
  label: startCase(ANALYTICS),
  icon: ANALYTICS
};

const PAGES_CONFIG = {
  id: PAGES,
  label: startCase(PAGES),
  icon: PAGES
};

const INSIGHTS_CONFIG = {
  id: INSIGHTS,
  label: startCase(INSIGHTS),
  icon: INSIGHTS
};

const LINKS_CONFIG = {
  id: LINKS,
  label: "Payment links",
  icon: LINKS
};

const FILES_CONFIG = {
  id: FILES,
  label: startCase(FILES),
  icon: FILES
};

const INTEGRATIONS_CONFIG = {
  id: INTEGRATIONS,
  label: startCase(INTEGRATIONS),
  icon: "code"
};

const CAMPAIGNS_CONFIG = {
  id: CAMPAIGNS,
  label: startCase(CAMPAIGNS),
  icon: CAMPAIGNS
};

const PRODUCTS_CONFIG = {
  id: PRODUCTS,
  label: startCase(PRODUCTS),
  icon: PRODUCTS
};

const COUPONS_CONFIG = {
  id: COUPONS,
  label: startCase(COUPONS),
  icon: COUPONS
};

const TAX_RATES_CONFIG = {
  id: TAX_RATES,
  label: startCase(TAX_RATES),
  icon: TAX_RATES
};

const SHIPPING_RATES_CONFIG = {
  id: SHIPPING_RATES,
  label: startCase(SHIPPING_RATES),
  icon: SHIPPING_RATES
};

const SETTINGS_CONFIG = {
  id: SETTINGS,
  label: startCase(SETTINGS),
  icon: SETTINGS
};

const SALES_CONFIG = {
  id: SALES,
  label: startCase(SALES),
  icon: SALES
};

const CONTACTLESS_CONFIG = {
  id: CONTACTLESS,
  label: startCase(CONTACTLESS),
  icon: CONTACTLESS
};

const FEATURES_CONFIG = {
  id: FEATURES,
  label: startCase(FEATURES),
  icon: FEATURES
};

const LEGAL_CONFIG = {
  id: LEGAL_CONTENTS,
  label: "Legal",
  icon: "legal"
};

export const HUB_APP_SUB_SECTION_CONFIG = [
  HOME_CONFIG,
  SALES_CONFIG,
  PAGES_CONFIG,
  PRODUCTS_CONFIG,
  COUPONS_CONFIG,
  LINKS_CONFIG,
  TAX_RATES_CONFIG,
  SHIPPING_RATES_CONFIG,
  ANALYTICS_CONFIG,
  SETTINGS_CONFIG,
  FILES_CONFIG,
  CONTACTLESS_CONFIG,
  FEATURES_CONFIG,
  LEGAL_CONFIG
];

/**
 * Control sidebar nav on Hub
 * Configure sidebar Hub nav sub sections
 * Check beta flags to show / hide app sections
 */
export const getOrderedAppSubSections = () => {
  const result = [
    HOME_CONFIG,
    SALES_CONFIG,
    LINKS_CONFIG,
    CONTACTLESS_CONFIG,
    PRODUCTS_CONFIG,
    COUPONS_CONFIG,
    TAX_RATES_CONFIG,
    ANALYTICS_CONFIG,
    FILES_CONFIG,
    INTEGRATIONS_CONFIG
  ];

  if (useUserFeature(USER_CAPABILITY.INSIGHTS)) {
    result.push(INSIGHTS_CONFIG);
  }

  if (useUserFeature(USER_CAPABILITY.PAGES)) {
    result.push(PAGES_CONFIG);
  }

  if (useUserFeature(USER_CAPABILITY.SHIPPING_RATES)) {
    result.push(SHIPPING_RATES_CONFIG);
  }

  if (useUserFeature(USER_CAPABILITY.CAMPAIGNS)) {
    result.push(CAMPAIGNS_CONFIG);
  }

  if (useUserFeature(USER_CAPABILITY.FEATURES)) {
    result.push(FEATURES_CONFIG);
  }

  if (useUserFeature(USER_CAPABILITY.LEGAL)) {
    result.push(LEGAL_CONFIG);
  }

  result.push(SETTINGS_CONFIG);

  return compact(result);
};
