import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
  FOCUS_CHECKOUT_KEY,
  VISIBLE_ENTITY_SETTINGS_FOCUS
} from "utils/constants/focus";
import { useFormikContext } from "formik";
import {
  CHECKOUT_PRODUCT_SETTINGS_INTENT,
  CHECKOUT_SETTINGS_INTENT
} from "utils/constants/checkout";
import { EDITOR_MODE } from "utils/constants/editor";
import { FIELD_GROUP_INTENT } from "utils/constants/fields";
import {
  FORM_INTENT,
  PRICING_TABLE_INTENT,
  PRODUCT_COLLECTION_INTENT
} from "utils/constants/pricing";
import { STATE_KEYS } from "utils/constants/state";
import { VIEWPORT_SIZE } from "utils/constants/viewport";
import { usePageFactoryContext } from "utils/context";
import { getFieldPath } from "utils/form";
import {
  getFormLogicSelections,
  getConfigLogicCheckoutSelectionsComboIx
} from "utils/logic";

/**
 * Null out the menu nav (e.g. field level navigation)
 */
const RESET_MENU_FOCUS = [
  {
    key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
    value: null
  },
  {
    key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
    value: null
  },
  {
    key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
    value: null
  },
  {
    key: STATE_KEYS.EDITOR.MENU_THIRD_INTENT,
    value: null
  }
];

export const getMenuIntentFocus = ({
  menuIntent,
  activeIndex,
  activeSubIndex,
  menuSubIntent,
  menuThirdIntent
}) => [
  {
    key: STATE_KEYS.EDITOR.MENU_INTENT,
    value: menuIntent || null
  },
  {
    key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
    value:
      Number.isInteger(activeIndex) && activeIndex > -1 ? activeIndex : null
  },
  {
    key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
    value:
      Number.isInteger(activeSubIndex) && activeSubIndex > -1
        ? activeSubIndex
        : null
  },
  {
    key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
    value: menuSubIntent || null
  },
  {
    key: STATE_KEYS.EDITOR.MENU_THIRD_INTENT,
    value: menuThirdIntent || null
  }
];

export const getFormFieldGroupAddFieldFocus = ({ uuid }) => {
  return [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: uuid
    },
    {
      key: STATE_KEYS.EDITOR.MODE,
      value: EDITOR_MODE.USE
    },
    {
      key: STATE_KEYS.EDITOR.MENU_INTENT,
      value: FORM_INTENT.FIELD_GROUP_ORDER
    },
    /**
     * Null out the menu nav (e.g. field level navigation)
     */
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
      value: 0
    },
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: 0
    },
    {
      key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
      value: FIELD_GROUP_INTENT.ADD_FIELD
    }
  ];
};

export const getFormFieldGroupFieldFocus = ({
  uuid,
  mode,
  activeIndex,
  activeSubIndex
}) => {
  return [
    ...VISIBLE_ENTITY_SETTINGS_FOCUS,
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: uuid
    },
    {
      key: STATE_KEYS.EDITOR.MODE,
      value: mode || EDITOR_MODE.USE
    },
    {
      key: STATE_KEYS.EDITOR.MENU_INTENT,
      value: FORM_INTENT.FIELD_GROUP_ORDER
    },
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
      value:
        Number.isInteger(activeIndex) && activeIndex > -1 ? activeIndex : null
    },
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value:
        Number.isInteger(activeSubIndex) && activeSubIndex > -1
          ? activeSubIndex
          : null
    },
    {
      key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
      value: null
    },
    {
      key: STATE_KEYS.EDITOR.MENU_THIRD_INTENT,
      value: null
    }
  ];
};

export const getFocusConfig = ({
  uuid,
  mode,
  menuIntent,
  activeIndex,
  activeSubIndex,
  menuSubIntent,
  menuThirdIntent
}) => {
  return [
    ...VISIBLE_ENTITY_SETTINGS_FOCUS,
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: uuid
    },
    {
      key: STATE_KEYS.EDITOR.MODE,
      value: mode || EDITOR_MODE.USE
    },
    {
      key: STATE_KEYS.EDITOR.MENU_INTENT,
      value: menuIntent || null
    },
    /**
     * Null out the menu nav (e.g. field level navigation)
     */
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
      value:
        Number.isInteger(activeIndex) && activeIndex > -1 ? activeIndex : null
    },
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value:
        Number.isInteger(activeSubIndex) && activeSubIndex > -1
          ? activeSubIndex
          : null
    },
    /**
     * If the are fields then go to combinations else go to root menu
     */
    {
      key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
      value: menuSubIntent || null
    },
    {
      key: STATE_KEYS.EDITOR.MENU_THIRD_INTENT,
      value: menuThirdIntent || null
    }
  ];
};

export const getFormPresentationSettingsFocus = ({ name }) => [
  {
    key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
    value: name
  },
  {
    key: STATE_KEYS.EDITOR.MENU_INTENT,
    value: FORM_INTENT.PRESENTATION_SETTINGS
  },
  ...RESET_MENU_FOCUS
];

export const getEditorModeFocus = (mode) => [
  {
    key: STATE_KEYS.EDITOR.MODE,
    value: mode || EDITOR_MODE.USE
  }
];

export const getEditorViewportFocus = (viewport) => [
  {
    key: STATE_KEYS.EDITOR.VIEWPORT,
    value: viewport || VIEWPORT_SIZE.MOBILE
  }
];

/**
 * Note: BUILDER_INTENT is expected to be spread into this collection from the consumer
 * @param {Object} params
 * @returns
 */
export const getFormCheckoutSettingsFocus = ({
  uuid,
  mode,
  activeIndex,
  activeSubIndex,
  menuSubIntent,
  menuThirdIntent
}) => {
  return [
    ...VISIBLE_ENTITY_SETTINGS_FOCUS,
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: uuid
    },
    {
      key: STATE_KEYS.EDITOR.MODE,
      value: mode || EDITOR_MODE.USE
    },
    {
      key: STATE_KEYS.EDITOR.MENU_INTENT,
      value: FORM_INTENT.CHECKOUT_SETTINGS
    },
    /**
     * Null out the menu nav (e.g. field level navigation)
     * - combination index
     */
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
      value:
        Number.isInteger(activeIndex) && activeIndex > -1 ? activeIndex : null
    },
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value:
        Number.isInteger(activeSubIndex) && activeSubIndex > -1
          ? activeSubIndex
          : null
    },
    /**
     * If the are fields then go to combinations else go to root menu
     */
    {
      key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
      value: menuSubIntent || null
    },
    {
      key: STATE_KEYS.EDITOR.MENU_THIRD_INTENT,
      value: menuThirdIntent || null
    }
  ];
};

export const getProductCollectionCheckoutSettingsFocus = ({ uuid }) => {
  return [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: uuid
    },
    {
      key: STATE_KEYS.EDITOR.MODE,
      value: EDITOR_MODE.USE
    },
    {
      key: STATE_KEYS.EDITOR.MENU_INTENT,
      value: PRODUCT_COLLECTION_INTENT.PRODUCT_ORDER
    },
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
      value: null
    },
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: null
    }
  ];
};

export const getFocusForContext = ({ uuid, context, activeIndex }) => {
  if (context === FOCUS_CHECKOUT_KEY.COMMON) {
    return getFormCheckoutSettingsFocus({
      uuid,
      menuSubIntent: CHECKOUT_SETTINGS_INTENT.SET_COMMON
    });
  } else if (context === FOCUS_CHECKOUT_KEY.CONSTANT) {
    return getFormCheckoutSettingsFocus({
      uuid,
      menuSubIntent: CHECKOUT_SETTINGS_INTENT.SET_CONSTANT
    });
  } else if (context === FOCUS_CHECKOUT_KEY.COMBINATIONS) {
    return getFormCheckoutSettingsFocus({
      uuid,
      menuSubIntent: CHECKOUT_SETTINGS_INTENT.SET_PRODUCTS,
      menuThirdIntent: CHECKOUT_PRODUCT_SETTINGS_INTENT.SET_COMBINATIONS,
      activeIndex
    });
  }
};

export const useFormCheckoutPreviewFocus = ({ name, fieldGroups }) => {
  let result = null;

  const { stateKey } = usePageFactoryContext();
  const { values } = useFormikContext();

  const contentRoot = `content.${name}`;
  const contentPath = getFieldPath(stateKey, contentRoot);

  const configPath = `${contentPath}.config`;
  const configValues = get(values, configPath);

  const selections = getFormLogicSelections({
    contentPath,
    values,
    fieldGroups
  });

  const checkoutConstantPath = `${contentPath}.${STATE_KEYS.FORM.CONFIG_LOGIC_CHECKOUTS_CONSTANT}`;
  const checkoutConstant = get(values, checkoutConstantPath);

  const comboIndex = getConfigLogicCheckoutSelectionsComboIx(
    selections,
    configValues
  );

  const hasCheckoutConstant = !isEmpty(checkoutConstant);
  const hasCombo = comboIndex >= 0;
  const canFocus = hasCheckoutConstant || hasCombo;
  if (canFocus) {
    result = getFocusForContext({
      context: hasCombo
        ? FOCUS_CHECKOUT_KEY.COMBINATIONS
        : FOCUS_CHECKOUT_KEY.CONSTANT,
      activeIndex: comboIndex,
      uuid: name,
      mode: EDITOR_MODE.INSPECT
    });
  }

  return result;
};

export const resetFocusBlockRoot = (name) => [
  {
    key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
    value: name
  },
  {
    key: STATE_KEYS.EDITOR.PRICING_INTENT,
    value: null
  },
  {
    key: STATE_KEYS.EDITOR.PRICING_ACTIVE_PRODUCT,
    value: null
  }
];

export const setAddProductOrderFocus = (name) => [
  {
    key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
    value: name
  },
  {
    key: STATE_KEYS.EDITOR.PRICING_INTENT,
    value: PRICING_TABLE_INTENT.PRODUCT_ORDER
  }
];
