import { ORGANIZATION } from "utils/constants/models";
import { getConfig } from "utils/env";
import { useCtxReferences } from "utils/selectors";
import { getSubdomainRoot } from "utils/subdomain";

export const getAppUrlVariations = ({ application }) => {
  const shortRoot = getConfig("WEB_CLIENT_ROOT");
  const ctxApp = application || {};

  const subdomain = getSubdomainRoot(ctxApp.subdomain);
  const alias = `${shortRoot}/${ctxApp.alias}`;

  const isOrg = ctxApp.resource === ORGANIZATION;
  const root = isOrg ? subdomain : alias;
  const key = isOrg ? ctxApp.subdomain : ctxApp.alias;

  return {
    subdomain,
    alias,
    standard: shortRoot,
    root,
    key
  };
};

export const useAppUrlVariations = () => {
  const { application } = useCtxReferences();

  return getAppUrlVariations({ application });
};
