import C from "constants/integration";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import startCase from "lodash/startCase";
import { setOnboardings } from "./onboarding";
import { setProducts } from "./product";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_INTEGRATION_STORE,
    payload: {
      isLoading: true
    }
  };
}

export function updateIntegrationAction(payload) {
  return {
    type: C.UPDATE_INTEGRATION,
    payload
  };
}

export const setIntegrations = (payload) => {
  return {
    type: C.SET_INTEGRATIONS,
    payload
  };
};

export const removeIntegrations = (payload) => {
  return {
    type: C.REMOVE_INTEGRATIONS,
    payload
  };
};

export const replaceIntegrations = (payload) => {
  return {
    type: C.REPLACE_INTEGRATIONS,
    payload
  };
};

export function resetIntegrationNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_INTEGRATION_STORE,
      payload
    });
  };
}

export function showIntegrationNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_INTEGRATION_STORE,
        message || `Success!`
      )
    );
  };
}

/**
 * exchangeAndCreateIntegration
 */
export const exchangeAndCreateIntegration = (
  { resourceUUID, resource, data, service },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/integrations/${service}`;
    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [
          setIntegrations([responseData.integration]),
          ActionHelpers.successNote(
            C.UPDATE_INTEGRATION_STORE,
            `Connected ${startCase(service)} integration`
          )
        ];
        if (responseData.onboarding) {
          actions.push(setOnboardings([responseData.onboarding]));
        }

        dispatch(batchActions(actions));

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_INTEGRATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * createIntegration
 */
export const createIntegration = (
  { resourceUUID, resource, data, service },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/integrations`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [
          setIntegrations([responseData]),
          ActionHelpers.successNote(
            C.UPDATE_INTEGRATION_STORE,
            `Created ${startCase(service)} integration`
          )
        ];

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_INTEGRATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchIntegration
 */
export const fetchIntegration = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/integrations/${uuid}`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [setIntegrations([responseData])];

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_INTEGRATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * createIntegrationProduct
 */
export const createIntegrationProduct = (
  { resourceUUID, resource, uuid, service, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/integrations/${uuid}/products/${service}`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [
          setIntegrations([responseData.integration]),
          setProducts([responseData.product])
        ];

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_INTEGRATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchIntegrations
 */
export const fetchIntegrations = (
  { resourceUUID, resource },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/integrations`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [setIntegrations(responseData)];

        dispatch(batchActions(actions));

        callback(null, responseData);
      })

      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_INTEGRATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * deleteIntegration
 */
export function deleteIntegration(
  { resource, resourceUUID, uuid, service },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_INTEGRATION_STORE,
        "delete",
        "Integration",
        "Delete integration"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/integrations/${uuid}`;
    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const actions = [
          removeIntegrations([data]),
          ActionHelpers.clearLoader(C.UPDATE_INTEGRATION_STORE),
          ActionHelpers.successNote(
            C.UPDATE_INTEGRATION_STORE,
            `Disconnected ${startCase(service)} integration`
          )
        ];

        dispatch(batchActions(actions));
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_INTEGRATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

export function updateIntegration(
  { resource, resourceUUID, uuid, service, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_INTEGRATION_STORE,
        "update",
        "Integration",
        "Update integration"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/integrations/${uuid}`;
    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [
          setIntegrations(responseData),
          ActionHelpers.clearLoader(C.UPDATE_INTEGRATION_STORE),
          ActionHelpers.successNote(
            C.UPDATE_INTEGRATION_STORE,
            `Updated ${service ? startCase(service) : ""} integration`
          )
        ];

        dispatch(batchActions(actions));
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_INTEGRATION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
