import startCase from "lodash/startCase";

const DASHBOARD = "dashboard";
const ANALYTICS = "analytics";
const EMBED = "embed";

export const COUPON_TABS = {
  DASHBOARD,
  ANALYTICS,
  EMBED
};

export const COUPON_ACTION_UID = {
  MERCHANT_CHECKOUT: "merchant_checkout"
};

const ACTIVE = "active";
const INACTIVE = "inactive";
const ARCHIVED = "archived";

export const COUPON_STATUS = {
  ACTIVE,
  INACTIVE,
  ARCHIVED
};

const ONCE = "once";
const REPEATING = "repeating";
const FOREVER = "forever";

export const COUPON_DURATION = {
  ONCE,
  REPEATING,
  FOREVER
};

const FIXED = "fixed";
const PERCENTAGE = "percentage";

export const COUPON_DISCOUNT_TYPE = {
  PERCENTAGE,
  FIXED
};

export const COUPON_DURATION_OPTIONS = [
  {
    uid: COUPON_DURATION.ONCE,
    label: startCase(COUPON_DURATION.ONCE),
    description: "Coupon will only be applied once."
  },
  {
    uid: COUPON_DURATION.REPEATING,
    label: startCase(COUPON_DURATION.REPEATING),
    description:
      "Coupon will be used repeatedly for a specified duration of months."
  },
  {
    uid: COUPON_DURATION.FOREVER,
    label: startCase(COUPON_DURATION.FOREVER),
    description: "Coupons will last forever."
  }
];

export const COUPON_LIMIT = 1;
