import { ORGANIZATION, USER } from "utils/constants/models";

export const API_KEY_OWNER = {
  ORGANIZATION: ORGANIZATION,
  USER: USER
};

const LIVE = "live";
const TEST = "test";
export const API_KEY_MODE = {
  LIVE,
  TEST
};

const SECRET = "secret";
const PUBLIC = "public";
export const API_KEY_TYPE = {
  SECRET,
  PUBLIC
};
