import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import formatDistance from "date-fns/formatDistance";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import compareAsc from "date-fns/compareAsc";
import compareDesc from "date-fns/compareDesc";
import isPast from "date-fns/isPast";

export const TS_FORMAT = {
  MDY: "MMM do, yyyy",
  DATE_AND_TIME: "MMMM d, yyyy h:mm aa",
  FULL: "MMMM d, yyyy h:mm aa zz",
  YMDMS: "MMM do, yyyy",
  PERIOD: "MMM d, yyyy"
};

export const MOBILE_TS_FORMAT = {
  DATETIME_LOCAL: "yyyy-MM-dd'T'HH:mm",
  DATE: "yyyy-MM-dd"
};

const ORDER_BY_OPTIONS = {
  DESC: "desc",
  ASC: "asc"
};

const ORDER_BY_FIELDS = {
  CREATED_AT: "created_at"
};

// const MOCK_DATE = new Date("2021-04-26");

/**
 * If value is a string we convert it to a date
 * Else we leave it as a Date instance
 */
export const getDateOrNow = (value) =>
  value ? (typeof value === "string" ? new Date(value) : value) : new Date();

export const getPickDatePrompt = (value, tsFormat = TS_FORMAT.FULL) =>
  value ? format(getDateOrNow(value), tsFormat) : "Select a date";

export const getNowDate = () =>
  process.env.NODE_ENV === "test" ? "2021-04-26" : new Date();

const STUB_ISO_TODAY = "2022-03-15T22:24:48.826Z";
export const getToday = () =>
  process.env.NODE_ENV === "test" ? new Date(STUB_ISO_TODAY) : new Date();

// If value is a seconds decimal we convert to an integer
export const formatMilliseconds = (value) => {
  const ts = typeof value === "number" ? Number.parseInt(value) : value;
  return `${ts}`.length === 10 ? Math.floor(ts * 1000) : ts;
};

export const isToday = (now = new Date(), comparison = new Date()) => {
  return (
    comparison.getDate() === now.getDate() &&
    comparison.getMonth() === now.getMonth() &&
    comparison.getFullYear() === now.getFullYear()
  );
};

export const getTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const toZonedTime = (timestamp) =>
  utcToZonedTime(formatMilliseconds(timestamp), getTimezone());

export const timeSinceComputation = (computedAt) => {
  let result = "";
  try {
    if (computedAt) {
      const comparisonDate =
        typeof computedAt === "number"
          ? toZonedTime(computedAt)
          : new Date(computedAt);

      result = `${formatDistance(new Date(), comparisonDate, {
        includeSeconds: true
      })} ago`;
    }
  } catch (error) {
    result = "";
  }
  return result;
};

export const isExpired = (timestamp) => {
  const zoned = toZonedTime(timestamp);
  return isPast(zoned);
};

export const formatUTCTime = (timestamp, tsFormat = TS_FORMAT.MDY) => {
  const zoned = toZonedTime(timestamp);
  return format(zoned, tsFormat);
};

export const formatTime = (timestamp, tsFormat = TS_FORMAT.MDY) => {
  try {
    return format(parseISO(timestamp), tsFormat);
  } catch (error) {
    return "";
  }
};

export const orderByDate = (
  models,
  field = ORDER_BY_FIELDS.CREATED_AT,
  order = ORDER_BY_OPTIONS.DESC
) => {
  const dates = models.map((model) => {
    let ts;
    try {
      ts = parseISO(model[field]);
    } catch (error) {
      console.error("Order by date", error);
    }
    return {
      ...model,
      ts
    };
  });
  const sorted = dates.sort((a, b) =>
    order === ORDER_BY_OPTIONS.DESC
      ? compareDesc(a.ts, b.ts)
      : compareAsc(a.ts, b.ts)
  );

  return sorted.map(({ ts, ...rest }) => rest);
};

/**
 * If theres no need to account for time
 * - first adjust time to be the selected date at local midnight
 * - then convert to UTC
 * Else we use the date as is
 * @param {Date} selectedDate
 * @returns
 */
export const getDatepickerAdjustedUTC = ({ showTime, date }) => {
  if (showTime) {
    return date.toISOString();
  } else {
    const dateFormat = format(date, MOBILE_TS_FORMAT.DATE);
    const atMidnight = new Date(`${dateFormat}T00:00`);
    return new Date(atMidnight).toISOString();
  }
};

/**
 * Dates in this format YYYY-MM-DD need to have their time value set to midnight
 * - Otherwise they will default to now time which is incorrect when you want to select a date
 * Examples:
 *  Leading zero on date - not midnight
 *  new Date('2022-05-27')
 *  - Thu May 26 2022 17:00:00 GMT-0700 (Pacific Daylight Time)
 *  Leading zero on date BUT with time - then forced midnight
 *  new Date('2022-05-27T00:00')
 *  - Fri May 27 2022 00:00:00 GMT-0700 (Pacific Daylight Time)
 */
export const getDateInputAdjustedUTC = ({ showTime, ts }) => {
  const adjusted = showTime ? ts : `${ts}T00:00`;
  return new Date(adjusted).toISOString();
};
