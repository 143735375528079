// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export const SUBSCRIPTION_STATUSES = {
  // Valid
  ACTIVE: "active",
  TRIALING: "trialing",
  // Can bill
  PAST_DUE: "past_due",
  UNPAID: "unpaid",
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXPIRED: "incomplete_expired",
  ACTION_REQUIRED: "action_required",
  // Can't bill
  INACTIVE: "inactive",
  CANCELED: "canceled",
  // Suspended
  SUSPENDED: "suspended"
};

export const GOOD_STANDING_SUBSCRIPTION_STATUSES = [
  SUBSCRIPTION_STATUSES.ACTIVE,
  SUBSCRIPTION_STATUSES.TRIALING
];

export const NEEDS_ATTENTION_SUBSCRIPTION_STATUS = [
  SUBSCRIPTION_STATUSES.PAST_DUE,
  SUBSCRIPTION_STATUSES.UNPAID,
  SUBSCRIPTION_STATUSES.INCOMPLETE,
  SUBSCRIPTION_STATUSES.INCOMPLETE_EXPIRED,
  SUBSCRIPTION_STATUSES.ACTION_REQUIRED
];

export const INVALID_SUBSCRIPTION_STATUS = [
  SUBSCRIPTION_STATUSES.SUSPENDED,
  SUBSCRIPTION_STATUSES.INACTIVE,
  SUBSCRIPTION_STATUSES.CANCELED
];

export const SUSPENDED_SUBSCRIPTION_STATUS = [SUBSCRIPTION_STATUSES.SUSPENDED];

export const LICENSE_STATUSES = {
  INACTIVE: "inactive",
  ACTIVE: "active",
  SUSPENDED: "suspended",
  CANCELED: "canceled"
};

export const SUBSCRIPTION_SCHEDULE_COPY = {
  INSTALLMENTS: {
    ENABLED:
      "Allow your customer to pay in installments by setting the expected number of payments to be made. The payment frequency and amount is derived from the recurring prices present at checkout.",
    ITERATIONS_LABEL: "Number of payments.",
    DISABLED:
      "Installment payments can only be enabled when checkout contains at least one recurring price.",
    FOOTER:
      "Installment payments will automatically stop after your customer has paid the defined number of payments."
  }
};

const INSTALLMENTS = "installments";

export const SUBSCRIPTION_SCHEDULE_TEMPLATE_TYPE = {
  INSTALLMENTS
};

const INSTALLMENTS_TEMPLATE = {
  end_behavior: "cancel",
  proration_behavior: "create_prorations",
  phases: [
    {
      iterations: 1
    }
  ]
};

export const SUBSCRIPTION_SCHEDULE_TEMPLATE = {
  [SUBSCRIPTION_SCHEDULE_TEMPLATE_TYPE.INSTALLMENTS]: INSTALLMENTS_TEMPLATE
};
