import { trimStart } from "lodash";
import compact from "lodash/compact";
import { EMBED_LAYOUT, EMBED_LAYOUT_KEYS } from "utils/constants/embed";
import { getAppUrlVariations } from "./hooks/application";
import { getLiveManifestResourceUrl } from "./manifest";
import { getManifestLiveVariation } from "./mapStateToProps";

export const getQueryEmbedEnabled = (query) =>
  Boolean(query && (Boolean(query.embed) || query.embed === "true"));

export const getEmbedParams = (query) => ({
  enabled: getQueryEmbedEnabled(query),
  layout: EMBED_LAYOUT_KEYS[query.embed_layout] || EMBED_LAYOUT.SPLIT
});

/**
 * Note: need to double escapte the regex so that it's preserved in the string
 * @param {Object} params
 * @param {String} params.id - id of the node
 * @returns
 */
const CANCEL_URL = "cancel_url";
const getExtendQSSnippet = () => `if (search) {
     target.src = target.src + '&' + search;
   }`;
const getIncludeCancelUrlSnippet = () =>
  `target.src = target.src + '&${CANCEL_URL}=' + encodeURIComponent(window.location.href);`;

/**
 * A. Add additional query string params
 * B. include the current location as a cancel_url
 */
const getDynamicSnippet = ({ id, extendQS, includeCancelUrl }) =>
  `<script>
 try {
   const target = document.getElementById('${id}');
   ${
     extendQS
       ? `const search = window.location.search.replace(/^\\?/, '');`
       : ""
   }
   if (!target) {
     throw new Error('${id} target not found')
   }
   ${extendQS ? getExtendQSSnippet() : ""}
   ${includeCancelUrl ? getIncludeCancelUrlSnippet() : ""}
 } catch (err) {
   console.error(err);
 }
 </script>`;

export const getEmbedSnippet = ({
  url,
  layout,
  id,
  params: { extendQS, includeCancelUrl }
}) => {
  return compact([
    `<script type='text/javascript' src='https://priceblocs.com/js/iframeResizer.min.js'></script>`,
    `<iframe id='${id}' src='${url}?embed=true&embed_layout=${layout}' frameborder='0' scrolling='no' width='100%'></iframe>`,
    extendQS || includeCancelUrl
      ? getDynamicSnippet({ id, extendQS, includeCancelUrl })
      : null,
    `<script type='text/javascript'>iFrameResize({ log: false, checkOrigin: false, heightCalculationMethod: 'taggedElement' }, '#${id}')</script>`
  ]).join("");
};

export const getEmbedInitialValues = ({ manifest, application }) => {
  const urlVariations = getAppUrlVariations({ application });
  const manifestLiveVariation = getManifestLiveVariation({
    id: manifest.id,
    application
  });

  const liveUrl = getLiveManifestResourceUrl({
    application,
    urlVariations,
    liveVariation: manifestLiveVariation
  });

  return {
    url: liveUrl,
    id: `${urlVariations.key}__${trimStart(manifestLiveVariation.route, "/")}`,
    layout: EMBED_LAYOUT.SINGLE,
    params: {
      extendQS: false,
      includeCancelUrl: true
    }
  };
};
