import omit from "lodash/omit";
import compact from "lodash/compact";
import minBy from "lodash/minBy";
import uniq from "lodash/uniq";
import get from "lodash/get";
import isArray from "lodash/isArray";
import reduce from "lodash/reduce";
import map from "lodash/map";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import pick from "lodash/pick";
import deepmerge from "deepmerge";
import { SCHEMA_CATEGORY } from "utils/constants/schema";
import { ENV, ENV_VALUES } from "utils/constants/env";
import {
  RECURRING_INTERVALS,
  INTERVAL_LABELS_MAP,
  PAGE_ACTION_UIDS,
  DEFAULT_FEATURE_LIST_TITLE,
  DEFAULT_FEATURES_COMMON_CONFIG,
  DEFAULT_PRICING_CONFIG,
  CLUSIVITY_TYPES,
  PRICING_TABLE_PRODUCT_LIMIT_MAX,
  SCHEMA_TYPES,
  KEY_TYPES,
  LICENSE_TYPE,
  PRICING_TABLE_PRODUCT_LIMIT_START
} from "./constants";
import ENTITIES from "./constants/entity";
import { getInterpolatedValue } from "./template";
import { CURRENCY_CODES } from "./constants/currency";
import { formatPriceMonthly, formatPriceMonthlyNoCurrency } from "./currency";
import { getIsShortLinkSetupMode, getShortLinkImages } from "utils/shortLink";
import { shortLinkCheckoutPreview } from "./preview";
import { reducePageMinimumData } from "utils/manifest";
import { PAYMENT_ACTION } from "utils/constants/payment";
import { EDITOR_PRESENTATION, EDITOR_FLOW } from "utils/constants/editor";
import { METADATA_PAIR_ID, TEST_RESOURCE_ID_RE } from "utils/constants/stripe";
import { getUUID } from "utils/uuid";
import { CONTENT_DATA, ENTITY_DATA, STATE_KEYS } from "utils/constants/state";
import {
  MANIFEST_CONTEXT_TYPE,
  MANIFEST_LOGIC_TYPE
} from "utils/constants/manifest";
import { composeCheckoutConfigsToShortLink } from "utils/checkout/merge";

const VALUE_KEY = "value";
const QUERY_KEY = "query";

const NO_UUID_KEYS = [QUERY_KEY];

export const ensureUUID = (data) => {
  return Object.keys(data).reduce((memo, key) => {
    const isUUID = key === "uuid";
    const value = data[key];
    let processedVal = value;

    if (Array.isArray(value)) {
      processedVal = value.map((val) => ensureUUID(val));
    } else if (typeof value === "object") {
      processedVal = ensureUUID(value);
    } else if (isUUID && !value) {
      processedVal = getUUID();
    }
    memo[key] = processedVal;

    return memo;
  }, {});
};

const getFirstSchemaCollectionKey = (schemaFields) =>
  Object.keys(omit(schemaFields, ["uuid"]))[0];
/**
 * ===================================
 * Schema rules
 * ===================================
 *
 * Value setting
 * - DIRECTLY set - [default]
 * -- e.g user types into an input field
 * - INDIRECTLY set - [hidden]
 * -- e.g user adds a product to pricing table and its uid gets set
 * -- but the user doesn't type in the actual uid
 * - NOT set - computed in memory - [virtual]
 * -- e.g formatted price value computed at runtime
 *
 * Persistence
 * - IS persisted - [default, hidden]
 * - NOT persisted - [virtual]
 */

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

const combineMerge = (target, source, options) => {
  const destination = target.slice();

  source.forEach((item, index) => {
    if (typeof destination[index] === "undefined") {
      destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
    } else if (options.isMergeableObject(item)) {
      destination[index] = deepmerge(target[index], item, options);
    } else if (target.indexOf(item) === -1) {
      destination.push(item);
    }
  });
  return destination;
};

export const mergeReplace = (base, overwrite) => {
  return deepmerge(base, overwrite, { arrayMerge: overwriteMerge });
};

export const combineReplace = (base, overwrite) => {
  return deepmerge(base, overwrite, { arrayMerge: combineMerge });
};

/**
 * schemaPropertiesToValues
 * - Note: we let date types default to empty strings so the client can take care of the today date in the absence of a value
 * @param {Object} properties
 * @param {Array} categoryKeys
 * @returns
 */
export const schemaPropertiesToValues = (
  properties,
  categoryKeys = [SCHEMA_CATEGORY.DEFAULT, SCHEMA_CATEGORY.HIDDEN]
) => {
  if (isArray(properties)) {
    return map(properties, (val) => {
      const defaultExists = typeof val.default !== "undefined";
      if (categoryKeys.indexOf(val.category) > -1) {
        if ([SCHEMA_TYPES.STRING, SCHEMA_TYPES.DATE].indexOf(val.type) > -1) {
          return defaultExists ? val.default : "";
        } else if (val.type === SCHEMA_TYPES.BOOLEAN) {
          return defaultExists ? val.default : false;
        } else if (val.type === SCHEMA_TYPES.NUMBER) {
          return defaultExists ? val.default : 0;
        } else if (val.type === SCHEMA_TYPES.ARRAY) {
          const itemProps = val.items.properties;
          if (itemProps) {
            return defaultExists
              ? val.default
              : [schemaPropertiesToValues(itemProps)];
          } else if (isArray(val.items)) {
            return defaultExists
              ? val.default
              : [schemaPropertiesToValues(val.items)];
          } else {
            return [];
          }
        } else if (val.type === SCHEMA_TYPES.OBJECT) {
          return schemaPropertiesToValues(val.properties);
        }
      }
    });
  } else {
    return reduce(
      properties,
      (memo, val, key) => {
        const defaultExists = typeof val.default !== "undefined";
        if (categoryKeys.indexOf(val.category) > -1) {
          if ([SCHEMA_TYPES.STRING, SCHEMA_TYPES.DATE].indexOf(val.type) > -1) {
            memo[key] = defaultExists ? val.default : "";
          } else if (val.type === SCHEMA_TYPES.BOOLEAN) {
            memo[key] = defaultExists ? val.default : false;
          } else if (val.type === SCHEMA_TYPES.NUMBER) {
            memo[key] = defaultExists ? val.default : 0;
          } else if (val.type === SCHEMA_TYPES.ARRAY) {
            const itemProps = val.items.properties;
            if (itemProps) {
              memo[key] = defaultExists
                ? val.default
                : [schemaPropertiesToValues(itemProps)];
            } else if (isArray(val.items)) {
              memo[key] = defaultExists
                ? val.default
                : schemaPropertiesToValues(val.items);
            } else {
              memo[key] = [];
            }
          } else if (val.type === SCHEMA_TYPES.OBJECT) {
            memo[key] = schemaPropertiesToValues(val.properties);
          }

          if (key === VALUE_KEY) {
            memo.default = memo[key];
          }
        }
        if (NO_UUID_KEYS.indexOf(key) === -1) {
          memo.uuid = getUUID();
        }

        return memo;
      },
      {}
    );
  }
};

export const pricesProductUIDs = (prices) =>
  uniq(prices.map((price) => price.product));

const getFeatureListTitle = (uid, config) =>
  get(config, `${uid}.title`, DEFAULT_FEATURE_LIST_TITLE);

const getFeatureListItem = ({ feature, isActive, prodConfig }) => {
  const result = {
    title: feature.title,
    uuid: feature.uuid,
    active: isActive
  };

  if (prodConfig && prodConfig.value) {
    result.tooltip = compact([prodConfig.value, prodConfig.tooltip]).join(". ");
  }

  return result;
};

const getOrderConfig = (order) =>
  order.reduce(
    (memo, orderConfig) => {
      if (orderConfig.active) {
        memo.orderKeys.push(orderConfig.key);
        memo.orderValues.push(orderConfig.value);
      }
      return memo;
    },
    {
      orderKeys: [],
      orderValues: []
    }
  );

const applyLayoutToCollection = (items, layout) =>
  layout.reduce((memo, layoutConfig) => {
    const itemMatch = items.find((item) => item.uuid === layoutConfig.uuid);
    if (itemMatch) {
      memo.push(itemMatch);
    }

    return memo;
  }, []);

const getAdditionalItem = (count) => ({
  title: `And ${count} more`,
  hideIcon: true,
  active: true
});

const reduceProductFeatures = ({
  config,
  features,
  product,
  preceding,
  clusivity,
  entitlements
}) => {
  const inclusive = clusivity === CLUSIVITY_TYPES.INCLUSIVE;
  const incremental = clusivity === CLUSIVITY_TYPES.INCREMENTAL;
  const exclusive = clusivity === CLUSIVITY_TYPES.EXCLUSIVE;

  return features.reduce(
    (memo, feature) => {
      if (!feature.title) {
        throw new Error(`Feature title missing for ${feature}`);
      }
      const prodConfig = get(entitlements, `${feature.uuid}.${product.id}`);

      const isActive = Boolean(prodConfig && prodConfig.enabled);

      const activeExclusive = isActive && exclusive;
      const activeIncremental = isActive && incremental;

      if (activeIncremental) {
        /**
         * If there is a preceding
         * - only add to the list if its active for the product but not active in the preceding
         */
        if (preceding) {
          const precedingAlsoActive = Boolean(
            get(entitlements, `${feature.uuid}.${preceding.id}.enabled`)
          );
          if (!precedingAlsoActive) {
            memo.title = `Everything in ${preceding.name} plus`;
            memo.items.push(
              getFeatureListItem({ feature, isActive, prodConfig })
            );
          }
        } else {
          memo.items.push(
            getFeatureListItem({ feature, isActive, prodConfig })
          );
        }
      } else if (inclusive || activeExclusive) {
        memo.items.push(getFeatureListItem({ feature, isActive, prodConfig }));
      }

      return memo;
    },
    {
      title: getFeatureListTitle(product.id, config.features),
      items: []
    }
  );
};

/**
 *
 * @param {Object} params
 * @param {Object} params.product - product in context
 * @param {Object} params.config - manifest configg
 * @param {Object} params.preceding - product before one listed
 * @param {Array<{uid: String, title: String}>} features  - collection of features
 */
export const formatProductFeatures = (
  { product, config, preceding, entitlements },
  features = []
) => {
  const limitValue = parseInt(get(config, "features.common.limit"));
  const limit = Number.isNaN(limitValue) ? null : limitValue;
  const order = get(config, "features.common.order");
  const layout = get(config, `features[${product.id}].layout`);
  const clusivity = get(config, "features.common.clusivity");
  const show = get(config, "features.common.show");
  const incremental = clusivity === CLUSIVITY_TYPES.INCREMENTAL;

  if (!show) {
    return null;
  } else {
    const result = reduceProductFeatures({
      config,
      features,
      product,
      preceding,
      clusivity,
      entitlements
    });
    let items = result.items;
    /**
     * Apply ordering if present
     */
    if (order) {
      const { orderKeys, orderValues } = getOrderConfig(order);
      items = orderBy(result.items, orderKeys, orderValues);
    }
    const itemsCount = items.length;
    /**
     * IF a layout is present then reorder by the layout and append any "And N more"
     * ELSE IF a limit is present then append the "And N more" label
     */
    let resultItems = [...items];
    if (layout && layout.length) {
      resultItems = applyLayoutToCollection(items, layout);

      const difference = items.length - layout.length;
      if (difference) {
        resultItems.push(getAdditionalItem(difference));
      }
    } else if (limit > 0 && itemsCount > limit) {
      resultItems = items.slice(0, limit);

      const difference = itemsCount - limit;
      if (difference) {
        resultItems.push(getAdditionalItem(difference));
      }
    } else if (limit === 0) {
      resultItems = [];
    }
    result.items = resultItems;

    const incrementalEmptyTitle =
      result.items.length === 0 && incremental && preceding && preceding.name;
    if (incrementalEmptyTitle) {
      result.title = `No greater features than in ${preceding.name}`;
    }

    return result;
  }
};

/**
 * TODO: the return shape should be driven by the schema if present
 * @param {string} value
 * @param {null|object} schema
 */
const getSubtextValueWithForSchema = (value, schema) =>
  schema
    ? {
        copy: {
          value,
          template: value
        }
      }
    : value;

export const formatPricesSubtext = ({
  prices,
  interval,
  licenseType,
  schema
}) => {
  const remainingPrices = compact(Object.values(omit(prices, [interval])));

  const licenseTypeString =
    licenseType && licenseType === LICENSE_TYPE.GROUP ? " per person," : "";

  const collection = [];
  remainingPrices.forEach((remPrice) => {
    const priceValue = `${formatPriceMonthlyNoCurrency(
      remPrice
    )}${licenseTypeString} per month, when billed ${
      INTERVAL_LABELS_MAP[remPrice.interval]
    }.`;

    collection.push(getSubtextValueWithForSchema(priceValue, schema));
  });

  return compact(collection);
};

const getPrimaryIntervalPrices = ({ prices, currency }) => {
  return Object.values(RECURRING_INTERVALS).reduce((memo, interval) => {
    memo[interval] = prices.find(
      (price) =>
        price.interval === interval &&
        price.primary &&
        price.currency === currency
    );
    return memo;
  }, {});
};

const getPrimaryPriceForAction = ({
  prices,
  primaries,
  defaultInterval,
  defaultCurrency
}) =>
  prices.find(({ id, interval, primary }) => {
    return primaries.find((configPrimary) => {
      return (
        defaultInterval === interval &&
        defaultInterval === configPrimary.interval &&
        defaultCurrency === configPrimary.currency &&
        primary &&
        id === configPrimary.price
      );
    });
  });

const extendActionWithCheckoutParams = ({
  product,
  config,
  action,
  admin,
  checkout
}) => {
  const result = { ...action };
  const { subdomain, account } = admin;

  const { defaultInterval, defaultCurrency, primaries } = config.pricing;

  const primaryPriceForAction = getPrimaryPriceForAction({
    prices: product.prices,
    primaries,
    defaultInterval,
    defaultCurrency
  });

  /**
   * If the action is Stripe checkout
   * - AND primary price
   * - AND subdomain are present is present
   * - THEN attach the price to the query
   */
  const checkoutEnabled =
    primaryPriceForAction && account.chargesEnabled && account.detailsSubmitted;
  if (
    result.action_uid === PAGE_ACTION_UIDS.STRIPE_CHECKOUT &&
    checkoutEnabled
  ) {
    if (!subdomain) {
      throw new Error(`Subdomain required to extend checkout action`);
    }
    result.query = {
      [PAGE_ACTION_UIDS.STRIPE_CHECKOUT]: primaryPriceForAction.id,
      subdomain: admin.subdomain
    };
    if (admin.alias) {
      result.query.alias = admin.alias;
    }
    if (checkout) {
      result.query.checkout = checkout;
    }
  }

  return result;
};

export const recomputeProductActions = ({
  product,
  config,
  actions,
  admin,
  checkout
}) => {
  return actions.map((action) => {
    return extendActionWithCheckoutParams({
      product,
      config,
      action,
      admin,
      checkout
    });
  });
};

export const formatProductActions = ({
  product,
  config,
  schemaFields,
  admin,
  checkout
}) => {
  const result = extendActionWithCheckoutParams({
    product,
    config,
    action: schemaFields,
    admin,
    checkout
  });

  return [result];
};

export const formatPrimaryPrices = ({ product }) =>
  product.prices.filter((price) => price.primary);

export const formatTitle = ({ product, config }) => {
  return product.name;
};

export const formatDescription = ({ product, config }) => {
  return product.description;
};

export const recomputePricingTableProducts = ({
  products,
  config,
  schema,
  admin,
  checkout,
  entitlements
}) => {
  const defaultInterval = get(config, `pricing.defaultInterval`);
  const defaultCurrency = get(config, `pricing.defaultCurrency`);
  const licenseType = get(config, `pricing.licenseType`);

  const result = products.map((product) => {
    const primaryIntervalPrices = getPrimaryIntervalPrices({
      prices: product.prices,
      currency: defaultCurrency
    });
    const primaryPrice = primaryIntervalPrices[defaultInterval];

    const subtextInput = {
      prices: primaryIntervalPrices,
      interval: defaultInterval,
      licenseType: licenseType,
      schema: get(schema, "subtext[0]")
    };

    return {
      ...product,
      actions: recomputeProductActions({
        product,
        config,
        actions: product.actions,
        admin,
        checkout
      }),
      /**
       * Virtual
       */
      subtext: licenseType && formatPricesSubtext(subtextInput),
      formattedPrice: primaryPrice ? formatPriceMonthly(primaryPrice) : ""
    };
  });

  return result;
};

const computePricingTableProduct = ({
  product,
  defaultInterval,
  defaultCurrency,
  features,
  config,
  schemaFields,
  preceding,
  admin,
  checkout,
  entitlements
}) => {
  const primaryIntervalPrices = getPrimaryIntervalPrices({
    prices: product.prices,
    currency: defaultCurrency
  });
  const licenseType = get(config, `pricing.licenseType`);

  const primaryPrice = primaryIntervalPrices[defaultInterval];
  const actions = formatProductActions({
    product,
    config,
    schemaFields: get(schemaFields, "actions[0]"),
    admin,
    checkout
  });

  const subtextInput = {
    prices: primaryIntervalPrices,
    interval: defaultInterval,
    licenseType,
    schema: get(schemaFields, "subtext[0]")
  };

  return {
    // Real
    uid: product.id,
    /**
     * NOTE: product key of name is renamed to title to avoid conflict with client usage of name key passed to form level components
     */
    title: formatTitle({ product, config }),
    description: formatDescription({ product, config }),
    actions,
    subtext: licenseType && formatPricesSubtext(subtextInput),
    // ** Virtual candidate - store test only and control presentation via configuration
    // - Slate mentions API / markdown formatting API
    /**
     * Virtual
     */
    formattedPrice: primaryPrice ? formatPriceMonthly(primaryPrice) : "",
    features: formatProductFeatures(
      { product, config, preceding, entitlements },
      features
    ),
    // TODO: return bare minimum price keys
    prices: formatPrimaryPrices({ product, config })
  };
};

/**
 * @param {Array} products
 * @param {Object} config
 */
export const buildPricingTableProducts = ({
  products,
  config,
  features,
  schemaFields,
  admin,
  checkout,
  entitlements
}) => {
  const defaultInterval = get(config, `pricing.defaultInterval`);
  const defaultCurrency = get(config, `pricing.defaultCurrency`);

  const result = products.map((product, productIx) => {
    return computePricingTableProduct({
      product,
      defaultInterval,
      defaultCurrency,
      config,
      features,
      schemaFields,
      preceding: products[productIx - 1],
      admin,
      checkout,
      entitlements
    });
  });

  return result;
};

const getProductPricesWithMinAmount = (products) =>
  products.reduce((memo, product) => {
    const minPrice =
      minBy(product.prices, "amount") || minBy(product.prices, "unit_amount");

    if (minPrice) {
      memo.push({
        id: minPrice.id,
        product: minPrice.product,
        amount: minPrice.amount
      });
    }
    return memo;
  }, []);

const getPricesWithProduct = (prices, products) =>
  prices.reduce((memo, price) => {
    const productMatch = products.find(({ id }) => id === price.product);

    if (productMatch) {
      memo.push(productMatch);
    } else {
      throw new Error(
        `Cant find product: ${price.product} for price: ${price.id}`
      );
    }
    return memo;
  }, []);

const orderProductsByLayout = (layout, prices, products) =>
  layout.reduce((memo, { uid }) => {
    const productPricesMatch = prices.find(({ id }) => id === uid);
    const productMatch = products.find(({ id }) => id === uid);

    if (productPricesMatch) {
      memo.push(productPricesMatch);
    } else if (productMatch) {
      memo.push({
        name: productMatch.name,
        id: productMatch.id,
        prices: []
      });
    }

    return memo;
  }, []);

/**
 * Default order products by amount ascending (cheapest -> expensive)
 * Override filter and order by layout if present
 * @param {Array} products - collection of Stripe products and prices
 * @param {Array} layout - array of ordered product uids
 */
export const getOrderedProducts = (products, layout) => {
  const minPrices = getProductPricesWithMinAmount(products);
  const lowToHighPrices = orderBy(minPrices, ["amount"], ["asc"]);

  const productPrices = getPricesWithProduct(lowToHighPrices, products);

  /**
   * If there is a layout set in configuration
   * Use it to order the products
   */
  if (layout && layout.length) {
    return orderProductsByLayout(layout, productPrices, products);
  } else {
    return productPrices;
  }
};

export const transformPrice = (
  { id, currency, product, unit_amount, recurring },
  extra
) => ({
  id,
  currency,
  product,
  // TODO: remove this mapping and update the client code
  amount: unit_amount,
  interval: recurring ? recurring.interval : null,
  ...extra
});

const getIsPricePrimary = (price, primaries) => {
  const productPrimaries = primaries.filter(
    (prim) => prim.product === price.product
  );

  return Boolean(
    productPrimaries &&
      productPrimaries.find((primaryPrice) => primaryPrice.price === price.id)
  );
};

export const extendProductsWithPrices = (products, prices, primaries) => {
  return products.reduce((memo, { id, name, description }) => {
    const productPrices = prices
      .filter((price) => price.product === id)
      .map((price) => {
        return transformPrice(price, {
          primary: getIsPricePrimary(price, primaries)
        });
      });

    memo.push({
      id,
      name,
      description,
      prices: productPrices
    });

    return memo;
  }, []);
};

const fillSchemaArraysWithData = (schema, data, variables) => {
  return data.map((data) => {
    return reduce(
      schema,
      (memo, _, key) => {
        const schemaVal = schema[key];
        return processSchema(memo, schemaVal, data, key, variables);
      },
      {}
    );
  });
};

export const fillSchemaObjectsWithData = (schema, data, variables) => {
  return reduce(
    schema,
    (memo, schemaVal, key) => {
      return processSchema(memo, schemaVal, data, key, variables);
    },
    {}
  );
};

const processSchema = (memo, schema, data, key, variables) => {
  const values = data[key];
  /**
   * Allow empty / falsy values but omit undefined values
   */
  if (typeof values !== "undefined") {
    const arrayOfSchema = Array.isArray(schema);
    const arrayOfValues = Array.isArray(values);
    const processArrays = arrayOfSchema && arrayOfValues;
    const processObject = typeof schema === "object";

    if (processArrays) {
      memo[key] = fillSchemaArraysWithData(schema[0], values, variables);
    } else if (processObject) {
      memo[key] = fillSchemaObjectsWithData(schema, values, variables);
    } else {
      /**
       * Allow unsetting of the value by not checking if data[key] is truthy
       * within this else clause
       * Otherwise the user will clear a field and the default value will render and not an empty string etc.
       * Or setting an empty collection (e.g actions) without being able to have it applied
       * This would be a bad UX
       */
      memo[key] = values;
      if (key === KEY_TYPES.TEMPLATE) {
        memo.value = getInterpolatedValue({
          value: values,
          variables
        });
      }
    }
  }

  return memo;
};

/**
 * [User triggered from the preview page]
 * Update the formatted price the subtext and the action sessions
 * @param {Object} props
 */
export const recomputePricingTableContent = (props) => {
  const { entity, config, data, admin, checkout, entitlements } = props;
  const schemaFields = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );
  const COLLECTION_KEY = getFirstSchemaCollectionKey(schemaFields);

  const computedProductValues = recomputePricingTableProducts({
    products: data.products,
    config,
    schema: schemaFields,
    admin,
    checkout,
    entitlements
  });

  return {
    [COLLECTION_KEY]: computedProductValues
  };
};

/**
 * [User triggered from the preview page]
 * Update the formatted price the subtext and the action sessions
 * @param {Object} props
 */
export const recomputeFeatureComparisonContent = (props) => {
  const { config, data, admin, checkout, products, prices } = props;
  const primaries = get(config, "pricing.primaries");

  const extendedProducts = extendProductsWithPrices(
    products,
    prices,
    primaries
  );

  const resolvedCollection = getResolvedCollectionForFeatureComparison({
    ...props,
    features: [],
    variables: []
  });

  const header = resolvedCollection.map((product) => {
    const actionInput = {
      product: {
        ...product,
        prices: extendedProducts.find(({ id }) => id === product.uid).prices
      },
      config,
      actions: product.actions,
      admin,
      checkout
    };

    return {
      ...product,
      actions: recomputeProductActions(actionInput)
    };
  });

  return {
    ...data,
    header
  };
};

/**
 * Ensure that actions configured to use stripe checkout sessions do so
 * @param {Object} props
 * @param {Array} props.collection - collection of products
 * @param {Object} props.config - manifest configuration
 */
const extendCollectionWithSessionActions = ({
  collection,
  config,
  admin,
  checkout
}) =>
  collection.map((resultProduct) => {
    return {
      ...resultProduct,
      actions: resultProduct.actions.map((action) => {
        return extendActionWithCheckoutParams({
          product: resultProduct,
          config,
          action,
          admin,
          checkout
        });
      })
    };
  });

/**
 *
 * @param {Object} param
 * @param {Object} param.initialData - default generated shape of the pricing table to be extended with whatever persisted values the user has (e.g. a set title overwrites default title)
 * @param {Object} param.data - the data to be used to overwrite the default pricing table
 * @param {Object} param.schema - the reduced entity schema for default and hidden fields
 * @param {Array} param.variables - variables to be used for value interpolation
 */
const getMergedInterpolatedPricingTable = ({
  initialData,
  persistedData,
  schema,
  variables
}) => {
  /**
   * This step will interpolate any template variables
   */
  const values = fillSchemaObjectsWithData(schema, persistedData, variables);

  return reduce(
    initialData,
    (memo, val, key) => {
      if (Array.isArray(val)) {
        memo[key] = val.reduce((memo, valData) => {
          const productMatch =
            values[key] && values[key].find(({ uid }) => valData.uid === uid);
          if (productMatch) {
            /**
             * Merge replace so that we can unset collections like actions etc.
             */
            memo.push(mergeReplace(valData, productMatch));
          }
          return memo;
        }, []);
      } else {
        memo[key] = memo.push(mergeReplace(val, values[key]));
      }

      return memo;
    },
    {}
  );
};

/**
 * Check if we need to add any products which are
 * - in layout but
 * - NOT in the result collection
 * This can happen between removing and adding products to the layout via drag and drop
 * - we need to
 * -- find the missing product
 * -- add it to the collection
 * -- re-order the collection by layout property
 * Then ultimately filter by the layout which is the control variable
 * @param {Object} params
 * @param {Object} params.collection - the merged persisted result of the pricing table
 * @param {Object} params.products - all eligible computed products to be checked for missing products
 * @param {Object} params.layout - the pricing layout to enforce
 */
const resolveLayoutDifferences = (props) => {
  const { collection, products, layout } = props;
  let result = [...collection];

  const productUIDs = result.map(({ uid }) => uid);
  const missingProducts = products.filter(
    ({ uid }) => productUIDs.indexOf(uid) === -1
  );

  if (missingProducts.length) {
    const updatedProductsSet = result.concat(missingProducts);
    /**
     * Any additions will require a reordering as described in the layout
     */
    if (Array.isArray(layout)) {
      result = layout.reduce((memo, { uid }) => {
        const productMatch = updatedProductsSet.find(
          (product) => product.uid === uid
        );
        if (productMatch) {
          memo.push(productMatch);
        }
        return memo;
      }, []);
    } else {
      result = updatedProductsSet;
    }
  }

  return result.filter((product) =>
    Boolean(layout.find(({ uid }) => uid === product.uid))
  );
};

const getComputedPricingTableValues = ({
  schema,
  config,
  products,
  prices,
  features,
  layout,
  primaries,
  admin,
  checkout,
  entitlements
}) => {
  const extendedProducts = extendProductsWithPrices(
    products,
    prices,
    primaries
  );
  const orderedProducts = getOrderedProducts(extendedProducts, layout);
  return buildPricingTableProducts({
    products: orderedProducts,
    config,
    features,
    schemaFields: schema,
    admin,
    checkout,
    entitlements
  });
};

/**
 * 1. Generate defaults
 * 2. Overwrite with present values
 * 3. Resolve layout differences
 * 4. Extend actions with session params
 */
export const getPricingTableContent = (props) => {
  const {
    entity,
    products,
    prices,
    config,
    content,
    features,
    variables,
    admin,
    checkout,
    entitlements
  } = props;
  const schemaFields = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );
  const layout = get(config, "pricing.layout");
  const primaries = get(config, "pricing.primaries");
  const COLLECTION_KEY = getFirstSchemaCollectionKey(schemaFields);
  const contentPath = `${entity.uuid}.data`;
  const data = get(content, contentPath);
  const schemaKey = `${COLLECTION_KEY}[0]`;

  /**
   * 1. Generate defaults
   */
  const computedPricingTableValues = getComputedPricingTableValues({
    schema: get(schemaFields, schemaKey),
    config,
    products,
    prices,
    features,
    layout,
    primaries,
    admin,
    checkout,
    entitlements
  });

  const computedPricingTable = {
    [COLLECTION_KEY]: computedPricingTableValues
  };

  /**
   * Persisted values > computed values
   * - So merge them
   * - Note: Persisted values are ones entered by the user already like product title / description etc
   */
  const persistedData = isEmpty(data) ? computedPricingTable : data;

  /**
   * 2. Overwrite with present values
   * Merge the collections and process any template fields
   */
  const mergeInterpolateInput = {
    initialData: computedPricingTable,
    persistedData: persistedData,
    schema: schemaFields
  };
  const result = getMergedInterpolatedPricingTable({
    ...mergeInterpolateInput,
    variables
  });

  /**
   * 3. Resolve layout differences
   * Check if we need to add back products which are present in layout but present in the persisted collection
   */
  const resolvedLayout = resolveLayoutDifferences({
    collection: result[COLLECTION_KEY],
    products: computedPricingTableValues,
    layout
  });

  result[COLLECTION_KEY] = resolvedLayout;

  /**
   * 4. Extend actions with session params
   * Ensure that the actions have up to date stripe_checkout session values
   * - Session values need to be regenerated on every request to avoid them being stale
   */
  result[COLLECTION_KEY] = extendCollectionWithSessionActions({
    collection: result[COLLECTION_KEY],
    config,
    admin,
    checkout
  });

  return result;
};

export const getBannerContent = ({ entity, content }) => {
  const currentValues = get(content, `[${entity.uuid}].data`, {});
  const defaultValues = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );
  const mergedResult = mergeReplace(defaultValues, currentValues);

  return mergedResult;
};

export const getSocialProfileContent = ({ entity, content }) => {
  const currentValues = get(content, `[${entity.uuid}].data`, {});
  const defaultValues = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );
  const mergedResult = mergeReplace(defaultValues, currentValues);

  return mergedResult;
};

/**
 * =========================================
 * ShortLinks / PaymentLinks / Products
 * =========================================
 */
export const prepareProductCollectionItem = ({
  product,
  shortLink,
  products,
  prices,
  coupons,
  taxRates,
  shippingRates,
  test = false
}) => {
  const checkoutPreview = shortLinkCheckoutPreview({
    shortLink,
    products,
    prices,
    coupons,
    taxRates,
    shippingRates
  });

  const images = getShortLinkImages({
    shortLink,
    products,
    prices
  });

  const discount =
    get(checkoutPreview, "checkout.discountItems[0].value") || "";
  const label = get(checkoutPreview, "checkout.banner.label") || "";
  const sublabel =
    get(checkoutPreview, "checkout.banner.sublabel") || "One time";
  let ctxTitle = (product && product.title) || "";
  if (!ctxTitle && getIsShortLinkSetupMode(shortLink)) {
    ctxTitle = "Payment details";
  }
  const ctxDescription = (product && product.description) || "";
  const labelNote =
    get(checkoutPreview, "checkout.amountItems[0].subvalue") || "";

  return {
    uuid: get(product, "uuid"),
    title: ctxTitle,
    description: ctxDescription,
    discount,
    images,
    label,
    labelNote,
    sublabel
  };
};

export const getProductCollectionContent = ({
  entity,
  content,
  products,
  prices,
  coupons,
  taxRates,
  shippingRates
}) => {
  const currentValues = get(content, `[${entity.uuid}].data`, {});
  const schemaFields = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );

  if (isEmpty(currentValues)) {
    return ensureUUID(schemaFields);
  } else {
    const commonCheckout =
      get(
        content,
        `[${entity.uuid}].${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_CHECKOUT_COMMON}`
      ) || {};
    const productsCheckouts =
      get(
        content,
        `[${entity.uuid}].${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_PRODUCTS_CHECKOUTS}`
      ) || {};
    const mergedResult = mergeReplace(schemaFields, currentValues);

    mergedResult.products = mergedResult.products.map((product) => {
      return prepareProductCollectionItem({
        product,
        shortLink: composeCheckoutConfigsToShortLink({
          selectedCheckout: productsCheckouts[product.uuid],
          commonConfig: commonCheckout
        }),
        products,
        prices,
        coupons,
        taxRates,
        shippingRates
      });
    });

    return mergedResult;
  }
};

export const getPaymentContent = ({ entity, content }) => {
  const currentValues = get(content, `[${entity.uuid}].data`, {});
  const defaultValues = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );
  const mergedResult = mergeReplace(defaultValues, currentValues);

  return mergedResult;
};

export const getFormContent = ({ entity, content }) => {
  const currentValues = get(content, `[${entity.uuid}].data`, {});
  const schemaFields = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );

  if (isEmpty(currentValues)) {
    return ensureUUID(schemaFields);
  } else {
    return mergeReplace(schemaFields, currentValues);
  }
};

const getDefaultBrand = (admin) => ({
  src: get(admin, "profile.businessLogo"),
  url: get(admin, "profile.url")
});

export const getNavigationContent = ({ entity, content, admin, variables }) => {
  const currentValues = get(content, `[${entity.uuid}].data`, {});
  const schemaFields = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );

  if (!isEmpty(currentValues)) {
    const values = fillSchemaObjectsWithData(
      schemaFields,
      currentValues,
      variables
    );

    return mergeReplace(schemaFields, values);
  } else {
    const filledSchema = fillSchemaObjectsWithData(
      schemaFields,
      schemaFields,
      variables
    );
    const defaultNav = {
      brand: getDefaultBrand(admin)
    };
    return mergeReplace(filledSchema, defaultNav);
  }
};

export const getFooterContent = ({ entity, content, admin, variables }) => {
  const currentValues = get(content, `[${entity.uuid}].data`, {});
  const schemaFields = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );

  if (!isEmpty(currentValues)) {
    const values = fillSchemaObjectsWithData(
      schemaFields,
      currentValues,
      variables
    );

    return mergeReplace(schemaFields, values);
  } else {
    const filledSchema = fillSchemaObjectsWithData(
      schemaFields,
      schemaFields,
      variables
    );
    // TODO: make links conditional based on what the company has set in stripe
    // - add that conditionality to the checkout success and error pages
    // - add link to stripe account profile in nav and footer blocks
    const defaultFooter = {
      brand: getDefaultBrand(admin),
      sections: [
        {
          header: "Company",
          links: [
            {
              copy: "Contact Us",
              href: get(admin, "profile.supportEmail"),
              hrefType: "mailto"
            },
            {
              copy: "Support",
              href: get(admin, "profile.supportUrl"),
              hrefType: "link"
            },
            {
              copy: "Phone",
              href: get(admin, "profile.supportPhone"),
              hrefType: "tel"
            },
            {
              copy: "Home",
              href: get(admin, "profile.url"),
              hrefType: "link"
            }
          ]
        }
      ]
    };

    return mergeReplace(filledSchema, defaultFooter);
  }
};

const getResolvedCollectionForFeatureComparison = ({
  entity,
  entities,
  products,
  prices,
  config,
  content,
  admin,
  checkout,
  variables,
  features
}) => {
  const schemaFields = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );
  const primaries = get(config, "pricing.primaries");
  const layout = get(config, "pricing.layout");
  const defaultInterval = get(config, `pricing.defaultInterval`);
  const defaultCurrency = get(config, `pricing.defaultCurrency`);

  const extendedProducts = extendProductsWithPrices(
    products,
    prices,
    primaries
  );

  /**
   * Products in the feature table are derived from the pricing layout
   * - If there are no products within the pricing layuout then there are none in the feature comparison table
   */
  const orderedProducts = layout.length
    ? getOrderedProducts(extendedProducts, layout)
        .slice(0, PRICING_TABLE_PRODUCT_LIMIT_MAX)
        .map((product) =>
          computePricingTableProduct({
            product,
            defaultInterval,
            defaultCurrency,
            features,
            config,
            schemaFields: get(schemaFields, "products[0]"),
            admin,
            checkout
          })
        )
    : [];

  /**
   * Fill and interpolate the product schema
   */
  const values = fillSchemaObjectsWithData(
    { products: schemaFields.products },
    { products: orderedProducts },
    variables
  ).products;

  const hydratedProducts = orderedProducts.map((product, productIx) => ({
    ...product,
    ...values[productIx]
  }));

  return getResolveProductsCollection({
    entities,
    content,
    products: hydratedProducts
  });
};

export const getOrderedFeatureGroups = (layout = [], featureGroups) =>
  layout.reduce((memo, { uuid }) => {
    const featureGroupMatch = featureGroups.find(
      (group) => group.uuid === uuid
    );
    if (featureGroupMatch) {
      memo.push(featureGroupMatch);
    }
    return memo;
  }, []);

export const getFeatureComparisonContent = (props) => {
  const {
    entity,
    content,
    features,
    featureGroups,
    config,
    entitlements
  } = props;
  const schemaFields = schemaPropertiesToValues(
    get(entity, "schema.properties")
  );

  const orderedFeatureGroups = getOrderedFeatureGroups(
    get(config, "featureGroups.layout", []),
    featureGroups
  );

  const resolvedCollection = getResolvedCollectionForFeatureComparison(props);

  const data = {
    header: resolvedCollection.map(
      ({ title, uid, formattedPrice, actions }) => ({
        uid,
        title,
        formattedPrice,
        actions
      })
    ),
    groups: orderedFeatureGroups.map((featureGroup) => ({
      columns: reduceGroupFeatureComparisonColumns({
        products: resolvedCollection,
        initial: {
          header: featureGroup.title,
          accessor: "title"
        }
      }),
      rows: reduceGroupFeatureComparisonRows({
        featureGroup,
        features,
        products: resolvedCollection,
        entitlements
      })
    }))
  };

  const currentValues = get(content, `[${entity.uuid}].data`, {});
  /**
   * TODO: "product" key on content should be omitted - need to factor into schema design
   */
  const mergedResult = mergeReplace(schemaFields, {
    ...currentValues,
    ...data
  });

  return mergedResult;
};

const getProductCollectionConfig = (props) => {
  const { entity, content } = props;
  const defaultConfig = {
    presentation: EDITOR_PRESENTATION.SPLIT,
    /**
     * The flow of the content (i.e. standard / leftRight / rightLeft)
     */
    flow: EDITOR_FLOW.STANDARD,
    /**
     * Can be click / scan
     */
    paymentAction: PAYMENT_ACTION.CLICK,
    initialPreview: {
      show: true
    },
    checkout: {
      common: {}
    },
    products: {
      checkouts: {}
    },
    cart: {
      products: []
    }
  };

  const existingConfig = get(content, `[${entity.uuid}].config`, {});

  const mergedResult = mergeReplace(defaultConfig, existingConfig);

  return mergedResult;
};

const getFormConfig = (props) => {
  const { entity, content } = props;

  const defaultConfig = {
    brand: {
      show: true
    },
    /**
     * Initial preview section - when no checkout selections are active
     */
    initialPreview: {
      show: true
    },
    /**
     * Checkout related confi
     * - common checkout values to apply to all products
     */
    checkout: {
      common: {}
    },
    /**
     * Logic which controls what product(s) are part of checkout
     */
    logic: {
      type: MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED,
      checkouts: {
        fields: [],
        constant: null,
        combinations: []
      }
    }
  };

  const existingConfig = get(content, `[${entity.uuid}].config`, {});

  const mergedResult = mergeReplace(defaultConfig, existingConfig);

  /**
   * Ensure minimum default values for late addition keys
   */
  if (typeof mergedResult.logic.type === "undefined") {
    mergedResult.logic.type = MANIFEST_LOGIC_TYPE.CHECKOUT_REQUIRED;
  }
  if (typeof mergedResult.logic.checkouts.constant === "undefined") {
    mergedResult.logic.checkouts.constant = null;
  }

  return mergedResult;
};

const getNavigationConfig = (props) => {
  const { entity, content } = props;
  const defaultConfig = {
    brand: {
      show: true
    }
  };

  const existingConfig = get(content, `[${entity.uuid}].config`, {});

  const mergedResult = mergeReplace(defaultConfig, existingConfig);

  return mergedResult;
};

const getFooterConfig = (props) => {
  const { entity, content } = props;
  const defaultConfig = {
    brand: {
      show: true
    }
  };

  const existingConfig = get(content, `[${entity.uuid}].config`, {});

  const mergedResult = mergeReplace(defaultConfig, existingConfig);

  return mergedResult;
};

const getUI = (props) => {
  const { entity, content } = props;
  const defaultUI = {};

  const existingUI = get(content, `[${entity.uuid}].ui`, {});

  const mergedResult = mergeReplace(defaultUI, existingUI);

  return mergedResult;
};

const emptyConfig = () => ({});

const CONTENT_GENERATORS = {
  [ENTITIES.NAVIGATION]: getNavigationContent,
  [ENTITIES.BANNER]: getBannerContent,
  [ENTITIES.PRICING_TABLE]: getPricingTableContent,
  [ENTITIES.FEATURE_COMPARISON]: getFeatureComparisonContent,
  [ENTITIES.SOCIAL_PROFILE]: getSocialProfileContent,
  [ENTITIES.PRODUCT_COLLECTION]: getProductCollectionContent,
  [ENTITIES.PAYMENT]: getPaymentContent,
  [ENTITIES.FORM]: getFormContent,
  [ENTITIES.FOOTER]: getFooterContent
};

const CONFIG_GENERATORS = {
  [ENTITIES.NAVIGATION]: getNavigationConfig,
  [ENTITIES.BANNER]: emptyConfig,
  [ENTITIES.PRICING_TABLE]: emptyConfig,
  [ENTITIES.FEATURE_COMPARISON]: emptyConfig,
  [ENTITIES.SOCIAL_PROFILE]: emptyConfig,
  [ENTITIES.PRODUCT_COLLECTION]: getProductCollectionConfig,
  [ENTITIES.PAYMENT]: emptyConfig,
  [ENTITIES.FORM]: getFormConfig,
  [ENTITIES.FOOTER]: getFooterConfig
};

const UI_GENERATORS = {
  [ENTITIES.NAVIGATION]: getUI,
  [ENTITIES.BANNER]: getUI,
  [ENTITIES.PRICING_TABLE]: getUI,
  [ENTITIES.FEATURE_COMPARISON]: getUI,
  [ENTITIES.SOCIAL_PROFILE]: getUI,
  [ENTITIES.PRODUCT_COLLECTION]: getUI,
  [ENTITIES.PAYMENT]: getUI,
  [ENTITIES.FORM]: getUI,
  [ENTITIES.FOOTER]: getUI
};

export const mergePrimariesSets = (initial, subtitutes = []) => {
  const result = [...initial];

  subtitutes.forEach((substitute) => {
    const subIsValid =
      substitute.product &&
      substitute.interval &&
      substitute.currency &&
      substitute.price;

    if (subIsValid) {
      const subIx = result.findIndex((resultPrimary) => {
        return (
          resultPrimary.product === substitute.product &&
          resultPrimary.interval === substitute.interval &&
          resultPrimary.currency === substitute.currency
        );
      });

      if (subIx > -1) {
        result.splice(subIx, 1, substitute);
      } else {
        result.push(substitute);
      }
    }
  });

  return result;
};

export const getDefaultRecurringPricePrimaries = ({ products, prices }) =>
  products.reduce((memo, product) => {
    const productPrices = prices.filter(
      (price) => price.product === product.id && price.recurring && price.active
    );

    Object.values(RECURRING_INTERVALS).forEach((interval) => {
      const currencyPriceGroups = groupBy(productPrices, "currency");

      for (const currency in currencyPriceGroups) {
        const currencyPrices = currencyPriceGroups[currency];

        const intervalPrices = currencyPrices.filter(
          ({ recurring }) => recurring.interval === interval
        );
        if (intervalPrices.length) {
          const minPrice = minBy(intervalPrices, "unit_amount");

          if (minPrice) {
            memo.push({
              product: minPrice.product,
              interval,
              price: minPrice.id,
              currency: minPrice.currency
            });
          }
        }
      }
    });

    return memo;
  }, []);

export const getPricesWithRecurringInterval = (prices) =>
  prices.reduce((memo, price) => {
    const isValidInterval =
      price.interval === RECURRING_INTERVALS.MONTH ||
      price.interval === RECURRING_INTERVALS.YEAR;
    if (isValidInterval) {
      const intervalExists = memo.find(
        ({ interval }) => interval === price.interval
      );
      if (!intervalExists) {
        if (price.amount > 0) {
          memo.push(price);
        }
      }
    }
    return memo;
  }, []);

const adjustLayoutForEnterpriseProducts = (
  layout,
  products,
  limit = PRICING_TABLE_PRODUCT_LIMIT_MAX
) => {
  // Splice products from the end of the array
  const result = layout.slice(limit * -1);

  const enterpriseProduct = products.find(({ name }) =>
    /enterprise/gi.test(name)
  );

  const existingEnterpriseIndex = result.findIndex((layoutProduct) =>
    /enterprise/gi.test(layoutProduct.name)
  );

  /**
   * If Enterprise is already present in result subset, then splice and push to the end
   * Else if it exists in general, pop one off and add it to the end
   */
  if (existingEnterpriseIndex > -1) {
    const enterpriseConfig = result[existingEnterpriseIndex];
    result.splice(existingEnterpriseIndex, 1);
    result.push(enterpriseConfig);
  } else if (enterpriseProduct) {
    // Remove the first if the max length has been reached to make space for the enterprise product
    if (result.length >= limit) {
      result.shift();
    }
    result.push({ uid: enterpriseProduct.id });
  }

  return result.map(({ uid }) => ({ uid }));
};

export const adjustLayoutForFreeProducts = (products, initialLayout) => {
  const missingProducts = products.filter((product) => {
    return !initialLayout.find(({ uid }) => uid === product.id);
  });

  const enterpriseIndex = missingProducts.findIndex(({ name }) =>
    /enterprise/gi.test(name)
  );
  const appendCollection = [];
  if (enterpriseIndex > -1) {
    appendCollection.push({
      uid: missingProducts[enterpriseIndex].id
    });
    missingProducts.splice(enterpriseIndex, 1);
  }
  let prependCollection = [];
  if (missingProducts.length) {
    prependCollection = missingProducts.map(({ id }) => ({ uid: id }));
  }

  return [...prependCollection, ...initialLayout, ...appendCollection];
};

/**
 * Seed the editor with a products layout that reflects the best guess of a sass subscription tiered model
 * - bias to products with prices that have an amount
 * - bias to products with prices that recur both monthly and annually
 * - append any enterprise named products
 * @param {Array} orderedProducts - collection of products ordered by cost asc
 */
export const getInitialRecurringProductPricesLayout = (
  orderedProducts,
  limit = PRICING_TABLE_PRODUCT_LIMIT_START
) => {
  const layout = [];
  for (let prodIx = 0; prodIx < orderedProducts.length; prodIx++) {
    const product = orderedProducts[prodIx];
    const productPrices = product.prices;

    if (productPrices && productPrices.length) {
      const validPrices = getPricesWithRecurringInterval(productPrices);

      const productWithNameExists = layout.find(
        (layoutProduct) => layoutProduct.name === product.name
      );

      const minTwoBillingIntervalTypes = validPrices.length >= 2;
      if (minTwoBillingIntervalTypes && !productWithNameExists) {
        layout.push({
          name: product.name,
          uid: product.id
        });
      }
    }
  }

  return adjustLayoutForEnterpriseProducts(layout, orderedProducts, limit);
};

export const getInitialFeatureGroupLayout = (featureGroups) => {
  return orderBy(featureGroups, ["title"]).map(({ uuid }) => ({ uuid }));
};

export const getDefaultFeatureTablePreviewProductsLayout = ({
  products,
  prices
}) => {
  const primaries = getDefaultRecurringPricePrimaries({ products, prices });
  const extendedProducts = extendProductsWithPrices(
    products,
    prices,
    primaries
  );
  const orderedProducts = getOrderedProducts(extendedProducts);

  return getInitialRecurringProductPricesLayout(orderedProducts);
};

export const getLayoutDefaultEmphasisIndex = (layoutLength) => {
  const splitIndex = Math.ceil(layoutLength / 2);
  let emphasisIndex;
  if (splitIndex === 0) {
    emphasisIndex = 0;
  } else {
    const targetEmphasisIndex = Math.abs(splitIndex - 1);
    emphasisIndex = Number.isNaN(targetEmphasisIndex) ? 0 : targetEmphasisIndex;
  }

  return emphasisIndex;
};

export const getDefaultCurrencyFromPrimaries = (primaries) => {
  const usdMatch = primaries.find(
    (primary) => primary.currency === CURRENCY_CODES.USD
  );
  const firstPrimary = primaries[0];
  return usdMatch || !firstPrimary ? CURRENCY_CODES.USD : firstPrimary.currency;
};

export const getDefaultFeatureListConfig = (products) =>
  products.reduce((memo, { id }) => {
    memo[id] = {
      title: "Top features",
      layout: []
    };
    return memo;
  }, {});

export const buildDefaultManifestConfig = ({
  context,
  products,
  prices,
  primaries,
  featureGroups
}) => {
  const pricingExtension = {
    primaries: [],
    emphasisIndex: 0,
    defaultCurrency: null,
    layout: []
  };
  let featuresExtension = {};
  let featureGroupLayout = [];

  if (context === MANIFEST_CONTEXT_TYPE.PRICING) {
    const defaultPrimaries = getDefaultRecurringPricePrimaries({
      products,
      prices
    });
    const manifestPrimaries = mergePrimariesSets(defaultPrimaries, primaries);

    /**
     * NOTE: REFACTOR unit amount gets transformed to amount here
     */
    const extendedProducts = extendProductsWithPrices(
      products,
      prices,
      manifestPrimaries
    );

    /**
     * Determine default currency for page
     */
    const defaultCurrency = getDefaultCurrencyFromPrimaries(manifestPrimaries);

    /**
     * Get the product layout
     */
    const orderedProducts = getOrderedProducts(extendedProducts);
    const layout = getInitialRecurringProductPricesLayout(orderedProducts);

    /**
     * Get the ordered feature groups
     */
    featureGroupLayout = getInitialFeatureGroupLayout(featureGroups);

    /**
     * Get emphasis based on product layout
     */
    const emphasisIndex = getLayoutDefaultEmphasisIndex(layout.length);
    pricingExtension.primaries = manifestPrimaries;
    pricingExtension.emphasisIndex = emphasisIndex;
    pricingExtension.defaultCurrency = defaultCurrency;
    pricingExtension.layout = layout;

    featuresExtension = getDefaultFeatureListConfig(products);
  }

  return {
    pricing: {
      ...DEFAULT_PRICING_CONFIG,
      ...pricingExtension
    },
    features: {
      common: DEFAULT_FEATURES_COMMON_CONFIG,
      ...featuresExtension
    },
    featureGroups: {
      layout: featureGroupLayout
    }
  };
};

export const extendManifestEntities = (
  manifestEntities = [],
  appEntities = []
) => {
  return manifestEntities.map((manifestEntity) => {
    let result = {
      ...manifestEntity
    };
    if (!manifestEntity.schema) {
      const appEntityMatch = appEntities.find(
        (appEntity) => appEntity.uid === manifestEntity.uid
      );
      if (appEntityMatch && appEntityMatch.schema) {
        result = {
          ...manifestEntity,
          schema: appEntityMatch.schema,
          validation: appEntityMatch.validation || {}
        };
      }
    }
    return result;
  });
};

const buildDefaultManifestLayout = (entities) =>
  entities.map(({ uuid }) => ({ uuid }));

/**
 * Challenge is ensuring old manifests get new defaults values for added configuration
 * - If we add a new config feature we need to ensure that the old manifests dont break because they dont have them
 * - So when a config is pre-existing we extend it with the defaults but careful not to overwrite the root config object
 * - Replacing that reference will cause an infinite re-compute loop on the client (BUG)
 * - Ideally we would do a simple mergeReplace(default, current) and be done
 */
export const getManifestConfig = ({
  manifest,
  products,
  prices,
  featureGroups
}) => {
  const ctxConfig = get(manifest, "data.config");

  const defaultConfig = buildDefaultManifestConfig({
    context: manifest.context,
    products,
    prices,
    featureGroups
  });
  /**
   * Preserve reference to config
   * - .features shoudl exist from defaults but running into an issue which is hard to track so adding check and default set for features here
   */
  if (ctxConfig) {
    if (!ctxConfig.features) {
      ctxConfig.features = {};
    }
    ctxConfig.features.common = {
      ...DEFAULT_FEATURES_COMMON_CONFIG,
      ...get(ctxConfig, "features.common")
    };

    ctxConfig.pricing = {
      ...DEFAULT_PRICING_CONFIG,
      ...get(ctxConfig, "pricing")
    };

    return ctxConfig;
  } else {
    return defaultConfig;
  }
};

/**
 * Safety check to ensure that the right models are used between dev and prod
 * - Stripe keys will fetch the appropriate models but this adds a guard also
 * @param {Array} models
 * @param {String} env - node env passed in as a dependency
 * @param {Boolean} testOverride -
 */
export const getEnvCollection = (
  models,
  env = process.env.NODE_ENV,
  testOverride = false
) => {
  const validEnv = ENV_VALUES.indexOf(env) >= 0;

  /**
   * Note: filtering out PB test products created via /test/links
   * This could be an issue when trying to enforce that the user can see a list of test links (i.e. when livemode is set in a global state toggle)
   */
  if (validEnv) {
    if (env === ENV.PRODUCTION && !testOverride) {
      return models.filter((model) => {
        return (
          model.livemode &&
          !TEST_RESOURCE_ID_RE.test(model.id) &&
          !get(model, `metadata.${METADATA_PAIR_ID}`)
        );
      });
    } else {
      return models.filter((model) => {
        return (
          !model.livemode &&
          !TEST_RESOURCE_ID_RE.test(model.id) &&
          !get(model, `metadata.${METADATA_PAIR_ID}`)
        );
      });
    }
  } else {
    throw new Error(`${env} is not a valid ENV value`);
  }
};

/**
 * We decorate the manifest with the stored content
 * - Important to note that the prices for the environment are used
 * - i.e. return only prices with livemode false when in development or override
 */
export const prepareManifestForEditor = (props) => {
  const {
    admin,
    checkout,
    manifest,
    prices,
    products,
    taxRates,
    coupons,
    shippingRates,
    shortLinks,
    appEntities,
    features,
    featureGroups,
    entitlements
  } = props;
  // Stub variables for the moment
  const variables = [];
  const envPrices = getEnvCollection(prices);
  const envProducts = getEnvCollection(products);

  const manifestEntities = get(manifest, ENTITY_DATA, []);
  const manifestContent = get(manifest, CONTENT_DATA, []);

  const extendedEntities = extendManifestEntities(
    manifestEntities,
    appEntities
  );

  const entitiesLayout =
    get(manifest, "data.layout") ||
    buildDefaultManifestLayout(extendedEntities);

  const config = getManifestConfig({
    manifest,
    products: envProducts,
    prices: envPrices,
    featureGroups
  });

  /**
   * Extract raw product prices data to support entities on the page
   * - This data is fresh from an API pull
   * - Not stored in PB db
   * - Used to render things like images
   */
  const pageData = reducePageMinimumData({
    manifest: {
      data: {
        config,
        entities: manifestEntities,
        content: manifestContent
      }
    },
    shortLinks,
    products,
    prices,
    coupons,
    taxRates,
    shippingRates
  });

  const data = extendedEntities.reduce(
    (memo, entity) => {
      if (!memo.content[entity.uuid]) {
        memo.content[entity.uuid] = {};
      }
      const generatorInput = {
        entity,
        prices: envPrices,
        products: envProducts,
        variables,
        features,
        featureGroups,
        entitlements,
        shortLinks,
        taxRates,
        coupons,
        shippingRates,
        admin,
        checkout,
        config: memo.config,
        content: memo.content,
        entities: memo.entities
      };

      const generatedContent = {
        data:
          CONTENT_GENERATORS[entity.uid] &&
          CONTENT_GENERATORS[entity.uid](generatorInput),
        ui:
          UI_GENERATORS[entity.uid] &&
          UI_GENERATORS[entity.uid](generatorInput),
        config:
          CONFIG_GENERATORS[entity.uid] &&
          CONFIG_GENERATORS[entity.uid](generatorInput)
      };

      memo.content[entity.uuid] = generatedContent;

      return memo;
    },
    {
      category: manifest.data.category,
      config,
      layout: entitiesLayout,
      entities: extendedEntities || [],
      content: manifest.data.content || {},
      admin,
      checkout,
      data: pageData
    }
  );

  /**
   * [Client-Server manifest id pair log]
   * Extend data with manifest id
   */
  data.id = manifest.id;

  return {
    id: manifest.id,
    uuid: manifest.uuid,
    version: manifest.version,
    context: manifest.context,
    title: manifest.title,
    data,
    updatedAt: manifest.updated_at || manifest.updatedAt
  };
};

export const getPagePayload = ({
  manifest,
  theme,
  integrations,
  capabilities
}) => ({ manifest, theme, integrations, capabilities });

// ==================== FEATURES
const FEATURE_PRODUCT_KEYS = ["uid", "title", "actions", "formattedPrice"];
/**
 *
 * @param {Object} props
 * @param {Array<{uid: String, title: String}>} props.products
 * @param {Array<{uid: String, title: String}>} props.contentProducts
 */
export const mergeProductCollections = (props) => {
  const { products, contentProducts } = props;
  const productsCopy = cloneDeep(products);
  const contentProductsCopy = cloneDeep(contentProducts);

  const resolvedCollection = productsCopy.reduce((memo, product) => {
    const replacementIndex = contentProductsCopy.findIndex(
      ({ uid }) => uid === product.uid
    );
    if (replacementIndex > -1) {
      const model = contentProductsCopy[replacementIndex];

      memo.push(pick(model, FEATURE_PRODUCT_KEYS));

      contentProductsCopy.splice(replacementIndex, 1);
    } else {
      memo.push(pick(product, FEATURE_PRODUCT_KEYS));
    }

    return memo;
  }, []);

  const uniqueCollection = uniqBy(
    resolvedCollection.concat(contentProductsCopy),
    "uid"
  );

  return uniqueCollection;
};

const getResolveProductsCollection = ({ entities, content, products }) => {
  const existingTable = entities.find(
    ({ uid }) => uid === ENTITIES.PRICING_TABLE
  );
  if (existingTable) {
    const tableKey = `${existingTable.uuid}.data.products`;
    const contentProducts = existingTable ? get(content, tableKey, []) : [];
    return mergeProductCollections({
      products,
      contentProducts
    });
  } else {
    return products;
  }
};

export const reduceGroupFeatureComparisonColumns = ({ initial, products }) => {
  return products.reduce(
    (memo, product) => {
      memo.push({ accessor: product.uid });
      return memo;
    },
    [initial]
  );
};

export const reduceGroupFeatureComparisonRows = ({
  featureGroup,
  entitlements,
  features = [],
  products = []
}) => {
  return featureGroup.feature_uuids.reduce((memo, featureUUID) => {
    const featureMatch = features.find(({ uuid }) => uuid === featureUUID);

    if (featureMatch) {
      memo.push(
        products.reduce(
          (memo, product) => {
            const prodConfig = get(
              entitlements,
              `${featureMatch.uuid}.${product.uid}`
            );
            let value = null;
            if (prodConfig) {
              value = {
                uid: product.uid,
                ...prodConfig
              };
            }

            memo[product.uid] = value;

            return memo;
          },
          {
            title: {
              value: featureMatch.title,
              tooltip: featureMatch.tooltip || null
            }
          }
        )
      );
    }

    return memo;
  }, []);
};

export const priceToPrimary = (price) => ({
  price: price.id,
  product: price.product,
  interval: get(price, "recurring.interval"),
  currency: price.currency
});

export const reduceCouponsPromotionCodes = (discounts = []) =>
  discounts.reduce(
    (memo, { coupon, promotion_code }) => {
      if (coupon) {
        memo.coupons.push(coupon);
      } else if (promotion_code) {
        memo.promotion_codes.push(promotion_code);
      }
      return memo;
    },
    { coupons: [], promotion_codes: [] }
  );

export const reduceShippingOptionsRates = (options) =>
  Array.isArray(options)
    ? options.map(({ shipping_rate }) => shipping_rate)
    : [];
