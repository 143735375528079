import CONSTANTS from "constants/paymentMethod";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const internals = {
  [CONSTANTS.SET_PAYMENT_METHODS]: (state, { payload }) => {
    return helpers.setIn(state, ["data"], "id", payload);
  },
  [CONSTANTS.REPLACE_PAYMENT_METHODS]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  [CONSTANTS.REMOVE_PAYMENT_METHODS]: (state, { payload }) => {
    for (let i = 0; i < payload.length; i++) {
      const model = payload[i];
      const { id } = model;
      state = helpers.inActionAtIndex("remove", state, ["data"], id, ["id"]);
    }

    return state;
  },
  [CONSTANTS.UPDATE_PAYMENT_METHOD_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
