import { STATE_KEYS } from "utils/constants/state";
import {
  BLOCK_LIST_WIDTH,
  BLOCK_REGISTRY,
  BUILDER_INTENT
} from "utils/constants/builder";
import { prepareManifestForEditor } from "components/FormerEditor/common";
import {
  checkPinnedEntities,
  isFirstPosition,
  isLastPosition
} from "utils/entity";
import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { getFieldPath } from "./form";
import { singularize } from "./text";
import { useFormikContext } from "formik";
import { useAppEntities } from "./selectors";

/**
 * Manually clear all matching storage keys
 * Prod
 * Object.keys(window.localStorage).filter((key) => /^priceblocs-builder-/.test(key)).forEach((key) => window.localStorage.removeItem(key));
 * Dev
 * Object.keys(window.localStorage).filter((key) => /^priceblocs-dev-builder-/.test(key)).forEach((key) => window.localStorage.removeItem(key));
 * Legacy
 * window.localStorage.removeItem("priceblocs-dev-builder")
 */
const BUILDER_KEY =
  process.env.NODE_ENV === "production"
    ? "priceblocs-builder"
    : "priceblocs-dev-builder";

const MATCH_RE = new RegExp(`^${BUILDER_KEY}-`);

const getBuilderKey = (key) => `${BUILDER_KEY}-${key}`;

export const setStorePageBuilder = ({ key, value }) =>
  typeof window !== "undefined" &&
  window.localStorage.setItem(getBuilderKey(key), JSON.stringify(value));

export const clearStorePageBuilder = () => {
  if (typeof window !== "undefined") {
    Object.keys(window.localStorage)
      .filter((key) => MATCH_RE.test(key))
      .forEach((key) => window.localStorage.removeItem(key));
  }
};

export const clearStorePageBuilderKey = (key) =>
  typeof window !== "undefined" &&
  window.localStorage.removeItem(getBuilderKey(key));

export const getStorePageBuilder = (key) => {
  if (typeof window !== "undefined") {
    /* eslint-disable-next-line no-useless-catch */
    try {
      const data = window.localStorage.getItem(getBuilderKey(key));
      return data ? JSON.parse(data) : {};
    } catch (err) {
      console.log("------- ~ err", err);
      throw err;
    }
  } else {
    return {};
  }
};

export const scrollPagePreviewBottom = () => {
  if (typeof document !== "undefined") {
    const target = document.getElementById(BLOCK_REGISTRY.PAGE_PREVIEW);

    if (target) {
      const scrollPos = target.getBoundingClientRect().bottom;
      if (scrollPos) {
        target.scrollTop = scrollPos;
      }
    }
  }
};

export const removeEntity = ({
  values,
  uuid,
  setValues,
  setShowModal,
  setFieldTouched
}) => () => {
  const updatedValues = { ...values };
  const entities = get(updatedValues, STATE_KEYS.MANIFEST.ENTITIES);
  const layout = get(updatedValues, STATE_KEYS.MANIFEST.LAYOUT);
  const content = get(updatedValues, STATE_KEYS.MANIFEST.CONTENT);
  const entityIndex = entities.findIndex((manifest) => manifest.uuid === uuid);

  /**
   * Remove selected entity from the entity layout config object
   */
  const layoutIndex = layout.findIndex((layout) => layout.uuid === uuid);
  if (entityIndex > -1) {
    entities.splice(entityIndex, 1);
  }
  if (layoutIndex > -1) {
    layout.splice(layoutIndex, 1);
  }

  /**
   * Delete any content set on behalf of the entity
   * - Note: this includes any checkout logic / combinations which are under this key
   */
  if (content && content[uuid]) {
    delete content[uuid];
  }

  /**
   * Check to see if there are any blocks remaining which we can show the settings for if the settings menu is open
   */
  const needsFallback =
    get(updatedValues, STATE_KEYS.EDITOR.ACTIVE_BLOCK) === uuid &&
    get(updatedValues, STATE_KEYS.EDITOR.VISIBILITY_PANEL) &&
    get(updatedValues, STATE_KEYS.EDITOR.INTENT) ===
      BUILDER_INTENT.ENTITY_SETTINGS;
  const canFallback = layout.length > 0;

  /**
   * Update editor state so that the user sees the BlockSettings panel menu
   * for the next suitable block
   */
  if (needsFallback && canFallback) {
    const onlyOneLeft = layout.length === 1;
    let newActiveBlock;
    if (onlyOneLeft) {
      newActiveBlock = layout[0].uuid;
    } else {
      const previousLayout = layout[layoutIndex - 1];
      newActiveBlock = previousLayout
        ? previousLayout.uuid
        : layout[layoutIndex].uuid;
    }

    set(updatedValues, STATE_KEYS.EDITOR.ACTIVE_BLOCK, newActiveBlock);
  } else {
    /**
     * Reset editor state so that the user sees the EntityPicker panel menu
     */
    set(updatedValues, STATE_KEYS.EDITOR.INTENT, BUILDER_INTENT.ENTITY_ADD);
    set(updatedValues, STATE_KEYS.EDITOR.VISIBILITY_PANEL, true);
    set(updatedValues, STATE_KEYS.EDITOR.ACTIVE_BLOCK, null);
  }

  set(updatedValues, STATE_KEYS.MANIFEST.ENTITIES, entities);
  set(updatedValues, STATE_KEYS.MANIFEST.LAYOUT, layout);
  // Set fallback for UI
  setValues(updatedValues);
  setFieldTouched(STATE_KEYS.MANIFEST.ENTITIES, true);
  setShowModal && setShowModal(false);
};

export const addEntity = (props) => {
  const {
    admin,
    checkout,
    entity,
    values,
    entities,
    appEntities,
    entitlementConfig,
    products,
    prices,
    features,
    featureGroups,
    shortLinks,
    taxRates,
    shippingRates,
    coupons,
    uuid,
    setValues,
    setTouched
  } = props;
  return () => {
    if (
      entity.validation &&
      entity.validation.max === 1 &&
      entities.find(({ uid }) => uid === entity.uid)
    ) {
      console.log("Trigger notification that only one is allowed");
    } else {
      const updatedValues = { ...values };
      const updatedLayout = get(updatedValues, STATE_KEYS.MANIFEST.LAYOUT);
      const newEntity = { uuid };
      const entityToInsert = {
        ...entity,
        ...newEntity
      };
      let scrollBottomOnUpdate = false;

      entities.push(entityToInsert);
      const contentKey = `${STATE_KEYS.MANIFEST.CONTENT}.${uuid}`;

      // Add to Entities
      set(updatedValues, STATE_KEYS.MANIFEST.ENTITIES, entities);
      set(updatedValues, contentKey, {});

      /**
       * Add to Layout based on validations
       * min / max
       * position (index they are pinned to)
       */
      const addToStart = isFirstPosition(entity.validation);
      if (addToStart) {
        updatedLayout.unshift(newEntity);
      } else {
        scrollBottomOnUpdate = true;
        const { lastPinned } = checkPinnedEntities({ entities });
        const addToEnd = isLastPosition(entity.validation);

        if (addToEnd) {
          updatedLayout.push(newEntity);
        } else {
          const insertIndex = lastPinned
            ? updatedLayout.length - 1
            : updatedLayout.length;

          updatedLayout.splice(insertIndex, 0, newEntity);
        }
      }

      set(updatedValues, STATE_KEYS.MANIFEST.LAYOUT, updatedLayout);

      // Set block settings active for the new entity added
      set(updatedValues, STATE_KEYS.EDITOR.ACTIVE_BLOCK, uuid);
      set(
        updatedValues,
        STATE_KEYS.EDITOR.INTENT,
        BUILDER_INTENT.ENTITY_SETTINGS
      );

      // Recompute manifest
      const newManifest = prepareManifestForEditor({
        manifest: updatedValues.manifest,
        admin,
        checkout,
        products,
        prices,
        features,
        featureGroups,
        shortLinks,
        taxRates,
        shippingRates,
        coupons,
        appEntities,
        entitlementConfig,
        variables: updatedValues.variables
      });
      updatedValues.manifest = newManifest;

      // Set and touch
      setValues(updatedValues);
      setTouched({
        [STATE_KEYS.MANIFEST.ENTITIES]: true,
        [contentKey]: true
      });

      /**
       * Scroll the page preview to the bottom
       */
      if (scrollBottomOnUpdate) {
        scrollPagePreviewBottom();
      }
    }
  };
};

const applyFocus = (values, collection) => {
  collection.forEach(({ key, value }) => {
    if (!key || typeof value === "undefined") {
      throw Error(`Invalid focus passed key:${key} value:${value}`);
    }

    set(values, key, value);
  });

  return values;
};

export const updateEditor = (values, focus) => {
  const updatedValues = cloneDeep(values);
  const collection = [
    {
      key: STATE_KEYS.EDITOR.INTENT,
      value: BUILDER_INTENT.ENTITY_SETTINGS
    },
    {
      key: STATE_KEYS.EDITOR.VISIBILITY_PANEL,
      value: true
    },
    ...focus
  ];

  applyFocus(updatedValues, collection);

  return updatedValues;
};

export const setEditorFocus = ({ values, setValues, focus = [] }) => {
  return () => {
    const updatedValues = updateEditor(values, focus);

    setValues(updatedValues);
  };
};

export const applyEditorFocus = ({ values, setValues, focus = [] }) => {
  return () => {
    const updatedValues = cloneDeep(values);
    applyFocus(updatedValues, focus);

    setValues(updatedValues);
  };
};

/**
 * Determine whether the field meets its schema defined dependencies
 * @param {Object} params
 */
export const getMeetsDependencies = ({
  values,
  dependencies,
  fieldKey,
  name
}) => {
  let meetsDependencies = true;
  if (dependencies && dependencies[fieldKey]) {
    for (const depKey in dependencies[fieldKey]) {
      const depValue = dependencies[fieldKey][depKey];
      const comparisonValue = get(values, getFieldPath(name, depKey));

      if (comparisonValue !== depValue) {
        meetsDependencies = false;
        break;
      }
    }
  }
  return meetsDependencies;
};

export const getFieldDeleteItemName = (name) => {
  const lastItem = name.split(".").pop();

  return singularize(lastItem);
};

export const usePanel = () => {
  const { values } = useFormikContext();
  const panelVisible = get(values, STATE_KEYS.EDITOR.VISIBILITY_PANEL);

  return {
    visible: panelVisible,
    width: BLOCK_LIST_WIDTH
  };
};

/**
 * Disable selecting an entity (block) based on any validations present
 * @param {Object} entity
 * @param {Array} entities
 * @returns
 */
export const disableEntitySelection = (entity, entities) => {
  let disable = false;
  let maxReached = false;
  let requiresParent = false;

  if (entity.validation) {
    const max = entity.validation.max;
    if (max) {
      const matchingEntities = entities.filter(({ uid }) => uid === entity.uid);
      if (matchingEntities.length >= max) {
        maxReached = true;
      }
    }

    if (entity.validation.parents && entity.validation.parents.length) {
      const matchingEntities = entity.validation.parents.reduce(
        (memo, parentUID) => {
          const parentMatch = entities.find(({ uid }) => uid === parentUID);
          if (parentMatch) {
            memo.push(parentMatch);
          }
          return memo;
        },
        []
      );
      const foundLessThanRequired =
        matchingEntities.length < entity.validation.parents.length;
      if (foundLessThanRequired) {
        requiresParent = true;
      }
    }
  }
  if (maxReached || requiresParent) {
    disable = true;
  }

  return disable;
};

export const getRemainingEntityRequiredParents = ({
  uid,
  appEntities,
  currentEntities
}) => {
  let remainingRequiredParents = [];

  const targetEntityConfig = appEntities.find((entity) => entity.uid === uid);
  const parents = get(targetEntityConfig, "validation.parents", []);

  if (parents.length) {
    remainingRequiredParents = parents.reduce((memo, parentUID) => {
      const parentMatch = currentEntities.find(
        (entity) => entity.uid === parentUID
      );
      const configMatch = appEntities.find(
        (entity) => entity.uid === parentUID
      );

      if (!parentMatch && configMatch) {
        memo.push(configMatch);
      }
      return memo;
    }, []);
  }
  return remainingRequiredParents;
};

/**
 * Returns all required parent entities
 * @param {Array} entities
 */
export const useRemainingEntityRequiredParents = (entityUID) => {
  const { values } = useFormikContext();
  const appEntities = useAppEntities();
  const currentEntities = get(values, STATE_KEYS.MANIFEST.ENTITIES, []);

  return getRemainingEntityRequiredParents({
    uid: entityUID,
    appEntities,
    currentEntities
  });
};
