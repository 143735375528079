import { v4 as uuidv4 } from "uuid";
import { COUPON_DISCOUNT_TYPE, COUPON_DURATION } from "./constants/coupon";
import get from "lodash/get";
import set from "lodash/set";
import orderBy from "lodash/orderBy";
import {
  formattedPriceToUnitAmount,
  formatUnitAmount,
  getCurrencyConfigForLocale
} from "components/FormerEditor/common/currency";
import startCase from "lodash/startCase";
import { formatUTCTime, isExpired } from "utils/date";
import { getStripeAdminLink } from "utils/stripe";
import { pluralizeCopy } from "utils/copy";
import { FORM_KEY } from "utils/constants/form";
import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import { getUnixTime, fromUnixTime } from "date-fns";
import { STATUS, UI_THEME } from "utils/constants/ui";
import { getBoolSelectValue } from "./form";

/**
 * Scaffold a discount holder object
 * Value can either be coupon id or promotion code
 * @param {String} uuid - uuid for client side management
 */
export const getSelectDiscountScaffold = (uuid = uuidv4()) => {
  return {
    key: uuid,
    uuid,
    coupon: "",
    promotion_code: ""
  };
};

export const getCreateCouponScaffold = (uuid = uuidv4()) => {
  const currencyConfig = getCurrencyConfigForLocale();
  const initialCurrency = currencyConfig.code.toLowerCase();

  return {
    [FORM_KEY]: {
      discount_type: COUPON_DISCOUNT_TYPE.PERCENTAGE,
      max_redemptions: false,
      redeem_by: false
    },
    key: uuid,
    uuid,
    name: "",
    percent_off: "10",
    currency: initialCurrency,
    amount_off: formatUnitAmount({
      amount: 1000,
      currency: initialCurrency
    }),
    duration: COUPON_DURATION.ONCE,
    // Present if duration is 'repeating'
    duration_in_months: 1,
    max_redemptions: 0,
    redeem_by: new Date(),
    promotion_codes: [],
    applies_to: {
      products: []
    }
  };
};

export const prepareEditCouponInitialValues = ({ coupon, promotionCodes }) => {
  const currencyConfig = getCurrencyConfigForLocale();
  const initialCurrency = currencyConfig.code.toLowerCase();
  const hasPercentOff = !isNull(coupon.percent_off);
  const hasMaxRedemptions = !isNull(coupon.max_redemptions);
  const hasRedeemBy = !isNull(coupon.redeem_by);

  let redeem_by;
  if (!isNull(coupon.redeem_by)) {
    redeem_by = fromUnixTime(coupon.redeem_by);
  }

  return {
    [FORM_KEY]: {
      discount_type: hasPercentOff
        ? COUPON_DISCOUNT_TYPE.PERCENTAGE
        : COUPON_DISCOUNT_TYPE.FIXED,
      max_redemptions: hasMaxRedemptions,
      redeem_by: hasRedeemBy
    },
    id: coupon.id,
    name: coupon.name,
    percent_off: isNull(coupon.percent_off) ? "0" : `${coupon.percent_off}`,
    currency: isNull(coupon.currency) ? initialCurrency : `${coupon.currency}`,
    amount_off: isNull(coupon.amount_off)
      ? ""
      : formatUnitAmount({
          amount: coupon.amount_off,
          currency: coupon.currency
        }),
    duration: coupon.duration,
    duration_in_months: isNull(coupon.duration_in_months)
      ? 0
      : coupon.duration_in_months,
    max_redemptions: isNull(coupon.max_redemptions)
      ? 0
      : coupon.max_redemptions,
    redeem_by,
    promotion_codes: promotionCodes.filter((promoCode) => {
      return promoCode.coupon.id === coupon.id;
    }),
    applies_to: isEmpty(coupon.applies_to)
      ? {
          products: []
        }
      : coupon.applies_to,
    times_redeemed: coupon.times_redeemed,
    valid: coupon.valid
  };
};

/**
 * Note: start with a hydrated form - transform afterwards
 */
export const prepareCreateCouponInitialValues = (uuid) => {
  return {
    coupons: [getCreateCouponScaffold(uuid)]
  };
};

const prepareCouponFromValues = (coupon) => {
  const result = {
    name: coupon.name,
    duration: coupon.duration
  };
  if (coupon[FORM_KEY].discount_type === COUPON_DISCOUNT_TYPE.PERCENTAGE) {
    result.percent_off = parseFloat(coupon.percent_off);
  } else if (coupon[FORM_KEY].discount_type === COUPON_DISCOUNT_TYPE.FIXED) {
    result.amount_off = formattedPriceToUnitAmount({
      formattedPrice: coupon.amount_off,
      currency: coupon.currency
    });
    result.currency = coupon.currency;
  }

  if (coupon.duration === COUPON_DURATION.REPEATING) {
    result.duration_in_months = coupon.duration_in_months;
  }

  if (coupon[FORM_KEY].max_redemptions && coupon.max_redemptions) {
    result.max_redemptions = coupon.max_redemptions;
  }
  /**
   * Need to ensure that no null values are passed
   * and that unix conversion uses Date input
   */
  if (coupon[FORM_KEY].redeem_by && coupon.redeem_by) {
    const redeemByDate =
      coupon.redeem_by instanceof Date
        ? coupon.redeem_by
        : new Date(coupon.redeem_by);
    const redeemBy = getUnixTime(redeemByDate);
    if (redeemBy) {
      result.redeem_by = redeemBy;
    }
  }

  if (coupon.promotion_codes && coupon.promotion_codes.length) {
    // TODO: sanitize and format
    coupon.promotionCodes = coupon.promotion_codes;
  }

  return result;
};

export const prepareCreateCoupons = (coupons) => {
  return omitAndSanitizeParams({
    coupons: coupons.map((values) => prepareCouponFromValues(values))
  });
};

/**
 * Compare changes on keys for update
 * Note: db will merge the data field but that should be deprecated
 * @param {Object} initial form values
 * @param {Object} current form values
 */
export const prepareUpdateCoupon = (initial, current) =>
  ["name"].reduce((memo, fieldKey) => {
    const initialVal = get(initial, fieldKey);
    const currentVal = get(current, fieldKey);
    const differentValues = currentVal !== initialVal;
    if (differentValues) {
      set(memo, fieldKey, currentVal);
    }
    return memo;
  }, {});

export const getCouponFormattedValueFromValues = ({ coupon }) => {
  const discount = coupon.amount_off
    ? coupon.amount_off
    : `${coupon.percent_off}%`;

  return [coupon.name, `${discount} off`].join(" - ");
};

export const getCouponFormattedValue = ({ coupon }) => {
  const discount = coupon.amount_off
    ? formatUnitAmount({
        amount: coupon.amount_off,
        currency: coupon.currency
      })
    : `${coupon.percent_off}%`;

  return `${discount} off`;
};

export const getCouponPillProps = (coupon) => {
  const isExpired = Boolean(coupon.expired);
  const isRedeemed = Boolean(coupon.expired);

  const props = { theme: UI_THEME.SLIM };
  if (isExpired || isRedeemed) {
    props.status = STATUS.WARNING;
    if (isExpired) {
      props.copy = "Expired";
    } else if (isRedeemed) {
      props.copy = "Fully redeemed";
    }
  } else {
    props.copy = coupon.duration;
  }
  return props;
};

export const prepareCouponShow = ({ coupon }) => {
  let duration = "";
  let expiration = null;
  let disabled = false;
  let expired = false;
  let redeemed = false;
  if (
    coupon.duration === COUPON_DURATION.REPEATING &&
    coupon.duration_in_months
  ) {
    duration = `Every ${pluralizeCopy("month", coupon.duration_in_months)}`;
  } else {
    duration = startCase(coupon.duration);
  }

  if (coupon.redeem_by) {
    const redeemBy = coupon.redeem_by * 1000;

    expiration = formatUTCTime(redeemBy);
    expired = isExpired(redeemBy);
  }
  if (coupon.max_redemptions) {
    redeemed = coupon.times_redeemed >= coupon.max_redemptions;
  }

  disabled = Boolean(expired || redeemed);

  const result = {
    id: coupon.id,
    name: coupon.name,
    currency: coupon.currency,
    duration,
    type: coupon.amount_off
      ? COUPON_DISCOUNT_TYPE.FIXED
      : COUPON_DISCOUNT_TYPE.PERCENTAGE,
    discount: coupon.amount_off
      ? formatUnitAmount({
          amount: coupon.amount_off,
          currency: coupon.currency
        })
      : `${coupon.percent_off}%`,
    redemptions: coupon.max_redemptions
      ? `${coupon.times_redeemed}/${coupon.max_redemptions}`
      : coupon.times_redeemed,
    adminUrl: getStripeAdminLink(coupon.livemode, `coupons/${coupon.id}`),
    createdAt: formatUTCTime(coupon.created * 1000),
    expiration,
    expired,
    redeemed,
    disabled,
    isRedeemed: getBoolSelectValue(redeemed),
    isExpired: getBoolSelectValue(expired)
  };

  return result;
};

export const prepareCouponList = ({ coupons, shouldOrder }) => {
  const ctxModels = shouldOrder
    ? orderBy(coupons, ["created"], ["desc"])
    : coupons;

  return ctxModels.map((coupon) => {
    return prepareCouponShow({
      coupon
    });
  });
};

export const parseFirstIdFromDiscounts = (discounts = "") => {
  const firstDiscount = discounts.split(",")[0];
  return firstDiscount && firstDiscount.split(":")[1];
};

/**
 * Forward apply coupons are ones that will apply to future payments
 * - i.e. duration is longer than a month
 * @param {*} coupons
 * @returns
 */
export const filterForwardApplyCoupons = (coupons) =>
  coupons.filter(({ duration, duration_in_months }) => {
    let repeats = duration !== COUPON_DURATION.ONCE;
    if (duration === COUPON_DURATION.REPEATING && duration_in_months === 1) {
      repeats = false;
    }
    return repeats;
  });

export const filterForwardApplyCouponsWithExpiry = ({
  coupons,
  durationCount = 1
}) =>
  coupons.filter(
    ({ duration, duration_in_months }) =>
      duration === COUPON_DURATION.REPEATING &&
      duration_in_months > durationCount
  );
