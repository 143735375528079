import CONSTANTS from "constants/organizationDatasource";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const DATASOURCE_ID = "datasource_id";
const ORGANIZATION_ID = "organization_id";

const internals = {
  [CONSTANTS.SET_ORGANIZATION_DATASOURCES]: (state, { payload }) => {
    return helpers.setAssociationsIn(
      state,
      payload,
      DATASOURCE_ID,
      ORGANIZATION_ID
    );
  },
  [CONSTANTS.REPLACE_ORGANIZATION_DATASOURCES]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  [CONSTANTS.UPDATE_ORGANIZATION_DATASOURCE_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
