import C from "constants/subscription";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import ActionHelpers from "./helpers";
import { authBearerToken } from "utils/auth";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_SUBSCRIPTION_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setSubscriptions = (payload) => {
  return {
    type: C.SET_SUBSCRIPTIONS,
    payload
  };
};

export const setRawSubscriptions = (payload) => {
  return {
    type: C.SET_RAW_SUBSCRIPTIONS,
    payload
  };
};

export const removeSubscriptions = (payload) => {
  return {
    type: C.REMOVE_SUBSCRIPTIONS,
    payload
  };
};

/** resetSubscriptionNotes
 */
export function resetSubscriptionNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_SUBSCRIPTION_STORE,
      payload
    });
  };
}

export function showSubscriptionNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_SUBSCRIPTION_STORE,
        message || `Success!`
      )
    );
  };
}

/**
 * fetchRawSubscription
 */
export const fetchRawSubscription = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    dispatch(ActionHelpers.loadingAction(C.UPDATE_SUBSCRIPTION_STORE));
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/subscriptions/${uuid}/raw`;
    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const subscriptions = Array.isArray(data) ? data : [data];

        dispatch(
          batchActions([
            setRawSubscriptions(subscriptions),
            ActionHelpers.clearLoader(C.UPDATE_SUBSCRIPTION_STORE)
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SUBSCRIPTION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchSubscriptions
 */
export const fetchSubscriptions = (
  { resourceUUID, resource },
  callback = () => {}
) => {
  return (dispatch) => {
    dispatch(ActionHelpers.loadingAction(C.UPDATE_SUBSCRIPTION_STORE));
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/subscriptions`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        dispatch(
          batchActions([
            setSubscriptions(data),
            ActionHelpers.clearLoader(C.UPDATE_SUBSCRIPTION_STORE)
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SUBSCRIPTION_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
