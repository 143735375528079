import CONSTANTS from "constants/userFeatureGroup";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: []
});

const FEATURE_GROUP_ID = "feature_group_id";
const USER_ID = "user_id";

const internals = {
  [CONSTANTS.SET_USER_FEATURE_GROUPS]: (state, { payload }) => {
    return helpers.setAssociationsIn(state, payload, FEATURE_GROUP_ID, USER_ID);
  },
  [CONSTANTS.REPLACE_USER_FEATURE_GROUPS]: (state, { payload }) => {
    return state.setIn(["data"], fromJS(payload));
  },
  [CONSTANTS.UPDATE_USER_FEATURE_GROUP_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
