import keymirror from "keymirror";

export default keymirror({
  SET_ORGANIZATIONS: null,
  SET_ORGANIZATION_SUBSCRIPTION: null,
  SET_ORGANIZATION_LICENSES: null,
  UPDATE_ORGANIZATION_STORE: null,
  SET_ORGANIZATION_API_KEYS: null,
  SET_ORGANIZATION_SUBDOMAINS: null
});
