import { RiPlayCircleLine } from "@hacknug/react-icons/ri";

export const BORDER_STYLE = {
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderRightWidth: 0,
  borderLeftWidth: 2,
  borderStyle: "solid",
  borderColor: "transparent"
};

export const MAX_HEIGHT = 400;
export const PADDER_HEIGHT = 54;

const LOOM = "loom";
const NOTION = "notion";
const YOU_TUBE = "youtube";

export const COMMAND_SERVICE = {
  LOOM,
  YOU_TUBE,
  NOTION
};

const VIDEO = "video";
export const COMMAND_TYPE = {
  VIDEO
};

const LOOM_CONFIG = {};
const YOU_TUBE_CONFIG = {
  //   adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
  params: "", // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
  playlist: false, // Use  true when your ID be from a playlist
  poster: "hqdefault", // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
  title: "YouTube Embed", // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
  noCookie: true // Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
};

export const YOU_TUBE_EMBED_KEYS = [
  // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
  "adNetwork",
  // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
  "params",
  // Use  true when your ID be from a playlist
  "playlist",
  // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
  "poster",
  // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
  "title",
  // Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
  "noCookie"
];

export const DEFAULT_SERVICE_CONFIG = {
  [COMMAND_SERVICE.YOU_TUBE]: YOU_TUBE_CONFIG,
  [COMMAND_SERVICE.LOOM]: LOOM_CONFIG
};

export const COMMAND_TYPE_ICON_MAP = {
  video: RiPlayCircleLine
};

export const COMMAND_CONTENT_ID = {
  PLACEHOLDER: "a6d360b6-f9cd-44d6-a252-fda714fd60d0",
  FUNDAMENTALS: {
    ADD_A_FIELD: "ef78a639-00c5-4ffb-a335-fb270b6d549f",
    ADD_A_PRODUCT: "998458f3-09f5-48b4-9665-ee0c0ad6d0bf",
    FIRST_PAGE: "65ec9fba-7b7a-40fb-b677-24d03b330415",
    BUILD_A_FORM: "5818a663-9bdb-4d4c-87be-436e89906b3a"
  },
  CHECKOUT: {
    /**
     * Needs:
     * - product settings
     */
    // DEFAULT_CONFIG: "a6d360b6-f9cd-44d6-a252-fda714fd60d0",
    // COMMON_CONFIG: "a6d360b6-f9cd-44d6-a252-fda714fd60d0",
    // LOGIC_SETTINGS: "a6d360b6-f9cd-44d6-a252-fda714fd60d0",
    COUPON: "6da9d4c5-fe94-4b43-b95b-cf494345a6df",
    CONNECT_PROMOTION_CODE_SETTINGS: "eb46a356-a6dd-49ca-9c12-566f2e638198",
    // COMBINATION_LOGIC: "1ea6e979-c5a4-45f9-8ed7-05fcc7a4474d",
    LOGIC: "e13e56be-8f02-4cba-b9b4-28472ee6a758",
    SETUP_FIELD_COMBINATIONS: "d43b8c70-3fc0-4d34-8b70-bea7da4396c3"
  },
  FIELDS: {
    STANDARD_SETTINGS: "62df14cf-64ee-42b7-b51f-eb7b97e15394",
    CONNECT_EMAIL_SETTINGS: "6d17e97e-41bc-4187-94ff-8586bd83672a",
    PREFILL_AND_CONNECT: "16aac5c3-e6eb-4537-9287-cb08a5c147c4",
    COMBINATION_UPSELL: "0c7522af-b1eb-4ff5-88a7-6c547393a3b0",
    // PREFILL_SETTINGS: "a6d360b6-f9cd-44d6-a252-fda714fd60d0",
    // CONNECT_SETTINGS: "a6d360b6-f9cd-44d6-a252-fda714fd60d0",
    CONNECT_PROMOTION_CODE_SETTINGS: "b874ca05-502f-4057-9f29-e40ce1732066"
  },
  EDITOR: {
    THEME: "44cd3b51-56e2-4e31-86ac-f8f0016b3454",
    REORDER_FIELDS: "fd32fe42-9436-430f-9fd0-d3949cbcb1aa"
  },
  WEBFLOW: {
    EMBED: "52ecb8a1-2662-4987-a989-16202317ea7a"
  },
  EMBED: {
    STYLE_LAYOUT: "fdfcd31b-cd96-46bf-9104-9b02a1c83288",
    PARAMETERS_FORWARD: "21590b5f-01fa-4852-8af5-d1c9483cb03a",
    HOSTING_ALLOWED_DOMAINS: "db49f524-fa98-44c0-a845-7e2244b1fd19"
    // Forward cancel url and success_url
  },
  ZAPIER: {
    APP: "c896d2d4-422c-48f4-846b-70e14e6afe38",
    CREATE_A_WORKFLOW: "8bcd439a-fecc-4e67-aa96-e7a0e860a325",
    AUTHENTICATE: "f0965e76-00d0-480c-8ac1-8f84fa45e25f",
    FORMAT_FILTER: "f3dbd732-749e-4760-b47c-678daea6e0e0",
    FORMAT_LABELED_DICTIONARY: "754948c2-0eb0-49ab-9fb9-6b50b2f0a5a4"
  },
  API: {
    CHECKOUT: "a6445c27-77af-4f6c-8408-6f5adfc47519"
  }
};
