export const ONBOARDING_STATUSES = {
  INCOMPLETE: "incomplete",
  COMPLETE: "complete",
  SKIPPED: "skipped"
};

/**
 * These action uids map to the uids received from the onboarding configuration via the API
 */
// API location src/app/v1/api/configuration/onboarding/constants/actions.js
const ONBOARDING_PROGRESS = "onboarding_progress";
const ONBOARDING_INVITE = "onboarding_invite";
const ONBOARDING_SKIP = "onboarding_skip";
const POST_REQUEST = "post_request";
const PUT_REQUEST = "put_request";
export const ACTION_UIDS = {
  ONBOARDING_PROGRESS,
  ONBOARDING_INVITE,
  ONBOARDING_SKIP,
  POST_REQUEST,
  PUT_REQUEST
};

const PERMISSIONS = "permissions";
const COMPLETE = "complete";
const EDUCATION = "education";
const INVITE = "invite";
const QUIZ = "quiz";
const NOMINATE = "nominate";
const GOAL = "goal";
const SETUP = "setup";
const CONFIRM = "confirm";
const APP_SETUP = "app_setup";
const PATH_SELECT = "path_select";
const LINK_BUILDER = "link_builder";
const PAGE_BUILDER = "page_builder";
const COMPLETE_PAGE_BUILDER = "complete_page_builder";
const COMPLETE_LINK_BUILDER = "complete_link_builder";
const COMPLETE_PERMISSIONS = "complete_permissions";

const STRIPE_CONNECT_PERMISSIONS = "stripe_connect_permissions";
const STRIPE_CONNECT_COMPLETE_PERMISSIONS =
  "stripe_connect_complete_permissions";
export const COMPONENTS = {
  PERMISSIONS,
  COMPLETE,
  SETUP,
  CONFIRM,
  EDUCATION,
  INVITE,
  // Components named after categories
  QUIZ,
  NOMINATE,
  GOAL,
  PATH_SELECT,
  APP_SETUP,
  LINK_BUILDER,
  PAGE_BUILDER,
  COMPLETE_PAGE_BUILDER,
  COMPLETE_LINK_BUILDER,
  COMPLETE_PERMISSIONS,
  STRIPE_CONNECT_PERMISSIONS,
  STRIPE_CONNECT_COMPLETE_PERMISSIONS
};

export const CONFIRM_STEP = "confirm";
