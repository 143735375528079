import SERVICES from "utils/constants/services";

const SERVICE_STATE = Object.keys(SERVICES).reduce((memo, service) => {
  memo[service] =
    process.env.NODE_ENV === "production"
      ? `${service}-csrf-state`
      : `${service}-dev-csrf-state`;
  return memo;
}, {});

export const setCSRF = (service, state) =>
  typeof window !== "undefined" &&
  window.localStorage.setItem(SERVICE_STATE[service], state);

export const clearCSRF = (service) =>
  typeof window !== "undefined" &&
  window.localStorage.setItem(SERVICE_STATE[service], "");

export const getCSRF = (service) =>
  typeof window !== "undefined" &&
  window.localStorage.getItem(SERVICE_STATE[service]);
