import { TEMPLATE_PAGE_ENTITY_GROUPS } from "components/FormerEditor/common/constants/entity";
import { v4 as uuidv4 } from "uuid";
import { useAppEntities } from "./selectors";

export const isFirstPosition = (validation) =>
  validation && validation.position === 0;
export const isLastPosition = (validation) =>
  validation && validation.position === -1;

export const checkPinnedEntities = ({ entities }) => ({
  firstPinned: Boolean(
    entities.find(({ validation }) => isFirstPosition(validation))
  ),
  lastPinned: Boolean(
    entities.find(({ validation }) => isLastPosition(validation))
  )
});

export const getAllTemplateEntities = (appEntities) =>
  appEntities.map((entity) => ({
    ...entity,
    uuid: uuidv4()
  }));

export const reorderEntities = (orderedEntities, entities) =>
  orderedEntities.reduce((memo, entity) => {
    const match = entities.find((subject) => subject.uid === entity.uid);
    if (match) {
      memo.push(match);
    }
    return memo;
  }, []);

export const toggleTemplateEntity = ({
  orderedEntities,
  selectedEntities,
  selectedIndex,
  isSelected,
  entity,
  setFieldValue
}) => () => {
  let updatedSelectedEntities = [...selectedEntities];
  if (isSelected) {
    updatedSelectedEntities.splice(selectedIndex, 1);
  } else {
    updatedSelectedEntities.push(entity);
  }
  updatedSelectedEntities = reorderEntities(orderedEntities, [
    ...updatedSelectedEntities
  ]);

  setFieldValue("entities", updatedSelectedEntities);
};

export const entitiesForTemplate = (template, entities) =>
  TEMPLATE_PAGE_ENTITY_GROUPS[template]
    ? TEMPLATE_PAGE_ENTITY_GROUPS[template].reduce((memo, entityUid) => {
        const entMatch = entities.find((formEnt) => formEnt.uid === entityUid);
        if (entMatch) {
          memo.push(entMatch);
        }
        return memo;
      }, [])
    : [];

export const getEntityPinning = (appEntities, entityUID) => {
  const entityMatch = appEntities.find((entity) => entity.uid === entityUID);

  return Boolean(
    entityMatch &&
      entityMatch.validation &&
      typeof entityMatch.validation.position === "number"
  );
};

export const useEntityPinning = (entityUID) => {
  const appEntities = useAppEntities();

  return getEntityPinning(appEntities, entityUID);
};
