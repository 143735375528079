import { getEmbedStyles } from "constants/styles/embed";
import { useRouter } from "next/router";
import { getEmbedParams, getQueryEmbedEnabled } from "utils/embed";
import { getEmbedState } from "utils/manifest";

export const useEmbedEnabled = () => {
  const { query } = useRouter();

  return getQueryEmbedEnabled(query);
};

export const useEmbed = () => {
  const { query } = useRouter();
  const embedParams = getEmbedParams(query);
  return getEmbedState(embedParams);
};

export const useEmbedStyles = () => {
  const { query } = useRouter();
  if (!query.embed) {
    return null;
  }

  const { styles } = getEmbedStyles(query);
  return styles;
};
