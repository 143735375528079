import IMAGES from "utils/constants/image";
import { ROLES } from "utils/constants/role";

export const PAYMENT_LINK_MODE = "PAYMENT_LINK_MODE";
/**
 * *********************
 * Education Keys
 * *********************
 */
// Onboarding
export const ONBOARDING_QUIZ = "ONBOARDING_QUIZ";
export const LINK_BUILDER_ONBOARDING = "LINK_BUILDER_ONBOARDING";
export const PAGE_BUILDER_ONBOARDING = "PAGE_BUILDER_ONBOARDING";
export const APP_SETUP = "APP_SETUP";
export const PAGE_BUILDER_FEATURE_GROUPS_ONBOARDING =
  "PAGE_BUILDER_FEATURE_GROUPS_ONBOARDING";
export const CAMPAIGN_BUILDER_AUDIENCE_ONBOARDING =
  "CAMPAIGN_BUILDER_AUDIENCE_ONBOARDING";
// First time feature usage
export const USER_ACTIONS_DASHBOARD_FIRST = "USER_ACTIONS_DASHBOARD_FIRST";
export const EDITOR_FIRST_TIME = "EDITOR_FIRST_TIME";
// Merchant account
export const MERCHANT_ACCOUNT_VERIFICATION = "MERCHANT_ACCOUNT_VERIFICATION";
export const MERCHANT_ACCOUNT_CUSTOMER_SUPPORT =
  "MERCHANT_ACCOUNT_CUSTOMER_SUPPORT";
export const MERCHANT_ACCOUNT_BRANDING = "MERCHANT_ACCOUNT_BRANDING";
export const MERCHANT_ACCOUNT_EMAIL = "MERCHANT_ACCOUNT_EMAIL";
export const MERCHANT_ACCOUNT_TAX = "MERCHANT_ACCOUNT_TAX";
export const MERCHANT_ACCOUNT_REDIRECTS = "MERCHANT_ACCOUNT_REDIRECTS";
export const MERCHANT_ACCOUNT_RETURN_TO = "MERCHANT_ACCOUNT_RETURN_TO";

// export const MERCHANT_ACCOUNT_PROTECTION = "MERCHANT_ACCOUNT_PROTECTION";
export const MERCHANT_ACCOUNT_CHECKOUT = "MERCHANT_ACCOUNT_CHECKOUT";
export const MERCHANT_ACCOUNT_CHECKLIST_COMPLETE =
  "MERCHANT_ACCOUNT_CHECKLIST_COMPLETE";

export const ADMIN_MERCHANT_ACCOUNT_CHECKLIST_KEYS = [
  MERCHANT_ACCOUNT_VERIFICATION,
  MERCHANT_ACCOUNT_CUSTOMER_SUPPORT,
  MERCHANT_ACCOUNT_BRANDING,
  MERCHANT_ACCOUNT_EMAIL,
  MERCHANT_ACCOUNT_TAX,
  MERCHANT_ACCOUNT_CHECKOUT
  // MERCHANT_ACCOUNT_PROTECTION
];

/**
 * Only show these sections for verified users
 */
export const VERIFIED_MERCHANT_ACCOUNT_CHECKLIST_KEYS = [
  MERCHANT_ACCOUNT_REDIRECTS,
  MERCHANT_ACCOUNT_RETURN_TO
];

export const ROLE_VERIFIED_MERCHANT_ACCOUNT_CHECKLIST = {
  [ROLES.SUPER]: VERIFIED_MERCHANT_ACCOUNT_CHECKLIST_KEYS,
  [ROLES.ADMIN]: VERIFIED_MERCHANT_ACCOUNT_CHECKLIST_KEYS,
  [ROLES.OWNER]: VERIFIED_MERCHANT_ACCOUNT_CHECKLIST_KEYS,
  [ROLES.WRITER]: VERIFIED_MERCHANT_ACCOUNT_CHECKLIST_KEYS,
  [ROLES.READER]: null
};

export const ROLE_MERCHANT_ACCOUNT_CHECKLIST = {
  [ROLES.SUPER]: ADMIN_MERCHANT_ACCOUNT_CHECKLIST_KEYS,
  [ROLES.ADMIN]: ADMIN_MERCHANT_ACCOUNT_CHECKLIST_KEYS,
  [ROLES.OWNER]: ADMIN_MERCHANT_ACCOUNT_CHECKLIST_KEYS,
  [ROLES.WRITER]: ADMIN_MERCHANT_ACCOUNT_CHECKLIST_KEYS,
  [ROLES.READER]: null
};

const EDUCATION_SLIDES = {
  [USER_ACTIONS_DASHBOARD_FIRST]: [
    {
      image: IMAGES.LINK_USE_CASE_EMAIL,
      title: "Sell in any channel",
      header: "PriceBlocs payment links help you sell directly through email."
    },
    {
      image: IMAGES.LINK_USE_CASE_SOCIAL,
      title: "Monetize social",
      header: "Use payment links directly with your customers over chat."
    },
    {
      image: IMAGES.LINK_USE_CASE_VIDEO,
      title: "Live revenue",
      header:
        "Sell more easily over webinars with direct to checkout payment links."
    },
    {
      image: IMAGES.LINK_USE_CASE_SMS,
      title: "Maximize engagement",
      header:
        "Payment links can help you make the most of high open rates within SMS."
    }
    // {
    //   image: IMAGES.LINK_USE_CASE_CONTACTLESS,
    //   title: "Seamless in person payments",
    //   header: "Contactless selling made easy."
    // }
  ],
  [PAYMENT_LINK_MODE]: [
    {
      title: "Mode",
      header:
        "Event data is tracked in either live or test mode. Currently only payment links support both test and live mode. You can visualize events for either of these modes by toggling the menu where available."
    }
  ],
  [LINK_BUILDER_ONBOARDING]: [
    {
      image: IMAGES.PAYMENT_LINK_BUILD,
      title: "Build a payment link",
      header:
        "Choose products, prices, discounts and more, to add to your payment link."
    },
    {
      image: IMAGES.PAYMENT_LINK_OMNICHANNEL,
      title: "Omnichannel",
      header:
        "Payment links work everywhere your customers are, allowing you to accept payments in any channel with a single click."
    },
    {
      image: IMAGES.PAYMENT_LINK_PAYMENT_METHODS,
      title: "All major payment methods",
      header:
        "Payment links support all major payment methods including digital wallets like Apple and Google Pay."
    }
  ],
  [PAGE_BUILDER_ONBOARDING]: [
    {
      image: IMAGES.EDITOR_EDIT_BLOCK,
      title: "Choose your template",
      header:
        "You can build a checkout form to capture user information, or build a single page store for some of your products."
    },
    {
      image: IMAGES.BLOCKS_PICKER,
      title: "Choose your blocks",
      header:
        "Each page you build is made up of a collection of blocks. You can mix and match blocks for navigation, pricing and more to customize your page."
    }
  ],
  [EDITOR_FIRST_TIME]: [
    {
      image: IMAGES.EDITOR_EDIT_BLOCK,
      title: "Click to customize",
      header:
        "Each block can be customized with your own content. Just click on the block you want to edit to get started."
    },
    {
      image: IMAGES.EDITOR_BLOCK_SETTINGS,
      title: "Drag and drop",
      header:
        "You can reorder your blocks by dragging them up or down. Each block has a settings menu which allows you to control how their content is presented."
    },
    {
      image: IMAGES.EDITOR_SIDE_NAV,
      title: "Page settings",
      header:
        "Use the side nav to see more page builder elements like products, features and settings."
    },
    {
      image: IMAGES.EDITOR_PUBLISH,
      title: "Publish your page",
      header:
        "When you're ready to go live with your page, click publish to make it available to the world!"
    }
  ],
  [PAGE_BUILDER_FEATURE_GROUPS_ONBOARDING]: [
    {
      image: IMAGES.FEATURE_COMPARISON,
      title: "Compare your product features",
      header:
        "Add and group your features per product to help your customers compare their options."
    },
    {
      image: IMAGES.PRODUCT_FEATURE_SHOW,
      title: "Show whats in your products",
      header:
        "Show which features are included in each product by toggling them on and off with a simple click in each cell."
    },
    {
      image: IMAGES.PRODUCT_FEATURE_TOOLTIP,
      title: "Add feature details",
      header:
        "Add an optional tooltip and customize how the product feature is presented by editing it's details."
    }
  ],
  [CAMPAIGN_BUILDER_AUDIENCE_ONBOARDING]: [
    {
      // image: IMAGES.FEATURE_COMPARISON,
      title: "Choose your audience",
      header:
        "Create a new audience by uploading a csv of contacts or use one you've created already"
    }
  ]
};

export const FEATURE = {
  USER_ACTIONS: "user_actions",
  LINK_BUILDER: "link_builder",
  PAGE_BUILDER: "page_builder",
  PAGE_BUILDER_FEATURE_GROUPS: "page_builder_feature_groups",
  CAMPAIGN_BUILDER_AUDIENCE: "campaign_builder_audience",
  EDITOR: "editor",
  MERCHANT_ACCOUNT: "merchant_account"
};

const MERCHANT_ACCOUNT_CONTEXT = {
  MERCHANT_ACCOUNT_VERIFICATION: "merchant_account_verification",
  MERCHANT_ACCOUNT_CUSTOMER_SUPPORT: "merchant_account_customer_support",
  MERCHANT_ACCOUNT_BRANDING: "merchant_account_branding",
  MERCHANT_ACCOUNT_EMAIL: "merchant_account_email",
  MERCHANT_ACCOUNT_TAX: "merchant_account_tax",
  MERCHANT_ACCOUNT_REDIRECTS: "merchant_account_redirects",
  MERCHANT_ACCOUNT_RETURN_TO: "merchant_account_return_to",
  MERCHANT_ACCOUNT_CHECKOUT: "merchant_account_checkout",
  MERCHANT_ACCOUNT_CHECKLIST_COMPLETE: "merchant_account_checklist_complete"
  // MERCHANT_ACCOUNT_PROTECTION: "merchant_account_protection",
};

export const CONTEXT = {
  DASHBOARD_FIRST_TIME: "dashboard_first_time",
  ONBOARDING: "onboarding",
  EDITOR_FIRST_TIME: "editor_first_time",
  ...MERCHANT_ACCOUNT_CONTEXT
};

const MERCHANT_ACCOUNT_CONFIG = {
  [MERCHANT_ACCOUNT_VERIFICATION]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_VERIFICATION
  },
  [MERCHANT_ACCOUNT_CUSTOMER_SUPPORT]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_CUSTOMER_SUPPORT
  },
  [MERCHANT_ACCOUNT_BRANDING]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_BRANDING
  },
  [MERCHANT_ACCOUNT_EMAIL]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_EMAIL
  },
  [MERCHANT_ACCOUNT_TAX]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_TAX
  },
  [MERCHANT_ACCOUNT_REDIRECTS]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_REDIRECTS
  },
  [MERCHANT_ACCOUNT_RETURN_TO]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_RETURN_TO
  },
  // [MERCHANT_ACCOUNT_PROTECTION]: {
  //   feature: FEATURE.MERCHANT_ACCOUNT,
  //   context: CONTEXT.MERCHANT_ACCOUNT_PROTECTION
  // },
  [MERCHANT_ACCOUNT_CHECKOUT]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_CHECKOUT
  },
  [MERCHANT_ACCOUNT_CHECKLIST_COMPLETE]: {
    feature: FEATURE.MERCHANT_ACCOUNT,
    context: CONTEXT.MERCHANT_ACCOUNT_CHECKLIST_COMPLETE
  }
};

const EDUCATION_CONFIG = {
  [USER_ACTIONS_DASHBOARD_FIRST]: {
    feature: FEATURE.USER_ACTIONS,
    context: CONTEXT.DASHBOARD_FIRST_TIME
  },
  [LINK_BUILDER_ONBOARDING]: {
    feature: FEATURE.LINK_BUILDER,
    context: CONTEXT.ONBOARDING
  },
  [PAGE_BUILDER_ONBOARDING]: {
    feature: FEATURE.PAGE_BUILDER,
    context: CONTEXT.ONBOARDING
  },
  [PAGE_BUILDER_FEATURE_GROUPS_ONBOARDING]: {
    feature: FEATURE.PAGE_BUILDER_FEATURE_GROUPS,
    context: CONTEXT.ONBOARDING
  },
  [CAMPAIGN_BUILDER_AUDIENCE_ONBOARDING]: {
    feature: FEATURE.CAMPAIGN_BUILDER_AUDIENCE,
    context: CONTEXT.ONBOARDING
  },
  [EDITOR_FIRST_TIME]: {
    feature: FEATURE.EDITOR,
    context: CONTEXT.EDITOR_FIRST_TIME
  },
  ...MERCHANT_ACCOUNT_CONFIG
};

export const getEducationSlidesForKey = (key) => EDUCATION_SLIDES[key];

export const getEducationConfigForKey = (key) => EDUCATION_CONFIG[key];

export const STRIPE_EDUCATION = {
  AUTOMATIC_TAX: {
    title: "Auto tax collection",
    description: `When enabled, taxes will be automatically calculated and collected at checkout. When disabled, you will be able to manually add one or more tax rates to be used within a checkout session.`,
    disabled: `Activate Stripe Tax within your Stripe dashboard to get started with auto tax collection. Once activated, you can enable auto tax collection here within PriceBlocs.`,
    disclaimer: `Note: please make sure automatic tax collection is also enabled for your Stripe account.`,
    checkout: `Enable to automatically calculate and collect taxes at checkout. Disable to add a tax rate manually.`
  },
  TAX_ID_COLLECTION: {
    title: "Tax id collection",
    description: `When enabled, your customers will be prompted to add a business name and tax id during checkout when purchasing as a business.`,
    checkout: `Allow your customers to enter a business name and tax id during checkout.`,
    disclaimer: `Standard is suitable for collecting tax ids from European & Australasian customers.`,
    international: `Select International if you're collecting tax ids from customers outside that area.`
  },
  ALLOW_PROMOTION_CODES: {
    title: "Allow promotion codes",
    description: `When enabled, your customers can enter a valid promotion code during checkout.`,
    checkout: `Allow your customers to enter a promotion code during checkout. If enabled, additional discounts cannot be added.`,
    disabled: `Promotion codes can't be enabled when a discount is already present.`
  }
};
