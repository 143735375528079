import CONSTANTS from "constants/auth";
import { fromJS } from "immutable";

export const initialState = fromJS({
  loading: false,
  note: null,
  data: null
});

const internals = {
  [CONSTANTS.SET_AUTH]: (state, { payload }) => {
    return state.set("data", fromJS(payload));
  },
  [CONSTANTS.UPDATE_AUTH_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
