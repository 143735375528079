import get from "lodash/get";
import {
  ADDRESS_KEYS,
  ADDRESS_KEY_LABEL_MAP,
  // ADDRESS_LOCATION_KEYS,
  ADDRESS_LABEL_KEYS,
  REQUIRED_ADDRESS_KEYS,
  REQUIRED_ADDRESS_RECEIVER_KEYS,
  ADDRESS_RECEIVER_KEY_LABEL_MAP
} from "utils/constants/address";

const getShortOrLong = (value) =>
  value.short.length > 1 ? value.short : value.long;

/**
 * @typedef {Object} FormGeocodedAddress
 * @property {Object} servicePlaceId - GooglePlaceID
 * @property {Object} location - lat lng
 * @property {Object} address - lat lng
 */

/**
 *
 * @param {Object} details
 * @param {String} details.place_id
 * @param {String} details.formatted_address
 * @param {Array} details.address_components
 * @returns {FormGeocodedAddress}
 *
 */
export const googlePlaceToAddress = (details) => {
  const address = {
    // City, district, suburb, town, or village.
    [ADDRESS_KEYS.CITY]: null,
    // Two-letter country code (ISO 3166-1 alpha-2).
    [ADDRESS_KEYS.COUNTRY]: null,
    // Address line 1 (e.g., street, PO Box, or company name).
    [ADDRESS_KEYS.LINE_ONE]: [],
    // Address line 2 (e.g., apartment, suite, unit, or building).
    [ADDRESS_KEYS.LINE_TWO]: [],
    // ZIP or postal code.
    [ADDRESS_KEYS.POSTAL_CODE]: null,
    // State, county, province, or region.
    [ADDRESS_KEYS.STATE]: null
  };

  // const location = details.geometry.location;
  const result = {
    servicePlaceId: details.place_id,
    location: {
      lat: null,
      lng: null
    }
    // Not meant to store the location per the google terms - if needed we can call a refresh on using the place id using the request above
    // location: ADDRESS_LOCATION_KEYS.reduce((memo, key) => {
    //   memo[key] =
    //     typeof location[key] === "function"
    //       ? location[key]()
    //       : typeof location[key] === "number"
    //       ? location[key]
    //       : null;
    //   return memo;
    // }, {})
  };

  /**
   * Parse formatted address to parts in case we are unable to find the right 'types' of address values
   */
  const fallbackFragments =
    details && details.formatted_address
      ? details.formatted_address.split(", ").map((fragment) => fragment.trim())
      : null;

  /**
   * Question:
   * - What field does a business come in on?
   * Answer:
   * - It doesnt really. You can search by business name and see seach predictions
   * - but the response will have line1 as the know postal address e.g.
   * - The type of establishment will be listed under types
   * Query: Le Beau market
   * Result: {
   *  "line1": "1263 Leavenworth St",
   *  ...,
   *  "types": ["establishment", "grocery_or_supermarket", "store"]
   * }
   */
  const allValues = {};
  details.address_components.reduce(
    (memo, { short_name, long_name, types }) => {
      allValues[types[0]] = { short: short_name, long: long_name };
      return memo;
    },
    {}
  );
  /**
   * City
   */
  if (allValues.locality) {
    address[ADDRESS_KEYS.CITY] = allValues.locality.long;
  } else if (allValues.postal_town) {
    address[ADDRESS_KEYS.CITY] = allValues.postal_town.long;
  } else if (fallbackFragments && fallbackFragments[1]) {
    address[ADDRESS_KEYS.CITY] = fallbackFragments[1];
  }

  /**
   * Country
   */
  if (allValues[ADDRESS_KEYS.COUNTRY]) {
    address[ADDRESS_KEYS.COUNTRY] = allValues[ADDRESS_KEYS.COUNTRY].short;
  }

  /**
   * Line 1
   */
  if (allValues.street_number) {
    address[ADDRESS_KEYS.LINE_ONE].push(allValues.street_number.short);
  }
  if (allValues.route) {
    address[ADDRESS_KEYS.LINE_ONE].push(allValues.route.short);
  } else if (allValues.intersection) {
    address[ADDRESS_KEYS.LINE_ONE].push(allValues.intersection.short);
  }
  if (
    address[ADDRESS_KEYS.LINE_ONE].length === 0 &&
    fallbackFragments &&
    fallbackFragments[0]
  ) {
    address[ADDRESS_KEYS.LINE_ONE] = fallbackFragments[0];
  }

  /**
   * Line 2
   */
  if (allValues.neighborhood) {
    address[ADDRESS_KEYS.LINE_TWO].push(getShortOrLong(allValues.neighborhood));
  } else {
    if (allValues.premise) {
      address[ADDRESS_KEYS.LINE_TWO].push(allValues.premise.short);
    }
    if (allValues.subpremise) {
      address[ADDRESS_KEYS.LINE_TWO].push(allValues.subpremise.short);
    }
  }

  /**
   * Postal code
   */
  if (allValues.postal_code) {
    address[ADDRESS_KEYS.POSTAL_CODE] = allValues.postal_code.short;
  }

  /**
   * State
   * - States with chars over 1 are allowed
   * - e.g. Dublin short is D so we use Country Dublin instead
   */
  if (allValues.administrative_area_level_1) {
    address[ADDRESS_KEYS.STATE] = getShortOrLong(
      allValues.administrative_area_level_1
    );
  }

  for (const key in address) {
    if (Array.isArray(address[key])) {
      address[key] = address[key].join(" ");
    }
  }

  result.address = address;

  return result;
};

export const addressToPlaceLabel = (
  address,
  keys = ADDRESS_LABEL_KEYS,
  delimeter = ", "
) =>
  keys
    .reduce((memo, key) => {
      memo.push(
        Array.isArray(key)
          ? addressToPlaceLabel(address, key, " ")
          : address[key]
      );
      return memo;
    }, [])
    .join(delimeter);

export const addressToSelectOption = ({ address, servicePlaceId }, focus) => {
  const formattedAddress = addressToPlaceLabel(address);
  const focusFormat = focus
    ? formattedAddress
    : formattedAddress.split(", ")[0];

  return {
    label: focusFormat,
    value: {
      description: focusFormat,
      place_id: servicePlaceId,
      reference: servicePlaceId
    }
  };
};

export const getAddressRequiredStatus = ({ name, errors, touched }) => {
  const result = {
    status: {},
    error: null
  };

  const requiredKeys = Object.values(REQUIRED_ADDRESS_KEYS);
  for (let index = 0; index < requiredKeys.length; index++) {
    const key = requiredKeys[index];
    const requiredKeyPath = `${name}.address.${key}`;

    const requiredKeyError = get(errors, requiredKeyPath) || null;
    const requiredKeyTouched = get(touched, requiredKeyPath) || null;
    const requiredError = Boolean(requiredKeyError && requiredKeyTouched);
    if (!result.error && requiredError) {
      result.error = true;
    }
    result.status[key] = {
      error: requiredKeyError,
      touched: requiredKeyTouched
    };
  }

  return result;
};

export const validateAddress = (values) => {
  let result = null;

  for (const key in REQUIRED_ADDRESS_KEYS) {
    const requiredKey = REQUIRED_ADDRESS_KEYS[key];

    const value = values ? values[requiredKey] : null;
    const requiredViolation = !value;
    const minViolation = value && value.length < 2;
    if (
      (requiredViolation || minViolation) &&
      ADDRESS_KEY_LABEL_MAP[requiredKey]
    ) {
      if (!result) {
        result = {};
      }
      const keyLabel = ADDRESS_KEY_LABEL_MAP[requiredKey];

      result[requiredKey] = `${keyLabel} is ${
        requiredViolation ? "required" : "too short"
      }.`;
    }
  }

  return result;
};

export const validateAddressReceiver = (values) => {
  let result = null;

  for (const key in REQUIRED_ADDRESS_RECEIVER_KEYS) {
    const receiverKey = REQUIRED_ADDRESS_RECEIVER_KEYS[key];
    const receiverName = values && values[receiverKey];
    if (!receiverName) {
      if (!result) {
        result = {};
      }
      result[
        receiverKey
      ] = `${ADDRESS_RECEIVER_KEY_LABEL_MAP[receiverKey]} is required`;
    }
  }
  return result;
};

export const getAddressFieldProps = ({ key, customClasses }) => ({
  name: `address.${key}`,
  placeholder: ADDRESS_KEY_LABEL_MAP[key],
  customClasses
});
