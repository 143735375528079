import {
  PRODUCT_COLLECTION_INTENT,
  PRICING_TABLE_INTENT
} from "utils/constants/pricing";
import { STATE_KEYS } from "utils/constants/state";
import { BUILDER_INTENT } from "./builder";

export const PRICING_INTENT_BILLING_FOCUS = {
  key: STATE_KEYS.EDITOR.PRICING_INTENT,
  value: PRICING_TABLE_INTENT.BILLING_TYPE
};

export const FEATURES_ACTIVE_SECTION_FOCUS = {
  key: STATE_KEYS.EDITOR.ACTIVE_SECTION,
  // Note: SECTIONS import is undefined for some reason
  value: "features"
};

export const PRODUCT_COLLECTION_INTENT_PRODUCT_FOCUS = {
  key: STATE_KEYS.EDITOR.MENU_INTENT,
  value: PRODUCT_COLLECTION_INTENT.PRODUCT_ORDER
};

const COMMON = "common";
const CONSTANT = "constant";
const COMBINATIONS = "combinations";
export const FOCUS_CHECKOUT_KEY = {
  COMMON,
  CONSTANT,
  COMBINATIONS
};

export const VISIBLE_ENTITY_SETTINGS_FOCUS = [
  {
    key: STATE_KEYS.EDITOR.INTENT,
    value: BUILDER_INTENT.ENTITY_SETTINGS
  },
  {
    key: STATE_KEYS.EDITOR.VISIBILITY_PANEL,
    value: true
  }
];
