export const CONFIG_PRICING_LICENSE_TYPE = "config.pricing.licenseType";
export const CONFIG_PRICING_DEFAULT_INTERVAL = "config.pricing.defaultInterval";
export const CONFIG_PRICING_DEFAULT_CURRENCY = "config.pricing.defaultCurrency";
export const CONFIG_PRICING_EMPHASIS_INDEX = "config.pricing.emphasisIndex";
export const CONFIG_PRICING_SHOW_IMAGERY = "config.pricing.showImagery";

/**
 * /render page decoration keys
 * - The server will tack these keys on conditionally based on current context of the render
 * - i.e customer is in a campaign and has a checkout value
 * - i.e customer has checkout connect values based on current context
 */
export const CAMPAIGN_CHECKOUT = "campaign.checkout";
export const CONNECT = "connect";

export const PRODUCT_SUBSCRIPTION_SCHEDULE = "product.subscription_schedule";

/**
 * What is this?
 */
export const CONFIG_FEATURES_SHOW = "config.features.common.show";
export const CONFIG_CHECKOUT_COMMON = "config.checkout.common";

export const ADMIN = "admin";
export const PRICE_DATA = "data.prices";
export const PRODUCT_DATA = "data.products";
export const ENTITY_DATA = "data.entities";
export const CONTENT_DATA = "data.content";
const MANIFEST_DATA = "manifest.data";
const THEME_DATA = "theme.data";

export const SHIPPING_OPTIONS = "shipping_options";
export const SHIPPING_ADDRESS_COLLECTION = "shipping_address_collection";
export const TRIAL_PERIOD_DAYS = "trial_period_days";
export const TRIAL_END = "trial_end";

export const ENABLED = "enabled";
export const TYPE = "type";
export const AUTOMATIC_TAX = "automatic_tax";
export const TAX_ID_COLLECTION = "tax_id_collection";
export const CONFIRMATION = "confirmation";

export const SUBSCRIPTION_SCHEDULE = "subscription_schedule";
export const AUTOMATIC_TAX_ENABLED = "automatic_tax.enabled";
export const FIRST_PHASE_ITERATIONS_PATH = "phases[0].iterations";

const IDENTITY_META_SESSION_DOCUMENT = "meta.session.options.document";
const FILE_META_OPTIONS = "meta.options";

export const DATA_ADMIN = "data.admin";
export const DATA_ADMIN_CLIENT_KEY = `${DATA_ADMIN}.clientKey`;
export const MANIFEST_DATA_ADMIN_CLIENT_KEY = `manifest.${DATA_ADMIN_CLIENT_KEY}`;

export const ACCOUNT_SHOW_BANNER = "account.showBanner";

export const STATE_KEYS = {
  EDITOR: {
    INTENT: "editor.intent",
    VIEWPORT: "editor.viewport",
    MODE: "editor.mode",
    VISIBILITY_PANEL: "editor.visibility.panel",
    ACTIVE_BLOCK: "editor.activeBlock",
    ACTIVE_SECTION: "editor.activeSection",
    PRICING: "editor.pricing",
    // PricingTable intent
    // - BillingType | FeatureList | ProductOrder
    PRICING_INTENT: "editor.pricing.intent",
    // > Product add | Price add
    PRODUCT_ORDER_INTENT: "editor.productOrder.intent",
    PRICING_ACTIVE_PRICE: "editor.pricing.activePrice",
    PRICING_ACTIVE_PRODUCT: "editor.pricing.activeProduct",

    /**
     * Some additional config for the payment link collection layout
     * ProductOrder | LayoutSettings | PaymentSettings
     */
    MENU: "editor.menu",
    MENU_INTENT: "editor.menu.intent",
    MENU_SUB_INTENT: "editor.menu.subIntent",
    MENU_THIRD_INTENT: "editor.menu.thirdIntent",
    MENU_ACTIVE_INDEX: "editor.menu.activeIndex",
    MENU_ACTIVE_SUB_INDEX: "editor.menu.activeSubIndex",

    // Product feature order
    FEATURE_INTENT: "editor.feature.intent",
    FEATURE_ACTIVE_PRODUCT: "editor.feature.activeProduct",
    PUBLISH_SHOW_MENU: "editor.publish.showMenu",
    /**
     * Control on form validation
     */
    VALIDATIONS_FIELDS_ENABLED: "editor.validations.fields.enabled"
  },
  MANIFEST: {
    UUID: "manifest.uuid",
    UPDATED_AT: "manifest.updatedAt",
    CONTEXT: "manifest.context",
    DATA: MANIFEST_DATA,
    ADMIN: `${MANIFEST_DATA}.${ADMIN}`,
    ADMIN_ACCOUNT: `${MANIFEST_DATA}.${ADMIN}.account`,
    ADMIN_ASSET_UPLOAD_KEY: `${MANIFEST_DATA}.${ADMIN}.assetUploadKey`,
    ADMIN_SUBDOMAIN: `${MANIFEST_DATA}.${ADMIN}.subdomain`,
    ADMIN_ALIAS: `${MANIFEST_DATA}.${ADMIN}.alias`,
    CONFIG: `${MANIFEST_DATA}.config`,
    ENTITIES: `${MANIFEST_DATA}.entities`,
    /**
     * Order of the entities within the layout
     * e.g. [{uuid: 'navigation-uuid'}, {uuid: 'banner-uuid'}]
     */
    LAYOUT: `${MANIFEST_DATA}.layout`,
    CONTENT: `${MANIFEST_DATA}.content`,
    CONNECT: `${MANIFEST_DATA}.connect`,
    CAMPAIGN: `${MANIFEST_DATA}.campaign`,
    PRICING_PRIMARIES: `${MANIFEST_DATA}.config.pricing.primaries`,
    CONFIG_CHECKOUT_COMMON: `${MANIFEST_DATA}.${CONFIG_CHECKOUT_COMMON}`,
    /**
     * Order of the products within pricing components within the layout
     * e.g. [{uuid: 'prod_IdtIa0DicpBE75'}, {uuid: 'prod_IdtLTITI0wAD1F'}]
     */
    PRICING_LAYOUT: `${MANIFEST_DATA}.config.pricing.layout`,
    PRICING_EMPHASIS_INDEX: `${MANIFEST_DATA}.${CONFIG_PRICING_EMPHASIS_INDEX}`,
    PRICING_DEFAULT_INTERVAL: `${MANIFEST_DATA}.${CONFIG_PRICING_DEFAULT_INTERVAL}`,
    PRICING_DEFAULT_CURRENCY: `${MANIFEST_DATA}.${CONFIG_PRICING_DEFAULT_CURRENCY}`,
    PRICING_LICENSE_TYPE: `${MANIFEST_DATA}.${CONFIG_PRICING_LICENSE_TYPE}`,
    PRICING_SHOW_IMAGERY: `${MANIFEST_DATA}.${CONFIG_PRICING_SHOW_IMAGERY}`,
    FEATURES: `${MANIFEST_DATA}.config.features`,
    FEATURES_CLUSIVITY: `${MANIFEST_DATA}.config.features.common.clusivity`,
    FEATURES_SHOW: `${MANIFEST_DATA}.${CONFIG_FEATURES_SHOW}`,
    FEATURES_LIMIT: `${MANIFEST_DATA}.config.features.common.limit`,
    FEATURES_ORDER: `${MANIFEST_DATA}.config.features.common.order`,
    FEATURES_LAYOUT: `${MANIFEST_DATA}.config.features.common.layout`,
    FEATURE_GROUPS_LAYOUT: `${MANIFEST_DATA}.config.featureGroups.layout`
  },
  THEME: {
    DATA: THEME_DATA,
    FONT: `${THEME_DATA}.font`,
    FONT_FAMILY: `${THEME_DATA}.font.family`,
    COLOR_PRIMARY: `${THEME_DATA}.color.primary`,
    COLOR_SECONDARY: `${THEME_DATA}.color.secondary`,
    COLOR_HIGHLIGHT: `${THEME_DATA}.color.highlight`,
    TEXT_PRIMARY: `${THEME_DATA}.text.primary`,
    TEXT_SECONDARY: `${THEME_DATA}.text.secondary`
  },
  PRICE: {
    INTERVAL: "recurring.interval"
  },
  MERCHANT_ACCOUNT_CONFIG: {
    TRIAL_PERIOD_DAYS: `data.pricing.common.${TRIAL_PERIOD_DAYS}`,
    SUCCESS_URL: "data.common.success_url",
    CANCEL_URL: "data.common.cancel_url",
    RETURN_URL: "data.common.return_url",
    RETURN_COPY: "data.common.return_copy",
    BRANDING_ICON: "data.common.branding.icon",
    BRANDING_LOGO: "data.common.branding.logo",
    EMAIL_RECEIPT: "data.common.email.receipt",
    EMAIL_NOTIFICATION: "data.common.email.notification",
    BILLING_UNSUBSCRIBE: "data.common.billing.unsubscribe",
    AUTOMATIC_TAX_ENABLED: `data.common.${AUTOMATIC_TAX_ENABLED}`,
    TAX_ID_COLLECTION_ENABLED: "data.common.tax_id_collection.enabled",
    TAX_ID_COLLECTION_TYPE: "data.common.tax_id_collection.type",
    BILLING_PORTAL: "data.common.billing.portal",
    BILLING_SCOPES: "data.common.billing.scopes"
  },
  ACCOUNT: {
    CHARGES_ENABLED: "account.chargesEnabled",
    DETAILS_SUBMITTED: "account.detailsSubmitted",
    BUSINESS_PROFILE_NAME: "account.business_profile.name"
  },
  EVENT: {
    FULFILLMENT_STATUS: "data.fulfillment.status"
  },
  SALE: {
    MODE: "data.mode",
    CUSTOMER: "data.customer",
    CUSTOMER_EMAIL: "data.customer_email",
    OWNER_EMAIL: "data.owner_email",
    LINE_ITEMS: "data.line_items",
    INTEGRATION_ID: "data.integration_id",
    SHORT_LINK_SHORT_ID: "data.short_link_short_id",
    SHORT_LINK_ID: "data.short_link_id",
    MANIFEST_ID: "data.manifest_id",
    PLATFORM_SOURCE: "data.platform_source",
    SUBDOMAIN: "data.subdomain",
    DISCOUNTS: "data.discounts",
    SHIPPING_RATE: "data.shipping_rate",
    TAX_RATES: "data.tax_rates",
    SHIPPING_OPTIONS: "data.shipping_options",
    TRIAL_PERIOD_DAYS: "data.trial_period_days",
    TRIAL_END: "data.trial_end",
    ALIAS: "data.alias",
    ROUTE: "data.route",
    PAYMENT_STATUS: "data.payment_status",
    FULFILLMENT: "data.fulfillment",
    FULFILLMENT_STATUS: "data.fulfillment.status",
    FULFILLMENT_REQUIRES_SHIPPING: "data.fulfillment.requires_shipping",
    INTEGRATION_ORDER_ID: "data.integration_order_id",
    FORM_DATA: "data.form_data",
    AMOUNT_SUBTOTAL: "data.amount_subtotal",
    AMOUNT_DISCOUNT: "data.amount_discount",
    AMOUNT_TOTAL: "data.amount_total",
    CURRENCY: "data.currency",
    PAYMENT_INTENT_ID: "data.payment_intent_id",
    SETUP_ID: "data.setup_intent_id",
    SUBSCRIPTION: "data.subscription",
    SESSION_ID: "data.session_id"
  },
  SESSION: {
    SUBSCRIPTION: "subscription",
    PAYMENT_INTENT_ID: "payment_intent.id",
    PAYMENT_INTENT_CAPTURE_METHOD: "payment_intent.capture_method",
    METADATA_FEE_PERCENT: "metadata.fee_percent",
    AFTER_EXPIRATION_RECOVERY_ENABLED: "after_expiration.recovery.enabled",
    AFTER_EXPIRATION_RECOVERY_URL: "after_expiration.recovery.url",
    CONSENT_PROMOTIONS: "consent.promotions",
    ADJUSTABLE_QUANTITY_ENABLED: "adjustable_quantity.enabled",
    ADJUSTABLE_QUANTITY_MINIMUM: "adjustable_quantity.minimum",
    ADJUSTABLE_QUANTITY_MAXIMUM: "adjustable_quantity.maximum",
    RECOVERED_FROM: "recovered_from",
    CUSTOMER_DETAILS_EMAIL: "customer_details.email",
    SHIPPING_RATE: "shipping_rate"
  },
  APPLICATION: {
    ALLOWLIST_DOMAINS: "data.allowlist.domains"
  },
  SHORT_LINK: {
    TITLE: "title",
    DESCRIPTION: "description",
    STATUS: "status",
    SOURCE: "source",
    EXPIRATION: "expiration",
    SUCCESS_URL: "data.success_url",
    CANCEL_URL: "data.cancel_url",
    APPLICATION_ID: "data.application_id",
    MERCHANT_ACCOUNT_ID: "data.merchant_account_id",
    CAMPAIGN_ID: "data.campaign_id",
    CONFIRMATION: "data.confirmation",
    CONFIRMATION_TEMPLATE: "data.confirmation.template",
    CONFIRMATION_DATA: "data.confirmation.data",
    FIELDS: "data.fields",
    FIELDS_PRE: "data.fields.pre",
    FIELDS_POST: "data.fields.post",
    PRIMARIES: "data.product.primaries",
    PRICES: "data.product.prices",
    ATTACHMENTS: "data.product.attachments",
    CHECKOUT: "data.product.checkout",
    DISCOUNTS: "data.product.checkout.discounts",
    ALLOW_PROMOTION_CODES: "data.product.checkout.allow_promotion_codes",
    // Taxes
    TAX_RATES: "data.product.checkout.tax_rates",
    DYNAMIC_TAX_RATES: "data.product.checkout.dynamic_tax_rates",
    AUTOMATIC_TAX: "data.product.checkout.automatic_tax",
    AUTOMATIC_TAX_ENABLED: `data.product.checkout.${AUTOMATIC_TAX_ENABLED}`,
    TAX_ID_COLLECTION: "data.product.checkout.tax_id_collection",
    TAX_ID_COLLECTION_ENABLED:
      "data.product.checkout.tax_id_collection.enabled",
    TAX_ID_COLLECTION_TYPE: "data.product.checkout.tax_id_collection.type",
    TRIAL_PERIOD_DAYS: `data.product.checkout.${TRIAL_PERIOD_DAYS}`,
    TRIAL_END: `data.product.checkout.${TRIAL_END}`,
    MODE: "data.product.checkout.mode",
    CUSTOMER: "data.product.checkout.customer",
    CUSTOMER_EMAIL: "data.product.checkout.customer_email",
    PAYMENT_METHOD_TYPES: "data.product.checkout.payment_method_types",
    PAYMENT_INTENT_DATA: "data.product.checkout.payment_intent_data",
    PAYMENT_INTENT_DATA_CAPTURE_METHOD:
      "data.product.checkout.payment_intent_data.capture_method",
    PAYMENT_INTENT_DATA_SETUP_FUTURE_USAGE:
      "data.product.checkout.payment_intent_data.setup_future_usage",
    SUBSCRIPTION_SCHEDULE: "data.product.subscription_schedule",
    SHIPPING_ADDRESS_COLLECTION: `data.product.checkout.${SHIPPING_ADDRESS_COLLECTION}`,
    SHIPPING_OPTIONS: `data.product.checkout.${SHIPPING_OPTIONS}`,
    SHIPPING_ALLOWED_COUNTRIES: `data.product.checkout.${SHIPPING_ADDRESS_COLLECTION}.allowed_countries`,
    SHIPPING_WORLDWIDE: "data.product.checkout.shipping_worldwide"
  },
  CAMPAIGN: {
    TITLE: "title",
    DESCRIPTION: "description",
    STATUS: "status",
    SUCCESS_URL: "data.success_url",
    CANCEL_URL: "data.cancel_url",
    RECEIVER: "data.receiver",
    DATASOURCES: "data.receiver.datasources",
    AUDIENCES: "data.receiver.audiences",
    PRIMARIES: "data.product.primaries",
    ATTACHMENTS: "data.product.attachments",
    CHECKOUT: "data.product.checkout",
    DISCOUNTS: "data.product.checkout.discounts",
    TAX_RATES: "data.product.checkout.tax_rates",
    DYNAMIC_TAX_RATES: "data.product.checkout.dynamic_tax_rates",
    SHIPPING_OPTIONS: `data.product.checkout.${SHIPPING_OPTIONS}`,
    TRIAL_PERIOD_DAYS: `data.product.checkout.${TRIAL_PERIOD_DAYS}`,
    TRIAL_END: `data.product.checkout.${TRIAL_END}`,
    MODE: "data.product.checkout.mode",
    CUSTOMER_EMAIL: "data.product.checkout.customer_email",
    PAYMENT_METHOD_TYPES: "data.product.checkout.payment_method_types",
    SHIPPING_ADDRESS_COLLECTION: `data.product.checkout.${SHIPPING_ADDRESS_COLLECTION}`,
    SHIPPING_ALLOWED_COUNTRIES: `data.product.checkout.${SHIPPING_ADDRESS_COLLECTION}.allowed_countries`,
    SHIPPING_WORLDWIDE: "data.product.checkout.shipping_worldwide"
  },
  FORM: {
    FIELD_GROUPS: "data.fieldGroups",
    CONFIG_LOGIC_TYPE: "config.logic.type",
    CONFIG_LOGIC_CHECKOUTS_CONSTANT: "config.logic.checkouts.constant",
    CONFIG_LOGIC_CHECKOUTS_FIELDS: "config.logic.checkouts.fields",
    CONFIG_LOGIC_CHECKOUTS_COMBOS: "config.logic.checkouts.combinations",
    CONFIG_CHECKOUT_COMMON
  },
  PRODUCT_COLLECTION: {
    PRODUCTS: "data.products",
    ACTIONS: "data.actions",
    CONFIG_PRODUCTS_CHECKOUTS: "config.products.checkouts",
    CONFIG_CART: "config.cart",
    CONFIG_CART_PRODUCTS: "config.cart.products",
    CONFIG_CHECKOUT_COMMON
  },
  ENTITY_CONFIG: {
    BRAND_SHOW: "brand.show",
    INITIAL_PREVIEW_SHOW: "initialPreview.show"
  },
  INSIGHTS: {
    TYPE: "type",
    SUBDOMAIN: "subdomain"
  },
  IDENTITY: {
    HEADER: `meta.header`,
    TITLE: `meta.title`,
    PROMPT: `meta.prompt`,
    TYPE: `meta.session.type`,
    ALLOWED_TYPES: `${IDENTITY_META_SESSION_DOCUMENT}.allowedTypes`,
    REQUIRE_ID_NUMBER: `${IDENTITY_META_SESSION_DOCUMENT}.requireIdNumber`,
    REQUIRE_LIVE_CAPTURE: `${IDENTITY_META_SESSION_DOCUMENT}.requireLiveCapture`,
    REQUIRE_MATCHING_SELFIE: `${IDENTITY_META_SESSION_DOCUMENT}.requireMatchingSelfie`
  },
  ADDRESS: {
    TYPE: `meta.type`,
    SERVICE: `meta.service`,
    RECEIVER_ENABLED: `meta.receiver.enabled`,
    RECEIVER_PLACEHOLDER: `meta.receiver.placeholder`,
    RECEIVER_REQUIRED: `meta.receiver.required`
  },
  LEGAL: {
    ACCEPTANCE_CLICKED: "acceptance.clicked",
    ACCEPTANCE_TYPE: "meta.acceptance.type",
    PRESENTATION_TYPE: "meta.presentation.type",
    CONTENT_INLINE_CONTENT: "meta.content.inline.content",
    CONTENT_OVERLAY_CONTENT: "meta.content.overlay.content"
  },
  MULTISELECT: {
    PRESENTATION_TYPE: `meta.presentation.type`,
    OPTIONS_SETS: `meta.options.sets`
  },
  DATEPICKER: {
    PRESENTATION_FORMAT: `meta.presentation.format`,
    PRESENTATION_TIMEFRAME_TYPE: `meta.presentation.timeframe.type`,
    PRESENTATION_CONTROLS_TIME: `meta.presentation.controls.time`
  },
  FILE: {
    HEADER: `meta.header`,
    TITLE: `meta.title`,
    PROMPT: `meta.prompt`,
    FROM_SOURCES: `${FILE_META_OPTIONS}.fromSources`,
    ALLOWED_TYPES: `${FILE_META_OPTIONS}.allowedTypes`
  },
  WORKFLOW: {
    STEPS: `data.steps`,
    TITLE: `title`,
    STATUS: `status`
  },
  APP_CHECKOUT: {
    SHIPPING_DELIVERY_UNITS: "shipping.deliveryEstimate.units",
    SHIPPING_DELIVERY_TEMPLATES: "shipping.deliveryEstimate.templates",
    IDENTITY_TYPES: "identity.types",
    IDENTITY_DOCUMENT_ALLOWED_TYPES: "identity.options.document.allowedTypes",
    FILE_FROM_SOURCES: "file.options.fromSources",
    FILE_ALLOWED_TYPES: "file.options.allowedTypes",
    ADDRESS_TYPES: "address.types",
    OPTION_SETS: "optionSets",
    LEGAL_ACCEPTANCE_TYPE_OPTIONS: "legal.acceptance.type.options",
    LEGAL_PRESENTATION_TYPE_OPTIONS: "legal.presentation.type.options",
    PREFILL_SOURCE_OPTIONS: "prefill.source.options",
    LOOKUP_SOURCE_OPTIONS: "lookup.source.options",
    CONNECT_ACTION_OPTIONS: "connect.actions.options",
    VALIDATION_ACTION_OPTIONS: "validation.actions.options",
    MULTISELECT_TYPES: "multiselect.types"
  },
  CONNECT: {
    STRIPE_CHECKOUT_VALUES: "stripe.checkout.values"
  },
  PUBLISH: {
    SHOW_MENU: "showMenu",
    INTENT: "intent",
    EXISTING_ROUTE: "existingRoute",
    NEW_ROUTE: "newRoute"
  },
  EMBED: {
    LAYOUT: "layout"
  },
  FIELD: {
    HIDDEN: "presentation.hidden",
    CONNECT_ENABLED: "connect.enabled",
    CONNECT_ACTIONS: "connect.actions",
    PREFILL: "prefill",
    PREFILL_ENABLED: "prefill.enabled",
    PREFILL_SOURCE: "prefill.source",
    PREFILL_READONLY: "prefill.readonly",
    PREFILL_QUERY: "prefill.query",
    PREFILL_QUERY_KEYS: "prefill.query.keys",
    VALIDATION_REQUEST: "validation.request",
    VALIDATION_REQUEST_ENABLED: "validation.request.enabled",
    VALIDATION_REQUEST_TRIGGERS: "validation.request.triggers",
    VALIDATION_REQUEST_ACTIONS: "validation.request.actions",
    VALIDATION_REQUEST_INTEGRATION_ID: "validation.request.integration_id",
    VALIDATION_REQUEST_VALIDATION: "validation.request.validation",
    VALIDATION_REQUEST_VALIDATION_CONDITIONS:
      "validation.request.validation.conditions"
  }
};
