import { COLORS } from "utils/styles";

export const AMOUNT = "amount";
export const APPROVAL_STATUSES = [
  "pending",
  "review",
  "feedback",
  "approved",
  "denied",
  "suspended"
];

export const STATUS_INCOMPLETE = "incomplete";
export const STATUS_COMPLETE = "complete";
export const GOAL_STATUSES = [STATUS_INCOMPLETE, STATUS_COMPLETE];

export const PRODUCT_TYPES = [
  "course",
  "open_source",
  "conference",
  "training",
  "workshop",
  "book",
  "certificate",
  "course",
  "tutorial",
  "mentorship",
  "retreat",
  // Additional
  "blog",
  "service"
];

export const INTERVALS = ["", "hour", "day", "week", "month", "year"];

export const FORMAT_TYPES = ["online", "live", "mixed"];

export const QUARTER_TYPES = ["winter", "summer", "spring", "fall"];

export const INVENTORY_TYPES = ["bucket"];

export const INVENTORY_VALUES = ["limited"];

export const BUTTON_HOVER_COLORS = {
  hover: "#555555",
  active: "#555555",
  activeHover: "#555555",
  inactive: "#949494"
};

export const BUTTON_NO_HOVER_COLORS = {
  hover: "#949494",
  inactive: "#949494"
};

export const ICON_PROPS = {
  size: 20,
  color: "#555555"
};

export const BORDER_STYLE = { borderWidth: "0.0625rem" };

export const CONTRAST_BG = { backgroundColor: "#FAFAFA" };

export const ICON_BUTTON_CLASSES = {
  button: "pa0 ma0 bg-transparent pointer bn items-center flex"
};

export const AVATAR_PROPS = {
  dimensions: {
    width: 50,
    height: 50
  }
};

export const TAP_TARGET = { height: 40, width: 40 };

export const ACCEPT_TABS = {
  label: "accept",
  children: []
};

export const MAKE_TABS = {
  label: "make",
  children: [
    {
      label: "email"
    }
    // {
    //   label: 'slack'
    // },
    // {
    //   label: 'google'
    // }
  ]
};

export const NOMINATE_TABS_CONFIG = [ACCEPT_TABS, MAKE_TABS];

export const AUTO_ATTRS = {
  autoCapitalize: "none",
  autoComplete: "off",
  autoCorrect: "off",
  spellCheck: "false"
};

export const AUTH_AUTO_ATTRS = {
  autoCapitalize: "none",
  autoCorrect: "off",
  autoComplete: "on",
  spellCheck: "false"
};

export const INFO_MODAL_ICON = {
  iconType: "info",
  iconProps: {
    size: 26
  },
  colors: {
    hover: COLORS.blue,
    active: COLORS.blue,
    inactive: COLORS.blue
  }
};

export const SUGGESTIONABLE_ID = "suggestionable_id";
export const SUGGESTION_KEYS = [
  "id",
  "title",
  "description",
  "suggestionable_type",
  SUGGESTIONABLE_ID,
  "department_level_id",
  "organization_id",
  "tasks"
];
