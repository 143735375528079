import CONSTANTS from "constants/subscription";
import { fromJS } from "immutable";
import helpers from "./helpers";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  raw: [],
  data: []
});

const internals = {
  [CONSTANTS.SET_SUBSCRIPTIONS]: (state, { payload }) => {
    return helpers.setIn(state, ["data"], "id", payload);
  },
  [CONSTANTS.SET_RAW_SUBSCRIPTIONS]: (state, { payload }) => {
    for (let i = 0; i < payload.length; i++) {
      const subscription = payload[i];
      const { id } = subscription;
      const subIndex = state.get("data").findIndex((sub) => {
        const storeId = sub.get("subscription_id");
        return storeId === id;
      });
      if (subIndex > -1) {
        state = state.setIn(["data", subIndex, "raw"], subscription);
      }
    }

    return state;
  },
  [CONSTANTS.UPDATE_SUBSCRIPTION_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
