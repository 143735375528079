import keymirror from "keymirror";

export default keymirror({
  UPDATE_ONBOARDING_STORE: null,
  UPDATE_ONBOARDING_STORE_FORM: null,
  RESET_ONBOARDING_STORE_FORM: null,
  SET_ONBOARDING_CONFIG: null,
  SET_ONBOARDINGS: null,
  UPDATE_ONBOARDING: null,
  SET_ONBOARDING_CONFIG_OPTIONS: null,
  REPLACE_ONBOARDING_FORM_SECTION_VALUES: null,
  SET_UPDATE_ONBOARDING_FORM_SECTION_VALUES: null,
  SET_OR_REMOVE_ONBOARDING_FORM_SECTION_VALUES: null,
  REMOVE_ONBOARDING_FORM_SECTION_VALUES: null
});
