import { useCapabilities } from "utils/selectors";

export const useUserFeature = (feature) => {
  const capabilities = useCapabilities();

  return Boolean(
    capabilities && capabilities.user && capabilities.user[feature]
  );
};

export const useUserCapabilities = () => {
  const capabilities = useCapabilities();

  return capabilities && capabilities.user;
};
