import PATHS from "utils/paths";
import { PRODUCT_TITLE } from "constants/marketing";

export const TITLE = "title";
export const DESCRIPTION = "description";
export const NAME = "name";
export const IMAGE = "image";
export const OG_IMAGE = "og:image";
export const OG_TITLE = "og:title";
export const OG_URL = "og:url";
export const OG_DESCRIPTION = "og:description";
export const OG_TYPE = "og:type";
export const OG_IMAGE_WIDTH = "og:image:width";
export const OG_IMAGE_HEIGHT = "og:image:height";
export const OG_SITE_NAME = "og:site_name";
export const TWITTER_SITE = "twitter:site";
export const TWITTER_CREATOR = "twitter:creator";
export const TWITTER_CARD = "twitter:card";
export const TWITTER_TITLE = "twitter:title";
export const TWITTER_DESCRIPTION = "twitter:description";
export const TWITTER_IMAGE = "twitter:image";
export const ICON = "icon";
export const ICON_SIZES = "icon:sizes";
export const APPLE_TOUCH_ICON = "apple-touch-icon";
export const MASK_ICON = "mask-icon";
export const MASK_ICON_COLOR = "mask-icon:color";

export const DEFAULT_TWITTER_CARD = "summary_large_image";
export const DEFAULT_ICON_SIZES = "192x192";
export const DEFAULT_MASK_ICON_COLOR = "#FFFFFF";
export const DEFAULT_OG_TYPE = "website";
export const DEFAULT_OG_IMAGE_WIDTH = "1200";
export const DEFAULT_OG_IMAGE_HEIGHT = "630";

export const METADATA_KEYS = [
  TITLE,
  DESCRIPTION,
  NAME,
  IMAGE,
  OG_IMAGE,
  OG_TITLE,
  OG_URL,
  OG_DESCRIPTION,
  OG_TYPE,
  OG_IMAGE_WIDTH,
  OG_IMAGE_HEIGHT,
  OG_SITE_NAME,
  TWITTER_SITE,
  TWITTER_CREATOR,
  TWITTER_CARD,
  TWITTER_TITLE,
  TWITTER_DESCRIPTION,
  TWITTER_IMAGE,
  ICON,
  ICON_SIZES,
  APPLE_TOUCH_ICON,
  MASK_ICON,
  MASK_ICON_COLOR
];

const APP_ROOT = `https://priceblocs.com`;

const HANDLE = "@PriceBlocsHQ";
const BRAND = "PriceBlocs";
const BASE = {
  [OG_TYPE]: "website",
  [OG_IMAGE_WIDTH]: "1200",
  [OG_IMAGE_HEIGHT]: "630",
  [TWITTER_SITE]: HANDLE,
  [TWITTER_CREATOR]: HANDLE,
  [TWITTER_CARD]: "summary_large_image",
  [OG_SITE_NAME]: BRAND,
  [TWITTER_TITLE]: BRAND,
  [TITLE]: PRODUCT_TITLE,
  [NAME]: PRODUCT_TITLE,
  [OG_TITLE]: PRODUCT_TITLE,
  [ICON]: "/touch-icon.png",
  [ICON_SIZES]: "192x192",
  [APPLE_TOUCH_ICON]: "/touch-icon.png",
  [MASK_ICON]: "/favicon-mask.svg",
  [MASK_ICON_COLOR]: "#FFFFFF"
};

const getOgURL = (path) => {
  return path
    ? path === PATHS.HOME
      ? `${APP_ROOT}${path}`
      : `${APP_ROOT}${path}/`
    : `${APP_ROOT}/`;
};

const getDefaultMeta = (path) => {
  const ogURL = getOgURL(path);

  return {
    ...BASE,
    [OG_URL]: ogURL,
    [DESCRIPTION]: DESCRIPTIONS[PATHS.HOME],
    [OG_DESCRIPTION]: DESCRIPTIONS[PATHS.HOME],
    [TWITTER_DESCRIPTION]: DESCRIPTIONS[PATHS.HOME],
    [IMAGE]: OG_IMAGES[PATHS.HOME],
    [OG_IMAGE]: OG_IMAGES[PATHS.HOME],
    [TWITTER_IMAGE]: TWITTER_IMAGES[PATHS.HOME]
  };
};

const TITLES = {
  [PATHS.HOME]: PRODUCT_TITLE,
  [PATHS.PRICING]: "PriceBlocs - choose the right plan for your team.",
  [PATHS.LINKS_SHOW]: PRODUCT_TITLE,
  [PATHS.FEATURES_INTEGRATIONS_STRIPE]:
    "PriceBlocs & Stripe - The most advanced set of no-code Stripe payment tools.",
  [PATHS.FEATURES_INTEGRATIONS_ZAPIER]:
    "PriceBlocs & Zapier - Sell anywhere, connect to anything.",
  [PATHS.FEATURES_DIGITAL_CONTENT]:
    "PriceBlocs - Sell your digital content with payment links.",
  [PATHS.FEATURES_INTEGRATIONS_WEBFLOW]:
    "PriceBlocs & Webflow - Accept one time and recurring payments, with just a link.",
  [PATHS.FEATURES_CHANNELS]:
    "PriceBlocs - Sell subscriptions and products in any channel.",
  [PATHS.FEATURES_CHECKOUT]:
    "PriceBlocs - The one click way for your customers to checkout.",
  [PATHS.FEATURES_LINKS_ADVANCED]:
    "PriceBlocs & Stripe - The most advanced Stripe payment links",
  [PATHS.FEATURES_LINKS_API]:
    "PriceBlocs API - The API for Stripe payment links",
  [PATHS.FEATURES_ANALYTICS]:
    "PriceBlocs - Free funnel and conversion analytics for Stripe payment links.",
  [PATHS.USE_CASES_PAYMENT_LINKS_AUTHORIZATION]:
    "PriceBlocs - Put a hold on a card with payment authorization links.",
  [PATHS.FEATURES_CONTACTLESS]:
    "PriceBlocs - Accept payments with custom branded QR codes.",
  [PATHS.TOOLS_QR_CODES]:
    "PriceBlocs - Accept payments with custom branded QR codes.",
  [PATHS.TOOLS_QR_CODES_STRIPE]:
    "PriceBlocs - Convert any Stripe payment link into a custom branded QR code.",
  [PATHS.TOOLS_QR_CODES_USA]:
    "PriceBlocs - Design a July 4th QR code for your business."
};

const NAMES = {
  [PATHS.HOME]: PRODUCT_TITLE,
  [PATHS.PRICING]: "PriceBlocs - choose the right plan for your team.",
  [PATHS.LINKS_SHOW]:
    "PriceBlocs - sell anywhere with one click payment links.",
  [PATHS.FEATURES_INTEGRATIONS_STRIPE]:
    "PriceBlocs & Stripe - The most advanced set of no-code Stripe payment tools.",
  [PATHS.FEATURES_INTEGRATIONS_ZAPIER]:
    "PriceBlocs & Zapier - Sell anywhere, connect to anything.",
  [PATHS.FEATURES_DIGITAL_CONTENT]:
    "PriceBlocs - Sell your digital content with payment links.",
  [PATHS.FEATURES_INTEGRATIONS_WEBFLOW]:
    "PriceBlocs & Webflow - Accept one time and recurring payments, with just a link.",
  [PATHS.FEATURES_CHANNELS]:
    "PriceBlocs - Sell subscriptions and products in any channel.",
  [PATHS.FEATURES_CHECKOUT]:
    "PriceBlocs - The one click way for your customers to checkout.",
  [PATHS.FEATURES_LINKS_ADVANCED]:
    "PriceBlocs & Stripe - The most advanced Stripe payment links.",
  [PATHS.FEATURES_LINKS_API]:
    "PriceBlocs API - The API for Stripe payment links",
  [PATHS.FEATURES_ANALYTICS]:
    "PriceBlocs - Free funnel and conversion analytics for Stripe payment links.",
  [PATHS.USE_CASES_PAYMENT_LINKS_AUTHORIZATION]:
    "PriceBlocs - Put a hold on a card with payment authorization links.",
  [PATHS.FEATURES_CONTACTLESS]:
    "PriceBlocs - Accept payments with custom branded QR codes.",
  [PATHS.TOOLS_QR_CODES]:
    "PriceBlocs - Accept payments with custom branded QR codes.",
  [PATHS.TOOLS_QR_CODES_STRIPE]:
    "PriceBlocs - Convert any Stripe payment link into a custom branded QR code.",
  [PATHS.TOOLS_QR_CODES_USA]:
    "PriceBlocs - Design a July 4th QR code for your business."
};

const DESCRIPTIONS = {
  [PATHS.HOME]: PRODUCT_TITLE,
  [PATHS.PRICING]: "PriceBlocs - choose the right plan for your team.",
  [PATHS.LINKS_SHOW]:
    "PriceBlocs - sell anywhere with one click payment links.",
  [PATHS.FEATURES_INTEGRATIONS_STRIPE]:
    "Connect the full power of the Stripe API to our set of no code tools.",
  [PATHS.FEATURES_INTEGRATIONS_ZAPIER]:
    "Use our Zapier integration to connect your checkout data to all of your favorite apps.",
  [PATHS.FEATURES_DIGITAL_CONTENT]:
    "Upload a file, and we'll send your customer a link to download it after a successful payment.",
  [PATHS.FEATURES_INTEGRATIONS_WEBFLOW]:
    "Accept payments from any static webflow page, no coding or backend required.",
  [PATHS.FEATURES_CHANNELS]:
    "Payment links help you sell anything, anywhere, with as little friction as possible.",
  [PATHS.FEATURES_CHECKOUT]:
    "Sell from anywhere with one click checkout links.",
  [PATHS.FEATURES_LINKS_ADVANCED]:
    "The most advanced Stripe checkout features, all available within a simple, easy to use payment link. Enable automatic taxes, perform dynamic customer look ups, sell multiple products and more, all without writing any code.",
  [PATHS.FEATURES_LINKS_API]:
    "The power of Stripe payment links, through a simple REST API. Enable automatic taxes, perform dynamic customer look ups, sell multiple products and more, just an API call away.",
  [PATHS.FEATURES_ANALYTICS]:
    "Track the performance of your payment links with our free funnnel and conversion dashboards for payment links",
  [PATHS.USE_CASES_PAYMENT_LINKS_AUTHORIZATION]:
    "Payment authorization links ensure that funds exist on your customer's card, which can then be captured later. When a customer authorizes a payment, their bank guarantees the amount and holds it on their card for up to seven days.",
  [PATHS.FEATURES_CONTACTLESS]:
    "Design a custom QR code to accept one time and recurring payments. Checkout is just a scan away.",
  [PATHS.TOOLS_QR_CODES]:
    "Design a custom QR code to accept one time and recurring payments. Checkout is just a scan away.",
  [PATHS.TOOLS_QR_CODES_STRIPE]:
    "Convert your Stripe payment links into custom branded QR codes so you can accept payments anywhere. Checkout is just a scan away.",
  [PATHS.TOOLS_QR_CODES_USA]:
    "Design a custom QR code to accept one time and recurring payments. Checkout is just a scan away."
};

const OG_IMAGES = {
  [PATHS.HOME]: "https://priceblocs.com/meta/og-image-primary.jpg",
  [PATHS.PRICING]: "https://priceblocs.com/meta/og-image-pricing.jpg",
  [PATHS.LINKS_SHOW]: "https://priceblocs.com/meta/og-image-primary.jpg",
  [PATHS.FEATURES_PAGES_CREATORS]:
    "https://priceblocs.com/meta/og-image-pages-creators.jpg",
  [PATHS.FEATURES_PAGES_SUBSCRIPTIONS]:
    "https://priceblocs.com/meta/og-image-pages-subscriptions.jpg",
  [PATHS.FEATURES_INTEGRATIONS_STRIPE]:
    "https://priceblocs.com/meta/og-image-integrations-stripe.jpg",
  [PATHS.FEATURES_INTEGRATIONS_ZAPIER]:
    "https://priceblocs.com/meta/og-image-integrations-zapier.jpg",
  [PATHS.FEATURES_INTEGRATIONS_WEBFLOW]:
    "https://priceblocs.com/meta/og-image-integrations-webflow.jpg",
  [PATHS.FEATURES_CHANNELS]:
    "https://priceblocs.com/meta/og-image-channels.jpg",
  [PATHS.FEATURES_CHECKOUT]:
    "https://priceblocs.com/meta/og-image-checkout.jpg",
  [PATHS.FEATURES_DIGITAL_CONTENT]:
    "https://priceblocs.com/meta/og-image-features-digitalContent.jpg",
  [PATHS.FEATURES_LINKS_ADVANCED]:
    "https://priceblocs.com/meta/og-image-features-advancedLinks.jpg",
  [PATHS.FEATURES_LINKS_API]:
    "https://priceblocs.com/meta/og-image-features-apiLinks.jpg",
  [PATHS.FEATURES_ANALYTICS]:
    "https://priceblocs.com/meta/og-image-features-analytics.jpg",
  [PATHS.USE_CASES_PAYMENT_LINKS_AUTHORIZATION]:
    "https://priceblocs.com/meta/og-image-useCases-paymentLinks-authorization.jpg",
  [PATHS.FEATURES_CONTACTLESS]:
    "https://priceblocs.com/meta/og-image-features-contactless.jpg",
  [PATHS.TOOLS_QR_CODES]:
    "https://priceblocs.com/meta/og-image-features-contactless.jpg",
  [PATHS.TOOLS_QR_CODES_STRIPE]:
    "https://priceblocs.com/meta/og-image-features-contactless.jpg",
  [PATHS.TOOLS_QR_CODES_USA]:
    "https://priceblocs.com/meta/og-image-features-contactless-usa.jpg"
};

const TWITTER_IMAGES = {
  [PATHS.HOME]: "https://priceblocs.com/meta/twitter-card-primary.jpg",
  [PATHS.PRICING]: "https://priceblocs.com/meta/twitter-card-pricing.jpg",
  [PATHS.LINKS_SHOW]: "https://priceblocs.com/meta/twitter-card-primary.jpg",
  [PATHS.FEATURES_PAGES_CREATORS]:
    "https://priceblocs.com/meta/twitter-card-creators.jpg",
  [PATHS.FEATURES_PAGES_SUBSCRIPTIONS]:
    "https://priceblocs.com/meta/twitter-card-subscriptions.jpg",
  [PATHS.FEATURES_INTEGRATIONS_STRIPE]:
    "https://priceblocs.com/meta/twitter-card-integrations-stripe.jpg",
  [PATHS.FEATURES_INTEGRATIONS_ZAPIER]:
    "https://priceblocs.com/meta/twitter-card-integrations-zapier.jpg",
  [PATHS.FEATURES_INTEGRATIONS_WEBFLOW]:
    "https://priceblocs.com/meta/twitter-card-integrations-webflow.jpg",
  [PATHS.FEATURES_CHANNELS]:
    "https://priceblocs.com/meta/twitter-card-channels.jpg",
  [PATHS.FEATURES_CHECKOUT]:
    "https://priceblocs.com/meta/twitter-card-checkout.jpg",
  [PATHS.FEATURES_DIGITAL_CONTENT]:
    "https://priceblocs.com/meta/twitter-card-features-digitalContent.jpg",
  [PATHS.FEATURES_LINKS_ADVANCED]:
    "https://priceblocs.com/meta/twitter-card-features-advancedLinks.jpg",
  [PATHS.FEATURES_LINKS_API]:
    "https://priceblocs.com/meta/twitter-card-features-apiLinks.jpg",
  [PATHS.FEATURES_ANALYTICS]:
    "https://priceblocs.com/meta/twitter-card-features-analytics.jpg",
  [PATHS.USE_CASES_PAYMENT_LINKS_AUTHORIZATION]:
    "https://priceblocs.com/meta/twitter-card-useCases-paymentLinks-authorization.jpg",
  [PATHS.FEATURES_CONTACTLESS]:
    "https://priceblocs.com/meta/twitter-card-features-contactless.jpg",
  [PATHS.TOOLS_QR_CODES]:
    "https://priceblocs.com/meta/twitter-card-features-contactless.jpg",
  [PATHS.TOOLS_QR_CODES_STRIPE]:
    "https://priceblocs.com/meta/twitter-card-features-contactless.jpg",
  [PATHS.TOOLS_QR_CODES_USA]:
    "https://priceblocs.com/meta/twitter-card-features-contactless-usa.jpg"
};
/**
 * Note:
 * - When adding additional routes make sure to add them to this list below too
 */
const META_PROPS = [
  PATHS.HOME,
  PATHS.PRICING,
  PATHS.LINKS_SHOW,
  PATHS.LEVELS_EXPLAINED,
  PATHS.GOALS_EXPLAINED,
  PATHS.PRODUCTS_EXPLAINED,
  PATHS.TEAM_EXPLAINED,
  PATHS.EVALUATIONS_EXPLAINED,
  PATHS.FEATURES_PAGES_CREATORS,
  PATHS.FEATURES_CHANNELS,
  PATHS.FEATURES_CHECKOUT,
  PATHS.FEATURES_PAGES_SUBSCRIPTIONS,
  PATHS.FEATURES_INTEGRATIONS_ZAPIER,
  PATHS.FEATURES_INTEGRATIONS_WEBFLOW,
  PATHS.FEATURES_DIGITAL_CONTENT,
  PATHS.FEATURES_LINKS_ADVANCED,
  PATHS.FEATURES_LINKS_API,
  PATHS.FEATURES_ANALYTICS,
  PATHS.USE_CASES_PAYMENT_LINKS_AUTHORIZATION,
  PATHS.FEATURES_CONTACTLESS,
  PATHS.TOOLS_QR_CODES,
  PATHS.TOOLS_QR_CODES_STRIPE,
  PATHS.TOOLS_QR_CODES_USA
].reduce((memo, path) => {
  const ogURL = getOgURL(path);
  memo[path] = {
    ...BASE,
    // Title
    [TITLE]: TITLES[path],
    [NAME]: NAMES[path],
    [OG_TITLE]: TITLES[path],
    [OG_URL]: ogURL,
    // Description
    [DESCRIPTION]: DESCRIPTIONS[path],
    [OG_DESCRIPTION]: DESCRIPTIONS[path],
    [TWITTER_DESCRIPTION]: DESCRIPTIONS[path],
    [IMAGE]: OG_IMAGES[path],
    [OG_IMAGE]: OG_IMAGES[path],
    [TWITTER_IMAGE]: TWITTER_IMAGES[path]
  };
  return memo;
}, {});

export const getPageMetaProps = ({ path, metadata } = {}) => {
  const result =
    path && META_PROPS[path] ? META_PROPS[path] : getDefaultMeta(path);

  return { ...result, ...metadata };
};
