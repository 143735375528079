// import isEmpty from "lodash/isEmpty";
import Mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import isEmpty from "lodash/isEmpty";
import { isToday } from "utils/date";
import { getConfig } from "utils/env";
import { snakeKeys } from "utils/data";
import {
  USER,
  APPLICATION,
  PRODUCT,
  PRICE,
  FEATURE,
  FEATURE_GROUP,
  MERCHANT_ACCOUNT,
  MANIFEST,
  METADATA,
  SHORT_LINK,
  PAYMENT_LINK,
  FIELD_GROUP,
  TAX_RATE,
  SHIPPING_RATE,
  COUPON,
  PROMOTION_CODE,
  INTEGRATION
} from "utils/constants/models";
export const AFFILIATE_TAG = "priceblocs07-20";
export const DEFAULT_USER_UUID = "0000-0000-0000-0000";
// Anonymous
export const USER_SESSION = `${USER}_session`;
export const USER_SIGNUP = `${USER}_signup`;
export const USER_DELETE = `${USER}_delete`;
export const USER_EDUCATION = `${USER}_education`;
// Session
export const USER_LOGIN = `${USER}_login`;
export const LAST_SESSION_DATE = `priceblocs-last-session-date`;

// Context
export const TEMPLATE_PAGE_BUILDER = `template_page_builder`;
export const TEMPLATE_LINK_BUILDER = `template_link_builder`;
export const TEMPLATE_CAMPAIGN_BUILDER = `template_campaign_builder`;

// Actions
export const PAGE_VIEW = `page_view`;
export const SHOW_CALCULATOR = `show_calculator`;
export const PUBLISH_APPLICATION = `publish_${APPLICATION}`;
export const SHOW_LIVE_APPLICATION = `show_live_${APPLICATION}`;

// Product
export const REMOVE_PRODUCT = `remove_${PRODUCT}`;
export const ADD_PRODUCT = `add_${PRODUCT}`;
export const CREATE_PRODUCT = `create_${PRODUCT}`;
export const DELETE_PRODUCT = `delete_${PRODUCT}`;
export const UPDATE_PRODUCT = `update_${PRODUCT}`;
export const SELECT_PRODUCT = `select_${PRODUCT}`;
export const SHOW_PRODUCT = `show_${PRODUCT}`;
export const DRAG_PRODUCT = `drag_${PRODUCT}`;
export const DRAG_PAYMENT_LINK = `drag_${PAYMENT_LINK}`;
export const DRAG_FIELD_GROUP = `drag_${FIELD_GROUP}`;
export const DRAG_SELECT_OPTION = `drag_select_option`;
export const HIGHLIGHT_PRODUCT = `highlight_${PRODUCT}`;
export const ADD_PRODUCT_TO_TABLE = `add_${PRODUCT}_to_table`;
export const REMOVE_PRODUCT_FROM_TABLE = `remove_${PRODUCT}_from_table`;
export const REMOVE_PAYMENT_LINK_FROM_COLLECTION = `remove_${PAYMENT_LINK}_from_collection`;
// Price
export const REMOVE_PRICE = `remove_${PRICE}`;
export const ADD_PRICE = `add_${PRICE}`;
export const CREATE_PRICE = `create_${PRICE}`;
export const SELECT_PRIMARY_PRICE = `select_primary_${PRICE}`;
// Feature Group
export const REMOVE_FEATURE_GROUP = `remove_${FEATURE_GROUP}`;
export const UPDATE_FEATURE_GROUP = `update_${FEATURE_GROUP}`;
export const ADD_FEATURE_GROUP = `add_${FEATURE_GROUP}`;
// Metadata
export const UPDATE_METADATA = `update_${METADATA}`;
export const IMPORT_METADATA = `import_${METADATA}`;
// Feature
export const ADD_FEATURE = `add_${FEATURE}`;
export const SHOW_FEATURE_EDIT = `show_${FEATURE}_edit`;
export const REMOVE_FEATURE = `remove_${FEATURE}`;
export const DRAG_FEATURE = `drag_${FEATURE}`;
// Manifest
export const UPDATE_MANIFEST = `update_${MANIFEST}`;
export const DELETE_MANIFEST = `delete_${MANIFEST}`;
// Integration
export const DELETE_INTEGRATION = `delete_${INTEGRATION}`;
// Merchant Account
export const UPDATE_MERCHANT_ACCOUNT_CONFIG = `update_${MERCHANT_ACCOUNT}_config`;
export const DELETE_MERCHANT_ACCOUNT = `delete_${MERCHANT_ACCOUNT}`;
// ShortLink
export const DELETE_SHORT_LINK = `delete_${SHORT_LINK}`;
export const CREATE_SHORT_LINK = `create_${SHORT_LINK}`;
export const UPDATE_SHORT_LINK = `update_${SHORT_LINK}`;
// Coupon
export const DELETE_COUPON = `delete_${COUPON}`;
export const CREATE_COUPON = `create_${COUPON}`;
export const UPDATE_COUPON = `update_${COUPON}`;
// TaxRate
export const DELETE_TAX_RATE = `delete_${TAX_RATE}`;
export const CREATE_TAX_RATE = `create_${TAX_RATE}`;
export const UPDATE_TAX_RATE = `update_${TAX_RATE}`;
// PromotionCode
export const DELETE_PROMOTION_CODE = `delete_${PROMOTION_CODE}`;
export const CREATE_PROMOTION_CODE = `create_${PROMOTION_CODE}`;
export const UPDATE_PROMOTION_CODE = `update_${PROMOTION_CODE}`;
// General
export const COPY_AMOUNT = `copy_amount`;
export const SELECT_CURRENCY = `select_currency`;
export const SELECT_OPTION = `select_option`;
export const SELECT_FONT_FAMILY = `select_font_family`;
export const SELECT_BILLING_INTERVAL = `select_billing_interval`;
export const REFLECT_FOCUS = `reflect_focus`;
export const CLICK_NAV_LINK = `click_nav_link`;
export const CLICK_MENU_NAV_LIST = `click_menu_nav_list`;
export const SHOW_BLOCK_SETTINGS = `show_block_settings`;
export const CLICK_EXTERNAL_LINK = `click_external_link`;
export const CLICK_STRIPE_COMPLETE_ONBOARD = `click_stripe_complete_onboard`;
export const DRAG_BLOCK = `drag_block`;
export const COPY_SECURE_TOKEN = `copy_secure_token`;
export const CLICK_MADE_WITH = `click_made_with`;
export const SHOW_INFO = `show_info`;
// -- ShortLinks
export const COPY_SHORT_LINK_SHORT_ID = `copy_${SHORT_LINK}_short_id`;
export const COPY_SHORT_LINK_URL = `copy_${SHORT_LINK}_url`;
// -- Coupons
export const COPY_COUPON_ID = `copy_${COUPON}_id`;
// -- TaxRates
export const COPY_TAX_RATE_ID = `copy_${TAX_RATE}_id`;
// -- ShippingRates
export const COPY_SHIPPING_RATE_ID = `copy_${SHIPPING_RATE}_id`;

// Editor
export const CLICK_EDITOR_SECTION_NAV = `click_editor_section_nav`;

export const SHOW_PRODUCT_FEATURE_CONFIG_SETTINGS =
  "show_product_feature_config_settings";
export const TOGGLE_PRODUCT_FEATURE_CONFIG = `toggle_product_feature_config`;
export const TOGGLE_ALL_PRODUCTS_FOR_FEATURE =
  "toggle_all_products_for_feature";
export const TOGGLE_ALL_GROUP_FEATURES_FOR_PRODUCT =
  "toggle_all_group_features_for_product";

/**
 * Used in the multi step flow for a page builder
 */
export const EVENT_TEMPLATE_PAGE_BUILDER_START = `${TEMPLATE_PAGE_BUILDER}_start`;
export const EVENT_TEMPLATE_PAGE_BUILDER_BLOCK_PICKER = `${TEMPLATE_PAGE_BUILDER}_block_picker`;
export const EVENT_TEMPLATE_PAGE_BUILDER_PRODUCT_PICKER = `${TEMPLATE_PAGE_BUILDER}_product_picker`;
export const EVENT_TEMPLATE_PAGE_BUILDER_PAYMENT_PICKER = `${TEMPLATE_PAGE_BUILDER}_payment_picker`;
export const EVENT_TEMPLATE_PAGE_BUILDER_PRICE_PICKER = `${TEMPLATE_PAGE_BUILDER}_price_picker`;
export const EVENT_TEMPLATE_PAGE_BUILDER_FEATURE_PICKER = `${TEMPLATE_PAGE_BUILDER}_feature_picker`;
export const EVENT_TEMPLATE_PAGE_BUILDER_COMPLETE = `${TEMPLATE_PAGE_BUILDER}_complete`;

/**
 * Used in the multi step flow for a campaign builder
 */
export const EVENT_TEMPLATE_CAMPAIGN_BUILDER_START = `${TEMPLATE_CAMPAIGN_BUILDER}_start`;
export const EVENT_TEMPLATE_CAMPAIGN_BUILDER_DETAILS = `${TEMPLATE_CAMPAIGN_BUILDER}_details`;
export const EVENT_TEMPLATE_CAMPAIGN_BUILDER_AUDIENCE = `${TEMPLATE_CAMPAIGN_BUILDER}_audience`;
export const EVENT_TEMPLATE_CAMPAIGN_BUILDER_SEND = `${TEMPLATE_CAMPAIGN_BUILDER}_send`;
export const EVENT_TEMPLATE_CAMPAIGN_BUILDER_COMPLETE = `${TEMPLATE_CAMPAIGN_BUILDER}_complete`;

/**
 * Used in the single step flow for a link builder
 */
export const EVENT_TEMPLATE_LINK_BUILDER_START = `${TEMPLATE_LINK_BUILDER}_start`;
export const EVENT_TEMPLATE_LINK_BUILDER_COMPLETE = `${TEMPLATE_LINK_BUILDER}_complete`;

export const CTA_PAGE_TEMPLATE_BUILDER = `cta_page_${TEMPLATE_PAGE_BUILDER}`;
export const CTA_PAGE_LINK_BUILDER = `cta_page_${TEMPLATE_LINK_BUILDER}`;
export const CTA_PAGE_SCRATCH = `cta_page_scratch`;

// Manifests / Pages
export const PRODUCT_IMP = `product_impression`;
export const PRODUCT_CLICK = `product_click`;
export const PRODUCT_CONVERT = `product_convert`;
export const PRODUCT_SEARCH = "product_search";

// Goals
export const GOAL_IMP = "goal_impression";
export const GOAL_CREATE = "goal_create";
// Suggestions
export const SUGGESTION_CONVERT = "suggestion_convert";
export const SUGGESTIONS_CONVERT = "suggestions_convert";
export const SUGGESTION_IMP = "suggestion_impression";
// Nominate
export const NOMINATE_MANAGER = "nominate_manager";
export const NOMINATE_SUBORDINATE = "nominate_subordinate";
// Recommend
export const RECOMMENDATION_CREATE = "recommendation_create";
export const RECOMMENDATION_CONVERT = "recommendation_convert";
// Evaluation
export const EVALUATION_START = "evaluation_start";
export const EVALUATION_COMPLETE = "evaluation_complete";
export const EVALUATION_GUEST_COMPLETE = "evaluation_guest_complete";
export const EVALUATION_UPDATE = "evaluation_update";
// Compare
export const COMPARE_CHANGE_LEVEL = "compare_change_level";
export const COMPARE_SEE_TRAIT_BREAKDOWN = "compare_see_trait_breakdown";
export const COMPARE_NEXT_STEPS = "compare_next_steps";

// Pricing & Billing
export const PREPARE_PRICING_SESSION = "prepare_pricing_session";
export const PREPARE_BILLING_SESSION = "prepare_billing_session";

export const QR_CODE_DOWNLOAD = "qr_code_download";

// Appointment
export const APPOINTMENT_CREATE = "appointment_create";
// Lead
export const LEAD_CREATE = "lead_create";
// Navigation
export const GUIDANCE_CLICK = "guidance_click";
const EVALUATION = "evaluation";
const RECOMMENDATION = "recommendation";
const NOMINATION = "nomination";
const TEAM = "team";
const PLAN = "plan";
export const GUIDANCE_TYPES = {
  EVALUATION,
  RECOMMENDATION,
  NOMINATION,
  TEAM,
  PLAN
};
// Onboarding
export const ONBOARDING_COMPLETE = "onboarding_complete";
export const ONBOARDING_SKIPPED = "onboarding_skipped";
// Miscellaneous
export const SLACK_JOIN = "slack_join";
// Helpers
export const PLATFORM_WEB = "web";
export const TRACKING_LOG = "--- Track: ";

if (typeof window !== "undefined") {
  Mixpanel.init(getConfig("MIXPANEL_TOKEN"), {
    protocol: process.env.NODE_ENV === "production" ? "https" : "http",
    ip: false
  });
}

const PROD_OVERRIDE = false;
export const IS_PROD = process.env.NODE_ENV === "production" || PROD_OVERRIDE;

export default {
  track(eventName, props = {}) {
    if (IS_PROD && typeof window !== "undefined") {
      if (Mixpanel) {
        if (!isEmpty(props)) {
          Mixpanel.track(eventName, snakeKeys(props));
        } else {
          Mixpanel.track(eventName);
        }
      } else {
        console.warn(
          "--- No Mixpanel Enabled: ",
          eventName,
          JSON.stringify(props, null, 2)
        );
      }
    } else {
      console.log(TRACKING_LOG, eventName, JSON.stringify(props, null, 2));
    }
  },
  trackEventWithData(tracking) {
    if (IS_PROD && typeof window !== "undefined") {
      if (Mixpanel) {
        if (!tracking.event) {
          throw new Error("Attempted to trackEventWithData with no event");
        } else if (!tracking.data) {
          throw new Error("Attempted to trackEventWithData with no data");
        } else {
          if (!isEmpty(tracking.data)) {
            Mixpanel.track(tracking.event, snakeKeys(tracking.data));
          } else {
            Mixpanel.track(tracking.event);
          }
        }
      } else {
        console.warn(
          "--- No Mixpanel Enabled: ",
          tracking.event,
          JSON.stringify(tracking.data, null, 2)
        );
      }
    } else {
      console.log(
        TRACKING_LOG,
        tracking.event,
        JSON.stringify(tracking.data, null, 2)
      );
    }
  },
  setIdentifiers(user, eventName) {
    this.registerCrispUser(user);
    this.registerMixpanelUser(user, eventName);
    this.registerSentryUser(user);
  },
  registerMixpanelUser(user, eventName) {
    if (IS_PROD && typeof window !== "undefined") {
      if (Mixpanel) {
        // Register User attributes for subsequent events
        const registerProps = {
          distinct_id: user.uuid,
          email: user.email
        };
        Mixpanel.register(registerProps);
        // Set identity to associate on alias in Mixpanel
        this.identifyMixpanelUser(user);
        // Set Sentry user
        // Set persona in Mixpanel
        this.setMixpanelPerson(user);

        if (eventName) {
          Mixpanel.track(eventName, registerProps);
        }
      } else {
        console.warn("--- No Mixpanel Enabled");
      }
    } else {
      console.log(TRACKING_LOG, eventName, JSON.stringify(user, null, 2));
    }
  },
  bootIntercom() {
    if (typeof window !== "undefined" && window.Intercom) {
      const bootProps = {
        app_id: getConfig("INTERCOM_APP_ID")
      };
      window.Intercom("boot", bootProps);
    } else {
      // console.warn("--- No Intercom Enabled");
    }
  },
  /**
   * Convert to Intercom user
   * @param {Object} user
   */
  getIntercomUser(user) {
    if (user) {
      return {
        email: user.email,
        user_id: user.uuid,
        user_since: user.created_at
      };
    }
    return {};
  },
  registerUserSession(user) {
    this.registerMixpanelUserSession(user);
  },
  aliasMixpanelUser(user) {
    if (IS_PROD && typeof window !== "undefined") {
      if (Mixpanel) {
        Mixpanel.alias(user.uuid);
      } else {
        console.warn("--- No Mixpanel Enabled for Alias");
      }
    } else {
      console.log(
        TRACKING_LOG,
        "Alias Mixpanel user",
        JSON.stringify(user, null, 2)
      );
    }
  },
  setMixpanelPerson(user) {
    if (IS_PROD && typeof window !== "undefined") {
      if (Mixpanel) {
        const peopleProps = {
          "$email": user.email,
          "Sign up date": user.created_at,
          "USER_ID": user.uuid
        };
        if (user.username) {
          peopleProps.$name = user.username;
        }

        Mixpanel.people.set(peopleProps);
      } else {
        console.warn("--- No Mixpanel Enabled for Person");
      }
    } else {
      console.log(
        TRACKING_LOG,
        "Set Mixpanel Person",
        JSON.stringify(user, null, 2)
      );
    }
  },
  identifyMixpanelUser(user) {
    if (IS_PROD && typeof window !== "undefined") {
      if (Mixpanel) {
        Mixpanel.identify(user.uuid);
      } else {
        console.warn("--- No Mixpanel Enabled for Identify");
      }
    } else {
      console.log(
        TRACKING_LOG,
        "Identify Mixpanel User",
        JSON.stringify(user, null, 2)
      );
    }
  },
  aliasUser(user) {
    this.aliasMixpanelUser(user);
  },
  identifyUser(user) {
    this.identifyMixpanelUser(user);
  },
  /**
   * Send an session event if the current date is different to the last one
   */
  registerMixpanelUserSession(user) {
    if (typeof window !== "undefined") {
      const lastSessionDate = window.localStorage.getItem(LAST_SESSION_DATE);

      const today = new Date();
      const comparison = new Date(lastSessionDate);
      const needToRegisterSession =
        user && (!lastSessionDate || !isToday(today, comparison));

      if (needToRegisterSession) {
        if (IS_PROD) {
          const { uuid: distinct_id, email } = user;

          Mixpanel.track(USER_SESSION, {
            distinct_id,
            email
          });
        } else {
          console.log(
            TRACKING_LOG,
            USER_SESSION,
            JSON.stringify(user, null, 2)
          );
        }

        window.localStorage.setItem(LAST_SESSION_DATE, today);
      }
    }
  },
  registerSentryUser(user) {
    if (typeof window !== "undefined" && Sentry) {
      // Hard shutdown to trigger refresh
      Sentry.setUser({
        user: user.email,
        id: user.id
      });
    } else {
      console.warn("--- No Sentry Enabled");
    }
  },
  registerCrispUser(user) {
    if (typeof window !== "undefined" && window.$crisp) {
      // Prevent crisp console warnings re:shims
      window.$crisp.push(["safe", true]);
      window.$crisp.push(["set", "user:email", user.email]);
    } else {
      console.warn("--- No Crisp Enabled");
    }
  },
  resetSentry() {
    if (typeof window !== "undefined" && Sentry) {
      // Hard shutdown to trigger refresh
      Sentry.configureScope((scope) => scope.setUser(null));
    } else {
      console.warn("--- No Sentry Enabled");
    }
  },
  resetCrisp() {
    if (typeof window !== "undefined" && window.$crisp) {
      window.$crisp.push(["do", "session:reset"]);
    }
  },
  resetMixpanel() {
    if (typeof window !== "undefined" && IS_PROD && Mixpanel) {
      Mixpanel.reset();
    }
  },
  clearRegistrations() {
    if (typeof window !== "undefined") {
      this.resetCrisp();
      this.resetMixpanel();
      this.resetSentry();
    }
  }
};
