import C from "constants/userApplication";
import ActionHelpers from "./helpers";
// import axios from "axios";
// import { batchActions } from "redux-batched-actions";
// import { authBearerToken } from "utils/auth";

export function setLoading() {
  return {
    type: C.UPDATE_USER_APPLICATION_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserApplications = (payload) => {
  return {
    type: C.SET_USER_APPLICATIONS,
    payload
  };
};

export const replaceUserApplications = (payload) => {
  return {
    type: C.REPLACE_USER_APPLICATIONS,
    payload
  };
};

export const unsetMockUserApplication = () => {
  return {
    type: C.UNSET_MOCK_USER_APPLICATION
  };
};

/**
 * resetUserApplicationNotes
 */
export function resetUserApplicationNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_USER_APPLICATION_STORE,
      payload
    });
  };
}

export function showUserApplicationNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_USER_APPLICATION_STORE,
        message || `Success!`
      )
    );
  };
}
