import { STATUS } from "utils/constants/ui";
export const CAMPAIGN_CHANNELS = ["email"];

const RECOVER_CHECKOUT_SESSION = "recover_checkout_session";
const COLLECT_PAYMENT = "collect_payment";
const PROSPECT_CUSTOMERS = "prospect_customers";
const UPSELL_CHECKOUT_SESSION = "upsell_checkout_session";
const PARAMETERS = "parameters";

export const CAMPAIGN_TYPE = {
  RECOVER_CHECKOUT_SESSION,
  COLLECT_PAYMENT,
  PROSPECT_CUSTOMERS,
  UPSELL_CHECKOUT_SESSION,
  PARAMETERS
};

const UNKNOWN = "unknown";
const UNCONVERTED = "unconverted";

/**
 * ==================================
 * Upsell products
 * ==================================
 */
const PLATFORM_UPSELL_VIEWED = "platform_upsell_viewed";
const PLATFORM_UPSELL_STARTED = "platform_upsell_started";
/**
 * Customer has (self | organically) recovered on platform
 * - Has a corresponding platform event but its not associated with a recovery attempt
 */
const PLATFORM_CONVERTED = "platform_converted";
/**
 * Customer has converted via upsell campaign
 */
const PLATFORM_UPSOLD = "platform_upsold";
/**
 * Customer has converted via off platform
 * - i.e. they have an active sub but its not associated with an upsell attempt
 */
const SELLER_CONVERTED = "seller_converted";

export const UPSELL_CONVERSION_STATUS = {
  UNKNOWN,
  UNCONVERTED,
  PLATFORM_UPSELL_VIEWED,
  PLATFORM_UPSELL_STARTED,
  PLATFORM_CONVERTED,
  PLATFORM_UPSOLD,
  SELLER_CONVERTED
};

const UPSELLABLE = "upsellable";
const UNUPSELLABLE = "unupsellable";

export const UPSELL_CONVERSION_POTENTIAL = {
  UPSELLABLE,
  UNUPSELLABLE
};

export const UPSELL_CONVERSION_STATUS_LABEL_MAP = {
  [UPSELL_CONVERSION_STATUS.UNKNOWN]: "Unknown",
  [UPSELL_CONVERSION_POTENTIAL.UPSELLABLE]: "Upsellable",
  [UPSELL_CONVERSION_POTENTIAL.UNUPSELLABLE]: "Unupsellable",
  [UPSELL_CONVERSION_STATUS.UNCONVERTED]: "Unconverted",
  [UPSELL_CONVERSION_STATUS.PLATFORM_UPSELL_VIEWED]: "Viewed",
  [UPSELL_CONVERSION_STATUS.PLATFORM_UPSELL_STARTED]: "Started",
  [UPSELL_CONVERSION_STATUS.PLATFORM_CONVERTED]: "Converted",
  [UPSELL_CONVERSION_STATUS.PLATFORM_UPSOLD]: "Upsold",
  [UPSELL_CONVERSION_STATUS.SELLER_CONVERTED]: "Seller converted"
};

export const UPSELL_CONVERSION_STATUS_COLOR_MAP = {
  [UPSELL_CONVERSION_STATUS.UNKNOWN]: STATUS.WARNING,
  [UPSELL_CONVERSION_POTENTIAL.UPSELLABLE]: STATUS.WARNING,
  [UPSELL_CONVERSION_POTENTIAL.UNUPSELLABLE]: STATUS.WARNING,
  [UPSELL_CONVERSION_STATUS.UNCONVERTED]: STATUS.WARNING,
  [UPSELL_CONVERSION_STATUS.PLATFORM_UPSELL_VIEWED]: STATUS.VIEWED,
  [UPSELL_CONVERSION_STATUS.PLATFORM_UPSELL_STARTED]: STATUS.STARTED,
  [UPSELL_CONVERSION_STATUS.PLATFORM_CONVERTED]: STATUS.DANGER,
  [UPSELL_CONVERSION_STATUS.PLATFORM_UPSOLD]: STATUS.SUCCESS,
  [UPSELL_CONVERSION_STATUS.SELLER_CONVERTED]: STATUS.PREMIUM
};

/**
 * ==================================
 * Recover checkout
 * ==================================
 */
const PLATFORM_RECOVERED = "platform_recovered";
const PLATFORM_RECOVERY_VIEWED = "platform_recovery_viewed";
const PLATFORM_RECOVERY_STARTED = "platform_recovery_started";
export const RECOVERY_CONVERSION_STATUS = {
  UNKNOWN,
  UNCONVERTED,
  PLATFORM_RECOVERY_VIEWED,
  PLATFORM_RECOVERY_STARTED,
  PLATFORM_CONVERTED,
  PLATFORM_RECOVERED
};

const RECOVERABLE = "recoverable";
const UNRECOVERABLE = "unrecoverable";

export const RECOVERY_CONVERSION_POTENTIAL = {
  RECOVERABLE,
  UNRECOVERABLE
};

export const RECOVERY_CONVERSION_STATUS_LABEL_MAP = {
  [RECOVERY_CONVERSION_STATUS.UNKNOWN]: "Unknown",
  [RECOVERY_CONVERSION_POTENTIAL.RECOVERABLE]: "Recoverable",
  [RECOVERY_CONVERSION_POTENTIAL.UNRECOVERABLE]: "Unrecoverable",
  [RECOVERY_CONVERSION_STATUS.UNCONVERTED]: "Unconverted",
  [RECOVERY_CONVERSION_STATUS.PLATFORM_RECOVERY_VIEWED]: "Viewed",
  [RECOVERY_CONVERSION_STATUS.PLATFORM_RECOVERY_STARTED]: "Started",
  [RECOVERY_CONVERSION_STATUS.PLATFORM_CONVERTED]: "Converted",
  [RECOVERY_CONVERSION_STATUS.PLATFORM_RECOVERED]: "Recovered"
};

export const RECOVERY_CONVERSION_STATUS_COLOR_MAP = {
  [RECOVERY_CONVERSION_STATUS.UNKNOWN]: STATUS.WARNING,
  [RECOVERY_CONVERSION_POTENTIAL.RECOVERABLE]: STATUS.WARNING,
  [RECOVERY_CONVERSION_POTENTIAL.UNRECOVERABLE]: STATUS.WARNING,
  [RECOVERY_CONVERSION_STATUS.UNCONVERTED]: STATUS.WARNING,
  [RECOVERY_CONVERSION_STATUS.PLATFORM_RECOVERY_VIEWED]: STATUS.VIEWED,
  [RECOVERY_CONVERSION_STATUS.PLATFORM_RECOVERY_STARTED]: STATUS.STARTED,
  [RECOVERY_CONVERSION_STATUS.PLATFORM_CONVERTED]: STATUS.DANGER,
  [RECOVERY_CONVERSION_STATUS.PLATFORM_RECOVERED]: STATUS.SUCCESS
};

/**
 * ==================================
 * Prospect customers
 * ==================================
 */
export const PROSPECT_CUSTOMERS_MEAN_RANGE_LABEL_MAP = {
  0: "Low",
  1: "Medium",
  2: "High"
};

export const PROSPECT_CUSTOMERS_MEAN_RANGE_COLOR_MAP = {
  0: STATUS.DEFAULT,
  1: STATUS.WARNING,
  2: STATUS.SUCCESS
};

const PLATFORM = "platform";
const CONNECT = "connect";
export const CUSTOMER_SOURCE = {
  UNKNOWN,
  PLATFORM,
  CONNECT
};

const NOT_CUSTOMER = "not_customer";
const CONNECT_ONLY = "connect_only";

export const RECOVERY_CUSTOMER_SOURCE = {
  NOT_CUSTOMER,
  CONNECT_ONLY,
  PLATFORM,
  UNKNOWN
};

export const CUSTOMER_SOURCE_LABEL_MAP = {
  [RECOVERY_CUSTOMER_SOURCE.NOT_CUSTOMER]: "New",
  [RECOVERY_CUSTOMER_SOURCE.CONNECT_ONLY]: "Seller",
  [RECOVERY_CUSTOMER_SOURCE.PLATFORM]: "PriceBlocs",
  [RECOVERY_CUSTOMER_SOURCE.UNKNOWN]: "Unknown"
};

export const RECOVERY_STATUS_LABEL_MAP = {};

export const CAMPAIGN_CONFIG_PRIORITY = {
  PRIMARY: "primary",
  SECONDARY: "secondary"
};
