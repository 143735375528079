import CONSTANTS from "constants/app";
import { fromJS } from "immutable";

export const initialState = fromJS({
  isLoading: false,
  note: null,
  data: {
    capabilities: [],
    products: [],
    entities: [],
    templates: {},
    pages: {
      templates: {
        index: {},
        data: {}
      }
    },
    checkout: {},
    builder: {},
    fonts: [],
    rates: [],
    onboarding: {},
    variables: {},
    rawOrgSubscription: null
  }
});

const internals = {
  [CONSTANTS.SET_APP_CAPABILITIES]: (state, { payload }) => {
    return state.setIn(["data", "capabilities"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_PRODUCTS]: (state, { payload }) => {
    return state.setIn(["data", "products"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_TEMPLATES]: (state, { payload }) => {
    return state.setIn(["data", "templates"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_PAGES_TEMPLATES_INDEX]: (state, { payload }) => {
    return state.setIn(
      ["data", "pages", "templates", "index"],
      fromJS(payload)
    );
  },
  [CONSTANTS.SET_APP_PAGES_TEMPLATE]: (state, { payload }) => {
    return state.setIn(
      ["data", "pages", "templates", "data", payload.uuid],
      fromJS(payload)
    );
  },
  [CONSTANTS.SET_APP_FONTS]: (state, { payload }) => {
    return state.setIn(["data", "fonts"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_RATES]: (state, { payload }) => {
    return state.setIn(["data", "rates"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_CHECKOUT]: (state, { payload }) => {
    return state.setIn(["data", "checkout"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_BUILDER]: (state, { payload }) => {
    return state.setIn(["data", "builder"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_ENTITIES]: (state, { payload }) => {
    return state.setIn(["data", "entities"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_VARIABLES]: (state, { payload }) => {
    return state.setIn(["data", "variables"], fromJS(payload));
  },
  [CONSTANTS.SET_APP_ONBOARDING]: (state, { payload }) => {
    return state.setIn(["data", "onboarding"], fromJS(payload));
  },
  [CONSTANTS.UPDATE_APP_STORE]: (state, { payload }) => {
    return state.merge(fromJS(payload));
  }
};

export const reducer = (
  state = fromJS(initialState),
  { type, payload } = {}
) => {
  const reducerFn = internals[type];

  return reducerFn ? reducerFn(state, { type, payload }) : state;
};
