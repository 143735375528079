import C from "constants/price";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { REQUEST_METHOD } from "utils/constants/request";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_PRICE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setPrices = (payload) => {
  return {
    type: C.SET_PRICES,
    payload
  };
};

export const replacePrices = (payload) => {
  return {
    type: C.REPLACE_PRICES,
    payload
  };
};

export const removePrices = (payload) => {
  return {
    type: C.REMOVE_PRICES,
    payload
  };
};

/**
 * resetPriceNotes
 */
export function resetPriceNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_PRICE_STORE,
      payload
    });
  };
}

export function showPriceNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_PRICE_STORE, message || `Success!`)
    );
  };
}

/**
 * createMerchantAccountPrices
 */
export const createMerchantAccountPrices = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/prices`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        if (responseData.prices.length > 0) {
          dispatch(
            batchActions([
              setPrices(responseData.prices),
              setTimeout(() => {
                dispatch(
                  ActionHelpers.successNote(
                    C.UPDATE_PRICE_STORE,
                    "Prices created"
                  )
                );
              }, 250)
            ])
          );
        }
        callback(null, responseData.prices);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRICE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateMerchantAccountPrices
 */
export const updateMerchantAccountPrices = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/prices`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        if (responseData.prices.length > 0) {
          dispatch(
            batchActions([
              setPrices(responseData.prices),
              setTimeout(() => {
                dispatch(
                  ActionHelpers.successNote(
                    C.UPDATE_PRICE_STORE,
                    `Price${responseData.prices.length > 1 ? "s" : ""} updated`
                  )
                );
              }, 250)
            ])
          );
        }
        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRICE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * deleteMerchantAccountPrices
 */
export const deleteMerchantAccountPrices = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/prices`;

    return axios({
      method: REQUEST_METHOD.DELETE,
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        if (responseData.prices.length > 0) {
          dispatch(batchActions([removePrices(responseData.prices)]));
        }
        callback(null, responseData.prices);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRICE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * archiveMerchantAccountPrices
 */
export const archiveMerchantAccountPrices = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/prices/archive`;

    return axios({
      method: REQUEST_METHOD.PUT,
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        if (responseData.prices.length > 0) {
          dispatch(batchActions([removePrices(responseData.prices)]));
        }
        callback(null, responseData.prices);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_PRICE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
