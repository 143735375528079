import C from "constants/coupon";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { setPromotionCodes } from "./promotionCode";
import { REQUEST_METHOD } from "utils/constants/request";
import { pluralizeCopy } from "utils/copy";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_COUPON_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setCoupons = (payload) => {
  return {
    type: C.SET_COUPONS,
    payload
  };
};

export const replaceCoupons = (payload) => {
  return {
    type: C.REPLACE_COUPONS,
    payload
  };
};

export const removeCoupons = (payload) => {
  return {
    type: C.REMOVE_COUPONS,
    payload
  };
};

/**
 * resetCouponNotes
 */
export function resetCouponNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_COUPON_STORE,
      payload
    });
  };
}

export function showCouponNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_COUPON_STORE, message || `Success!`)
    );
  };
}

/**
 * createMerchantAccountCoupons
 */
export function createMerchantAccountCoupons(
  { resourceUUID, resource, uuid, data: requestData },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_COUPON_STORE,
        "get",
        "app",
        "Create merchant account coupon"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/coupons`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data: requestData,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const { coupons, promotionCodes } = data;
        const actions = [ActionHelpers.clearLoader(C.UPDATE_COUPON_STORE)];
        if (coupons.length) {
          actions.push(
            ActionHelpers.successNote(
              C.UPDATE_COUPON_STORE,
              `Created ${pluralizeCopy("coupon", coupons.length)}`
            )
          );
          actions.push(setCoupons(coupons));
        }
        if (promotionCodes.length) {
          actions.push(setPromotionCodes(promotionCodes));
        }

        dispatch(batchActions(actions));
        callback(null, { coupons, promotionCodes });
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_COUPON_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * updateMerchantAccountCoupons
 */
export const updateMerchantAccountCoupons = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/coupons`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { coupons } }) => {
        if (coupons.length > 0) {
          dispatch(batchActions([setCoupons(coupons)]));
        }
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_COUPON_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * deleteMerchantAccountCoupons
 */
export const deleteMerchantAccountCoupons = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/coupons`;

    return axios({
      method: REQUEST_METHOD.DELETE,
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { coupons } }) => {
        if (coupons.length > 0) {
          dispatch(batchActions([removeCoupons(data.coupons)]));
        }
        callback(null, coupons);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_COUPON_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
