import axios from "axios";
import isNull from "lodash/isNull";
import get from "lodash/get";
import pick from "lodash/pick";
import PATHS, { apiResourcePath } from "../paths";
import { defaultHttps } from "utils/url";
import { authBearerToken } from "../auth";
import { getSubdomainRoot } from "utils/subdomain";
import { STATE_KEYS } from "../constants/state";
import { TAX_ID_COLLECTION_TYPE } from "../constants/taxRate";
import { CAPTURE_METHOD } from "../constants/payment";
import { getValue } from "utils/data";
import {
  BUTTON_CLASS_CONTEXT,
  BUTTON_TAGS,
  DEFAULT_CHECKOUT_ACTION_CLASSES
} from "utils/constants/ui";
import { PAGE_ACTION_UIDS } from "components/FormerEditor/common/constants";
import classnames from "classnames";
import { reduceShortLinkDiscounts } from "utils/shortLink";
import { STRIPE_LINE_ITEM_KEYS } from "utils/constants/stripe";
import { mergeCheckoutConfigs } from "./merge";

const PRODUCT_CHECKOUT = "product.checkout";

export const merchantCheckoutScaffoldBase = ({
  merchantAccountConfig
} = {}) => {
  const result = {};

  const trialPeriodDays = get(
    merchantAccountConfig,
    STATE_KEYS.MERCHANT_ACCOUNT_CONFIG.TRIAL_PERIOD_DAYS,
    0
  );
  if (trialPeriodDays) {
    result.trial_period_days = trialPeriodDays;
  }
  const automaticTax = get(
    merchantAccountConfig,
    STATE_KEYS.MERCHANT_ACCOUNT_CONFIG.AUTOMATIC_TAX_ENABLED,
    false
  );
  if (automaticTax) {
    result.automatic_tax = {
      enabled: automaticTax
    };
  }

  const taxIdCollectionEnabled = get(
    merchantAccountConfig,
    STATE_KEYS.MERCHANT_ACCOUNT_CONFIG.TAX_ID_COLLECTION_ENABLED,
    false
  );
  const taxIdType = get(
    merchantAccountConfig,
    STATE_KEYS.MERCHANT_ACCOUNT_CONFIG.TAX_ID_COLLECTION_TYPE,
    TAX_ID_COLLECTION_TYPE.STANDARD
  );

  if (taxIdCollectionEnabled) {
    result.tax_id_collection = {
      enabled: taxIdCollectionEnabled,
      type: taxIdType
    };
  }

  return result;
};

export const getShortLinkCheckoutScaffold = ({
  merchantAccountConfig
} = {}) => {
  return {
    payment_method_types: ["card"],
    ...merchantCheckoutScaffoldBase({ merchantAccountConfig })
  };
};

export const fetchMerchantAccountCheckoutPreview = async ({
  resource,
  resourceUUID,
  uuid
}) => {
  /* eslint-disable-next-line no-useless-catch */
  try {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/checkout/success/preview`;

    const response = await axios({
      url: REQUEST_URL,
      method: "get",
      headers: authBearerToken()
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRedirectUrls = ({ application, merchantAccount }) => {
  const redirectRoot = getSubdomainRoot(application.subdomain);
  /**
   * Success URLs - after successful checkout payment
   * 1. Return to the seller configured checkout success url
   * 2. Return to the generic checkout success url
   * - NOTE: if using this url we need to have the application subdomain in the root (i.e. subdomain redirect root)
   * - This allows us to fetch the session on behalf of that account
   */
  const configSuccessURL = get(
    merchantAccount,
    "merchant_account_config.data.common.success_url"
  );
  const fallbackSuccessURL = `${redirectRoot}${PATHS.CHECKOUT_SUCCESS}`;
  const successUrl = defaultHttps(configSuccessURL || fallbackSuccessURL);

  /**
   * Cancel URLs
   * 1. Return to the url of the sellers site within the merchant account
   * 2. Return to the seller configured merchant acccount checkout cancel url
   */
  const merchantRootURL = get(merchantAccount, "profile.url");
  const configCancelURL = get(
    merchantAccount,
    "merchant_account_config.data.common.cancel_url"
  );
  const fallbackErrorURL = `${redirectRoot}${PATHS.CHECKOUT_ERROR}`;
  const cancelUrl = defaultHttps(
    merchantRootURL || configCancelURL || fallbackErrorURL
  );

  return {
    successUrl,
    cancelUrl
  };
};

/**
 * =======================================
 * Checkout merge
 * =======================================
 */
export const getLeftCollectionOrRight = ({
  left,
  right,
  leftPath,
  rightPath
}) => {
  let collection = get(left, leftPath) || [];

  if (!collection.length) {
    collection = get(right, rightPath) || [];
  }

  return collection;
};

/**
 * Discounts on a checkout are more specific than any setting in common
 * If no discounts are present but allow_promotion_codes is present then we use that
 */
export const getCheckoutOrCommonDiscounts = ({ checkout, common }) => {
  const result = {
    discounts: [],
    allow_promotion_codes: false
  };
  const checkoutDiscounts = get(checkout, "discounts") || [];
  const checkoutAllow = get(checkout, "allow_promotion_codes");

  const commonDiscounts = get(common, `${PRODUCT_CHECKOUT}.discounts`) || [];
  const commonAllow = get(common, `${PRODUCT_CHECKOUT}.allow_promotion_codes`);

  if (checkoutDiscounts.length > 0) {
    result.discounts = checkoutDiscounts;
  } else if (checkoutAllow && typeof checkoutAllow === "boolean") {
    result.allow_promotion_codes = checkoutAllow;
  } else if (commonDiscounts.length > 0) {
    result.discounts = commonDiscounts;
  } else if (commonAllow && typeof commonAllow === "boolean") {
    result.allow_promotion_codes = commonAllow;
  }

  return result;
};

const hasItems = (collection) =>
  Array.isArray(collection) && collection.length > 0;

export const checkoutConfigToSummaryList = (config) => {
  const result = {};
  const cancelUrl = config.cancel_url;
  const successUrl = config.success_url;
  const subscriptionSchedule = config.product
    ? config.product.subscription_schedule
    : null;

  const checkout = get(config, PRODUCT_CHECKOUT, {});
  if (cancelUrl || successUrl) {
    result.redirects = {};
    if (cancelUrl) {
      result.redirects.cancel_url = cancelUrl;
    }
    if (successUrl) {
      result.redirects.success_url = successUrl;
    }
  }

  if (hasItems(checkout.payment_method_types)) {
    if (!result.payment) {
      result.payment = {};
    }
    result.payment.methods = checkout.payment_method_types;
  }

  if (
    checkout.payment_intent_data &&
    checkout.payment_intent_data.capture_method &&
    checkout.payment_intent_data.capture_method === CAPTURE_METHOD.MANUAL
  ) {
    if (!result.payment) {
      result.payment = {};
    }
    result.payment.authorize = true;
  }
  if (checkout.mode) {
    if (!result.payment) {
      result.payment = {};
    }
    result.payment.mode = checkout.mode;
  }

  if (
    checkout.payment_intent_data &&
    checkout.payment_intent_data.setup_future_usage
  ) {
    if (!result.payment) {
      result.payment = {};
    }
    result.payment.setup_future_usage =
      checkout.payment_intent_data.setup_future_usage;
  }

  if (
    subscriptionSchedule &&
    subscriptionSchedule.phases &&
    subscriptionSchedule.phases[0] &&
    subscriptionSchedule.phases[0].iterations
  ) {
    result.installments = {
      iterations: subscriptionSchedule.phases[0].iterations
    };
  }

  if (checkout.trial_period_days) {
    result.trial = {
      days: checkout.trial_period_days
    };
  }

  if (checkout.automatic_tax && checkout.automatic_tax.enabled) {
    if (!result.tax) {
      result.tax = {};
    }
    result.tax.automatic = true;
  }

  const taxIdCollectionEnabled = Boolean(
    checkout.tax_id_collection && checkout.tax_id_collection.enabled
  );
  const hasTaxRates = hasItems(checkout.tax_rates);
  const hasDynamicTaxRates = hasItems(checkout.dynamic_tax_rates);

  const hasAnyTaxConfig = Boolean(
    taxIdCollectionEnabled || hasTaxRates || hasDynamicTaxRates
  );

  if (hasAnyTaxConfig && !result.tax) {
    result.tax = {};
  }

  if (taxIdCollectionEnabled) {
    result.tax.id_collection_type = checkout.tax_id_collection.type;
  }

  if (hasTaxRates) {
    result.tax.rates = checkout.tax_rates;
  }

  if (hasDynamicTaxRates) {
    result.tax.rates = [...result.tax.rates, ...checkout.dynamic_tax_rates];
  }

  if (hasItems(checkout.discounts)) {
    if (!result.discounts) {
      result.discounts = {};
    }
    result.discounts.models = checkout.discounts;
  }

  if (checkout.allow_promotion_codes) {
    if (!result.discounts) {
      result.discounts = {};
    }
    result.discounts.allow_promotion_codes = true;
  }

  if (checkout.shipping_worldwide) {
    if (!result.shipping) {
      result.shipping = {};
    }
    result.shipping.worldwide = true;
  }

  if (hasItems(checkout.shipping_options)) {
    if (!result.shipping) {
      result.shipping = {};
    }
    result.shipping.options = checkout.shipping_options;
  }

  if (
    checkout.shipping_address_collection &&
    hasItems(checkout.shipping_address_collection.allowed_countries)
  ) {
    if (!result.shipping) {
      result.shipping = {};
    }
    result.shipping.countries =
      checkout.shipping_address_collection.allowed_countries;
  }

  if (hasItems(config.product && config.product.attachments)) {
    if (!result.attachments) {
      result.attachments = {};
    }
    result.attachments.models = config.product.attachments;
  }

  return result;
};

export const getCheckoutCartShortLinkProduct = ({ content, cart }) => {
  const productsCheckouts = get(
    content,
    STATE_KEYS.PRODUCT_COLLECTION.CONFIG_PRODUCTS_CHECKOUTS
  );

  const hasProductsCollection =
    productsCheckouts && cart && Array.isArray(cart.products);
  const cartCheckouts = hasProductsCollection
    ? cart.products.reduce((memo, productUUID) => {
        if (productsCheckouts[productUUID]) {
          memo.push(productsCheckouts[productUUID]);
        }
        return memo;
      }, [])
    : [];

  return mergeCheckoutConfigs(cartCheckouts);
};

export const checkoutPreviewLineCount = ({
  lineItems,
  discountItems,
  amountItems,
  taxItems,
  shippingItems
}) => {
  const lineItemsCount = (lineItems && lineItems.length) || 0;

  const discountsCount = (discountItems && discountItems.length) || 0;

  const taxesCount = (taxItems && taxItems.length) || 0;

  const shippingsCount = (shippingItems && shippingItems.length) || 0;

  const amountsCount = (amountItems && amountItems.length) || 0;

  return (
    lineItemsCount + discountsCount + taxesCount + shippingsCount + amountsCount
  );
};

export const primaryToLineItem = (primary) =>
  pick(primary, STRIPE_LINE_ITEM_KEYS);

export const shortLinkToSessionInput = (shortLink) => {
  const primaries = get(shortLink, STATE_KEYS.SHORT_LINK.PRIMARIES) || [];
  const checkout = get(shortLink, STATE_KEYS.SHORT_LINK.CHECKOUT) || {};

  const result = {
    line_items: primaries.map((primary) => primaryToLineItem(primary)),
    ...checkout
  };
  const successUrl = get(shortLink, STATE_KEYS.SHORT_LINK.SUCCESS_URL);
  if (successUrl) {
    result.success_url = successUrl;
  }
  const cancelUrl = get(shortLink, STATE_KEYS.SHORT_LINK.CANCEL_URL);
  if (cancelUrl) {
    result.cancel_url = cancelUrl;
  }

  result.discounts = reduceShortLinkDiscounts(result.discounts);

  for (const key in result) {
    const val = result[key];
    const emptyArray = Array.isArray(val) && !val.length;
    const isNothing = isNull(val) || typeof val === "undefined";
    if (emptyArray || isNothing) {
      delete result[key];
    }
  }

  return result;
};

export const getCheckoutActionButtonProps = ({
  copy,
  loading,
  disabled,
  index,
  theme,
  type,
  styles,
  customClasses
}) => {
  const ctxClasses = { ...DEFAULT_CHECKOUT_ACTION_CLASSES, ...customClasses };

  const result = {
    className: ctxClasses.container,
    copy: getValue(copy),
    tag:
      type === PAGE_ACTION_UIDS.STRIPE_CHECKOUT
        ? BUTTON_TAGS.BUTTON
        : BUTTON_TAGS.LINK,
    loading,
    disabled,
    classes: {
      contextClass:
        ctxClasses.contextClass || BUTTON_CLASS_CONTEXT.BLOCKS_PRIMARY,
      button: classnames(ctxClasses.button, {
        ml1: index
      })
    }
  };

  if (styles) {
    result.styles = styles;
  }
  if (theme) {
    result.theme = theme;
  }

  return result;
};
