import C from "constants/manifest";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { deconstructReducerData, prepareModelAssociations } from "utils/data";
import { reduceModelActions } from "utils/action";
import { setThemes } from "./theme";
import { setApplications } from "./application";
import { setUserManifests } from "./userManifest";
import { setOrganizationManifests } from "./organizationManifest";
import { FEATURE_MODELS, FEATURE_ACTIONS_MAP } from "./featureGroup";
import compact from "lodash/compact";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_MANIFEST_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setManifests = (payload) => {
  return {
    type: C.SET_MANIFESTS,
    payload
  };
};

export const replaceManifests = (payload) => {
  return {
    type: C.REPLACE_MANIFESTS,
    payload
  };
};

export function removeManifests(payload) {
  return {
    type: C.REMOVE_MANIFESTS,
    payload
  };
}

export function updateManifestAction(payload) {
  return {
    type: C.UPDATE_MANIFEST,
    payload
  };
}

/**
 * resetManifestNotes
 */
export function resetManifestNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_MANIFEST_STORE,
      payload
    });
  };
}

export function showManifestNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_MANIFEST_STORE, message || `Success!`)
    );
  };
}

const MANIFEST_MODELS = ["manifests"];
const MANIFEST_ACTIONS_MAP = {
  setManifests,
  setUserManifests,
  setOrganizationManifests
};

/**
 * createManifest
 */
export const createManifest = (
  { resourceUUID, resource, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/manifests`;
    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const reducers = deconstructReducerData(data, "manifest");
        const manifests = Array.isArray(reducers.manifest)
          ? reducers.manifest
          : [reducers.manifest];

        dispatch(
          batchActions([
            setManifests(manifests),
            ActionHelpers.successNote(C.UPDATE_MANIFEST_STORE, "Created Page")
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MANIFEST_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * createManifestAndProductsFromTemplateBuilder
 */
export const createManifestAndProductsFromTemplateBuilder = (
  {
    resourceID,
    resourceUUID,
    resource,
    data: {
      copyManifestUUID,
      manifest,
      products,
      primaries,
      features,
      featureGroups,
      merchantAccountUUID
    }
  },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/manifests/products/prices`;
    return axios({
      method: "post",
      url: REQUEST_URL,
      data: {
        copyManifestUUID,
        manifest,
        products,
        primaries,
        features,
        featureGroups,
        merchantAccountUUID
      },
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          manifests: [responseData.manifest],
          features: responseData.features,
          featureGroups: responseData.featureGroups
        });

        const manifestActions = reduceModelActions({
          keys: MANIFEST_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: MANIFEST_ACTIONS_MAP
        });

        const featureActions = reduceModelActions({
          keys: FEATURE_MODELS,
          action: ACTION_TYPE.REPLACE,
          resource,
          data: preparedResponse,
          actionsMap: FEATURE_ACTIONS_MAP
        });

        const allActions = [...manifestActions, ...featureActions];

        allActions.push(
          ActionHelpers.successNote(C.UPDATE_MANIFEST_STORE, "Created Page")
        );

        dispatch(batchActions(compact(allActions)));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MANIFEST_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchManifests
 */
export function fetchManifests(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_MANIFEST_STORE,
        "update",
        "manifest",
        "Fetch manifests"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/manifests`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          manifests: responseData
        });

        const actions = reduceModelActions({
          keys: MANIFEST_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: MANIFEST_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MANIFEST_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * updateManifest
 */
export const updateManifest = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/manifests/${uuid}`;
    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const { manifest, theme } = data;
        const actions = [
          setManifests([manifest]),
          ActionHelpers.successNote(C.UPDATE_MANIFEST_STORE, "Saved Page")
        ];
        if (theme) {
          actions.push(setThemes([theme]));
        }

        dispatch(batchActions(actions));

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MANIFEST_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * deleteManifest
 * - the API will update the application config if that manifest is currently associated with an active deployment
 * - the response includes both the manifest and the application which may have been updated
 */
export const deleteManifest = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/manifests/${uuid}`;
    return axios({
      method: "delete",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { manifest, application } }) => {
        dispatch(
          batchActions([
            removeManifests([manifest]),
            setApplications([application]),
            ActionHelpers.successNote(C.UPDATE_MANIFEST_STORE, "Deleted Page")
          ])
        );

        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_MANIFEST_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
