import { fromJS } from "immutable";
import isEmpty from "lodash/isEmpty";

export default {
  inActionAtIndex: (action, state, path, id, idKey, payload) => {
    const data = state.getIn(path);

    if (data) {
      const targetIndex = data.findIndex((model) => model.getIn(idKey) === id);

      if (targetIndex >= 0) {
        const setPath = path.concat(targetIndex);
        state = state[`${action}In`](setPath, fromJS(payload));
      }
    }

    return state;
  },
  /**
   * NOTE: Dont mix set and replace
   * - use normal state.setIn for replacement with emtpy array etc.
   */
  setIn: (state, path, idKey, payload = []) => {
    let data = state.getIn(path);

    /**
     * Detect whether data is a list or not
     */
    if (data && data.findIndex && Array.isArray(payload)) {
      for (let i = 0; i < payload.length; i++) {
        const model = payload[i];

        if (!isEmpty(model)) {
          const modelIx = data.findIndex((storeRoom) => {
            return storeRoom.get(idKey) === model[idKey];
          });

          // Replace found models
          if (modelIx >= 0) {
            data = data.setIn([modelIx], fromJS(model));
          } else {
            // Add models which have not been found
            data = data.push(fromJS(model));
          }
          state = state.setIn(path, data);
        }
      }
    }

    return state;
  },
  /**
   * Create local records for known associations
   */
  setAssociationsIn: (state, payload, firstKey, secondKey) => {
    let data = state.getIn(["data"]);

    /**
     * Detect whether data is a list or not
     */
    if (data && data.findIndex && Array.isArray(payload)) {
      for (let i = 0; i < payload.length; i++) {
        const model = payload[i];

        if (!isEmpty(model)) {
          const modelIx = data.findIndex((storeModel) => {
            return (
              storeModel.get(firstKey) === model[firstKey] &&
              storeModel.get(secondKey) === model[secondKey]
            );
          });

          if (modelIx === -1) {
            // data.getIn(['0'])
            // data = data.setIn([modelIx], fromJS(model));
            data = data.push(fromJS(model));
          }

          state = state.setIn(["data"], data);
        }
      }
    }

    return state;
  }
};

export const getConstant = (constants, key) => {
  const constantMatch = constants[key];
  if (!constantMatch) {
    throw new Error(`No constant for: ${key}`);
  }
  return constantMatch;
};
